import {
  TransformModel
} from '@/models/TransformModel'
import Learner from './Learner'
import Module from '@/models/Module'


export default class Grade extends TransformModel {
  static entity = 'grades'
  static primaryKey = ['user_id', 'module_id']
  static fields() {
    return {
      id: this.attr(null),
      grade: this.attr(null).nullable(),
      symbol: this.attr(null),
      user_id: this.attr(null),
      registration_id: this.attr(null),
      user: this.belongsTo(Learner, 'user_id'),
      module_id: this.attr(null),
      module: this.belongsTo(Module, 'module_id'),
      comment: this.attr(null),
      withdrawn:this.attr(false),
      is_new: this.attr(1),
      released_at: this.attr(null),
    }
  }
  static mutators() {
    return {
      grade(value) {
        if (value != null) {
          return parseFloat(value)
        }
        return value
      },

    }
  }
  static deleteRemote(grade_id) {
    return this.api().delete(`/grades/${grade_id}`, {
      delete: grade_id
    })
  }
  static create(grade) {
    return this.api().post(`/grades`, grade, {
      save: false
    })
  }

  static downloadReport(registration) {
    return this.api().post(`/grades/download-report`, {registration_id: registration.id}, {
      save: false,
      responseType: 'blob'
    })
  }

  static downloadTranscript(registration) {
    return this.api().post(`/grades/download-transcript`, {registration_id: registration.id}, {
      save: false,
      responseType: 'blob'
    })
  }

  static update(grade) {
    return this.api().patch(`/grades/${grade.id}`, grade, {
      save: true,
      dataTransformer: (
        response
      ) => {
        let element = response.data.data
        element = {
          ...element,
          ...element.attributes
        }
        return element
      }
    })
  }

  static fetchAllUngraded(page = 1, limit = 999, query = {}, module) {
    return this.api().get(`/registrations?with[]=user&with[]=modules&page=${page}&limit=${limit}`, {
      params: {
        ...(query.user_id ? {
          user_id: query.user_id
        } : {}),
        ...(query.course_id ? {
          course_id: query.course_id
        } : {}),
        ...(query.year ? {
          year: query.year
        } : {}),
        ...(query.ungraded_module_id ? {
          ungraded_module_id: query.ungraded_module_id
        } : {}),
        ...(query.current_semester ? {
          current_semester: query.current_semester
        } : {}),
      },

      dataTransformer: (response) => {
        let storageArray = []
        response.data.data.forEach(element => {
          element.attributes.user = {
            ...element.attributes.user,
            ...element.attributes.user.attributes
          }
          if(element.attributes.grade) {
            element.attributes.grade = (Math.round(parseFloat(element.attributes.grade) * 10) / 10).toFixed(1)
          }
          if (module) {
            let grade = {}

            grade.user = element.attributes.user
            grade.user_id = parseInt(element.attributes.user.id)
            grade.registration_id = element.id
            grade.module_id = parseInt(module.id)
            grade.module = module

            storageArray.push(grade)
          } else {
            element.attributes.modules.forEach(new_module => {

              let grade = {}
              new_module = {
                ...new_module,
                ...new_module.attributes
              }
              grade.user = element.attributes.user
              grade.user_id = parseInt(element.attributes.user.id)
              grade.registration_id = element.id
              grade.module_id = parseInt(new_module.id)
              grade.module = new_module
              if (Object.prototype.hasOwnProperty.call(grade.module,'status_id')){
                if (grade.module.status_id===2){
                  grade.withdrawn = true
                }
              }
              storageArray.push(grade)
            })
          }

        })

        return storageArray
      }
    })
  }
  static fetchAll(page = 1, limit = 999, query = {}, relationships = []) {
    return this.api().get(`/grades`, {
      params: {
        page: page,
        limit: limit,
        ...(relationships ? {
          with: relationships
        } : {}),
        ...(query.module_id ? {
          module_id: query.module_id
        } : {}),
        ...(query.year ? {
          year: query.year
        } : {}),
        ...(query.course_id ? {
          course_id: query.course_id
        } : {}),
        ...(query.learner_id ? {
          user_id: query.learner_id
        } : {}),
        ...(query.is_complete ? {
          is_complete: query.is_complete
        } : {}),
        ...(query.is_released ? {
          is_released: query.is_released
        } : {}),
        ...(query.current_semester ? {
          current_semester: query.current_semester
        } : {}),
        ...(query.registration ? {
          registration: query.registration
        } : {})
      },

      dataTransformer: (response) => {
        let storageArray = []
        response.data.data.forEach(element => {
          if (element.attributes.user) {
            element.attributes.user = {
              ...element.attributes.user,
              ...element.attributes.user.attributes
            }
          }
          if (element.attributes.module) {
            element.attributes.module = {
              ...element.attributes.module,
              ...element.attributes.module.attributes
            }
          }

          element.is_new = 0
          if(element.attributes.grade) {
            element.attributes.grade = (Math.round(parseFloat(element.attributes.grade) * 10) / 10).toFixed(1)
          }

          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)
        })
        return storageArray

      }
    })
  }
  static sakaiFetchForUser(site_id, user_id) {
    return this.api().get('/lms/sites/'+site_id+'/user/'+user_id);
  }

  static sakaiFetchForSite(site_id) {
    return this.api().get('/lms/modules/'+site_id+'/retrieve-grades');
  }

  static sakaiFetchAllGrades() {
    return this.api().get('/lms/modules/retrieve-all');
  }

  static sakaiReleaseAllGrades() {
    return this.api().get('/grades/release-all');
  }
}
