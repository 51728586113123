export const StatusesModule = {
  namespaced: true,

  state: {
    statuses:
      [
        {id: 1, name: "Application Incomplete"},
        {id: 2, name: "Registration Pending"},
        {id: 3, name: "Registration Complete"},
        {id: 4, name: "Application Incomplete"},
        {id: 5, name: "Application Accepted"},
        {id: 6, name: "Withdrawn"},
        {id: 7, name: "Postponed"},
        {id: 8, name: "Document Verification"},
        {id: 9, name: "Application Declined"},
        {id: 10, name: "RPL Submitted"},
        {id: 11, name: "RPL Review"},
        {id: 12, name: "RPL Complete"},
        {id: 14, name: "CAT Submitted"},
        {id: 15, name: "CAT Review"},
        {id: 16, name: "CAT Complete"},
        {id: 13, name: "LMS Upload"},
        {id: 17, name: "Application Payment Pending"},
        {id: 18, name: "Initial Payment Pending"},
        {id: 19, name: "Dismissed"},
        {id: 20, name: "Application Cancelled"},
        {id: 21, name: "Suspended"},
      ],
    grouped_statuses:
      [{
      name: "Application",
      statuses: [
        {id: 1, name: "Application Incomplete"},
        {id: 17, name: "Application Payment Pending"},
      ]
    },
      {
      name: "Registration",
      statuses: [
        {id: 2, name: "Registration Pending"},
        {id: 8, name: "Document Verification"},
        {id: 18, name: "Initial Payment Pending"},
        {id: 13, name: "LMS Upload"},
        {id: 3, name: "Registration Complete"},
        {id: 19, name: "Dismissed"},
        {id: 20, name: "Application Cancelled"},
        {id: 21, name: "Suspended"},
      ]
    },
      {
      name: "Withdrawals",
      statuses: [
        {id: 9, name: "Application Declined"},
        {id: 7, name: "Postponed"},
        {id: 6, name: "Withdrawn"},
      ]
    },

    ]
    ,
    // Pre rework
    STATUSES_REGISTERED: [3, 13],
    STATUSES_APPLICATION_INCOMPLETE: [1],
    STATUSES_APPLICATION_COMPLETE: [2, 3, 4, 5, 6, 7, 8, 10, 11, 12, 13],
    STATUSES_ACCEPTED_REGISTRATIONS: [1, 2, 3, 5, 6, 7, 8, 12],
    STATUSES_REGISTRATIONS_IN_PROGRESS: [1, 2],
    STATUSES_PROFILE_LOCKED:[2,5,8,9,10,11,12,14,15,16],
    //Rework
    STATUSES_REGISTRATION_COMPLETE:[3,6,7,9,13,21],
    STATUSES_REGISTRATION_PROBATION:[19,20,21]
  }
};
