import {TransformModel} from '@/models/TransformModel'
import Module from '@/models/Module'
import ModuleRegistration from '@/models/ModuleRegistration'
import Learner from './Learner'
import Course from './Course'
import User from './User'
import RegistrationStage from './RegistrationStage'
import RegistrationNote from '@/models/RegistrationNote'
import Intake from "@/models/Intake";

export default class Registration extends TransformModel {
  static entity = 'registrations'

  static fields() {
    return {
      activities: this.attr(() => ([{
        created_at: null,
        note: null,
        user: {},
      }])),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      id: this.attr(null),
      user_id: this.attr(null),
      total_tasks_count: this.attr(null),
      completed_tasks_count: this.attr(null),
      user: this.belongsTo(Learner, 'user_id'),
      course_id: this.attr(null),
      course: this.belongsTo(Course, 'course_id'),
      owner: this.belongsTo(User, 'owner_id'),
      owner_id: this.attr(null),
      creator: this.belongsTo(User, 'creator_id'),
      creator_id: this.attr(null),
      stage_id: this.attr(null),
      semester: this.attr(null),
      year: this.attr(null),
      delivery_type_id: this.attr(null),
      stage: this.belongsTo(RegistrationStage, 'stage_id'),
      registration_status_id: this.attr(null, value => value === null ? null : parseInt(value)),
      notes: this.hasMany(RegistrationNote, 'enquiry_id'),
      age_human_readable: this.attr(null),
      enrolment_date: this.attr(null),
      level: this.attr(null),
      message: this.attr(null),
      marketing_source_id: this.attr(null),
      accepted_as_application: this.attr(null),
      rpl_cat_requested: this.attr(null),
      exemption_type_id: this.attr(null),
      modules: this.belongsToMany(Module, ModuleRegistration, 'registration_id', 'module_id'),
      is_returning: this.attr(null),
      intake_id: this.attr(null),
      intake: this.belongsTo(Intake, 'intake_id'),
      dismissed_at: this.attr(null),
      dismissal_reason: this.attr(null),
      grade_status_id: this.attr(null),
      grade_hold: this.attr(0),
    }
  }

  get grade_status () {
    switch (this.grade_status_id) {
      case 1:
        return 'Enrolled';
      case 2:
        return 'Probation';
      case 3:
        return 'Extended Probation';
      case 4:
        return 'Dismissed';
      default:
        return '';
    }
  }

  static fetchAll(page = 1, limit = 999, query = {}, by_stages = false) {
    return this.api().get(`/registrations?with[]=user&with[]=course&with[]=intake&page=${page}&limit=${limit}`, {
      params: {
        ...(query.board_id ? {
          board_id: query.board_id
        } : {}),
        ...(query.user_id ? {
          user_id: query.user_id
        } : {}),
        ...(query.creator_id ? {
          creator_id: query.creator_id
        } : {}),
        ...(query.year ? {
          year: query.year
        } : {}), ...(query.has_incomplete_tasks ? {
          has_incomplete_tasks: query.has_incomplete_tasks
        } : {}),
        ...(query.semester ? {
          semester: query.semester
        } : {}),
        ...(query.owner_id === null ? {
          owner_id: 'null'
        } : {}),
        ...(query.owner_id ? {
          owner_id: query.owner_id
        } : {}),
        ...(query.course_id === null ? {
          course_id: 'null'
        } : {}),
        ...(query.course_id ? {
          course_id: query.course_id
        } : {}),
        ...(query.registration_status_id ? {
          registration_status_id: query.registration_status_id
        } : {}),
        ...(query.order_by ? {
          order_by: query.order_by
        } : {}),
        ...(query.order_direction ? {
          order_direction: query.order_direction
        } : {}),
        ...(query.ungraded_module_id ? {
          ungraded_module_id: query.ungraded_module_id
        } : {}),
        ...(by_stages == true ? {
          by_stages: 1
        } : {}),
      },

      dataTransformer: (response) => {
        let storageArray = []
        response.data.data.forEach(element => {
          element.attributes.user = {
            ...element.attributes.user,
            ...element.attributes.user.attributes
          }
          if (element.attributes.course) {
            element.attributes.course = {
              ...element.attributes.course,
              ...element.attributes.course.attributes
            }
          }
          if (element.attributes.stage) {
            element.attributes.stage = {
              ...element.attributes.stage,
              ...element.attributes.stage.attributes
            }
          }
          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)
        })
        return storageArray
      }
    })
  }

  static search(data, saved = true, is_open = null, page = 1, limit = 100) {
    return this.api().get(`/courses?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(is_open ? {
          is_open: 1
        } : {})
      },
      save: saved
    })
  }

  // static registrationLogin(token) {
  //   return this.api().get(`http://api.aas.site/api/1/registrations/1?signature=${token}`, {
  //     save: false
  //   })
  // }
  static deleteRemote(registrationId) {
    return this.api().delete(`/registrations/${registrationId}`, {
      save: false
    })
  }

  static updateRegistrationModuleStatus(registrationId, moduleId, statusId) {

    return this.api().patch(`/registrations/${registrationId}/modules/${moduleId}`, {
      status_id: statusId
    })
  }

  static newRegistration(registration) {
    return this.api().post(`/registrations`, registration, {
      dataTransformer: (response) => {


        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static checkStatus(registration_id) {
    return this.api().post(`/registrations/${registration_id}/check-application-status`, {}, {
      save: false
    })
  }

  static emailProof(registration_id, type, body = null,save) {
    return this.api().post(`/registrations/${registration_id}/email-proof-of-registration`, {
      save: save,
      type: type,
      ...(body !== null ? {body: body} : {})
    })
  }

  static emailAcceptance(registration_id, options) {
    return this.api().post(`/registrations/${registration_id}/email-acceptance-letter`, options, {
      save: false
    })
  }

  static updateRemote(registration) {
    return this.api().put(`/registrations/${registration.id}`, registration, {
      save: true
    })
  }

  /**
   * Check paid registrations
   * @function
   * @param {string} registration_id - The id of the invoice

   * @returns {Promise<Response>} - The newly created invoice
   */

  static CheckPayments(registration_id) {
    return this.api().post(`/registrations/${registration_id}/check-payments-for-programme`, {}, {
        save: false,
        dataTransformer: ({data: {data}}) => {
          return {...data, ...data.attributes}
        },
      }
    )
  }

  /**
   *
   * @param registration_id
   * @returns {Promise<Response>} - The boolean response
   * @constructor
   */
  static CheckIsReturningCourse(registration_id) {
    return this.api().post(`/registrations/${registration_id}/check-is-returning`, {}, {
        save: false,
      }
    )
  }

  static updateStage(registration) {
    delete registration.modules
    return this.api().put(`/registrations/${registration.id}`, registration, {
      save: false
    })
  }

  static toggleModule(id, module_id) {
    return this.api().post(`/courses/${id}/assign-module`, {
      module_id: module_id
    })
  }

  static fetchByUser(page, limit, id, relationships = [
    'user',
    'course',
    'modules',
    'intake',
    'course.intakes',
  ]) {
    return this.api().get(`/registrations?user=${id}`, {
      params: {
        page: page,
        limit: limit,
        with: relationships

      },
      dataTransformer: (response) => {

        return response.data.data.map(registration => {
          if (Object.prototype.hasOwnProperty.call(registration.attributes, 'user')) {
            let user = registration.attributes.user
            Object.assign(user, user.attributes)
          }
          if (Object.prototype.hasOwnProperty.call(registration.attributes, 'intake')) {
            let intake = registration.attributes.intake
            if (intake != null) {
              Object.assign(intake, intake.attributes)
            }

          }
          if (Object.prototype.hasOwnProperty.call(registration.attributes, 'stage')) {
            let stage = registration.attributes.stage
            Object.assign(stage, stage.attributes)
          }
          if (Object.prototype.hasOwnProperty.call(registration.attributes, 'course')) {
            let course = registration.attributes.course;
            if (course !== null) {
              Object.assign(registration.attributes.course, registration.attributes.course.attributes)
              if (Object.prototype.hasOwnProperty.call(course, 'intakes')) {
                registration.attributes.course.intakes.forEach(intake => {
                  Object.assign(intake, intake.attributes)
                });
              }
            }
          }

          if (Object.prototype.hasOwnProperty.call(registration.attributes, 'modules')) {

            registration.attributes.modules.map(module => {
              Object.assign(module, module.attributes)
            });
          }

          return {...registration, ...registration.attributes}
        })
      }
    })
  }

  static fetchById(id, relationships = [], query = {}) {
    return this.api().get(`/registrations/${id}`, {
      params: {
        ...query,
        ...{
          with: [
            'user',
            'course',
            'modules',
            'modules.semesters',
            'activities.user',
            'is_returning',
            'course.qualification',
            'intake',
            'course.intakes',
            ...relationships
          ]
        }
      },
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        if (Object.prototype.hasOwnProperty.call(target, 'user')) {
          let user = target.user
          Object.assign(user, user.attributes)
        }
        if (Object.prototype.hasOwnProperty.call(target, 'intake')) {
          let intake = target.intake
          if (intake != null) {
            Object.assign(intake, intake.attributes)
          }
        }
        if (Object.prototype.hasOwnProperty.call(target, 'course')) {
          let course = target.course
          if (course != null) {
            Object.assign(course, course.attributes)
            if (Object.prototype.hasOwnProperty.call(course, 'qualification')) {
              let qualification = course.qualification;
              if (qualification) {
                Object.assign(qualification, qualification.attributes);
              }
            }
            if (Object.prototype.hasOwnProperty.call(course, 'intakes')) {
              course.intakes.forEach(intake => {
                Object.assign(intake, intake.attributes)
              });
            }
          }
        }
        if (Object.prototype.hasOwnProperty.call(target, 'modules')) {

          target.modules.forEach(module => {
            Object.assign(module, module.attributes)
          });
        }
        return target
      }
    })
  }

  static bulkChange(updateObject) {
    return this.api().post(`/registrations/bulk-update`, updateObject, {
      saved: false,
      dataTransformer: (response) => {


        return response
      }
    })
  }

}
