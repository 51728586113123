<template>
  <section class="section">
    <title-bar>{{ $tc('Qualification', 2) }}</title-bar>

    <div class="columns">
      <div class="column is-half">
        <b-field label="Filter" horizontal>
          <b-input
            v-debounce:300ms="getFilteredQualifications"
            placeholder="Type any parameter here"
            :loading="loadingFilter"
            v-model="filter"
          ></b-input>
        </b-field>
      </div>
    </div>
    <b-table scrollable class="margin-top" :data="qualifications" :striped="true" :hoverable="true" :bordered="true">
      <b-table-column v-slot="props" label="Code" field="code" sortable width="40" numeric>{{ props.row.code }}</b-table-column>

      <b-table-column v-slot="props" sortable field="name" label="Name">{{ props.row.name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="long_name" label="Long Name">{{ props.row.long_name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="long_name" label="Department">{{
          props.row.department_id ? $store.state.departments.departments.find(department => department.id === props.row.department_id).name : 'None'
        }}
      </b-table-column>
      <b-table-column v-slot="props" sortable field="nqf_level" label="NQF Level">{{ props.row.nqf_level }}</b-table-column>
      <b-table-column v-slot="props" sortable field="standardised_level_id" label="Standardised Level">{{ props.row.standardised_level_id?$store.state.levels.levels.find(level=> level.id===props.row.standardised_level_id).name:'None'  }}</b-table-column>
      <b-table-column v-slot="props" centered  custom-key="actions">
        <b-field>
          <span class="control">
            <b-button  @click="viewQualification(props.row.id)" type="is-info">View</b-button>
          </span>
          <span class="control">
            <b-button
            v-if="can_delete_qualifications"
            @click="start_delete_qualification(props.row.id)"
            type="is-danger"
          >Delete
            </b-button>
          </span>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      class="margin-top"
      :total="meta.total"
      :current="meta.current_page"
      :range-before="2"
      :range-after="2"
      :per-page="meta.per_page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Qualification from "@/models/Qualification";
import TitleBar from "@/components/TitleBar";

export default {
  name: "Qualifications",
  components: {TitleBar},
  data() {
    return {
      filter: "",
      loadingFilter: false,
      updatingQualification: false,
      qualifications: [],
      meta: Object,
      page: 1
    };
  },
  methods: {
    start_delete_qualification(qualificationId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Qualification",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Qualification",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteQualification(qualificationId)
      });

    },
    deleteQualification(qualificationId) {
      this.$store.dispatch("loader/show");

      this.updatingQualification = true;
      Qualification.Delete(qualificationId)
        .then(() => {
          Qualification.delete(qualificationId);
          this.getQualifications(1, 15)
            .then(() => {
              this.$store.dispatch("loader/hide");
            })
            .catch(err => {
            });
          this.updatingQualification = false;
        })
        .catch(err => {
        });
    },
    viewQualification(id) {
      this.$router.push(`/qualifications/edit/${id}`);
    },
    getFilteredQualifications(text) {
      this.loadingFilter = true;

      Qualification.search(text, true).then(result => {
        this.loadingFilter = false;

        this.qualifications = result.response.data.data;

        this.meta = result.response.data.meta;
      });
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      if (this.filter.length > 0) {
        Qualification.search(this.filter, true, null, pageNumber, 15).then(result => {
          this.qualifications = result.response.data.data;
          this.meta = result.response.data.meta;
          this.$store.dispatch("loader/hide");
        });
      } else {
        this.getQualifications(pageNumber, this.meta.per_page).then(() => {
          this.$store.dispatch("loader/hide");
        });
      }
    },
    getQualifications(page, limit) {
      return Qualification.fetchAll(page, limit).then(result => {
        this.qualifications = result.response.data.data;

        this.meta = result.response.data.meta;
      });
    }
  },

  computed: {
    can_delete_qualifications() {
      return this.$store.getters["entities/permissions/find"]("delete qualifications");
    }
  },
  mounted() {
    this.$store.dispatch("loader/show");
    this.getQualifications(1, 15)
      .then(() => {
        this.$store.dispatch("loader/hide");
      })
      .catch(err => {
      });
  }
};
</script>
