import {
  Model
} from '@vuex-orm/core'

export default class Permission extends Model {
  static entity = 'permissions'
  static primaryKey = 'name'
  static fields() {
    return {
      id: this.attr(null),
      name: this.attr('')
    }
  }

  static fetchAll() {
    return this.api().get(`/permissions`, {
      save: false,

    })
  }


}
