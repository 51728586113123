<template>
    <card-component
        title="LMS Code Manager"
        :icon="$tc('icons.modules')">
        <form @submit.prevent="addLMSCode" class="mb-6">
          <div style="display: flex;align-items: center;" >
            <div class="lmsCode mr-3" style="width: 100%">
              <b-field label="Search Code" custom-class="nowrap-text" group-multiline grouped horizontal>
                <b-field>
                  <LMSCodeSearchSelect
                    :isDisabled="disableForm"
                    v-model="selectedCode"
                ></LMSCodeSearchSelect>
                </b-field>
              </b-field>
            </div>
            <div class="semester mr-3">
              <b-field label="Semesters" horizontal>
                <b-select
                    v-model="selectedSemester"
                    :disabled="disableForm"
                    required
                    placeholder="Select Semester">
                  <option
                      v-for="semester in semesters"
                      :key="semester.id"
                      :value="semester.id">
                    {{ semester.name }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="year mr-3">
              <b-field label="Year" horizontal>
                <b-select v-model="selectedYear" :disabled="disableForm" placeholder="Year" required>
                  <option :value="(new Date().getFullYear()-1)" :key="(new Date().getFullYear()-1)+'year'">{{ (new Date().getFullYear()-1) }}</option>
                  <option
                      v-for="number in 5"
                      :key="number + 'year'"
                      :value="(new Date().getFullYear()-1)+number"
                  >{{ (new Date().getFullYear() - 1) + number }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="add mr-3">
              <b-button
                  native-type="submit"
                  :loading="loadingModuleAdd"
                  type="is-primary"
                  :disabled="disableForm"
              >Add Code
              </b-button>
            </div>
          </div>
        </form>
        <div class="columns mt-6" v-if="props.module.lms_codes && props.module.lms_codes.length" >
          <div class="column is-12">
            <b-table
                :data="props.module.lms_codes"
                :striped="true"
                scrollable
                :hoverable="true"
                :bordered="true"
            >
              <b-table-column
                field="code"
                label="LMS Code"
                v-slot="props"
              >
                <span :class="checkIfCurrentLMSCode(props.row?.lms_code) ? 'has-text-success' : ''">{{ props.row.lms_code }}</span>
              </b-table-column>
              <b-table-column
                field="Semester"
                label="Semester"
                v-slot="props"
              >{{ returnSemester(props.row.semester_id) }}
              </b-table-column>
              <b-table-column
                field="Year"
                label="Year"
                v-slot="props"
              >{{ props.row.year }}
              </b-table-column>
              <b-table-column
                  label="Actions"
                  v-slot="props"
                  class="has-text-centered"
                  custom-key="actions"
                  width="120">
                <b-checkbox
                    :indeterminate="true"
                    :value="true"
                    @click.native.prevent="confirmDelete(props.row.id)"
                ></b-checkbox>
              </b-table-column>
            </b-table>
          </div>
        </div>
    </card-component>
</template>


<script setup>
import Semester from "@/models/Semester";
import ModuleLMSCode from "@/models/ModuleLMSCode";
import CardComponent from "@/components/CardComponent";
import LMSCodeSearchSelect from "@/components/global/selects/LMSCodeSearchSelect.vue";


import { ref, computed } from "vue";
import { useBuefy } from "@/compositions/utils/useBuefy";
import { useStore } from "@/compositions/useStore";

const store = useStore();
const buefy = useBuefy();

const loadingModuleAdd = ref(false);

//  Form data
const selectedCode = ref(null);
const selectedSemester = ref(null);
const selectedYear = ref(null);

const props = defineProps({
    module: {
        type: Object,
        required: true,
    },
    currentSemester: {
      type: Object,
    },
    currentLMSCode:{
      type: String,
    },
    disableForm: {
      type: Boolean,
      default: true,
    },
});

const emit = defineEmits(["refreshModule"]);

const semesters = computed(() => {
    return Semester.query().get();
});

const returnSemester = (id) => {
    let semester = semesters.value.find((semester) => semester.id == id);
    if(semester?.name) return semester.name;
    return ""
};

const clearFormData = () => {
    selectedCode.value = null;
    selectedSemester.value = null;
    selectedYear.value = null;
};

const checkIfCurrentLMSCode = (lmsCode) => {
    return lmsCode == props.currentLMSCode;
};

const addLMSCode = () => {
    const lmsCodesArray = props.module.lms_codes.map((lmsCode) => lmsCode.lms_code);
    const semesterIdsArray = props.module.lms_codes.map((lmsCode) => lmsCode.semester_id);

    if(lmsCodesArray.includes(selectedCode.value)){
        buefy.snackbar.open({
            message: "LMS Code already added to this module.",
            type: "is-danger",
        });
        return;
    }

    if(semesterIdsArray.includes(parseInt(selectedSemester.value))){
        buefy.snackbar.open({
            message: "LMS Code already added to this module for this semester.",
            type: "is-danger",
        });
        return;
    }

    let payload = {
        module_id: parseInt(props.module.id),
        lms_code: selectedCode.value,
        semester_id: parseInt(selectedSemester.value),
        year: selectedYear.value,
    }

    store.dispatch("loader/show");
    ModuleLMSCode.Store(payload)
      .then(() => {
        emit("refreshModule");
        clearFormData();
        buefy.snackbar.open({
          message: "LMS Code Added!",
          type: "is-success",
        });
      })
      .catch((error) => {
        store.dispatch("loader/hide");
        buefy.snackbar.open({
          message: "Error adding LMS Code",
          type: "is-danger",
        });
        throw error;
      });
};

const confirmDelete = (id) => {
    buefy.dialog.confirm({
        title: "Are you sure you want to remove this LMS Code?",
        message: "This action cannot be undone.",
        confirmText: "Delete",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
            removeLMSCode(id);
        },
    });
};

const removeLMSCode = (id) => {
    store.dispatch("loader/show");
    ModuleLMSCode.Delete(id)
      .then(() => {
        emit("refreshModule");
        buefy.snackbar.open({
          message: "LMS Code Removed!",
          type: "is-success",
        })
      })
      .catch((error) => {
        store.dispatch("loader/hide");
        buefy.snackbar.open({
          message: "Error removing LMS Code",
          type: "is-danger",
        });
        throw error;
      });
};
</script>

<style >
.label.nowrap-text {
  white-space: nowrap;
}
</style>
```
