import {TransformModel} from '@/models/TransformModel'
import Module from '@/models/Module'
import ModuleCourse from '@/models/ModuleCourse'
import CourseRelatedCourse from '@/models/CourseRelatedCourse'
import CourseSubject from "@/models/CourseSubject";
import CourseCoreOption from "@/models/CourseCoreOption";
import Qualification from "@/models/Qualification";
import Intake from "@/models/Intake";
import CourseIntake from "@/models/CourseIntake";

export default class Course extends TransformModel {
  static entity = 'courses'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      achieved_at: this.attr(null),
      has_age_exemption: this.attr(0),
      age_exemption: this.attr(23),
      needs_application_fee:this.attr(0),
      has_matric_requirements: this.attr(0),
      matric_requirements: this.attr(()=>{
        return []
      }),
      has_school_subject_requirements: this.attr(0),
      has_custom_form: this.attr(0),
      is_custom_flow: this.attr(0),
      new_application_stage_id:this.attr(null),
      payment_pending_stage_id:this.attr(null),
      application_complete_stage_id:this.attr(null),
      custom_forms:this.attr(()=>{
        return []
      }),
      achievement_type_id: this.attr(null),
      honors_status_id: this.attr(null),
      status_id: this.attr(null),
      long_name: this.attr(null),
      che_code: this.attr(null),
      saqa_number: this.attr(null),
      heqcis_number: this.attr(null),
      che_cesm_code_id: this.attr(null),
      has_core_options:this.attr(0),
      has_qualification_requirement:this.attr(0),
      total_credits: this.attr(null),
      is_open: this.attr(null),
      delivery_type_id: this.attr(null),
      type_id: this.attr(null),
      modules: this.belongsToMany(Module, ModuleCourse, 'course_id', 'module_id'),
      related_courses: this.belongsToMany(Course, CourseRelatedCourse, 'course_id', 'related_course_id'),
      school_subjects:this.hasOne(CourseSubject,'course_id'),
      min_extra_cores:this.attr(0),
      max_extra_cores:this.attr(0),
      core_options:this.hasMany(CourseCoreOption,'course_id'),
      qualification_id: this.attr(null),
      qualification: this.belongsTo(Qualification, 'qualification_id', 'id'),
      intakes: this.belongsToMany(Intake, CourseIntake, 'course_id', 'intake_id'),
      student_info: this.attr(null),
    }
  }

  static fetchAll(page = 1, limit = 999) {
    return this.api().get(`/courses`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }

  static fetchAllUserCourses(page = 1, limit = 999, user_id) {
    return this.api().get(`/users/${user_id}/courses`, {
      params: {
        page: page,
        limit: limit

      },
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }

  static search(data, saved = true, is_open = null, page = 1, limit = 100, relationships = []) {
    return this.api().get(`/courses?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(is_open ? {
          is_open: 1
        } : {}),
        ...(relationships ? {
          with: relationships
        } : {})
      },
      save: saved
    })
  }

  static qSearch(data, qualification, intake, saved = true, is_open = null, page = 1, limit = 100, relationships = []) {
    let url = `/courses?limit=${limit}&page=${page}`;
    if (qualification) {
      url += `&qualification=${qualification}`
    }
    if (intake) {
      url += `&intake=${intake}`
    }
    if (data) {
      url += `&search=${data}`
    }
    return this.api().get(url, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(is_open ? {
          is_open: 1
        } : {}),
        ...(relationships ? {
          with: relationships
        } : {})
      },
      save: saved
    })
  }

  static Delete(courseId) {
    return this.api().delete(`/courses/${courseId}`, {
      delete: courseId
    })
  }

  static Store(course) {
    return this.api().post(`/courses`, course, {
      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static Update(course) {
    return this.api().patch(`/courses/${course.id}`, course, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static updateUserCourse(user_id, course_id, items) {
    return this.api().patch(`/users/${user_id}/courses/${course_id}`, items, {
      save: false
    })
  }

  static toggleModule(id, module_id) {
    return this.api().post(`/courses/${id}/assign-module`, {
      module_id: module_id
    })
  }

  static toggleRelatedCourse(id, course_id) {
    return this.api().post(`/courses/${id}/relate-course`, {
      related_course_id: course_id
    })
  }

  static fetchById(id,relationships=['modules','coreOptions','coreOptions.supplements','related_courses', 'qualification', 'intakes']) {
    return this.api().get(`/courses/${id}`, {
      params: {

        ...(relationships ? {
          with: relationships
        } : {})

      },
      dataTransformer: (response) => {
        let target = response.data.data
        if (Object.prototype.hasOwnProperty.call(target.attributes, 'core_options')){
          target.attributes.core_options.map(core=>{
            if (Object.prototype.hasOwnProperty.call(core.attributes, 'supplements')){
              core.attributes.supplements.map(supplement=>{
                Object.assign(supplement,supplement.attributes)
              })
            }
            Object.assign(core,core.attributes)
          })
        }
        Object.assign(target, target.attributes)
        if (Object.prototype.hasOwnProperty.call(target, 'modules')) {

          target.modules.forEach(module => {
            Object.assign(module, module.attributes)

          });
        }
        if (Object.prototype.hasOwnProperty.call(target, 'intakes')) {

          target.intakes.forEach(intake => {
            Object.assign(intake, intake.attributes)
          });
        }
        if (Object.prototype.hasOwnProperty.call(target, 'qualification')) {
          if(target.qualification) {
            Object.assign(target.qualification, target.qualification.attributes)
          }

        }

        if (Object.prototype.hasOwnProperty.call(target, 'related_courses')) {

          target.related_courses.forEach(course => {
            Object.assign(course, course.attributes)
          });
        }
        return target
      }
    })
  }

}
