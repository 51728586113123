import Router from '@/router';
import Store from '@/store';
import {
  ACCESS_TOKEN
} from '@/config'
import {
  AUTH_ROUTES

} from '@/config';
import {
  AuthClient
} from "@/clients/AuthClient";
import Permission from "../models/Permission";


export default Router.beforeEach((to, from, next) => {
  Store.watch((state) => {
    if (state.auth.authenticated) {
      next()
    } else {

      if (AUTH_ROUTES.includes(to.name)) {

        next()
      } else {
        if (ACCESS_TOKEN) {
          AuthClient.check()
            .then(response => {

              let permissionData = []
              let isLearner = false
              response.data.data.attributes.permissions.forEach(permission => {
                if (permission.attributes.name==='view frontend') {
                  isLearner = true
                }
                permissionData.push({
                  id: permission.id,
                  name: permission.attributes.name
                })
              })
              Permission.insert({
                data: permissionData
              })



              Store.commit("user", {
                name: response.data.data.attributes.first_name,
                email: response.data.data.attributes.username,
                avatar: "/data-sources/avatars/annie-spratt-121576-unsplash.jpg",
                id: response.data.data.id,
                isLearner:isLearner
              });

              Store.dispatch("auth/updateAuthenticated", true);
              next()
            })
            .catch(() => {
              Store.dispatch("auth/updateAuthenticated", false);
              next('/login')
            });
        } else {
          next('/login')
        }
      }
    }

  });
});
