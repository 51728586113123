<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="has-background-white box is-relative card box" @submit.prevent="handleSubmit(submit)">
      <title-bar class="card-header"><p class="card-header-title">Modules</p></title-bar>
      <div class="card-content">
        <div>
          <p class="is-size-5 title has-text-underlined"> Selecting your modules
          </p>
          <p class="is-size-5">

            You will see the total credit value for the programme, total credits completed if applicable and the
            total credit value for the modules selected below</p>
          <ul class="pl-4 is-size-5" style="list-style-type: disc">

            <li>
              First indicate the module choice by clicking the check box
            </li>
            <li>
              Then select the semester for which you would like to take this module in
            </li>
            <li>
              If you change your mind at any time in this step, you can unselect by changing the semester or changing
              the checked status
            </li>
            <li>
              Review the chosen list of modules and semester to confirm all is in order
            </li>

            <li>
              Submit your modules by clicking the ‘Finish’ button at the bottom of the page
            </li>
          </ul>


        </div>


        <label class="label">Modules</label>
<!--        <ValidationProvider v-slot="{ errors }"-->
<!--                            :rules="`min_credits:${totalCredits},${registration.course.total_credits}`"-->
<!--                            :skipIfEmpty="false">-->
<!--          <b-field-->
<!--            label="Credits Needed"-->
<!--            :message="errors.length>0?errors[0]:''"-->
<!--            :type="errors.length>0?'is-danger':''"-->
<!--          >-->
<!--            <b-input type="number" :value="registration.course.total_credits" disabled></b-input>-->
<!--          </b-field>-->
<!--        </ValidationProvider>-->
        <b-table
          :bordered="true"
          :data="availableModules"
          :header-checkable="false"
          :hoverable="true"
          :striped="true"
          class="margin-top"
        >
          <b-table-column v-slot="props"
                          field="code"
                          label="Code"
                          numeric

                          width="40"
          >{{ props.row.code }}
          </b-table-column>

          <b-table-column v-slot="props" field="name" label="Name" >{{ props.row.name }}</b-table-column>
          <b-table-column v-slot="props" field="level" label="Level (year)" >{{ props.row.level }}</b-table-column>
          <!--          <b-table-column v-slot="props"-->
          <!--                          field="subject.name"-->
          <!--                          label="Subject"-->
          <!--                          sortable-->
          <!--          >{{ props.row.subject? props.row.subject.name:'' }}-->
          <!--          </b-table-column>-->
          <b-table-column v-slot="props" field="standardised_level_id" label="Standardised Level" >{{
              props.row.standardised_level_id ? $store.state.levels.levels.find(level => level.id === props.row.standardised_level_id).name : 'None'
            }}
          </b-table-column>

          <b-table-column v-slot="props"


                          label="Semester"
          >

            <b-field v-if="props.row.semesters.length>1">
              <b-select :disabled="!selectedModuleIds.includes(props.row.id)"
                        :required="selectedModuleIds.includes(props.row.id)"
                        :value="selectedSemester(props.row)"
                        placeholder="Semester"
                        @input="selectSemester($event,props.row)">
                <option v-for="semester in props.row.semesters" :key="semester.id+'_'+props.row.id" :value="semester.id">
                  {{ semester.name }}
                </option>
              </b-select>
            </b-field>
            <b-field v-else> {{ props.row.semesters[0] ? props.row.semesters[0].name : '' }}</b-field>
          </b-table-column>
          <b-table-column
            field="credits"
            label="Credits |:"
            numeric

          >
            <template v-slot:header="{column}"
            >{{ column.label }} {{ totalCredits }}
            </template
            >
            <template v-slot="props">{{ props.row.credits }}</template>
          </b-table-column>

          <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
            <b-tooltip :active="previouslySelectedModules.includes(parseInt(props.row.id))" label="You have already completed this module"
                       multilined
                       position="is-left">
              <b-checkbox
                v-model="selectedModuleIds"
                :disabled="previouslySelectedModules.includes(parseInt(props.row.id))"
                :native-value="props.row.id"
                @click.native.prevent="checkLevel($event,props.row)"
              ></b-checkbox>
            </b-tooltip>

          </b-table-column>
        </b-table>
        <label v-if="supplements.length>0"   class="label">Supplementary Modules</label>

        <div class="core-option-list-item p-2" v-for="supplementOption in supplements">
          <p
            :key="supplementOption.core_name + 'title'" class="title is-4">
            {{ supplementOption.core_name }}
          </p>
          <ValidationProvider v-slot="{ errors }"
                              :rules="`min_credits:${supplementTotalCredits(supplementOption)},${supplementOption.credits_deficit}`"
                              :skipIfEmpty="false">
            <p class="is-size-5">
              Select modules to make up the total credits needed.
            </p>
            <b-field
              label="Total Credits Needed"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''"
            >

              <b-input class="un-input" :value="supplementOption.credits_deficit" disabled></b-input>
            </b-field>
          </ValidationProvider>
          <b-table
            :key="supplementOption.core_name"
            :bordered="true"
            :data="supplementOption.modules"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props"
                            field="code"
                            label="Code"
                            numeric
                            sortable
                            width="40"
            >{{ props.row.code }}
            </b-table-column>

            <b-table-column v-slot="props" field="name" label="Name" sortable>{{ props.row.name }}</b-table-column>
            <b-table-column v-slot="props" field="level" label="Level (year)" sortable>{{ props.row.level }}</b-table-column>
            <!--          <b-table-column v-slot="props"-->
            <!--                          field="subject.name"-->
            <!--                          label="Subject"-->
            <!--                          sortable-->
            <!--          >{{ props.row.subject? props.row.subject.name:'' }}-->
            <!--          </b-table-column>-->
            <b-table-column v-slot="props" field="standardised_level_id" label="Standardised Level" sortable>{{
                props.row.standardised_level_id ? $store.state.levels.levels.find(level => level.id === props.row.standardised_level_id).name : 'None'
              }}
            </b-table-column>

            <b-table-column v-slot="props"


                            label="Semester"
            >

              <b-field v-if="props.row.semesters.length>1">
                <b-select :disabled="!selectedModuleIds.includes(props.row.id)"
                          :required="selectedModuleIds.includes(props.row.id)"
                          :value="selectedSemester(props.row)"
                          placeholder="Semester"
                          @input="selectSemester($event,props.row)">
                  <option v-for="semester in filterSemester(props.row.semesters)" :key="semester.id+'_'+props.row.code+supplementOption.core_name" :value="semester.id">
                    {{ semester.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field v-else> {{ props.row.semesters[0] ? props.row.semesters[0].name : '' }}</b-field>
            </b-table-column>
            <b-table-column
              field="credits"
              label="Credits |:"
              numeric
              sortable
            >
              <template v-slot:header="{column}"
              >{{ column.label }} {{ supplementTotalCredits(supplementOption) }}
              </template
              >
              <template v-slot="props">{{ props.row.credits }}</template>
            </b-table-column>

            <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
              <b-tooltip :active="previouslySelectedModules.includes(parseInt(props.row.id))" label="You have already completed this module"
                         multilined
                         position="is-left">
                <b-checkbox

                  v-model="selectedModuleIds"
                  :disabled="previouslySelectedModules.includes(parseInt(props.row.id))"
                  :native-value="props.row.id"
                  @click.native.prevent="checkLevel($event,props.row)"
                ></b-checkbox>
              </b-tooltip>

            </b-table-column>
          </b-table>
        </div>


      </div>

      <footer class="card-footer">
        <div class="card-footer-item">
          <b-field grouped>

            <p class="control">
              <b-button type="is-secondary" @click="confirmSubmit(false)">Save and Continue Later</b-button>
            </p>
            <p class="control">
              <b-button ref="submitButton" :loading="loading" native-type="submit"
                        type="is-primary">Next
              </b-button>
            </p>
          </b-field>
        </div>
      </footer>
      <b-modal v-if="isSubmitModalActive" :active.sync="isSubmitModalActive" scroll="keep">

        <form class="modal-card" style="max-width: 100%" @submit.prevent="confirmSubmit">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Confirm Module Selection </p>
          </header>
          <section class="modal-card-body">
            <p>Please read your registration details and confirm that they are correct. Once you have submitted, your
              registration will be locked and sent for final approval.</p>
            <table class="table is-bordered is-striped mb-5">
              <tr>
                <td>Year of Study</td>
                <td>
                  {{ registration.level }}
                </td>
              </tr>

              <tr>
                <td>Programme</td>
                <td>
                  {{ registration.course.name }}
                </td>
              </tr>

              <tr v-for="(module,index) of selectedModules" :key="index">
                <td>{{ index === 0 ? 'Modules' : '' }}</td>
                <td>{{ module.name }}</td>
              </tr>
              <tr>
                <td>Delivery type</td>
                <td>
                  {{ registration.delivery_type_id === 1 ? 'Contact' : 'Online' }}
                </td>
              </tr>
              <tr>
                <td>Credits in Programme</td>
                <td>{{ registration.course.total_credits }}</td>
              </tr>
              <tr v-if="passedCredits>0">
                <td>Credits already achieved</td>
                <td>{{ passedCredits }}</td>
              </tr>
              <tr>
                <td>Credits in Selected Modules</td>
                <td>{{ totalCredits }}</td>
              </tr>
              <tr>
                <td>Year of entry</td>
                <td>
                  {{ registration.year }}
                </td>
              </tr>
              <tr>
                <td>Semester of entry</td>
                <td>
                  {{ registration.semester }}
                </td>
              </tr>
            </table>
            <b-checkbox v-model="readDeclaration" class="has-text-weight-bold" required>I have hereby read and agree to
              the following declaration:
            </b-checkbox>
            <p class="is-size-7">
              “I, {{ learner.first_name }} {{ learner.last_name }}, {{ learner.student_number }}, am fully aware that
              the programme I have enrolled in, that is, the {{ registration.course.name }} with SAQA ID:
              {{ registration.course.saqa_number }}, is registered with the Department of Higher Education and Training
              to Cornerstone Institute, as indicated on the registration certificate dated 07/10/2022"
            </p>
            <b-checkbox v-model="readPaymentDeclaration" class="has-text-weight-bold" required>I understand that all fees paid are non-refundable.
            </b-checkbox>

          </section>
          <footer class="modal-card-foot">
            <b-field>
              <b-button type="is-primary" @click="isSubmitModalActive=false">
                Cancel
              </b-button>
              <b-button
                ref="submit"
                native-type="submit"
                type="is-success"
              >
                Submit
              </b-button>
            </b-field>
          </footer>
        </form>
      </b-modal>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>

    </form>
  </ValidationObserver>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import Learner from "@/models/Learner";
import Module from "@/models/Module";
import Registration from "@/models/Registration";
import SupplementOption from "@/models/SupplementOption";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";


extend("min_credits", {
  validate(value, args) {
    if (parseInt(args.deficit) > parseInt(args.current)) {
      return 'Insufficient Credits Selected'
    }
    return true
  },
  params: ['current', 'deficit']
});
export default {
  name: 'RegistrationModulesPanel',
  components: {
    ValidationObserver,
    ValidationProvider,
    TitleBar,
  },
  props: {
    registration_id: {
      type: String,
      required: true
    }, learner_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      readDeclaration: false,
      readPaymentDeclaration: false,
      loading: false,
      loadingModules: false,
      loadingModuleAdd: false,
      previouslySelectedModules: [],
      selectedModule: null,
      availableModules: [],
      supplementaryModules: [],
      selectedModuleIds: [],
      loadingLearners: false,
      selectedModules: [],
      isSubmitModalActive: false,
    };
  },

  computed: {
    onProbation() {
      return this.registration.grade_status_id != 1;
    },
    supplements() {
      return SupplementOption.query().withAllRecursive().get()
    },
    registration() {
      return Registration.query().whereId(this.registration_id).withAll().first()
    },
    learner() {
      return Learner.query().whereId(this.learner_id).first()
    },
    passedCredits() {
      let sum = 0
      this.availableModules.map(module => {
        if (this.selectedModules.includes(module.id)) {
          sum = sum + module.credits
        }
      })
      return sum
    },
    delivery_type: {
      get() {
        if (this.registration.delivery_type_id != null) {
          return this.registration.delivery_type_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.registration.delivery_type_id = parseInt(newValue);
      },
    },

    totalCredits() {
      let total = 0;
      Module.query().where('is_supplement', false).whereIdIn(this.selectedModuleIds).get().forEach((module) => {
        total += module.credits;
      });
      return total;
    },

  },
  async created() {
    this.loading = true
    await this.getModules();

    await Registration.fetchById(this.registration_id)
      .then((result) => {
        this.selectedModules = result.entities.hasOwnProperty("modules")
          ? JSON.parse(JSON.stringify(Registration.query().whereId(this.registration_id).withAllRecursive().first().modules))
          : [];

        this.selectedModuleIds = this.selectedModules.map(module => module.id)
      })
      .catch((err) => {
        this.handleError(err)
        this.loading = false
      });
    await Registration.fetchByUser(1, 999, this.$store.state.userId).then(() => {
      let previousRegistrations = Registration.query().where(registration => {
        return (registration.id !== this.registration_id) && ((+registration.registration_status_id ===3) || (+registration.registration_status_id===13))
      }).with('modules').with('modules.semesters').get()
      if (previousRegistrations.length > 0) {
        previousRegistrations.forEach(registration => registration.modules.forEach(module => {
          if (module.hasOwnProperty('status_id')) {
            if (module.status_id === 1 && module.is_passed !== 1) {
              this.previouslySelectedModules.push(parseInt(module.id))
            }
          }
        }))
      }

    })
    this.loading = false

  },
  methods: {
    filterSemesters(semesters) {
      return semesters.filter((sem) => {
        return new Date(sem.end_date) > new Date()
      });
    },
    supplementTotalCredits(core_option) {
      let sum = 0
      core_option.modules.map(module => {
        if (this.selectedModuleIds.includes(module.id)) {
          sum += module.credits
        }
      })
      return sum
    },
    selectSemester($event, analysedModule) {
      this.selectedModules[this.selectedModules.findIndex(module => module.id === analysedModule.id)].semester = $event
    },
    selectedSemester(analysedModule) {
      if (this.selectedModules.some(module => module.id === analysedModule.id)) {

        return this.selectedModules.find(module => module.id === analysedModule.id).semester
      }
      return null
    },
    displaySemesters(semesters) {
      if (semesters.length > 0) {
        return Object.values(semesters).join(", ");
      }
      return "";
    },
    checkLevel($evt, module) {
      if (this.disabled) {
        return;
      }
      if (this.previouslySelectedModules.includes(parseInt(module.id))) {
        return
      }
      if (this.selectedModules.some((item) => item.id === module.id)) {
        this.removeModule(module.id);
      } else {
        // before we select this module, validate
        if (this.onProbation) {
          if (this.canSelectOnProbation(module)) {
            this.selectModule(module)
          } else {
            // throw validation error here
            this.$buefy.dialog.alert({
              title: "Probation Module Credit Limit",
              message:
                "Your current registration has been marked for academic probation, and thus you are limited to a maximum of 40 credits.",
              type: "is-info",
            });
          }
        } else {
          this.selectModule(module);
        }

      }
    },

    canSelectOnProbation(module) {
      let creditCount = 0;
      this.selectedModules.forEach((m) => {
        creditCount += m.credits;
      })
      return creditCount + module.credits <= 40;
    },
    removeModule(module_id) {
      this.selectedModules = this.selectedModules.filter(
        (el) => el.id != module_id
      );
      this.selectedModuleIds = this.selectedModuleIds.filter(
        (el) => el != module_id
      );
    },
    confirmSelectModule(module) {
      if (module.semesters.length === 1) {
        module.semester = module.semesters[0].id

      }
      this.loadingModuleAdd = true;
      this.selectedModules.push(module);
      this.selectedModuleIds.push(module.id);
      this.loadingModuleAdd = false;
    },
    selectModule(module) {
      if (module) {
        if (module.level > this.registration.level) {
          this.$buefy.dialog.confirm({
            title: "Confirm Module Selection",
            message:
              "The level of this module is higher than the year you are registering for, are you sure you want to pick it?",
            confirmText: "Confirm Selection",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.confirmSelectModule(module),
          });
        } else {
          this.confirmSelectModule(module);
        }
      }
    },
    getModules() {
      this.loadingModules = true;
      Module.deleteAll()
      return Module.FetchAvailableForRegistration(1, 999, this.registration_id, ['subjects', 'semesters']).then(() => {
        this.loadingModules = false;
        this.availableModules = Module.query().with('semesters').where('is_supplement', false).orderBy('level').orderBy('name').get();
        this.supplementaryModules = Module.query().with('semesters').where('is_supplement', true).get();
      });
    },


    submit() {
      if (this.totalCredits > 0) {
        this.confirmSubmit(true)
      } else {
        this.$buefy.snackbar.open({
          message: "Please select at least one non-supplementary module.",
          type: "is-link",
          position: "is-top",
          actionText: "Ok",
        });
      }
    },

    confirmSubmit(goNext = true) {
      this.isSubmitModalActive = false
      let modules = []
      this.selectedModules.forEach((element) => {
        if (element.semester === null) {
          if (Module.query().whereId(element.id).with('semesters').first().semesters.length === 1) {
            element.semester = Module.query().whereId(element.id).with('semesters').first().semesters[0].id
          }
        }
        modules.push({module_id: parseInt(element.id), semester: parseInt(element.semester), status_id: 1});
      });
      // let submit_date = new Date(this.date);
      // this.registration.enrolment_date =
      //   submit_date.getFullYear() +
      //   "-" +
      //   (submit_date.getMonth() + 1) +
      //   "-" +
      //   submit_date.getDate();
      let submitObject = JSON.parse(JSON.stringify(this.registration))
      submitObject.modules = modules
      Registration.updateRemote(submitObject)
        .then(() => {
          if (goNext) {
            setTimeout(() => {
              this.$emit('success')
            }, 1000);
          }
          this.$buefy.snackbar.open({
            message: goNext ? "Modules Chosen!" : "Modules Saved!",
            queue: false,
            duration: 1000,
            type: "is-link",
            onAction: () => {
              if (goNext) {
                this.$emit('success')
              }
            },
          });
        })
        .catch((err) => {
          this.registration.registration_status_id = 1;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
};
</script>
