<template>
  <section class="section  is-fullheight fullpage-container">
    <div class="fullpage-content">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-two-fifths-widescreen is-three-fifths-desktop is-four-fifths-tablet">
            <router-view/>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FullPage',
  created () {
    this.$store.commit('fullPage', true)
    this.$store.commit('asideRightToggle', false)
  },
  beforeDestroy () {
    this.$store.commit('fullPage', false)
  }
}
</script>
