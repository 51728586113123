<template>
  <section class="section">
    <title-bar>Create New {{$tc('Learner',1)}} Profile</title-bar>
    <card-component :title="$tc('Learner',1)+' Form'" :icon="$tc('icons.learners')">
      <form @submit.prevent="submit">
        <b-field label="First Name" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              :icon="$tc('icons.learners')"
              v-model="learner.first_name"
              placeholder="First Name"
              name="first_name"
              required
            />
          </b-field>
        </b-field>
        <b-field label="Middle Name(s)" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              :icon="$tc('icons.learners')"
              v-model="learner.middle_name"
              placeholder="Middle Name(s)"
              name="middle_name"

            />
          </b-field>
        </b-field>       <b-field label="Last Name" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              :icon="$tc('icons.learners')"
              v-model="learner.last_name"
              placeholder="Last Name"
              name="last_name"
              required
            />
          </b-field>
        </b-field>
        <b-field :label="$tc('Username',1)" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              :icon="$tc('icons.emails')"
              v-model="learner.username"
              :placeholder="$tc('Username',1)"
              :name="$tc('username',1)"
            />
          </b-field>
        </b-field>
        <b-field label="LMS ID" horizontal>
          <b-field>
            <b-input

              autocomplete="chrome-off"
              icon="account"
              placeholder="LMS ID"
              name="lms_id"
              v-model="learner.lms_id"
            />
          </b-field>
        </b-field>

        <b-field horizontal :label="`${$tc('Learner',1)} Type`">
          <b-field>
            <b-select
              required
              v-model="learner.student_level"
              :placeholder="`Select the ${$tc('learner',1)} type`">
              <option :value="1">Undergraduate</option>
              <option :value="2">Postgraduate</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Identity type" horizontal>
          <b-field :type="{'is-danger':learner.identification_type_id===null}">
            <b-select
              icon="id-card"
              v-model="learner.identification_type_id"
              placeholder="Identity type"
            >
              <option
                v-for="identification_type in identification_types"
                :key="identification_type.id"
                :value="identification_type.id"
              >{{ identification_type.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Identification number" horizontal>
          <b-field>

                <b-input
                  required
                  autocomplete="off"
                  icon="id-card"
                  v-model="learner.id_number"
                  placeholder="ID number"
                />

          </b-field>
        </b-field>
        <b-field label="Email Address" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              :icon="$tc('icons.emails')"
              type="email"
              v-model="learner.email"
              placeholder="E-mail"
              name="email"
              required
            />
          </b-field>
        </b-field>
        <b-field label="Primary Contact Number" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              type="tel"
              v-model="learner.primary_contact_number"
              name="phone"
              :icon="$tc('icons.phone')"
              placeholder="072 555 5555"
              expanded
            />
          </b-field>
        </b-field>
        <b-field label="Secondary Contact Number" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              type="tel"
              v-model="learner.secondary_contact_number"
              name="phone"
              :icon="$tc('icons.phone')"
              placeholder="072 555 5555"
              expanded
            />
          </b-field>
        </b-field>
        <b-field :label="$tc('Cellphone',1)+' Number'" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              type="tel"
              v-model="learner.cellphone_number"
              name="phone"
              :icon="$tc('icons.phone')"
              placeholder="072 555 5555"
              expanded
            />
          </b-field>
        </b-field>
        <b-field label="Country" horizontal>
          <b-field expanded :type="{'is-danger':learner.physical_address.country===null}" >

            <b-select class="style-force" expanded
                      @input="((value)=>{getStates(value); learner.physical_address.country_id = getCountryId(value)})"
                      icon="flag" required v-model="learner.physical_address.country">
              <option v-for="country in countries" :value="country.name" :key="country.id">{{ country.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Province" horizontal>
          <b-field :type="{'is-danger':learner.physical_address.province===null}" >
            <b-select
              class="style-force"
              @input="value=> {getCities(value); learner.physical_address.province_id = getStateId(value,learner.physical_address.country_id);learner.physical_address.city_id = null;learner.physical_address.city =null}"
              v-model="learner.physical_address.province"
              v-if="selectedCountryStates(learner.physical_address.country_id).length>0">
              <option v-for="state in selectedCountryStates(learner.physical_address.country_id)"
                      :value="state.name" :key="state.id">{{ state.name }}
              </option>
            </b-select>
            <b-input v-else
                     @input="learner.physical_address.province_id = null;learner.physical_address.city_id = null"
                     autocomplete="chrome-off"
                     icon="map-marker"
                     v-model="learner.physical_address.province"
                     placeholder="Gauteng"
                     required
            />
          </b-field>
        </b-field>
        <b-field label="City" horizontal>
          <b-field :type="{'is-danger':learner.physical_address.city===null}" >
            <b-autocomplete
              v-if="learner.physical_address.province_id"
              @blur="validateAutocomplete(learner.physical_address.city_id,'learner.physical_address.city')"
              v-debounce:300ms="value=>{getFilteredCity(value,learner.physical_address.province_id);learner.physical_address.city_id=null}"
              :data="cities"
              icon="flag"
              v-model="learner.physical_address.city"
              ref="autocomplete_a"
              autocomplete="chrome-off"
              placeholder="City"
              name="City"
              required
              field="name"
              :clear-on-select="false"
              @select="option => {learner.physical_address.city=option.name;learner.physical_address.city_id=parseInt(option.id)}"
            >
              <template slot-scope="props">
                <p>{{ props.option.name }}</p>
              </template>
            </b-autocomplete>

            <b-input
              v-else
              autocomplete="chrome-off"
              icon="map-marker"
              v-model="learner.physical_address.city"
              placeholder="Johannesburg"
              required
            />
          </b-field>
        </b-field>
        <b-field label="Personal title" horizontal>
          <b-select v-model="learner.title" placeholder="Select a title">
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </b-select>
        </b-field>
        <hr />
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";

import Learner from "@/models/Learner";
import Lookup from "@/models/Lookup";
import Country from "@/models/Country";
import City from "@/models/City";
import State from "@/models/State";
import set from "lodash/set";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      learner: {
        physical_address: {
          city: null,
          province: null,
          country: null,
          country_id: null,
          city_id: null,
          province_id: null
        },
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        username: null,
        lms_id: null,
        student_level:null,
        primary_contact_number: "",
        secondary_contact_number: "",
        cellphone_number: "",
      },
    };
  },
  computed:{
    cities() {
      return City.query().orderBy('name').get()
    },
    countries() {
      return [Country.query().where('name','South Africa').first()??{},...Country.query().orderBy('name').get()]
    },
    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    },
  },
  mounted() {
    Lookup.fetchAll()
    Country.fetchAll({page: 1, limit: 999})

  },
  methods: {
    validateAutocomplete(source, target) {
      if (source == null) {
        set(this, target, null)
      }
    },
    getStateId(name, country_id) {

      return parseInt(State.query().where('country_id', country_id).where('name', name).first().id)
    },
    getCountryId(name) {
      return Country.query().where('name', name).first().id
    },
    getFilteredCity(text, state_id) {
      City.deleteAll()
      City.fetchAllPublic({page: 1, limit: 30}, {state_id: state_id, search: text})
    },
    selectedCountryStates(country_id) {
      if (country_id) {
        return State.query().where('country_id', country_id).orderBy('name').get()
      }
      return []
    },
    getCities(value) {
      this.$store.dispatch('loader/show')
      State.fetchAll({page: 1, limit: 5}, {name: value}, ['cities']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    }, getStates(value) {
      this.$store.dispatch('loader/show')
      Country.fetchAll({page: 1, limit: 5}, {name: value}, ['states']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    submit() {
      Learner.newLearner(this.learner)
        .then((result) => {
          setTimeout(() => {
            this.$router.push(`/learners/edit/${result.response.data.data.id}`);
          }, 1000);

          this.$buefy.snackbar.open({
            message: this.$tc("Learner", 1) + " Created!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
};
</script>
