import {
  TransformModel
} from '@/models/TransformModel'


export default class EnquiryMadeByStage extends TransformModel {
  static entity = 'EnquiriesMadeByStage'
  static primaryKey = 'name'
  static fields() {
    return {
      enquiries_count: this.attr(null),
      name: this.attr('')
    }
  }
  static fetchAll(start = null, end = null, owner_id = null, creator_id = null, status_id = null, filter = true, year = null, semester = null) {
    return this.api().get(`/reports/enquiries-stage`, {
      params: {
        filter: filter,
        start: start,
        end: end,
        ...(status_id ? {
          status_id: status_id
        } : {}),
        ...(owner_id ? {
          owner_id: owner_id
        } : {}),
        ...(creator_id ? {
          creator_id: creator_id
        } : {}),
        ...(year ? {
          year: year
        } : {}),
        ...(semester ? {
          semester: semester
        } : {})
      },

      dataTransformer: (response) => {

        response.data.data.attributes.data.push({
          enquiries_count: response.data.data.attributes.data.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.enquiries_count
          }, 0),
          name: 'Total',
          $id: 'Total'
        })
        return response.data.data.attributes.data

      }

    })
  }



}
