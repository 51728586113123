import {
  Line,
  mixins
} from 'vue-chartjs'

const {
  reactiveProp
} = mixins


export default {
  name: 'line-chart',
  extends: Line,
  mixins: [reactiveProp],
  props: ['options', 'chartData'],

  data() {
    return {
      ctx: null
    }
  },

  watch: {
    watch: {
      options() {

        this.$data._chart.destroy();
        this.renderChart(this.chartData, this.options);
      },
      chartData() {

        this.$data._chart.destroy();
        this.renderChart(this.chartData, this.options);
      }

    }
  },
  mounted() {

    this.renderChart(this.chartData, this.options)
    this.$watch('chartData', (newVal, oldVal) => {
      if (!oldVal) {
        this.renderChart(
          this.chartData,
          this.options
        )
      }
    }, {
      immediate: true
    })
    this.$watch('options', (newVal, oldVal) => {
      this.$data._chart.destroy();

      this.renderChart(
        this.chartData,
        this.options
      )

    }, {
      immediate: true
    })


  }
}
