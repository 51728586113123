import Store from '@/store';

export default {
  methods:
    {
      handleError(error) {
        if (Object.prototype.hasOwnProperty.call(error.response.data, 'errors')
        ) {
          let errors = ''
          for (let type in error.response.data.errors) {
            errors += `<li><span class="is-capitalized">${type}</span>: ${error.response.data.errors[type]}</li>`
          }
          Store.dispatch('toast/createToast', {message: `<div class="content">Errors:<ul>${errors}</ul></div>`})

        } else {
          Store.dispatch('toast/createToast', {message: JSON.stringify(error.response.data)})
        }
      }
    }
}
