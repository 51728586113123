<template>
  <Lookup :lookup_type_id="14" :title="'Where Hear'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "WhereHear",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
