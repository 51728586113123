<template>
  <form @submit.prevent="submitEmail()" class="email-form">
    <div v-if="!is_bulk" class="columns is-mobile is-vcentered">
      <div class="column is-narrow">
        <p class="subtitle is-6">To:</p>
      </div>
      <div class="column">
        <b-field>
          <b-taginput v-if="!send_to_email"
                      v-debounce:300ms="getFilteredLearner"
                      :data="filteredLearners"
                      autocomplete
                      disabled
                      v-model="form.recipients"
                      placeholder="Recipients"
                      :loading="loadingLearners"
                      :clear-on-select="false"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
            <template slot="tag" slot-scope="tag">{{ tag.tag.first_name }} {{ tag.tag.last_name }}</template>
          </b-taginput>
          <b-taginput v-else
                      :data="filteredLearners"
                      autocomplete
                      disabled
                      v-model="form.recipients"
                      placeholder="Recipients"
                      :loading="loadingLearners"
                      :clear-on-select="false"
          >

            <template slot="tag" slot-scope="tag">{{ tag.tag.first_name }} {{ tag.tag.last_name }} |
              {{ tag.tag.email }}
            </template>
          </b-taginput>

        </b-field>
      </div>
    </div>
    <b-field v-if="is_bulk">
      <b-select
        required
        placeholder="Select the message type"
        v-model="form.type"
        label="Message type"
      >
        <option v-if="permissions.can_send_bulk_email" value="email">Email</option>
        <option v-if="permissions.can_send_bulk_sms" value="sms">SMS</option>
      </b-select>
    </b-field>
    <div v-if="form.type!='sms'||!is_bulk" class="columns is-mobile is-vcentered">
      <div class="column">
        <b-field>
          <b-input
            expanded
            autocomplete="off"
            v-model="form.subject"
            placeholder="Subject"
            name="subject"
            required
          />
        </b-field>
      </div>
    </div>

    <b-field v-if="form.type=='sms'">
      <b-field label="Message">
        <b-input required v-model="form.body" maxlength="9999" type="textarea"></b-input>
      </b-field>
    </b-field>
    <b-field v-if="form.type=='email'||!is_bulk">
      <b-field class="content">
        <ckeditor :editor="editor" v-model="form.body" :config="editorConfig"></ckeditor>
      </b-field>
    </b-field>

    <b-field>
      <b-field grouped>
        <p v-if="has_attachments" class="control">
          <b-upload multiple v-model="attachments">
            <a class="button is-primary">
              <b-icon icon="paperclip"></b-icon>
              <span>Attach</span>
            </a>
          </b-upload>
        </p>
        <div class="control">
          <b-button
            native-type="submit"
            :disabled="username_missing || form.body.length<=0"
            type="is-primary"
          >Send
          </b-button>
        </div>
        <div class="control" v-if="can_cancel">
          <b-button type="is-secondary" @click.prevent="cancelEmail()">Cancel</b-button>
        </div>
        <span
          v-if="username_missing"
          class="has-text-danger"
        >Please add an email address to your send from field to be able to send emails.</span>
      </b-field>
    </b-field>
    <div v-if="attachments.length>0 && has_attachments" class="upload-file-list column">
      <div class="notification">
        <div class="tags">
          <span v-for="(file, index) in attachments" :key="index" class="tag is-primary">
            {{ file.name }}
            <button
              class="delete is-small"
              type="button"
              @click="deleteAttachment(index)"
            ></button>
          </span>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="false" :active.sync="loadingEmail" :can-cancel="false"></b-loading>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/EditorConfig";
import Email from "@/models/Email";
import Learner from "@/models/Learner";
import checkExtension from "@/mixins/FileTypes";

export default {
  name: "EmailForm",
  props: {
    permissions: {
      type: Object,
      default: () => ({
        can_create: true,
      }),
    },
    can_cancel: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    send_to_email: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
    username_missing: {
      type: Boolean,
      default() {
        return false;
      },
    },
    filters: {type: Object, required: false},
    is_bulk: {
      type: Boolean,
      default() {
        return false;
      },
    },
    bulk_total: {type: Number, required: false},
    has_attachments: {
      type: Boolean,
      default() {
        return true;
      },
    },
    fetch_on_send: {
      type: Boolean,
      default() {
        return true;
      },
    },
    in_modal: {
      type: Boolean,
      default() {
        return false;
      },
    },
    reply_email: {
      type: Object,
      default() {
        return {};
      },
    },
    default_recipient: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      loadingEmail: false,
      loadingLearners: false,
      filteredLearners: [],
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Message body"},
      },
      form: {
        recipients: [this.default_recipient],
        body: "",
        subject: "",
        type: null,
      },
      attachments: [],
    };
  },
  mounted() {
    if (this.reply_email.hasOwnProperty("body")) {
      this.form.subject = this.reply_email.subject;
    }
  },
  mixins: [checkExtension],


  watch: {
    attachments(val) {
      if (val.length > 0) {
        let index = val.length - 1
        let file = val[index]
        if (file !== null) {
          if (!this.checkExtension(file)) {
            this.attachments.pop()
          }
        }
      }
    },
    reply_email() {
      if (this.reply_email.hasOwnProperty("body")) {
        this.form.subject = this.reply_email.subject;
      }
    },
  },

  methods: {
    deleteAttachment(index) {
      this.attachments.splice(index, 1);
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        this.filteredLearners = result.response.data.data;
      });
    },
    cancelEmail() {
      this.form = {
        recipients: [this.default_recipient],

        subject: "",
        body: "",
        type: null,
      };
      this.attachments = [];
      this.$emit("cancel");
    },
    submitEmail() {
      if (this.permissions.can_create) {
        this.loadingEmail = true;
        var fd = new FormData();
        if (this.attachments.length > 0) {
          for (let x = 0; x < this.attachments.length; x++) {
            fd.append("attachments[]", this.attachments[x]);
          }
        }
        if (this.form.type == "sms") {
          this.subject = "sms";
        }
        fd.append("subject", this.form.subject);
        fd.append("body", this.form.body);
        if (!this.is_bulk && !this.send_to_email) {
          fd.append("to_user_id", this.form.recipients[0].id);
        }
        if (!this.is_bulk && this.send_to_email) {
          fd.append("to_user_email", this.form.recipients[0].email);
        }
        if (this.is_bulk) {
          fd.append("type", this.form.type);
        }
        if (!this.is_bulk) {
          Email.send(fd)
            .then(() => {
              this.$buefy.snackbar.open({
                message: "Email sent",
                queue: false,
              });
              this.cancelEmail();
              if (this.fetch_on_send) {
                if (this.send_to_email){
                  Email.fetch(null,null,null,null,null,this.default_recipient.email)
                    .then(() => {
                      this.loadingEmail = false;
                    })
                    .catch((err) => {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err.response.data),
                      });
                    });
                } else {
                  Email.fetch(this.default_recipient.id)
                    .then(() => {
                      this.loadingEmail = false;
                    })
                    .catch((err) => {
                      this.$store.dispatch("toast/createToast", {
                        message: JSON.stringify(err.response.data),
                      });
                    });
                }

              } else {
                this.$emit("sent");
                this.loadingEmail = false;
              }
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        } else if (this.is_bulk == true && this.form.type != null) {
          this.$buefy.dialog.confirm({
            title: "Send Bulk Message",
            message: `Are you sure you want to send this message to ${
              this.bulk_total
            } recipient${this.bulk_total == 1 ? "" : "s"}?`,
            confirmText: "Send Bulk Message",
            type: "is-danger",
            hasIcon: true,
            onCancel: () => this.cancelEmail(),
            onConfirm: () => {
              this.$store.dispatch("loader/show");
              Email.bulkSend(fd, this.filters)
                .then(() => {
                  this.$store.dispatch("loader/hide");
                  this.cancelEmail();
                  this.$buefy.snackbar.open({
                    message: "Bulk message sent",
                    queue: false,
                    type: "is-link",
                  });
                })
                .catch((err) => {
                  this.$store.dispatch("loader/hide");
                  this.cancelEmail();
                  this.$store.dispatch("toast/createToast", {
                    message: JSON.stringify(err.response.data),
                  });
                });
            },
          });
        }
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
  },
};
</script>
