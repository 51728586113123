<template>
  <section class="section">
    <title-bar>Edit your registration</title-bar>
    <card-component title="Registration Form" icon="ballot">
      <form @submit.prevent="submit">
        <b-field horizontal>
          <div>
            <p class="is-size-5 title has-text-underlined"> Selecting your modules
            </p>
            <p>

              You will see the total credit value for the programme, total credits completed if applicable and the
              total credit value for the modules selected below</p>
            <ul style="list-style-type: disc" class="pl-4">
              <li>
                Select your year of study. This is the year you’re enrolling with Cornerstone for this programme
              </li>
              <li> Now you can select the modules offered in your chosen programme
                <ul class="pl-4" style="list-style-type: circle">
                  <li>
                    First indicate the module choice by clicking the check box
                  </li>
                  <li>
                    Then select the semester for which you would like to take this module in
                  </li>
                </ul>
              </li>
              <li>
                If you change your mind at any time in this step, you can unselect by changing the semester or changing
                the checked status
              </li>
              <li>
                Review the chosen list of modules, delivery type, year of entry and semester to confirm all is in order
              </li>
              <li>
                Make sure the total credit value are under the maximum allowed as per the programme registration
                guidelines
              </li>
              <li>
                Upload your Proof of payment for initial fee
              </li>
              <li>
                Submit your registration by clicking the ‘Finish’ button at the bottom of the page
              </li>
            </ul>


          </div>


        </b-field>
        <b-field label="Year of Study" horizontal>
          <b-select
            disabled
            required
            v-model="form.level"
            placeholder="Select the registration year"
          >
            <option v-for="level in 5" :key="level" :value="level">{{ level }}</option>
          </b-select>
        </b-field>
        <b-field :label="$tc('Course',1)" horizontal>
          <b-field>
            <b-autocomplete
              v-debounce:300ms="getFilteredCourse"
              :data="filteredCourses"
              icon="account"
              required
              disabled
              autocomplete="off"
              v-model="courseHold"
              :placeholder="$tc('Course',1) + ' Name'"
              name="Course"
              :loading="loadingCourses"
              field="name"
              :clear-on-select="false"
              @select="option => selectCourse(option)"
            >
              <template slot-scope="props">
                <p>{{ props.option.code }} | {{ props.option.name }}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>
        <b-field label="Modules" horizontal>
          <b-table
            :header-checkable="false"
            class="margin-top"
            :data="filteredModules"
            :striped="true"
            :hoverable="true"
            :bordered="true"
          >
            <b-table-column v-slot="props"
              label="Code"
              field="code"
              sortable
              width="40"
              numeric
            >{{ props.row.code }}
            </b-table-column>

            <b-table-column v-slot="props" sortable field="name" label="Name">{{ props.row.name }}</b-table-column>
            <b-table-column v-slot="props" sortable field="level" label="Level (year)">{{ props.row.level }}</b-table-column>
            <b-table-column v-slot="props"
              sortable
              field="subject.name"
              label="Subject"
            >{{ props.row.subject.name }}
            </b-table-column>
            <b-table-column v-slot="props" sortable field="standardised_level_id" label="Standardised Level">{{
                props.row.standardised_level_id ? $store.state.levels.levels.find(level => level.id === props.row.standardised_level_id).name : 'None'
              }}
            </b-table-column>

            <!--              <b-table-column v-slot="props" label="Semester">{{ displaySemesters(props.row.semesters) }}</b-table-column>-->
            <b-table-column v-slot="props"


              label="Semester"
            >

              <b-field v-if="props.row.semesters.length>1">
                <b-select @input="selectSemester($event,props.row)"
                          :required="!disabled||selectedModuleIds.includes(props.row.id)"
                          :disabled="disabled||!selectedModuleIds.includes(props.row.id)"
                          :value="selectedSemester(props.row)"
                          placeholder="Semester">
                  <option v-for="semester in props.row.semesters" :key="semester.id" :value="semester.id">
                    {{ semester.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field v-else> {{ props.row.semesters[0].name }}</b-field>
            </b-table-column>
            <b-table-column
              field="credits"
              sortable
              label="Credits |:"
              numeric
            >
              <template v-slot:header="{column}"
              >{{ column.label }} {{ totalCredits }}
              </template
              >
              <template v-slot="props" >{{ props.row.credits }}</template>
            </b-table-column>

            <b-table-column v-slot="props" width="120" class="has-text-centered" custom-key="actions">
              <b-tooltip multilined position="is-left"
                         :active="previouslySelectedModules.includes(parseInt(props.row.id))"
                         label="You have already completed this module">
                <b-checkbox
                  @click.native.prevent="checkLevel($event,props.row)"
                  v-model="selectedModuleIds"
                  :disabled="disabled || previouslySelectedModules.includes(parseInt(props.row.id))"
                  :native-value="props.row.id"
                ></b-checkbox>
              </b-tooltip>

            </b-table-column>
          </b-table>
        </b-field>
        <b-field v-if="form.course_id" horizontal label="Delivery Type">
          <b-select
            required
            :disabled="form.registration_status_id !=1"
            v-model="delivery_type"
            placeholder="Select a delivery type"
          >
            <option
              v-if="form.course.delivery_type_id == 1 || form.course.delivery_type_id == 3"
              value="1"
            >Contact
            </option>
            <option
              v-if="form.course.delivery_type_id == 2 || form.course.delivery_type_id == 3"
              value="2"
            >Online
            </option>
          </b-select>
        </b-field>
        <b-field label="Year of Entry" horizontal>
          <b-select
            :disabled="form.registration_status_id !=1"
            v-model="form.year"
            placeholder="Select the year"
            required
          >
            <option
              v-for="number in 2"
              :key="number + 'year'"
              :value="(new Date().getFullYear()-1)+number"
            >{{ (new Date().getFullYear() - 1) + number }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Semester of Entry" horizontal>
          <b-select
            :disabled="form.registration_status_id !=1"
            v-model="form.semester"
            placeholder="Select the semester"
          >
            <option
              v-for="semester in $store.getters['semesters/available_semesters']"
              :key="semester"
              :value="semester"
            >{{ semester }}
            </option>
          </b-select>
        </b-field>

        <hr/>

        <card-component title="Supporting Documents" icon="cloud-upload">
          <div class="content">
            <p class="is-size-4">
              Required Documents:
            </p>
            <ul>
              <li>
                Proof of payment for initial fee
              </li>

            </ul>
          </div>
          <div class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload :disabled="disabled" v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon icon="cloud-upload" custom-size="default"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field label="Caption">
                            <b-input
                              v-model="dropFile.caption"
                              placeholder="Describe the document"
                              name="caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-field>
                            <b-select v-model="tagHold" placeholder="Select a tag" required>
                              <option v-for="(tag,index) in $store.state.applications.tags" :value="tag" :key="index">
                                {{ tag }}
                              </option>
                            </b-select>
                            {{ dropFile.tag }}
                            <b-input v-if="(!tags.includes(tagHold)||tagHold==='Other') && tagHold!==null"
                                     placeholder="Your tag" required>

                            </b-input>
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            class="margin-top"
            :data="media"
            :striped="true"
            :hoverable="true"
            :bordered="true"
          >
            <b-table-column v-slot="props" sortable field="filename" label="Filename">{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props"
              sortable
              field="caption"
              label="Caption"
            >{{ return_caption(props.row.caption) }}
            </b-table-column>

            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      tag="a"
                      :href="props.row.temporary_url"
                      type="is-info"
                      target="_blank"
                    >View
                    </b-button>
                  </span>
                <span class="control">
                    <b-button
                      @click="start_delete_media(props.row.id)"
                      :disabled="disabled"
                      type="is-danger"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>

          </b-table>
        </card-component>
        <b-loading :is-full-page="false" :active.sync="updatingMedia" :can-cancel="false"></b-loading>
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button
                native-type="submit"
                :disabled="disabled || dropFile || !proofOfPaymentUploaded"
                type="is-primary"
              >Finish
              </b-button>
            </div>
          </b-field>
        </b-field>
      </form>
      <b-modal :active.sync="isSubmitModalActive" v-if="isSubmitModalActive" scroll="keep">

        <form @submit.prevent="confirmSubmit" class="modal-card" style="max-width: 100%">
          <header class="modal-card-head">
            <p class="modal-card-title">
              Complete Registration </p>
          </header>
          <section class="modal-card-body">
            <p>Please read your registration details and confirm that they are correct. Once you have submitted, your
              registration will be locked and sent for final approval.</p>
            <table class="table is-bordered is-striped mb-5">
              <tr>
                <td>Year of Study</td>
                <td>
                  {{ form.level }}
                </td>
              </tr>

              <tr>
                <td>Programme</td>
                <td>
                  {{ registration.course.name }}
                </td>
              </tr>

              <tr v-for="(module,index) of selectedModules">
                <td>{{ index === 0 ? 'Modules' : '' }}</td>
                <td>{{ module.name }}</td>
              </tr>
              <tr>
                <td>Delivery type</td>
                <td>
                  {{ form.delivery_type_id === 1 ? 'Contact' : 'Online' }}
                </td>
              </tr>
              <tr>
                <td>Credits in Programme</td>
                <td>{{ registration.course.total_credits }}</td>
              </tr>
              <tr v-if="passedCredits>0">
                <td>Credits already achieved</td>
                <td>{{ passedCredits }}</td>
              </tr>
              <tr>
                <td>Credits in Selected Modules</td>
                <td>{{ totalCredits }}</td>
              </tr>
              <tr>
                <td>Year of entry</td>
                <td>
                  {{ form.year }}
                </td>
              </tr>
              <tr>
                <td>Semester of entry</td>
                <td>
                  {{ form.semester }}
                </td>
              </tr>
            </table>
            <b-checkbox required v-model="readDeclaration" class="has-text-weight-bold">I have hereby read and agree to
              the following declaration:
            </b-checkbox>
            <p class="is-size-7">
              “I, {{ learner.first_name }} {{ learner.last_name }}, {{ learner.student_number }}, am fully aware that
              the programme I have enrolled in, that is, the {{ registration.course.name }} with SAQA ID:
              {{ registration.course.saqa_number }}, is registered with the Department of Higher Education and Training
              to Cornerstone Institute, as indicated on the registration certificate dated 23 May 2019"
            </p>
          </section>
          <footer class="modal-card-foot">
            <b-field>
              <b-button @click="isSubmitModalActive=false" type="is-primary">
                Cancel
              </b-button>
              <b-button
                native-type="submit"
                type="is-success"
              >
                Submit
              </b-button>
            </b-field>
          </footer>
        </form>
      </b-modal>

    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";

import Course from "@/models/Course";
import Learner from "@/models/Learner";
import Module from "@/models/Module";
import Media from "@/models/Media";
import Registration from "@/models/Registration";
import checkExtension from "@/mixins/FileTypes";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  props: ["id"],
  data() {
    return {
      readDeclaration: false,
      course: null,
      loaded: false,
      tagHold: null,
      tags: ["Proof of payment", 'Other'],
      dropFile: null,
      updatingMedia: false,
      loadingModules: false,
      loadingModuleAdd: false,
      previouslySelectedModules: [],
      selectedModule: null,
      selectedLearner: {name: ""},
      filteredLearners: [],
      filteredCourses: [],
      filteredModules: [],
      selectedModuleIds: [],
      loadingCourses: false,
      courseHold: "",
      loadingLearners: false,
      selectedModules: [],
      isSubmitModalActive: false,
      date: null,
      form: {
        id: null,
        user_id: null,
        level: null,
        year: null,
        semester: null,
        course_id: null,
        modules: null,
        enrolment_date: null,
        registration_status_id: 2,
        stage_id: 1,
        delivery_type_id: "",
      },
    };
  },
  mixins: [checkExtension],

  watch: {
    dropFile(val) {
      if (val !== null) {
        if (!this.checkExtension(val)) {
          this.dropFile = null
        }
      }
    }
  },
  computed: {
    isReturningStudent() {
      return Registration.query().where('course_id', this.registration.course_id).where('registration_status_id', (value => {
        return this.$store.state.statuses.STATUSES_REGISTERED.includes(parseInt(value))
      })).count() > 0
    },
    learner() {
      return Learner.query().get()[0]
    },
    passedCredits() {
      let sum = 0
      this.filteredModules.map(module => {
        if (this.selectedModules.includes(module.id)) {
          sum = sum + module.credits
        }
      })
      return sum
    },
    delivery_type: {
      get() {
        if (this.form.delivery_type_id != null) {
          return this.form.delivery_type_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.form.delivery_type_id = parseInt(newValue);
      },
    },
    disabled() {
      return (
        this.form.registration_status_id == 2 ||
        this.form.registration_status_id == 3 ||
        this.form.registration_status_id == 4 ||
        this.form.registration_status_id == 8
      );
    },
    totalCredits() {
      let total = 0;
      this.selectedModules.forEach((module) => {
        total += module.credits;
      });
      return total;
    },
    enrolment_date: {
      set(newValue) {
        this.date = new Date(newValue);
      },
      get() {
        return new Date(this.date);
      },
    },
    registration() {
      return Registration.query()
        .with("user")
        .with("course")
        .with("stage")
        .with("modules")
        .with("modules.semesters")
        .where("id", this.id)
        .get()[0];
    },
    levelWatch() {
      return this.form.level;
    },
    media() {
      return Media.all();
    },
    proofOfPaymentUploaded() {
      return Media.query().where('tag', 'Proof of payment').count() > 0
    }
  },
  mounted() {
    Media.deleteAll();
    this.updatingMedia = true;
    this.$store.dispatch("loader/show");
    Learner.deleteAll()
    Learner.fetchById(parseInt(this.$store.state.userId))
    Registration.fetchById(this.id)
      .then((result) => {
        this.form = this.registration;

        this.courseHold =
          this.registration.course != null ? this.registration.course.name : "";

        this.date = this.registration.enrolment_date;
        this.getFilteredModule("", 999);
        this.selectedModules = result.entities.hasOwnProperty("modules")
          ? result.entities.modules
          : [];

        this.selectedModules.forEach((element) => {
          this.selectedModuleIds.push(element.id);
        });
        this.loaded = true;

        this.$store.dispatch("loader/hide");
      })
      .catch((err) => {
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      });
    Registration.fetchByUser(1, 999, this.$store.state.userId).then(() => {
      let previousRegistrations = Registration.query().where(registration => {
        return registration.id != this.id
      }).with('modules').with('modules.semesters').get()
      if (previousRegistrations.length > 0) {
        previousRegistrations.forEach(registration => registration.modules.forEach(module => {
          if (module.hasOwnProperty('status_id')) {
            if (module.status_id == 1 && module.is_passed !== 1) {
              this.previouslySelectedModules.push(parseInt(module.id))
            }
          }
        }))
      }

    })

    Media.fetchAll(this.id, "registrations")
      .then(() => {
        this.updatingMedia = false;
      })
      .catch((err) => {
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      });
  },
  methods: {
    selectSemester($event, analysedModule) {
      this.selectedModules[this.selectedModules.findIndex(module => module.id == analysedModule.id)].semester = $event
    },
    selectedSemester(analysedModule) {
      if (this.selectedModules.some(module => module.id == analysedModule.id)) {

        return this.selectedModules.find(module => module.id == analysedModule.id).semester
      }
      return null
    },
    displaySemesters(semesters) {
      if (semesters.length > 0) {
        return Object.values(semesters).join(", ");
      }
      return "";
    },
    checkLevel($evt, module) {
      if (this.disabled) {
        return;
      }
      if (this.previouslySelectedModules.includes(parseInt(module.id))) {
        return
      }
      if (this.selectedModules.some((item) => item.id == module.id)) {
        this.removeModule(module.id);
      } else {
        this.selectModule(module);
      }
    },
    submitMedia() {
      this.updatingMedia = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("tag", this.tagHold);
      formData.append("caption", this.dropFile.caption);
      this.tagHold = null

      Media.uploadToRegistration(formData, this.form.id)
        .then(() => {
          Media.fetchAll(this.form.id, "registrations")
            .then(() => {
              this.dropFile = null;
              this.updatingMedia = false;
            })
            .catch(() => {
              this.updatingMedia = false;
              this.$store.dispatch("toast/createToast", {
                message: "Upload failed",
              });
            });
        })
        .catch((err) => {
          this.updatingMedia = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
    return_caption(caption) {
      if (caption == "undefined") {
        return "None";
      } else {
        return caption;
      }
    },
    deleteMedia(mediaId) {
      this.updatingMedia = true;
      Media.deleteRemote(mediaId)
        .then(() => {
          Media.delete(mediaId);
          Media.fetchAll(this.form.id, "registrations")
            .then(() => {
              this.updatingMedia = false;
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete_media(mediaId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Media",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Media",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteMedia(mediaId),
      });

    },
    removeModule(module_id) {
      this.selectedModules = this.selectedModules.filter(
        (el) => el.id != module_id
      );
      this.selectedModuleIds = this.selectedModuleIds.filter(
        (el) => el != module_id
      );
    },
    confirmSelectModule(module) {
      if (module.semesters.length === 1) {
        module.semester = module.semesters[0].id

      }
      this.loadingModuleAdd = true;
      this.selectedModules.push(module);
      this.selectedModuleIds.push(module.id);
      this.loadingModuleAdd = false;
    },
    selectModule(module) {
      if (module) {
        if (module.level > this.form.level) {
          this.$buefy.dialog.confirm({
            title: "Confirm Module Selection",
            message:
              "The level of this module is higher than the year you are registering for, are you sure you want to pick it?",
            confirmText: "Confirm Selection",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.confirmSelectModule(module),
          });
        } else {
          this.confirmSelectModule(module);
        }
      }
    },
    getFilteredModule(text, limit = 45) {
      this.loadingModules = true;
      Module.deleteAll()
      return Module.search(
        text,
        true,
        1,
        limit,
        this.form.course_id,
        null,
        this.$store.getters['semesters/available_semesters'].length>1? null:2

      ).then(() => {
        this.loadingModules = false;
        this.filteredModules = Module.query().with('semesters').get();
      });
    },
    selectCourse(course) {
      this.selectedModules = [];
      this.selectedModuleIds = [];
      this.loadingCourses = true;

      this.form.course_id = course.id;
      this.form.course = course;
      Course.fetchById(course.id)
        .then(() => {
          this.getFilteredModule("", 999).then(() => {
            this.loadingCourses = false;
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    selectLearner(learner) {
      this.form.user_id = learner.id;
      this.selectedLearner.name = learner.first_name + " " + learner.last_name;
    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.search(text, true, 1).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.filteredCourses = result.entities.courses;
        } else {
          this.filteredCourses = [];
        }
      });
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.filteredLearners = result.entities.learners;
        } else {
          this.filteredLearners = [];
        }
      });
    },
    submit() {
      if (this.selectedModuleIds.length > 0) {
        this.isSubmitModalActive = true
      } else {
        this.$buefy.snackbar.open({
          message: "Please select at least one module.",
          type: "is-link",
          position: "is-top",
          actionText: "Ok",
        });
      }
    },
    confirmSubmit() {
      this.isSubmitModalActive = false

      this.form.modules = [];
      this.selectedModules.forEach((element) => {
        if (element.semester === null) {
          if (Module.query().whereId(element.id).with('semesters').first().semesters.length == 1) {
            element.semester = Module.query().whereId(element.id).with('semesters').first().semesters[0].id
          }
        }
        this.form.modules.push({module_id: parseInt(element.id), semester: parseInt(element.semester), status_id: 1});
      });
      // let submit_date = new Date(this.date);
      // this.form.enrolment_date =
      //   submit_date.getFullYear() +
      //   "-" +
      //   (submit_date.getMonth() + 1) +
      //   "-" +
      //   submit_date.getDate();
      this.form.user_id = this.$store.state.userId;
      this.form.registration_status_id = 2;
      Registration.updateRemote(this.form)
        .then(() => {
          setTimeout(() => {
            this.$router.push("/registrations/mine");
          }, 5000);
          this.$buefy.snackbar.open({
            message: "Registration Updated!",
            queue: false,
            duration: 5000,
            type: "is-link",
            onAction: () => {
              this.$router.push(`/registrations/mine`);
            },
          });
        })
        .catch((err) => {
          this.form.registration_status_id = 1;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
};
</script>
