<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="submit">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Languages</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <p class="is-size-5 pb-2">Languages you have conversational proficiency in.</p>
          <label  class="label">Language</label>
          <div class="columns is-multiline">
            <div v-for="language in languages" :key="language.id" class="column">
              <b-field >
                <b-checkbox v-model="selected_language_ids" :disabled="!canEdit" :native-value="language.id"
                            :required="language_required">{{ language.name }}
                </b-checkbox>
              </b-field>
            </div>
          </div>
          <hr>
          <b-field v-if="selected_language_ids.length>0" label="How Did You Acquire Proficiency In This Language?">
            <div v-for="(user_language,index) in user_languages_hold" :key="user_language.id">
              <h3 class="title is-5">{{ user_language.language.name }}</h3>
              <b-field grouped>

                <b-field label="Method">
                  <b-select :disabled="!canEdit" v-model="user_language.acquired_type_id" :required=true expanded placeholder="Method of Acquisition">
                    <option v-for="method in methods" :key="method.id" :value="method.id">
                      {{ method.name }}
                    </option>
                  </b-select>
                </b-field>
                <template v-if="user_language.acquired_type_id!==null">
                  <b-field v-if="user_language.acquired_type_id!==2" :label="user_language.acquired_type_id===1?'Matric Mark':'Module Mark'">
                    <b-input :disabled="!canEdit" v-model="user_language.grade" :min="0" placeholder="Mark" required type="number">

                    </b-input>
                  </b-field>
                  <b-field v-if="(user_language.acquired_type_id!==2)&&(user_language.acquired_type_id!==1)" :label="user_language.acquired_type_id===3?'Module Code':'Course Name'">
                    <b-input :disabled="!canEdit" v-model="user_language.code" :placeholder="user_language.acquired_type_id===3?'Module Code':'Course Name'" required></b-input>
                  </b-field>
                  <b-field v-if="(user_language.acquired_type_id!==2)&&(user_language.acquired_type_id!==1)" label="Institution Name">
                    <b-input :disabled="!canEdit" v-model="user_language.institution" placeholder="Institution Name" required></b-input>
                  </b-field>
                </template>
              </b-field>
              <hr v-if="index!==user_languages_hold.length-1"/>
            </div>
          </b-field>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>

              <p class="control">
                <b-button :disabled="invalid ||!canEdit" :loading="loading" native-type="submit"
                          type="is-primary">{{ edit ? 'Next' : 'Save' }}
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Lookup from "@/models/Lookup";
import UserLanguage from "@/models/UserLanguage";
import CourseLanguages from "@/models/CourseLanguages";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "Languages",
  components: {
    TitleBar,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      selected_language_ids: [],
      user_languages_hold: [],
      methods: [
        {id: 1, name: 'Subject in Matric'}, {id: 2, name: 'Home Language'}, {id: 3, name: 'University Module'}, {id: 4, name: 'Short Course'}
      ],

    }
  },
  watch: {
    selected_language_ids(newVal) {
      let newLangs = []
      newVal.map(language_id => {
        if (!UserLanguage.query().whereId(language_id).exists()) {
          newLangs.push({id: language_id, language_id: language_id, user_id: this.learner_id})
        }
      })
      UserLanguage.insert({data: newLangs}).then(() => {
        newVal.map(language_id => {
          if (!this.user_languages_hold.some(user_lang => {
            return user_lang.id === language_id
          })) {
            this.user_languages_hold.push(JSON.parse(JSON.stringify(UserLanguage.query().whereId(language_id).withAll().first())))
          }
        })
        this.user_languages_hold = this.user_languages_hold.filter(item => {
          return this.selected_language_ids.includes(item.id)
        })

      })
    },
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('loader/show')
        await UserLanguage.Update({
          languages: this.user_languages_hold.map(item => {
            item.language_id = item.id
            item.user_id = this.learner_id
            return item
          })
        }, this.learner_id)
        await this.$store.dispatch('loader/hide')

        this.$emit('next', 'languages')
      } catch (err) {
        this.handleError(err)
        await this.$store.dispatch('loader/hide')

      }


    }
  },

  computed: {
    languages(){
      return CourseLanguages.query().get()
    },
    language_required() {
      return false
    },
    language_disabled() {
      return false
    },
    institutions() {

      return Lookup.query().where("lookup_type_id", 9).get();
    },
    qualifications() {
      return Lookup.query().where("lookup_type_id", 19).orderBy('name').get();    },


  },
  async created() {
    this.loading = true
    await Promise.all([UserLanguage.deleteAll(),CourseLanguages.deleteAll()])
    await Promise.all([Lookup.fetchAll({lookup_type_id: [5]}), UserLanguage.FetchAll(1, 999, this.learner_id),
      CourseLanguages.FetchAll(1, 999, this.course.id),
    ])
    this.selected_language_ids = UserLanguage.query().get().map(lang => lang.id)

    this.loading = false
  },
  props: {

    learner_id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    course: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

