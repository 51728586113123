import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import {ACCESS_TOKEN, API_URL} from './config';
import each from 'lodash/each'
import {AuthModule} from '@/modules/AuthModule';
import {ToastModule} from '@/modules/ToastModule';
import {LoaderModule} from "@/modules/LoaderModule"
import {ChartModule} from "@/modules/ChartModule"
import {DateSelectModule} from "@/modules/DateSelectModule"
import {SemestersModule} from "@/modules/SemestersModule"
import {RegistrationModule} from "@/modules/RegistrationModule";
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import VuexORMSearch from "@vuex-orm/plugin-search";

import User from '@/models/User'
import Post from '@/models/Post'
import Enquiry from '@/models/Enquiry'
import Course from '@/models/Course'
import Permission from '@/models/Permission'
import Learner from '@/models/Learner'
import Stage from '@/models/Stage'
import Note from '@/models/Note'
import Profile from '@/models/Profile'
import Lookup from '@/models/Lookup'
import Role from '@/models/Role'
import RoleUser from '@/models/RoleUser'
import Media from '@/models/Media'
import Module from '@/models/Module'
import ModuleCourse from '@/models/ModuleCourse'
import RPL from '@/models/RPL'
import EnquiryMadeByUser from '@/models/reports/EnquiryMadeByUser'
import RegistrationMadeByUser from '@/models/reports/RegistrationMadeByUser'
import RegistrationMadeByCourse from '@/models/reports/RegistrationMadeByCourse'
import EnquiryMadeByUserOverTime from '@/models/reports/EnquiryMadeByUserOverTime'
import EnquiryWonByUser from '@/models/reports/EnquiryWonByUser'
import EnquiryWonByUserOverTime from '@/models/reports/EnquiryWonByUserOverTime'
import EnquiryLostByUser from '@/models/reports/EnquiryLostByUser'
import EnquiryLostByUserOverTime from '@/models/reports/EnquiryLostByUserOverTime'
import EnquiryMadeBySource from '@/models/reports/EnquiryMadeBySource'
import EnquiryMadeByStage from '@/models/reports/EnquiryMadeByStage'
import CourseInterest from '@/models/reports/CourseInterest'
import Registration from '@/models/Registration'
import ModuleRegistration from '@/models/ModuleRegistration'
import RegistrationStage from '@/models/RegistrationStage'
import RegistrationNote from '@/models/RegistrationNote'
import RegistrationMadeByStage from '@/models/reports/RegistrationMadeByStage';
import EnquiryMadeByStageByUser from '@/models/reports/EnquiryMadeByStageByUser';
import RegistrationMadeByStageByUser from '@/models/reports/RegistrationMadeByStageByUser';
import Activity from "@/models/Activity";
import Semester from '@/models/Semester';
import SemesterModule from '@/models/SemesterModule';
import Email from '@/models/Email';
import UserEmail from '@/models/UserEmail';
import Schedule from '@/models/Schedule';
import Grade from '@/models/Grade';
import PermissionRole from '@/models/PermissionRole'
import RegistrationMadeBySource from "@/models/reports/RegistrationMadeBySource";
import {StatusesModule} from "@/modules/StatusesModule";
import City from "@/models/City";
import State from "@/models/State";
import Country from "@/models/Country";
import DuplicateLearner from "@/models/DuplicateLearner";
import RegistrationBoard from "@/models/RegistrationBoard";
import {DepartmentsModule} from "@/modules/DepartmentsModule";
import {LevelsModule} from "@/modules/LevelsModule";
import {ApplicationsModule} from "@/modules/ApplicationsModule";
import {ProfileModule} from "@/modules/ProfileModule";
import {ConditionsModule} from "@/modules/ConditionsModule";
import CourseSubject from "@/models/CourseSubject";
import SchoolSubject from "@/models/SchoolSubject";
import Document from "@/models/Document";
import CourseDocuments from "@/models/CourseDocument";
import CourseExemptionType from "@/models/CourseExemptionType";
import UserSubject from "@/models/UserSubject";
import Invoice from "@/models/Invoice";
import InvoiceItem from "@/models/InvoiceItem";
import CourseLanguages from "@/models/CourseLanguages";
import CourseCoreOption from "@/models/CourseCoreOption";
import UserLanguage from "@/models/UserLanguage";
import UserCoreOption from "@/models/UserCoreOption";
import SupplementOption from "@/models/SupplementOption";
import UserDocument from "@/models/UserDocument";
import InvoicePayment from "@/models/InvoicePayment";
import Intake from "@/models/Intake";
import CourseIntake from "@/models/CourseIntake";
import CourseRelatedCourse from "@/models/CourseRelatedCourse";
import Qualification from "@/models/Qualification";
import IntakeSemester from "@/models/IntakeSemester";
import ModuleLMSCode from "./models/ModuleLMSCode";

let accessToken = ACCESS_TOKEN;
if (ACCESS_TOKEN == null) {
  accessToken = '';
}
let additionalHeaders = {}
let defaultHeaders = {
  'X-Requested-With': 'XMLHttpRequest',
  'Accept': 'application/json',
  'Content-Type': 'application/json'
};

if (accessToken.length > 0) {
  defaultHeaders['Authorization'] = 'Bearer ' + accessToken;
}

// let axiosOptions = {
//   baseURL: API_URL,
//   headers: {
//     ...defaultHeaders,
//     ...additionalHeaders
//   }
// };

VuexORM.use(VuexORMAxios, {
  axios,
  baseURL: API_URL,
  headers: {
    ...defaultHeaders,
    ...additionalHeaders
  }
})
VuexORM.use(VuexORMSearch, {})

Vue.use(Vuex)

const database = new VuexORM.Database()

database.register(User)
database.register(Post)
database.register(Enquiry)
database.register(Course)
database.register(Permission)
database.register(Learner)
database.register(Stage)
database.register(Note)
database.register(EnquiryMadeByUser)
database.register(RegistrationMadeByUser)
database.register(RegistrationMadeByCourse)
database.register(RegistrationMadeByStage)
database.register(RegistrationMadeByStageByUser)
database.register(EnquiryMadeByUserOverTime)
database.register(EnquiryWonByUser)
database.register(EnquiryWonByUserOverTime)
database.register(EnquiryLostByUser)
database.register(EnquiryLostByUserOverTime)
database.register(EnquiryMadeBySource)
database.register(EnquiryMadeByStage)
database.register(EnquiryMadeByStageByUser)
database.register(CourseInterest)
database.register(Profile)
database.register(Lookup)
database.register(Media)
database.register(Module)
database.register(Semester)
database.register(Role)
database.register(Activity)
database.register(RPL)
database.register(RoleUser)
database.register(PermissionRole)
database.register(Semester)
database.register(SemesterModule)
database.register(ModuleCourse)
database.register(Registration)
database.register(ModuleRegistration)
database.register(Email)
database.register(UserEmail)
database.register(RegistrationStage)
database.register(RegistrationNote)
database.register(Schedule)
database.register(Grade)
database.register(RegistrationMadeBySource)
database.register(City)
database.register(State)
database.register(Country)
database.register(DuplicateLearner)
database.register(RegistrationBoard)
database.register(CourseSubject)
database.register(SchoolSubject)
database.register(Document)
database.register(CourseDocuments)
database.register(CourseExemptionType)
database.register(UserSubject)
database.register(Invoice)
database.register(InvoiceItem)
database.register(CourseLanguages)
database.register(CourseCoreOption)
database.register(UserLanguage)
database.register(UserCoreOption)
database.register(SupplementOption)
database.register(UserDocument)
database.register(InvoicePayment)
database.register(Intake)
database.register(CourseIntake)
database.register(CourseRelatedCourse)
database.register(Qualification)
database.register(IntakeSemester)
database.register(ModuleLMSCode)

export default new Vuex.Store({
  modules: {
    auth: AuthModule,
    toast: ToastModule,
    loader: LoaderModule,
    chart: ChartModule,
    dates: DateSelectModule,
    semesters: SemestersModule,
    statuses: StatusesModule,
    departments: DepartmentsModule,
    levels: LevelsModule,
    applications: ApplicationsModule,
    profile: ProfileModule,
    conditions: ConditionsModule,
    registrations: RegistrationModule
    // permissions: PermissionsModule
  },
  plugins: [VuexORM.install(database)],
  state: {
    /* User */
    userName: null,
    userEmail: null,
    userAvatar: null,
    userId: null,
    isLearner:false,
    /* NavBar */
    isNavBarVisible: true,

    /* Aside Menu */
    isAsideVisible: true,
    isAsideExpanded: false,
    isAsideMobileExpanded: false,

    /* Aside Right */
    isAsideRightVisible: false,
    asideRightMode: 'updates',

    /* FooterBar */
    isFooterBarVisible: true
  },
  mutations: {
    /* A fit-them-all commit */
    basic(state, payload) {
      state[payload.key] = payload.value
    },

    /* User Data */
    user(state, payload) {
      if (payload.name) {
        state.userName = payload.name
      }
      if (payload.email) {
        state.userEmail = payload.email
      }
      if (payload.avatar) {
        state.userAvatar = payload.avatar
      }
      if (payload.id) {
        state.userId = payload.id
      }
      if (payload.isLearner) {
        state.isLearner = payload.isLearner
      }
    },

    /* Full Page mode */
    fullPage(state, payload) {
      state.isNavBarVisible = !payload
      state.isAsideVisible = !payload
      state.isFooterBarVisible = !payload

      each(['has-aside-left', 'has-navbar-fixed-top'], htmlClass => {
        if (payload) {
          document.documentElement.classList.remove(htmlClass)
        } else {
          document.documentElement.classList.add(htmlClass)
        }
      })
    },

    /* Aside Desktop */
    asideStateToggle(state, payload = null) {
      const htmlAsideClassName = 'has-aside-expanded'

      let isExpand

      if (payload !== null) {
        isExpand = payload
      } else {
        isExpand = !state.isAsideExpanded
      }

      if (isExpand) {
        document.documentElement.classList.add(htmlAsideClassName)
      } else {
        document.documentElement.classList.remove(htmlAsideClassName)
      }

      state.isAsideExpanded = isExpand
    },

    /* Aside Mobile */
    asideMobileStateToggle(state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    },

    /* Aside Right */
    asideRightToggle(state, payload = null) {
      const htmlClassName = 'has-aside-right'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideRightVisible
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideRightVisible = isShow
    }
  },
  actions: {}
})
