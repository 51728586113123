<template>
  <Export

    :date_filter="true"
    :entities="entities"
    :admissions_team="admissions_team"
    :title="'Registration Stage Changes'"
    :creator_filter="true"
    :course_filter="false"
    :module_filter="false"
    :inline_modules_filter="false"
    :stages="stages"
    :stages_range_filter="true"
    :stages_filter="false"
    :semesters="semesters"
    :year_filter="true"
    :existing_filter="false"
  ></Export>
</template>


<script>
import Stage from "@/models/RegistrationStage";
import Export from "@/components/Export";
import User from "@/models/User";
import Semester from "@/models/Semester";

export default {
  name: "registrations",
  components: {
    Export,
  },
  data() {
    return {
      entities: "registrations-stage-changes",
      admissions_team: [],

    };
  },
  computed: {
    stages() {
      return Stage.all();
    },
    semesters() {
      return Semester.all();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");

    User.deleteAll();
    Stage.fetchAll();
    Semester.FetchAll();

    User.fetchAll("edit registrations").then((result) => {
      this.admissions_team = result.entities.users;
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
