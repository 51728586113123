<template>
  <card-component title="Password reset" icon="lock-reset">
    <form @submit.prevent="submit">
      <b-field expanded label="Reset Method">
        <b-select expanded required v-model="resetMethod">
          <option value="email">Email Address</option>
          <option value="id">ID Number</option>
        </b-select>
      </b-field>
      <b-field v-if="resetMethod==='email'" label="Email Address">
        <b-input
          :key="'email-address'"
          name="email"
          type="email"
          v-model="form.email"
          required
          autofocus
        />
      </b-field>
      <b-field v-if="resetMethod==='id'" label="ID Number">
        <b-input
          :key="'id-number'"
          name="id"
          v-model="form.id_number"
          required
          autofocus
        />
      </b-field>

      <hr/>

      <b-field grouped>
        <div class="control">
          <button
            type="submit"
            class="button is-primary"
            :class="{'is-loading':isLoading}"
          >Send Reset Link
          </button>
        </div>
        <div v-if="$cookie.get('stored_application')==null" class="control">
          <router-link to="/login" class="button is-primary is-outlined">Back</router-link>
        </div>
      </b-field>
    </form>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import {AuthClient} from "@/clients/AuthClient";

export default {
  components: {CardComponent},
  data() {
    return {
      resetMethod: null,
      isLoading: false,
      form: {
        email: null,
        id_number: null
      }
    };
  },
  methods: {
    submit() {
      this.isLoading = true;
      AuthClient.reset(this.resetMethod==='email'?{email:this.form.email}:{id_number:this.form.id_number}).then(() => {
        this.isLoading = false;
        this.$buefy.snackbar.open({
          message: "Link sent",
          duration: 1000,
          queue: false,
          type: "is-link"
        });
      });
    }
  }
};
</script>

<style scoped>
</style>
