<template>
  <section :class="{'section':!inTab}">
    <title-bar v-if="!inTab">
      Gradebook
      <template #button>
        <b-field horizontal>
          <section style="text-align: center;">
            <b-button
              :disabled="!can_fetch_grades"
              @click="startFetchAllGrades()"
              :loading="fetchLoading"
              type="is-warning"
              size="is-medium"
              style="margin-bottom: 10px;"
            >Retrieve Grades</b-button>
            <b-field>
              <b-tag size="is-medium" type="is-dark">Last Retrieved: {{ gradesFetchedAt }}</b-tag>
            </b-field>
            <b-field>
              <b-tag size="is-medium">Last Retrieved By: {{ gradesFetchedBy }}</b-tag>
            </b-field>
          </section>
          <section style="text-align: center;">
            <b-button
              :disabled="!can_release_grades"
              @click="startReleaseAllGrades()"
              :loading="fetchLoading"
              type="is-success"
              size="is-medium"
              style="margin-bottom: 10px;"
            >Release Grades</b-button>
            <b-field>
              <b-tag size="is-medium" type="is-dark">Last Released: {{ gradesReleasedAt }} </b-tag>
            </b-field>
            <b-field>
              <b-tag size="is-medium">Last Released By: {{ gradesReleasedBy }}</b-tag>
            </b-field>
          </section>
        </b-field>

      </template>
    </title-bar>
    <card-component :class="{'no-child-padding':inTab}" :icon="$tc('icons.gradebook')">
      <form
        @submit.prevent="storeGrade({module_id:module_id,user_id:learner_id,comment:comment,symbol:symbol,grade:grade})"
      >
        <b-field v-if="hasFilters" grouped group-multiline>
          <b-field style="min-width: 400px" expanded :label="$tc('Course',1)">
            <b-autocomplete
              expanded
              @typing="getFilteredCourse"
              :data="courseSearchResponse"
              autocomplete="off"
              :loading="loadingCourses"
              v-model="courseSearchTerm"
              :placeholder="$tc('Course',1)"
              :clear-on-select="false"
              @select="option => selectCourse(option)"
              :custom-formatter="thing=> courseName(thing)"
            >
              <template slot-scope="props">
                <p>{{props.option.name}}</p>
              </template>
            </b-autocomplete>
          </b-field>
          <b-field  expanded v-if="course_id==null"  label="Module">
            <b-autocomplete expanded
              ref="module_search"
              @typing="getFilteredModule"
              :data="moduleSearchResponse"
              autocomplete="off"
              :required="show_add_fields"
              :loading="loadingModules"
              v-model="moduleSearchTerm"
              placeholder="Module"
              @select="option => selectModule(option)"
              :clear-on-select="false"
              :custom-formatter="thing=> moduleName(thing)"
            >
              <template slot-scope="props">
                <p>{{props.option.code}} | {{props.option.name}}</p>
              </template>
            </b-autocomplete>
          </b-field>
          <b-field expanded label="Module" v-if="course_id!=null">
            <b-select expanded placeholder="Module" v-model="module" @input="selectModule">
              <option :value="null">All</option>
              <option
                :value="module"
                :key="module.id"
                v-for="module in modules"
              >{{module.code}} | {{module.name}}</option>
            </b-select>
          </b-field>
          <b-field label="Registration Year">
            <b-select placeholder="Year" v-model="year">
              <option :value="null">All</option>
              <option
                v-for="number in 4"
                :key="number + 'year'"
                :value="(new Date().getFullYear()-4)+number"
              >{{(new Date().getFullYear()-4)+number}}</option>
            </b-select>
          </b-field>

          <b-field expanded :label="$tc('Learner',1)">
            <b-autocomplete
              expanded
              ref="learner_search"
              :required="show_add_fields"
              @typing="getFilteredLearner"
              :data="learnerSearchResponse"
              autocomplete="off"
              :loading="loadingLearners"
              v-model="learnerSearchTerm"
              :placeholder="$tc('Learner',1)"
              @select="option => selectLearner(option)"
              :clear-on-select="false"
              :custom-formatter="thing=> learnerName(thing)"
            >
              <template slot-scope="props">
                <p>{{props.option.first_name}} {{props.option.last_name}} | {{props.option.email}}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>
        <!-- <b-field>
          <b-button
            v-if="can_create_grades"
            v-show="!show_add_fields"
            type="is-success"
            :icon-left="$tc('icons.create')"
            @click="showEntryForm()"
          >Add Gradebook Entry</b-button>
        </b-field>

        <b-field v-show="show_add_fields" grouped>
          <b-field label="Grade">
            <b-numberinput
              required
              step="0.01"
              lang="en"
              v-model="grade"
              placeholder="Grade"
              min="0"
              :controls="false"
              max="100"
            ></b-numberinput>
          </b-field>
          <b-field label="Symbol">
            <b-input required v-model="symbol" placeholder="Symbol" />
          </b-field>
          <b-field label="Comment" expanded>
            <b-input v-model="comment" expanded type="textarea" placeholder="Comment" />
          </b-field>
          <b-loading :is-full-page="false" :active="loadingGradeCreate"></b-loading>
        </b-field>
        <b-field v-show="show_add_fields" grouped>
          <b-field>
            <b-button
              v-if="can_create_grades"
              type="is-success"
              :icon-left="$tc('icons.create')"
              native-type="submit"
            >Save</b-button>
          </b-field>
          <b-field>
            <b-button type="is-danger" icon-left="cancel" @click="closeEntryForm()">Cancel</b-button>
          </b-field>
        </b-field>-->
      </form>
      <b-table
        class="margin-top"
        :data="grades_hold"
        :striped="true"
        :hoverable="true"
        :bordered="true"
      >
        <b-table-column v-slot="props"
          sortable
          field="user.first_name"
          :label="($tc('Learner',1))"
        >{{ props.row.user.first_name }} {{ props.row.user.last_name }}</b-table-column>

        <b-table-column v-slot="props" sortable field="module.name" label="Module">{{ props.row.module ?  props.row.module.name : '' }}</b-table-column>
        <b-table-column v-slot="props" sortable width="110" field="grade" label="Grade">
          {{ props.row.grade ? props.row.grade.toFixed(1) : ''}}
        </b-table-column>
        <b-table-column v-slot="props" width="110" label="Symbol" sortable field="symbol">
          {{ props.row.is_new==0&& row_edit_id!=props.row.$id?props.row.symbol:'' }}
          <b-field v-if="props.row.is_new==1 || row_edit_id==props.row.$id">
            <b-select v-model="props.row.symbol" placeholder="Symbol" disabled>
              <option
                v-for="(grade_symbol,index) in symbols"
                :key="grade_symbol + index"
                :value="grade_symbol"
              >{{grade_symbol}}</option>
            </b-select>
          </b-field>
        </b-table-column>
        <b-table-column v-slot="props" label="Comment" field="comment">
          {{ props.row.is_new==0&& row_edit_id!=props.row.$id?props.row.comment:'' }}
          <b-field v-if="props.row.is_new==1 || row_edit_id==props.row.$id">
            <b-input v-model="props.row.comment" placeholder="Comment" disabled/>
          </b-field>
        </b-table-column>

        <b-table-column v-slot="props" custom-key="actions">
          <b-field grouped>
<!--            <b-field v-if="can_edit_grades && props.row.is_new==0 && row_edit_id!=props.row.$id">-->
<!--              <b-button @click="startEditGrade(props.row)" type="is-info">Edit</b-button>-->
<!--            </b-field>-->
<!--            <b-field v-if="can_edit_grades && props.row.is_new==0 && row_edit_id==props.row.$id">-->
<!--              <b-button-->
<!--                @click="saveEditGrade(props.row)"-->
<!--                :icon-right="'content-save'"-->
<!--                type="is-success"-->
<!--              ></b-button>-->
<!--            </b-field>-->
<!--            <b-field v-if="row_edit_id==props.row.$id">-->
<!--              <b-button-->
<!--                type="is-link"-->
<!--                :icon-right="'cancel'"-->
<!--                @click="props.row.grade=row_hold.grade;props.row.symbol=row_hold.symbol;props.row.comment=row_hold.comment;row_edit_id=null"-->
<!--              ></b-button>-->
<!--            </b-field>-->
<!--            <b-field v-if="can_create_grades && props.row.is_new==1">-->
<!--              <b-button-->
<!--                @click="storeGrade({module_id:props.row.module_id,user_id:props.row.user_id,comment:props.row.comment,symbol:props.row.symbol,grade:props.row.grade, registration_id:props.row.registration_id})"-->
<!--                type="is-info"-->
<!--                :disabled="props.row.grade==null"-->
<!--              >Save</b-button>-->
<!--            </b-field>-->
<!--            <b-field v-if="can_delete_grades && props.row.is_new==0">-->
<!--              <b-button-->
<!--                @click="start_delete_grade(props.row)"-->
<!--                type="is-danger"-->
<!--                icon-right="delete"-->
<!--              ></b-button>-->
<!--            </b-field>-->
            <b-field v-if="can_fetch_grades">
              <b-button
                @click="startFetchGrade(props.row)"
                type="is-success"
                :loading="isFetchGradeLoading(props.row)"
              >Fetch</b-button>
            </b-field>
            <b-field
              :label="errorGradeId == props.row.module_id ? errorGradeMessage : ''"
              label-position="right">
            </b-field>
          </b-field>
        </b-table-column>
      </b-table>
      <b-pagination
        v-if="grades.length>limit"
        class="margin-top"
        :total="grades.length"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
      ></b-pagination>
      <b-loading :is-full-page="false" :active.sync="loading" :can-cancel="false"></b-loading>    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Learner from "@/models/Learner";
import Module from "@/models/Module";
import Grade from "@/models/Grade";
import Course from "@/models/Course";
export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      grades_hold: [],
      row_hold: null,
      show_add_fields: false,
      row_edit_id: null,
      grade: null,
      symbol: null,
      symbols: ["A", "B", "C", "D", "E", "F", "RE", "INC", "NC"],
      comment: null,
      year: null,
      term_id: null,
      loadingGradeCreate: false,
      learner_id: this.defaultUser,
      module_id: null,
      loadingCourses: null,
      loadingModules: null,
      loadingLearners: null,
      course_id: this.defaultCourse,
      learnerSearchResponse: [],
      learnerSearchTerm: "",
      learner: {},
      courseSearchResponse: [],
      courseSearchTerm: "",
      moduleSearchResponse: [],
      moduleSearchTerm: "",
      module: null,
      modules: [],
      newSchedule: [],
      page: 1,
      editSchedule: {},
      unGraded: [],
      loading:false,
      isEditModalActive: false,
      editModuleSearchTerm: "",
      editLearnerSearchTerm: "",
      limit: 50,
      meta: {},
      fetchLoading: false,
      errorGradeId: null,
      errorGradeMessage: null,
      gradesFetchedAt: null,
      gradesFetchedBy: null,
      gradesReleasedAt: null,
      gradesReleasedBy: null,
      currentLoadingModule: null,
    };
  },
  props:{
    inTab:{
      type:Boolean,default(){
        return false
}
    },
    hasFilters:{
      type:Boolean,
     default(){
        return true
      }
    },
    defaultUser:{
      type:Number,default() {
        return null;
      }
    } , defaultCourse:{
      type:Number,default() {
        return null;
      }
    },
    registrationId:{
      type:Number,default(){
        return null
      }
    },
    currentSemester:{
      type:Boolean,
      default() {
        return false;
      }
    }
  },
  computed: {
    // modules() {
    //   return Module.query().get();
    // },
    grades() {
      return Grade.query().with("module").with("user").get();
    },
    offset_grades() {
      return Grade.query()
        .with("module")
        .with("user")
        .where(
          grade=>{
            return  this.registrationId===null?true:parseInt(this.registrationId) === parseInt(grade.registration_id)
          }
        ).where('withdrawn',false)
        .offset((this.page - 1) * this.limit)
        .limit(this.limit)
        .get();
    },
    filters() {
      return {
        ...(this.course_id
          ? {
            course_id: this.course_id,
          }
          : {}),
        ...(this.learner_id
          ? {
            learner_id: this.learner_id,
          }
          : {}),
        ...(this.module_id
          ? {
            module_id: this.module_id,
          }
          : {}),
        ...(this.year
          ? {
            year: this.year,
          }
          : {}),
        ... {
          is_complete: 1,
          current_semester: this.currentSemester,
        }
      };
    },

    can_delete_grades() {
      return this.$store.getters["entities/permissions/find"]("delete grades");
    },
    can_edit_grades() {
      return this.$store.getters["entities/permissions/find"]("edit grades");
    },
    can_create_grades() {
      return this.$store.getters["entities/permissions/find"]("create grades");
    },
    can_fetch_grades() {
      return this.$store.getters["entities/permissions/find"]("retrieve grades");
    },
    can_release_grades() {
      return this.$store.getters["entities/permissions/find"]("release grades");
    }
  },
  watch: {
    registrationId(){
      this.setPage(this.page);
    },
    filters() {
      this.fetchData(this.page);
    },
    grades() {},
  },
  methods: {
    isFetchGradeLoading(row) {
      if (row && row.module) {
        return this.currentLoadingModule == row.module.id
      }
      return false
    },
    saveEditGrade(grade) {
      this.loading=true;
      Grade.update(grade)
        .then(() => {
          this.loading=false;
          this.row_edit_id = null;
          this.$buefy.snackbar.open({
            message: "Grade updated",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.loading=false;
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    setPage(page) {
      this.page = page;
      this.grades_hold = this.offset_grades;
    },
    startEditGrade(row) {
      this.row_hold = JSON.parse(JSON.stringify(row));
      this.row_edit_id = row.$id;
    },
    startFetchGrade(row) {
      this.errorGradeId = null;
      this.errorGradeMessage = null;
      this.currentLoadingModule = row.module.id;
      Grade.sakaiFetchForUser(row.module.id, row.user.id).then(() => {
        this.currentLoadingModule = null;
        this.fetchData();
      }).catch((e) => {
        if (e.response) {
          this.errorGradeId = row.module_id;
          this.errorGradeMessage = e.response.data.errors.user_id[0]
        }
        this.currentLoadingModule = null;
        this.fetchData();
      })
    },
    startFetchAllGrades() {
      this.fetchLoading = true;
      Grade.sakaiFetchAllGrades().then((res) => {
        this.$buefy.snackbar.open({
          message: "Grade import process started.",
          queue: false,
          type: "is-link",
        });
        this.fetchLoading = false;
        this.gradesFetchedAt = res.response.data.retrieved_at
        this.gradesFetchedBy = res.response.data.retrieved_by
      }).catch((e) => {
        this.fetchLoading = false;
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(e.response.data),
        });
      })
    },
    startReleaseAllGrades() {
      this.fetchLoading = true;
      Grade.sakaiReleaseAllGrades().then((res) => {
        this.$buefy.snackbar.open({
          message: "Grade release process started.",
          queue: false,
          type: "is-link",
        });
        this.fetchLoading = false;
        this.gradesReleasedAt = res.response.data.released_at
        this.gradesReleasedBy = res.response.data.released_by
      }).catch((e) => {
        this.fetchLoading = false;
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(e.response.data),
        });
      })
    },
    closeEntryForm() {
      this.show_add_fields = false;
      this.note = null;
      this.comment = null;
      this.grade = null;
      // this.$nextTick(() => {
      //   this.$refs.learner_search.checkHtml5Validity();
      //   this.$refs.module_search.checkHtml5Validity();
      // });
    },
    showEntryForm() {
      this.show_add_fields = true;
      // this.$nextTick(() => {
      //   this.$refs.learner_search.checkHtml5Validity();
      //   this.$refs.module_search.checkHtml5Validity();
      // });
    },
    storeGrade(grade) {
      this.loadingGradeCreate = true;
      Grade.create(grade)
        .then(() => {
          this.loadingGradeCreate = false;
          this.fetchData(this.page);
          this.closeEntryForm();
          this.$buefy.snackbar.open({
            message: "Grade entered",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.loadingGradeCreate = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteGrade(item) {
      this.loading=true;
      Grade.deleteRemote(item.id)
        .then(() => {
          this.loading=false;
          this.grades_hold = this.offset_grades;
          this.$buefy.snackbar.open({
            message: "Grade deleted",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.loading=false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete_grade(item) {
      this.$buefy.dialog.confirm({
        title: "Deleting Grade Item",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Grade Item",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteGrade(item),
      });
    },
    fetchData() {
      this.grades_hold = this.offset_grades;
      Grade.deleteAll();
      this.loading=true;
      this.getGrades(this.filters);
    },

    learnerName(learner) {
      return `${learner.first_name} ${learner.last_name} | ${learner.email}`;
    },
    courseName(course) {
      return `${course.name}`;
    },
    selectLearner(option, index = null) {
      if (option) {
        if (index != null) {
          this.newSchedule[index].learner_id = option.id;
          return;
        }
        this.learner_id = option.id;
      }
    },
    selectCourse(option) {
      if (option) {
        this.loading=true;
        Module.deleteAll();
        Course.fetchById(option.id).then(({ entities }) => {
          this.modules = JSON.parse(JSON.stringify(entities.modules));
          this.course_id = option.id;
        });
      }
    },
    selectEditLearner(option) {
      if (option) {
        this.editSchedule.learner_id = option.id;
      }
    },
    getFilteredCourse(text) {
      if (text.length == 0) {
        this.courseSearchResponse = [];
        this.course_id = null;
        return;
      }
      this.loadingCourses = true;

      Course.search(text, true).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.courseSearchResponse = result.entities.courses;
        } else {
          this.courseSearchResponse = [];
        }
      });
    },
    getFilteredLearner(text) {
      if (text.length == 0) {
        this.learnerSearchResponse = [];
        this.learner_id = null;
        return;
      }
      this.loadingLearners = true;

      Learner.search(text, true, 1, 15).then((result) => {
        this.loadingLearners = false;
        if (result.entities) {
          this.learnerSearchResponse = result.entities.learners;
        } else {
          this.learnerSearchResponse = [];
        }
      });
    },
    moduleName(module) {
      return `${module.code} | ${module.name}`;
    },
    selectModule(option) {
      if (option != null) {
        this.module = option;
        this.module_id = option.id;
      } else if (option == null) {
        this.module = null;
        this.module_id = null;
      }
    },

    getFilteredModule(text, is_add = false) {
      if (text.length == 0) {
        this.moduleSearchResponse = [];
        if (!is_add) {
          this.module_id = null;
          this.module = null;
        }
      }
      this.loadingModules = true;

      Module.search(text).then((result) => {
        this.loadingModules = false;

        if (result.entities) {
          this.moduleSearchResponse = result.entities.modules;
        } else {
          this.moduleSearchResponse = [];
        }
      });
    },
    getGrades(filters) {
      let Promises = [];

      if (Object.keys(filters).length > 0) {
        let Promise1 = Grade.fetchAll(1, 999, filters, ["user", "module"]);
        Promises.push(Promise1);

        let Promise2 = Grade.fetchAllUngraded(
          1,
          999,
          {
            course_id: this.course_id,
            ungraded_module_id: this.module_id,
            year: this.year,
            user_id: this.learner_id,
            current_semester: this.currentSemester
          },
          this.module
        );
        Promises.push(Promise2);

        Promise.all(Promises)
          .then((res) => {
            let meta = res[0].response.data.meta;
            this.gradesFetchedBy = meta.last_retrieved_by;
            this.gradesFetchedAt = meta.last_retrieved_at;
            this.gradesReleasedBy = meta.last_released_by;
            this.gradesReleasedAt = meta.last_released_at;
            this.grades_hold = this.offset_grades;

            this.loading=false;
          })
          .catch((err) => {
            this.loading=false;

            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      } else {
        this.grades_hold = this.offset_grades;

        this.loading=false;
      }
    },
  },
  mounted() {
    this.fetchData()
  },
};
</script>
