import {addYears, differenceInYears} from "date-fns";

export default {
  methods: {
    validate_id(idNumber) {
      // store the error
      let error = null
      let data = {
        idNumber: null,
        date_of_birth: null,
        gender: null,
        is_citizen: null
      }
      // assume everything is correct and if it later turns out not to be, just set this to false
      let correct = true;

      //Ref: http://www.sadev.co.za/content/what-south-african-id-number-made
      // SA ID Number have to be 13 digits, so check the length
      if (idNumber.length !== 13 || !isNumber(idNumber)) {
        error = 'ID number is invalid - input is not a valid number'
        correct = false;
        return {error, data}

      }

      // get first 6 digits as a valid date
      let tempDate = new Date(idNumber.substring(0, 2), idNumber.substring(2, 4) - 1, idNumber.substring(4, 6));

      if (differenceInYears(new Date(),tempDate)>99){
        tempDate = addYears(tempDate,100)
      }
      let id_date = tempDate.getDate();
      let id_month = tempDate.getMonth();
      let id_year = tempDate.getFullYear();

      let fullDate = tempDate;
      if (!((id_year.toString().substring(2) === idNumber.substring(0, 2)) && (id_month === parseInt(idNumber.substring(2, 4)) - 1) && (id_date === parseInt(idNumber.substring(4, 6))))) {
        error = 'ID number is invalid - date is invalid'
        correct = false;
        return {error, data}
      }

      // get the gender
      let genderCode = idNumber.substring(6, 10);
      let gender = parseInt(genderCode) < 5000 ? "Female" : "Male";

      // get country ID for citizenship
      let citizenship = parseInt(idNumber.substring(10, 11)) === 0

      // apply Luhn's formula for check-digits
      let tempTotal = 0;
      let checkSum = 0;
      let multiplier = 1;
      for (let i = 0; i < 13; ++i) {
        tempTotal = parseInt(idNumber.charAt(i)) * multiplier;
        if (tempTotal > 9) {
          tempTotal = parseInt(tempTotal.toString().charAt(0)) + parseInt(tempTotal.toString().charAt(1));
        }
        checkSum = checkSum + tempTotal;
        multiplier = (multiplier % 2 === 0) ? 1 : 2;
      }
      if ((checkSum % 10) !== 0) {
        error = 'ID number does not appear to be authentic'
        correct = false;
        return {error, data}

      }


      if (correct) {
        data.date_of_birth = fullDate
        data.is_citizen = citizenship
        data.idNumber = idNumber
        data.gender = gender

      }

      return {error,data};
    }

  }
}

function isNumber(n) {
  return !isNaN(parseFloat(n)) && isFinite(n);
}

