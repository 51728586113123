import {TransformModel} from '@/models/TransformModel'

export default class CourseLanguages extends TransformModel {
  static entity = 'course-languages'

  static fields() {
    return {
      id: this.attr(null),
      name:this.attr(null)
    }
  }

  static FetchAll(page = 1, limit = 999, course_id) {
    return this.api().get(`/courses/${course_id}/language-options`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(language => {
          language.id = parseInt(language.id)
          return {...language, ...language.attributes}
        })
      },
    })
  }

  static Update(course_languages) {
    return this.api().patch(`/courses/${course_languages.course_id}/language-options`, course_languages, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }




}
