<template>
  <div class="view-login">
    <img src="@/assets/cornerstone-logo.png" alt />

    <card-component>
      <form @submit.prevent="submit" method="POST">
        <b-field label="Have you completed a previous application?">
          <b-switch
              v-model="form.is_existing_student">{{form.is_existing_student?'Yes':'No'}}</b-switch>
        </b-field>
        <b-field :label="form.is_existing_student?'Student ID/Student Email':'ID Number'">

          <b-input
              name="username" v-model="$store.state.auth.username" required autofocus />
        </b-field>
        <b-field v-if="form.is_existing_student" ><span class="subtitle is-size-7">Your student email should end with @cornerstone.ac.za</span></b-field>
        <b-field label="Password">
          <b-input
              type="password" name="password" v-model="$store.state.auth.password" required />
        </b-field>

        <!-- <b-field>
                  <b-checkbox type="is-primary" class="is-thin" v-model="form.remember">Remember me</b-checkbox>
        </b-field>-->

        <hr />

          <b-field position="is-centered" grouped group-multiline>
            <b-field expanded class="fullpage-button-field">
              <b-button expanded

                        native-type="submit"
                        type="is-primary"
                        :loading="isLoading"
              >Login
              </b-button>
            </b-field>
            <b-field expanded class="fullpage-button-field">
              <b-button expanded tag="router-link"
                        to="/password-recovery"

                        type="is-primary" outlined>
                Forgot password
              </b-button>
            </b-field>

            <b-field expanded class="fullpage-button-field">
              <b-button expanded tag="router-link"
                        to="/admin/login"

                        type="is-primary" outlined>
                Log in as staff
              </b-button>
            </b-field>

          </b-field>
        <router-link

                to="/apply"
                class=""
        >Not yet registered? Start here.</router-link>
      </form>
    </card-component>

  </div>
</template>

<script>
import { AuthClient } from "@/clients/AuthClient";
import VueCookie from "vue-cookie";
import CardComponent from "@/components/CardComponent";
import { BACKEND_URL, CLIENT_SECRET } from "@/config";

export default {
  name: "Login",
  components: { CardComponent },
  data() {
    return {
      backendUrl: BACKEND_URL,
      isLoading: false,
      maintenance:false,
      form: {
        remember: true,
        is_existing_student:false
      }
    };
  },
  mounted(){
    if (this.$route.query.returning){
      this.form.is_existing_student = true
    }
  },
  methods: {
    submit() {
      this.isLoading = true;
      AuthClient.login({
        username: this.$store.state.auth.username,
        password: this.$store.state.auth.password,
        is_existing_student: this.form.is_existing_student,
        client_id: "2",
        client_secret: CLIENT_SECRET,
        grant_type: "password"
      })
        .then(result => {
          VueCookie.set("access_token", result.data.access_token);
          window.location.href = "/registrations/mine";
        })
        .catch(err => {
          this.handleError(err)


          this.isLoading = false;
        });
    }
  }
};
</script>
