import {TransformModel} from '@/models/TransformModel'
import {format} from "date-fns";


export default class Semester extends TransformModel {
  static entity = 'semesters'

  get formatted_start_date() {
    if (this.start_date !== null) {
      return format(new Date(this.start_date), 'yyyy-MM-dd')
    }
    return 'None'
  }

  get formatted_end_date() {
    if (this.end_date !== null) {
      return format(new Date(this.end_date), 'yyyy-MM-dd')
    }
    return 'None'
  }

  static fields() {
    return {
      type: this.attr('semesters'),
      id: this.attr(null),
      name: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      year: this.attr(null),
      enrolment_date: this.attr(null),
    }
  }

  static FetchAll({page = 1, limit = 30, orderBy = 'id', orderDir = 'asc'} = {}, query = {}, relationships = []) {
    return this.api().get(`/semesters`, {
      params: {
        ...{
          page: page,
          limit: limit,
          orderBy,
          orderDir,
          with: relationships
        }, ...(query !== {} ? query : {}),
      },
      dataTransformer: (response) => {
        let storageArray = []

        response.data.data.forEach(element => {

          element = {
            ...element,
            ...element.attributes
          }

          storageArray.push(element)

        })
        return storageArray
      }
    })
  }

  static Delete(semesterId) {
    return this.api().delete(`/semesters/${semesterId}`, {
      delete: semesterId
    })
  }

  static Store(semester) {
    return this.api().post(`/semesters`, semester, {
      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static Update(semester) {
    return this.api().patch(`/semesters/${semester.id}`, semester, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/semesters/${id}`, {
      params: {
        with: [...relationships, "module_lms_codes", "module_lms_codes.semester"],
        // ...(relationships ? {
        //   with: relationships
        // } : {})

      },
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        return target
      }
    })
  }

  static search(data, page = 1, limit = 100, relationships = []) {
    return this.api().get(`/semesters?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(relationships ? {
          with: relationships
        } : {})
      },
    })
  }
}
