<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form :class="{'section':!inModal}" class="is-relative" @submit.prevent="handleSubmit(submit)">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Qualification', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">
          {{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Qualification', 1) }}</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <b-tabs v-model="activeTab" :destroy-on-hide="true" class="block no-padding" expanded position="is-centered"
                  size="is-large" type="is-toggle">
            <b-tab-item class="py-5" label="Qualification Details">
              <b-field :horizontal="!inModal" label="Name">
                <b-input
                  v-model="qualificationObject.name"
                  autocomplete="off"
                  name="name"
                  placeholder="Name"
                  required
                />
              </b-field>
              <b-field :horizontal="!inModal" label="Long Name">

                <b-input
                  v-model="qualificationObject.long_name"
                  autocomplete="off"
                  name="long_name"
                  placeholder="Long Name"
                />
              </b-field>
              <b-field :horizontal="!inModal" label="Code">
                <b-input
                  v-model="qualificationObject.code"
                  autocomplete="off"
                  name="code"
                  placeholder="Code"
                  required
                />
              </b-field>
              <b-field :horizontal="!inModal" label="Department">
                <b-select v-model="qualificationObject.department_id" placeholder="Select a department" required>
                  <option v-for="department in $store.state.departments.departments" :key="department.id"
                          :value="department.id">{{ department.name }}
                  </option>
                </b-select>
              </b-field>

              <b-field :horizontal="!inModal" label="Standardised Level">
                <b-select v-model="qualificationObject.standardised_level_id" placeholder="Select a level" required>
                  <option v-for="level in $store.state.levels.levels" :key="level.id"
                          :value="level.id">{{ level.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field :horizontal="!inModal" label="NQF Level">
                <b-input
                  v-model="qualificationObject.nqf_level"
                  autocomplete="off"
                  name="nqf_level"
                  placeholder="NQF Level"
                  type="number"
                />
              </b-field>
            </b-tab-item>
          </b-tabs>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :loading="loading" native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
              <p v-if="edit" class="control">
                <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                  Delete
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {mapState} from "vuex";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Qualification from "@/models/Qualification";

export default {
  name: "QualificationForm",
  components: {
    TitleBar,
    ValidationObserver,
  },
  data() {
    return {
      activeTab: 0,
      loaded: false,
      loading: false,
      qualificationObject: {
        name: null,
        code: null,
        long_name: null,
        nqf_level: null,
        department_id: null,
        has_school_subject_requirements: 0,
      },

    }
  },
  methods: {
    checkList(targetList, newItem) {
      return !targetList.includes(newItem)
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.$tc('Qualification', 1)}`,
        confirmText: `Delete ${this.$tc('Qualification', 1)}`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this ${this.$tc('qualification', 1)}?`,
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      Qualification.Delete(this.qualificationObject.id).then(() => {
        this.$buefy.snackbar.open(`${this.$tc('Qualification', 1)} deleted!`);
        this.$emit("close");
        this.$emit("deleted");

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
        if (!this.inModal) {
          this.$router.push('/qualifications')
        }
      });

    },
    submit() {
      this.loading = true
      if (this.edit) {
        if (!this.canEdit) {
          this.$store.dispatch('toast/createToast')
          return
        }
        Qualification.Update(this.qualificationObject, true).then(() => {
          this.$buefy.snackbar.open(`${this.$tc('Qualification', 1)} updated!`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        if (!this.canCreate) {
          this.$store.dispatch('toast/createToast')
          return;
        }
        Qualification.Store(this.qualificationObject).then(({entities: {qualifications}}) => {
          this.$buefy.snackbar.open(`${this.$tc('Qualification', 1)} created!`)
          this.qualificationObject.id = qualifications[0].id
          this.$router.push(`/qualifications/edit/${qualifications[0].id}`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },
  async created() {
    this.loading = true
    if (this.edit) {
      Qualification.fetchById(this.id)
        .then(() => {
          this.qualificationObject = Qualification.query().whereId(this.id).with("modules").first();
          this.$store.dispatch("loader/hide");
          this.loaded = true;
          this.loading = false
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.loading = false
          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    } else {
      this.loading = false
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

