<template>
  <div class="columns">
    <div class="column">
      <b-field horizontal label="How did you hear about Cornerstone?">
        <SelectMarketingWhereHear
          v-model="marketingObject.where_hear"
          :required="required"
          :disabled="disabled"
        />
      </b-field>
      <b-field horizontal label="Why did you choose Cornerstone?">
        <b-input
          v-model="marketingObject.why_choose"
          :disabled="disabled"
          :required="required"
          maxlength="80"
          autocomplete="chrome-off"
          type="textarea"
        />
      </b-field>
      <b-field horizontal label="Which institution did you attend before Cornerstone?">
        <SelectMarketingPriorInstitution
          v-model="marketingObject.prior_institution_id"
          :required="required"
          :disabled="disabled"
        />
      </b-field>
      <b-button v-if="!useInParent" @click="submitMarketing" type="is-primary">
        Save
      </b-button>
    </div>
  </div>
</template>

<script>
import SelectMarketingPriorInstitution from "@/components/global/selects/SelectMarketingPriorInstitution.vue";
import Profile from "@/models/Profile";
import SelectMarketingWhereHear from "@/components/global/selects/SelectMarketingWhereHear.vue";

export default {
  name: "MarketingForm",
  components: {SelectMarketingWhereHear, SelectMarketingPriorInstitution},

  props: {
    disabled: {
      type: Boolean,
      default(){
        return false;
      },
    },
    required: {
      type: Boolean,
      default(){
        return true;
      },
    },
    useInParent: {
      type: Boolean,
      default(){
        return true;
      },
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    profileId: {
      type: [Number, String],
      default() {
        return null;
      },
    }
  },
  data() {
    return {
      marketingObject: {
        where_hear: null,
        why_choose: null,
        prior_institution_id: null,
      },
    }
  },
  methods: {
    submitMarketing(){
      let submitObject = {...this.marketingObject};
      Profile.updateRemote(submitObject, this.profile.id, true)
        .then(() => {
          this.$buefy.snackbar.open(`Submitted`);
          this.$emit("update-marketing-question");
          this.loading = false;
        })
        .catch(err => {
          this.handleError(err);
          this.loading = false;
        });
    },
    persist(){
      if(this.profile === null){
        return;
      }
      if(this.profile.where_hear !== null){
        this.marketingObject.where_hear = this.profile.where_hear;
      }
      if(this.profile.why_choose !== null){
        this.marketingObject.why_choose = this.profile.why_choose;
      }
      if(this.profile.prior_institution_id !== null){
        this.marketingObject.prior_institution_id = this.profile.prior_institution_id;
      }
    },
  },
  computed: {
    profile(){
      return Profile.query().where('user_id', parseInt(this.profileId)).first();
    },
  },
  watch: {
    marketingObject: {
      handler(newVal){
        if(!this.useInParent){
          return;
        }
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
  async mounted() {
    await Profile.fetchById(this.profileId)
    this.persist();
  }
}
</script>
