<template>
  <div id="reports">
    <div class="columns min-height-classlist">
      <div class="column is-one-fifth reports-list has-background-white">
        <hr/>
        <b-collapse
          class="card"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">{{ collapse.title }}</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
            </a>
          </div>
          <div
            v-show="hasPermission(list.permission)"
            v-for="list in collapse.lists"
            :key="list.title"
            class="card-content"
          >
            <div class="content">
              <a @click="$router.push('/exports/'+list.to)">{{ list.title }}</a>
            </div>
          </div>
        </b-collapse>
      </div>

      <router-view class="column exports-view is-four-fifths"/>
    </div>
  </div>
</template>


<script>
import router from "@/router";

export default {
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Registrations",
          lists: [
            {
              title: "Registrations",
              to: "registrations",
              permission: "view exports",
            },
            {
              title: "Registrations By Course",
              to: "registrations-by-course",
              permission: "view exports",
            },
            {
              title: "Registrations By Gender",
              to: "registrations-by-gender",
              permission: "view exports",
            },
            {
              title: "Registrations By Ethnicity",
              to: "registrations-by-ethnicity",
              permission: "view exports",
            },
            {
              title: "Registrations By Nationality",
              to: "registrations-by-nationality",
              permission: "view exports",
            },
            {
              title: "Registrations By Age",
              to: "registrations-by-age",
              permission: "view exports",
            }, {
              title: "Registrations By Location",
              to: "registrations-by-location",
              permission: "view exports",
            }, {
              title: "Registration Stage Changes",
              to: "registrations-stage-changes",
              permission: "view exports",
            },{
              title: "Registration Status Changes",
              to: "registrations-status-changes",
              permission: "view exports",
            },
            {
              title: "Grade Statuses Export",
              to: "grade-statuses",
              permission: "view exports",
            }
          ],
        },
        // {
        //   title: "Enquiries",
        //   lists: [
        //     {
        //       title: "Enquiries",
        //       to: "enquiries",
        //       permission: "view exports",
        //     },
        //   ],
        // },
        {
          title: router.app.$tc("Learner", 2),
          lists: [
            {
              title: router.app.$tc("Learner", 2),
              to: "learners",
              permission: "view exports",
            },
            {
              title: "New " + router.app.$tc("Learner", 2) + " Per Course",
              to: "new-learners-per-course",
              permission: "view exports",
            },
            {
              title: "Probation Candidates",
              to: "probation-candidates",
              permission: "view exports",
            },
            {
              title: "Class Size",
              to: "class-size",
              permission: "view exports",
            },
          ],
        },
        {
          title: "Grades",
          lists: [
            {
              title: "Modules Success Rate",
              to: "modules-success-rate",
              permission: "view exports",
            },
            {
              title: "Bell Curve Semester",
              to: "bell-curve-semester",
              permission: "view exports",
            },
            {
              title: router.app.$tc("Learner", 1) + " Grades",
              to: "grades",
              permission: "view exports",
            },
            {
              title: router.app.$tc("Learner", 1) + " Success Rate",
              to: "learner-success-rate",
              permission: "view exports",
            },
            {
              title: "Graduand List",
              to: "graduand-list",
              permission: "view exports",
            },
          ],
        },
        {
          title: "Edudex",
          lists: [
            {
              title: "CHE Persons File 61",
              to: "edudex-persons",
              permission: "view edudex persons export",
            },{
              title: "Qualifications File 63",
              to: "edudex-qualifications",
              permission: "view edudex qualifications export",
            }, {
              title: "CHE Staff File 65",
              to: "edudex-staff",
              permission: "view edudex staff export",
            },{
              title: "Student FTE File 68",
              to: "edudex-students",
              permission: "view edudex student fte export",
            },
          ],
        },
      ],
    };
  },
  computed: {},
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
  },
};
</script>

<style lang="scss">
.min-height-classlist {
  min-height: 100vh;
}

.reports-list {
  .card:not(:last-child) {
    margin-bottom: 0 !important;
  }
}

.exports-view {
  max-width: 80%;
}

#reports {
  max-width: 85vw;
}

canvas {
  height: 500px !important;
}
</style>
