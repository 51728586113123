<template>
  <section class="section">
    <title-bar>Registrations</title-bar>
    <div class="columns">
      <div class="column">
        <!-- <h3 class="has-text-weight-bold">Filter</h3> -->
        <b-field grouped group-multiline>
          <b-field>
            <b-radio-button v-model="filter" native-value="course">
              <b-icon :icon="$tc('icons.courses')"></b-icon>
              <span>{{ $tc('Course', 1) }}</span>
            </b-radio-button>

            <b-radio-button v-model="filter" native-value="learner">
              <b-icon :icon="$tc('icons.learners')"></b-icon>
              <span>{{ $tc('Learner', 1) }}</span>
            </b-radio-button>

            <b-radio-button v-if="administrate_registrations" v-model="filter" native-value="owner">
              <b-icon :icon="$tc('icons.users')"></b-icon>
              <span>Owner</span>
            </b-radio-button>
          </b-field>
          <b-field v-if="administrate_registrations">
            <b-button @click="setTasksFilter(1)">Incomplete Activities</b-button>
            <b-button @click="setNoOwner">No Owner</b-button>
            <b-button @click="setNoCourse">No Course</b-button>
          </b-field>
          <b-radio-button v-model="rplCatFilter" :native-value="1">
            <span>RPL/CAT requested</span>
          </b-radio-button>
          <b-field>
            <b-select v-model="yearFilter" placeholder="Year">
              <option
                v-for="number in 5"
                :key="number + 'year'"
                :value="(new Date().getFullYear()-1)+number"
              >{{ (new Date().getFullYear() - 1) + number }}
              </option>
            </b-select>

            <b-select v-model="semesterFilter" placeholder="Semester">
              <option :value="1">1</option>
              <option :value="2">2</option>
            </b-select>
          </b-field>
          <b-select placeholder="Registration Status" v-model="statusFilter">
            <optgroup :key="index" v-for="(group,index) in $store.state.statuses.grouped_statuses"
                      :label="group.name">

              <option :key="status.id" v-for="status in group.statuses" :value="status.id">{{ status.name }}
              </option>
            </optgroup>
          </b-select>

          <b-field>
            <b-taglist>
              <b-tag
                type="is-info"
                v-if="courseSearchId"
                @close="courseSearchId = null"
                closable
              >{{ $tc('Course', 1) }}
              </b-tag>
              <b-tag
                type="is-info"
                v-if="tasksFilter"
                @close="setTasksFilter(0)"
                closable
              >Incomplete Activities
              </b-tag>
              <b-tag
                type="is-info"
                v-if="learnerSearchId"
                @close="learnerSearchId = null"
                closable
              >{{ $tc('Learner', 1) }}
              </b-tag>
              <b-tag
                type="is-info"
                v-if="ownerSearchId"
                @close="ownerSearchId = null"
                closable
              >Owner
              </b-tag>
              <b-tag type="is-info" v-if="noOwner" @close="noOwner = false" closable>No Owner</b-tag>
              <b-tag type="is-info" v-if="noCourse" @close="noCourse = false" closable>No Course</b-tag>
              <b-tag type="is-info" v-if="statusFilter" @close="statusFilter = null" closable>Status</b-tag>
              <b-tag type="is-info" v-if="yearFilter" @close="yearFilter = null" closable>Year</b-tag>
              <b-tag
                type="is-info"
                v-if="semesterFilter"
                @close="semesterFilter = null"
                closable
              >Semester
              </b-tag>
              <b-tag
                type="is-info"
                v-if="rplCatFilter"
                @close="rplCatFilter = null"
                closable
              >rpl/cat
              </b-tag>
            </b-taglist>
          </b-field>
        </b-field>
        <b-field v-if="filter === 'learner'">
          <b-autocomplete
            @typing="getFilteredLearner"
            :data="learnerSearchResponse"
            autocomplete="off"
            :icon="$tc('icons.learners')"
            v-model="learnerSearchTerm"
            :placeholder="$tc('Learner',1)"
            :clear-on-select="true"
            @select="option => selectLearner(option)"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field v-if="filter === 'course'">
          <b-autocomplete
            @typing="getFilteredCourse"
            :data="courseSearchResponse"
            autocomplete="off"
            :icon="$tc('icons.courses')"
            v-model="courseSearchTerm"
            :placeholder="$tc('Course',1)"
            :clear-on-select="true"
            @select="option => selectCourse(option)"
          >
            <template slot-scope="props">
              <p>{{ props.option.code }} | {{ props.option.name }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field v-if="filter === 'owner'">
          <b-autocomplete
            @typing="getFilteredOwner"
            :data="ownerSearchResponse"
            autocomplete="off"
            :icon="$tc('icons.users')"
            v-model="ownerSearchTerm"
            placeholder="Owner"
            :clear-on-select="true"
            @select="option => selectOwner(option)"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
    </div>
    <div v-if="isTable && isLoaded">
      <b-field grouped>
        <b-field>

          <b-checkbox-button v-model="bulkChange"
                             :native-value="true"
                             type="is-primary">
            <span>Bulk Change Registrations</span>
          </b-checkbox-button>
        </b-field>
        <b-field v-if="bulkChange">
          <b-select placeholder="Registration Status" v-model="bulkChangeStatus">
            <option :value="null">Unchanged</option>
            <optgroup :key="index" v-for="(group,index) in $store.state.statuses.grouped_statuses"
                      :label="group.name">

              <option :key="status.id" v-for="status in group.statuses" :value="status.id">{{ status.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-field v-if="bulkChange">
          <b-select placeholder="Registration Stage" v-model="bulkChangeStage">
            <option :value="null">Unchanged</option>
            <option v-for="stage in stages" :key="stage.id" :value="stage.id">{{ stage.name }}</option>
          </b-select>
        </b-field>
        <b-field
          v-if="bulkChange">
          <b-select placeholder="Owner" v-model="bulkChangeOwnerId">
            <option :value="null">Unchanged</option>
            <option v-for="user in users" :key="user.id" :value="user.id">{{
                user.first_name
              }} {{ user.last_name }} | {{ user.email }}
            </option>
          </b-select>
        </b-field>
        <b-field v-if="bulkChange">
          <b-button :disabled="bulkChangeRegistrations.length<1" type="is-primary" @click="startBulkChange">
            Save
          </b-button>
        </b-field>
      </b-field>
    </div>
    <b-table
      :checkable="bulkChange"
      :checked-rows.sync="bulkChangeRegistrations"
      v-if="isTable && isLoaded"
      ref="table"
      class="margin-top"
      :data="tableHold"
      :striped="true"
      :hoverable="true"
      :bordered="true"
      narrowed
      backend-sorting
      @sort="onSort"
    >
      <template slot-scope="props">
        <b-table-column label="First name"  field="user.first_name">{{ props.row.user.first_name }}</b-table-column>
        <b-table-column label="Last name"  field="user.last_name">{{ props.row.user.last_name }}</b-table-column>
        <b-table-column
          label="Owner"
          field="owner.first_name"
        >{{ props.row.owner != null ? props.row.owner.first_name : '' }}
          {{ props.row.owner != null ? props.row.owner.last_name : '' }}
        </b-table-column>
        <b-table-column
          label="Creator"
          field="creator.first_name"
        >{{ props.row.creator != null ? props.row.creator.first_name : '' }}
          {{ props.row.creator != null ? props.row.creator.last_name : '' }}
        </b-table-column>
        <b-table-column label="Status" sortable field="registration_status_id">
          {{ props.row.registration_status_id ? processStatusName(props.row.registration_status_id) : 'None' }}
        </b-table-column>
        <b-table-column label="Stage" sortable field="stage_id">{{ props.row.stage.name }}</b-table-column>
        <b-table-column
          :label="$tc('Course',1)"
          field="course.name"
        >{{ props.row.course != null ? props.row.course.name : '' }}
        </b-table-column>
        <b-table-column label="Age" field="age_human_readable">{{ props.row.age_human_readable }}</b-table-column>
        <b-table-column
          sortable
          field="created_at"
          label="Created at"
        >{{ props.row.created_at != null ? (new Date(props.row.created_at).toLocaleString()) : '' }}
        </b-table-column>
        <b-table-column
          sortable
          field="updated_at"
          label="Updated at"
        >{{ props.row.updated_at != null ? (new Date(props.row.updated_at).toLocaleString()) : '' }}
        </b-table-column>
        <b-table-column custom-key="actions" label="View">
          <b-button tag="router-link" :to="`/registrations/edit/${props.row.id}?index_stage=${props.row?.stage_id}`"
                    @click="goToRegistration(props.row.id)" type="is-primary">View
          </b-button>
        </b-table-column>
      </template>
    </b-table>
    <b-pagination
      v-if="isTable && isLoaded"
      class="margin-top"
      :total="meta.total"
      :current="page"
      :range-before="2"
      :range-after="2"
      :per-page="page_limit"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import RegistrationStage from "@/models/RegistrationStage";
import User from "@/models/User";
import Learner from "@/models/Learner";
import Course from "@/models/Course";
import Registration from "@/models/Registration";
import VueCookie from "vue-cookie";

export default {
  name: "RegistrationsIndex",
  components: {
    TitleBar,

  },
  props: {
    team_permission: {
      type: String,
      default() {
        return 'edit registrations'
      }
    },


    default_year: {
      Number,
      default() {
        return new Date().getFullYear() + 1
      }
    }
  },
  data() {
    return {
      bulkChangeRegistrations: [],
      bulkChange: false,
      bulkChangeStatus: null,
      bulkChangeStage: null,
      bulkChangeOwnerId: null,
      tasksFilter: 0,
      isLoaded: false,
      tableHold: [],
      infiniteId: new Date(),
      isTable: true,
      draggingStage: null,
      draggingRegistration: null,
      dragging: false,
      showWonLost: false,
      filter: "",
      statusFilter: null,
      yearFilter: this.default_year,
      semesterFilter: null,
      rplCatFilter: 0,
      value: false,
      noOwner: false,
      noCourse: false,
      learnerSearchTerm: "",
      ownerSearchTermForBulk: "",
      ownerSearchTerm: "",
      courseSearchTerm: "",
      learnerSearchId: null,
      courseSearchId: null,
      ownerSearchId: null,
      learnerSearchResponse: [],
      courseSearchResponse: [],
      ownerSearchResponse: [],
      ownerSearchResponseForBulk: [],
      stageSortedRegistrations: [],
      order_by: null,
      order_direction: null,
      card_page: 1,
      card_page_limit: 50,
      page: 1,
      page_limit: 50,
      meta: Object,
    };
  },

  mounted() {
    User.deleteAll();
    this.$store.dispatch("loader/show");
    // RegistrationStage.deleteAll();
    //
    RegistrationStage.fetchAll();
    let registrations_index_save = JSON.parse(VueCookie.get("registrations_index_save"));

    if (registrations_index_save != null) {
      this.page = registrations_index_save != null ? registrations_index_save.page : 1;
      this.tasksFilter = registrations_index_save != null ? registrations_index_save.tasksFilter : 1;
      if (registrations_index_save != null) {
        this.learnerSearchId =
          registrations_index_save.filters.hasOwnProperty("user_id") != null
            ? registrations_index_save.filters.user_id
            : null;
        this.courseSearchId =
          registrations_index_save.filters.hasOwnProperty("course_id") != null
            ? registrations_index_save.filters.course_id
            : null;
        this.ownerSearchId =
          registrations_index_save.filters.hasOwnProperty("owner_id") != null
            ? registrations_index_save.filters.owner_id
            : null;
        this.statusFilter =
          registrations_index_save.filters.hasOwnProperty("registration_status_id") !=
          null
            ? registrations_index_save.filters.registration_status_id
            : null;
        this.yearFilter =
          registrations_index_save.filters.hasOwnProperty("year") != null
            ? registrations_index_save.filters.year
            : null;
        this.semesterFilter =
          registrations_index_save.filters.hasOwnProperty("semester") != null
            ? registrations_index_save.filters.semester
            : null;
        this.rplCatFilter =
          registrations_index_save.filters.hasOwnProperty("rpl_cat_requested") != null
            ? registrations_index_save.filters.rpl_cat_requested
            : null;
        if (registrations_index_save.filters.hasOwnProperty("owner_id")) {
          if (registrations_index_save.filters.owner_id == null) {
            this.noOwner = true;
          }
        }
        if (registrations_index_save.filters.hasOwnProperty("course_id")) {
          if (registrations_index_save.filters.course_id == null) {
            this.noCourse = true;
          }
        }
      }
      this.$cookie.delete("registrations_index_save");
    } else {
      this.getRegistrations(
        this.card_page,
        this.card_page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        this.tableHold = this.registrations;
        this.isLoaded = true
        this.$store.dispatch("loader/hide");
      });
    }
    User.fetchAll(this.team_permission);
  },
  watch: {
    isTable() {
      this.$store.dispatch("loader/show");

      Registration.deleteAll();
      this.getRegistrations(
        this.page,
        this.page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        this.isLoaded = true;
        this.tableHold = this.registrations;
        this.$store.dispatch("loader/hide");
      });
    },
    filters() {
      this.updateFilters()
    },
  },
  methods: {
    processStatusName(id) {
      return this.$store.state.statuses.statuses.find(status => status.id === parseInt(id)).name
    },
    performChange() {
      this.$store.dispatch('loader/show')
      let registration_ids = []
      this.bulkChangeRegistrations.map(registration => {
        registration_ids.push(registration.id)
      })
      Registration.bulkChange({...{registration_ids: registration_ids}, ...(this.bulkChangeStage ? {stage_id: this.bulkChangeStage} : {}), ...(this.bulkChangeStatus ? {registration_status_id: this.bulkChangeStatus} : {}), ...(this.bulkChangeOwnerId ? {owner_id: this.bulkChangeOwnerId} : {})}).then(response => {
        Registration.deleteAll();
        this.getRegistrations(
          this.page,
          this.page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          this.bulkChangeRegistrations = []
          this.bulkChangeStatus = null
          this.bulkChangeStage = null
          this.bulkChangeOwnerId = null
          this.isLoaded = true;
          this.tableHold = this.registrations;
          this.$buefy.snackbar.open({
            message: response.response.data.message,
            queue: false,
            type: "is-primary"
          });
          this.$store.dispatch("loader/hide");
        });


      }).catch((err) => {
        this.$store.dispatch('loader/hide')

        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      })
    },
    startBulkChange() {
      this.$buefy.dialog.confirm({
        title: "Bulk Change",
        message:
          "Are you sure you want to edit these registrations?",
        confirmText: "Perform bulk change",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.performChange(),
      });
    },
    updateFilters() {
      if (this.isTable && this.isLoaded) {
        this.$store.dispatch("loader/show");
        this.page = 1;

        Registration.deleteAll();
        this.getRegistrations(
          this.page,
          this.page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          VueCookie.set(
            "registrations_index_save",
            JSON.stringify({
              filters: this.filters,
              page: this.page,
              isTable: this.isTable,
              route: this.$router.currentRoute.path,
              tasksFilter: this.tasksFilter
            })
          );
          this.tableHold = this.registrations;

          this.$store.dispatch("loader/hide");
        });
      }
      if (!this.isTable) {
        this.card_page = 1;
        Registration.deleteAll();

        this.infiniteId++;
        this.$store.dispatch("loader/show");
        this.getRegistrations(
          this.card_page,
          this.card_page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          VueCookie.set(
            "registrations_index_save",
            JSON.stringify({
              filters: this.filters,
              page: this.page,
              isTable: this.isTable,
              route: this.$router.currentRoute.path,
              tasksFilter: this.tasksFilter
            })
          );
          this.$store.dispatch("loader/hide");
          this.$forceUpdate();
        });
      }
    },
    onSort(column, sort) {
      this.order_by = column;
      this.order_direction = sort;
      this.setPage(this.page);
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      Registration.deleteAll();
      this.page = pageNumber;
      this.getRegistrations(
        this.page,
        this.page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        VueCookie.set(
          "registrations_index_save",
          JSON.stringify({
            filters: this.filters,
            page: this.page,
            isTable: this.isTable,
            route: this.$router.currentRoute.path,
            tasksFilter: this.tasksFilter
          })
        );
        this.tableHold = this.registrations;
        this.$store.dispatch("loader/hide");
      });
    },

    startDrag(evt, stageId) {
      this.dragging = true;
      this.draggingStage = stageId;
      /**
       * @param evt          The drag event.
       * @param evt.originalEvent   Contains the element of the dragged stage
       */
      this.draggingRegistration = evt.originalEvent.target.id;
    },
    endDrag() {
      this.dragging = false;
      this.draggingStage = null;
      this.draggingRegistration = null;
    },
    setTasksFilter(bool) {
      this.tasksFilter = bool;
      this.updateFilters()
    }, setNoOwner() {
      this.ownerSearchId = null;
      this.noOwner = true;
    },
    setNoCourse() {
      this.courseSearchId = null;
      this.noCourse = true;
    },
    twiddle() {
      this.setSortedRegistrations();
      this.$forceUpdate();
    },
    selectCourse(option) {
      this.courseSearchId = option.id;
      this.courseSearchResponse = [];
    },
    selectOwner(option) {
      this.noOwner = false;
      this.ownerSearchId = option.id;
      this.ownerSearchResponse = [];
    },
    selectOwnerForBulk(option) {
      this.bulkChangeOwnerId = option.id;
      // this.ownerSearchResponseForBulk = [];
    },
    selectLearner(option) {
      this.learnerSearchId = option.id;
      this.learnerSearchResponse = [];
    },
    getFilteredOwnerForBulk(text) {
      this.ownerSearchResponseForBulk = User.query()
        .search(text, {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    }, getFilteredOwner(text) {
      this.ownerSearchResponse = User.query()
        .search(text, {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.learnerSearchResponse = result.entities.learners;
        } else {
          this.learnerSearchResponse = [];
        }
      });
    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.search(text, true).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.courseSearchResponse = result.entities.courses;
        } else {
          this.courseSearchResponse = [];
        }
      });
    },
    registrationOwner(owner_id) {
      let owner = User.find(parseInt(owner_id));

      if (owner) {
        return `${owner.first_name} ${owner.last_name}`;
      } else {
        return `None`;
      }
    },
    goToRegistration() {
      VueCookie.set(
        "registrations_index_save",
        JSON.stringify({
          filters: this.filters,
          page: this.page,
          isTable: this.isTable,
          route: this.$router.currentRoute.path,
          tasksFilter: this.tasksFilter
        })
      );

    },

    setSortedRegistrations() {
      this.stages.forEach((element) => {

        this.stageSortedRegistrations[element.id - 1] = Registration.query()
          .with("user")
          .with("course")
          .where((registration) => {
            for (var key in this.filters) {
              if (registration[key] != this.filters[key]) return false;
            }
            return true;
          })
          .where("stage_id", parseInt(element.id))
          .orderBy("created_at", "desc")
          .get();
      });
      this.value = true;
    },
    onChange(evt, stage) {
      if (evt.hasOwnProperty("added")) {
        let registration = evt.added.element;

        Registration.update({
          where: registration.id,
          data: {
            stage_id: stage,
          },
        }).then((result) => {
          Registration.updateStage(result);
        });
      }
    },

    infiniteHandler($state) {
      return Registration.fetchAll(
        this.card_page + 1,
        this.card_page_limit,
        {
          ...this.filters, ...(this.tasksFilter
            ? {
              has_incomplete_tasks: this.tasksFilter,
            }
            : {}),
          ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {order_by: "created_at"}),
          ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {order_direction: "desc"}),
        },
        true
      ).then(({response}) => {
        if (response.data.data.length > 0) {
          this.meta = response.data.meta;
          this.card_page++;
          this.twiddle();
          $state.loaded();
          return;
        }
        $state.complete();
      });
    },
    getRegistrations(page, limit, query) {
      return Registration.fetchAll(
        page,
        limit,
        {
          ...query,
           ...(this.tasksFilter
            ? {
              has_incomplete_tasks: this.tasksFilter,
            }
            : {}),
          ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {order_by: "created_at"}),
          ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {order_direction: "desc"}),
        },
        false
      ).then((result) => {
        if (result.response.data.meta) {
          this.meta = result.response.data.meta;
        }
        this.setSortedRegistrations();
      });
    },
  },

  computed: {

    filters() {
      return {
        ...(this.statusFilter
          ? {
            registration_status_id: this.statusFilter,
          }
          : {}),
        ...(this.yearFilter
          ? {
            year: this.yearFilter,
          }
          : {}),
        ...(this.semesterFilter
          ? {
            semester: this.semesterFilter,
          }
          : {}),
        ...(this.rplCatFilter
          ? {
            rpl_cat_requested: this.rplCatFilter,
          }
          : {}),
        ...(this.learnerSearchId
          ? {
            user_id: this.learnerSearchId,
          }
          : {}),
        ...(this.courseSearchId
          ? {
            course_id: this.courseSearchId,
          }
          : {}),
        ...(this.ownerSearchId
          ? {
            owner_id: this.ownerSearchId,
          }
          : {}),
        ...(!this.noOwner ? {} : {owner_id: null}),
        ...(!this.noCourse ? {} : {course_id: null}),
      };
    },

    registrations() {
      return Registration.query()
        .with("user")
        .with("owner")
        .with("creator")
        .with("course")
        .with("stage")
        .with('status')
        .where((registration) => {
          for (var key in this.filters) {
            if (registration[key] != this.filters[key]) return false;
          }
          return true;
        })
        .orderBy(
          this.order_by != null ? this.order_by : "created_at",
          this.order_direction != null ? this.order_direction : "desc"
        )
        .get();
    },
    administrate_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "administrate registrations"
      );
    },
    learners() {
      return Learner.all();
    },
    users() {
      return User.all();
    },
    stages() {
      return RegistrationStage.query().orderBy("order", "asc").get();
    },
    search() {
      return Learner.query()
        .search(".com", {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>

a:visited {
  color: unset !important;
}


</style>
