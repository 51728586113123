<template>
  <section class="section">
    <title-bar>Edit a role</title-bar>
    <card-component
      title="Role Form"
      v-on:header-icon-click="toggleRoleDetailsLock()"
      :header-icon="roleDetailsLockIcon"
      :icon="$tc('icons.roles')"
    >
      <form @submit.prevent="submit">
        <b-field label="Name" horizontal>
          <b-field>
            <b-input
              :disabled="roleDetailsLock"
              autocomplete="off"
              v-model="role.name"
              placeholder="Name"
              name="name"
              required
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Permission search">
          <b-input v-model="search" placeholder="Search by name" name="search" />
        </b-field>
        <b-field>
          <b-field label="Permissions" horizontal>
            <b-table
              :header-checkable="false"
              class="margin-top"
              :data="processedPermissions"
              :striped="true"
              :hoverable="true"
              :bordered="true"
            >
              <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{ props.row.id }}</b-table-column>

              <b-table-column v-slot="props" sortable field="attributes.name" label="Name">{{ props.row.name }}</b-table-column>

              <b-table-column v-slot="props"
                width="120"
                class="has-text-centered"
                label="Active"
                custom-key="actions"
              >
                <b-checkbox
                  v-model="role.permissions"
                  :disabled="roleDetailsLock"
                  :native-value="props.row.id"
                ></b-checkbox>
              </b-table-column>
            </b-table>
          </b-field>
        </b-field>
        <hr />
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button :disabled="roleDetailsLock" native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Role from "@/models/Role";
import Permission from "@/models/Permission";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  props: ["id"],
  data() {
    return {
      search: "",
      role: {
        id: this.id,
        name: "",
        permissions: [],
      },
      permissions: [],
      roleDetailsLock: true,
    };
  },

  computed: {
    roleDetailsLockIcon() {
      if (this.roleDetailsLock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    processedPermissions() {
      let store = [];
      this.permissions.forEach((permission) => {
        permission.name = permission.attributes.name;
        store.push(permission);
      });
      return store.filter((permission) =>
        permission.name.includes(this.search)
      );
    },
  },
  methods: {
    toggleRoleDetailsLock() {
      if (this.roleDetailsLock == true) {
        if (this.id == 1) {
          this.$buefy.dialog.alert({
            title: "Editing Forbidden",
            message: "The super admin role cannot be edited.",
            type: "is-danger",
            hasIcon: true,
          });
          return;
        }
        this.$buefy.dialog.confirm({
          title: "Start Editing Role",
          message:
            "Are you sure you want to edit this role? Note that roles affect access and actions across the entire site.",
          confirmText: "I am sure",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => (this.roleDetailsLock = false),
        });
        return;
      }
      this.roleDetailsLock = true;
    },
    getFilteredSubject(text) {
      this.subjectSearchTerm = text;
    },
    submit() {
      this.$store.dispatch("loader/show");
      Role.updateRole(this.role)
        .then(() => {
          this.$store.dispatch("loader/hide");
          this.$buefy.snackbar.open({
            message: "Role updated",
            queue: false,
            type: "is-link",
          });
          setTimeout(() => {
            this.$router.push(`/roles`);
          }, 1000);
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data.message),
          });
        });
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Permission.fetchAll()
      .then(({ response }) => {
        this.permissions = response.data.data;
      })
      .catch((err) => {
        this.$store.dispatch("loader/hide");

        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data.message),
        });
      });
    Role.fetchSingleWithPermissions(this.id)
      .then(({ response }) => {
        this.role.permissions = response.data.data.attributes.permissions.map(
          (permission) => (permission = permission.id)
        );
        this.role.name = response.data.data.attributes.name;
        this.$store.dispatch("loader/hide");
      })
      .catch((err) => {
        this.$store.dispatch("loader/hide");

        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data.message),
        });
      });
  },
};
</script>
