<template>
  <QualificationForm :id="id" :can-create="can_create_qualifications" :can-delete="can_delete_qualifications" :can-edit="can_edit_qualifications" edit/>
</template>

<script>
import QualificationForm from "@/components/Qualifications/QualificationForm";

export default {
  props: ['id'],
  name: 'QualificationEdit',
  components: {
    QualificationForm,

  },
  computed: {
    parsedId() {
      return parseInt(this.id)
    },
    can_delete_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("delete qualifications");
    }, can_edit_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("edit qualifications");
    }, can_create_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("create qualifications");
    }
  }
};
</script>
