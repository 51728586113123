import {
  TransformModel
} from '@/models/TransformModel'
import RegistrationStage from "@/models/RegistrationStage";

export default class RegistrationBoard extends TransformModel {
  static entity = 'registrationBoards'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      order: this.attr(null),
      stages:this.hasMany(RegistrationStage,'board_id')
    }
  }



}
