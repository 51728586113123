<template>
  <div ref="shadowHost"></div>
</template>

<script>
export default {
  props: ['htmlContent'],
  mounted() {
    const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' });
    shadowRoot.innerHTML = this.htmlContent;
  },
  watch: {
    htmlContent(newVal) {
      const shadowRoot = this.$refs.shadowHost.shadowRoot;
      shadowRoot.innerHTML = newVal;
    }
  }
}
</script>
