<template>
  <div v-if="loaded && this.registration">
    <section class="section">
      <div class="box columns">
        <div class="column">
          <div class="columns">
            <div class="column is-6">
              <p class="title">
                <a
                  :href="`/learners/edit/${learner.id}`"
                  class="has-text-underlined"
                >{{ learner.first_name }} {{ learner.last_name }}</a
                >
                <b-button class="ml-1" icon-right="pencil" inverted type="is-primary"
                          @click="startChangeUser()">
                </b-button>
              </p>

              <p>Enrolment date: {{ registration_details.enrolment_date }}</p>
              <p>Registration created: {{ registration?.age_human_readable }}</p>
              <p>Returning: {{ registration_details.is_returning ? "Yes" : "No"}}</p>


              <b-dropdown aria-role="list" class="margin-top">
                <template #trigger="{active}">
                  <button
                    class="button is-light"
                  >
                    <span>Actions</span>
                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                  </button>
                </template>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="() => {
                   acceptance_modal = true;
                    acceptance_letter_action = 'download';
                  }"
                >Download acceptance letter
                </b-dropdown-item>

                <b-dropdown-item
                  aria-role="listitem"
                  @click="() => {
                    acceptance_modal = true;
                    acceptance_letter_action = 'send';
                  }"
                >Email acceptance letter to learner
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="registration_details.registration_status_id == 3 || registration_details.registration_status_id == 21"
                  aria-role="listitem"
                  @click="() => {
                    proof_modal = true;
                    proof_letter_action = 'download';
                  }"
                >Download proof of registration
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="registration_details.registration_status_id == 3"
                  aria-role="listitem"
                  @click="() => {
                    proof_modal = true;
                    proof_letter_action = 'email';
                  }"
                >Email proof of registration to learner
                </b-dropdown-item>



              </b-dropdown>

              <b-field label="Grade Hold" class="margin-top">
                <b-switch :false-value="0"
                          :true-value="1"
                          v-model="gradeHold"
                >{{gradeHold === 0 ? "No" : "Yes" }}</b-switch>
              </b-field>

              <div class="prevNextButtons mt-5">
                <b-tooltip label="Previous registration"
                           type="is-dark"
                           position="is-top">
                  <b-button class="mr-1 is-bold" icon-left="chevron-left" type="is-primary" :disabled="!responseMeta?.previous" @click="routeToRegistration(responseMeta?.previous)"></b-button>
                </b-tooltip>
                <b-tooltip label="Next registration"
                           type="is-dark"
                           position="is-top">
                  <b-button class="ml-1 is-bold" icon-right="chevron-right" type="is-primary" :disabled="!responseMeta?.next" @click="routeToRegistration(responseMeta?.next)"></b-button>
                </b-tooltip>
              </div>
            </div>

            <div class="column is-6">
              <div class="columns is-multiline  is-vcentered">
                <div class="column is-6 px-0">
                  <b-field label="Status">
                    <b-select
                      v-model="registration_status"
                      :disabled="!can_edit_registration"
                      :loading="registrationStatus"
                    >
                      <optgroup v-for="(group,index) in $store.state.statuses.grouped_statuses" :key="index"
                                :label="group.name">

                        <option v-for="status in group.statuses" :key="status.id" :value="status.id">{{ status.name }}
                        </option>
                      </optgroup>

                    </b-select>
                  </b-field>
                </div>
                <div class="column is-6 px-0">
                  <b-field label="Stage">
                    <b-select
                      v-model="registration_stage"
                      :disabled="!can_edit_registration"
                      :loading="moving"
                    >
                      <optgroup v-for="board in minItemBoards" :key="board.name" :label="board.name">
                        <option v-for="stage in orderedStages(board.id)" :key="stage.name" :value="stage.id">
                          {{ stage.name }}
                        </option>
                      </optgroup>
                    </b-select>
                  </b-field>


                </div>
                <div class="margin-top">
                  <b-field label="Grade Status">
                    <b-select
                      v-model="grade_status"
                      :disabled="!administrate_registrations"
                      placeholder="Select the registration status"
                      :loading="gradeStatusLoading"
                    >
                      <option key="1" value=1>Enrolled</option>
                      <option key="2" value=2>Probation</option>
                      <option key="3" value=3>Extended Probation</option>
                      <option key="4" value=4>Dismissed</option>
                    </b-select>
                  </b-field>
                  <b-field label="Assign to">
                    <b-select
                      v-model="owner"
                      :disabled="!can_edit_registration"
                      :icon="$tc('icons.users')"
                      placeholder="Select the registration owner"
                    >
                      <option
                        v-for="user in admissions_team"
                        :key="user.id"
                        :value="user"
                      >
                        <p>
                          {{ user.first_name }} {{ user.last_name }} |
                          {{ user.email }}
                        </p>
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Registration Creator">
                    <b-select
                      v-model="creator"
                      :disabled="!administrate_registrations"
                      :icon="$tc('icons.users')"
                      placeholder="Select the registration creator"
                    >
                      <option
                        v-for="user in admissions_team"
                        :key="user.id"
                        :value="user"
                      >
                        <p>
                          {{ user.first_name }} {{ user.last_name }} |
                          {{ user.email }}
                        </p>
                      </option>
                    </b-select>
                  </b-field>
                  <b-loading
                    :active.sync="setting_owner"
                    :can-cancel="false"
                    :is-full-page="false"
                  ></b-loading>
                </div>
              </div>
            </div>
          </div>
          <b-progress
            v-if="current_stage"
            :value="stageValue"
            show-value
            size="is-medium"
            type="is-success"
          >
            <p class="has-text-black">{{ current_stage.name }}</p>
          </b-progress>
        </div>
      </div>
    </section>
    <section class="section">
      <div class="columns">
        <div class="column is-one-third">
          <div class="columns">
            <div class="column is-12 box">
              <card-component
                :header-icon="registration_details_lock_icon"
                :icon="$tc('icons.registrations')"
                title="Registration Details"
                v-on:header-icon-click="toggleRegistrationDetailsLock()"
              >
                <form @submit.prevent="submitRegistrationDetails">
                  <b-field group-multiline grouped>
                    <b-field v-if="!registration_details.qualification && !qualificationHold" expanded>The
                      associated qualification is not available. It may have been deleted.
                    </b-field>
                    <b-field label="Qualification" expanded>
                      <b-autocomplete
                        v-model="qualificationHold"
                        v-debounce:300ms="getFilteredQualification"
                        :clear-on-select="false"
                        :data="filteredQualifications"
                        :disabled="registration_details_lock"
                        :icon="$tc('icons.courses')"
                        :loading="loadingQualifications"
                        placeholder="Qualification Name"
                        autocomplete="off"
                        expanded
                        field="name"
                        name="Qualification"
                        required
                        @select="(option) => selectQualification(option)"
                      >
                        <template slot-scope="props">
                          <p>
                            {{ props.option.code }} | {{ props.option.name }}
                          </p>
                        </template>
                      </b-autocomplete>
                    </b-field>

                    <b-field v-if="registration_details.course_id && !registration_details.course" expanded>The
                      associated
                      {{ $tc('course', 1) }} is not available. It may have been deleted.
                    </b-field>
                    <b-field :label="$tc('Course', 1)" expanded>
                      <b-autocomplete
                        v-model="courseHold"
                        v-debounce:300ms="getFilteredCourse"
                        :clear-on-select="false"
                        :data="filteredCourses"
                        :disabled="registration_details_lock"
                        :icon="$tc('icons.courses')"
                        :loading="loadingCourses"
                        :placeholder="$tc('Course', 1) + ' Name'"
                        autocomplete="off"
                        expanded
                        field="name"
                        name="Course"
                        required
                        @select="(option) => selectCourse(option)"
                      >
                        <template slot-scope="props">
                          <p>
                            {{ props.option.code }} | {{ props.option.name }}
                          </p>
                        </template>
                      </b-autocomplete>
                    </b-field>
                  </b-field>
                  <b-field
                    v-if="registration_details.course"
                    label="Intake"
                  >
                    <b-select
                      v-model="current_intake"
                      :disabled="registration_details_lock"
                      placeholder="Select an Intake"
                    >
                      <option
                        v-for="intake in intakes"
                        :key="intake.id"
                        :value="intake.id"
                      >
                        {{ intake.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field
                    v-if="registration_details.course"
                    label="Delivery Type"
                  >
                    <b-select
                      v-model="delivery_type"
                      :disabled="registration_details_lock"
                      placeholder="Select a delivery type"
                      required
                    >
                      <option
                        v-if="
                          registration_details.course.delivery_type_id == 1 ||
                          registration_details.course.delivery_type_id == 3
                        "
                        value="1"
                      >
                        Contact
                      </option>
                      <option
                        v-if="
                          registration_details.course.delivery_type_id == 2 ||
                          registration_details.course.delivery_type_id == 3
                        "
                        value="2"
                      >
                        Online
                      </option>
                    </b-select>
                  </b-field>
                  <b-field horizontal label="Year of Study">
                    <b-field>
                      <b-select v-model="registration_details.level"
                                :disabled="registration_details_lock" placeholder="Select the registration year"
                                required>
                        <option v-for="level in 5" :key="level" :value="level">{{ level }}</option>
                      </b-select>
                    </b-field>
                  </b-field>
                  <b-field label="Year of Entry">
                    <b-field>
                      <b-input
                        v-model="registration_details.year"
                        :disabled="registration_details_lock"
                        type="number"
                      ></b-input>
                    </b-field>
                  </b-field>
                  <b-field label="Semester of Entry">
                    <b-field>
                      <b-select
                        v-model="registration_details.semester"
                        :disabled="registration_details_lock"
                        placeholder="Select the semester"
                      >
                        <option v-for="semester in semesters" :key="semester.name" :value="semester.id">{{ semester.name }}</option>
                      </b-select>
                    </b-field>
                  </b-field>
                  <b-field label="Enrolment Date">
                    <b-datepicker
                      v-model="enrolment_date"
                      :disabled="registration_details_lock"
                      :years-range="[-50, 30]"
                      icon="calendar-today"
                      placeholder="Click to select..."
                      trap-focus
                    ></b-datepicker>
                  </b-field>
                  <b-field label="Marketing Source">
                    <b-select
                      v-model="registration_details.marketing_source_id"

                      :disabled="registration_details_lock"
                      placeholder="Select the marketing source"
                    >
                      <option
                        v-for="source in sources"
                        :key="source.id"
                        :value="source.id"
                      >
                        {{ source.name }}
                      </option>
                    </b-select>
                  </b-field>
                  <b-field label="Message">
                    <b-input
                      v-model="registration_details.message"

                      :disabled="registration_details_lock"
                      placeholder="Explain how we can help you"
                      type="textarea"
                    />
                  </b-field>
                  <b-field label="RPL/CAT requested">
                    <b-checkbox
                      v-model="registration_details.rpl_cat_requested"
                      :disabled="registration_details_lock"
                      :true-value="1"
                    ></b-checkbox>
                  </b-field>
                  <!--                  <b-field v-if="registration_details.rpl_cat_requested" label="Exemption Type">-->
                  <!--                    <b-select v-model="registration_details.exemption_type_id"-->
                  <!--                              :disabled="registration_details_lock">-->
                  <!--                      <option :value="1">RPL for Access</option>-->
                  <!--                      <option :value="2">RPL for Exemption</option>-->
                  <!--                      <option :value="3">CAT</option>-->

                  <!--                    </b-select>-->

                  <!--                  </b-field>-->
                  <b-field label="Disability Indicated">
                    <b-switch v-model="disability" :false-value="false" :true-value="true" :disabled="true">
                      {{ disability === true ? "Yes" : "No" }}
                    </b-switch>
                  </b-field>
                  <b-field>
                    <b-field>
                      <div class="columns is-mobile">
                        <div class="column is-6 has-text-left">
                          <b-button
                            :disabled="registration_details_lock || dropFile"
                            :loading="savingRegistrationDetails"
                            native-type="submit"
                            type="is-primary"
                          >Submit
                          </b-button
                          >
                        </div>
                        <div class="column is-6 has-text-right">
                          <b-button
                            v-if="delete_registrations"
                            :disabled="registration_details_lock || dropFile"
                            type="is-danger"
                            @click="start_delete_registration(registration.id)"
                          >Delete
                          </b-button
                          >
                        </div>
                      </div>
                    </b-field>
                  </b-field>
                </form>

              </card-component>
            </div>
          </div>
          <div class="columns margin-top">
            <div class="column box">
              <card-component
                :header-icon="learner_details_lock_icon"
                :icon="$tc('icons.learners')"
                :title="$tc('Learner', 1) + ' Details'"
                v-on:header-icon-click="toggleLearnerDetailsLock()"
              >
                <form @submit.prevent="submitLearnerDetails">
                  <b-field label="First Name">
                    <b-field>
                      <b-input
                        v-model="learner_details.first_name"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.learners')"
                        autocomplete="off"
                        name="first_name"
                        placeholder="First Name"
                        required
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Last Name">
                    <b-field>
                      <b-input
                        v-model="learner_details.last_name"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.learners')"
                        autocomplete="off"
                        name="last_name"
                        placeholder="Last Name"
                        required
                      />
                    </b-field>
                  </b-field>
                  <b-field :label="$tc('Username', 1)">
                    <b-field>
                      <b-input
                        v-model="learner_details.username"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.emails')"
                        :name="$tc('username', 1)"
                        :placeholder="$tc('Username', 1)"
                        autocomplete="off"
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Student Number">
                    <b-field>
                      <b-input
                        :disabled="learner_details_lock"

                        :icon="$tc('icons.learners')"
                        :value="learner_details.student_number"
                        autocomplete="off"
                        name="student_number"
                        placeholder="Student Number missing"
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Email Address">
                    <b-field>
                      <b-input
                        v-model="learner_details.email"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.emails')"
                        autocomplete="off"
                        name="email"
                        placeholder="E-mail"
                        required
                        type="email"
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Primary Contact Number">
                    <b-field>
                      <b-input
                        v-model="learner_details.primary_contact_number"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.phone')"
                        expanded
                        name="phone"
                        placeholder="072 555 5555"
                        type="tel"
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Secondary Contact Number">
                    <b-field>
                      <b-input
                        v-model="learner_details.secondary_contact_number"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.phone')"
                        expanded
                        name="phone"
                        placeholder="072 555 5555"
                        type="tel"
                      />
                    </b-field>
                  </b-field>
                  <b-field :label="$tc('Cellphone', 1) + ' Number'">
                    <b-field>
                      <b-input
                        v-model="learner_details.cellphone_number"
                        :disabled="learner_details_lock"
                        :icon="$tc('icons.phone')"
                        expanded
                        name="phone"
                        placeholder="072 555 5555"
                        type="tel"
                      />
                    </b-field>
                  </b-field>
                  <b-field label="Personal title">
                    <b-select
                      v-model="learner_details.title"
                      :disabled="learner_details_lock"
                      placeholder="Select a title"
                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                    </b-select>
                  </b-field>
                  <b-field>
                    <b-field grouped>
                      <div class="control">
                        <b-button
                          :disabled="learner_details_lock"
                          :loading="savingLearnerDetails"
                          native-type="submit"
                          type="is-primary"
                        >Submit
                        </b-button
                        >
                      </div>
                    </b-field>
                  </b-field>
                </form>
              </card-component>
            </div>
          </div>
        </div>
        <div ref="tabs" class="column margin-left is-two-thirds">
          <div class="columns box" style="width: 100%;">
            <div class="column" style="width: 100%;">
              <b-tabs v-model="activeTab" multiline>
                <b-tab-item :icon="$tc('icons.modules')" label="Modules">
                  <b-field label="Add Module">
                    <b-autocomplete
                      v-model="selectedModule"
                      :disabled="registration_details_lock"
                      :clear-on-select="true"
                      :data="filteredModulesForAdd"
                      :icon="$tc('icons.modules')"
                      :loading="loadingModuleAdd"
                      autocomplete="off"
                      field="name"
                      name="module"
                      placeholder="Module name"
                      @select="option => selectModule(option)"
                      @typing="text=>getFilteredModuleForAdd(text,modules)"
                    >
                      <template v-slot="props">
                        <p>{{ props.option.attributes.code }} | {{ props.option.attributes.name }}</p>
                      </template>
                    </b-autocomplete>
                  </b-field>
                  <b-field label="Modules">
                    <b-table
                      :bordered="true"
                      :data="filteredModules"
                      :header-checkable="false"
                      :hoverable="true"
                      :striped="true"
                      class="margin-top"
                      narrowed
                      scrollable
                    >
                      <b-table-column v-slot="props"
                                      field="code"
                                      label="Code"
                                      numeric
                                      sortable
                                      width="40"
                      >{{ props.row.code }}
                      </b-table-column
                      >

                      <b-table-column v-slot="props" field="name" label="Name" sortable>{{
                          props.row.name
                        }}
                      </b-table-column>
                      <b-table-column v-slot="props"
                                      field="level"
                                      label="Level (year of study)"
                                      sortable
                      >{{ props.row.level }}
                      </b-table-column
                      >
                      <b-table-column v-slot="props"
                                      field="subject.name"
                                      label="Subject"
                                      sortable
                      >{{ props.row.subject.name }}
                      </b-table-column
                      >
                      <b-table-column v-slot="props" field="standardised_level_id" label="Standardised Level" sortable>{{
                          props.row.standardised_level_id ? $store.state.levels.levels.find(level => level.id === props.row.standardised_level_id).name : 'None'
                        }}
                      </b-table-column>

                      <b-table-column v-slot="props"


                                      label="Semester"
                      >

                        <b-field v-if="props.row.semesters.length>1 || props.row.semester && props.row.semester != props.row.semesters[0].id">
                          <b-select :disabled="registration_details_lock||!selectedModuleIds.includes(props.row.id)"
                                    :required="selectedModuleIds.includes(props.row.id)"
                                    :value="selectedSemester(props.row)"
                                    placeholder="Semester"
                                    @input="selectSemester($event,props.row)">
                            <option v-for="semester in filterSemesters(props.row.semesters)" :key="semester.id" :value="semester.id">
                              {{ semester.name }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field v-else-if="props.row.semesters.length===1">
                          {{ props.row.semesters[0].name }}
                        </b-field>
                      </b-table-column>

                      <b-table-column
                        field="credits"
                        label="Credits |:"
                        numeric
                        sortable
                      >
                        <template v-slot:header="{column}"
                        >{{ column.label }} {{ totalCredits }}
                        </template
                        >
                        <template v-slot="props">{{ props.row.credits }}</template>
                      </b-table-column>
                      <b-table-column v-slot="props"
                                      field="status_id"
                                      label="Status"

                                      sortable
                      >

                        <b-field>
                          <b-select v-model="props.row.status_id"
                                    :disabled="registration_details_lock||!selectedModuleIds.includes(props.row.id)"
                                    :loading="loadingModuleStatus===props.row.id" :required="selectedModuleIds.includes(props.row.id)"
                                    placeholder="Status" @input="changeModuleStatus($event,props.row.id)">
                            <option v-for="status in moduleStatuses" :key="status.id" :value="status.id">
                              {{ status.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </b-table-column>
                      <b-table-column v-slot="props" :label="`${$tc('lms',2)} ID`">
                        <span>{{ props.row.lms_id !== null ? props.row.lms_id : 'Not synced' }}</span>
                      </b-table-column>
                      <b-table-column v-slot="props"
                                      class="has-text-centered"
                                      custom-key="actions"
                                      label="Selected"
                      >
                        <b-checkbox
                          v-model="selectedModuleIds"
                          :disabled="registration_details_lock"
                          :native-value="props.row.id"
                          @click.native.prevent="
                              checkLevel($event, props.row)
                            "
                        ></b-checkbox>
                      </b-table-column>
                    </b-table>
                  </b-field>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.media')" label="Verify">
                  <DocumentVerificationPanel :course_id="registration?.course_id" :learner_id="learner?.id"/>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.media')" label="Media">
                  <card-component
                    :icon="$tc('icons.media')"
                    title="Supporting Documents"
                  >
                    <div class="columns">
                      <b-field class="column is-4">
                        <div class="columns is-centered">
                          <div class="column is-12 has-text-centered">
                            <b-upload v-model="dropFile" drag-drop>
                              <section class="section">
                                <div class="content has-text-centered">
                                  <p>
                                    <b-icon
                                      icon="upload"
                                      size="is-large"
                                    ></b-icon>
                                  </p>
                                  <p>Drop your file here or click to upload</p>
                                  <p>Max file size of 10mb</p>
                                </div>
                              </section>
                            </b-upload>
                          </div>
                        </div>
                      </b-field>
                      <div v-if="dropFile" class="upload-file-list column">
                        <div class="notification">
                          <div class="level">
                            <div class="level-left">
                              <div class="level-item">
                                <form @submit.prevent="submitMedia">
                                  <b-icon
                                    custom-size="default"
                                    icon="cloud-upload"
                                  ></b-icon>
                                  <span>{{ dropFile.name }}</span>
                                  <b-field>
                                    <b-field>
                                      <b-input
                                        v-model="dropFile.caption"
                                        name="caption"
                                        placeholder="Describe the upload"
                                      />
                                    </b-field>
                                  </b-field>
                                  <!-- <b-field>
                                    <b-select
                                      v-model="dropFile.tag"
                                      placeholder="Select a tag"
                                      required
                                    >
                                      <option
                                        v-for="(tag,index) in $store.state.applications.tags"
                                        :value="tag"
                                        :key="index"
                                      >{{ tag }}</option>
                                    </b-select>
                                  </b-field>-->
                                  <b-field>
                                    <b-field grouped>
                                      <div class="control">
                                        <b-button
                                          native-type="submit"
                                          type="is-primary"
                                        >Submit upload
                                        </b-button
                                        >
                                      </div>
                                    </b-field>
                                  </b-field>
                                </form>
                              </div>
                            </div>
                            <div class="level-right">
                              <div class="level-item">
                                <a
                                  class="tag is-delete"
                                  @click.prevent="deleteDropFile()"
                                ></a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <b-table
                      :bordered="true"
                      :data="media"
                      :hoverable="true"
                      :striped="true"
                      class="margin-top"
                    >
                      <b-table-column v-slot="props"
                                      field="filename"
                                      label="Filename"
                                      sortable
                      >{{ props.row.filename }}
                      </b-table-column
                      >

                      <!-- <b-table-column v-slot="props"
                        sortable
                        field="tag"
                        label="Tag"
                      >{{ props.row.tag }}</b-table-column>-->
                      <b-table-column v-slot="props"
                                      field="caption"
                                      label="Caption"
                                      sortable
                      >{{
                          return_caption(props.row.caption)
                        }}
                      </b-table-column
                      >
                      <b-table-column v-slot="props" centered custom-key="actions">

                        <b-field>
<span class="control">
   <b-button
     :href="props.row.temporary_url"
     tag="a"
     target="_blank"
     type="is-info"
   >View
                          </b-button
                          >
</span>
                          <span
                            class="control">
              <b-button
                type="is-danger"
                @click="start_delete_media(props.row.id)"
              >Delete
                          </b-button
                          >
</span>
                        </b-field>
                      </b-table-column>

                    </b-table>
                  </card-component>
                  <b-loading
                    :active.sync="updatingMedia"
                    :can-cancel="false"
                    :is-full-page="false"
                  ></b-loading>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.invoices')" label="Invoices">
                  <InvoicePanel v-if="activeTab===3" :admin="true" :invoiceable-id="this.$route.params.id" :invoiceable-type="'registration'" :learner_id="learner.id"/>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.languages')" label="Languages">
                  <Languages v-if="activeTab===4" :admin="true" :can-edit="!registration_details_lock" :course="course" :edit="false" :learner_id="learner.id"/>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.courses')" label="Specialisations">
                  <CoreOptions v-if="activeTab===5" :admin="true" :can-edit="!registration_details_lock" :course="course" :edit="false" :learner_id="learner.id"/>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.courses')" label="Conditions">
                  <RegistrationConditionsPanel v-if="activeTab===6" :id="id"/>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.notes')" label="Notes">
                  <ckeditor
                    v-model="editorData"
                    :config="editorConfig"
                    :editor="editor"
                  ></ckeditor>
                  <b-button
                    :loading="savingNote"
                    class="margin-top"
                    type="is-primary"
                    @click="saveNote"
                  >Submit
                  </b-button
                  >
                  <b-loading
                    :active.sync="savingNote"
                    :is-full-page="false"
                  ></b-loading>
                </b-tab-item>
                <b-tab-item :icon="$tc('icons.activities')" label="Activity">
                  <activityForm
                    :action="editingActivity ? 'edit' : 'create'"
                    :activity="activityToEdit ? activityToEdit : null"
                    :model_id="parseInt(id)"
                    :model_type="'registrations'"
                    :permissions="{
                      can_create: can_edit_registration,
                      can_edit: can_edit_registration,
                    }"
                    :team="admissions_team"
                    @cancelActivity="editingActivity = false"
                  ></activityForm>
                </b-tab-item>
                <b-tab-item v-if="can_create_emails" icon="email" label="Email">
                  <emailForm
                    :default_recipient="learner"
                    :permissions="{ can_create: can_edit_registration }"
                    :reply_email="reply_email"
                    :username_missing="$store.state.userEmail == null"
                  ></emailForm>
                </b-tab-item>
                <b-tab-item v-if="!!$store.getters['entities/permissions/find']('view grades')" :icon="$tc('icons.gradebook')"
                            label="Gradebook">
                  <GradesIndex :current-semester="false" :default-user="parseInt(this.learner.id)" :hasFilters="false" :in-tab="true"/>
                </b-tab-item>
              </b-tabs>
            </div>
          </div>

          <div class="columns">
            <div class="column">
              <b-tabs
                v-model="list"
                class="block"
                multiline
                position="is-centered"
              >
                <b-tab-item label="All">
                  <div
                    v-for="(item, index) in allListItems"
                    :key="index + 'list'"
                  >
                    <activityListItem
                      v-if="item.type == 'tasks'"
                      :activity="item"
                      :model_id="parseInt(id)"
                      :model_type="'registrations'"
                      :permissions="{
                        can_delete: can_delete_activity(item),
                        can_edit: can_edit_activity(item),
                        can_complete: can_edit_registration,
                      }"
                      @editActivity="editActivity($event)"
                    ></activityListItem>
                    <noteListItem
                      v-if="item.type == 'notes'"
                      :note="item"
                      :permissions="{
                        can_delete: delete_notes,
                        can_edit: edit_notes,
                      }"
                    ></noteListItem>
                    <emailListItem
                      v-if="item.type == 'emails' && can_view_emails"
                      :email="item"
                      :email_direction="item.was_received===1 ? 'Received' : 'Sent'
                      "
                      @reply="reply"
                    ></emailListItem>
                  </div>
                </b-tab-item>
                <b-tab-item label="Activities">
                  <div
                    v-for="activity in paginated_activities"
                    :key="activity.id + 'activity'"
                  >
                    <activityListItem
                      :activity="activity"
                      :model_id="parseInt(id)"
                      :model_type="'registrations'"
                      :permissions="{
                        can_delete: can_delete_activity(activity),
                        can_edit: can_edit_activity(activity),
                        can_complete: can_edit_registration,
                      }"
                      @editActivity="editActivity($event)"
                    ></activityListItem>
                  </div>
                  <b-pagination
                    :current.sync="pages.actvities"
                    :per-page="15"
                    :range-after="2"
                    :range-before="2"
                    :total="activities.length"
                    aria-current-label="Current page"
                    aria-next-label="Next page"
                    aria-page-label="Page"
                    aria-previous-label="Previous page"
                    class="margin-top"
                  ></b-pagination>
                </b-tab-item>
                <b-tab-item label="Notes">
                  <div v-for="note in paginated_notes" :key="note.id + 'note'">
                    <noteListItem
                      :note="note"
                      :permissions="{
                        can_delete: delete_notes,
                        can_edit: edit_notes,
                      }"
                    ></noteListItem>
                  </div>
                  <b-pagination
                    :current.sync="pages.notes"
                    :per-page="15"
                    :range-after="2"
                    :range-before="2"
                    :total="notes.length"
                    aria-current-label="Current page"
                    aria-next-label="Next page"
                    aria-page-label="Page"
                    aria-previous-label="Previous page"
                    class="margin-top"
                  ></b-pagination>
                </b-tab-item>
                <b-tab-item label="Log">
                  <div v-for="log in sorted_log" :key="log.id + 'log'">
                    <logListItem :log="log"></logListItem>
                  </div>
                </b-tab-item>
                <b-tab-item v-if="can_view_emails" label="Emails">
                  <div
                    v-for="email in paginated_emails"
                    :key="email.id + 'email'"
                  >
                    <emailListItem
                      :email="email"
                      :email_direction="email.was_received===1? 'Received' : 'Sent'
                      "
                      @reply="reply"
                    ></emailListItem>
                  </div>
                  <b-pagination
                    :current.sync="pages.emails"
                    :per-page="15"
                    :range-after="2"
                    :range-before="2"
                    :total="emails.length"
                    aria-current-label="Current page"
                    aria-next-label="Next page"
                    aria-page-label="Page"
                    aria-previous-label="Previous page"
                    class="margin-top"
                  ></b-pagination>
                </b-tab-item>
              </b-tabs>
              <hr/>
            </div>
          </div>
        </div>
      </div>
    </section>
    <b-modal :active.sync="report_modal">
      <div class="box" style="min-height: 300px">
        <div class="columns is-multiline is-centered">
          <b-field class="column is-narrow" grouped>
            <b-field>
              <b-select v-model="report_save">
                <option :value="true">Save to registration</option>
                <option :value="false">Don't save to registration</option>
              </b-select>
            </b-field>
            <b-field>
              <button
                class="button is-light"
                @click="download_report_save(registration_details.id,report_save)"
              >
                <span class="is-capitalized">Download</span>
              </button>
            </b-field>
          </b-field>
        </div>
      </div>
    </b-modal>
    <b-modal :active.sync="acceptance_modal">
      <div class="box" style="min-height: 300px">
        <div class="columns is-multiline is-centered">
          <b-field class="column is-narrow" grouped>
            <b-field>
              <b-dropdown v-model="acceptance_letter_type" aria-role="list">
                <button
                  slot="trigger"
                  slot-scope="{ active }"
                  class="button is-light"
                >
                  <span class="is-capitalized">{{
                      acceptance_letter_type == null
                        ? "Letter type"
                        : acceptance_letter_type
                    }}</span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>

                <b-dropdown-item aria-role="listitem" value="standard"
                >Standard
                </b-dropdown-item
                >

                <b-dropdown-item aria-role="listitem" value="adaptable"
                >Adaptable
                </b-dropdown-item
                >
                <b-dropdown-item aria-role="listitem" value="adaptable PGCE IP"
                >Adaptable PGCE IP
                </b-dropdown-item
                >
                <b-dropdown-item aria-role="listitem" value="adaptable PGCE FP"
                >Adaptable PGCE FP
                </b-dropdown-item
                >

                <b-dropdown-item aria-role="listitem" value="conditional"
                >Conditional
                </b-dropdown-item
                >

                <b-dropdown-item aria-role="listitem" value="refused"
                >Refused
                </b-dropdown-item
                >
              </b-dropdown>
            </b-field>
            <b-field v-if="acceptance_letter_type != 'adaptable'&&acceptance_letter_type != 'adaptable PGCE IP'&&acceptance_letter_type != 'adaptable PGCE FP'">
              <b-button
                :disabled="acceptance_letter_type == null"
                class="is-capitalized"
                type="is-primary"
                @click="handle_acceptance_click(registration_details.id)"
              >{{ acceptance_letter_action }}
              </b-button
              >
            </b-field>
          </b-field>
        </div>
        <b-field class="content">
          <ckeditor
            v-if="acceptance_letter_type == 'adaptable'||acceptance_letter_type=='adaptable PGCE IP'||acceptance_letter_type=='adaptable PGCE FP'"
            v-model="acceptance_letter"
            :config="editorConfig"
            :editor="editor"
          ></ckeditor>
        </b-field>
        <b-field v-if="acceptance_letter_type == 'adaptable'||acceptance_letter_type=='adaptable PGCE IP'||acceptance_letter_type=='adaptable PGCE FP'">
          <b-button
            class="is-capitalized"
            type="is-primary"
            @click="handle_acceptance_click(registration_details.id)"
          >{{ acceptance_letter_action }}
          </b-button
          >
        </b-field>
      </div>
    </b-modal>
    <b-modal :active.sync="proof_modal">
      <div class="box" style="min-height: 300px">
        <div class="columns is-multiline is-centered">
          <b-field class="column is-narrow" grouped>
            <b-field>
              <b-dropdown v-model="proof_letter_type" aria-role="list">
                <button
                  slot="trigger"
                  slot-scope="{ active }"
                  class="button is-light"
                >
                  <span class="is-capitalized">{{
                      proof_letter_type == null
                        ? "Letter type"
                        : proof_letter_type
                    }}</span>
                  <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                </button>

                <b-dropdown-item aria-role="listitem" value="standard"
                >Standard
                </b-dropdown-item
                >

                <b-dropdown-item aria-role="listitem" value="adaptable"
                >Adaptable
                </b-dropdown-item
                >
              </b-dropdown>
            </b-field>
            <b-field v-if="proof_letter_type != 'adaptable'">
              <b-button
                :disabled="proof_letter_type == null"
                class="is-capitalized"
                type="is-primary"
                @click="handle_proof_click(registration_details.id)"
              >{{ proof_letter_action }}
              </b-button
              >
            </b-field>
          </b-field>
        </div>
        <b-field class="content">
          <ckeditor
            v-if="proof_letter_type == 'adaptable'||proof_letter_type=='adaptable PGCE IP'||proof_letter_type=='adaptable PGCE FP'"
            v-model="proof_letter"
            :config="editorConfig"
            :editor="editor"
          ></ckeditor>
        </b-field>
        <b-field v-if="proof_letter_type == 'adaptable'||proof_letter_type=='adaptable PGCE IP'||proof_letter_type=='adaptable PGCE FP'">
          <b-button
            class="is-capitalized"
            type="is-primary"
            @click="handle_proof_click(registration_details.id)"
          >{{ proof_letter_action }}
          </b-button
          >
        </b-field>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Registration from "@/models/Registration";
import Activity from "@/models/Activity";
import User from "@/models/User";
import Course from "@/models/Course";
import Qualification from "@/models/Qualification";
import RegistrationStage from "@/models/RegistrationStage";
import Learner from "@/models/Learner";
import RegistrationNote from "@/models/RegistrationNote";
import Module from "@/models/Module";
import Media from "@/models/Media";
import Email from "@/models/Email";
import axios from "axios";
import {ACCESS_TOKEN, API_URL} from "@/config";
import EmailForm from "@/components/EmailForm";
import ActivityForm from "@/components/ActivityForm";
import ActivityListItem from "@/components/ActivityListItem";
import EmailListItem from "@/components/EmailListItem";
import NoteListItem from "@/components/RegistrationNoteListItem";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import {editorConfig} from "@/EditorConfig";
import LogListItem from "@/components/LogListItem";
import Lookup from "@/models/Lookup";
import VueCookie from "vue-cookie";
import GradesIndex from "@/components/GradesIndex";
import ExtensionsMixin from "@/mixins/FileTypes";
import LearnerSelectModal from "@/components/LearnerSelectModal";
import RegistrationBoard from "@/models/RegistrationBoard";
import DocumentVerificationPanel from "@/components/media/DocumentVerificationPanel";
import InvoicePanel from "@/components/invoices/InvoicePanel";
import Languages from "@/components/Registrations/conditions/Languages";
import CoreOptions from "@/components/Registrations/conditions/CoreOptions";
import RegistrationConditionsPanel from "@/components/Registrations/RegistrationConditionsPanel";
import Intake from "@/models/Intake";
import Semester from "@/models/Semester";
import Profile from "@/models/Profile";
// import Semester from "@/models/Semester";

export default {
  name: "EditRegistration",
  props: ["id"],
  mixins: [ExtensionsMixin],
  components: {
    RegistrationConditionsPanel,
    CoreOptions,
    Languages,
    InvoicePanel,
    CardComponent,
    ActivityForm,
    ActivityListItem,
    LogListItem,
    EmailListItem,
    NoteListItem,
    EmailForm,
    GradesIndex,
    DocumentVerificationPanel
  },
  data() {
    return {
      loadingModuleStatus: null,
      moduleStatuses: [{
        name: 'Registered', id: 1
      }, {name: 'Withdrawn', id: 2}],
      list: 0,
      dropFile: null,
      reply_email: {},
      selectedModule: null,
      loadingModuleAdd: false,
      filteredModulesForAdd: [],
      loadedModuleIds: [],
      selectedModules: [],
      filteredModules: [],
      applications_stage_ids: [10, 11, 12, 14, 15],
      registrations_stage_ids: [1, 2, 3, 4, 5, 6, 7, 8, 9, 13],
      updatingMedia: false,
      savingRegistrationDetails: false,
      savingLearnerDetails: false,
      setting_owner: false,
      tags: ["ID", "Certificate", "Licence", "Module Outline",
        "Transcript", "Other"],
      courseHold: "",
      qualificationHold: "",
      qualificationId: null,
      pages: {notes: 1, activities: 1, emails: 1},
      selectedModuleIds: [],
      activityToEdit: null,
      editingActivity: false,
      savingNote: false,
      moving: false,
      registrationStatus: false,
      editorData: "",
      noteBeingEdited: Object,
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{placeholder: "Note body"},
      },
      date: null,
      activeTab: 0,
      registration_details_lock: true,
      learner_details_lock: true,
      filteredCourses: [],
      filteredQualifications: [],
      loadingCourses: false,
      loadingQualifications: false,
      loaded: false,
      learner_details: Object,
      disability: null,
      registration_details: Object,
      admissions_team: [],
      acceptance_letter: null,
      acceptance_modal: false,
      proof_letter: null,
      proof_modal: false,
      report_modal: false,
      report_save: false,
      acceptance_letter_type: null,
      acceptance_letter_action: null,
      proof_letter_type: null,
      proof_letter_action: null,
      semesters: [],
      gradeStatusLoading: false,
      responseMeta: {}
    };
  },
  watch: {
    acceptance_letter_type(type) {
      let list = "<ul>";
      this.selectedModules.forEach((module) => {
        list += `<li><strong>${module.name}</strong></li>`;
      });
      list += "</ul>";
      if (type === 'adaptable') {
        this.acceptance_letter = `<p><strong>The modules you have to complete <u>in addition</u> to the compulsory ${this.registration_details.course.long_name} are:</strong></p>${list}`;
      }
      if (type === 'adaptable PGCE IP') {
        this.acceptance_letter = `<p>Your acceptance is based on your qualifications:
<ul><li>National Senior Certificate</li><li>National Diploma (Human Resources Management)</li><li>BTech (Human Resources Management)</li></ul></p>
<p>You meet the DHET admission requirement for:
<ul>
<li>The additional language</li>
</ul></p>
<p>Please note that you <b>do not meet the DHET admission requirements</b> for the teaching of:
<ul>
<li>Languages (English, Afrikaans), Mathematics and Life Skills.</li>
</ul></p>
<p>To meet these admission requirements; you are required to complete the prerequisite modules listed
below for non-degree purposes (NDP). These modules can be taken while you are doing your PGCE,
and it may extend your studies to two-three years, due to the credit limit set by the CHE per study year.
Full-time (non-working) students may take a maximum of 150 credits per study year, and part-time
students a maximum of 75 credits per study year. This maximum must first be made up of any
prerequisite (NDP) modules that you are required complete. Depending on the credit value of your NDP
modules, these may then be supplemented by taking PGCE modules concurrently. For example, a full-
time student who must complete 2 prerequisite modules of 15 credits each can still enrol for 120 PGCE
credits in the same year. A full-time student who must complete 6 prerequisite modules of 15 credits
each can only enrol for 60 PGCE credits in the same year. This means that you will not be able to
complete the qualification in one year.</p>
<p>The prerequisite (NDP) modules are located in our BA in Alternative Education programme and will be
made available on the system for you to select them to complete your registration. Please note again that the
NDP modules must be selected BEFORE any of the PGCE modules, and depending on the number of
prerequisite (NDP) modules you have to complete and the credit limit, as mentioned above.
</p>
${list}
<p>Teacher training also includes the need to demonstrate conversational competence (listening and speaking)
in one of the South African official languages other than English and Afrikaans. Cornerstone offers
Conversational IsiXhosa as part of the curriculum if you lack this competency. If you are conversationally
proficient in one of the official languages, excluding Afrikaans and English, you should submit a letter from
a qualified/accredited teacher or lecturer in such language that states your proficiency and an affidavit from
the police station to confirm this. This would exempt you from completing the conversational isiXhosa
module.</p>`;
      }
      if (type === 'adaptable PGCE FP') {
        this.acceptance_letter = `<p>Your acceptance is based on your qualifications:
<ul><li>National Senior Certificate</li><li>National Diploma (Human Resources Management)</li><li>BTech (Human Resources Management)</li></ul></p>
<p>You meet the DHET admission requirement for:
<ul>
<li>The additional language</li>
</ul></p>
<p>Please note that you <b>do not meet the DHET admission requirements</b> for the teaching of:
<ul>
<li>Languages (English, Afrikaans), Mathematics and Life Skills.</li>
</ul></p>
<p>To meet these admission requirements; you are required to complete the prerequisite modules listed
below for non-degree purposes (NDP). These modules can be taken while you are doing your PGCE,
and it may extend your studies to two-three years, due to the credit limit set by the CHE per study year.
Full-time (non-working) students may take a maximum of 150 credits per study year, and part-time
students a maximum of 75 credits per study year. This maximum must first be made up of any
prerequisite (NDP) modules that you are required complete. Depending on the credit value of your NDP
modules, these may then be supplemented by taking PGCE modules concurrently. For example, a full-
time student who must complete 2 prerequisite modules of 15 credits each can still enrol for 120 PGCE
credits in the same year. A full-time student who must complete 6 prerequisite modules of 15 credits
each can only enrol for 60 PGCE credits in the same year. This means that you will not be able to
complete the qualification in one year.</p>
<p>The prerequisite (NDP) modules are located in our BA in Alternative Education programme and will be
made available on the system for you to select them to complete your registration. Please note again that the
NDP modules must be selected BEFORE any of the PGCE modules, and depending on the number of
prerequisite (NDP) modules you have to complete and the credit limit, as mentioned above.
</p>
${list}
<p>Teacher training also includes the need to demonstrate conversational competence (listening and speaking)
in one of the South African official languages other than English and Afrikaans. Cornerstone offers
Conversational IsiXhosa as part of the curriculum if you lack this competency. If you are conversationally
proficient in one of the official languages, excluding Afrikaans and English, you should submit a letter from
a qualified/accredited teacher or lecturer in such language that states your proficiency and an affidavit from
the police station to confirm this. This would exempt you from completing the conversational isiXhosa
module.</p>`;
      }
    },
    dropFile(val) {
      if (val !== null) {
        if (!this.checkExtension(val)) {
          this.dropFile = null
        }
      }
    }
  },
  computed: {
    intakes() {
      return Intake.query()
        .orderBy('start_date', 'desc')
        .orderBy('end_date', 'desc')
        .get()
    },
    modules() {
      return Module.query().get()
    },
    sources() {
      return Lookup.query().where("lookup_type_id", 7).get();
    },
    sorted_log() {
      let sorted = this.registration.activities;
      sorted.sort(function compare(a, b) {
        const dateA = new Date(a.attributes.created_at);
        const dateB = new Date(b.attributes.created_at);
        return dateB - dateA;
      });
      return sorted;
    },
    registration_stage: {
      get() {
        return this.registration_details.stage_id
      },
      set(newValue) {
        this.moving = true;
        this.registration_details.stage_id = newValue;
        Registration.update({
          where: this.registration.id,
          data: {stage_id: newValue},
        });

        this.submitRegistrationDetails().then(() => {
          this.moving = false;
        });
      }
    },
    gradeHold:{
      get(){
        return this.registration_details.grade_hold
      },
      set(newValue){
        this.registration_details.grade_hold = newValue
        this.updateGradeHold(newValue)
      }
    },
    registration_status: {
      get() {
        return this.registration_details.registration_status_id;
      },
      set(newValue) {
        this.toggleStatus(newValue);
      },
    },
    grade_status: {
      get() {
        return this.registration_details.grade_status_id;
      },
      set(newValue) {
        this.toggleGradeStatus(newValue);
      },
    },
    allListItems() {
      let composite = [...this.activities, ...this.notes, ...this.emails];
      composite = composite.sort(function compare(a, b) {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        return dateB - dateA;
      });

      return composite.slice(0, 15);
    },
    paginated_activities() {
      return Activity.query()
        .with("creator")
        .with("assigned_to_user")
        .with("completed_user")
        .orderBy("created_at", "desc")
        .offset((this.pages.activities - 1) * 15)
        .limit(15)
        .get();
    },
    paginated_notes() {
      if(!this.registration?.id) return {}
      return RegistrationNote.query()
        .with("user")
        .where("registration_id", parseInt(this.registration.id))
        .orderBy("created_at", "desc")
        .offset((this.pages.notes - 1) * 15)
        .limit(15)
        .get();
    },
    notes() {
      if(!this.registration?.id) return {}
      return RegistrationNote.query()
        .with("user")
        .where("registration_id", parseInt(this.registration.id))
        .orderBy("created_at", "desc")
        .get();
    },
    activities() {
      return Activity.query()
        .with("creator")
        .with("assigned_to_user")
        .with("completed_user")
        .orderBy("created_at", "desc")
        .get();
    },
    emails() {
      return Email.query().orderBy("created_at", "desc").with("media").get();
    },
    paginated_emails() {
      return Email.query()
        .orderBy("created_at", "desc")
        .with("media")
        .offset((this.pages.emails - 1) * 15)
        .limit(15)
        .get();
    },
    delivery_type: {
      get() {
        if (this.registration_details.delivery_type_id != null) {
          return this.registration_details.delivery_type_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.registration_details.delivery_type_id = parseInt(newValue);
      },
    },
    current_intake: {
      get() {
        if (this.registration_details.intake_id != null) {
          return this.registration_details.intake_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.registration_details.intake_id = parseInt(newValue);
      },
    },
    totalCredits() {
      let total = 0;
      this.selectedModules.forEach((module) => {
        if (module.status_id !== 2) {
          total += module.credits;
        }
      });
      return total;
    },
    enrolment_date: {
      set(newValue) {
        this.date = new Date(newValue);
      },
      get() {
        if (this.date === null) {
          return null
        }
        return new Date(this.date);
      },
    },
    courseName() {
      if (this.registration.course) {
        return this.registration.course.name;
      }
      return "No " + this.$tc("Course", 1);
    },
    administrate_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "administrate registrations"
      );
    },
    delete_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "delete registrations"
      );
    },
    administrate_notes() {
      return this.$store.getters["entities/permissions/find"](
        "administrate notes"
      );
    },
    media() {
      return Media.all();
    },
    edit_notes() {
      return this.$store.getters["entities/permissions/find"]("edit notes");
    },
    delete_notes() {
      return this.$store.getters["entities/permissions/find"]("delete notes");
    },
    creator: {
      get() {
        return User.find(parseInt(this.registration?.creator_id));
      },
      set(creator) {
        this.setting_owner = true;

        Registration.update({
          where: this.registration.id,
          data: {creator_id: creator.id},
        });
        this.registration_details.creator_id = creator.id;
        this.submitRegistrationDetails().then(() => {
          this.setting_owner = false;
        });
      },
    },
    owner: {
      get() {
        return User.find(parseInt(this.registration?.owner_id));
      },
      set(owner) {
        this.setting_owner = true;
        Registration.update({
          where: this.registration.id,
          data: {owner_id: owner.id},
        });
        this.registration_details.owner_id = owner.id;
        this.submitRegistrationDetails().then(() => {
          this.setting_owner = false;
        });
      },
    },
    learner_details_lock_icon() {
      if (this.learner_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    registration_details_lock_icon() {
      if (this.registration_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    can_edit_learner() {
      return this.$store.getters["entities/permissions/find"]("edit learners");
    },
    can_edit_registration() {
      return this.$store.getters["entities/permissions/find"](
        "edit registrations"
      );
    }, can_administrate_applications() {
      return this.$store.getters["entities/permissions/find"](
        "administrate applications"
      );
    }, can_administrate_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "administrate applications"
      );
    },
    can_create_emails() {
      return this.$store.getters["entities/permissions/find"]("create emails");
    },
    can_view_emails() {
      return this.$store.getters["entities/permissions/find"]("view emails");
    },
    registration() {
      return Registration.query()
        .with("user")
        .with("course")
        .with("stage")
        .with("intake")
        .with("course.intakes")
        .where("id", this.$route.params.id)
        .get()[0];
    },

    learner() {
      return this.registration?.user ?? {};
    },
    users() {
      return User.all();
    },
    course() {
      return this.registration?.course ?? {};
    },
    current_stage() {
      return this.registration?.stage ?? {};
    },
    boards() {
      return RegistrationBoard.query().orderBy('order').with('stages').get()
    },
    minItemBoards() {
      return this.boards.filter(board => {
        return !board.stages.some(stage => stage.name.length === 0) && board.id !== 4
      })
    },
    stages() {
      return RegistrationStage.query().where(stage => {
        return stage.name.length > 0
      }).orderBy("order", "asc").get();
    },
    // boards() {
    //   return [{
    //     name: 'On-boarding',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(0).limit(7).get()
    //   }, {
    //     name: 'Student Advisors',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(7).limit(1).get()
    //   }, {
    //     name: 'Admissions',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(8).limit(5).get()
    //   }, {
    //     name: 'RPL/CAT',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(13).limit(1).get()
    //   }, {
    //     name: 'Finance/Bursar',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(14).limit(1).get()
    //   }, {
    //     name: 'Final Approval of Registration',
    //     stages: RegistrationStage.query().orderBy("order", "asc").offset(15).limit(10).get()
    //   }]
    //
    // },
    stageValue() {
      return (
        ((this.stages.findIndex((x) => x.id == this.current_stage.id) + 1) /
          this.stages.length) *
        100
      );
    },
  },
  methods: {
    routeToRegistration(registrationId) {
      this.$router.push(`/registrations/edit/${registrationId}?index_stage=${this.$route.query.index_stage}`);
      this.runMountedSetup();
    },
    runMountedSetup() {
      Activity.deleteAll();
      Media.deleteAll();
      Email.deleteAll();
      Lookup.fetchAll();
      Module.deleteAll();
      this.updatingMedia = true;
      this.checkForDisability();
      Registration.checkStatus(this.$route.params.id)
      this.$store.dispatch("loader/show");
      Activity.fetchAll("registrations", this.$route.params.id);

      let registrationQuery = {}
      if (this.$route.query?.index_stage) {
        registrationQuery.index_stage = this.$route.query.index_stage
        registrationQuery.index_order = this.$store.getters['registrations/registrationsOrder']
      }
      Registration.fetchById(this.$route.params.id, [], registrationQuery)
          .then((result) => {
            this.responseMeta = result.response.data.data.meta
            this.registration_details = this.registration;
            this.learner_details = this.learner;
            Email.fetch(this.learner.id);

            this.courseHold =
                this.registration.course != null ? this.registration.course.name : "";
            this.qualificationId = this.registration.course?.qualification_id;
            this.qualificationHold = result.entities.hasOwnProperty('qualifications')
                ? result.entities.qualifications.length > 0
                    ? result.entities.qualifications[0].long_name
                    : ""
                : "";
            // this.intakes = result.entities.hasOwnProperty('intakes')
            //   ? result.entities.intakes : [];
            this.date = this.registration.enrolment_date;
            this.selectedModules = result.entities.hasOwnProperty("modules")
                ? result.entities.modules
                : [];
            this.selectedModules.forEach((element) => {
              this.selectedModuleIds.push(element.id);
            });
            this.loadedModuleIds = JSON.parse(JSON.stringify(this.selectedModuleIds))
          })
          .then(() => {
            RegistrationStage.fetchAll();
            User.fetchAll("edit registrations").then((result) => {
              this.admissions_team = result.entities.users;
            });
            Semester.FetchAll().then((result) => {
              this.semesters = result.entities.semesters.sort((a,b) => b.id - a.id);
            });
          })
          .then(() => {
            this.getFilteredModule("", 999);

            RegistrationNote.fetchAllByRegistration(this.registration.id).then(
                () => {
                  this.loaded = true;
                  this.$store.dispatch("loader/hide");
                }
            );

          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err?.response?.data),
            });
            if (
                err.response.status.toString()[0] == 4 ||
                err.response.status.toString()[0] == 5
            ) {
              setTimeout(() => {
                this.$router.go(-1);
              }, 2000);
            }
          });
      Media.fetchAll(this.$route.params.id, "registrations")
          .then(() => {
            this.updatingMedia = false;
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
    },
    checkForDisability(){
      const profile = Profile.query().first();
      if (profile === null) {
        return;
      }
      if (profile.disability_ids !== null) {
        this.disability = true;
      }
      if (profile.accommodation_ids !== null) {
        this.disability = true;
      }
    },
    filterSemesters(semesters) {
      return semesters.filter((sem) => {
        return new Date(sem.end_date) > new Date()
      });
    },
    getFilteredModuleForAdd(text) {
      this.loadingModuleAdd = true;

      Module.search(text, true, 1, 45, null, null).then((result) => {
        this.loadingModuleAdd = false;

        this.filteredModulesForAdd = result.response.data.data.filter(
          (module) => !this.filteredModules.some((item) => item.id === module.id)
        );
      });
    },
    launchReportSave() {
      this.$buefy.dialog.confirm({
        message:
          "Do you want to save a copy of the report to the registration?",
        confirmText: "Yes",
        cancelText: "No"
        , type: "is-primary",
        onConfirm: () => this.download_report_save(this.registration_details.id, true),
        onCancel: () => this.download_report_save(this.registration_details.id, false),
      });
    },
    orderedStages(board_id) {
      return RegistrationStage.query().where('board_id', board_id).orderBy('order').get()
    },
    selectSemester($event, analysedModule) {
      this.selectedModules[this.selectedModules.findIndex(module => module.id == analysedModule.id)].semester = $event

    },
    selectedSemester(analysedModule) {
      if (this.selectedModules.some(module => module.id == analysedModule.id)) {

        return this.selectedModules.find(module => module.id == analysedModule.id).semester
      }
      return null
    },
    displaySemesters(semesters) {
      if (semesters.length === 1) {
        return semesters[0].id
      } else if (semesters.length > 1) {
        let text = semesters[0].id
        for (let i = 1; i < semesters.length; i++) {
          text += ", " + semesters[i].id;
        }
        return text
      }
      return "";
    },
    startChangeUser() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          existingUser: this.registration_details.user
        },
        component: LearnerSelectModal,
        fullScreen: false,
        hasModalCard: false,
        trapFocus: true,
        events: {
          "learner": (user) => {
            this.registration_details.user_id = parseInt(user.id);
            this.submitRegistrationDetails().then(() => {
              this.$router.go(0)

            })
          },
        },
      });
    },
    changeModuleStatus(event, id) {
      if (!this.selectedModuleIds.includes(id)) {
        return
      }


      this.selectedModules[this.selectedModules.findIndex(module => module.id == id)].status_id = parseInt(event)


    },
    can_edit_activity(activity) {
      return !!(this.$store.getters["entities/permissions/find"]("edit tasks") ||
        activity?.creator_id == this.$store.state.userId);

    },
    can_delete_activity(activity) {
      return !!(this.$store.getters["entities/permissions/find"]("delete tasks") ||
        activity?.creator_id == this.$store.state.userId);

    },
    setPage(event, type) {
      this.pages[type] = event;
    },
    reply($evt) {
      this.activeTab = 4;
      this.reply_email = $evt;
      this.$refs["tabs"].scrollIntoView({
        behavior: "smooth",
      });
    },
    editActivity(event) {
      this.activeTab = 3;

      this.editingActivity = true;
      this.activityToEdit = Activity.query()
        .with("creator")
        .whereId(event)
        .get()[0];

      this.$refs["tabs"].scrollIntoView({
        behavior: "smooth",
      });
    },
    handle_acceptance_click(id) {
      let options = {
        type: this.acceptance_letter_type.includes('adaptable') ? 'adaptable' : this.acceptance_letter_type,
        ...((this.acceptance_letter_type == "adaptable" || this.acceptance_letter_type == "adaptable PGCE IP" || this.acceptance_letter_type == "adaptable PGCE FP")
          ? {
            body: this.acceptance_letter,
          }
          : {}),
      };
      if (this.acceptance_letter_action == "send") {
        this.$store.dispatch("loader/show");

        Registration.emailAcceptance(id, options)
          .then(() => {
            this.$store.dispatch("loader/hide");
            this.acceptance_modal = false;

            this.$buefy.snackbar.open({
              message: "Email Sent",
              type: "is-link",
            });
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      } else if (this.acceptance_letter_action == "download") {
        this.$buefy.dialog.confirm({
          message:
            "Do you want to save a copy of the Acceptance Letter to the registration?",
          confirmText: "Yes",
          cancelText: "No"
          , type: "is-primary",
          onConfirm: () => this.download_letter_save(id, options, true),
          onCancel: () => this.download_letter_save(id, options, false),
        });

      }
    },
    download_letter_save(id, options, save) {
      options = {...options, ...{save: save}}
      this.$store.dispatch("loader/show");
      axios({
        url: `${API_URL}/registrations/${id}/download-acceptance-letter`,
        method: "post",
        responseType: "arraybuffer",
        data: options,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + ACCESS_TOKEN,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          let letter_type = 'acceptance'
          if (this.acceptance_letter_type === 'refused') {
            letter_type = 'refusal'
          }
          if (this.acceptance_letter_type === 'conditional') {
            letter_type = 'conditional_acceptance'
          }
          link.setAttribute(
            "download",
            `${this.learner_details.student_number}_${letter_type}_letter.pdf`
          );
          document.body.appendChild(link);
          link.click();
          this.$buefy.snackbar.open({
            message: "Download opened",
            type: "is-link",
          });
          this.acceptance_modal = false;
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.$store.dispatch("loader/hide");
        });
    },
    download_report_save(id, save) {
      let options = {save: save}
      this.$store.dispatch("loader/show");
      axios({
        url: `${API_URL}/registrations/${id}/download-report`,
        method: "post",
        responseType: "arraybuffer",
        data: options,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + ACCESS_TOKEN,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.learner_details.student_number}_report.pdf`
          );
          document.body.appendChild(link);
          link.click();
          this.$buefy.snackbar.open({
            message: "Download opened",
            type: "is-link",
          });
          this.report_modal = false;
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    email(id,save) {
      this.$store.dispatch("loader/show");
      Registration.emailProof(id, this.proof_letter_type, this.proof_letter,save)
        .then(() => {
          this.$buefy.snackbar.open({
            message: "Email Sent",
            type: "is-link",
          });
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });

          this.$store.dispatch("loader/hide");
        });
    },
    handle_proof_click(id) {
      this.$buefy.dialog.confirm({
        message:
          "Do you want to save a copy of the Proof of Registration to the registration?",
        confirmText: "Yes",
        cancelText: "No"
        , type: "is-primary",
        onConfirm: () => this.proof_letter_action === 'download' ? this.download_proof(id, true) : this.email(id,true),
        onCancel: () => this.proof_letter_action === 'download' ? this.download_proof(id, false) : this.email(id,false),
      });
    },
    download_proof(id, save) {

      this.$store.dispatch("loader/show");
      axios({
        url: `${API_URL}/registrations/${id}/download-proof-of-registration`,
        method: "post",
        responseType: "arraybuffer",
        data: {save: save, type: this.proof_letter_type, ...(this.proof_letter_type === 'adaptable' ? {body: this.proof_letter} : {})},
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",

          Authorization: "Bearer " + ACCESS_TOKEN,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${this.learner_details.student_number}_proof_of_registration.pdf`
          );
          this.$buefy.snackbar.open({
            message: "Download opened",
            type: "is-link",
          });
          document.body.appendChild(link);
          link.click();
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.$store.dispatch("loader/hide");
        });
    },
    checkLevel($evt, module) {
      if (this.registration_details_lock) {
        return;
      }
      if (this.selectedModules.some((item) => item.id == module.id)) {
        this.removeModule(module.id);
      } else {
        this.confirmSelectModule(module);
      }
    },
    removeModule(module_id) {
      this.selectedModules = this.selectedModules.filter(
        (el) => el.id != module_id
      );

      this.selectedModuleIds = this.selectedModuleIds.filter(
        (el) => el != module_id
      );
    },
    confirmSelectModule(module) {
      if (!this.filteredModules.some(item=>{
        return +item.id === +module.id
      })) {
        this.filteredModules.push(module)
      }
      this.loadingModuleAdd = true;
      this.selectedModuleIds.push(module.id);
      this.selectedModules.push(module);
      this.loadingModuleAdd = false;
    },
    submitMedia() {
      this.updatingMedia = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("tag", this.dropFile.tag);
      formData.append("caption", this.dropFile.caption);

      Media.uploadToRegistration(formData, this.registration_details.id)
        .then(() => {
          Media.fetchAll(this.registration_details.id, "registrations").then(
            () => {
              this.dropFile = null;
              this.updatingMedia = false;
            }
          );
        })
        .catch((err) => {
          this.updatingMedia = false;
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
    return_caption(caption) {
      if (caption == "undefined") {
        return "None";
      } else {
        return caption;
      }
    },
    deleteRegistration(registrationId) {
      this.savingRegistrationDetails = true;
      this.$store.dispatch("loader/show");
      Registration.deleteRemote(registrationId)
        .then(() => {

          Registration.delete(registrationId);
          let registrations_save = JSON.parse(VueCookie.get("registrations_save"));
          if (registrations_save != null) {
            this.$router.push(registrations_save.route);
          } else {
            this.$router.push('/registrations/boards/on-boarding')
          }
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.$store.dispatch("loader/hide");
        });
    },
    deleteMedia(mediaId) {
      this.updat = true;
      Media.deleteRemote(mediaId)
        .then(() => {
          Media.delete(mediaId);
          Media.fetchAll(this.registration_details.id, "registrations")
            .then(() => {
              this.updatingMedia = false;
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete_media(mediaId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Media",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Media",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteMedia(mediaId),
      });

    },
    start_delete_registration(registrationId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Registration",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Registration",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteRegistration(registrationId),
      });

    },
    saveNote() {
      this.savingNote = true;
      RegistrationNote.newNote({
        registration_id: this.registration.id,
        note: this.editorData,
      }).then(() => {
        RegistrationNote.fetchAllByRegistration(this.registration.id).then(
          () => {
            this.savingNote = false;
            this.editorData = "";
          }
        );
      });
    },
    startDelete(note) {
      if (
        (note.user_id == this.$store.state.userId ||
          this.administrate_notes == true) &&
        this.delete_notes
      ) {
        this.$buefy.dialog.confirm({
          title: "Deleting note",
          message:
            "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
          confirmText: "Delete Note",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteNote(note),
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteNote(note) {
      RegistrationNote.delete(note.id);
      RegistrationNote.deleteRemote(note);
    },
    saveEdit() {
      RegistrationNote.update({
        where: this.noteBeingEdited.id,
        data: {note: this.noteBeingEdited.note},
      });
      RegistrationNote.updateRemote(this.noteBeingEdited);
      this.noteBeingEdited = Object;
    },
    editNote(note) {
      if (
        (note.user_id == this.$store.state.userId ||
          this.administrate_notes == true) &&
        this.edit_notes
      ) {
        this.noteBeingEdited = note;
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
    toggleLearnerDetailsLock() {
      if (this.can_edit_learner) {
        this.learner_details_lock = !this.learner_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
    toggleRegistrationDetailsLock() {
      if (
        this.can_edit_registration &&
        this.registration.registration_status_id != 3
      ) {
        this.registration_details_lock = !this.registration_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },

    selectModule(module) {
      if (module) {
        let select = module
        if (Object.prototype.hasOwnProperty.call(module, 'attributes')) {
          select = Module.query().whereId(module.id).withAll().first()
        }

        if (select.level > this.registration_details.level) {
          this.$buefy.dialog.confirm({
            title: "Confirm Module Selection",
            message:
              "The level of this module is higher than the level the student is registering for, are you sure you want to pick it?",
            confirmText: "Confirm Selection",
            type: "is-danger",
            hasIcon: true,
            onConfirm: () => this.confirmSelectModule(select),
          });
        } else {
          this.confirmSelectModule(select);
        }
      }
    },
    getFilteredModule(text, limit = 999) {
      this.loadingModules = true;
      return Module.search(
        text,
        true,
        1,
        limit,
        this.registration_details.course_id,
        null
        // this.registration_details.semester
      ).then(() => {
        this.filteredModules = Module.query().with('semesters').get();
        this.loadingModules = false;

      });
    },
    selectCourse(course) {
      if (course) {
        this.selectedModules = [];
        this.selectedModuleIds = [];
        this.loadingCourses = true;

        this.registration_details.course_id = course.id;
        this.registration_details.course = course;

        Course.fetchById(course.id)
          .then(() => {
            this.getFilteredModule("", 999).then(() => {
              if (!this.qualificationId) {
                this.qualificationId = course.qualification_id;
                this.qualificationHold = course.qualification.long_name;
              }
              this.loadingCourses = false;
            });
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      }
    },
    selectQualification(qualification) {
      if (qualification) {
        // clear the course hold
        this.courseHold = "";
        this.selectedModules = [];
        this.selectedModuleIds = [];
        this.loadingQualifications = true;
        this.registration_details.course_id = null;
        this.registration_details.course = null;
        this.qualificationId = qualification.id;
        this.qualificationHold = qualification.long_name;
        this.loadingQualifications = false;
      }
    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.qSearch(text, this.qualificationId, false,true, 1).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.filteredCourses = result.entities.courses;
        } else {
          this.filteredCourses = [];
        }
      });
    },
    getFilteredQualification(text) {
      this.loadingQualifications = true;

      Qualification.search(text, true).then(result => {
        this.loadingQualifications = false;

        this.filteredQualifications = result.response.data.data;

        this.meta = result.response.data.meta;
      });
    },
    submitLearnerDetails() {
      this.savingLearnerDetails = true;
      Learner.updateRemote(this.learner_details).then(() => {
        this.savingLearnerDetails = false;
      });
    },
    submitRegistrationDetails() {
      let semester_trip = false
      let status_trip = false
      this.registration_details.modules = []
      this.selectedModules.forEach((element) => {

        if (element.semester === null) {
          if (Module.query().whereId(element.id).with('semesters').first().semesters.length == 1) {
            element.semester = Module.query().whereId(element.id).with('semesters').first().semesters[0].id
          } else {
            semester_trip = true


          }
        }
        if (element.status_id === null) {

          status_trip = true
        }
        this.registration_details.modules.push({
          module_id: parseInt(element.id),
          semester: parseInt(element.semester),
          status_id: parseInt(element.status_id)
        });
      });
      if (semester_trip) {
        this.$store.dispatch('toast/createToast', {message: 'Please select a semester on all selected modules.'})
        return Promise.reject()
      }
      if (status_trip) {
        this.$store.dispatch('toast/createToast', {message: 'Please select a status on all selected modules.'})
        return Promise.reject()

      }
      if (this.date) {
        let submit_date = new Date(this.date);
        this.registration_details.enrolment_date =
          submit_date.getFullYear() +
          "-" +
          (submit_date.getMonth() + 1) +
          "-" +
          submit_date.getDate();
      }

      this.savingRegistrationDetails = true;
      return Registration.updateRemote(this.registration_details).then(() => {
        this.savingRegistrationDetails = false;
        this.$buefy.snackbar.open('Registration Updated!')
        this.registration_details_lock=true

      })
    },
    toggleDismissalStatus(reason) {
      this.registration_details.dismissed_at = new Date().toISOString().substring(0, 10);
      this.registration_details.dismissal_reason = reason;
      Registration.update({
        where: this.registration.id,
        data: {
          registration_status_id: 19,
          dismissed_at: new Date().toISOString().substring(0, 10),
          dismissal_reason: reason
        },
      });
      this.submitRegistrationDetails().then(() => {
        this.registrationStatus = false;
      });
    },
    updateGradeHold(bool){
      Registration.update({
        where: this.registration.id,
        data: {
          grade_hold: bool,
        },
      });
      this.submitRegistrationDetails()
    },
    toggleStatus(status) {
      this.registrationStatus = true;
      this.registration_details.registration_status_id = status;
      if (status == 19) {
        this.$buefy.dialog.prompt({
          message: '<section style="margin-left:10%;"><h3>Please select a dismissal reason:</h3>' + '<ol><li>Finance</li><li>Conduct</li><li>Academic</li></ol></section>',
          inputAttrs: {
            type: 'number',
            placeholder: 'Select a reason',
            value: 1,
            min: 1,
            max: 3
          },
          trapFocus: true,
          onConfirm: (value) => this.toggleDismissalStatus(value)
        });
      } else {
        Registration.update({
          where: this.registration.id,
          data: {
            registration_status_id: status,
          },
        });
        this.submitRegistrationDetails().then(() => {
          this.registrationStatus = false;
        });
      }
    },
    toggleGradeStatus(status) {
      this.gradeStatusLoading = true;
      this.registration_details.grade_status_id = status;
      Registration.update({
        where: this.registration.id,
        data: {grade_status_id: status},
      });
      this.submitRegistrationDetails().then(() => {
        this.gradeStatusLoading = false;
      });
    },
  },
  mounted() {
    this.runMountedSetup()
  },
};
</script>

