import {TransformModel} from '@/models/TransformModel'
import SchoolSubject from "@/models/SchoolSubject";

export default class UserSubject extends TransformModel {
  static entity = 'user-subjects'

  static fields() {
    return {
      id: this.attr(null),
      school_subject_id:this.attr(null),
      subject:this.belongsTo(SchoolSubject,'school_subject_id'),
      grade:this.attr(null),
      name:this.attr(null),

    }
  }

  static FetchAll(page = 1, limit = 999, learner_id) {
    return this.api().get(`/learners/${learner_id}/school-subjects`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(subject => {
          subject.attributes.grade = parseFloat(subject.attributes.grade)
          subject.attributes.school_subject_id = parseInt(subject.id)

          return {...subject, ...subject.attributes}
        })
      },
    })
  }

  static Update(user_subjects,learner_id) {
    return this.api().patch(`/learners/${learner_id}/school-subjects`, user_subjects, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }


}
