<template>
  <div class="container is-relative">
    <b-collapse
      :open="isOpen"
      animation="slide"
      aria-id="contentIdForA11y3"
      class="card has-boxshadow"
      @close="isOpen = false"
      @open="isOpen = true">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
        >
          <div class="card-header-title is-flex is-justify-content-space-between">
            <span>{{ registration.course.code }} - {{ registration.course.name }} {{ registration.year }}</span>
            <span>{{ status }}</span>

          </div>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content">
        <b-field :label="$tc('Course', 1)">
          {{ registration.course.code }} - {{ registration.course.name }}
        </b-field>
        <b-field label="Status">
          {{ status }}
        </b-field>
        <b-field label="Year">
          {{ registration.year }}
        </b-field>
        <b-field label="Level">
          {{ registration.level }}
        </b-field>
        <b-field label="Semester">
          {{ registration.semester }}
        </b-field>
        <b-field label="Created At">
          {{ created_at }}
        </b-field>
        <template v-if="canReRegister">
          <p class="is-size-5">If you're a returning student and would like to register for this {{ $tc('course', 1) }} again start here:</p>
          <b-button type="is-success" @click="startReRegister">
            Re-Register For This {{ $tc('Course', 1) }}
          </b-button>
        </template>
        <template v-if="canContinue">
          <b-button type="is-success" @click="continueRegistration">
            Continue this registration
          </b-button>
        </template>
      </div>
      <footer class="card-footer mb-4">
      </footer>
    </b-collapse>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"/>
  </div>

</template>

<script>

import {format} from "date-fns";
import RegistrationCourseForm from "@/components/Registrations/RegistrationCourseForm";
import Registration from "@/models/Registration";

export default {
  name: "LearnerRegistrationListItem",
  data() {
    return {
      loading: false,
      isOpen: false,
    };
  },

  props: {
    registration: {
      type: Object,
      required: true
    }, user_id: {
      type: String,
      required: true
    },
  },
  methods: {
    continueRegistration(){
      this.$router.push({name:'registrations.register'})
    },
    startReRegister() {
      this.$buefy.modal.open({
        parent: this,
        props: {
          course_id: this.registration.course_id.toString(),
          inModal: true,
          id:this.user_id
        },
        events: {
          'success': (registration_id) => {
            this.$router.push({name:'registrations.register'})
          }
        },
        component: RegistrationCourseForm,
        hasModalCard: true,
        trapFocus: true,
      });
    }
  },
  computed: {
    canContinue(){
      return !this.$store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(this.registration.registration_status_id))
    },
    canReRegister(){
      return  (!Registration.query().where('registration_status_id', (value => {
        return !this.$store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(value))
      })).count() > 0) && (this.registration.registration_status_id===3||this.registration.registration_status_id===7) && (this.registration.accepted_as_application===1)
    },
    created_at() {
      return format(new Date(this.registration.created_at), "yyyy-MM-dd hh:mm")
    },
    status() {
      return this.$store.state.statuses.statuses.find(item => {
        return item.id == this.registration.registration_status_id
      }).name
    }
  },
  mounted() {

  },
};
</script>
