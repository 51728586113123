import {
  TransformModel
} from '@/models/TransformModel'
import State from "@/models/State";


export default class Country extends TransformModel {
  static entity = 'countries'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      states: this.hasMany(State, 'country_id')
    }
  }

  static fetchAll({page = 1, limit = 100}, query = {}, relationships = []) {
    return this.api().get(`/countries`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          }, ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(country => {

            if (relationships.includes('states')) {
              country.attributes.states.map(state => {
                Object.assign(state, state.attributes)
              })
            }
            country.id = parseInt(country.id)
            return {...country, ...country.attributes}
          })
        },
      }
    )
  }static fetchAllPublic({page = 1, limit = 100}, query = {}, relationships = []) {
    return this.api().get(`/public/lookups/countries`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          }, ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(country => {

            if (relationships.includes('states')) {
              country.attributes.states.map(state => {
                Object.assign(state, state.attributes)
              })
            }
            country.id = parseInt(country.id)
            return {...country, ...country.attributes}
          })
        },
      }
    )
  }


}
