import {TransformModel} from '@/models/TransformModel'
import Semester from '@/models/Semester'
import SupplementOption from "@/models/SupplementOption";
import {normalize} from "@/utils/data";
import ModuleLMSCode from "@/models/ModuleLMSCode";

export default class Module extends TransformModel {
  static entity = 'modules'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      code: this.attr(null),
      level: this.attr(null),
      is_passed: this.attr(null),
      nqf: this.attr(null),
      status_id: this.attr(null),
      semester: this.attr(null),
      lms_codes: this.attr(null),
      lms_codes_models: this.hasMany(ModuleLMSCode, 'module_id'),
      credits: this.attr(null),
      subject_id: this.attr(null),
      subject: this.attr(null),
      lms_id: this.attr(null),
      is_supplement: this.attr(false),
      semester_ids: this.attr([]),
      semesters: this.hasManyBy(Semester, 'semester_ids'),
      standardised_level_id: this.attr(null),
      is_pass_fail: this.attr(0)
    }
  }

  static fetchAll(page = 1, limit = 999, relationships = ["lms_codes", "lms_codes.semester"]) {
    return this.api().get(`/modules?with[]=semesters`, {
      params: {
        page: page,
        limit: limit,
        with: relationships
      },

      dataTransformer: (response) => {
        return normalize(response.data.data)
      }
    })
  }

  static FetchAvailableForRegistration(page = 1, limit = 999, registration_id, relationships = []) {
    return this.api().get(`/registrations/${registration_id}/available-modules`, {
      params: {
        page: page,
        limit: limit,
        with: relationships
      },

      dataTransformer: ({data}) => {

        SupplementOption.insert({
          data: data.supplementary_modules.map(option => {
            option.modules = option.modules.map(module => {
              if (Object.prototype.hasOwnProperty.call(module, 'semesters')) {
                module.semesters.map(semester => {
                  semester.id = semester.id.toString()
                  Object.assign(semester, semester.attributes)
                })
              }
              module.id = module.id.toString()
              module.is_supplement = true
              return module
            })
            return option
          })
        })
        return data.default_modules.map(module => {
          if (Object.prototype.hasOwnProperty.call(module, 'semesters')) {
            module.semesters.map(semester => {
              semester.id = semester.id.toString()
              Object.assign(semester, semester.attributes)
            })
          }
          module.id = module.id.toString()

          return module
        })
      }
    })
  }

  static search(data, saved = true, page = 1, limit = 100, courseId = null, subjectId = null, semesterId = null) {
    return this.api().get(`/modules?search=${data}&limit=${limit}&page=${page}&with[]=semesters&with[]=lms_codes`, {
      dataTransformer: ({data: {data}}) => {
        return data.map(module => {

          module.attributes.semesters.map(semester => {
            Object.assign(semester, semester.attributes)
          })


          return {...module, ...module.attributes}
        })
      },
      params: {
        ...(courseId ? {
          courseId: courseId
        } : {}),
        ...(subjectId ? {
          subjectId: subjectId
        } : {}),
        ...(semesterId ? {
          semesterId: semesterId
        } : {})
      },
      save: saved

    })
  }

  static deleteRemote(moduleId) {
    return this.api().delete(`/modules/${moduleId}`, {
      save: false
    })
  }

  static newModule(module) {
    return this.api().post(`/modules`, module, {
      save: false
    })
  }

  static toggleSemester(id, semester_id) {
    return this.api().post(`/modules/${id}/toggle-semester`, {
      semester_id: semester_id
    })
  }

  static updateRemote(module) {
    return this.api().patch(`/modules/${module.id}`, module, {
      save: false
    })
  }

  static fetchById(id) {
    return this.api().get(`/modules/${id}?with[]=semesters&with[]=lms_codes`, {
      dataTransformer: (response) => {
        let target = response.data.data

        target = {
          ...target,
          ...target.attributes
        }
        //  let storageArray = []
        //  response.data.data.forEach(element => {
        //    element.attributes.user = {
        //      ...element.attributes.user,
        //      ...element.attributes.user.attributes
        //    }
        //    if (element.attributes.course) {
        //      element.attributes.course = {
        //        ...element.attributes.course,
        //        ...element.attributes.course.attributes
        //      }
        //    }
        //    element = {
        //      ...element,
        //      ...element.attributes
        //    }
        //    storageArray.push(element)

        //  })


        return normalize(target)
      }
    })
  }

}
