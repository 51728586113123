import Learner from "@/models/Learner";
import Registration from "@/models/Registration";

export const ApplicationsModule = {
  namespaced: true,

  state: {
    //Rework
    canSeeRegistrationsIndex: true,
    hasRegistrationInProgress: true,
    registrationProgressSet: false,
    activeRegistrationId: null,
    //pre rework
    profileComplete: false,
    canEditProfile: false,
    profileErrors: null,
    conditionTags: [
      "Certified copy of ID or other proof of identity",
      "Certified copy of National Senior Certificate or equivalent",
      "Personal Statement document",
      "Qualification Certificate",
      "Transcript",
      "Essay",
      "Application form"
    ],
    tags: [
      "Academic report",
      "Certified copy of tertiary qualification",
      "Certificate of conduct",
      "Certified copy of ID or other proof of identity",
      "Certified copy of National Senior Certificate or equivalent",
      "Completed Application Form",
      "Disability and/or mental health challenge statement",
      "Module outline",
      "Personal Statement document",
      "Proof of payment",
      "Qualification Certificate",
      "SAQA verification certificate",
      "Sponsorship Letter",
      "State of Health Statement",
      "Supplementary subject certificate",
      "Transcript",
      "RPL/CAT Documents",
      "Essay",
      "Application form"
    ],
    applicationProgress: [
      {
        name: 'Registration incomplete', ids: [
          1,
          4,
          10,
          14
        ], value: 25
      }, {
        name: 'Registration in progress', ids: [2,
          11,
          15], value: 50
      }, {
        name: 'Almost there', ids: [5], value: 75
      },
      {
        name: 'Registration complete', ids: [8,
          3,
          13,
          12,
          16], value: 100
      }, {
        name: 'Danger', ids: [9,
          6,
          7], value: 100
      }
    ]

  },

  actions: {

    checkProfileStatus({
                         state
                       }) {
      return Learner.checkProfileComplete().then((result) => {
        state.profileComplete = true
        state.profileErrors = null
      }).catch((err) => {
        state.profileComplete = false
        state.profileErrors = err.response.data.errors

      })
    },
    checkApplicationStatus({
                             state, rootState
                           }, userId) {
      return Registration.fetchByUser(
        1,
        999,
        userId
      ).then((result) => {
        state.canEditProfile = !Registration.query().where('registration_status_id', (value => {
          return rootState.statuses.STATUSES_PROFILE_LOCKED.includes(value)
        })).count() > 0
        return Promise.resolve(result.response.data.meta);
      });
    },
  }
};
