import {TransformModel} from "@/models/TransformModel";
import Invoice from "@/models/Invoice";

/**
 * An invoice payment
 * @property {number} id
 */
export default class InvoicePayment extends TransformModel {
  static entity = 'invoice-payments'

  static fields() {
    return {
      id: this.attr(null),
      invoice_id: this.attr(null),
      invoice: this.belongsTo(Invoice, 'invoice_id'),
      payment_provider: this.attr(null),
      total: this.attr(null),
      message: this.attr(null),
      is_new:this.attr(false)

    }
  }


  /**
   * Returns invoice payments via invoice
   * @function
   * @param {number} invoice_id The id of the invoice
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll(invoice_id, relationships = []) {
    return this.api().get(`/invoices/${invoice_id}/payments`, {
        params: {
          with: relationships
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(payment => {
            return {...payment, ...payment.attributes}

          })
        },
      }
    )
  }

  /**
   * @function
   * @param {Object} payment - The id of the invoice

   * @returns {Promise<Response>} - The newly created invoice
   */

  static Store(payment) {
    return this.api().post(`/invoices/${payment.invoice_id}/payments`, payment, {
        dataTransformer: ({data: {data}}) => {

          return {...data, ...data.attributes}
        },
      }
    )
  }

  /**
   * @function
   * @param {Object} payment - The id of the invoice

   * @returns {Promise<Response>} - The newly created invoice
   */

  static Update(payment) {
    return this.api().patch(`/invoices/${payment.invoice_id}/payments/${payment.id}`, payment, {
        dataTransformer: ({data: {data}}) => {

          return {...data, ...data.attributes}
        },
      }
    )
  }


  /**
   * Delete an existing document
   * @function
   * @param {number} document_id - The id of the document
   */

  static Delete(document_id) {
    return this.api().delete(`/documents/${document_id}`, {
        delete: document_id
      }
    )
  }


}
