<template>
  <section class="section">
    <title-bar class="no-margin-bottom">Edit {{ $tc('Learner', 1) }}</title-bar>
    <b-tabs v-model="activeTab" :destroy-on-hide="true" class="block no-padding" position="is-centered" type="is-boxed">

      <b-tab-item>
        <card-component
            :header-icon="learner_details_lock_icon"
            :icon="$tc('icons.learners')"
            :title="$tc('Learner',1) +' Basic Details'"
            v-on:header-icon-click="toggleLearnerDetailsLock()"
            class="basicDetailsCard"
        >
<!--          <header >-->
<!--            Is Suspended <b-checkbox></b-checkbox>-->
<!--          </header>-->
          <form @submit.prevent="submitProfile(null)">
            <b-field horizontal label="Is Suspended">
              <b-field class="mt-1">
                <b-checkbox v-model="learner.is_suspended"
                            :false-value="0"
                            :true-value="1">
                </b-checkbox>
              </b-field>
            </b-field>
            <b-field horizontal label="First Name">
              <b-field>
                <b-input
                    v-model="learner.first_name"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="first_name"
                    placeholder="First Name"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Middle Name(s)">
              <b-field>
                <b-input
                    v-model="learner.middle_name"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="middle_name"
                    placeholder="Middle Name(s)"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Last Name">
              <b-field>
                <b-input
                    v-model="learner.last_name"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="last_name"
                    placeholder="Last Name"

                />
              </b-field>
            </b-field>
            <b-field :label="$tc('Username',1)" horizontal>
              <b-field>
                <b-input
                    v-model="learner.username"
                    :disabled="learner_details_lock"
                    :name="$tc('username',1)"
                    :placeholder="$tc('Username',1)"
                    autocomplete="chrome-off"
                    icon="account"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Initial Email Password">
              <b-field>
                <b-input
                    v-model="learner.email_password"
                    autocomplete="chrome-off"
                    disabled
                    icon="account"
                    name="Initial Email Password"
                    placeholder="Initial Email Password"
                />
              </b-field>
            </b-field>

            <b-field :label="`${$tc('Learner',1)} Type`" horizontal>
              <b-field>
                <b-select
                    v-model="learner.student_level"
                    :disabled="learner_details_lock"

                    :placeholder="`Select the ${$tc('learner',1)} type`"
                >
                  <option :value="0" disabled>Not Set</option>
                  <option :value="1">Undergraduate</option>
                  <option :value="2">Postgraduate</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Student Number">
              <b-field>
                <b-input
                    v-model="learner.student_number"

                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="student_number"
                    placeholder="Student Number missing"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="LMS ID">
              <b-field>
                <b-input
                    v-model="learner.lms_id"

                    :disabled="learner_details_lock || !$store.getters['entities/permissions/find']('edit user lms fields')"
                    autocomplete="chrome-off"
                    icon="account"
                    name="lms_id"
                    placeholder="LMS ID"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="LMS Username">
              <b-field>
                <b-input
                    v-model="learner.lms_username"

                    :disabled="learner_details_lock || !$store.getters['entities/permissions/find']('edit user lms fields')"
                    autocomplete="chrome-off"
                    icon="account"
                    name="lms_username"
                    placeholder="LMS Username"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Email Address">
              <b-field>
                <b-input
                    v-model="learner.email"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    icon="email"
                    name="email"
                    placeholder="E-mail"

                    type="email"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Primary Contact Number">
              <b-field>
                <b-input
                    v-model="learner.primary_contact_number"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    expanded
                    icon="phone"
                    name="phone"
                    placeholder="072 555 5555"
                    type="tel"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Secondary Contact Number">
              <b-field>
                <b-input
                    v-model="learner.secondary_contact_number"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    expanded
                    icon="phone"
                    name="phone"
                    placeholder="072 555 5555"
                    type="tel"
                />
              </b-field>
            </b-field>
            <b-field :label="$tc('Cellphone',1)+' Number'" horizontal>
              <b-field>
                <b-input
                    v-model="learner.cellphone_number"
                    :disabled="learner_details_lock"
                    autocomplete="chrome-off"
                    expanded
                    icon="phone"
                    name="phone"
                    placeholder="072 555 5555"
                    type="tel"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Personal title">
              <b-field>
                <b-select
                    v-model="learner.title"
                    :disabled="learner_details_lock"
                    placeholder="Select a title"
                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field v-if="!!$store.getters['entities/permissions/find']('change users passwords')" :label="`Change ${$tc('Learner',1)} Password`" horizontal>
              <b-field>
                <b-button :disabled="learner_details_lock"
                          type="is-primary" @click="openPasswordModal">
                  Create New Password
                </b-button>
              </b-field>
            </b-field>

            <hr/>

            <p class="label has-text-centered">Additional Information</p>

            <b-field horizontal label="Gender">
              <b-field :type="{'is-danger':profile.gender_id===null}">
                <b-select
                    v-model="profile.gender_id"
                    :disabled="learner_details_lock"
                    placeholder="Gender"

                >
                  <option
                      v-for="gender in genders"
                      :key="gender.id"
                      :value="gender.id"
                  >{{ gender.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Equity Status">
              <b-field :type="{'is-danger':profile.ethnicity_id===null}">
                <b-select
                    v-model="profile.ethnicity_id"
                    :disabled="learner_details_lock"
                    placeholder="Equity Status"

                >
                  <option
                      v-for="ethnicity in ethnicities"
                      :key="ethnicity.id"
                      :value="ethnicity.id"
                  >{{ ethnicity.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field horizontal label="Date of birth">
              <b-field :type="{'is-danger':date_of_birth===null}">
                <b-datepicker
                    v-model="date_of_birth"
                    :disabled="learner_details_lock"
                    :years-range="[-100,100]"
                    icon="calendar-today"
                    placeholder="Click to select..."

                ></b-datepicker>
              </b-field>
            </b-field>
            <b-field horizontal label="Identity type">
              <b-field :type="{'is-danger':profile.identification_type_id===null}">
                <b-select
                    v-model="profile.identification_type_id"
                    :disabled="learner_details_lock"
                    icon="id-card"
                    placeholder="Identity type"

                >
                  <option
                      v-for="identification_type in identification_types"
                      :key="identification_type.id"
                      :value="identification_type.id"
                  >{{ identification_type.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field horizontal label="Identification number">
              <b-field :type="{'is-danger':profile.id_number===null}">
                <ValidationObserver ref="observer">
                  <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`${profile.identification_type_id == 9 ? 'id' : ''}`"
                  >
                    <b-input
                        v-model="profile.id_number"
                        :disabled="learner_details_lock"

                        autocomplete="chrome-off"
                        icon="id-card"
                        placeholder="ID number"

                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </ValidationObserver>
              </b-field>
            </b-field>

            <!--            <b-field label="Nationality" horizontal>-->
            <!--              <b-field :type="{'is-danger':nationality===null}">-->
            <!--                <b-autocomplete-->
            <!--                  @blur="validateAutocomplete(profile.nationality_id,'nationality')"-->
            <!--                  v-debounce:300ms="getFilteredNationality"-->
            <!--                  :data="filteredNationalities"-->
            <!--                  icon="flag"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  v-model="nationality"-->
            <!--                  placeholder="Nationality"-->
            <!--                  name="Nationality"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  -->
            <!--                  field="name"-->
            <!--                  :clear-on-select="false"-->
            <!--                  @select="option => selectNationality(option)"-->
            <!--                >-->
            <!--                  <template slot-scope="props">-->
            <!--                    <p>{{ props.option.name }}</p>-->
            <!--                  </template>-->
            <!--                </b-autocomplete>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field label="Language" horizontal>-->
            <!--              <b-field :type="{'is-danger':language===null}">-->
            <!--                <b-autocomplete-->
            <!--                  @blur="validateAutocomplete(profile.language_id,'language')"-->
            <!--                  v-debounce:300ms="getFilteredLanguage"-->
            <!--                  :data="filteredLanguages"-->
            <!--                  icon="flag"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  v-model="language"-->
            <!--                  placeholder="Language"-->
            <!--                  name="Language"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  -->
            <!--                  field="name"-->
            <!--                  :clear-on-select="false"-->
            <!--                  @select="option => selectLanguage(option)"-->
            <!--                >-->
            <!--                  <template slot-scope="props">-->
            <!--                    <p>{{ props.option.name }}</p>-->
            <!--                  </template>-->
            <!--                </b-autocomplete>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field label="Religion" horizontal>-->
            <!--              <b-field :type="{'is-danger':profile.religion_id===null}">-->
            <!--                <b-select-->
            <!--                  icon="id-card"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  v-model="profile.religion_id"-->
            <!--                  placeholder="Religion"-->
            <!--                  -->
            <!--                >-->
            <!--                  <option-->
            <!--                    v-for="religion in religions"-->
            <!--                    :key="religion.id"-->
            <!--                    :value="religion.id"-->
            <!--                  >{{ religion.name }}-->
            <!--                  </option>-->
            <!--                </b-select>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field label="Marital Status" horizontal>-->
            <!--              <b-field :type="{'is-danger':profile.marital_status_id===null}">-->
            <!--                <b-select-->
            <!--                  icon="id-card"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  v-model="profile.marital_status_id"-->
            <!--                  placeholder="Marital Status"-->
            <!--                  -->
            <!--                >-->
            <!--                  <option-->
            <!--                    v-for="status in marital_status"-->
            <!--                    :key="status.id"-->
            <!--                    :value="status.id"-->
            <!--                  >{{ status.name }}-->
            <!--                  </option>-->
            <!--                </b-select>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <b-field horizontal label="Citizenship">
              <b-field :type="{'is-danger':profile.citizenship.type===null}">
                <b-select v-model="profile.citizenship.type"                   @input="handlePermitTypeSet"
                          :disabled="learner_details_lock"
                          icon="id-card"
                          placeholder="Citizenship" >
                  <option v-for="type in $store.state.profile.citizenship_types" :value="type">{{ type }}</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field v-if="profile.citizenship.type!==null && profile.citizenship.type!=='RSA'" horizontal
                     label="Other Citizenship">
              <b-field :type="{'is-danger':profile.citizenship.other===null}">
                <b-select
                    v-model="profile.citizenship.other"
                    :disabled="learner_details_lock"
                    expanded
                    icon="flag" >
                  <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <template v-if="profile.citizenship.type==='Other'"><p class="label has-text-centered">Foreign National</p>
              <b-field horizontal label="Permit type">
                <b-field :type="{'is-danger':profile.permit_type_id===null}">
                  <b-select
                      v-model="profile.permit_type_id"
                      :disabled="learner_details_lock"
                      icon="id-card"
                      placeholder="Permit type"

                  >
                    <option
                        v-for="permit_type in permit_types"
                        :key="permit_type.id"
                        :value="permit_type.id"
                    >{{ permit_type.name }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field horizontal label="Permit number">
                <b-field :type="{'is-danger':profile.permit_number===null}">
                  <b-input
                      v-model="profile.permit_number"
                      :disabled="learner_details_lock"
                      autocomplete="chrome-off"
                      icon="id-card"
                      placeholder="Permit number"

                  />
                </b-field>
              </b-field>
              <b-field horizontal label="Permit expiry date">
                <b-field :type="{'is-danger':permit_expiry_date===null}">
                  <b-datepicker
                      v-model="permit_expiry_date"
                      :disabled="learner_details_lock"
                      :years-range="[-100,100]"
                      icon="calendar-today"
                      placeholder="Click to select..."

                  ></b-datepicker>
                </b-field>
              </b-field>
            </template>


            <!--            <b-field horizontal label="How did you hear about Cornerstone?">-->
            <!--              <b-field :type="{'is-danger':profile.where_hear===null}">-->
            <!--                <b-select-->
            <!--                  v-model="profile.where_hear"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  placeholder="Source"-->
            <!--                >-->
            <!--                  <option-->
            <!--                    v-for="source in $store.state.profile.where_hear_options"-->
            <!--                    :value="source"-->
            <!--                  >{{ source }}-->
            <!--                  </option>-->
            <!--                </b-select>-->

            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal label="Why did you choose Cornerstone?">-->
            <!--              <b-field :type="{'is-danger':profile.why_choose===null}">-->
            <!--                <b-input-->
            <!--                  v-model="profile.why_choose"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  autocomplete="chrome-off"-->

            <!--                  type="textarea"-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal label="Which Institution did you attend before Cornerstone?">-->
            <!--              <b-field :type="{'is-danger':profile.prior_institution===null}">-->
            <!--                <b-input-->
            <!--                  v-model="profile.prior_institution"-->
            <!--                  :disabled="learner_details_lock"-->
            <!--                  autocomplete="chrome-off"-->

            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <MarketingForm
                v-model="marketingObject"
                :disabled="learner_details_lock"
                :profileId="id"
            />
            <b-field horizontal>
              <template slot="label">
                <span class="has-pointer" @click="showPhysical = !showPhysical">
                  Physical Address
                  <b-icon :icon="showPhysical?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
              </template>
              <b-field></b-field>
            </b-field>
            <transition name="fade">
              <div v-if="showPhysical">
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.physical_address.line_1===null}" label="Street Address">
                    <b-input
                        v-model="profile.physical_address.line_1"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="15 Main Street"

                    />
                  </b-field>
                  <b-field label="Additional Address Information">
                    <b-input
                        v-model="profile.physical_address.line_2"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="10 Fancy Mansions"
                    />
                  </b-field>


                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.physical_address.country===null}" expanded label="Country">

                    <b-select v-model="profile.physical_address.country"
                              :disabled="learner_details_lock"
                              expanded
                              icon="flag"  @input="((value)=>{getStates(value); profile.physical_address.country_id = getCountryId(value)})">
                      <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.physical_address.province===null}" label="Province">
                    <b-select
                        v-if="selectedPhysicalCountryStates.length>0"

                        v-model="profile.physical_address.province"
                        :disabled="learner_details_lock"
                        @input="value=> {getCities(value); profile.physical_address.province_id = getStateId(value,profile.physical_address.country_id);profile.physical_address.city_id = null;profile.physical_address.city =null}">
                      <option v-for="state in selectedPhysicalCountryStates"
                              :key="state.id" :value="state.name">{{ state.name }}
                      </option>
                    </b-select>
                    <b-input v-else
                             v-model="profile.physical_address.province"
                             :disabled="learner_details_lock"
                             autocomplete="chrome-off"
                             icon="map-marker"
                             placeholder="Gauteng"

                             @input="profile.physical_address.province_id = null;profile.physical_address.city_id = null"
                    />
                  </b-field>
                  <b-field :type="{'is-danger':profile.physical_address.city===null}" label="City">
                    <b-autocomplete
                        v-if="profile.physical_address.province_id"
                        ref="autocomplete_a"
                        v-model="profile.physical_address.city"
                        v-debounce:300ms="value=>{getFilteredCity(value,profile.physical_address.province_id);profile.physical_address.city_id=null}"
                        :clear-on-select="false"
                        :data="cities"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        field="name"
                        icon="flag"
                        name="City"
                        placeholder="City"

                        @blur="validateAutocomplete(profile.physical_address.city_id,'profile.physical_address.city')"
                        @select="option => {profile.physical_address.city=option.name;profile.physical_address.city_id=parseInt(option.id)}"
                    >
                      <template slot-scope="props">
                        <p>{{ props.option.name }}</p>
                      </template>
                    </b-autocomplete>

                    <b-input
                        v-else
                        v-model="profile.physical_address.city"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="Johannesburg"

                    />
                  </b-field>

                  <b-field :type="{'is-danger':profile.physical_address.postal_code===null}" label="Postal Code">
                    <b-input
                        v-model="profile.physical_address.postal_code"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="5432"

                    />
                  </b-field>
                </b-field>

              </div>
            </transition>
            <b-field horizontal>
              <template slot="label">
                <span class="has-pointer" @click="showPostal = !showPostal">
                  Postal Address
                  <b-icon :icon="showPostal?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
              </template>
              <b-field></b-field>
            </b-field>
            <transition name="fade">
              <div v-show="showPostal">
                <b-field horizontal>
                  <b-field>
                    <b-checkbox :value="copy_postal_address" @input="twin_addresses">Same as above</b-checkbox>
                  </b-field>
                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.postal_address.line_1===null}" label="Street Address">
                    <b-input
                        v-model="profile.postal_address.line_1"
                        :disabled="learner_details_lock || copy_postal_address"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="15 Main Street"

                    />
                  </b-field>
                  <b-field label="Additional Address Information">
                    <b-input
                        v-model="profile.postal_address.line_2"
                        :disabled="learner_details_lock || copy_postal_address"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="10 Fancy Mansions"
                    />
                  </b-field>


                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.postal_address.country===null}" expanded label="Country">

                    <b-select v-model="profile.postal_address.country"
                              :disabled="learner_details_lock || copy_postal_address"
                              expanded
                              icon="flag"  @input="((value)=>{getStates(value); profile.postal_address.country_id = getCountryId(value)})">
                      <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>

                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.postal_address.province===null}" label="Province">
                    <b-select v-if="selectedPostalCountryStates.length>0" v-model="profile.postal_address.province"
                              :disabled="learner_details_lock || copy_postal_address"

                              @input="value=> {getCities(value); profile.postal_address.province_id = getStateId(value,profile.postal_address.country_id);profile.postal_address.city_id = null;profile.postal_address.city =null}">
                      <option v-for="state in selectedPostalCountryStates"
                              :key="state.id" :value="state.name">{{ state.name }}
                      </option>
                    </b-select>
                    <b-input v-else
                             v-model="profile.postal_address.province"
                             :disabled="learner_details_lock || copy_postal_address"
                             autocomplete="chrome-off"
                             icon="map-marker"
                             placeholder="Gauteng"

                             @input="profile.postal_address.province_id = null;profile.postal_address.city_id = null"
                    />
                  </b-field>
                  <b-field :type="{'is-danger':profile.postal_address.city===null}" label="City">
                    <b-autocomplete
                        v-if="profile.postal_address.province_id"
                        ref="autocomplete_b"
                        v-model="profile.postal_address.city"
                        v-debounce:300ms="value=>{getFilteredCity(value,profile.postal_address.province_id);profile.postal_address.city_id=null}"
                        :clear-on-select="false"
                        :data="cities"
                        :disabled="learner_details_lock || copy_postal_address"
                        autocomplete="chrome-off"
                        field="name"
                        icon="flag"
                        name="City"
                        placeholder="City"

                        @blur="validateAutocomplete(profile.postal_address.city_id,'profile.postal_address.city')"
                        @select="option => {profile.postal_address.city=option.name;profile.postal_address.city_id=parseInt(option.id)}"
                    >
                      <template slot-scope="props">
                        <p>{{ props.option.name }}</p>
                      </template>
                    </b-autocomplete>

                    <b-input
                        v-else
                        v-model="profile.postal_address.city"
                        :disabled="learner_details_lock || copy_postal_address"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="Johannesburg"

                    />
                  </b-field>

                  <b-field :type="{'is-danger':profile.postal_address.postal_code===null}" label="Postal Code">
                    <b-input
                        v-model="profile.postal_address.postal_code"
                        :disabled="learner_details_lock || copy_postal_address"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="5432"

                    />
                  </b-field>
                </b-field>

              </div>
            </transition>
            <b-field horizontal>
              <template slot="label">
                <span class="has-pointer" @click="showAlternative = !showAlternative">
                  Alternative Address
                  <b-icon :icon="showAlternative?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
              </template>
              <b-field></b-field>
            </b-field>
            <transition name="fade">
              <div v-if="showAlternative">
                <b-field horizontal>
                  <b-field label="Street Address">
                    <b-input
                        v-model="profile.alternative_address.line_1"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="15 Main Street"

                    />
                  </b-field>
                  <b-field label="Additional Address Information">
                    <b-input
                        v-model="profile.alternative_address.line_2"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="10 Fancy Mansions"
                    />
                  </b-field>


                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.alternative_address.country===null}" expanded label="Country">

                    <b-select v-model="profile.alternative_address.country"
                              :disabled="learner_details_lock"
                              expanded
                              icon="flag"  @input="((value)=>{getStates(value); profile.alternative_address.country_id = getCountryId(value)})">
                      <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.alternative_address.province===null}" label="Province">
                    <b-select v-if="selectedAlternativeCountryStates.length>0" v-model="profile.alternative_address.province"
                              :disabled="learner_details_lock"

                              @input="value=> {getCities(value); profile.alternative_address.province_id = getStateId(value,profile.alternative_address.country_id);profile.alternative_address.city_id = null;profile.alternative_address.city =null}">
                      <option v-for="state in selectedAlternativeCountryStates"
                              :key="state.id" :value="state.name">{{ state.name }}
                      </option>
                    </b-select>
                    <b-input v-else
                             v-model="profile.alternative_address.province"
                             :disabled="learner_details_lock"
                             autocomplete="chrome-off"
                             icon="map-marker"
                             placeholder="Gauteng"

                             @input="profile.alternative_address.province_id = null;profile.alternative_address.city_id = null"
                    />
                  </b-field>
                  <b-field :type="{'is-danger':profile.alternative_address.city===null}" label="City">
                    <b-autocomplete
                        v-if="profile.alternative_address.province_id"
                        ref="autocomplete_c"
                        v-model="profile.alternative_address.city"
                        v-debounce:300ms="value=>{getFilteredCity(value,profile.alternative_address.province_id);profile.alternative_address.city_id=null}"
                        :clear-on-select="false"
                        :data="cities"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        field="name"
                        icon="flag"
                        name="City"
                        placeholder="City"

                        @blur="validateAutocomplete(profile.alternative_address.city_id,'profile.alternative_address.city')"
                        @select="option => {profile.alternative_address.city=option.name;profile.alternative_address.city_id=parseInt(option.id)}"
                    >
                      <template slot-scope="props">
                        <p>{{ props.option.name }}</p>
                      </template>
                    </b-autocomplete>

                    <b-input
                        v-else
                        v-model="profile.alternative_address.city"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="Johannesburg"

                    />
                  </b-field>

                  <b-field :type="{'is-danger':profile.alternative_address.postal_code===null}" label="Postal Code">
                    <b-input
                        v-model="profile.alternative_address.postal_code"
                        :disabled="learner_details_lock"
                        autocomplete="chrome-off"
                        icon="map-marker"
                        placeholder="5432"

                    />
                  </b-field>
                </b-field>
              </div>
            </transition>


            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                      :disabled="learner_details_lock"
                      native-type="submit"
                      type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
        <template slot="header">
          <b-icon :icon="$tc('icons.learners')"
                  :type="{'is-danger':isDetailsIncomplete
 }" size="is-medium"></b-icon>
          <span>Details           <span v-if="isDetailsIncomplete"
                                        class="is-size-7 has-text-danger">&nbsp;- Incomplete</span>
</span>
        </template>
        <card-component icon="cloud-upload" title="Submit required documentation">
          <div>
            <p v-if="!media.some(item=>item.tag.includes('Certified copy of ID or other proof of identity')||item.tag.includes('Copy of SA ID')||item.tag.includes('Copy of Passport'))"
               class="has-text-centered has-text-danger">
              Don't forget to upload proof of identity</p>
            <div class="content">
              <p class="is-size-4">Required documents:</p>
              <ul>
                <li>Certified copy of National Senior Certificate or Equivalent</li>
                <li>Certified copies of tertiary qualifications including academic record(s)</li>
                <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
                <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
              </ul>
            </div>
          </div>
          <div v-if="can_create_media" class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field>
                            <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-select v-model="dropFile.tag" placeholder="Select a tag" >
                            <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                              {{ tag }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            v-if="can_view_media"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
            <b-table-column v-slot="props"
                            field="caption"
                            label="Caption"
                            sortable
            >{{ return_caption(props.row.caption) }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      :href="props.row.temporary_url"
                      tag="a"
                      target="_blank"
                      type="is-info"
                    >View
                    </b-button>
                  </span>
                <span class="control">
                    <b-button
                      type="is-danger"

                      @click="start_delete_media(props.row.id)"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>
          </b-table>
          <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isFinanceIncomplete }"
            icon="alpha-f-box"
            size="is-medium"
          ></b-icon>
          <span>Finance</span>
          <span v-if="isFinanceIncomplete" class="is-size-7 has-text-danger">&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_finance_lock_icon"
          :title="$tc('Learner',1) +' Finance'"
          icon="ballot"
          v-on:header-icon-click="learner_finance_lock_state=!learner_finance_lock_state"
        >
          <form
            autocomplete="chrome-off"
            @submit.prevent="submitProfile(['sponsor_information','finance','billing_contact'])"
          >
            <b-field horizontal>
              <p>Indicate how you plan to finance your education at Cornerstone.</p>
            </b-field>
            <b-field horizontal label="Method of finance">
              <b-field :type="{'is-danger':profile.finance===null}">
                <b-select
                  v-model="profile.finance"
                  :disabled="learner_finance_lock"
                  placeholder="Type"

                >
                  <option
                    v-for="method in finance_type"
                    :value="method.id"
                  >{{ method.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <transition name="fade">
              <div
                v-if="showOther"
                class="field-margin-bottom"
              >
                <b-field horizontal label="Specify Other">
                  <b-field :type="{'is-danger':profile.finance_other===null}">
                    <b-input
                      v-model="profile.finance_other"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"

                      type="textarea"
                    />
                  </b-field>
                </b-field>
              </div>
            </transition>
            <transition name="fade">
              <div
                v-if="showSponsor"
                class="field-margin-bottom"
              >
                <b-field horizontal>
                  <b-field label="Sponsor Information"></b-field>
                </b-field>
                <b-field horizontal label="Last Name">
                  <b-field :type="{'is-danger':profile.sponsor_information.last_name===null}">
                    <b-input
                      v-model="profile.sponsor_information.last_name"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="account"
                      name="last_name"
                      placeholder="Last Name"

                    />
                  </b-field>
                </b-field>
                <b-field horizontal label="Initials">
                  <b-field :type="{'is-danger':profile.sponsor_information.initials===null}">
                    <b-input
                      v-model="profile.sponsor_information.initials"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="account"
                      name="initials"
                      placeholder="Initials"

                    />
                  </b-field>
                </b-field>
                <b-field horizontal label="Title">
                  <b-field :type="{'is-danger':profile.sponsor_information.title===null}">
                    <b-select
                      v-model="profile.sponsor_information.title"
                      :disabled="learner_finance_lock"
                      placeholder="Select a title"

                    >
                      <option value="Mr">Mr</option>
                      <option value="Mrs">Mrs</option>
                      <option value="Ms">Ms</option>
                      <option value="Dr">Dr</option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field horizontal>
                  <template slot="label">
                    <span>Address</span>
                  </template>
                  <b-field></b-field>
                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.sponsor_information.address.line_1===null}"
                           label="Street Address">
                    <b-input
                      v-model="profile.sponsor_information.address.line_1"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="map-marker"
                      placeholder="15 Main Street"

                    />
                  </b-field>
                  <b-field label="Additional Address Information">
                    <b-input
                      v-model="profile.sponsor_information.address.line_2"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="map-marker"
                      placeholder="10 Fancy Mansions"
                    />
                  </b-field>


                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.sponsor_information.address.country===null}" expanded
                           label="Country">

                    <b-select v-model="profile.sponsor_information.address.country"
                              :disabled="learner_finance_lock"
                              expanded
                              icon="flag"  @input="((value)=>{getStates(value); profile.sponsor_information.address.country_id = getCountryId(value)})">
                      <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                      </option>
                    </b-select>
                  </b-field>
                </b-field>
                <b-field horizontal>
                  <b-field :type="{'is-danger':profile.sponsor_information.address.province===null}" label="Province">
                    <b-select v-if="selectedSponsorCountryStates.length>0" v-model="profile.sponsor_information.address.province"
                              :disabled="learner_finance_lock"

                              @input="value=> {getCities(value); profile.sponsor_information.address.province_id = getStateId(value,profile.sponsor_information.address.country_id);profile.sponsor_information.address.city_id = null;profile.sponsor_information.address.city =null}">
                      <option v-for="state in selectedSponsorCountryStates"
                              :key="state.id" :value="state.name">{{ state.name }}
                      </option>
                    </b-select>
                    <b-input v-else
                             v-model="profile.sponsor_information.address.province"
                             :disabled="learner_finance_lock"
                             autocomplete="chrome-off"
                             icon="map-marker"
                             placeholder="Gauteng"

                             @input="profile.sponsor_information.address.province_id = null;profile.sponsor_information.address.city_id = null"
                    />
                  </b-field>
                  <b-field :type="{'is-danger':profile.sponsor_information.address.city===null}" label="City">
                    <b-autocomplete
                      v-if="profile.sponsor_information.address.province_id"
                      v-model="profile.sponsor_information.address.city"
                      v-debounce:300ms="value=>{getFilteredCity(value,profile.sponsor_information.address.province_id);profile.sponsor_information.address.city_id=null}"
                      :clear-on-select="false"
                      :data="cities"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      field="name"
                      icon="flag"
                      name="City"
                      placeholder="City"

                      @blur="validateAutocomplete(profile.sponsor_information.address.city_id,'profile.sponsor_information.address.city')"
                      @select="option => {profile.sponsor_information.address.city=option.name;profile.sponsor_information.address.city_id=parseInt(option.id)}"
                    >
                      <template slot-scope="props">
                        <p>{{ props.option.name }}</p>
                      </template>
                    </b-autocomplete>

                    <b-input
                      v-else
                      v-model="profile.sponsor_information.address.city"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="map-marker"
                      placeholder="Johannesburg"

                    />
                  </b-field>

                  <b-field :type="{'is-danger':profile.sponsor_information.address.postal_code===null}"
                           label="Postal Code">
                    <b-input
                      v-model="profile.sponsor_information.address.postal_code"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="map-marker"
                      placeholder="5432"

                    />
                  </b-field>
                </b-field>
                <b-field horizontal label="Contact Number">
                  <b-field :type="{'is-danger':profile.sponsor_information.contact_number===null}">
                    <b-input
                      v-model="profile.sponsor_information.contact_number"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      expanded
                      icon="phone"
                      name="phone"
                      placeholder="072 555 5555"
                      type="tel"
                    />
                  </b-field>
                </b-field>
                <b-field horizontal label="Email Address">
                  <b-field :type="{'is-danger':profile.sponsor_information.email===null}">
                    <b-input
                      v-model="profile.sponsor_information.email"
                      :disabled="learner_finance_lock"
                      autocomplete="chrome-off"
                      icon="email"
                      name="email"
                      placeholder="E-mail"
                      type="email"
                    />
                  </b-field>
                </b-field>
              </div>
            </transition>

            <hr/>
            <b-field
              v-if="profile.finance==='Sponsor'&&!media.some(item=>item.tag.includes('Sponsorship Letter'))"
              horizontal>
              <p class="is-flex" style="align-items:center">
                <!--suppress CssInvalidPropertyValue -->
                <span style="height: fit-content;height: -moz-fit-content;">If sponsored, please include a letter by your sponsor, stating the amount and regularity of the
                                sponsorship in this template:</span>
                <b-button
                  download
                  href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/Sponsorship+Letter+Template.doc"
                  tag="a"
                >Template
                </b-button>
              </p>
            </b-field>
            <b-field horizontal>
              <template #label>
                <p>Person responsible for Billing</p>
              </template>
              <b-field>
                <p class="help has-text-weight-normal">Please provide the details of the person who is responsible for payment of your study fees.</p>
              </b-field>
            </b-field>
            <b-field horizontal label="Full Name">
              <b-input v-model="profile.billing_contact.name" placeholder="Full Name"></b-input>
            </b-field>
            <b-field horizontal label="Contact Number">
              <b-input v-model="profile.billing_contact.contact_number" placeholder="Contact Number" type="tel"></b-input>
            </b-field>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_finance_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>

        <card-component icon="cloud-upload" title="Submit required documentation">
          <div>
            <p v-if="profile.finance==='Sponsor'&&!media.some(item=>item.tag.includes('Sponsorship Letter'))"
               class="has-text-danger">
              Don't forget to upload your sponsor letter.</p>
            <div class="content">
              <p class="is-size-4">Required documents:</p>
              <ul>
                <li>Certified copy of National Senior Certificate or Equivalent</li>
                <li>Certified copies of tertiary qualifications including academic record(s)</li>
                <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
                <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
              </ul>
            </div>
          </div>
          <div v-if="can_create_media" class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field>
                            <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-select v-model="dropFile.tag" placeholder="Select a tag" >
                            <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                              {{ tag }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            v-if="can_view_media"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
            <b-table-column v-slot="props"
                            field="caption"
                            label="Caption"
                            sortable
            >{{ return_caption(props.row.caption) }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      :href="props.row.temporary_url"
                      tag="a"
                      target="_blank"
                      type="is-info"
                    >View
                    </b-button>
                  </span>
                <span v-if="can_delete_media" class="control">
                    <b-button
                      type="is-danger"

                      @click="start_delete_media(props.row.id)"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>
          </b-table>
          <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isFamilyIncomplete}"
            icon="account-group"
            size="is-medium"
          ></b-icon>
          <span>Family</span>
          <span
            v-if="isFamilyIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_next_of_kin_lock_icon"
          :title="$tc('Learner',1) +' Next of kin'"
          icon="ballot"
          v-on:header-icon-click="learner_next_of_kin_lock_state=!learner_next_of_kin_lock_state"
        >
          <form autocomplete="chrome-off"
                @submit.prevent="submitProfile(['next_of_kin_information','family_information','alternative_contact'])">
            <b-field horizontal>
              <template #label>
                <p>Alternative Contact</p>
              </template>

              <b-field>                <p class="help has-text-weight-normal">Please provide the details of the the person who is responsible for payment of your study fees.</p>
              </b-field>
            </b-field>
            <b-field horizontal label="Full Name">
              <b-input v-model="profile.alternative_contact.name" placeholder="Full Name"></b-input>
            </b-field>
            <b-field horizontal label="Contact Number">
              <b-input v-model="profile.alternative_contact.contact_number" placeholder="Contact Number" type="tel"></b-input>
            </b-field>
            <b-field horizontal>
              <b-field label="Next of Kin"></b-field>
            </b-field>
            <b-field horizontal label="Last Name">
              <b-field :type="{'is-danger':profile.next_of_kin_information.last_name===null}">
                <b-input
                  v-model="profile.next_of_kin_information.last_name"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="account"
                  name="last_name"
                  placeholder="Last Name"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Initials">
              <b-field :type="{'is-danger':profile.next_of_kin_information.initials===null}">
                <b-input
                  v-model="profile.next_of_kin_information.initials"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="account"
                  name="initials"
                  placeholder="Initials"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Title">
              <b-field :type="{'is-danger':profile.next_of_kin_information.title===null}">
                <b-select
                  v-model="profile.next_of_kin_information.title"
                  :disabled="learner_next_of_kin_lock"
                  placeholder="Select a title"

                >
                  <option value="Mr">Mr</option>
                  <option value="Mrs">Mrs</option>
                  <option value="Ms">Ms</option>
                  <option value="Dr">Dr</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal>
              <template slot="label">
                <span>Address</span>
              </template>
              <b-field></b-field>
            </b-field>
            <b-field horizontal>
              <b-field :type="{'is-danger':profile.next_of_kin_information.address.line_1===null}"
                       label="Street Address">
                <b-input
                  v-model="profile.next_of_kin_information.address.line_1"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="map-marker"
                  placeholder="15 Main Street"

                />
              </b-field>
              <b-field label="Additional Address Information">
                <b-input
                  v-model="profile.next_of_kin_information.address.line_2"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="map-marker"
                  placeholder="10 Fancy Mansions"
                />
              </b-field>


            </b-field>
            <b-field horizontal>
              <b-field :type="{'is-danger':profile.next_of_kin_information.address.country===null}" expanded
                       label="Country">

                <b-select v-model="profile.next_of_kin_information.address.country" :disabled="learner_next_of_kin_lock"
                          expanded
                          icon="flag"  @input="((value)=>{getStates(value); profile.next_of_kin_information.address.country_id = getCountryId(value)})">
                  <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal>
              <b-field :type="{'is-danger':profile.next_of_kin_information.address.province===null}" label="Province">
                <b-select v-if="selectedNextOfKinCountryStates.length>0" v-model="profile.next_of_kin_information.address.province"
                          :disabled="learner_next_of_kin_lock"

                          @input="value=> {getCities(value); profile.next_of_kin_information.address.province_id = getStateId(value,profile.next_of_kin_information.address.country_id);profile.next_of_kin_information.address.city_id = null;profile.next_of_kin_information.address.city =null}">
                  <option v-for="state in selectedNextOfKinCountryStates"
                          :key="state.id" :value="state.name">{{ state.name }}
                  </option>
                </b-select>
                <b-input v-else
                         v-model="profile.next_of_kin_information.address.province"
                         :disabled="learner_next_of_kin_lock"
                         autocomplete="chrome-off"
                         icon="map-marker"
                         placeholder="Gauteng"

                         @input="profile.next_of_kin_information.address.province_id = null;profile.next_of_kin_information.address.city_id = null"
                />
              </b-field>
              <b-field :type="{'is-danger':profile.next_of_kin_information.address.city===null}" label="City">
                <b-autocomplete
                  v-if="profile.next_of_kin_information.address.province_id"
                  v-model="profile.next_of_kin_information.address.city"
                  v-debounce:300ms="value=>{getFilteredCity(value,profile.next_of_kin_information.address.province_id);profile.next_of_kin_information.address.city_id=null}"
                  :clear-on-select="false"
                  :data="cities"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  field="name"
                  icon="flag"
                  name="City"
                  placeholder="City"

                  @blur="validateAutocomplete(profile.next_of_kin_information.address.city_id,'profile.next_of_kin_information.address.city')"
                  @select="option => {profile.next_of_kin_information.address.city=option.name;profile.next_of_kin_information.address.city_id=parseInt(option.id)}"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.name }}</p>
                  </template>
                </b-autocomplete>

                <b-input
                  v-else
                  v-model="profile.next_of_kin_information.address.city"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="map-marker"
                  placeholder="Johannesburg"

                />
              </b-field>

              <b-field :type="{'is-danger':profile.next_of_kin_information.address.postal_code===null}"
                       label="Postal Code">
                <b-input
                  v-model="profile.next_of_kin_information.address.postal_code"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="map-marker"
                  placeholder="5432"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Contact Number">
              <b-field :type="{'is-danger':profile.next_of_kin_information.contact_number===null}">
                <b-input
                  v-model="profile.next_of_kin_information.contact_number"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  expanded
                  icon="phone"
                  name="phone"
                  placeholder="072 555 5555"

                  type="tel"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Email Address">
              <b-field :type="{'is-danger':profile.next_of_kin_information.email===null}">
                <b-input
                  v-model="profile.next_of_kin_information.email"
                  :disabled="learner_next_of_kin_lock"
                  autocomplete="chrome-off"
                  icon="email"
                  name="email"
                  placeholder="E-mail"

                  type="email"
                />
              </b-field>
            </b-field>
            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_next_of_kin_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isMedicalIncomplete}"
            icon="medical-bag"
            size="is-medium"
          ></b-icon>
          <span>Disability status for universal access</span>
          <span
            v-if="isMedicalIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_medical_lock_icon"
          icon="ballot"
          title="Disability status for universal access"
          v-on:header-icon-click="learner_medical_lock_state=!learner_medical_lock_state"
        >
          <DisabilitiesComponent :disabled="learner_medical_lock"/>
<!--          <form autocomplete="chrome-off" @submit.prevent="submitProfile(['medical_information'])">-->
<!--            <b-field horizontal label="Do you have any of the following conditions?">-->
<!--              <b-field></b-field>-->
<!--            </b-field>-->
<!--            <b-field-->
<!--              horizontal-->
<!--              label="Physical Disability"-->
<!--            >-->
<!--              <b-field :type="{'is-danger':profile.medical_information.physical_disability===null}">-->
<!--                <b-checkbox-->
<!--                  v-model="physical_disability"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="false"-->
<!--                  :required="profile.medical_information.physical_disability == null"-->
<!--                  :true-value="true"-->
<!--                  name="physical_disability"-->
<!--                >Yes-->
<!--                </b-checkbox>-->
<!--                <b-checkbox-->
<!--                  v-model="physical_disability"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="true"-->
<!--                  :true-value="false"-->

<!--                  name="physical_disability"-->
<!--                >No-->
<!--                </b-checkbox>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-field v-if="physical_disability" horizontal label="Please Specify">-->
<!--              <b-field>-->
<!--                <b-input-->
<!--                  v-model="profile.medical_information.physical_disability"-->
<!--                  :disabled="learner_medical_lock"-->

<!--                  type="textarea"-->
<!--                ></b-input>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-field-->
<!--              horizontal-->
<!--              label="Psychological Disability"-->
<!--            >-->
<!--              <b-field :type="{'is-danger':profile.medical_information.psychological_disability===null}">-->
<!--                <b-checkbox-->
<!--                  v-model="psychological_disability"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="false"-->
<!--                  :required="profile.medical_information.psychological_disability == null"-->
<!--                  :true-value="true"-->
<!--                  name="psychological_disability"-->
<!--                >Yes-->
<!--                </b-checkbox>-->
<!--                <b-checkbox-->
<!--                  v-model="psychological_disability"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="true"-->
<!--                  :true-value="false"-->

<!--                  name="psychological_disability"-->
<!--                >No-->
<!--                </b-checkbox>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-field v-if="psychological_disability" horizontal label="Please Specify">-->
<!--              <b-field>-->
<!--                <b-input-->
<!--                  v-model="profile.medical_information.psychological_disability"-->
<!--                  :disabled="learner_medical_lock"-->

<!--                  type="textarea"-->
<!--                ></b-input>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-field-->
<!--              horizontal-->
<!--              label="Any Chronic Medical Condition"-->
<!--            >-->
<!--              <b-field :type="{'is-danger':profile.medical_information.chronic===null}">-->
<!--                <b-checkbox-->
<!--                  v-model="chronic"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="false"-->
<!--                  :required="profile.medical_information.chronic == null"-->
<!--                  :true-value="true"-->
<!--                  name="chronic"-->
<!--                >Yes-->
<!--                </b-checkbox>-->
<!--                <b-checkbox-->
<!--                  v-model="chronic"-->
<!--                  :disabled="learner_medical_lock"-->
<!--                  :false-value="true"-->
<!--                  :true-value="false"-->

<!--                  name="chronic"-->
<!--                >No-->
<!--                </b-checkbox>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-field v-if="chronic" horizontal label="Please Specify">-->
<!--              <b-field>-->
<!--                <b-input-->
<!--                  v-model="profile.medical_information.chronic"-->
<!--                  :disabled="learner_medical_lock"-->

<!--                  type="textarea"-->
<!--                ></b-input>-->
<!--              </b-field>-->
<!--            </b-field>-->
            <!--            <b-field-->
            <!--              label="Do you have any physical disabilities and/or mental health related challenges? If so, please attach a seperate statement furnishing details."-->
            <!--              horizontal-->
            <!--            >-->
            <!--              <b-field :type="{'is-danger':profile.medical_information.physical_disabilities===null}">-->
            <!--                <b-checkbox-->
            <!--                  :disabled="learner_medical_lock"-->
            <!--                  v-model="profile.medical_information.physical_disabilities"-->
            <!--                  :true-value="true"-->
            <!--                  :false-value="false"-->
            <!--                  :required="profile.medical_information.physical_disabilities == null"-->
            <!--                >Yes-->
            <!--                </b-checkbox>-->
            <!--                <b-checkbox-->
            <!--                  :disabled="learner_medical_lock"-->
            <!--                  v-model="profile.medical_information.physical_disabilities"-->
            <!--                  :true-value="false"-->
            <!--                  :false-value="true"-->
            <!--                >No-->
            <!--                </b-checkbox>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal>-->
            <!--              <table class="table is-bordered is-striped">-->
            <!--                <thead>-->
            <!--                <tr>-->
            <!--                  <th>Rating</th>-->
            <!--                  <th>No difficulty</th>-->
            <!--                  <th>Some difficulty</th>-->
            <!--                  <th>A lot of difficulty</th>-->
            <!--                  <th>Cannot do at all</th>-->
            <!--                </tr>-->
            <!--                </thead>-->
            <!--                <tbody>-->
            <!--                <tr>-->
            <!--                  <th>Seeing</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.seeing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :required="profile.medical_information.seeing == null"-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.seeing"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.seeing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.seeing"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.seeing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.seeing"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.seeing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.seeing"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr>-->
            <!--                  <th>Hearing</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.hearing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        :required="profile.medical_information.hearing == null"-->
            <!--                        v-model="profile.medical_information.hearing"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.hearing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.hearing"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.hearing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.hearing"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.hearing===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.hearing"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr>-->
            <!--                  <th>Communicating</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.communicating===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :required="profile.medical_information.communicating == null"-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.communicating"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.communicating===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.communicating"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.communicating===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.communicating"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.communicating===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.communicating"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr>-->
            <!--                  <th>Walking</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.walking===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :required="profile.medical_information.walking == null"-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.walking"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.walking===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.walking"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.walking===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.walking"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.walking===null}" class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.walking"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr>-->
            <!--                  <th>Remembering</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.remembering===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :required="profile.medical_information.remembering == null"-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.remembering"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.remembering===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.remembering"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.remembering===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.remembering"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.remembering===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.remembering"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                <tr>-->
            <!--                  <th>Self-care</th>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.self_care===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :required="profile.medical_information.self_care == null"-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.self_care"-->
            <!--                        true-value="No difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.self_care===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.self_care"-->
            <!--                        true-value="Some difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.self_care===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.self_care"-->
            <!--                        true-value="A lot of difficulty"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                  <td>-->
            <!--                    <b-field :type="{'is-danger':profile.medical_information.self_care===null}"-->
            <!--                             class="has-text-centered">-->
            <!--                      <b-checkbox-->
            <!--                        :disabled="learner_medical_lock"-->
            <!--                        v-model="profile.medical_information.self_care"-->
            <!--                        true-value="Cannot do at all"-->
            <!--                      ></b-checkbox>-->
            <!--                    </b-field>-->
            <!--                  </td>-->
            <!--                </tr>-->
            <!--                </tbody>-->
            <!--              </table>-->
            <!--            </b-field>-->

<!--            <hr/>-->
<!--            <b-field horizontal>-->
<!--              <b-field grouped>-->
<!--                <div class="control">-->
<!--                  <b-button-->
<!--                    :disabled="learner_medical_lock"-->
<!--                    native-type="submit"-->
<!--                    type="is-primary"-->
<!--                  >Save-->
<!--                  </b-button>-->
<!--                </div>-->
<!--              </b-field>-->
<!--            </b-field>-->
<!--            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>-->
<!--          </form>-->
        </card-component>
        <card-component icon="cloud-upload" title="Submit required documentation">
          <div class="content">
            <p class="is-size-4">Required documents:</p>
            <ul>
              <li>Certified copy of National Senior Certificate or Equivalent</li>
              <li>Certified copies of tertiary qualifications including academic record(s)</li>
              <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
              <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
            </ul>
          </div>
          <div v-if="can_create_media" class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field>
                            <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-select v-model="dropFile.tag" placeholder="Select a tag" >
                            <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                              {{ tag }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            v-if="can_view_media"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
            <b-table-column v-slot="props"
                            field="caption"
                            label="Caption"
                            sortable
            >{{ return_caption(props.row.caption) }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      :href="props.row.temporary_url"
                      tag="a"
                      target="_blank"
                      type="is-info"
                    >View
                    </b-button>
                  </span>
                <span v-if="can_delete_media" class="control">
                    <b-button
                      type="is-danger"

                      @click="start_delete_media(props.row.id)"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>
          </b-table>
          <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger':isPriorIncomplete}"
            icon="school"
            size="is-medium"
          ></b-icon>
          <span>Prior Studies</span>
          <span
            v-if="isPriorIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_school_lock_icon"
          icon="ballot"
          title="School attended"
          v-on:header-icon-click="learner_school_lock_state=!learner_school_lock_state"
        >
          <form
            autocomplete="chrome-off"
            @submit.prevent="submitProfile(['school_attended','school_record','previous_studies'])"
          >
            <b-field
              horizontal
            >If you are over 23 and do not have a matric certificate, you will need to apply through the Alternative
              Access Route which is made available on the My Registrations page.
            </b-field>
            <b-field horizontal>
              <p>(a) Applicants who have not yet completed Gr 12 must furnish a copy of their Gr 11 report.</p>
              <p>(b) Applicants who have a tertiary qualification need not complete this section, but must attach a copy
                of their school-leaving certificate with the application.</p>
            </b-field>
            <b-field horizontal label="Name of School">
              <b-field :type="{'is-danger':profile.school_attended.name===null}">
                <b-input
                  v-model="profile.school_attended.name"
                  :disabled="learner_school_lock"
                  autocomplete="chrome-off"
                  icon="account"
                  name="school_name"
                  placeholder="School Name"

                />
              </b-field>
            </b-field>
            <b-field horizontal label="Type of School">
              <b-field :type="{'is-danger':profile.school_attended.type_of_school ===null}" expanded>

                <b-select v-model="profile.school_attended.type_of_school"
                          :disabled="learner_school_lock"
                          expanded
                          icon="flag" placeholder="Type of School" >
                  <option v-for="type in $store.state.profile.school_types" :value="type">{{ type }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <!--            <b-field horizontal>-->
            <!--              <template slot="label">-->
            <!--                <span>Address</span>-->
            <!--              </template>-->
            <!--              <b-field></b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal>-->
            <!--              <b-field :type="{'is-danger':profile.school_attended.address.line_1===null}" label="Street Address">-->
            <!--                <b-input-->
            <!--                  :disabled="learner_school_lock"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  icon="map-marker"-->
            <!--                  v-model="profile.school_attended.address.line_1"-->
            <!--                  placeholder="15 Main Street"-->
            <!--                  required-->
            <!--                />-->
            <!--              </b-field>-->
            <!--              <b-field label="Apartment Number">-->
            <!--                <b-input-->
            <!--                  :disabled="learner_school_lock"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  icon="map-marker"-->
            <!--                  v-model="profile.school_attended.address.line_2"-->
            <!--                  placeholder="10 Fancy Mansions"-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal>-->
            <!--              <b-field expanded :type="{'is-danger':profile.school_attended.address.country===null}" label="Country">-->

            <!--                <b-select expanded-->
            <!--                          @input="((value)=>{getStates(value); profile.school_attended.address.country_id = getCountryId(value)})"-->
            <!--                          :disabled="learner_school_lock"-->

            <!--                          icon="flag" required v-model="profile.school_attended.address.country">-->
            <!--                  <option v-for="country in countries" :value="country.name" :key="country.id">{{ country.name }}-->
            <!--                  </option>-->
            <!--                </b-select>-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field horizontal>-->
            <!--              <b-field :type="{'is-danger':profile.school_attended.address.province===null}" label="Province">-->
            <!--                <b-select required :disabled="learner_school_lock"-->
            <!--                          @input="value=> {getCities(value); profile.school_attended.address.province_id = getStateId(value,profile.school_attended.address.country_id);profile.school_attended.address.city_id = null;profile.school_attended.address.city =null}"-->
            <!--                          v-model="profile.school_attended.address.province"-->
            <!--                          v-if="selectedSchoolCountryStates.length>0">-->
            <!--                  <option v-for="state in selectedSchoolCountryStates"-->
            <!--                          :value="state.name" :key="state.id">{{ state.name }}-->
            <!--                  </option>-->
            <!--                </b-select>-->
            <!--                <b-input v-else-->
            <!--                         @input="profile.school_attended.address.province_id = null;profile.school_attended.address.city_id = null"-->
            <!--                         :disabled="learner_school_lock"-->

            <!--                         autocomplete="chrome-off"-->
            <!--                         icon="map-marker"-->
            <!--                         v-model="profile.school_attended.address.province"-->
            <!--                         placeholder="Gauteng"-->
            <!--                         required-->
            <!--                />-->
            <!--              </b-field>-->
            <!--              <b-field :type="{'is-danger':profile.school_attended.address.city===null}" label="City">-->
            <!--                <b-autocomplete-->
            <!--                  v-if="profile.school_attended.address.province_id"-->
            <!--                  @blur="validateAutocomplete(profile.school_attended.address.city_id,'profile.school_attended.address.city')"-->
            <!--                  v-debounce:300ms="value=>{getFilteredCity(value,profile.school_attended.address.province_id);profile.school_attended.address.city_id=null}"-->
            <!--                  :data="cities"-->
            <!--                  icon="flag"-->
            <!--                  v-model="profile.school_attended.address.city"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  placeholder="City"-->
            <!--                  name="City"-->
            <!--                  :disabled="learner_school_lock"-->

            <!--                  required-->
            <!--                  field="name"-->
            <!--                  :clear-on-select="false"-->
            <!--                  @select="option => {profile.school_attended.address.city=option.name;profile.school_attended.address.city_id=parseInt(option.id)}"-->
            <!--                >-->
            <!--                  <template slot-scope="props">-->
            <!--                    <p>{{ props.option.name }}</p>-->
            <!--                  </template>-->
            <!--                </b-autocomplete>-->

            <!--                <b-input-->
            <!--                  v-else-->
            <!--                  :disabled="learner_school_lock"-->

            <!--                  autocomplete="chrome-off"-->
            <!--                  icon="map-marker"-->
            <!--                  v-model="profile.school_attended.address.city"-->
            <!--                  placeholder="Johannesburg"-->
            <!--                  required-->
            <!--                />-->
            <!--              </b-field>-->

            <!--              <b-field :type="{'is-danger':profile.school_attended.address.postal_code===null}" label="Postal Code">-->
            <!--                <b-input-->
            <!--                  :disabled="learner_school_lock"-->

            <!--                  autocomplete="chrome-off"-->
            <!--                  icon="map-marker"-->
            <!--                  v-model="profile.school_attended.address.postal_code"-->
            <!--                  placeholder="5432"-->
            <!--                  required-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <!--            <b-field label="Telephone number of school" horizontal>-->
            <!--              <b-field :type="{'is-danger':profile.school_attended.contact_number===null}">-->
            <!--                <b-input-->
            <!--                  :disabled="learner_school_lock"-->
            <!--                  autocomplete="chrome-off"-->
            <!--                  type="tel"-->
            <!--                  v-model="profile.school_attended.contact_number"-->
            <!--                  name="phone"-->
            <!--                  icon="phone"-->
            <!--                  placeholder="072 555 5555"-->
            <!--                  expanded-->
            <!--                  required-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->

            <b-field horizontal label="Still in grade 11">
              <b-field>
                <b-checkbox
                  v-model="in_grade_11"
                  :disabled="learner_school_lock"
                  :false-value="false"
                  :true-value="true"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Grade 12 year">
              <b-field :type="{'is-danger':profile.school_record.school_leaving_year===null}">
                <b-input
                  v-model="profile.school_record.school_leaving_year"
                  :disabled="learner_school_lock"
                  :required="!in_grade_11"
                  autocomplete="chrome-off"
                  icon="account"
                  name="school_leaving_year"
                  placeholder="Leaving year"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="NBT for Maths">
              <b-field :type="{'is-danger':nbt_math_select===null}">
                <b-select
                  v-model="nbt_math_select"
                  :disabled="learner_school_lock"
                  placeholder="Completed Status"
                >
                  <option value="not complete">Not complete</option>
                  <option value="awaiting results">Complete - awaiting results</option>
                  <option :value="''">Complete</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field v-if="nbt_math_select == ''" horizontal label="NBT Maths result">
              <b-field :type="{'is-danger':profile.school_record.nbt_math===null}">
                <b-input
                  v-model="profile.school_record.nbt_math"
                  :disabled="learner_school_lock"
                  autocomplete="chrome-off"
                  name="nbt_maths_result"
                  placeholder="Result"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="NBT for English">
              <b-field :type="{'is-danger':nbt_english_select===null}">
                <b-select
                  v-model="nbt_english_select"
                  :disabled="learner_school_lock"
                  placeholder="Completed Status"
                >
                  <option value="not complete">Not complete</option>
                  <option value="awaiting results">Complete - awaiting results</option>
                  <option :value="''">Complete</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field v-if="nbt_english_select == ''" horizontal label="NBT English result">
              <b-field :type="{'is-danger':profile.school_record.nbt_english===null}">
                <b-input
                  v-model="profile.school_record.nbt_english"
                  :disabled="learner_school_lock"
                  autocomplete="chrome-off"
                  name="nbt_english_result"
                  placeholder="Result"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Examination authority">
              <b-field :type="{'is-danger':profile.school_record.examination_authority===null}">
                <b-select
                  v-model="profile.school_record.examination_authority"
                  :disabled="learner_school_lock"
                  placeholder="Select authority"

                >
                  <option
                    v-for="authority in examination_authority"
                    :key="authority.id"
                    :value="authority.name"
                  >{{ authority.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Exemption type">
              <b-field :type="{'is-danger':profile.school_record.exemption_type===null}">
                <b-select
                  v-model="profile.school_record.exemption_type"
                  :disabled="learner_school_lock"
                  :required="!in_grade_11"
                  placeholder="Select exemption type"
                >
                  <option
                    v-for="type in exemption_type"
                    :key="type.id"
                    :value="type.name"
                  >{{ type.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal>If a student did not complete their National Certificate or equivalent with an
              accredited and registered South African Examination Authority, the qualification needs to be verified by
              SAQA. This is the responsibility of the applicant. The SAQA Certificate of Verification needs to be
              submitted.
            </b-field>
            <b-field horizontal label="SAQA Verification">
              <b-field
                :type="{'is-danger':(profile.school_record.saqa_verification===null)&&(profile.school_attended.type_of_school!=='Public')}">
                <b-select
                  v-model="profile.school_record.saqa_verification"
                  :disabled="learner_school_lock"
                  :required="profile.school_attended.type_of_school!=='Public'"
                  placeholder="SAQA Verification Status"
                >
                  <option
                    v-for="status in $store.state.profile.saqa_statuses"
                    :value="status"
                  >{{ status }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <hr/>
            <b-field horizontal>

              <p>If you have written any supplementary school subject(s) please provide appropriate certificates and
                verification thereof</p>
              <p>All applicants must attach a certified copy of their school leaving certificate</p>
            </b-field>
            <hr>
            <p class="label has-text-centered">Previous Studies</p>

            <b-field horizontal>
              <p>
                Applicants who have studied at other tertiary institutions must attach full academic reports and
                Certificates of Conduct from those institutions, otherwise their applications cannot be considered. All
                qualifications must have appropriate SAQA verification
              </p>
              <p>The SAQA Certificate of Verification needs to be submitted to Cornerstone Institute. This is the
                responsibility of the applicant.</p>
            </b-field>
            <b-field>
              <b-field>
                <div class="table-container">
                  <table class="table is-bordered is-striped">
                    <thead>
                    <tr class="table-input">
                      <th>From year</th>
                      <th>To year</th>
                      <th>Name of institution</th>
                      <th>
                        Name of
                        degree/diploma/certificate
                      </th>
                      <th>Study complete?</th>
                      <th>Qualification attached?</th>
                      <th>Type of institution</th>
                      <th>SAQA verification</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(study,index) in profile.previous_studies" :key="index">
                      <td>
                        <b-field>
                          <b-input
                            v-model="study.from_year"
                            :disabled="learner_school_lock"
                            autocomplete="chrome-off"
                            name="from_year"
                            placeholder="2019"

                            type="number"
                          />
                        </b-field>
                      </td>
                      <td>
                        <b-field>
                          <b-input
                            v-model="study.to_year"
                            :disabled="learner_school_lock"
                            autocomplete="chrome-off"
                            name="to_year"
                            placeholder="2020"

                            type="number"
                          />
                        </b-field>
                      </td>
                      <td>
                        <b-field>
                          <b-select
                            v-model="study.institution_name"
                            :disabled="learner_school_lock"
                            autocomplete="chrome-off"
                            name="institution_name"
                            placeholder="Name"

                          >
                            <option
                              v-for="institution in institutions"
                              :key="institution.id"
                              :value="institution.name"
                            >{{ institution.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </td>
                      <td>
                        <b-field>
                          <b-select
                            v-model="study.qualification_name"
                            :disabled="learner_school_lock"
                            autocomplete="chrome-off"
                            name="qualification_name"
                            placeholder="Qualification"

                          >
                            <option
                              v-for="qualification in qualifications"
                              :key="qualification.id"
                              :value="qualification.name"
                            >{{ qualification.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </td>
                      <td>
                        <b-field class="has-text-centered">
                          <b-checkbox
                            v-model="study.is_complete"
                            :disabled="learner_school_lock"
                            :false-value="false"
                            :true-value="true"
                          ></b-checkbox>
                        </b-field>
                      </td>
                      <td>
                        <b-field class="has-text-centered">
                          <b-checkbox

                            v-model="study.qualification_attached"
                            :disabled="learner_school_lock"
                            :false-value="false"
                            :true-value="true"
                          ></b-checkbox>
                        </b-field>
                      </td>
                      <td>
                        <b-field class="has-text-centered">

                          <b-select
                            v-model="study.type_of_institution"
                            :disabled="learner_school_lock"
                            icon="flag" placeholder="Type of institution" >
                            <option v-for="type in $store.state.profile.school_types" :value="type">{{ type }}
                            </option>
                          </b-select>
                        </b-field>

                      </td>
                      <td>
                        <b-field class="has-text-centered">
                          <b-select
                            v-model="study.saqa_verification"
                            :disabled="learner_school_lock"
                            :required="study.type_of_institution!=='Public'"
                            placeholder="SAQA Verification Status"
                          >
                            <option
                              v-for="status in $store.state.profile.saqa_statuses"
                              :value="status"
                            >{{ status }}
                            </option>
                          </b-select>
                        </b-field>

                      </td>
                      <td>
                        <b-button
                          :disabled="learner_school_lock"
                          icon-right="delete"
                          type="is-danger"
                          @click.prevent="remove_study(index)"
                        />
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </b-field>
            </b-field>
            <b-field horizontal>
              <b-button
                :disabled="learner_school_lock"
                type="is-success"
                @click.prevent="add_previous_study"
              >Add qualification
              </b-button>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_school_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
        <card-component icon="cloud-upload" title="Submit required documentation">
          <div class="content">
            <ul>
              <li
                v-if="in_grade_11"
              >If you have not yet completed grade 12 please upload your grade 11 report
              </li>
              <li>If you have written any supplementary school subject(s) please provide appropriate certificates and
                verification thereof
              </li>
              <li>If you have studied at another tertiary institution please attach your full academic reports and
                Certificates of Conduct from those institutions. All qualifications must have appropriate SAQA
                verification
              </li>
              <li>
                Don’t forget to upload your certified copy of your National Certificate or Equivalent and SAQA
                Certificate of Verification (if applicable).
              </li>
            </ul>
            <p class="is-size-4">Required documents:</p>
            <ul>
              <li>Certified copy of National Senior Certificate or Equivalent</li>
              <li>Certified copies of tertiary qualifications including academic record(s)</li>
              <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
              <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
            </ul>
          </div>

          <div v-if="can_create_media" class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field>
                            <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-select v-model="dropFile.tag" placeholder="Select a tag" >
                            <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                              {{ tag }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            v-if="can_view_media"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
            <b-table-column v-slot="props"
                            field="caption"
                            label="Caption"
                            sortable
            >{{ return_caption(props.row.caption) }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      :href="props.row.temporary_url"
                      tag="a"
                      target="_blank"
                      type="is-info"
                    >View
                    </b-button>
                  </span>
                <span v-if="can_delete_media" class="control">
                    <b-button
                      type="is-danger"

                      @click="start_delete_media(props.row.id)"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>
          </b-table>
          <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isEmploymentIncomplete}"
            icon="calendar-check"
            size="is-medium"
          ></b-icon>
          <span>Employment History</span>
          <span
            v-if="isEmploymentIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_employment_lock_icon"
          icon="ballot"
          title="Employment History"
          v-on:header-icon-click="learner_employment_lock_state=!learner_employment_lock_state"
        >
          <form autocomplete="chrome-off" @submit.prevent="submitProfile(['employment_information'])">
            <b-field horizontal>
              <b-field>
                <table class="table is-bordered is-striped">
                  <thead>
                  <tr>
                    <th>Start date</th>
                    <th>End date</th>
                    <th>Job Title</th>
                    <th>Employer</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(employment,index) in profile.employment_information" :key="index">
                    <td>
                      <b-field>
                        <b-field>
                          <b-datepicker
                            :disabled="learner_employment_lock"
                            :value="employment.start_date===null?null:new Date(employment.start_date)"
                            :years-range="[-100,100]"
                            icon="calendar-today"
                            placeholder="Click to select..."

                            @input="payload => employment.start_date = payload"
                          ></b-datepicker>
                        </b-field>
                      </b-field>
                    </td>
                    <td>
                      <b-field>
                        <b-field>
                          <b-datepicker
                            :disabled="learner_employment_lock"
                            :value="employment.end_date===null?null:new Date(employment.end_date)"
                            :years-range="[-100,100]"
                            icon="calendar-today"
                            placeholder="Click to select..."
                            @input="payload => employment.end_date = payload"
                          ></b-datepicker>
                        </b-field>
                      </b-field>
                    </td>
                    <td>
                      <b-field>
                        <b-input
                          v-model="employment.type_of_work"
                          :disabled="learner_employment_lock"
                          autocomplete="chrome-off"
                          placeholder="Job title"

                        />
                      </b-field>
                    </td>
                    <td>
                      <b-field>
                        <b-input
                          v-model="employment.employer"
                          :disabled="learner_employment_lock"
                          autocomplete="chrome-off"
                          icon="account"
                          placeholder="Employer"

                        />
                      </b-field>
                    </td>

                    <td>
                      <b-button
                        :disabled="learner_employment_lock"
                        icon-right="delete"
                        type="is-danger"
                        @click.prevent="remove_employer(index)"
                      />
                    </td>
                  </tr>
                  </tbody>
                </table>
              </b-field>
            </b-field>
            <b-field horizontal>
              <b-button
                :disabled="learner_employment_lock"
                type="is-success"
                @click.prevent="add_employment"
              >Add employer
              </b-button>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_employment_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isActivitiesIncomplete}"
            icon="calendar-check"
            size="is-medium"
          ></b-icon>
          <span>Activities</span>
          <span
            v-if="isActivitiesIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>
        <card-component
          :header-icon="learner_activities_lock_icon"
          icon="ballot"
          title="Activities"
          v-on:header-icon-click="learner_activities_lock_state=!learner_activities_lock_state"
        >
          <form autocomplete="chrome-off" @submit.prevent="submitProfile(['activities','personal_statements'])">

            <p class="has-text-centered label">
              Activities
            </p>

            <b-field horizontal>
              <p>To reflect important activities in which you are involved, complete the following:</p>
            </b-field>
            <b-field horizontal label="Societies, sport etc.:">
              <b-field :type="{'is-danger':profile.activities.sport_societies===null}">
                <b-input
                  v-model="profile.activities.sport_societies"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>
            <b-field horizontal label="Any leadership roles in the community:">
              <b-field :type="{'is-danger':profile.activities.leadership_roles===null}">
                <b-input
                  v-model="profile.activities.leadership_roles"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>
            <b-field horizontal label="Describe your community involvement:">
              <b-field :type="{'is-danger':profile.activities.community_involvement===null}">
                <b-input
                  v-model="profile.activities.community_involvement"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>

            <hr/>
            <p class="has-text-centered label">
              Personal Statement
            </p>

            <b-field horizontal>
              <p>Please answer the following questions:</p>
            </b-field>
            <b-field horizontal label="Why do you seek admission to Cornerstone?">
              <b-field :type="{'is-danger':profile.personal_statements.admission_reason===null}">
                <b-input
                  v-model="profile.personal_statements.admission_reason"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>
            <b-field
              horizontal
              label="What experiences, interests, hobbies and activities have had a significant affect upon your life?"
            >
              <b-field :type="{'is-danger':profile.personal_statements.interests_hobbies===null}">
                <b-input
                  v-model="profile.personal_statements.interests_hobbies"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>
            <b-field horizontal label="What are your personal goals and aspirations?">
              <b-field :type="{'is-danger':profile.personal_statements.goals===null}">
                <b-input
                  v-model="profile.personal_statements.goals"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>
            <b-field horizontal label="What occupation do you hope to pursue?">
              <b-field :type="{'is-danger':profile.personal_statements.occupation===null}">
                <b-input
                  v-model="profile.personal_statements.occupation"
                  :disabled="learner_activities_lock"

                  type="textarea"
                ></b-input>
              </b-field>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_activities_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
      </b-tab-item>
      <b-tab-item>
        <template slot="header">
          <b-icon
            :type="{'is-danger': isReferenceIncomplete}"
            icon="account-alert"
            size="is-medium"
          ></b-icon>
          <span>Reference</span>
          <span
            v-if="isReferenceIncomplete"
            class="is-size-7 has-text-danger"
          >&nbsp;- Incomplete</span>
        </template>

        <card-component
          :header-icon="learner_reference_lock_icon"
          :title="$tc('Learner',1) +' Reference'"
          icon="ballot"
          v-on:header-icon-click="learner_reference_lock_state=!learner_reference_lock_state"
        >
          <form autocomplete="chrome-off" @submit.prevent="submitProfile(['reference_contact'])">
            <b-field horizontal>
              <p>
                Please provide the contact details of one or more referees whom may be contacted to comment on your
                academic and/or
                professional performance e.g. educator, minister of religion.
              </p>
            </b-field>
            <b-field horizontal>
              <b-field label="Reference Contact Details"></b-field>
            </b-field>
            <div v-for="(contact,index) in profile.reference_contact" :key="index">
              <b-field horizontal label="Last Name">
                <b-field :type="{'is-danger':contact.last_name===null}">
                  <b-input
                    v-model="contact.last_name"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="last_name"
                    placeholder="Last Name"

                  />
                </b-field>
              </b-field>
              <b-field horizontal label="Initials">
                <b-field :type="{'is-danger':contact.initials===null}">
                  <b-input
                    v-model="contact.initials"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="account"
                    name="initials"
                    placeholder="Initials"

                  />
                </b-field>
              </b-field>
              <b-field horizontal label="Title">
                <b-field :type="{'is-danger':contact.title===null}">
                  <b-select
                    v-model="contact.title"
                    :disabled="learner_reference_lock"
                    placeholder="Select a title"

                  >
                    <option value="Mr">Mr</option>
                    <option value="Mrs">Mrs</option>
                    <option value="Ms">Ms</option>
                    <option value="Dr">Dr</option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field horizontal>
                <template slot="label">
                  <span>Address</span>
                </template>
                <b-field></b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':contact.address.line_1===null}" label="Street Address">
                  <b-input
                    v-model="contact.address.line_1"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="15 Main Street"

                  />
                </b-field>
                <b-field label="Additional Address Information">
                  <b-input
                    v-model="contact.address.line_2"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="10 Fancy Mansions"
                  />
                </b-field>


              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':contact.address.country===null}" expanded
                         label="Country">

                  <b-select v-model="contact.address.country"
                            :disabled="learner_reference_lock"
                            expanded
                            icon="flag"  @input="((value)=>{getStates(value); contact.address.country_id = getCountryId(value)})">
                    <option v-for="country in countries" :key="country.id" :value="country.name">{{ country.name }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':contact.address.province===null}" label="Province">
                  <b-select v-if="selectedCountryStates(contact.address.country_id).length>0" v-model="contact.address.province"
                            :disabled="learner_reference_lock"

                            @input="value=> {getCities(value); contact.address.province_id = getStateId(value,contact.address.country_id);contact.address.city_id = null;contact.address.city =null}">
                    <option v-for="state in selectedCountryStates(contact.address.country_id)"
                            :key="state.id" :value="state.name">{{ state.name }}
                    </option>
                  </b-select>
                  <b-input v-else
                           v-model="contact.address.province"
                           :disabled="learner_reference_lock"
                           autocomplete="chrome-off"
                           icon="map-marker"
                           placeholder="Gauteng"

                           @input="contact.address.province_id = null;contact.address.city_id = null"
                  />
                </b-field>
                <b-field :type="{'is-danger':contact.address.city===null}" label="City">
                  <b-autocomplete
                    v-if="contact.address.province_id"
                    v-model="contact.address.city"
                    v-debounce:300ms="value=>{getFilteredCity(value,contact.address.province_id);contact.address.city_id=null}"
                    :clear-on-select="false"
                    :data="cities"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    field="name"
                    icon="flag"
                    name="City"
                    placeholder="City"

                    @blur="validateAutocomplete(contact.address.city_id,'contact.address.city')"
                    @select="option => {contact.address.city=option.name;contact.address.city_id=parseInt(option.id)}"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>

                  <b-input
                    v-else
                    v-model="contact.address.city"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="Johannesburg"

                  />
                </b-field>

                <b-field :type="{'is-danger':contact.address.postal_code===null}" label="Postal Code">
                  <b-input
                    v-model="contact.address.postal_code"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="5432"

                  />
                </b-field>
              </b-field>
              <b-field horizontal label="Contact Number">
                <b-field :type="{'is-danger':contact.contact_number===null}">
                  <b-input
                    v-model="contact.contact_number"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    expanded
                    icon="phone"
                    name="phone"
                    placeholder="072 555 5555"

                    type="tel"
                  />
                </b-field>
              </b-field>
              <b-field horizontal label="Email Address">
                <b-field :type="{'is-danger':contact.email===null}">
                  <b-input
                    v-model="contact.email"
                    :disabled="learner_reference_lock"
                    autocomplete="chrome-off"
                    icon="email"
                    name="email"
                    placeholder="E-mail"
                    required
                    type="email"
                  />
                </b-field>
              </b-field>
              <b-field class="mb-4" horizontal label="">
                <b-field>
                  <b-button type="is-danger" @click="remove_reference(index)">Remove Reference</b-button>
                </b-field>
              </b-field>
            </div>
            <b-field horizontal>
              <b-button
                :disabled="learner_employment_lock"
                type="is-success"
                @click.prevent="add_reference"
              >Add reference
              </b-button>
            </b-field>
            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button
                    :disabled="learner_reference_lock"
                    native-type="submit"
                    type="is-primary"
                  >Save
                  </b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
      </b-tab-item>
      <b-tab-item :icon="$tc('icons.courses')" :label="$tc('Course',2)">
        <card-component :icon="$tc('icons.courses')" :title="`${$tc('Learner',1)} ${$tc('Course',2)}`">
          <LearnerCourses :user_id="parseInt(id)"></LearnerCourses>
        </card-component>

      </b-tab-item>
      <b-tab-item icon="link" label="Links">
        <card-component :title="`Link for ${$tc('Learner',1)}`" icon="link">
          <b-tooltip label="Click to copy">
            <a
              class="has-text-underlined"
              @click="copyLink(learner.token_url)"
            >{{ $tc('Learner', 1) }} Login Link</a>
          </b-tooltip>
        </card-component>
        <card-component :title="`Synced with ${$tc('lms',1)}`" icon="cast-education">
          <div>
            <p>
              {{ $tc('Learner', 1) }} is {{ learner.lms_id !== null ? '' : 'not' }} synced with {{ $tc('lms', 1) }}
              {{ learner.lms_id !== null ? `as ID ${learner.lms_id}` : '' }}
            </p>
          </div>
        </card-component>
        <card-component v-if="enquiries.length>0" icon="link" title="Enquiries">
          <b-table :bordered="true" :data="enquiries" :hoverable="true" :striped="true">
            <b-table-column v-slot="props" field="id" label="ID" numeric sortable width="40">{{ props.row.id }}</b-table-column>

            <b-table-column v-slot="props"
                            field="age_human_readable"
                            label="Created"
                            sortable
            >{{ props.row.age_human_readable }}
            </b-table-column>

            <b-table-column v-slot="props" label="Course">{{ courseName(props.row) }}</b-table-column>
            <b-table-column v-slot="props" field="status_id" label="Status" sortable>
                <span
                  :class="{'has-background-incomplete':props.row.status_id == 3,'has-background-warning':props.row.status_id == 1,'has-background-success':props.row.status_id == 2,'has-background-incomplete':props.row.status_id == 5,'has-background-incomplete':props.row.status_id == 4}"
                  class="tag is-size-6"
                >{{ statusName(props.row.status_id) }}</span>
            </b-table-column>
            <b-table-column v-slot="props" custom-key="actions" width="80">
              <b-button type="is-info" @click="viewEnquiry(props.row.id)">View</b-button>
            </b-table-column>
          </b-table>
        </card-component>
        <card-component v-if="registrations.length>0" icon="link" title="Registrations">
          <b-table :bordered="true" :data="registrations" :hoverable="true" :striped="true">
            <b-table-column v-slot="props" field="id" label="Id" numeric width="40">{{ props.row.id }}</b-table-column>

            <b-table-column v-slot="props"
                            field="enrolment_date"
                            label="Enrolment Date"
                            sortable
            >{{ props.row.enrolment_date }}
            </b-table-column>
            <b-table-column v-slot="props" field="course.name" label="Course">{{ courseName(props.row) }}</b-table-column>
            <b-table-column v-slot="props" label="Total Credits">{{ totalCredits(props.row) }}</b-table-column>
            <b-table-column v-slot="props" field="registration_status_id" label="Status" sortable>
                <span
                  :class="{'has-background-incomplete':props.row.registration_status_id == 1 ||  props.row.registration_status_id == 6,'has-background-warning':props.row.registration_status_id == 2 || props.row.registration_status_id == 4,'has-background-success':props.row.registration_status_id == 3 ||  props.row.registration_status_id == 5 || props.row.registration_status_id == 8}"
                  class="tag is-size-6"
                >{{ registrationStatusName(props.row.registration_status_id) }}</span>
            </b-table-column>

            <b-table-column v-slot="props" custom-key="actions" width="80">
              <b-button type="is-info" @click="viewRegistration(props.row)">View</b-button>
            </b-table-column>
          </b-table>
        </card-component>
      </b-tab-item>
      <b-tab-item v-if="can_view_RPL">
        <template slot="header">
          <b-icon
            :type="{'is-danger': isReferenceIncomplete}"
            icon="clock"
            size="is-medium"
          ></b-icon>
          <span>Recognition of Prior Learning and Credit Accumulation Transfer (RPL/CAT)</span>

        </template>
        <card-component icon="table" title="Recognition of Prior Learning and Credit Accumulation Transfer (RPL/CAT)">
          <b-table
            v-if="priorLearnings"
            ref="table"
            :data="priorLearnings"
            :opened-detailed="defaultOpenedDetails"
            :show-detail-icon="true"
            detail-key="id"
            detailed
          >
            <b-table-column v-slot="props" field="id" label="ID" numeric width="40">{{ props.row.id }}</b-table-column>
            <b-table-column v-if="!loadingRPL"
                            v-slot="props"
                            field="module.name"
                            label="Module"
                            sortable
            >{{ props.row.module.name }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="institution_id"
                            label="Institution"
                            sortable
            >{{ lookupName(props.row.institution_id) }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason_id"
                            label="Reason"
                            sortable
            >{{ lookupName(props.row.reason_id) }}
            </b-table-column>
            <b-table-column v-slot="props" field="Grade" label="Level" sortable>{{ props.row.grade }}</b-table-column>
            <b-table-column v-slot="props" field="symbol" label="Symbol" sortable>{{ props.row.symbol }}</b-table-column>
            <b-table-column v-slot="props"
                            field="type_id"
                            label="Type"
                            sortable
            >{{ typeName(props.row.type_id) }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="status_id"
                            label="Status"
                            sortable
            >{{
                props.row.status_id ? $store.state.profile.rpl_statuses.find(status => status.id === props.row.status_id).name : 'None'
              }}
            </b-table-column>
            <b-table-column v-slot="props" field="comment" label="Comment">
              <template v-if="!props.row.comment">None</template>
              <template v-else>
                <b-button @click="toggle(props.row)">Show</b-button>
              </template>
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
<span v-if="can_edit_RPL" class="control">                  <b-button type="is-info"
                                                                      @click="editRPL(props.row)">Edit</b-button>
</span> <span v-if="can_delete_RPL" class="control"><b-button

                type="is-danger"
                @click="start_delete_RPL(props.row)"
              >Delete
                  </b-button></span>
              </b-field>
            </b-table-column>

            <template slot="detail" slot-scope="props">
              <b-field label="Comment">
                <div>{{ props.row.comment }}</div>
              </b-field>
            </template>
          </b-table>
        </card-component>
        <card-component
          v-if="can_create_RPL && !editingRPL"
          icon="plus"
          title="Add new Registered Prior Learning"
        >
          <form ref="RPLForm" @submit.prevent="submitNewRPL">

            <b-field v-for="(module,index) in new_RPL_modules" :key="index" horizontal>

              <b-field group-multiline grouped>
                <b-field expanded label="Module">
                  <b-autocomplete
                    v-model="module.nameHold"
                    :clear-on-select="false"
                    :data="filteredModules"
                    :loading="loadingModules"
                    autocomplete="off"
                    expanded
                    field="name"
                    name="module"
                    placeholder="Module name"
                    required
                    @blur="validateAutocomplete(module.id,'moduleHold')"
                    @select="option => selectModule(option,index)"
                    @typing="getFilteredModule"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.code }} | {{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>
                </b-field>
                <b-field label="Symbol">
                  <b-field>
                    <b-input
                      v-model="module.symbol"
                      autocomplete="off"
                      name="symbol"
                      placeholder="The symbol"
                      required
                    />
                  </b-field>
                </b-field>
                <b-field label="Remove">
                  <b-field>

                    <b-button @click="removeNewModule(module.id)">
                      Remove Module
                    </b-button>
                  </b-field>
                </b-field>
              </b-field>
            </b-field>
            <b-field horizontal>
              <b-field>
                <b-button :icon-right="$tc('icons.create')" @click="addNewModule">Add module</b-button>
              </b-field>
            </b-field>
            <b-field horizontal label="Institution">
              <b-field>
                <b-autocomplete
                  v-model="institution"
                  v-debounce:300ms="getFilteredInstitution"
                  :clear-on-select="false"
                  :data="filteredInstitutions"
                  autocomplete="off"
                  field="name"
                  name="Institution"
                  placeholder="Institution"
                  required
                  @blur="validateAutocomplete(new_RPL.institution_id,'institution')"
                  @select="option => selectInstitution(option)"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
            </b-field>
            <b-field horizontal label="Reason">
              <b-field>
                <b-select v-model="new_RPL.reason_id" placeholder="Select a reason" required>
                  <option
                    v-for="reason in reasons"
                    :key="reason.id"
                    :value="reason.id"
                  >{{ reason.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Level">
              <b-field>
                <b-input
                  v-model="new_RPL.grade"
                  autocomplete="off"
                  name="level"
                  placeholder="The level"
                  type="number"
                />
              </b-field>
            </b-field>
            <!--            <b-field label="Symbol" horizontal>-->
            <!--              <b-field>-->
            <!--                <b-input-->
            <!--                  autocomplete="off"-->
            <!--                  placeholder="The symbol"-->
            <!--                  v-model="new_RPL.symbol"-->
            <!--                  name="symbol"-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <b-field horizontal label="Type">
              <b-field>
                <b-select v-model="new_RPL_type" required>
                  <option value="1">RPL for Access</option>
                  <option value="3">RPL for Exemption</option>
                  <option value="2">CAT</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Status">
              <b-field>
                <b-select v-model="new_RPL.status_id" placeholder="Select a reason" required>
                  <option
                    v-for="status in $store.state.profile.rpl_statuses"
                    :key="status.id"
                    :value="status.id"
                  >{{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field horizontal label="Comment">
              <b-field>
                <b-input v-model="new_RPL.comment" type="textarea"></b-input>
              </b-field>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary">Submit</b-button>
                  <b-button
                    class="margin-left"
                    type="is-warning"
                    @click.prevent="cancelNewRPL()"
                  >Cancel
                  </b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
        <card-component
          v-if="can_edit_RPL && editingRPL"
          icon="plus"
          title="Edit Registered Prior Learning"
        >
          <form ref="RPLForm" @submit.prevent="submitEditRPL">
            <b-field horizontal label="Module">
              <b-field>
                <b-autocomplete
                  v-model="moduleHold"
                  :clear-on-select="false"
                  :data="filteredModules"
                  :loading="loadingModules"
                  autocomplete="off"
                  field="name"
                  name="module"
                  placeholder="Module name"

                  @blur="validateAutocomplete(edit_RPL.module_id,'moduleHold')"
                  @select="option => selectEditModule(option)"
                  @typing="getFilteredModule"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.code }} | {{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
            </b-field>
            <b-field horizontal label="Institution">
              <b-field>
                <b-autocomplete
                  v-model="institution"
                  v-debounce:300ms="getFilteredInstitution"
                  :clear-on-select="false"
                  :data="filteredInstitutions"
                  autocomplete="off"
                  field="name"
                  name="Institution"
                  placeholder="Institution"
                  required
                  @blur="validateAutocomplete(editRPL.institution_id,'institution')"
                  @select="option => selectEditInstitution(option)"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
            </b-field>
            <b-field horizontal label="Reason">
              <b-field>
                <b-select v-model="edit_RPL.reason_id" placeholder="Select a reason" required>
                  <option
                    v-for="reason in reasons"
                    :key="reason.id"
                    :value="reason.id"
                  >{{ reason.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Level">
              <b-field>
                <b-input
                  v-model="edit_RPL.grade"
                  autocomplete="off"
                  name="level"
                  placeholder="The level"
                  type="number"
                />
              </b-field>
            </b-field>
            <!--            <b-field label="Symbol" horizontal>-->
            <!--              <b-field>-->
            <!--                <b-input-->
            <!--                  autocomplete="off"-->
            <!--                  placeholder="The symbol"-->
            <!--                  v-model="edit_RPL.symbol"-->
            <!--                  name="symbol"-->
            <!--                />-->
            <!--              </b-field>-->
            <!--            </b-field>-->
            <b-field horizontal label="Type">
              <b-field>
                <b-select v-model="edit_RPL_type" required>
                  <option value="1">RPL for Access</option>
                  <option value="3">RPL for Exemption</option>
                  <option value="2">CAT</option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Status">
              <b-field>
                <b-select v-model="edit_RPL.status_id" placeholder="Select a reason" required>
                  <option
                    v-for="status in $store.state.profile.rpl_statuses"
                    :key="status.id"
                    :value="status.id"
                  >{{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Comment">
              <b-field>
                <b-input v-model="edit_RPL.comment" type="textarea"></b-input>
              </b-field>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary">Submit</b-button>
                  <b-button
                    class="margin-left"
                    type="is-warning"
                    @click.prevent="cancelEditRPL()"
                  >Cancel
                  </b-button>
                </div>
              </b-field>
            </b-field>
          </form>
        </card-component>
        <card-component icon="cloud-upload" title="Submit required documentation">
          <div>

            <div class="content">
              <p class="is-size-4">Required documents:</p>
              <div class="columns">
                <div class="column">
                  <p>To apply for RPL and/or CAT Download, fill in and upload the relevant of the following
                    documents:</p>

                  <b-field grouped>
                    <div class="control">
                      <b-button
                        download
                        href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/CI+RPL+Application+form+access.docx"
                        icon-left="download"
                        tag="a"
                      >RPL and CAT for access
                      </b-button>
                    </div>
                    <div class="control">
                      <b-button
                        download
                        href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/CAT+%26+RPL+form+for+exemption.doc"
                        icon-left="download"
                        tag="a"
                      >RPL and CAT for exemption
                      </b-button>
                    </div>
                  </b-field>
                </div>
              </div>

            </div>
          </div>
          <div v-if="can_create_media" class="columns">
            <b-field class="column is-4">
              <div class="columns is-centered">
                <div class="column is-12 has-text-centered">
                  <b-upload v-model="dropFile" drag-drop>
                    <section class="section">
                      <div class="content has-text-centered">
                        <p>
                          <b-icon icon="upload" size="is-large"></b-icon>
                        </p>
                        <p>Drop your file here or click to upload</p>
                        <p>Max file size of 10mb</p>
                      </div>
                    </section>
                  </b-upload>
                </div>
              </div>
            </b-field>
            <div v-if="dropFile" class="upload-file-list column">
              <div class="notification">
                <div class="level">
                  <div class="level-left">
                    <div class="level-item">
                      <form @submit.prevent="submitMedia">
                        <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                        <span>{{ dropFile.name }}</span>
                        <b-field>
                          <b-field>
                            <b-input
                              v-model="dropFile.caption"
                              name="caption"
                              placeholder="Caption"
                            />
                          </b-field>
                        </b-field>
                        <b-field>
                          <b-select v-model="dropFile.tag" placeholder="Select a tag" required>
                            <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                              {{ tag }}
                            </option>
                          </b-select>
                        </b-field>
                        <b-field>
                          <b-field grouped>
                            <div class="control">
                              <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                            </div>
                          </b-field>
                        </b-field>
                      </form>
                    </div>
                  </div>
                  <div class="level-right">
                    <div class="level-item">
                      <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b-table
            v-if="can_view_media"
            :bordered="true"
            :data="media"
            :hoverable="true"
            :striped="true"
            class="margin-top"
          >
            <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

            <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
            <b-table-column v-slot="props"
                            field="caption"
                            label="Caption"
                            sortable
            >{{ return_caption(props.row.caption) }}
            </b-table-column>
            <b-table-column v-slot="props" centered custom-key="actions">
              <b-field>
                  <span class="control">
                    <b-button
                      :href="props.row.temporary_url"
                      tag="a"
                      target="_blank"
                      type="is-info"
                    >View
                    </b-button>
                  </span>
                <span class="control">
                    <b-button
                      type="is-danger"

                      @click="start_delete_media(props.row.id)"
                    >Delete
                    </b-button>
                  </span>
              </b-field>
            </b-table-column>
          </b-table>
          <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
        </card-component>

      </b-tab-item>
      <b-tab-item icon="email" label="Email">
        <card-component>
          <div ref="emails">
            <emailForm
              v-if="learner.id!=null"
              :can_cancel="false"
              :default_recipient="learner"
              :permissions="{can_create:can_edit_learner}"
              :reply_email="reply_email"
              :username_missing="$store.state.userEmail==null"
            ></emailForm>
          </div>
          <hr/>
          <div v-for="email in paginated_emails" :key="email.id + 'email'">
            <emailListItem
              :email="email"
              :email_direction="email.was_received===1?'Received':'Sent'"
              @reply="reply"
            ></emailListItem>
          </div>
          <b-pagination
            :current.sync="pages.emails"
            :per-page="15"
            :range-after="2"
            :range-before="2"
            :total="emails.length"
            aria-current-label="Current page"
            aria-next-label="Next page"
            aria-page-label="Page"
            aria-previous-label="Previous page"
            class="margin-top"
          ></b-pagination>
        </card-component>
      </b-tab-item>
    </b-tabs>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Learner from "@/models/Learner";
import Profile from "@/models/Profile";
import Lookup from "@/models/Lookup";
import Media from "@/models/Media";
import Module from "@/models/Module";
import Enquiry from "@/models/Enquiry";
import Registration from "@/models/Registration";
import RPL from "@/models/RPL";
import EmailForm from "@/components/EmailForm";
import EmailListItem from "@/components/EmailListItem";
import Email from "@/models/Email";
import isEqual from "lodash/isEqual";


import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import checkExtension from "@/mixins/FileTypes";
import LearnerCourses from "@/components/LearnerCourses";
import LearnerPasswordForm from "@/components/LearnerPasswordForm";
import State from "@/models/State";
import Country from "@/models/Country";
import City from "@/models/City";
import set from "lodash/set";
import DisabilitiesComponent from "@/components/DisabilitiesComponent.vue";
import MarketingForm from "@/components/MarketingForm.vue";

extend("id", (idVal) => {
  var checkDigit = parseInt(idVal.charAt(idVal.length - 1));
  var odd = 0;
  var even = "";
  var evenResult = 0;
  var result;
  for (var c = 1; c <= idVal.length; c++) {
    if (c % 2 == 0) {
      even += idVal.charAt(c - 1);
    } else {
      if (c == idVal.length) {

      } else {
        odd = parseInt(odd) + parseInt(idVal.charAt(c - 1));
      }
    }
  }
  even = Number(even) * 2;
  even = even.toString();
  for (var r = 1; r <= even.length; r++) {
    evenResult = parseInt(evenResult) + parseInt(even.charAt(r - 1));
  }
  result = parseInt(odd) + parseInt(evenResult);
  result = result.toString();
  result = 10 - parseInt(result.charAt(result.length - 1));
  result = result.toString();
  if (result.length > 1) {
    result = result.charAt(result.length - 1);
  }
  if (parseInt(result) != checkDigit) {
    return "Please enter a valid SA ID number";
  } else {
    return true;
  }
});
export default {
  props: ["id"],
  components: {
    DisabilitiesComponent,
    MarketingForm,
    LearnerCourses,
    CardComponent,
    EmailForm,
    EmailListItem,
    TitleBar,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      tags: [
        "Sponsorship Letter",
        "State of Health Statement",
        "Disability and/or mental health challenge statement",
        "Certified copy of School leaving certificate",
        "SAQA verification certificate",
        "Supplementary subject certificate",
        "Academic report",
        "Certificate of conduct",
        "Proof of payment",
        "Certified copy of ID or other proof of identity",
        "Academic Certificate copy",
        "Personal Statement document",
        "Module Outline",
        "Transcript"
      ],
      copy_postal_address: false,
      dropFile: null,
      in_grade_11: false,
      loadingRPL: false,
      updatingMedia: false,
      activeTab: 0,
      showPhysical: true,
      showPostal: false,
      showAlternative: false,
      nationality: null,
      language: null,
      reply_email: {},
      pages: {emails: 1},
      updatingLearner: false,
      learner_school_lock_state: false,
      learner_finance_lock_state: false,
      learner_medical_lock_state: false,
      learner_next_of_kin_lock_state: false,
      learner_employment_lock_state: false,
      learner_activities_lock_state: false,
      learner_reference_lock_state: false,
      updatingProfile: false,
      learner_profile_lock: true,
      filteredNationalities: [],
      editingRPL: false,
      defaultOpenedDetails: [],
      filteredLanguages: [],
      institution: null,
      filteredInstitutions: [],
      filteredModules: [],
      moduleHold: "",
      selectedModuleId: null,
      new_RPL_modules: [],
      new_RPL: {
        user_id: this.id,
        institution_id: null,
        reason_id: null,
        grade: null,
        comment: null,
        type_id: "",
        status_id: 1
      },
      edit_RPL: {
        user_id: this.id,
        module_id: null,
        institution_id: null,
        reason_id: null,
        grade: null,
        symbol: null,
        comment: null,
        type_id: "",
        status_id: 1
      },
      loadingModules: false,
      learner: {
        student_level: null,
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        username: null,
        student_number: null,
        lms_id: null,
        lms_username: null,
        primary_contact_number: null,
        secondary_contact_number: null,
        cellphone_number: null,
        is_suspended: 0,
      },
      marketingObject: {},
      profile: {
        gender_id: null,
        ethnicity_id: null,
        address: null,
        citizenship: {
          type: null,
          other: null
        },
        physical_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
        },
        alternative_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
        },
        postal_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
        },
        id_number: null,
        identification_type_id: null,
        date_of_birth: null,
        nationality_id: null,
        language_id: null,
        religion_id: null,
        study_permit_number: null,
        permit_number: null,
        permit_type_id: null,
        permit_expiry_date: null,
        marital_status_id: null,
        finance: null,
        finance_other: null,
        sponsor_information: {
          last_name: null,
          initials: null,
          title: null,
          contact_number: null,
          email: null,
          address: {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
          },
        },
        billing_contact: {
          name: null,
          contact_number: null
        }, alternative_contact: {
          name: null,
          contact_number: null
        },
        next_of_kin_information: {
          last_name: null,
          initials: null,
          title: null,
          contact_number: null,
          email: null,
          address: {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
          },
        },
        family_information: {
          number_of_siblings: null,
          mother_education_level: null,
          father_education_level: null,
          grandparents_university_qualification: null,
          grandparents_technikon_degree_or_diploma: null,
          child_grant: null,
          social_pension: null,
        },
        reference_contact: [{
          last_name: null,
          initials: null,
          title: null,
          contact_number: null,
          email: null,
          address: {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
            country_id: null,
            city_id: null,
            province_id: null
          },
        }],

        medical_information: {
          good_physical_health: null,
          physical_disabilities: null,
          seeing: null,
          hearing: null,
          communicating: null,
          walking: null,
          remembering: null,
          self_care: null,
        },
        school_attended: {
          name: null,
          type_of_school: null,
          contact_number: null,
          address: {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null,
          },
        },
        school_record: {
          school_leaving_year: null,
          examination_authority: null,
          exemption_type: null,
          nbt_math: null,
          nbt_english: null,
          saqa_verification: null
        },
        previous_studies: [
          {
            from_year: null,
            to_year: null,
            institution_name: null,
            qualification_name: null,
            is_complete: false,
            qualification_attached: false,
          },
        ],
        employment_information: [
          {
            start_date: null,
            type_of_work: null,
            employer: null,
          },
        ],
        activities: {
          sport_societies: null,
          leadership_roles: null,
          community_involvement: null,
        },
        personal_statements: {
          admission_reason: null,
          interests_hobbies: null,
          goals: null,
          occupation: null,
        },
      },
    };
  },
  mixins: [checkExtension],

  watch: {
    dropFile(val) {
      if (val !== null) {
        if (!this.checkExtension(val)) {
          this.dropFile = null
        }
      }
    }
  },
  computed: {
    learner_details_lock() {

      return this.learner_details_lock_state
    },
    learner_school_lock() {

      return this.learner_school_lock_state
    }, learner_finance_lock() {

      return this.learner_finance_lock_state
    },
    learner_medical_lock() {

      return this.learner_medical_lock_state
    }, learner_next_of_kin_lock() {

      return this.learner_next_of_kin_lock_state
    }, learner_employment_lock() {

      return this.learner_employment_lock_state
    }, learner_activities_lock() {

      return this.learner_activities_lock_state
    }, learner_reference_lock() {

      return this.learner_reference_lock_state
    },
    storedLearner() {
      return Learner.query().first()
    }, storedProfile() {
      return Profile.query().first()
    },
    showSponsor() {
      return this.lookupCheck('Sponsor')
    }, showOther() {
      return this.lookupCheck('Other')
    },
    isDetailsIncomplete() {

      if (this.storedProfile !== null) {
        return this.checkKeys([
            'gender_id',
            'ethnicity_id',
            'date_of_birth',
            'identification_type_id',
            'id_number',
            'where_hear',
            'why_choose',
            'prior_institution',
            ...((this.storedProfile.citizenship.type !== null && this.storedProfile.citizenship.type === 'Other') ? [
              'permit_number',
              'permit_type_id',
              'permit_expiry_date',] : [])
          ], this.storedProfile)
          || this.checkKeys([
            'student_level',
            'title',
            'first_name',
            'last_name',
            'email',
            'primary_contact_number'
          ], this.storedLearner)
          || this.checkKeys([
            'type',
            ...((this.storedProfile.citizenship.type !== null && this.storedProfile.citizenship.type !== 'RSA') ? ['other'] : [])
          ], this.storedProfile.citizenship)
          || this.checkKeys([
            'line_1',
            'postal_code',
            'country_id',
          ], this.storedProfile.physical_address)
          || this.checkKeys([
            'line_1',
            'postal_code',
            'country_id',
          ], this.storedProfile.postal_address)
      }
      return true

    },
    isFinanceIncomplete() {

      if (this.storedProfile !== null) {
        return this.checkKeys([
          'finance',
          ...((this.showOther) ? [
            'finance_other',
          ] : [])
        ], this.storedProfile) || this.showSponsor ? (
          this.checkKeys([
            'last_name',
            'initials',
            'title',
            'contact_number',
            'email',
          ], this.storedProfile.sponsor_information) || this.checkKeys([
            'line_1',
            'postal_code',
            'country_id',
          ], this.storedProfile.sponsor_information.address)
        ) : false

      }
      return true

    },
    isFamilyIncomplete() {

      if (this.storedProfile !== null) {
        return this.checkKeys([
            'last_name',
            'initials',
            'title',
            'contact_number',
            'email',
          ], this.storedProfile.next_of_kin_information) ||
          this.checkKeys([
            'line_1',
            'postal_code',
            'country_id',
          ], this.storedProfile.next_of_kin_information.address)
      }
      return true

    },
    isMedicalIncomplete() {

      if (this.storedProfile !== null) {
        if(this.storedProfile.disability_ids !== null || this.storedProfile.accommodation_ids !== null){
          return false
        }
      }
      return true

    },
    isPriorIncomplete() {
      if (this.storedProfile !== null) {
        return this.checkKeys([
            'name',
            'type_of_school',

          ], this.storedProfile.school_attended) ||
          this.checkKeys([
            'school_leaving_year',
            'examination_authority',
            'exemption_type',
            'nbt_math',
            'nbt_english',
            ...((this.storedProfile.school_attended.type_of_school !== 'Public') ? ['saqa_verification'] : [])
          ], this.storedProfile.school_record) || (this.storedProfile.previous_studies.some(study => {
            return this.checkKeys([
              'from_year',
              'to_year',
              'institution_name',
              'qualification_name',
              'is_complete',
              'qualification_attached',
              'type_of_institution',
              ...((study.type_of_institution !== 'Public') ? ['saqa_verification'] : [])
            ], study)
          }))
      }
      return true

    },
    isEmploymentIncomplete() {
      if (this.storedProfile !== null) {
        return this.storedProfile.employment_information.some(job => {
          return this.checkKeys([
            'start_date',
            'type_of_work',
            'employer',
          ], job)
        })
      }
      return true

    },
    isActivitiesIncomplete() {
      if (this.storedProfile !== null) {
        return this.checkKeys([
            'sport_societies',
            'leadership_roles',
            'community_involvement',

          ], this.storedProfile.activities) ||
          this.checkKeys([
            'admission_reason',
            'interests_hobbies',
            'goals',
            'occupation',
          ], this.storedProfile.personal_statements)
      }
      return true

    },
    isReferenceIncomplete() {

      if (this.storedProfile !== null) {
        return this.storedProfile.reference_contact.some(reference => {
          return this.checkKeys([
            'last_name',
            'initials',
            'title',
            'contact_number',
            'email',
          ], reference) || this.checkKeys(['line_1',
            'postal_code',
            'country_id'], reference.address)
        })

      }
      return true

    },
    physical_disability: {
      set(newVal) {
        if (newVal === false) {
          this.profile.medical_information.physical_disability = false
        }
        if (newVal === true) {
          if (typeof this.profile.medical_information.physical_disability === 'string') {

          } else {
            this.profile.medical_information.physical_disability = ''
          }
        }
      }, get() {

        if (this.profile.medical_information.physical_disability === false) {
          return false
        }
        if (this.profile.medical_information.physical_disability === null) {
          return null
        }
        if (typeof this.profile.medical_information.physical_disability == 'undefined') {
          return null
        }
        return true
      }
    },
    psychological_disability: {
      set(newVal) {
        if (newVal === false) {
          this.profile.medical_information.psychological_disability = false
        }
        if (newVal === true) {
          if (typeof this.profile.medical_information.psychological_disability === 'string') {

          } else {
            this.profile.medical_information.psychological_disability = ''
          }
        }
      }, get() {

        if (this.profile.medical_information.psychological_disability === false) {
          return false
        }
        if (this.profile.medical_information.psychological_disability === null) {
          return null
        }
        if (typeof this.profile.medical_information.psychological_disability == 'undefined') {
          return null
        }
        return true
      }
    },
    chronic: {
      set(newVal) {
        if (newVal === false) {
          this.profile.medical_information.chronic = false
        }
        if (newVal === true) {
          if (typeof this.profile.medical_information.chronic === 'string') {

          } else {
            this.profile.medical_information.chronic = ''
          }
        }
      }, get() {

        if (this.profile.medical_information.chronic === false) {
          return false
        }
        if (this.profile.medical_information.chronic === null) {
          return null
        }
        if (typeof this.profile.medical_information.chronic == 'undefined') {
          return null
        }
        return true
      }
    },
    selectedPhysicalCountryStates() {

      return this.selectedCountryStates(this.profile.physical_address.country_id)
    },
    selectedPostalCountryStates() {

      return this.selectedCountryStates(this.profile.postal_address.country_id)
    }, selectedAlternativeCountryStates() {

      return this.selectedCountryStates(this.profile.alternative_address.country_id)
    }, selectedSponsorCountryStates() {

      return this.selectedCountryStates(this.profile.sponsor_information.address.country_id)
    }, selectedNextOfKinCountryStates() {

      return this.selectedCountryStates(this.profile.next_of_kin_information.address.country_id)
    }, selectedSchoolCountryStates() {

      return this.selectedCountryStates(this.profile.school_attended.address.country_id)
    },
    cities() {
      return City.query().orderBy('name').get()
    },
    countries() {
      return [Country.query().where('name', 'South Africa').first() ? Country.query().where('name', 'South Africa').first() : {}, ...Country.query().orderBy('name').get()]
    },
    emails() {
      return Email.query().orderBy("created_at", "desc").with("media").get();
    },
    paginated_emails() {
      return Email.query()
        .orderBy("created_at", "desc")
        .with("media")
        .offset((this.pages.emails - 1) * 15)
        .limit(15)
        .get();
    },
    nbt_math_select: {
      set(newValue) {
        this.profile.school_record.nbt_math = newValue;
      },
      get() {
        if (
          this.profile.school_record.nbt_math === "not complete" ||
          this.profile.school_record.nbt_math === "awaiting results"
        ) {
          return this.profile.school_record.nbt_math;
        }
        if (this.profile.school_record.nbt_math != null) {
          return "";
        }
        return null;
      },
    },
    nbt_english_select: {
      set(newValue) {
        this.profile.school_record.nbt_english = newValue;
      },
      get() {
        if (
          this.profile.school_record.nbt_english === "not complete" ||
          this.profile.school_record.nbt_english === "awaiting results"
        ) {
          return this.profile.school_record.nbt_english;
        }
        if (this.profile.school_record.nbt_english != null) {
          return "";
        }
        return null;
      },
    },

    edit_RPL_type: {
      get() {
        return this.edit_RPL.type_id.toString();
      },
      set(newValue) {
        this.edit_RPL.type_id = parseInt(newValue);
      },
    },
    new_RPL_type: {
      get() {
        return this.new_RPL.type_id.toString();
      },
      set(newValue) {
        this.new_RPL.type_id = parseInt(newValue);
      },
    },
    priorLearnings() {
      return RPL.query()
        .where('user_id', (value) => {
          return parseInt(value) === parseInt(this.id)
        })
        .with("module")
        .get();
    },
    can_delete_media() {
      return this.$store.getters["entities/permissions/find"]("delete media");
    },
    can_view_media() {
      return this.$store.getters["entities/permissions/find"]("view media");
    },
    can_create_media() {
      return this.$store.getters["entities/permissions/find"]("create media");
    },
    can_create_RPL() {
      return this.$store.getters["entities/permissions/find"](
        "create priorlearnings"
      );
    },
    date_of_birth: {
      set(date) {
        let process = new Date(date);
        this.profile.date_of_birth = new Date(
          process.getTime() - process.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
      },
      get() {
        if (this.profile.date_of_birth === null) {
          return null
        }
        return new Date(this.profile.date_of_birth);
      },
    },
    permit_expiry_date: {
      set(date) {
        let process = new Date(date);
        this.profile.permit_expiry_date = new Date(
          process.getTime() - process.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
      },
      get() {
        if (this.profile.permit_expiry_date === null) {
          return null
        }
        return new Date(this.profile.permit_expiry_date);
      },
    },
    enquiries() {
      return Enquiry.query()
        .where('user_id', (value) => {
          return parseInt(value) === parseInt(this.id)
        })
        .with("course")
        .with("user")
        .get();
    },
    registrations() {
      return Registration.query()
        .where('user_id', (value) => {
          return parseInt(value) === parseInt(this.id)
        })
        .with("modules")
        .with("course")
        .get();
    },
    media() {
      return Media.all();
    },
    genders() {
      return Lookup.query().where("lookup_type_id", 1).get();
    },
    ethnicities() {
      return Lookup.query().where("lookup_type_id", 2).get();
    },
    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    },
    nationalities() {
      return Lookup.query().where("lookup_type_id", 4).get();
    },
    // languages() {
    //   return Lookup.query().where("lookup_type_id", 5).get();
    // },
    reasons() {
      return Lookup.query().where("lookup_type_id", 8).get();
    },
    institutions() {
      return Lookup.query().where("lookup_type_id", 9).get();
    },

    qualifications() {
      return Lookup.query().where("lookup_type_id", 19).orderBy('name').get();
    },
    permit_types() {
      return Lookup.query().where("lookup_type_id", 10).where((lookup) => {
        return (lookup.id!==833 && lookup.id!==834)
      }).get();
    },
    religions() {
      return Lookup.query().where("lookup_type_id", 11).get();
    },
    marital_status() {
      return Lookup.query().where("lookup_type_id", 12).get();
    },
    exemption_type() {
      return Lookup.query().where("lookup_type_id", 13).get();
    },
    reference_type() {
      return Lookup.query().where("lookup_type_id", 14).get();
    },
    finance_type() {
      return Lookup.query().where("lookup_type_id", 15).get();
    },
    examination_authority() {
      return Lookup.query().where("lookup_type_id", 16).get();
    },
    education_level() {
      return Lookup.query().where("lookup_type_id", 17).get();
    },
    can_edit_learner() {
      return this.$store.getters["entities/permissions/find"]("edit learners");
    },
    can_view_RPL() {
      return this.$store.getters["entities/permissions/find"](
        "view priorlearnings"
      );
    },
    can_edit_RPL() {
      return this.$store.getters["entities/permissions/find"](
        "edit priorlearnings"
      );
    },
    can_delete_RPL() {
      return this.$store.getters["entities/permissions/find"](
        "delete priorlearnings"
      );
    },
    learner_details_lock_icon() {
      if (this.learner_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_reference_lock_icon() {
      if (this.learner_reference_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_profile_lock_icon() {
      if (this.learner_profile_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_finance_lock_icon() {
      if (this.learner_finance_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_medical_lock_icon() {
      if (this.learner_medical_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_school_lock_icon() {
      if (this.learner_school_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_family_lock_icon() {
      if (this.learner_family_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_next_of_kin_lock_icon() {
      if (this.learner_next_of_kin_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_studies_lock_icon() {
      if (this.learner_studies_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_employment_lock_icon() {
      if (this.learner_employment_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_activities_lock_icon() {
      if (this.learner_activities_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
    learner_statement_lock_icon() {
      if (this.learner_statement_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
  },
  methods: {
    handlePermitTypeSet(name){
      if (name==='RSA') {
        this.profile.permit_type_id = 834
      } else if (name==='Dual: RSA and other') {
        this.profile.permit_type_id = 833
      } else {
        this.profile.permit_type_id = null
      }
    },
    add_reference() {

      this.profile.reference_contact.push({
          is_complete: false,
          last_name: null,
          initials: null,
          title: null,
          contact_number: null,
          email: null,
          address: {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null, country_id: null,
            city_id: null,
            province_id: null
          }
        }
      );
    },
    remove_reference(index) {

      this.$buefy.dialog.confirm({
        title: "Removing reference",
        message:
          "Are you sure you want to <b>remove</b> this? This action cannot be undone.",
        confirmText: "Remove Reference",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          (this.profile.reference_contact = this.profile.reference_contact.filter(
            (item, place) => place != index
          )),
      });
    },
    checkKeys(keys = [], values) {
      let filtered = Object.keys(values)
        .filter(key => keys.includes(key))
        .reduce((obj, key) => {
          obj[key] = values[key];
          return obj;
        }, {});

      return Object.values(filtered).includes(null)
    },
    getStateId(name, country_id) {

      return parseInt(State.query().where('country_id', country_id).where('name', name).first().id)
    },
    getCountryId(name) {
      return Country.query().where('name', name).first().id
    },
    getFilteredCity(text, state_id) {
      City.deleteAll()
      City.fetchAll({page: 1, limit: 30}, {state_id: state_id, search: text})

    },
    selectedCountryStates(country_id) {
      if (country_id) {
        return State.query().where('country_id', country_id).orderBy('name').get()
      }
      return []
    },
    getCities(value) {
      this.$store.dispatch('loader/show')
      State.fetchAll({page: 1, limit: 5}, {name: value}, ['cities']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    }, getStates(value) {
      this.$store.dispatch('loader/show')
      Country.fetchAll({page: 1, limit: 5}, {name: value}, ['states']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    validateAutocomplete(source, target) {
      if (source == null) {
        set(this, target, null)
      }
    },
    openPasswordModal() {
      this.$buefy.modal.open({
        parent: this,
        component: LearnerPasswordForm,
        props: {
          learner_id: parseInt(this.id)
        },
        hasModalCard: true,
        trapFocus: true
      })
    },

    lookupCheck(name) {

      if (name && this.finance_type.length > 0) {
        let item = this.finance_type.find(type => {
          return type.name.includes(name)
        })
        if (typeof item !== 'undefined') {
          return item.id === this.profile.finance;

        }
        return false
      }
      return false;
    },
    setPage(event, type) {
      this.pages[type] = event;
    },
    reply($evt) {
      this.reply_email = $evt;
      this.$refs["emails"].scrollIntoView({
        behavior: "smooth",
      });
    },
    twin_addresses(value) {
      this.copy_postal_address = value;
      if (value == true) {
        return (this.profile.postal_address = this.profile.physical_address);
      }
      return (this.profile.postal_address = JSON.parse(
        JSON.stringify(this.profile.postal_address)
      ));
    },
    remove_employer(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting employer",
        message:
          "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Employer",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          (this.profile.employment_information = this.profile.employment_information.filter(
            (item, place) => place != index
          )),
      });
    },
    remove_study(index) {
      this.$buefy.dialog.confirm({
        title: "Deleting qualification",
        message:
          "Are you sure you want to <b>delete</b> this? This action cannot be undone.",
        confirmText: "Delete Qualification",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () =>
          (this.profile.previous_studies = this.profile.previous_studies.filter(
            (item, place) => place != index
          )),
      });
    },
    add_employment() {
      this.profile.employment_information.push({
        start_date: null,
        end_date: null,
        type_of_work: null,
        employer: null,
      });
    },
    add_previous_study() {
      this.profile.previous_studies.push({
        from_year: null,
        to_year: null,
        institution_name: null,
        qualification_name: null,
        is_complete: false,
        qualification_attached: false,
        saqa_verification: null,
        type_of_institution: null
      });
    },
    addressIcon(open) {
      return open == true ? "chevron-down" : "chevron-right";
    },
    typeName(type_id) {
      if (type_id == 1) {
        return "RPL for Access";
      } else if (type_id == 2) {
        return "CAT";
      } else if (type_id == 3) {
        return 'RPL for Exemption'
      }
      return "None";
    },
    cancelNewRPL() {
      for (var key in this.new_RPL) {
        if (key != "user_id" && key != "type_id") {
          this.new_RPL[key] = null;
        }
      }
      this.new_RPL.type_id = "";
      this.new_RPL_modules = null
      this.moduleHold = null;
      this.institution = null;
    },
    cancelEditRPL() {
      for (var key in this.edit_RPL) {
        if (key != "user_id") {
          this.edit_RPL[key] = null;
        }
      }
      this.editingRPL = false;
      this.moduleHold = null;
      this.institution = null;
    },
    editRPL(rpl) {
      this.$refs["RPLForm"].scrollIntoView({
        behavior: "smooth",
      });
      this.editingRPL = true;
      this.edit_RPL = RPL.find(rpl.id);
      this.moduleHold = rpl.module.name;
      this.institution = this.lookupName(rpl.institution_id);
    },
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    lookupName(lookup_id) {
      if (lookup_id) {
        return Lookup.query().whereId(lookup_id).get()[0].name;
      }
      return "loading";
    },
    submitNewRPL() {
      this.$store.dispatch("loader/show");
      this.loadingRPL = true;
      this.new_RPL.user_id = parseInt(this.id)
      this.new_RPL_modules = this.new_RPL_modules.filter(module => {
        return module.id !== null
      })

      Promise.all([this.new_RPL_modules.map(module => {
        return RPL.make({...this.new_RPL, ...{module_id: parseInt(module.id), symbol: module.symbol}})
      })])
        .then(() => {
          this.cancelNewRPL();

          RPL.fetchAll(this.id).then(() => {
            this.$store.dispatch("loader/hide");
            this.$buefy.snackbar.open({
              message: "RPL saved!",
              queue: false,
              type: "is-link",
            });
            this.loadingRPL = false;
          });
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.loadingRPL = false;
        });
    },
    submitEditRPL() {
      this.$store.dispatch("loader/show");
      this.loadingRPL = true;
      RPL.updateRemote(this.edit_RPL).then(() => {
        RPL.fetchAll(this.id).then(() => {
          this.$store.dispatch("loader/hide");
          this.$buefy.snackbar.open({
            message: "RPL saved!",
            queue: false,
            type: "is-link",
          });
          this.cancelEditRPL();

          this.loadingRPL = false;
        });
      });
    },
    selectModule(module, index) {
      if (module) {
        if (!this.new_RPL_modules.some(existing_module => {
          existing_module.id == module.id
        })) {
          this.new_RPL_modules[index].id = module.id
        }
      }
    },
    addNewModule() {
      this.new_RPL_modules.push({nameHold: '', id: null, symbol: null})
    },
    removeNewModule(id) {
      this.new_RPL_modules = this.new_RPL_modules.filter((module) => {
        return module.id !== id
      })
    },
    selectEditModule(module) {
      if (module) {
        this.edit_RPL.module_id = module.id;
      }
    },
    getFilteredModule(text) {
      this.loadingModules = true;

      Module.search(text, true, 1).then((result) => {
        this.loadingModules = false;
        if (result.entities) {
          this.filteredModules = result.entities.modules.filter(
            (module) =>
              !this.priorLearnings.some((item) => item.module.id == module.id)
          );
        } else {
          this.filteredModules = [];
        }
      });
    },
    copyLink(link) {
      const el = document.createElement("textarea");
      el.value = link;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    },
    viewRegistration(registration) {
      this.$router.push(`/registrations/edit/${registration?.id}?index_stage=${registration?.stage_id}`);
    },
    registrationStatusName(id) {
      return this.$store.state.statuses.statuses.find(status => status.id === parseInt(id)).name

    },
    totalCredits(registration) {
      let total = 0;
      registration.modules.forEach((element) => {
        if (element.status_id !== 2) {
          total += element.credits;
        }
      });
      return total;
    },
    courseName(registration) {
      return registration.course != null ? registration.course.name : "None";
    },
    viewEnquiry(enquiry_id) {
      this.$router.push(`/enquiries/edit/${enquiry_id}`);
    },
    statusName(status) {
      let name = "";
      switch (status) {
        case 1:
          name = "None";
          break;
        case 2:
          name = "Won";
          break;
        case 3:
          name = "Lost";
      }
      return name;
    },
    return_caption(caption) {
      if (caption == "undefined") {
        return "None";
      } else {
        return caption;
      }
    },
    deleteMedia(mediaId) {
      this.updatingMedia = true;
      Media.deleteRemote(mediaId)
        .then(() => {
          Media.delete(mediaId);
          Media.fetchAll(this.id)
            .then(() => {
              this.updatingMedia = false;
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteRPL(rpl) {
      this.$store.dispatch("loader/show");
      this.loadingRPL = true;
      RPL.deleteRemote(rpl)
        .then(() => {
          RPL.delete(rpl.id);
          this.loadingRPL = false;
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete_media(mediaId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Media",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Media",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteMedia(mediaId),
      });

    },
    start_delete_RPL(rpl) {
      this.$buefy.dialog.confirm({
        title: "Deleting Registered Prior Learning",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Prior Learning",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteRPL(rpl),
      });

    },
    submitMedia() {
      this.updatingMedia = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("tag", this.dropFile.tag);
      formData.append("caption", this.dropFile.caption);

      Media.uploadToLearner(formData, this.id)
        .then(() => {
          Media.fetchAll(this.id)
            .then(() => {
              this.dropFile = null;
              this.updatingMedia = false;
            })
            .catch(() => {
              this.updatingMedia = false;

              this.$store.dispatch("toast/createToast", {
                message: "Upload failed",
              });
            });
        })
        .catch((err) => {
          this.updatingMedia = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
    getFilteredNationality(text) {
      if (text.length > 0) {
        this.filteredNationalities = this.nationalities.filter((option) => {
          return (
            option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >=
            0
          );
        });
      }
    },
    selectNationality(option) {
      this.profile.nationality_id = option.id;
    },
    // getFilteredLanguage(text) {
    //   this.filteredLanguages = this.languages.filter((option) => {
    //     return (
    //       option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
    //     );
    //   });
    // },
    // selectLanguage(option) {
    //   this.profile.language_id = option.id;
    // },
    getFilteredInstitution(text) {
      this.filteredInstitutions = this.institutions.filter((option) => {
        return (
          option.name.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0
        );
      });
    },
    selectInstitution(option) {
      if (option) {
        this.new_RPL.institution_id = option.id;
      }
    },
    selectEditInstitution(option) {
      if (option) {
        this.editRPL.institution_id = option.id;
      }
    },
    toggleLearnerDetailsLock() {
      if (this.can_edit_learner) {
        this.learner_details_lock_state = !this.learner_details_lock_state;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
    toggleLearnerProfileLock() {
      if (this.can_edit_learner) {
        this.learner_profile_lock_state = !this.learner_profile_lock_state;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },

    submitProfile(keys = null) {

      this.updatingProfile = true;
      (keys === null ? Learner.updateRemote(this.learner) : Promise.resolve()).then(() => {
        if (this.learner.profile == null) {
          Profile.newProfile({...this.profile, ...this.marketingObject}, this.id)
            .then(() => {
              this.updatingProfile = false;
              this.$buefy.snackbar.open({
                message: "Profile Updated!",
                queue: false,
                type: "is-link",
              });
            })
            .catch((err) => {
              this.updatingProfile = false;

              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        } else {
          delete this.profile.$id;
          let send = this.profile;

          if (keys != null) {
            send = {};
            keys.forEach((key) => {
              let new_obj = {[key]: this.profile[key]};
              send = {...new_obj, ...send};
            });
            if (keys.includes('finance')) {
              send = {...send, ...{finance: this.profile.finance, finance_other: this.profile.finance_other}}
            }
          }

          Profile.updateRemote({...send, ...this.marketingObject}, this.id)
            .then(() => {
              this.updatingProfile = false;
              this.$buefy.snackbar.open({
                message: "Profile Updated!",
                queue: false,
                type: "is-link",
              });
            })
            .catch((err) => {
              this.updatingProfile = false;

              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        }
      })

    },
    submit() {
      this.updatingLearner = true;
      Learner.updateRemote(this.learner)
        .then(() => {
          this.updatingLearner = false;
          this.$buefy.snackbar.open({
            message: this.$tc("Learner", 1) + " Updated!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.updatingLearner = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          // this.$store.dispatch("toast/createToast", {
          //   message: "Something went wrong"
          // });
        });
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Media.deleteAll();
    Email.deleteAll();
    Country.fetchAll({page: 1, limit: 999})

    Lookup.fetchAll(this.id).then(() => {
      Learner.fetchById(this.id)
        .then(() => {
          this.learner = Learner.query()
            .whereId(this.id)
            .withAllRecursive()
            .first();

          if (this.learner.profile != null) {
            this.profile = this.learner.profile;
            let countriesId = []
            if (this.profile.physical_address.country) {
              if (!countriesId.includes(this.profile.physical_address.country)) {
                countriesId.push(this.profile.physical_address.country)
              }
            }

            if (this.profile.postal_address.country) {
              if (!countriesId.includes(this.profile.postal_address.country)) {
                countriesId.push(this.profile.postal_address.country)
              }
            }
            if (this.profile.alternative_address.country) {
              if (!countriesId.includes(this.profile.alternative_address.country)) {
                countriesId.push(this.profile.alternative_address.country)
              }
            }
            if (this.profile.next_of_kin_information.address.country) {
              if (!countriesId.includes(this.profile.next_of_kin_information.address.country)) {
                countriesId.push(this.profile.next_of_kin_information.address.country)
              }
            }
            if (this.profile.reference_contact) {
              this.profile.reference_contact.map(contact => {
                if (contact.address.country) {
                  if (!countriesId.includes(contact.address.country)) {
                    countriesId.push(contact.address.country)
                  }
                }
              })

            }
            if (this.profile.sponsor_information.address.country) {
              if (!countriesId.includes(this.profile.sponsor_information.address.country)) {
                countriesId.push(this.profile.sponsor_information.address.country)
              }
            }
            if (this.profile.school_attended.address.country) {
              if (!countriesId.includes(this.profile.school_attended.address.country)) {
                countriesId.push(this.profile.school_attended.address.country)
              }
            }
            if (!Object.prototype.hasOwnProperty.call(this.profile.medical_information, 'physical_disability')) {
              this.$set(this.profile.medical_information, 'physical_disability', null)
            }
            if (!Object.prototype.hasOwnProperty.call(this.profile.medical_information, 'chronic')) {
              this.$set(this.profile.medical_information, 'chronic', null)
            }
            if (!Object.prototype.hasOwnProperty.call(this.profile.medical_information, 'psychological_disability')) {
              this.$set(this.profile.medical_information, 'psychological_disability', null)
            }
            if (!Object.prototype.hasOwnProperty.call(this.profile.school_attended, 'type_of_school')) {
              this.$set(this.profile.school_attended, 'type_of_school', null)
            }
            if (!Object.prototype.hasOwnProperty.call(this.profile.school_record, 'saqa_verification')) {
              this.$set(this.profile.school_record, 'saqa_verification', null)
            }
            this.profile.previous_studies.map((study, index) => {
              if (!Object.prototype.hasOwnProperty.call(study, 'saqa_verification')) {
                this.$set(this.profile.previous_studies[index], 'saqa_verification', null)
              }
              if (!Object.prototype.hasOwnProperty.call(study, 'type_of_instition')) {
                this.$set(this.profile.previous_studies[index], 'type_of_instition', null)
              }
            })
            this.profile.employment_information.map((job, index) => {
              if (!Object.prototype.hasOwnProperty.call(job, 'end_date')) {
                this.$set(this.profile.employment_information[index], 'end_date', null)
              }

            })
            let countryPromises = []
            countriesId.forEach(country => {
              countryPromises.push(Country.fetchAll({page: 1, limit: 1}, {name: country}, ['states'])
              )
            })
            Promise.all(countryPromises)
            let nation_object = Lookup.query()
              .where((lookup) => {
                return lookup.id == this.profile.nationality_id;
              })
              .get();
            if (nation_object.length > 0) {
              this.nationality = nation_object[0].name;
            }
            //
            // let language_object = Lookup.query()
            //   .where((lookup) => {
            //     return lookup.id == this.profile.language_id;
            //   })
            //   .get();
            // if (language_object.length > 0) {
            //   this.language = language_object[0].name;
            // }
            if (
              isEqual(
                this.profile.physical_address,
                this.profile.postal_address
              )
            ) {
              this.twin_addresses(true);
            }
          }
          this.$store.dispatch("loader/hide");

          //delete intials
          // if (this.learner.profile.next_of_kin_information.hasOwnProperty('intials')) {
          //   delete this.learner.profile.next_of_kin_information.intials
          // }
          // if (this.learner.profile.reference_contact.hasOwnProperty('intials')) {
          //   delete this.learner.profile.reference_contact.intials
          // }
          // if (this.learner.profile.sponsor_information.hasOwnProperty('intials')) {
          //   delete this.learner.profile.sponsor_information.intials
          // }
        })
        .catch((err) => {
          if(err.response?.data){
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          }
          this.$store.dispatch("loader/hide");
        });

      RPL.fetchAll(this.id).then(() => {
        this.loadingRPL = false
      });
    });

    this.updatingMedia = true;
    Media.fetchAll(this.id)
      .then(() => {
        this.updatingMedia = false;
      })
      .catch((err) => {
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      });
    Enquiry.fetchByUser(1, 40, this.id);
    Email.fetch(this.id, null, null, null, null, null, 1, 999, ["media"]);

    Registration.fetchByUser(1, 40, this.id);
  },
};
</script>


<!--<style >-->
<!--.card.basicDetailsCard .card-header p{-->
<!--  padding-bottom: 0;-->
<!--}-->
<!--.card.basicDetailsCard .card-content{-->
<!--  padding-top: 0 !important;-->
<!--}-->
<!--</style>-->
