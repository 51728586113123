export const LoaderModule = {
  namespaced: true,

  state: {
    loading: false,
    overlay: false,
    opaque: false,
  },

  actions: {

    show({
           commit
         }) {
      commit('SHOW_LOADER');
    }, showOverlay({
                     commit
                   }) {
      commit('SHOW_OVERLAY');
    }, showOpaque({
                    commit
                  }) {
      commit('SHOW_OPAQUE_LOADER');
    },
    hide({
           commit
         }) {
      commit('HIDE_LOADER');
    }, hideOverlay({
                     commit
                   }) {
      commit('HIDE_OVERLAY');
    }, hideOpaque({
                    commit
                  }) {
      commit('HIDE_OPAQUE_LOADER');
    }
  },

  mutations: {

    SHOW_OPAQUE_LOADER(state) {
      state.loading = true;
      state.opaque = true
    },
    HIDE_OPAQUE_LOADER(state) {
      state.loading = false
      state.opaque = false
    }, SHOW_LOADER(state) {
      state.loading = true;
      state.opaque = false
    },
    HIDE_LOADER(state) {
      state.loading = false
      state.opaque = false
    },
    SHOW_OVERLAY(state) {
      state.overlay = true;
      state.opaque = false
    },
    HIDE_OVERLAY(state) {
      state.overlay = false
      state.opaque = false
    }
  }
};
