<template>
  <section class="section"></section>
</template>

<script>
import { AuthClient } from "@/clients/AuthClient";
import axios from "axios";
import Permission from "@/models/Permission";
import Store from "@/store";

export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  methods: {
    toggleUserDetailsLock() {
      if (this.can_edit_user) {
        this.user_details_lock = !this.user_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");

    if (this.$route.query.signature) {
      axios
        .get(
          `${this.$route.query.signedUrl}&signature=${this.$route.query.signature}`
        )
        .then((result) => {
          this.$cookie.set("access_token", result.data.accessToken);
          if (!this.$cookie.get("reloaded")) {
            this.$cookie.set("reloaded", true);
            this.$router.go();
          }
          AuthClient.check()
            .then((response) => {
              let permissionData = [];
              response.data.data.attributes.permissions.forEach(
                (permission) => {
                  permissionData.push({
                    id: permission.id,
                    name: permission.attributes.name,
                  });
                }
              );
              Permission.insert({
                data: permissionData,
              });

              Store.commit("user", {
                name: response.data.data.attributes.first_name,
                email: response.data.data.attributes.email,
                avatar:
                  "/data-sources/avatars/annie-spratt-121576-unsplash.jpg",
                id: response.data.data.id,
              });

              Store.dispatch("auth/updateAuthenticated", true);

              this.$router.push("/registrations/mine");
            })
            .catch(() => {
              Store.dispatch("auth/updateAuthenticated", false);
              this.$router.push("/login");
            });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });

          this.$store.dispatch("toast/createToast", {
            message: "Incorrect token",
          });
          setTimeout(() => {
            this.$router.push("/login");
          }, 2000);
        });
      this.$store.dispatch("loader/hide");
    } else {
      this.$store.dispatch("toast/createToast", {
        message: "Oops! You shouldn't be here",
      });
      setTimeout(() => {
        this.$router.push("/login");
      }, 2000);
    }
    this.$store.dispatch("loader/hide");
  },
};
</script>
