<template>
  <Lookup :lookup_type_id="15" :title="'Payment Method'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "PaymentMethod",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
