import {TransformModel} from "@/models/TransformModel";
import Invoice from "@/models/Invoice";

/**
 * An invoice item
 * @property {number} id
 */
export default class InvoiceItem extends TransformModel {
  static entity = 'invoice-items'

  static fields() {
    return {
      id: this.attr(null),
      type_id: this.attr(null),
      invoice_id: this.attr(null),
      invoice: this.belongsTo(Invoice, 'invoice_id'),
      description: this.attr(null),
      has_vat: this.attr(null),
      total_ex_vat: this.attr(null),
      vat_rate: this.attr(null),
      total: this.attr(null),
      created_at: this.attr(null),
      updated_at: this.attr(null),

    }
  }


}
