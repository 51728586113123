import {
  TransformModel
} from '@/models/TransformModel'
import RegistrationBoard from "@/models/RegistrationBoard";

export default class RegistrationStage extends TransformModel {
  static entity = 'registrationsStages'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      order: this.attr(null),
      board_id: this.attr(null),
      board:this.belongsTo(RegistrationBoard,'board_id')
    }
  }

  static fetchAll(board_id = null) {
    return this.api().get(`/registration-stages?with[]=board`, {
      params: {
        ...(board_id ? {
          board_id: board_id
        } : {}),
      },
      dataTransformer: ({data: {data}}) => {
        return data.map(stage => {
          if (stage.attributes.board) {
            Object.assign(stage.attributes.board, stage.attributes.board.attributes)
            stage.attributes.board.id = parseInt(stage.attributes.board.id)

          }
          return {...stage, ...stage.attributes}
        })
      },
    })
  }


}
