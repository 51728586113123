<template>
  <SemesterForm  :can-create="can_create_semesters" :can-delete="can_delete_semesters" :can-edit="can_edit_semesters" />
</template>

<script>
import SemesterForm from "@/components/semesters/SemesterForm";

export default {
  name: 'CourseCreate',
  components: {
    SemesterForm,

  },
  computed:{
    can_delete_semesters() {
      return !!this.$store.getters["entities/permissions/find"]("delete courses");
    }, can_edit_semesters() {
      return !!this.$store.getters["entities/permissions/find"]("edit courses");
    }, can_create_semesters() {
      return !!this.$store.getters["entities/permissions/find"]("create courses");
    }
  },
};
</script>
