<template>
  <section class="section">
    <title-bar>Edit Module</title-bar>
    <card-component
      title="Module Form"
      v-on:header-icon-click="toggleModuleDetailsLock()"
      :header-icon="module_details_lock_icon"
      :icon="$tc('icons.modules')"
    >
      <form @submit.prevent="submit">
        <b-field label="Name" horizontal>
          <b-field>
            <b-input
              :disabled="module_details_lock"
              autocomplete="off"
              v-model="module.name"
              placeholder="Name"
              name="name"
              required
            />
          </b-field>
        </b-field>

        <b-field label="Code" horizontal>
          <b-field>
            <b-input
              :disabled="module_details_lock"
              autocomplete="off"
              v-model="module_code"
              placeholder="Code"
              name="code"
              required
            />
          </b-field>
        </b-field>
        <b-field label="NQF Level" horizontal>
          <b-field>
            <b-input
              :disabled="module_details_lock"
              required
              type="number"
              autocomplete="off"
              v-model="nqf_level"
              placeholder="NQF Level"
              name="nqf_level"
            />
          </b-field>
        </b-field>
        <b-field label="Level" horizontal>
          <b-field>
            <b-input
              :disabled="module_details_lock"
              required
              type="number"
              autocomplete="off"
              v-model="level"
              placeholder="Level"
              name="level"
            />
          </b-field>
        </b-field>
        <b-field label="Current LMS Code" horizontal>
          <b-field>
            <b-input
              :disabled="true"
              autocomplete="off"
              v-model="lms_code"
              placeholder="LMS Code"
              name="lms_code"
            />
          </b-field>
        </b-field>

        <b-field label="Credits" horizontal>
          <b-field>
            <b-input
              :disabled="module_details_lock"
              required
              type="number"
              autocomplete="off"
              v-model="credits"
              placeholder="Credits"
              name="credits"
            />
          </b-field>
        </b-field>
        <b-field label="Subject" horizontal>
          <b-field>
            <b-select
              :disabled="module_details_lock"
              required
              v-model="module.subject_id"
              placeholder="Select a Subject"
            >
              <option
                v-for="subject in subjects"
                :key="subject.id"
                :value="subject.id"
              >{{subject.name}}</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Standardised Level" horizontal>
          <b-field>
            <b-select required v-model="module.standardised_level_id" placeholder="Select a Level">
              <option
                v-for="level in $store.state.levels.levels"
                :key="level.id"
                :value="level.id"
              >{{ level.name }}
              </option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Semesters" horizontal>
          <b-dropdown
            :disabled="module_details_lock"
            v-model="selectedSemesters"
            multiple
            aria-role="list"
          >
            <button class="button is-primary" type="button" slot="trigger">
              <span>Select Semesters</span>

              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="semester in semesters"
              :key="semester.id"
              :value="semester.id"
              aria-role="listitem"
              @click="toggleSemester(semester.id)"
            >
              <span>{{semester.name}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <b-field label="Pass/Fail" horizontal>
          <b-checkbox
            size="is-medium"
            :disabled="module_details_lock"
            v-model="module.is_pass_fail"
            :true-value="1"
            :false-value="0">
          </b-checkbox>
        </b-field>
        <b-field
          horizontal
          v-if="can_fetch_grades">
          <b-button
            @click="startFetchGrade()"
            type="is-success"
            :loading="gradeFetchLoading"
          >Fetch Grades</b-button>
        </b-field>
        <b-field
          horizontal
          v-if="gradeFetchError">
            {{gradeFetchError}}
          </b-field>
        <b-field horizontal>
          <b-field >
            <div class="control is-full">
              <hr />
              <b-button
                native-type="submit"
                :disabled="module_details_lock"
                type="is-primary"
              >Submit</b-button>
            </div>
          </b-field>
        </b-field>
        <b-loading :is-full-page="false" :active.sync="updatingModule" :can-cancel="false"></b-loading>
      </form>
      <hr />
      <LMSCodeAdd
        :module="module"
        @refreshModule="initComponent"
        :currentSemester="currentSemester"
        :currentLMSCode="lms_code"
        :disableForm="module_details_lock"
      />
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import LMSCodeAdd from "@/components/modules/LMSCodeAdd";

import Module from "@/models/Module";
import Semester from "@/models/Semester";
import Lookup from "@/models/Lookup";
import Grade from "@/models/Grade";

export default {
  props: ["id"],
  components: {
    CardComponent,
    TitleBar,
    LMSCodeAdd
  },
  data() {
    return {
      updatingModule: false,
      subjectSearchTerm: "",
      selectedSubject: null,
      module_details_lock: true,
      module: {
        name: null,
        code: null,
        nqf: null,
        level: null,
        credits: null,
        subject_id: null,
        lms_codes: null,
        semesters: [],
        standardised_level_id:null,
        is_pass_fail: null,
      },
      gradeFetchError: null,
      gradeFetchLoading: false,
    };
  },
  computed: {
    selectedSemesters: {
      get() {
        let array = [];
        this.module.semesters.forEach((element) => {
          array.push(element.id);
        });
        return array;
      },
      set() {
        return;
      },
    },
    currentSemester(){
      return this.semesters.find((semester) => {
        // is today between start and end date
        const today = new Date()
        const start = new Date(semester.start_date)
        const end = new Date(semester.end_date)
        if(today >= start && today <= end){
          return semester
        }
      })
    },
    lms_code: {
      get() {
        if (!this.module.lms_codes || this.module.lms_codes.length == 0 || !this.currentSemester?.id) return null;
        let code = this.module.lms_codes.find(code => code.semester_id == this.currentSemester.id);
        return code ? code.lms_code : this.module.lms_codes[0].lms_code
      },
      set(newVal) {
        this.module.lms_code = newVal;
      },
    },
    credits: {
      get() {
        return parseInt(this.module.credits);
      },
      set(newVal) {
        this.module.credits = newVal;
      },
    },
    level: {
      get() {
        return parseInt(this.module.level);
      },
      set(newVal) {
        this.module.level = newVal;
      },
    },
    module_code: {
      get() {
        return this.module.code;
      },
      set(newVal) {
        this.module.code = newVal;
      },
    },
    nqf_level: {
      get() {
        return parseInt(this.module.nqf);
      },
      set(newVal) {
        this.module.nqf = newVal;
      },
    },
    subjects() {
      return Lookup.query().where("lookup_type_id", 6).get();
    },
    semesters() {
      return Semester.query().get();
    },
    can_edit_module() {
      return this.$store.getters["entities/permissions/find"]("edit modules");
    },
    can_fetch_grades() {
      return true;
    },
    module_details_lock_icon() {
      if (this.module_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
  },
  methods: {
    startFetchGrade() {
      this.gradeFetchLoading = true;
      this.gradeFetchError = null;
      Grade.sakaiFetchForSite(this.module.id).then((res) => {
        this.gradeFetchLoading = false;
        this.gradeFetchError = res.response.data;
      }).catch((e) => {
        this.gradeFetchLoading = false;
        this.gradeFetchError = e.response.data.errors.module[0];
      })
    },
    toggleSemester(id) {
      this.$store.dispatch("loader/show");
      Module.toggleSemester(this.module.id, id)
        .then(() => {
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    getFilteredSubject(text) {
      this.subjectSearchTerm = text;
    },
    toggleModuleDetailsLock() {
      if (this.can_edit_module) {
        this.module_details_lock = !this.module_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
    submit() {
      this.updatingModule = true;
      if(this.lms_code) this.module.lms_code = this.lms_code;
      Module.updateRemote(this.module)
        .then(() => {
          this.updatingModule = false;
          this.$buefy.snackbar.open({
            message: "Module Updated!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.updatingModule = false;
        });
    },
    initComponent() {
      this.$store.dispatch("loader/show");
      Module.deleteAll()
      Semester.deleteAll()
      Semester.FetchAll();
      Lookup.fetchAll()
        .then(() => {
          Module.fetchById(this.id).then(() => {
            this.module = Module.query()
              .whereId(this.id)
              .with("semesters")
              .get()[0];
            this.$store.dispatch("loader/hide");
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    },
  },
  mounted() {
    this.initComponent();
  },
};
</script>
