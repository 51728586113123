<template>
  <BoardIndex
    :board_title="title"

    :team_permission="permission"
    :board_id="14"

  />

</template>

<script>
import BoardIndex from "@/components/BoardIndex";

export default {
  name: "Dormant-board",
  components: {
    BoardIndex
  },
  data() {
    return {
      title: 'Dormant',
      permission: 'view dormant board',

    };
  },

};
</script>

