<template>
  <div>
    <card-component :title="title">
      <section v-if="can_create" class="section no-padding-left no-padding-right padding-top">
        <form @submit.prevent="submit">
          <b-field grouped>
            <b-field expanded label="Name">
              <b-input expanded required placeholder="Name" v-model="newLookup.name" />
            </b-field><b-field expanded label="CHE Lookup">
              <b-input expanded  placeholder="CHE Lookup" v-model="newLookup.che_lookup" />
            </b-field>
            <b-field class="has-text-centered" label="Submit">
              <b-button
                type="is-success"
                :icon-left="$tc('icons.create')"
                native-type="submit"
              >Submit</b-button>
            </b-field>
          </b-field>
        </form>
      </section>
    </card-component>
    <card-component class="export-table">
      <b-table
        ref="table"
        class="my-table"
        :data="offset_lookups"
        :striped="true"
        :hoverable="true"
        :bordered="true"
        @sort="onSort"
        :default-sort="['name', 'asc']"
      >
        <b-table-column v-slot="props" field="id" sortable label="Id">{{props.row.id}}</b-table-column>
        <b-table-column v-slot="props" field="name" sortable label="Name">
          <p v-if="edit_lookup!=props.row.id">{{props.row.name}}</p>
          <b-field v-if="edit_lookup==props.row.id" expanded>
            <b-input expanded required placeholder="Name" v-model="props.row.name" />
          </b-field>
        </b-table-column><b-table-column v-slot="props" field="che_lookup" sortable label="CHE Lookup">
        <p v-if="edit_lookup!=props.row.id">{{props.row.che_lookup}}</p>
        <b-field v-if="edit_lookup==props.row.id" expanded>
          <b-input expanded required placeholder="CHE Lookup" v-model="props.row.che_lookup" />
        </b-field>
      </b-table-column>
        <b-table-column v-slot="props" label="Actions" custom-key="actions">
          <b-field grouped>
            <b-field v-if="edit_lookup!=props.row.id && can_edit">
              <b-button
                @click="edit_lookup=props.row.id;row_hold=JSON.parse(JSON.stringify(props.row))"
                type="is-link"
              >Edit</b-button>
            </b-field>
            <b-field v-if="edit_lookup==props.row.id">
              <b-button @click="confirm_update(props.row)" type="is-success">Save</b-button>
            </b-field>
            <b-field v-if="edit_lookup==props.row.id">
              <b-button
                @click="props.row.name = row_hold.name;props.row.che_lookup = row_hold.che_lookup; edit_lookup=null;"
                type="is-danger"
              >Cancel</b-button>
            </b-field>
            <b-field v-if="can_delete">
              <b-button @click="start_delete(props.row.id)" type="is-danger">Delete</b-button>
            </b-field>
          </b-field>
        </b-table-column>
      </b-table>
      <b-pagination
        class="margin-top"
        :total="lookups.length"
        :current.sync="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
      ></b-pagination>
    </card-component>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import Lookup from "@/models/Lookup";
export default {
  name: "lookup",
  components: {
    CardComponent,
  },
  props: {
    title: { type: String, required: true },
    lookup_type_id: { type: Number, required: true },
    limit: {
      type: Number,
      default: () => {
        return 50;
      },
    },
  },
  data() {
    return {
      newLookup: { name: "", lookup_type_id: this.lookup_type_id,che_lookup:'' },
      page: 1,
      sortField: "name",
      sortOrder: "asc",
      row_hold: null,
      edit_lookup: null,
    };
  },
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
    confirm_update(lookup) {
      this.$store.dispatch("loader/show");
      Lookup.updateRemote(lookup)
        .then(() => {
          this.$store.dispatch("loader/hide");
          this.edit_lookup = null;
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete(lookup_id) {
      this.$buefy.dialog.confirm({
        title: "Deleting lookup",
        message:
          "Reporting data will be affected if relating entities have been tagged with this field. Are you sure you wish to continue?",
        confirmText: "Delete lookup",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.confirm_delete(lookup_id),
      });
    },
    confirm_delete(lookup_id) {
      this.$store.dispatch("loader/show");
      Lookup.deleteRemote(lookup_id)
        .then(() => {
          this.$store.dispatch("loader/hide");
          this.$buefy.snackbar.open({
            message: "Lookup deleted!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    submit() {
      this.$store.dispatch("loader/show");
      Lookup.create(this.newLookup)
        .then(() => {
          this.$store.dispatch("loader/hide");
          this.$buefy.snackbar.open({
            message: "New lookup created!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
  },
  computed: {
    can_delete() {
      return this.hasPermission("delete lookups");
    },
    can_create() {
      return this.hasPermission("create lookups");
    },
    can_edit() {
      return this.hasPermission("edit lookups");
    },
    lookups() {
      return Lookup.query().where("lookup_type_id", this.lookup_type_id).get();
    },
    offset_lookups() {
      return Lookup.query()
        .where("lookup_type_id", this.lookup_type_id)
        .orderBy(this.sortField, this.sortOrder)
        .offset((this.page - 1) * this.limit)
        .limit(this.limit)
        .get();
    },
  },
};
</script>


<style lang="scss">
</style>
