import Router from '@/router';
import Store from '@/store';
import Permission from "../models/Permission";

export default Router.beforeEach((to, from, next) => {
  let gatePermissions = to.meta.permissions != undefined ? to.meta.permissions : [];
  if (to.meta.allow === false) {
    if (gatePermissions.length > 0) {
      let allowed = false;
      let userPermission = Permission.find(gatePermissions[0])

      if (userPermission) {
        allowed = true

      }
      if (allowed !== true) {
        Store.dispatch('toast/createToast')

        next('/')



      }
      next()
    }
  }
  next()
});
