import {
  TransformModel
} from '@/models/TransformModel'


export default class EnquiryMadeByStageByUser extends TransformModel {
  static entity = 'EnquiriesMadeByStageByUser'
  static fields() {
    return {
      enquiries_count: this.attr(null),
      name: this.attr(''),
      user_name: this.attr(null)
    }
  }
  static fetchAll(start = null, end = null, user_type = 'creator', status_id = null, filter = true, year = null, semester = null) {
    return this.api().get(`/reports/enquiries-stage-by-user`, {
      params: {
        filter: filter,
        start: start,
        end: end,
        ...(user_type ? {
          user_type: user_type
        } : {}),
        ...(status_id ? {
          status_id: status_id
        } : {}),
        ...(year ? {
          year: year
        } : {}),
        ...(semester ? {
          semester: semester
        } : {})

      },

      dataTransformer: (response) => {


        return response.data.data.attributes.data

      }

    })
  }



}
