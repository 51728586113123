export const ProfileModule = {
  namespaced: true,

  state: {

    citizenship_types:
      [
        'RSA', 'Dual: RSA and other', 'Other'
      ],
    finance_types: [
      'Self-Financed',
      'Sponsor',
      'Bursary',
      'Student Loan',
      'Financial Aid',
      'Other: Please specify'
    ],school_types: [
      'Private',
      'Public',
      'Non-South African',
    ],saqa_statuses: [
      'Not applied',
      'Applied',
      'Submitted to CI',
    ],
    where_hear_options:['Current Cornerstone Student','Past Cornerstone Student','Cornerstone Staff Member','Cornerstone Presentation','Cornerstone Flyer','Cornerstone Advert (radio)','Internet (google search)','Social Media (Facebook, Twitter, etc.)'],
    rpl_statuses:[{name:'Pending',id:1},{name:'Declined',id:2},{name:'Approved',id:3}]
  }
};
