<template>
  <section class="section">
    <title-bar>My Activities</title-bar>

    <div class="columns">
      <div class="column is-narrow">
        <b-field grouped>
          <b-field>
            <b-radio-button v-model="query.is_complete" :native-value="false">
              <b-icon icon="minus"></b-icon>
              <span>Incomplete</span>
            </b-radio-button>

            <b-radio-button v-model="query.is_complete" :native-value="true">
              <b-icon icon="check"></b-icon>
              <span>Complete</span>
            </b-radio-button>
          </b-field>
          <b-field>
            <b-radio-button v-model="query.model_type" native-value="enquiry">
              <b-icon :icon="$tc('icons.enquiries')"></b-icon>
              <span>Enquiries</span>
            </b-radio-button>

            <b-radio-button v-model="query.model_type" native-value="registration">
              <b-icon :icon="$tc('icons.registrations')"></b-icon>
              <span>Registrations</span>
            </b-radio-button>
          </b-field>
          <b-field>
            <b-taglist class="margin-left">
              <b-tag
                v-if="query.is_complete!=null"
                closable
                type="is-info"
                @close="query.is_complete = null"
              >Status
              </b-tag>
              <b-tag
                v-if="query.model_type!=null"
                closable
                type="is-info"
                @close="query.model_type = null"
              >Type
              </b-tag>
            </b-taglist>
          </b-field>
        </b-field>
      </div>
      <!-- <div class="column is-half">
        <b-field label="Filter" horizontal>
          <b-input
            v-debounce:300ms="getFilteredCourse"
            placeholder="Type any parameter here"
            :loading="loadingFilter"
            v-model="filter"
          ></b-input>
        </b-field>
      </div>-->
    </div>
    <b-table
      ref="table"
      :bordered="true"
      :data="activities"
      :hoverable="true"
      :opened-detailed="defaultOpenedDetails"
      :show-detail-icon="false"
      :striped="true"
      class="margin-top"
      detail-key="id"
      detailed
      narrowed
    >
      <b-table-column v-slot="props" centered field="is_complete" label="Status" sortable width="20">
        <b-tooltip
          :label="props.row.is_complete!=1?'Mark as complete':'Mark as incomplete'"
          position="is-top"
        >
          <b-button
            :loading="loadingActivities.includes(props.row.id)"
            :type="{'is-success':props.row.is_complete==1}"
            class="rounded"
            size="is-small"
            @click.prevent="completeActivity(props.row.id)"
          >
            <b-icon icon="check" size="is-small"></b-icon>
          </b-button>
        </b-tooltip>
      </b-table-column>
      <b-table-column v-slot="props" centered field="type_id" label="Task" sortable width="20">
        <b-tooltip :label="type(props.row.type_id).name" position="is-top">
          <b-icon :icon="type(props.row.type_id).icon" class="icon" size="is-small"></b-icon>
        </b-tooltip>
      </b-table-column>

      <b-table-column v-slot="props" field="name" label="Name" sortable>
        <div v-if="props.row.name.length<20" v-html="clipDescription(props.row.name)"></div>
        <a
          v-else
          class="has-text-info"
          @click="toggle(props.row)"
          v-html="clipDescription(props.row.name)"
        ></a>
      </b-table-column>
      <b-table-column v-slot="props" field="note" label="Description">
        <div v-if="props.row.note==null"></div>
        <div v-else-if="props.row.note.length<20" v-html="clipDescription(props.row.note)"></div>
        <a
          v-else
          class="has-text-info"
          @click="toggle(props.row)"
          v-html="clipDescription(props.row.note)"
        ></a>
      </b-table-column>
      <b-table-column v-slot="props"
                      field="creator.first_name"
                      label="Creator"
                      sortable
      >{{ props.row.creator ? `${props.row.creator.first_name} ${props.row.creator.last_name}` : 'N/A' }}
      </b-table-column>
      <b-table-column v-slot="props"
                      field="created_at"
                      label="Created at"
                      sortable
      >{{ (new Date(props.row.created_at).toLocaleString()) }}
      </b-table-column>
      <b-table-column v-slot="props"
                      field="due_at"
                      label="Due at"
                      sortable
      >{{ props.row.due_at != null ? (new Date(props.row.due_at).toLocaleString()) : '' }}
      </b-table-column>

      <b-table-column v-slot="props" custom-key="actions" field="taskable_type" label="View" sortable>
        <b-button
          type="is-primary"
          @click="goToEntity(props.row)"
        >View {{ props.row.taskable_type == "App\\Registration" ? "Registration" : "Enquiry" }}
        </b-button>
      </b-table-column>
      <template slot="detail" slot-scope="props">
        <b-field label="Name">
          <div v-html="props.row.name"></div>
        </b-field>
        <b-field v-if="props.row.note !=null" label="Description">
          <div v-html="props.row.note"></div>
        </b-field>
      </template>
    </b-table>
    <b-pagination
      :current="page"
      :per-page="page_limit"
      :range-after="2"
      :range-before="2"
      :total="meta.total"
      aria-current-label="Current page"
      aria-next-label="Next page"
      aria-page-label="Page"
      aria-previous-label="Previous page"
      class="margin-top"
      v-on:change="setPage"
    ></b-pagination>
    <b-loading :active="loadingData" :is-full-page="false"></b-loading>
  </section>
</template>

<script>
import Activity from "@/models/Activity";
import TitleBar from "@/components/TitleBar";
import clip from "text-clipper";
import Course from "@/models/Course";

export default {
  name: "Activities.Index",
  components: {TitleBar},
  data() {
    return {
      query: {is_complete: null, model_type: null, order_by: 'created_at', order_direction: 'desc'},
      defaultOpenedDetails: [],
      loadingActivities: [],
      loadingFilter: false,
      loadingData: false,
      taskDataHold: null,
      courses: [],
      meta: Object,
      page: 1,
      page_limit: 15,
      activity_types: [
        {id: 1, name: "Call", icon: "phone"},
        {id: 2, name: "Email", icon: "email"},
        {id: 3, name: "Task", icon: "clock"},
      ],
    };
  },
  watch: {
    filter() {
      this.setPage(1);
    },
  },
  methods: {
    toggle(row) {
      this.$refs.table.toggleDetails(row);
    },
    goToEntity(activity) {
      if (activity.taskable_type == "App\\Registration") {
        this.$router.push(`/registrations/edit/${activity.taskable_id}`);
      }
      if (activity.taskable_type == "App\\Enquiry") {
        this.$router.push(`/enquiries/edit/${activity.taskable_id}`);
      }
    },
    type(type_id) {
      return this.activity_types.find((type) => type.id == type_id);
    },
    clipDescription(text) {
      return clip(text, 20, {
        html: true,
      });
    },
    completeActivity(id) {
      this.loadingActivities.push(id);
      Activity.toggleComplete("registrations", 26, id).then(() => {
        this.loadingActivities = this.loadingActivities.filter(
          (current_id) => current_id != id
        );
      });
    },

    getFilteredCourse(text) {
      this.loadingFilter = true;

      Course.search(text, true).then((result) => {
        this.loadingFilter = false;

        this.courses = result.response.data.data;

        this.meta = result.response.data.meta;
      });
    },
    setPage(pageNumber) {

      this.loadingData = true;
      this.taskDataHold = this.activities;
      Activity.deleteAll();
      this.page = pageNumber;
      return Activity.fetchWithoutModel(this.filter, this.page, this.page_limit).then((result) => {
        this.meta = result.response.data.meta;
        this.$store.dispatch("loader/hide");
        this.loadingData = false;
        this.taskDataHold = null;
      });


    },

    getActivities(query, page = null, limit = null) {
      this.$store.dispatch("loader/show");
      Activity.deleteAll();
      return Activity.fetchWithoutModel(query, page, limit).then((result) => {
        this.meta = result.response.data.meta;
        this.$store.dispatch("loader/hide");
      });
    },
  },

  computed: {
    filter() {
      return {...{assigned_to_user_id: this.userId}, ...this.query};
    },
    userId() {
      return parseInt(this.$store.state.userId);
    },
    activities() {
      if (this.taskDataHold !== null) {
        return this.taskDataHold
      }
      return Activity.query()
        .where("assigned_to_user_id", this.userId)
        .withAll()
        .orderBy("created_at", "desc")
        .get();
    },
    can_delete_courses() {
      return this.$store.getters["entities/permissions/find"]("delete courses");
    },
  },
  mounted() {
    this.setPage(1)
  },
};
</script>
