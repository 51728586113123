<template>

  <div class="has-background-white box is-relative card box">
    <title-bar class="card-header"><p class="card-header-title">Pending Verification</p></title-bar>
    <div class="card-content">

      <p class="is-size-5 pb-2">Your application will now be vetted against the rules of the qualification automatically. If the information you have entered complies with the rules of the programme, you will be provisionally admitted. Once manual checking of your uploaded documents is complete, your registration will be confirmed.</p>
      <p class="is-size-5">
        You will receive an email once the verification process is complete.
      </p>


    </div>

    <footer class="card-footer">
      <div class="card-footer-item">
        <b-field grouped>

        </b-field>
      </div>
    </footer>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>

  </div>
</template>

<script>
import TitleBar from "@/components/TitleBar";

export default {
  name: 'RegistrationPendingPanel',
  components: {
    TitleBar,
  },
  props: {
  learner_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      readDeclaration: false,
      loading: false,
      loadingModules: false,
      loadingModuleAdd: false,
      previouslySelectedModules: [],
      selectedModule: null,
      availableModules: [],
      supplementaryModules: [],
      selectedModuleIds: [],
      loadingLearners: false,
      selectedModules: [],
      isSubmitModalActive: false,
    };
  },

  async created() {
  },
};
</script>
