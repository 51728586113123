import {
  TransformModel
} from '@/models/TransformModel'


export default class CourseInterest extends TransformModel {
  static entity = 'CoursesInterest'
  static primaryKey = 'course_name'

  static fields() {
    return {
      enquiries_created: this.attr(null),
      course_name: this.attr(null)
    }
  }

  static fetchAll(start = null, end = null, filter = true, year = null, semester = null) {
    return this.api().get(`/reports/course-interest`, {
      params: {
        filter: filter,
        start: start,
        end: end,
        ...(year ? {
          year: year
        } : {}),
        ...(semester ? {
          semester: semester
        } : {})
      },

      dataTransformer: (response) => {
        response.data.data.attributes.data.push({
          enquiries_created: response.data.data.attributes.data.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.enquiries_created
          }, 0),
          course_name: 'Total',
          $id: 'Total'
        })
        return response.data.data.attributes.data


      }
    })
  }




}
