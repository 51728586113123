import {
  Model
} from '@vuex-orm/core'

export default class semesterModule extends Model {
  static entity = 'semesterModule'

  static primaryKey = ['semester_id', 'module_id']


  static fields() {
    return {
      module_id: this.attr(null),
      semester_id: this.attr(null)
    }
  }


}
