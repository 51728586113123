import {
  TransformModel
} from '@/models/TransformModel'
import Profile from '@/models/Profile'
import Course from "@/models/Course";

export default class Learner extends TransformModel {
  static entity = 'learners'

  static fields() {
    return {
      created_at: this.attr(null),
      type: this.attr('users'),
      lms_id:this.attr(null),
      lms_username:this.attr(null),
      id: this.attr(null),
      title: this.attr(''),
      first_name: this.attr(''),
      middle_name: this.attr(''),
      last_name: this.attr(''),
      username: this.attr(''),
      email_password: this.attr(''),
      email: this.attr(''),
      course_ids:this.attr(() => []),
      courses:this.hasManyBy(Course,'course_ids'),
      profile: this.hasOne(Profile, 'user_id'),
      primary_contact_number: this.attr(''),
      secondary_contact_number: this.attr(''),
      cellphone_number: this.attr(''),
      token_url: this.attr(null),
      student_number: this.attr(null),
      registrations_count:this.attr(0),
      student_level:this.attr(0),
      is_suspended: this.attr(0),
    }
  }
  static newLearner(learner) {
    return this.api().post(`/learners`, learner, {
      dataTransformer: (response) => {

        let storageObject = response.data.data
        Object.assign(storageObject, storageObject.attributes)

        return storageObject
      }
    })
  }
  static updateRemote(learner) {
    return this.api().patch(`/learners/${learner.id}`, learner, {
      save: false
    })
  }
    static changePassword(learner_id,password,password_confirmation) {
      return this.api().put(`/learners/${learner_id}/password`, {password:password,password_confirmation:password_confirmation}, {
        save: false
      })
    }
  static fetchById(id) {
    return this.api().get(`/learners/${id}?with[]=profile`, {
      dataTransformer: (response) => {

        let target = response.data.data
        Object.assign(target, target.attributes)
        if (target.profile) {
          Object.assign(target.profile, target.profile.attributes)
          if (target.profile.finance !==null) {
            target.profile.finance = parseInt(target.profile.finance)
          }
        }
        return target
      }

    })
  }
  static fetchAll(page = 1, limit = 999) {
    return this.api().get(`/learners?with_count[]=registrations&with[]=profile&page=${page}&limit=${limit}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }
  static fetchAllForBulk(page = 1, limit = 999, query = {}) {
    return this.api().get(`/learners?page=${page}&limit=${limit}&with_count=registrations`, {
      params: {
        ...(query.registration_status ? {
          registration_status: query.registration_status
        } : {}),
        ...(query.registration_stage ? {
          registration_stage: query.registration_stage
        } : {}),
        ...(query.registration_course ? {
          registration_course: query.registration_course
        } : {}),
        ...(query.registration_year ? {
          registration_year: query.registration_year
        } : {}),
        ...(query.registration_semester ? {
          registration_semester: query.registration_semester
        } : {}),
        ...(query.enquiry_made_status ? {
          enquiry_made_status: query.enquiry_made_status
        } : {}),
        ...(query.enquiry_made_stage ? {
          enquiry_made_stage: query.enquiry_made_stage
        } : {}),
        ...(query.enquiry_made_course ? {
          enquiry_made_course: query.enquiry_made_course
        } : {}),
        ...(query.enquiry_made_year ? {
          enquiry_made_year: query.enquiry_made_year
        } : {}),
        ...(query.enquiry_made_semester ? {
          enquiry_made_semester: query.enquiry_made_semester
        } : {})
      },
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }
  static search(data, saved, page = 1, limit = 100) {
    return this.api().get(`/learners?search=${data}&limit=${limit}&page=${page}&with_count[]=registrations`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      save: saved
    })
  }

  static deleteRemote(learner_id) {
    return this.api().delete(`/learners/${learner_id}`, {
      delete: learner_id
    })
  }
  static checkProfileComplete() {
    return this.api().get(`/user/check-profile-complete`, {
      saved:false,
      save:false
    })
  }

}
