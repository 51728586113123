import {
  TransformModel
} from '@/models/TransformModel'

export default class Stage extends TransformModel {
  static entity = 'stages'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(''),
      order: this.attr(null)
    }
  }

  static fetchAll() {
    return this.api().get(`/stages`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }
  static fetchById(id) {
    return this.api().get(`/stages/${id}`)
  }

}
