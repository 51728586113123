import {
  ApiClientFactory
} from '@/ApiClientFactory';

let factory = new ApiClientFactory();

export const AuthClient = {

  /**
   * Logs a user in.
   *
   * param {data}
   */
  login(data) {
    return factory.post('oauth/token', data);
  },
  checkIfExists(data) {
    return factory.post('api/1/public/check-user-exists', data)
  },
  /**
   * Registers a new learner
   * @function
   * @param {Object} data
   * @param {string} data.first_name - First Name
   * @param {string} data.middle_name - Middle Name(s)
   * @param {string} data.last_name - Last Name
   * @param {string} data.email - Email
   * @param {string} data.password - Password
   * @param {string} data.id_number - ID Number
   * @param {number} data.identification_type_id - Type of the ID based off lookup
   * @returns {Promise<Response>}
   */
  register(data) {
    return factory.post('api/1/public/register', data);
  },
  reset(username) {
    return factory.post('api/1/password/create',
      username
    );
  },
  setPassword(data) {
    return factory.post('api/1/password/reset',
      data
    )
  },

  /**
   * Returns user if authenticated.
   */
  check(headers = {}) {
    return factory.get('/api/1/user?with[]=permissions', {}, headers);
  }

};
