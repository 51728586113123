<template>
  <div>
    <b-loading :active.sync="loading" :can-cancel="false"></b-loading>
    <DisabilitiesForm
        @hasDisabilitySelected="hasDisabilitySelected"
        :disability_indicated.sync="disability_indicated"
        :show-uploads="showUploads"
        :disabled="disabled"
        :use-in-parent="useInParent"
        :display-only-indicated="displayOnlyIndicated"
        :profile="profile"
        v-model="copy"  />
    <b-button v-if="!useInParent" @click="submitDisabilities" :disabled="disabled" type="is-primary">
      Save
    </b-button>
  </div>
</template>

<script>
import isEqual from "lodash/isEqual";
import DisabilitiesForm from "@/components/DisabilitiesForm.vue";
import Profile from "@/models/Profile";
import Media from "@/models/Media";
import {provide, ref} from "vue";

export default {
  name: "DisabilitiesComponent",
  setup() {
    const loading = ref(false)
    provide("loading", loading)
    return {
      loading
    }
  },
  components: {DisabilitiesForm},
  props: {
    showUploads: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    useInParent: {
      type: Boolean,
      default: false,
    },
    displayOnlyIndicated: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      copy: {...this.value},
      disability_indicated: false,
    }
  },
  computed: {
    isModeled() {
      return !!this.value;
    },
    profile() {
      return Profile.query().first();
    },
  },
  mounted() {
    if(!this.isModeled) {
      this.copy = {
        disability_ids: this.profile.disability_ids ?? [],
        accommodation_ids: this.profile.accommodation_ids ?? []
      }
    }
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;
        this.copy = newVal;
      },
    },
    copy: {
      handler(newVal, oldVal) {
        if (!this.isModeled) return;
        if (isEqual(newVal, oldVal)) return;
        this.$emit("input", newVal);
      },
    },
  },
  methods: {
    hasDisabilitySelected(bool) {
      this.disability_indicated = bool
      this.$emit("hasDisabilitySelected", bool)
    },
    hasDisabilityOptionsSelected() {
      return this.copy?.accommodation_ids?.length > 0 && this.copy?.disability_ids?.length > 0
    },
    submitDisabilities() {
      this.loading = true
      let submitObject = {...this.copy};
      if(this.disability_indicated && !this.hasDisabilityOptionsSelected()){
        this.$store.dispatch('toast/createToast', {message: 'Please make sure all disability options are selected'})
        this.loading = false
        return
      }
      if (this.disability_indicated && !Media.query().where("tag", 'Disability and/or mental health challenge statement').exists()){
        this.$store.dispatch('toast/createToast', {message: 'Please make sure all supported disability documentation is uploaded'})
        this.loading = false
        return
      }
      Profile.updateRemote(submitObject, this.profile.user_id, true)
        .then(() => {
          this.$buefy.snackbar.open(`Disabilities Submitted`);
          this.$emit("update-disabilities");
          this.loading = false;
        })
        .catch(err => {
          this.handleError(err);
          this.loading = false;
        });
    },
  },
  emits: ["input"],
}
</script>


