<template>
  <section class="section">
    <title-bar>Create a new User</title-bar>
    <card-component icon="ballot" title="User Form">
      <form @submit.prevent="submit">
        <b-field horizontal label="First Name">
          <b-field>
            <b-input
              v-model="user.first_name"
              autocomplete="off"
              icon="account"
              name="first_name"
              placeholder="First Name"
              required
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Last Name">
          <b-field>
            <b-input
              v-model="user.last_name"
              autocomplete="off"
              icon="account"
              name="last_name"
              placeholder="Last Name"
              required
            />
          </b-field>
        </b-field>    <b-field label="Middle Name(s)" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              icon="account"
              v-model="user.middle_name"
              placeholder="Middle Name(s)"
              name="middle_name"
            />
          </b-field>
        </b-field>
        <b-field horizontal label="ID Number">
          <b-field>
            <b-input
              v-model="user.id_number"
              autocomplete="off"
              icon="account"
              name="id_number"
              placeholder="ID Number"
              required
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Email Address">
          <b-field>
            <b-input
              v-model="user.email"
              autocomplete="off"
              icon="email"
              name="email"
              placeholder="E-mail"
              required
              type="email"
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Send email from address">
          <b-field>
            <b-input
              v-model="user.username"
              autocomplete="off"
              icon="email"
              name="send_address"
              placeholder="E-mail"
              required
              type="email"
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Primary Contact Number">
          <b-field>
            <b-input
              v-model="user.primary_contact_number"
              autocomplete="off"
              expanded
              icon="phone"
              name="phone"
              placeholder="072 555 5555"
              type="tel"
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Secondary Contact Number">
          <b-field>
            <b-input
              v-model="user.secondary_contact_number"
              autocomplete="off"
              expanded
              icon="phone"
              name="phone"
              placeholder="072 555 5555"
              type="tel"
            />
          </b-field>
        </b-field>
        <b-field :label="$tc('Cellphone',1)+' Number'" horizontal>
          <b-field>
            <b-input
              v-model="user.cellphone_number"
              autocomplete="off"
              expanded
              icon="phone"
              name="phone"
              placeholder="072 555 5555"
              type="tel"
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Personal title">
          <b-select v-model="user.title" placeholder="Select a title">
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Ms">Ms</option>
            <option value="Dr">Dr</option>
          </b-select>
        </b-field>
        <b-field horizontal label="Roles">
          <b-dropdown v-model="user.roles" aria-role="list" multiple scrollable>
            <button slot="trigger" class="button is-primary" type="button">
              <span>Select Roles</span>

              <b-icon icon="menu-down"></b-icon>
            </button>
            <b-dropdown-item :value="null" @click="user.roles=[]">
              <span>none</span>
            </b-dropdown-item>
            <b-dropdown-item
              v-for="(role,index) in roles"
              v-show="hasPermissionToAssign(role.name)"
              :key="index"
              :value="role.name"
              aria-role="listitem"
            >
              <span>{{ role.name }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>

        <ValidationObserver ref="observer">
          <b-field horizontal label="Password">
            <ValidationProvider v-slot="{ errors }" rules="required|min|password:@confirm">
              <b-input
                v-model="user.password"
                name="password"
                placeholder="password"
                type="password"
              />
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>

          <b-field horizontal label="Confirm password">
            <ValidationProvider v-slot="{ errors }" name="confirm" rules="required">
              <b-input v-model="confirm_password" placeholder="confirm password" type="password"/>
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </ValidationObserver>
        <hr/>

        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Role from "@/models/Role";

import User from "@/models/User";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});

export default {
  components: {
    CardComponent,
    TitleBar,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      confirm_password: null,

      user: {
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        id_number: null,
        primary_contact_number: "",
        secondary_contact_number: "",
        cellphone_number: "",
        password: null,
        join_by_id_number: false,
        username: "",
        roles: [],
      },
    };
  },
  computed: {
    roles() {
      return Role.all();
    },
  },
  methods: {
    hasPermissionToAssign(role) {
      if (this.$store.getters["entities/permissions/find"]("assign any role")) {
        return true;
      }
      return this.$store.getters["entities/permissions/find"](
        "assign " + role + " role"
      );
    },
    submit() {
      User.newUser(this.user)
        .then((result) => {
          setTimeout(() => {
            this.$router.push(`/users/edit/${result.response.data.data.id}`);
          }, 1000);
          this.$buefy.snackbar.open({
            message: this.user.join_by_id_number?"Staff Permissions Added":"User Created!",
            type: "is-link",

            queue: false,
          });
        })
        .catch((err) => {
          if (err.response.data.hasOwnProperty('errors')) {
            if (err.response.data.errors.hasOwnProperty('id_number')) {
              this.$buefy.dialog.confirm({
                title: "Link User",
                message:
                  `<p>${err.response.data.errors.id_number[0]}. Would you like to you like to make them a staff member?</p>
                    <p>Note: none of the information you've entered here will be persisted. Please update the user from the following screen.</p>
                  `,
                confirmText: "Link",
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => {
                  this.user.join_by_id_number = true
                  this.submit()
                },
              });
            }
          } else {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          }
        });
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Role.fetchAll().then(() => {
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
