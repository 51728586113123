<template>
  <div id="reports">
    <div class="columns min-height-report">
      <div class="column is-one-fifth reports-list has-background-white">
        <div>
          <h2 class="has-text-weight-bold margin-left margin-top">Reports</h2>
        </div>
        <hr />
        <b-collapse
          class="card"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">{{ collapse.title }}</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
            </a>
          </div>
          <div
            v-show="hasPermission(report.permission)"
            v-for="report in collapse.reports"
            :key="report.title"
            class="card-content"
          >
            <div class="content">
              <a @click="$router.push('/reports/'+report.to)">{{ report.title }}</a>
            </div>
          </div>
        </b-collapse>
      </div>

      <router-view class="column is-four-fifth" />
    </div>
  </div>
</template>


<script>
export default {
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Enquiries",
          reports: [
            {
              title: "Enquries made",
              to: "enquiries-made",
              permission: "view enquiries made report"
            },
            {
              title: "Enquiries won",
              to: "enquiries-won",
              permission: "view enquiries won report"
            },
            {
              title: "Enquiries lost",
              to: "enquiries-lost",
              permission: "view enquiries lost report"
            },
            {
              title: "Enquiry sources",
              to: "enquiries-sources",
              permission: "view enquiries sources report"
            },
            {
              title: "Enquiry stages",
              to: "enquiries-stages",
              permission: "view enquiries stages report"
            },
            {
              title: this.$tc("Course", 1) + " interest",
              to: "course-interest",
              permission: "view course interest report"
            }
          ]
        },
        {
          title: "Registrations",
          reports: [
            {
              title: "Registrations made",
              to: "registrations-made",
              permission: "view registrations made report"
            },
            {
              title: "Registrations by " + this.$tc("course", 1),
              to: "registrations-courses",
              permission: "view registrations courses report"
            },
            {
              title: "Registrations progress",
              to: "registrations-stages",
              permission: "view registrations stages report"
            },
            {
              title: "Registration sources",
              to: "registrations-sources",
              permission: "view registrations sources report"
            },
          ]
        }
      ]
    };
  },
  computed: {},
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    }
  }
};
</script>

<style lang="scss" >
.min-height-report {
  min-height: 100vh;
}
.reports-list {
  .card:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
#reports {
  max-width: 85vw;
}
canvas {
  height: 500px !important;
}
</style>
