<template>
  <div>
    <b-dropdown v-model="options" aria-role="list" multiple position="is-top-right" scrollable>
      <button
        slot="trigger"
        :disabled="disabled"
        class="button is-primary"
        type="button"
      >
        <span v-if="!options?.length">Select {{placeholder}}</span>
        <div v-for="(option,index) in options" :key="option.id">
          <p>
            <span>{{ optionsDisplay[option].name }}</span>
            <span v-if="index<options?.length-1">,&nbsp;</span>
          </p>
        </div>
        <b-icon icon="menu-down"></b-icon>
      </button>

      <b-dropdown-item
        v-for="(lookup) in lookupData"
        :key="lookup.id"
        :value="lookup.id"
        aria-role="listitem"
      >
        <span>{{ lookup.name }}</span>
      </b-dropdown-item>

    </b-dropdown>
  </div>
</template>

<script>
import Lookup from "@/models/Lookup";

//todo - use like <LookupMultiSelect :placeholder="'x'" :lookupId="y"/>
export default {
  name: "LookupMultiSelect",
  components: {},
  props: {
    value: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    placeholder: {
      type: String,
      default() {
        return "Options"
      },
    },
    lookupId: {
      type: Number,
      default() {
        return null;
      },
    },
  },
  methods: {
    buildOptionsDisplay(options) {
      this.optionsDisplay = options?.reduce((acc, optionId) => {
        acc[optionId] = this.lookupData.find(data => data.id === optionId)
        return acc
      }, {})
    }
  },
  data() {
    return {
      options: [],
      optionsDisplay: {}
    }
  },
  watch: {
    options(newVal) {
      this.$emit("input", newVal);
      this.buildOptionsDisplay(newVal)
    },
  },
  computed: {
    lookupData() {
      return Lookup.query().where("lookup_type_id", this.lookupId).get();
    },
  },
  mounted() {
    this.options = this.value
    this.buildOptionsDisplay(this.options)
  }
}
</script>
