<template>

  <form :class="{'section':!inModal}" class="is-relative" @submit.prevent="submit">
    <div :class="{'modal-card':inModal,'card':!inModal}">
      <header v-if="inModal" class="modal-card-head">
        <p class="card-title">{{ edit === false ? 'Create a new' : '' }} Invoice</p>
      </header>
      <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">
        {{ edit === false ? 'Create a new' : '' }} Invoice</p></title-bar>

      <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
        <b-field label="User">
          {{ invoiceObject.user.first_name }} {{ invoiceObject.user.last_name }}
        </b-field>

        <!--        <template v-if="edit">-->
        <!--          <b-field label="Invoice Type">-->
        <!--            {{ invoiceObject.readable_type }}-->
        <!--          </b-field>-->
        <!--          <div v-if="invoiceObject.readable_type==='Registration'">-->
        <!--            <b-field label="Course Code">-->
        <!--              {{ registration.course.code }}-->
        <!--            </b-field>-->
        <!--            <b-field label="Course">-->
        <!--              {{ registration.course.name }}-->
        <!--            </b-field>-->
        <!--          </div>-->
        <!--        </template>-->
        <template v-if="admin">
          <b-field label="Paying via EFT?">
            <b-switch v-model="invoiceObject.paying_via_eft" :true-value="1" :false-value="0">{{ invoiceObject.paying_via_eft ? 'Yes' : 'No' }}</b-switch>
          </b-field>
          <b-field label="Is Paid?">
            <b-switch :disabled="!canEdit || (invoiceObject.paying_via_eft!==1)" v-model="invoiceObject.is_paid" :true-value="1" :false-value="0">{{ invoiceObject.is_paid ? 'Yes' : 'No' }}</b-switch>
          </b-field>


          <b-field v-if="admin?invoiceObject.paying_via_eft===1:(invoiceObject.paying_via_eft===1&&method_confirmed)">
            EFT copy
          </b-field>
          <b-field v-if="invoice.needs_manual_verification===1" label="Manual Verification">
            <b-checkbox required :true-value="0" :false-value="1" v-model="invoiceObject.needs_manual_verification" >Payment provider has indicated that the transaction requires manual verification. Tick the box to confirm this has been done.</b-checkbox>
          </b-field>
        </template>
        <label class="label">Invoice Items</label>
        <div class="core-option-list-item p-1" style="border-radius:5px" v-for="item in invoiceItems" :key="item.id+'invoice'">
          <b-field label="Description">
            {{ item.description }}
          </b-field>
          <b-field label="Total">
            {{ item.total }}
          </b-field>

        </div>
        <label class="label">Payment Items</label>
        <div class="core-option-list-item p-1" style="border-radius:5px" v-for="item in paymentItems" :key="item.id">
          <b-field label="Message">
            <b-input v-model="item.message" required type="textarea" :disabled="!admin"/>
          </b-field>
          <b-field label="Payment Provider">
            <b-select :disabled="!admin" required v-model="item.payment_provider">
              <option value="Peach">Peach</option>
              <option value="EFT">EFT</option>
            </b-select>
          </b-field>
          <b-field label="Total">
            <b-input :min="0" :max="invoiceObject.total" v-model="item.total" type="number" required :disabled="!admin"></b-input>
          </b-field>

        </div>
        <div class="core-option-list-item p-1" style="border-radius:5px" v-for="(item,index) in newPaymentItems" :key="index + 'new'">
          <b-field label="Message">
            <b-input v-model="item.message" required type="textarea" :disabled="!admin"/>
          </b-field>
          <b-field label="Payment Provider">
            <b-select :disabled="!admin" required v-model="item.payment_provider">
              <option value="Peach">Peach</option>
              <option value="EFT">EFT</option>
            </b-select>
          </b-field>
          <b-field label="Total">
            <b-input :min="0" :max="invoiceObject.total" v-model="item.total" type="number" required :disabled="!admin"></b-input>
          </b-field>
          <b-field label="Remove">
            <b-button @click="remove(index)" type="is-danger">
              Remove
            </b-button>
          </b-field>
        </div>
        <template v-if="admin">
          <b-field label="Add Payment">
            <b-button @click="addPayment" type="is-success">Add</b-button>
          </b-field>
        </template>

        <template v-if="!admin">
          <div v-if="!invoice.is_paid===1"><p class="title is-5 pt-2">Pay in invoice panel copy copy</p>

            <div v-if="!method_confirmed">
              <b-field label="Payment Method">
                <b-field>
                  <b-radio v-model="payment_method" :native-value="0"
                           name="payment_method">
                    Pay via Peach Payments
                  </b-radio>
                </b-field>
                <b-field>
                  <b-radio v-model="payment_method" :native-value="1"
                           name="payment_method"
                  >
                    Pay via EFT
                  </b-radio>
                </b-field>
              </b-field>
            </div>
            <div v-if="method_confirmed">
              <div v-if="checkoutId!==null && invoice.paying_via_eft===0">
                <script :src="`https://eu-test.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`" type="application/javascript"></script>

                <form ref="paymentForm" action="http://localhost:8080/registrations/register" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
              </div>
              <div v-if="invoice.paying_via_eft===1">
                <p>EFT copy</p>
              </div>
              <b-field>
                <b-button type="is-primary" @click="method_confirmed=false;checkoutId=null">Choose a different method</b-button>
              </b-field>
            </div>

          </div>
<!--          <div v-else>-->
<!--            <p>Success copy</p>-->

<!--          </div>-->
        </template>


      </section>
      <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
        <div class="card-footer-item">
          <b-field grouped>
            <p class="control">
              <b-button v-if="admin" :disabled="edit?!canEdit:!canCreate" :loading="loading" native-type="submit"
                        type="is-primary">Save
              </b-button>
            </p>
            <p v-if="!admin" class="control">
<!--              <b-button :disabled="invoice.is_paid===1" :loading="loading" @click="selectPaymentMethod"-->
<!--                        type="is-primary">Choose payment method-->
<!--              </b-button>-->
            </p>
            <p v-if="edit && canDelete" class="control">
              <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                Delete
              </b-button>
            </p>
          </b-field>
        </div>
      </footer>
    </div>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </form>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import Course from "@/models/Course";
import Invoice from "@/models/Invoice";
import Registration from "@/models/Registration";
import InvoiceItem from "@/models/InvoiceItem";
import InvoicePayment from "@/models/InvoicePayment";

export default {
  name: "InvoiceForm",
  components: {
    TitleBar,

  },
  data() {
    return {
      loading: false,
      loaded: false,
      invoiceObject: {},
      method_confirmed: false,
      checkoutId: null,
      payment_tripped: false,
      newPaymentItems: []
    }
  },
  methods: {
    remove(index) {
      this.newPaymentItems = this.newPaymentItems.filter((item, itemIndex) => {
        return itemIndex !== index
      })
    },
    addPayment() {

      this.newPaymentItems.push({
        is_new: true,
        invoice_id: this.invoice.id,
        total: null,
        message: null,
        payment_provider: null
      })

    },
    async selectPaymentMethod() {
      this.loading = true
      if (this.invoice.paying_via_eft === 1) {
        if (!this.invoice.is_paid === 1) {
          Invoice.Update(this.invoiceObject).then(() => {
            this.method_confirmed = true
            this.loading = false
          })
        }
      }
      if (this.invoice.paying_via_eft === 0) {
        if (!this.invoice.is_paid === 1) {
          await Invoice.Update(this.invoiceObject)
          await this.generateCheckout().then(() => {
            this.method_confirmed = true
          }).catch(err => {
            this.handleError(err)
            //TODO handle already paid
          })
          this.loading = false

        }
      }
    },
    generateCheckout() {
      return Invoice.GenerateCheckout(this.invoice.id, this.invoice_type).then((response) => {
        this.checkoutId = response.response.data.checkout_id
      })
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Invoice`,
        confirmText: `Delete Option`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this invoice?`,
        onConfirm: () => {

        }
      })
    },

    delete() {
      this.loading = true
      Course.Delete(this.courseObject.id).then(() => {
        this.$buefy.snackbar.open(`${this.$tc('Course', 1)} deleted!`);
        this.$emit("close");
        this.$emit("deleted");

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
        if (!this.inModal) {
          this.$router.push('/courses')
        }
      });

    },
    async submit() {
      this.loading = true
      if (this.edit) {
        let Stores = []
        let Updates = []
        InvoicePayment.query().where('is_new', false).where('invoice_id', this.invoice.id).get().map(item => {
          Updates.push(InvoicePayment.Update(item))
        })
        this.newPaymentItems.map(item => {
          Stores.push(InvoicePayment.Store(item))
        })

        await Promise.all([Invoice.Update(this.invoiceObject), ...Stores, ...Updates])
        this.loading = false
        this.$emit('close')
      }
    }
  },

  computed: {
    payment_method: {
      get() {
        if (this.invoiceObject !== null) {
          if (this.invoiceObject.paying_via_eft === 1) {
            return 1
          }
          if (!this.payment_tripped) {
            return null
          }
          return this.invoiceObject.paying_via_eft
        } else {
          return null
        }
      }, set(value) {
        this.invoiceObject.paying_via_eft = value
        this.payment_tripped = true
      }
    },
    invoiceItems() {
      return InvoiceItem.query().where('invoice_id', this.invoice.id).get()
    }, paymentItems() {
      return InvoicePayment.query().where('invoice_id', this.invoice.id).get()
    },
    registration() {
      if (this.edit) {
        if (this.invoiceObject.readable_type === 'Registration') {
          return Registration.query().whereId(this.invoice.invoiceable_id.toString()).withAll().first()
        }
      }
      return null

    }
  },
  async created() {

    this.loading = true
    // await Invoice.FetchById(this.invoice.id, ['invoiceItems', 'invoicePayments', 'invoiceable'])
    if (this.edit) {
      this.invoiceObject = JSON.parse(JSON.stringify(Invoice.query().whereId(this.invoice.id).withAll().first()))
      this.invoiceObject.readable_type = this.invoice.readable_type
      // if (this.invoiceObject.readable_type==='Registration') {
      // await Registration.fetchById(this.invoiceObject.invoiceable_id,['course'])
      // }
      this.loading = false

    } else {
      this.loading = false
    }
  },
  mounted() {
    if (this.inModal) {
      this.makeModalLarge()
    }
  },
  props: {
    invoice: {
      type: Object,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, admin: {
      type: Boolean, default() {
        return false
      }
    },
    canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

