import {
  TransformModel
} from '@/models/TransformModel'
import Media from '@/models/Media'


export default class Email extends TransformModel {
  static entity = 'emails'

  static fields() {
    return {
      type: this.attr('emails'),
      id: this.attr(null),
      body: this.attr(null),
      text: this.attr(null),
      //   recipients: this.belongsToMany(User, UserEmail, 'email_id', 'user_id'),
      created_at: this.attr(null),
      from_email: this.attr(null),
      from_name: this.attr(null),
      is_read: this.attr(null),
      media: this.morphMany(Media, 'mediable_id', 'mediable_type'),
      from_user_id: this.attr(null),
      subject: this.attr(null),
      to_email: this.attr(null),
      to_user_id: this.attr(null),
      updated_at: this.attr(null),
      was_received: this.attr(null),
      is_html:this.attr(null)
    }
  }

  static send(email) {
    return this.api().post(`/emails`, email, {
      save: false
    })
  }

  static bulkSend(email, query) {
    return this.api().post(`/bulk-send`, email, {
      params: {
        ...(query.registration_status ? {
          registration_status: query.registration_status
        } : {}),
        ...(query.registration_stage ? {
          registration_stage: query.registration_stage
        } : {}),
        ...(query.registration_course ? {
          registration_course: query.registration_course
        } : {}),
        ...(query.registration_year ? {
          registration_year: query.registration_year
        } : {}),
        ...(query.registration_semester ? {
          registration_semester: query.registration_semester
        } : {}),
        ...(query.enquiry_made_status ? {
          enquiry_made_status: query.enquiry_made_status
        } : {}),
        ...(query.enquiry_made_stage ? {
          enquiry_made_stage: query.enquiry_made_stage
        } : {}),
        ...(query.enquiry_made_course ? {
          enquiry_made_course: query.enquiry_made_course
        } : {}),
        ...(query.enquiry_made_year ? {
          enquiry_made_year: query.enquiry_made_year
        } : {}),
        ...(query.enquiry_made_semester ? {
          enquiry_made_semester: query.enquiry_made_semester
        } : {})
      },

    })
  }

  static toggleRead(email_id) {
    return this.api().post(`/emails/${email_id}/toggle-read`,{},{
      save: false
    })
  }

  static fetch(for_user_id = null, from_user_id = null, to_user_id = null, between_users = null,from_email=null,to_email=null, page = 1, limit = 999, relationships = ['from_user', 'to_user', 'media']) {
    return this.api().get(`/emails`, {
      persistBy:'insertOrUpdate',
      params: {
        page: page,
        limit: limit,
        ...(relationships ? {
          with: relationships
        } : {}),
        ...(for_user_id ? {
          for_user_id: for_user_id
        } : {}),
        ...(from_user_id ? {
          from_user_id: from_user_id
        } : {}),
        ...(from_email ? {
          from_email: from_email
        } : {}),     ...(to_email ? {
          to_email: to_email
        } : {}),
        ...(to_user_id ? {
          to_user_id: to_user_id
        } : {}),
        ...(between_users ? {
          between_users: between_users
        } : {})
      },
      dataTransformer: (response) => {

        let storageArray = []

        response.data.data.forEach(element => {

          element = {
            ...element,
            ...element.attributes
          }

          if (element.media.length > 0) {
            element.media.forEach((attachment, index) => {
              attachment = {
                ...attachment,
                ...attachment.attributes
              }

              attachment.mediable_id = element.id
              attachment.mediable_type = 'emails'
              element.media[index] = JSON.parse(JSON.stringify((attachment)))


            })
          }
          storageArray.push(element)

        });

        return storageArray

      }
    })
  }


}
