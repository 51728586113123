import Store from '@/store';
import Registration from "@/models/Registration";

export default {
  methods:
    {
      async checkRegistrationFlow(pageName,isSuccess=false) {
        await Registration.fetchByUser(
          1,
          999,
          parseInt(Store.state.userId)
        ).then(() => {
          //check if there are any registrations
          if (Registration.query().count() > 0) {
            //check if any incomplete registrations
            if (Registration.query().where('registration_status_id', (value => {
              return !Store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(value))
            })).count() > 0) {
              //Set it so that the registration flow is visible and which registration should be active
              Store.state.applications.hasRegistrationInProgress = true
              Store.state.applications.activeRegistrationId = Registration.query().where('registration_status_id', (value => {
                return !Store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(value))
              })).first().id
              //check if any complete registrations
              Store.state.applications.canSeeRegistrationsIndex = Registration.query().where('registration_status_id', (value => {
                return Store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(value)) || Store.state.statuses.STATUSES_REGISTRATION_PROBATION.includes(parseInt(value))
              })).count() > 0;
            } else {
              if (Registration.query().where('registration_status_id', (value => {
                return Store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(value))
              })).count() > 0) {
                //set index visible
                Store.state.applications.canSeeRegistrationsIndex = true
              } else {
                //if no complete registrations don't allow access to registrations index
                Store.state.applications.canSeeRegistrationsIndex = false
                Store.state.applications.hasRegistrationInProgress = true
              }

            }
          } else {
            //If no registrations at all start registration flow and don't allow access to index
            Store.state.applications.hasRegistrationInProgress = true
            Store.state.applications.canSeeRegistrationsIndex = false
          }

        })
        Store.state.applications.registrationProgressSet = true
        //navigation logic
        if (pageName === 'registrations.mine') {

          if (Store.state.applications.canSeeRegistrationsIndex !== true) {
            return Promise.reject({name: 'registrations.register'})
          }
        }
        if (pageName === 'registrations.register') {

          console.log(isSuccess)
          if (isSuccess){
            return Promise.reject({name: 'registrations.mine',query:{success:1}})
          }
          if (Store.state.applications.hasRegistrationInProgress !== true && Store.state.applications.canSeeRegistrationsIndex === true) {

            return Promise.reject({name: 'registrations.mine'})
          }
        }
        return Promise.resolve()
      }
    }
}
