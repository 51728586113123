<template>
  <card-component
    :icon="$tc('icons.media')"
    :title="'Invoices'"
    class="is-relative"
  >

    <div v-if="admin" class="mt-2 mb-4">
      <b-field grouped>
<!--        <b-field>-->
<!--          <b-button type="is-primary" @click="startGenerateApplicationInvoice">-->
<!--            Generate Application Fee Invoice-->
<!--          </b-button>-->
<!--        </b-field>-->
        <b-field>
          <b-button type="is-primary" @click="startGenerateInitialFeeInvoice">
            Generate Invoice
          </b-button>
        </b-field>
      </b-field>
    </div>
    <b-table
      :bordered="true"
      :data="invoices"
      :hoverable="true"
      :striped="true"
    >
      <b-table-column v-slot="props" field="id" label="ID" sortable>
        {{ props.row.id }}
      </b-table-column>
      <b-table-column v-slot="props" field="invoiceable_type" label="Type" sortable>
        {{ props.row.readable_type }}
      </b-table-column>
      <b-table-column v-slot="props" field="is_paid" label="Paid" sortable>
        {{ props.row.is_paid === 1 ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column v-slot="props" field="paying_via_eft" label="Paying via EFT" sortable>
        {{ props.row.paying_via_eft === 1 ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column v-slot="props" :visible="admin" field="needs_manual_verification" label="Need Manual Verification" sortable>
        {{ props.row.needs_manual_verification === 1 ? 'Yes' : 'No' }}
      </b-table-column>
      <b-table-column v-slot="props" field="total" label="Total" sortable>
        {{ props.row.total }}
      </b-table-column>
      <b-table-column v-slot="props" width="60">
        <b-button type="is-primary" @click.prevent="openInvoice(props.row)">View</b-button>
      </b-table-column>
    </b-table>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"/>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import UserDocument from "@/models/UserDocument";
import Invoice from "@/models/Invoice";
import InvoicePayment from "@/models/InvoicePayment";
import InvoiceForm from "@/components/invoices/InvoiceForm";
import Registration from "@/models/Registration";

export default {
  name: 'InvoicePanel',
  components: {
    CardComponent
  },
  props: {
    admin: {
      type: Boolean,
      default() {
        return false
      }
    },
    learner_id: {
      type: String,
      default() {
        return null
      }

    },
    invoiceableType: {
      type: String,
      default() {
        return null
      }
    },
    invoiceableId: {
      type: String,
      default() {
        return null
      }
    },
  },
  methods: {
    startGenerateApplicationInvoice() {
      this.$buefy.dialog.confirm({
        title: "Generate Invoice",
        message:
          "Are you sure you want to generate an application fee invoice for this registration?",
        confirmText: "Generate Invoice",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('loader/show')
          Invoice.GenerateApplicationInvoice(this.invoiceableId).then(() => {
            this.$store.dispatch('loader/hide')
          }).catch(err => {
            this.handleError(err)
            this.$store.dispatch('loader/hide')
          })
        },
      })
    }, startGenerateInitialFeeInvoice() {
      this.$buefy.dialog.confirm({
        title: "Generate Invoice",
        message:
          "Are you sure you want to generate an invoice for this registration?",
        confirmText: "Generate Invoice",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => {
          this.$store.dispatch('loader/show')
          Invoice.GenerateInitialFeeInvoice(this.invoiceableId).then(() => {
            this.$store.dispatch('loader/hide')
          }).catch(err => {
            this.handleError(err)
            this.$store.dispatch('loader/hide')
          })
        },
      });
    },
    openInvoice(invoice) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          invoice: invoice,
          canEdit: this.admin,
          edit: true,
          inModal: true,
          admin: this.admin
        },
        component: InvoiceForm,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    saveUserDocuments() {
      this.loading = true
      UserDocument.Update({documents: this.documents}, this.learner_id).then(() => {
        this.$buefy.snackbar.open('Document Verification Updated!')
        this.loading = false
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    },
  },
  computed: {
    filters() {
      return {
        ...(this.learner_id
          ? {
            user: this.learner_id,
          }
          : {}),
        ...(this.invoiceableType
          ? {
            invoiceableType: this.invoiceableType,
          }
          : {}),
        ...(this.invoiceableId
          ? {
            invoiceableId: this.invoiceableId,
          }
          : {}),

      };
    },

    invoices() {
      return Invoice.query().withAll().get()
    },
    payments() {
      return InvoicePayment.query().withAll().get()
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      applicationFeePaid: false,
      intialFeePaid: false,
    };
  },

  async created() {
    this.loading = true
    await Promise.all([
      Invoice.deleteAll(),

    ])
    await Promise.all([
      Invoice.FetchAll({page: 1, limit: 20}, this.filters, ['invoiceItems', 'invoiceable']),
    ])
    await Promise.all(this.invoices.map(invoice => {
      return InvoicePayment.FetchAll(invoice.id)
    }))
    if (this.admin && this.invoiceableType === 'registration') {
      await Registration.CheckPayments(this.invoiceableId).then(({response: {data: data}}) => {

        if (Object.values(data).some(item => {
          return parseInt(item.type_id) === 2
        })) {
          if (Object.values(data).find(item => {
            return parseInt(item.type_id) === 2

          }).paid === true) {
            this.intialFeePaid = true
          }
        }
        if (Object.values(data).some(item => {
          return parseInt(item.type_id) === 1
        })) {
          if (Object.values(data).find(item => {
            return parseInt(item.type_id) === 1

          }).paid === true) {
            this.applicationFeePaid = true
          }
        }
      })
    }
    this.loaded = true
    this.loading = false


  },
};
</script>
