<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="submit">
      <div  :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Matric Information</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <b-field v-if="course.has_matric_requirements===1">
            <template #label>
                   <p>Exemption Type</p>
                   <p class="help has-text-weight-normal">The certification type of your highest non-tertiary schooling achievement</p>
            </template>
            <b-select
              v-model="exemption_type"
              placeholder="Select exemption type"
              required
            >
              <option
                v-for="type in exemption_types"
                :key="type.id"
                :value="type.name"
              >{{ type.name }}
              </option>
            </b-select>
          </b-field>
          <b-field v-if="course.has_school_subject_requirements===1 && !isAgeExempt" label="School Subjects">
            <b-table
              :bordered="true"
              :data="user_subject_store"
              :header-checkable="false"
              :hoverable="true"
              :striped="true"
              class="margin-top"
            >
              <b-table-column v-slot="props"
                              label="Subject"

              >{{ props.row.subject ? props.row.subject.name : '' }}
              </b-table-column>
              <b-table-column v-slot="props"
                              label="Grade Achieved (%)"

              >
                <b-field>
                  <b-numberinput v-model="props.row.grade" :controls="false" min-step="0.01" placeholder="Grade Achieved" required/>
                </b-field>
              </b-table-column>
            </b-table>
          </b-field>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>

              <p class="control">
                <b-button :disabled="invalid" :loading="loading" native-type="submit"
                          type="is-primary">Next
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Lookup from "@/models/Lookup";
import Profile from "@/models/Profile";
import CourseSubjects from "@/models/CourseSubject";
import UserSubject from "@/models/UserSubject";
import SchoolSubject from "@/models/SchoolSubject";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "MatricConditions",
  components: {
    TitleBar,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      courseHold: null,
      filteredCourses: [],
      exemption_type: null,
      user_subjects: []

    }
  },
  methods: {

    async submit() {
      await this.$store.dispatch('loader/show')
      if (this.course.has_matric_requirements === 1) {
        let submit_profile = Profile.query().where('user_id', (value) => {
      return parseInt(value) === parseInt(this.$store.state.userId)
    }).first()
        submit_profile.school_record.exemption_type = this.exemption_type
        delete submit_profile.staff_information
        await Profile.updateRemote(submit_profile, this.$store.state.userId,false).catch(err => {
          this.handleError(err)
          this.$store.dispatch('loader/hide')
        })
      }
      if (this.course.has_school_subject_requirements === 1 && !this.isAgeExempt) {
        UserSubject.Update({school_subjects: this.user_subject_store}, this.$store.state.userId)
      }
      await this.$store.dispatch('loader/hide')

      this.$emit('next', 'matric')


    }
  },

  computed: {
    user_subject_store() {
      return UserSubject.query().withAll().get()
    },
    course_subjects() {
      return CourseSubjects.query().get()
    },
    exemption_types() {
      return Lookup.query().where('lookup_type_id', 13).get()
    },
  },
  async mounted() {
    this.loading = true
    await Lookup.fetchAll({lookup_type_id: [13, 23]})
    if (this.course.has_matric_requirements === 1) {
      this.exemption_type = Profile.query().where('user_id', (value) => {
      return parseInt(value) === parseInt(this.$store.state.userId)
    }).first().school_record.exemption_type
    }
    if (this.course.has_school_subject_requirements === 1) {
      await CourseSubjects.deleteAll()
      await UserSubject.deleteAll()
      await UserSubject.FetchAll(1, 999, this.$store.state.userId)
      SchoolSubject.FetchAll()
      await CourseSubjects.FetchAll(1, 999, this.course.id)
      CourseSubjects.query().get().map(subject => {
        if (!UserSubject.query().where('school_subject_id', subject.school_subject_id).exists()) {
          UserSubject.insert({
            data: {
              school_subject_id: subject.school_subject_id,
              grade: null
            }
          })
        }
      })

    }
    this.loading = false
  },
  props: {

    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },    isAgeExempt: {
      type: Boolean, default() {
        return false
      }
    },
    course: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

