
export default {
    methods:
        {
            makeModalLarge(targetClass='animation-content') {
                let items = document.getElementsByClassName(targetClass);
                for (let i = 0; i < items.length; i++) {
                    items[i].classList.add("is-large")
                }
            },


        }
}
