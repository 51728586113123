<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="submit">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Prior Qualifications</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <PriorQualificationListItem v-for="(study,index) in previous_studies" :key="index" :index="index" :study="study" @remove="remove_study(index)"/>

          <!--          <div v-for="(study,index) in previous_studies" :key="index" class="core-option-list-item p-2 pb-4">-->
          <!--            <b-field group-multiline grouped>-->
          <!--              <b-field expanded label="Start Year">-->
          <!--                <b-select v-model="study.from_year" expanded name="from_year" placeholder="From Year" required>-->
          <!--                  <option v-for="year in years" :key="year+'start'+index" :value="year">{{ year }}</option>-->
          <!--                </b-select>-->

          <!--              </b-field>-->
          <!--              <b-field expanded label="End Year">-->
          <!--                <b-select v-model="study.to_year" expanded name="to_year" placeholder="End Year" required>-->
          <!--                  <option v-for="year in years" :key="year+'start'+index" :value="year">{{ year }}</option>-->
          <!--                </b-select>-->

          <!--              </b-field>-->

          <!--              <b-field expanded label="Name of Institution">-->
          <!--                <b-select v-model="study.institution_name"-->
          <!--                          autocomplete="chrome-off"-->
          <!--                          expanded-->
          <!--                          name="institution_name"-->
          <!--                          placeholder="Name"-->
          <!--                          required-->
          <!--                >-->
          <!--                  <option-->
          <!--                    v-for="institution in institutions"-->
          <!--                    :key="institution.id"-->
          <!--                    :value="institution.name"-->
          <!--                  >{{ institution.name }}-->
          <!--                  </option>-->
          <!--                </b-select>-->
          <!--              </b-field>-->

          <!--              <b-field expanded label="Name of degree/diploma/certificate">-->
          <!--                <b-select v-model="study.qualification_name"-->
          <!--                          autocomplete="chrome-off"-->
          <!--                          expanded-->
          <!--                          name="qualification_name"-->
          <!--                          placeholder="Qualification"-->
          <!--                          required-->
          <!--                >-->
          <!--                  <option-->
          <!--                    v-for="qualification in qualifications"-->
          <!--                    :key="qualification.id"-->
          <!--                    :value="qualification.name"-->
          <!--                  >{{ qualification.name }}-->
          <!--                  </option>-->
          <!--                </b-select>-->
          <!--              </b-field>-->

          <!--              <b-field expanded label="Study complete?">-->
          <!--                <b-checkbox-->
          <!--                  v-model="study.is_complete"-->
          <!--                  :false-value="false"-->
          <!--                  :true-value="true"-->
          <!--                >{{ study.is_complete ? 'Yes' : 'No' }}-->
          <!--                </b-checkbox>-->
          <!--              </b-field>-->

          <!--              <b-field expanded label="Type of Institution">-->

          <!--                <b-select v-model="study.type_of_institution"-->
          <!--                          expanded-->
          <!--                          icon="flag" placeholder="Type of institution" required>-->
          <!--                  <option v-for="type in $store.state.profile.school_types" :value="type">{{ type }}-->
          <!--                  </option>-->
          <!--                </b-select>-->
          <!--              </b-field>-->

          <!--              <b-field expanded label="SAQA verification">-->
          <!--                <b-select v-model="study.saqa_verification"-->
          <!--                          :required="study.type_of_institution!=='Public'"-->
          <!--                          expanded-->
          <!--                          placeholder="SAQA Verification Status"-->
          <!--                >-->
          <!--                  <option-->
          <!--                    v-for="status in $store.state.profile.saqa_statuses"-->
          <!--                    :value="status"-->
          <!--                  >{{ status }}-->
          <!--                  </option>-->
          <!--                </b-select>-->
          <!--              </b-field>-->

          <!--              <b-field label="Remove Qualification">-->
          <!--                <p class="control">-->
          <!--                  <b-button-->
          <!--                    icon-right="delete"-->
          <!--                    type="is-danger"-->
          <!--                    @click.prevent="remove_study(index)"-->
          <!--                  >Remove-->
          <!--                  </b-button>-->
          <!--                </p>-->
          <!--              </b-field>-->
          <!--            </b-field>-->
          <!--          </div>-->

          <b-field class="pt-4">
            <b-button
              type="is-success"
              @click.prevent="add_previous_study"
            >Add qualification
            </b-button>
          </b-field>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>

              <p class="control">
                <b-button :disabled="invalid" :loading="loading" native-type="submit"
                          type="is-primary">Next
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Lookup from "@/models/Lookup";
import Profile from "@/models/Profile";
import PriorQualificationListItem from "@/components/Registrations/conditions/PriorQualificationListItem"

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "PriorQualifications",
  components: {
    TitleBar,
    ValidationObserver,
    PriorQualificationListItem
  },
  data() {
    return {
      loading: false,
      courseHold: null,
      filteredCourses: [],
      exemption_type: null,
      user_subjects: [],
      previous_studies: []

    }
  },
  methods: {
    remove_study(index) {
      this.previous_studies = this.previous_studies.filter(
        (item, place) => place !== index
      )
    },
    add_previous_study() {

      this.previous_studies.push({
        from_year: null,
        to_year: null,
        institution_name: null,
        qualification_name: null,
        is_complete: false,
        qualification_attached: true,
        type_of_instituion: null,
        saqa_verification: null
      });
    },
    async submit() {
      if (this.previous_studies.length < 1) {
        return this.$buefy.snackbar.open({
          message: "Provide at least one Prior Qualification to proceed",
          type: "is-link",
          position: "is-top",
          actionText: "Ok",
        });
      }
      await this.$store.dispatch('loader/show')
      let submit_profile = Profile.query().where('user_id', (value) => {
        return parseInt(value) === parseInt(this.$store.state.userId)
      }).first()
      submit_profile.previous_studies = this.previous_studies
      delete submit_profile.staff_information

      try {
        await Profile.updateRemote(submit_profile, this.$store.state.userId, false)
        await this.$store.dispatch('loader/hide')
        this.$emit('next', 'qualifications')
      } catch (err) {
        this.handleError(err)
        await this.$store.dispatch('loader/hide')
      }


    }
  },

  computed: {
    years() {
      let years = [];
      for (let i = -3; i < 50; i++) {
        years.push(new Date().getFullYear() - i);
      }
      return years;
    },
    institutions() {

      return Lookup.query().where("lookup_type_id", 9).orderBy('name').get();
    },
    qualifications() {

      return Lookup.query().where("lookup_type_id", 19).orderBy('name').get();
    },


  },
  async mounted() {
    this.loading = true
    await Lookup.fetchAll({lookup_type_id: [9, 19]})
    this.previous_studies = Profile.query().where('user_id', (value) => {
      return parseInt(value) === parseInt(this.$store.state.userId)
    }).first().previous_studies

    this.loading = false
  },
  props: {

    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    course: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

