<template>
  <Export
    :can_generate="false"
    :date_filter="false"
    edudex_date_filter
    :type="'edudex'"
    :entities="entities"
    :title="'CHE Persons File 61'"
    :file_types="file_types"
    :default_type_value="null"
  ></Export>
</template>


<script>
import Export from "@/components/Export";


export default {
  name: "edudexStaff",
  components: {
    Export,
  },
  data() {
    return {
      file_types:[{name:'dat',value: null},{name:"csv",value:'csv'}, {name:"xls",value:'xls'}, {name:"xlsx",value:"xlsx"}],
      entities: "persons",
      admissions_team: [],

    };
  },
};
</script>
