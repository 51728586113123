<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Select a {{$tc('Learner',1)}}</p>
      </header>
      <section class="modal-card-body" style="min-height: 300px">
        <b-field :label="$tc('Learner',1)">
          <b-field>
            <b-autocomplete
              ref="autocomplete"
              v-debounce:300ms="getFilteredLearner"
              :data="filteredLearners"
              :icon="$tc('icons.learners')"
              autocomplete="off"
              v-model="searchTerm"
              placeholder="Name"
              name="first_name"
              :loading="loadingLearners"
              required
              :custom-formatter="thing=> name(thing)"
              :clear-on-select="false"
              @select="option => selectLearner(option)"
            >
              <template slot-scope="props">
                <p>{{props.option.first_name}} {{props.option.last_name}} | {{props.option.email}}  {{props.option.id==existingUser.id?'(Current Registration User)':''}}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>


      </section>
      <footer  class="modal-card-foot">
        <b-button @click.prevent="cancel" outlined :loading="loading" class="button" type="is-primary"
        >Cancel
        </b-button>
        <b-button :loading="loading" native-type="submit" class="button" type="is-primary"
        >Save
        </b-button>
      </footer>

    </div>
  </form>
</template>

<script>
import Learner from "@/models/Learner";

export default {
  name: "LearnerSelectModal",
  data() {
    return {
      loading: false,
      loadingLearners:false,
      filteredLearners:[],
      selectedLearner: { name: "" },
      searchTerm: '',
    }
  },
  methods: {
    name(thing) {
      return `${thing.first_name} ${thing.last_name} | ${thing.email} ${thing.id===this.existingUser.id?'(Current Registration User)':''}`
    },
    selectLearner(learner) {
      this.selectedLearner = learner
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.filteredLearners = result.entities.learners;
        } else {
          this.filteredLearners = [];
        }
      });
    },
    validateAutocomplete(source, target1,target2 = null) {
      if (source == null) {
        if (target2!==null){
          this[target1][target2] = null
        } else {
          this[target1] =null
        }
      }
    },
  cancel(){
      this.$emit('close')
  },
    submit() {
      this.$emit('learner',this.selectedLearner)
      this.$emit('close')
    }
  },

  mounted() {
    this.filteredLearners.push(this.existingUser)
    this.$refs.autocomplete.setSelected(this.existingUser)

  },
  props: {
    existingUser:{
      type:Object,required:true
    },
  }
}
</script>

