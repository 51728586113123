import VueCookie from 'vue-cookie';

export const socketsHost = 'http://localhost:5000/';
export var ACCESS_TOKEN = VueCookie.get('access_token');
export const AUTH_ROUTES = ['start', 'login', 'register', 'password-reset', 'password-recovery', 'apply','admin-login'];
export const DEFAULT_ROUTE = '/';

const BACKEND_URL = (process.env.NODE_ENV === 'development' ? "https://staging.api.aas.codespace.co.za/" : "https://api.aas.cornerstone.codespace.co.za/");
const API_URL = (process.env.NODE_ENV === 'development' ? "https://staging.api.aas.codespace.co.za/api/1" : "https://api.aas.cornerstone.codespace.co.za/api/1");
const CLIENT_SECRET = (process.env.NODE_ENV === 'development' ? "JbCffbEPm39WPyMgBKafYD2PbNr8uOCYYiZvNKkD" : "i9H59lkrEuOMcKfWhWMg30zaVsMCh3eHeH11jnSP");
const PEACH_URL = (process.env.NODE_ENV === 'development' ? "https://eu-test.oppwa.com/" : "https://eu-prod.oppwa.com/");

export {
  BACKEND_URL
}
export {
  API_URL
}
export {
  CLIENT_SECRET,
  PEACH_URL
}
