
import {Model} from "@vuex-orm/core";


export default class Lookup extends Model {
  static entity = 'lookups'

  static fields() {
    return {
      id: this.attr(null),
      che_lookup:this.attr(null),
      lookup_type_id: this.attr(null),
      name: this.attr('')
    }
  }

  static fetchAll(query={}) {
    return this.api().get(`/lookups`, {
      params: {
       ...(query !== {} ? query : {}),
      },
      dataTransformer: ({data: {data}}) => {
        return data.map(lookup => {
          return {...lookup, ...lookup.attributes}
        })
      },
    })
  }
  static fetchIDTypes() {
    return this.api().get(`/public/lookups/identification-type`, {
      dataTransformer: ({
        data
      }) => {
        let storage = []
        data.data.forEach(element => {
          storage.push(element)


        });
        return storage
      }
    })
  }
  static create(lookup) {
    return this.api().post(`/lookups`, lookup, {
      dataTransformer: ({
        data
      }) => {
        return data.data
      }
    })
  }

  static deleteRemote(lookup_id) {
    return this.api().delete(`/lookups/${lookup_id}`, {
      delete: lookup_id
    })
  }
  static updateRemote(lookup) {
    return this.api().patch(`/lookups/${lookup.id}`, lookup, {
      dataTransformer: ({
        data
      }) => {
        return data.data
      }
    })
  }



}
