import {
  TransformModel
} from '@/models/TransformModel'

export default class Profile extends TransformModel {
  static entity = 'profiles'

  static fields() {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      gender_id: this.attr(null),
      ethnicity_id: this.attr(null),
      address: this.attr(null),
      citizenship: this.attr(() => ({
        type: null,
        other: null
      })),
      physical_address: this.attr(() => ({
        line_1: null,
        line_2: null,
        city: null,
        province: null,
        postal_code: null,
        country: null,
        country_id: null,
        city_id: null,
        province_id: null
      })),
      alternative_address: this.attr(() => ({
        line_1: null,
        line_2: null,
        city: null,
        province: null,
        postal_code: null,
        country: null, country_id: null,
        city_id: null,
        province_id: null
      })),
      postal_address: this.attr(() => ({
        line_1: null,
        line_2: null,
        city: null,
        province: null,
        postal_code: null,
        country: null, country_id: null,
        city_id: null,
        province_id: null
      })),
      staff_information: this.attr(() => ({
        staff_category_id: null,
        appointment_date: new Date(),
        termination_date: new Date(),
        employment_status_id: null,
        highest_qualification_id: null,
        working_hours_per_month: null,
        appointment_type_id: null
      })),
      // postal_address: this.belongsTo(Address, 'postal_address'),
      date_of_birth: this.attr(null),
      identification_type_id: this.attr(null),
      nationality_id: this.attr(null),
      study_permit_number: this.attr(null),
      permit_number: this.attr(null),
      permit_type_id: this.attr(null),
      permit_expiry_date: this.attr(null),
      id_number: this.attr(null),
      language_id: this.attr(null),
      marital_status_id: this.attr(null),
      religion_id: this.attr(null),
      finance: this.attr(null),
      finance_other: this.attr(null),
      where_hear: this.attr(null),
      why_choose: this.attr(null),
      prior_institution_id: this.attr(null),
      prior_institution: this.attr(null),
      billing_contact: this.attr(()=>({
        name:null,
        contact_number:null
      })),
      alternative_contact: this.attr(()=>({
        name:null,
        contact_number:null
      })),

      family_information: this.attr(() => ({
        number_of_siblings: null,
        mother_education_level: null,
        father_education_level: null,
        grandparents_university_qualification: null,
        grandparents_technikon_degree_or_diploma: null,
        child_grant: null,
        social_pension: null
      })),
      sponsor_information: this.attr(() => ({
        last_name: null,
        initials: null,
        title: null,
        contact_number: null,
        email: null,
        address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null, country_id: null,
          city_id: null,
          province_id: null
        }
      })),
      next_of_kin_information: this.attr(() => ({
        last_name: null,
        initials: null,
        title: null,
        contact_number: null,
        email: null,
        address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          country_id: null,
          city_id: null,
          province_id: null
        }
      })),
      reference_contact: this.attr(() => ([{
        last_name: null,
        initials: null,
        title: null,
        contact_number: null,
        email: null,
        address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null, country_id: null,
          city_id: null,
          province_id: null
        }
      }])),
      // medical_information: this.attr(() => ({
      //   psychological_disability: null,
      //   physical_disability: null,
      //   chronic: null,
      // })),
      disability_ids: this.attr(null),
      accommodation_ids: this.attr(null),
      school_attended: this.attr(() => ({
        name: null,
        type_of_school: null,
            })),
      previous_studies: this.attr(() => ([{
        from_year: null,
        to_year: null,
        institution_name: null,
        qualification_name: null,
        is_complete: false,
        qualification_attached: false,
        type_of_institution: null,
        saqa_verification: null,
      }])),
      employment_information: this.attr(() => ([{
        start_date: null,
        end_date: null,
        type_of_work: null,
        employer: null
      }])),
      school_record: this.attr(() => ({
        school_leaving_year: null,
        examination_authority: null,
        exemption_type: null,
        nbt_math: null,
        nbt_english: null,
        saqa_verification: null,
        exit_aggregate_percentage:null
      })),
      activities: this.attr(() => ({
        sport_societies: null,
        leadership_roles: null,
        community_involvement: null
      })),
      personal_statements: this.attr(() => ({
        admission_reason: null,
        interests_hobbies: null,
        goals: null,
        occupation: null
      }))
    }
  }


  static mutators() {
    return {
      citizenship(value) {
        if (value == null) {
          return {
            type: null,
            other: null
          }
        }
        return value
      },
      staff_information(value) {
        if (value == null) {
          return {
            staff_category_id: null,
            appointment_date: "2021-12-30T22:00:00.000Z",
            termination_date: "2021-12-30T22:00:00.000Z",
            employment_status_id: null,
            highest_qualification_id: null,
            working_hours_per_month: null,
            appointment_type_id: null
          }
        }
        return value
      },

      family_information(value) {
        if (value == null) {
          return {
            is_complete: false,
            number_of_siblings: null,
            mother_education_level: null,
            father_education_level: null,
            grandparents_university_qualification: null,
            grandparents_technikon_degree_or_diploma: null,
            child_grant: null,
            social_pension: null
          }
        }
        return value
      },
      personal_statements(value) {
        if (value == null) {
          return {
            is_complete: false,

            admission_reason: null,
            interests_hobbies: null,
            goals: null,
            occupation: null
          }
        }
        return value
      },
      activities(value) {
        if (value == null) {
          return {
            is_complete: false,

            sport_societies: null,
            leadership_roles: null,
            community_involvement: null
          }
        }
        return value
      },
      previous_studies(value) {
        if (value == null) {
          return [{
            from_year: null,
            to_year: null,
            institution_name: null,
            qualification_name: null,
            is_complete: false,
            qualification_attached: false,
            type_of_institution: null,
            saqa_verification: null
          }]
        }
        return value
      },
      employment_information(value) {
        if (value == null) {
          return [{
            start_date: null,
            end_date: null,
            type_of_work: null,
            employer: null
          }]
        }
        return value
      },
      school_attended(value) {
        if (value == null) {
          return {
            is_complete: false,
            type_of_school: null,
            name: null,
            contact_number: null,
            address: {
              line_1: null,
              line_2: null,
              city: null,
              province: null,
              postal_code: null,
              country: null, country_id: null,
              city_id: null,
              province_id: null
            }
          }
        }
        return value
      },
      school_record(value) {
        if (value == null) {
          return {
            is_complete: false,
            nbt_math: null,
            nbt_english: null,
            school_leaving_year: null,
            examination_authority: null,
            exemption_type: null,
            saqa_verification: null
          }
        }
        return value
      },
      medical_information(value) {
        if (value == null) {
          return {
            psychological_disability: null,
            physical_disability: null,
            chronic: null,
          }
        }
        return value
      },
      sponsor_information(value) {
        if (value == null) {
          return {
            is_complete: false,
            last_name: null,
            initials: null,
            title: null,
            contact_number: null,
            email: null,
            address: {
              line_1: null,
              line_2: null,
              city: null,
              province: null,
              postal_code: null,
              country: null, country_id: null,
              city_id: null,
              province_id: null
            }
          }
        }
        return value
      },
      next_of_kin_information(value) {
        if (value == null) {
          return {
            is_complete: false,

            last_name: null,
            initials: null,
            title: null,
            contact_number: null,
            email: null,
            address: {
              line_1: null,
              line_2: null,
              city: null,
              province: null,
              postal_code: null,
              country: null, country_id: null,
              city_id: null,
              province_id: null
            }
          }
        }
        return value
      },
      reference_contact(value) {
        if (value == null) {
          return [
            {
            is_complete: false,

            last_name: null,
            initials: null,
            title: null,
            contact_number: null,
            email: null,
            address: {
              line_1: null,
              line_2: null,
              city: null,
              province: null,
              postal_code: null,
              country: null, country_id: null,
              city_id: null,
              province_id: null
            }
          }
          ]
        }
        return value
      },
      physical_address(value) {
        if (value == null) {
          return {

            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null, country_id: null,
            city_id: null,
            province_id: null
          }
        }
        return value
      },
      alternative_address(value) {
        if (value == null) {
          return {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null, country_id: null,
            city_id: null,
            province_id: null
          }
        }
        return value
      },
      postal_address(value) {
        if (value == null) {
          return {
            line_1: null,
            line_2: null,
            city: null,
            province: null,
            postal_code: null,
            country: null, country_id: null,
            city_id: null,
            province_id: null
          }
        }
        return value
      },
      alternative_contact(value) {
        if (value == null) {
          return {
            name: null,
            contact_number: null,

          }
        }
        return value
      },
      billing_contact(value) {
        if (value == null) {
          return {
            name: null,
            contact_number: null,

          }
        }
        return value
      },
    }
  }

  static newProfile(profile, learner_id) {
    return this.api().post(`/learners/${learner_id}/profile`, profile)
  }

  static updateRemote(profile, learner_id,saved=true) {
    return this.api().patch(`/learners/${learner_id}/profile`, profile, {
      save:saved,
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        if (target.finance !==null) {
          target.finance = parseInt(target.finance)
        }
        return target

      }
    })
  }

  static fetchById(id, type = 'learners') {
    return this.api().get(`/${type}/${id}/profile`, {
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        if (target.finance !==null) {
          target.finance = parseInt(target.finance)
        }
        return target

      }

    })
  }


}
