<template>
  <div class="view-login">
    <img src="@/assets/cornerstone-logo.png" alt />

    <card-component>
      <form @submit.prevent="submit" method="POST">
        <b-field label="Username">
          <b-input name="username" v-model="$store.state.auth.username" required autofocus />
        </b-field>

        <b-field label="Password">
          <b-input type="password" name="password" v-model="$store.state.auth.password" required />
        </b-field>

        <!-- <b-field>
                  <b-checkbox type="is-primary" class="is-thin" v-model="form.remember">Remember me</b-checkbox>
        </b-field>-->

        <hr />

          <b-field position="is-centered" grouped group-multiline>
            <b-field expanded class="fullpage-button-field">
              <b-button expanded
                        native-type="submit"
                        type="is-primary"
                        :loading="isLoading"
              >Login
              </b-button>
            </b-field>
            <b-field expanded class="fullpage-button-field">
              <b-button expanded tag="router-link"
                        to="/password-recovery"
                        type="is-primary" outlined>
                Forgot password
              </b-button>
            </b-field>

            <b-field expanded class="fullpage-button-field">
              <b-button expanded tag="router-link"
                        to="/login"
                        type="is-primary" outlined>
                Log in as {{$tc('learner',1)}}
              </b-button>
            </b-field>

          </b-field>


<!--        <router-link-->
<!--          to="/start"-->
<!--          class=""-->
<!--        >Not yet registered? Start here.</router-link>-->
      </form>
    </card-component>
  </div>
</template>

<script>
import { AuthClient } from "@/clients/AuthClient";
import VueCookie from "vue-cookie";
import CardComponent from "@/components/CardComponent";
import { BACKEND_URL, CLIENT_SECRET } from "@/config";

export default {
  name: "Login",
  components: { CardComponent },
  data() {
    return {
      backendUrl: BACKEND_URL,
      isLoading: false,
      form: {

        remember: true
      }
    };
  },
  methods: {
    submit() {
      this.isLoading = true;
      AuthClient.login({
        username: this.$store.state.auth.username,
        password: this.$store.state.auth.password,
        client_id: "2",
        client_secret: CLIENT_SECRET,
        grant_type: "password"
      })
        .then(result => {
          VueCookie.set("access_token", result.data.access_token);
          window.location.href = "/";
        })
        .catch(res => {

          this.$store.dispatch("toast/createToast", {
            message: res.response.data.message
          });

          this.isLoading = false;
        });
    }
  }
};
</script>
