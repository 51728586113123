<template>

  <b-tabs v-model="activeTab" :destroy-on-hide="true" class="non-hidden-tabs has-background-white box p-0" expanded
          multiline
  >

    <b-tab-item v-if="tabs.includes('matric')" :disabled="!achievedTabs.includes('matric')" label="Matric Requirements">
      <Matric :course="activeRegistration.course" :is-age-exempt="isAgeExempt" @next="completeTab"
              @withdraw="$emit('withdraw')"/>
    </b-tab-item>
    <b-tab-item v-if="tabs.includes('qualifications')" :disabled="!achievedTabs.includes('qualifications')"
                label="Prior Qualifications">
      <PriorQualifications :course="activeRegistration.course" @next="completeTab" @withdraw="$emit('withdraw')"/>
    </b-tab-item>
    <b-tab-item v-if="tabs.includes('languages')" :disabled="!achievedTabs.includes('languages')" label="Languages">
      <Languages :course="activeRegistration.course" :languages="languages" :learner_id="$store.state.userId" can-edit edit @next="completeTab" @withdraw="$emit('withdraw')"/>
    </b-tab-item>
    <b-tab-item v-if="tabs.includes('core')" :disabled="!achievedTabs.includes('core')" label="Specialisations">
      <CoreOptions :coreOptions="coreOptions" :course="activeRegistration.course" :learner_id="$store.state.userId" can-edit edit @next="completeTab" @withdraw="$emit('withdraw')"/>
    </b-tab-item>
    <b-tab-item v-if="tabs.includes('uploads')" :disabled="!achievedTabs.includes('uploads')" label="Required Uploads">
      <RequiredUploads :course="activeRegistration.course" :registration_id="activeRegistration.id" @next="completeTab" @withdraw="$emit('withdraw')"/>
    </b-tab-item>
    <b-tab-item :disabled="!achievedTabs.includes('success')" label="Confirmation">
      <div class="is-relative">
        <div v-if="activeRegistration.course.is_custom_flow===1" class="card">
          <title-bar class="card-header"><p class="card-header-title">Thank you for applying at Cornerstone Institute!</p></title-bar>
          <section class="card-content">

            <p>Your application is now under review and the Cornerstone Admissions Team will contact you to advise on the next steps.</p>
            <p>Please follow up on your application if you have not heard from us within a week.</p>
          </section>
        </div>
        <form v-else class="card" @submit.prevent="$emit('success')">

          <title-bar class="card-header"><p class="card-header-title">Success</p></title-bar>

          <section class="card-content">

            <p>You have successfully met all application conditions</p>
            <p>You can now proceed to the payment portal after agreeing to the declaration</p>
            <b-field class="pt-3">
              <b-checkbox v-model="accept_terms" required><span class="is-italic">I am fully aware that the programme I have enrolled for, {{
                  activeRegistration.course.name
                }}, SAQA ID: {{ activeRegistration.course.saqa_number }} is registered with
                                      the Department of Higher Education and Training to Cornerstone Institute as indicated on the Registration Certificate dated 07/10/2022.</span></b-checkbox>
            </b-field>
          </section>
          <footer class="card-footer">
            <div class="card-footer-item">
              <b-field grouped>

                <p class="control">
                  <b-button native-type="submit" type="is-primary">Confirm & Proceed</b-button>
                </p>
              </b-field>
            </div>
          </footer>
        </form>
      </div>


    </b-tab-item>
    <b-loading :active="loading" :is-full-page="false"/>
  </b-tabs>

</template>

<script>
import Registration from "@/models/Registration";
import Learner from "@/models/Learner";
import {mapState} from "vuex";
import Matric from "@/components/Registrations/conditions/Matric";
import RequiredUploads from "@/components/Registrations/conditions/RequiredUploads";
import CourseDocuments from "@/models/CourseDocument";
import {differenceInYears} from "date-fns";
import FailedConditionsModal from "@/components/Registrations/conditions/FailedConditionsModal";
import TitleBar from "@/components/TitleBar";
import PriorQualifications from "@/components/Registrations/conditions/PriorQualifications";
import Languages from "@/components/Registrations/conditions/Languages";
import CourseLanguages from "@/models/CourseLanguages";
import CoreOptions from "@/components/Registrations/conditions/CoreOptions";
import CourseCoreOption from "@/models/CourseCoreOption";
import Course from "@/models/Course";
// import LearnerRegistrationListItem from "@/components/registrations/RegistrationListItem"

export default {
  name: "ApplicationConditionsPanel",
  components: {
    Languages,
    PriorQualifications,
    Matric,
    RequiredUploads,

    TitleBar,
    CoreOptions
    // LearnerRegistrationListItem
  },
  data() {
    return {
      accept_terms: false,
      allowTabNavigation: false,
      achievedTabs: [],
      activeTab: 0,
      loading: false,
      hasCourseLanguages: false,
    };
  },
  computed: {
    tabs() {
      let tabs = []
      if (this.activeRegistration.course.has_matric_requirements === 1 || (this.activeRegistration.course.has_school_subject_requirements === 1 && !this.isAgeExempt)) {
        tabs.push('matric')
      }
      if (this.activeRegistration.course.has_qualification_requirement === 1) {
        tabs.push('qualifications')
      }
      if (this.hasCourseLanguages) {
        tabs.push('languages')
      }
      if (this.activeRegistration.course.has_core_options === 1) {
        tabs.push('core')
      }
      if (CourseDocuments.query().count() > 0) {
        tabs.push('uploads')
      }

      tabs.push('success')
      return tabs
    },
    ...mapState('applications', ['activeRegistrationId']),
    isAgeExempt() {
      if (this.activeRegistration.course.has_age_exemption === 1) {
        return differenceInYears(new Date(), new Date(this.learner.profile !== null ? this.learner.profile.date_of_birth : new Date())) >= this.activeRegistration.course.age_exemption
      }
      return false
    },
    activeRegistration() {
      return Registration.query().whereId(this.activeRegistrationId).withAllRecursive().first()
    },
    learner() {
      return Learner.query().whereId(this.$store.state.userId).withAll().first() ?? {profile: Object}
    },
    languages() {
      return CourseLanguages.query().get()
    },
    coreOptions() {
      return CourseCoreOption.query().withAll().get()
    },
  },
  methods: {
    showRegretModal(conditions) {
      this.$buefy.modal.open({
        parent: this,
        props: {
          conditions: conditions
        },
        events: {
          'updateStages': () => {
            this.sync()
          }
        },
        component: FailedConditionsModal,
        hasModalCard: true,
        trapFocus: true,
      });
    },
    checkSkip() {
      return Registration.checkStatus(this.activeRegistrationId).then(({response: {data: data}}) => {
        if (data.previously_accepted_for_course === true) {
          this.achievedTabs = JSON.parse(JSON.stringify(this.tabs))
          this.activeTab = this.tabs.length - 1
        }
        // if (data.passed === true) {
        //   this.achievedTabs = JSON.parse(JSON.stringify(this.tabs))
        //   this.activeTab = this.tabs.length - 1
        // }
      })
    },
    checkStatus() {
      Registration.checkStatus(this.activeRegistrationId).then(({response: {data: data}}) => {
        if (data.passed === true) {
          this.achievedTabs.push('success')
          this.activeTab = this.tabs.length - 1
        } else {
          this.showRegretModal(data)
        }
      })
    },
    completeTab(name) {
      this.achievedTabs.push(name)
      if (this.tabs.findIndex(item => item === name) === this.tabs.length - 2) {
        this.checkStatus()
      } else {
        if (this.tabs.length > this.tabs.findIndex(item => item === name)) {
          this.achievedTabs.push(this.tabs[this.tabs.findIndex(item => item === name) + 1])
        }
        this.activeTab++

      }

    }
  },
  watch: {
    activeTab(newTab) {
      if (newTab === this.tabs.length - 1) {
        this.checkStatus()
      }
    }
  },
  async created() {
    this.loading = true
    await Promise.all([CourseLanguages.deleteAll(), CourseDocuments.deleteAll(), CourseCoreOption.deleteAll()])
    await Promise.all([Learner.fetchById(this.$store.state.userId),
      CourseDocuments.FetchAll(1, 999, this.activeRegistration.course.id),
      CourseLanguages.FetchAll(1, 999, this.activeRegistration.course.id),
      Course.fetchById(this.activeRegistration.course.id, ['coreOptions', 'coreOptions.supplements']),
    ])
    if (CourseLanguages.query().count() > 0) {
      this.hasCourseLanguages = true
    }
    await this.checkSkip()
    this.loading = false
  }
};
</script>
