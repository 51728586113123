<template>
  <div>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Failed Application Conditions</p>
      </header>
      <section class="modal-card-body">
        <p>We regret to inform you that you have failed to meet the following application conditions:</p>
        <b-field v-if="failedSubjects.length>0 && !passedAgeExemption" label="School Subjects">
          <b-table
            :bordered="true"
            :data="failedSubjects"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
          >
            <b-table-column v-slot="props"
                            field="name"
                            label="Name"
                            sortable
            >{{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason"
                            label="Reason"
                            sortable
            >{{ props.row.reason }}
            </b-table-column>
          </b-table>
        </b-field>
        <b-field label="Matric Exemption Type" v-if="!passedMatricExemptionType">
            <b-table
              :bordered="true"
              :data="conditions.conditions.matric"
              :header-checkable="false"
              :hoverable="true"
              :striped="true"
            >
              <b-table-column v-slot="props"
                              field="exemption_type"
                              label="Exemption Type"
                              sortable
              >{{ props.row.exemption_type }}
              </b-table-column>
              <b-table-column v-slot="props"
                              field="reason"
                              label="Reason"
                              sortable
              >{{ props.row.reason }}
              </b-table-column>
            </b-table>
        </b-field>
        <p>You can correct your data or your application will be declined.</p>
      </section>
      <footer class="modal-card-foot">
        <b-button type="is-primary" @click="$emit('close')">Would like to correct
        </b-button>
        <b-button type="is-danger" @click="regret()">Abandon application
        </b-button>
      </footer>
    </div>
  </div>
</template>

<script>

import Registration from "@/models/Registration";

export default {
  name: "FailedApplicationConditions",
  data() {
    return {
      stage: {}
    }
  },
  computed: {
    passedMatricExemptionType(){
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'matric')) {
        return this.conditions.conditions.matric[0].passed
      }
      return true
    },
    passedAgeExemption() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'age_exemption')) {
        return this.conditions.conditions.age_exemption[0].passed
      }
      return false
    },
    failedSubjects() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'school_subjects')) {
        return this.conditions.conditions.school_subjects.filter(subject => !subject.passed)
      }
      return []
    },
  },
  methods: {
    regret() {
      this.$store.dispatch('loader/show')
      let submitRegistration = Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()
      submitRegistration.registration_status_id = 9
      submitRegistration.stage_id = 101
      Registration.updateRemote(submitRegistration).then(() => {
        Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first().registration_status_id = 9
        this.$store.dispatch('loader/hide')
        this.$store.state.applications.activeRegistrationId = null
        this.$store.state.applications.hasRegistrationInProgress = false
        this.$store.state.applications.canSeeRegistrationsIndex = true
        this.$router.push({name: 'registrations.mine'})
        this.$emit('close')
      })
    }
  },
  props: {
    conditions: {
      type: Object,
      required: true
    },

  }
}
</script>

