import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'
import Store from "@/store";
Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    // redirect: {
    //   name: 'home'
    // },
    beforeEnter: (to, from,next) => {
      if (Store.state.isLearner===true){
       return next('/my-profile')
      }
      next()
    },
    name: 'home',
    component: Home,


  },
    {
      path: '/learners/create',
      name: 'learners.create',
      meta: {
        permissions: ['create learners']
      },
      component: () => import('./views/learners/Create.vue')
    },
    {
      path: '/learners',
      name: 'learners.index',
      component: () => import('./views/learners/Index.vue'),
      meta: {
        permissions: ['view learners'],
        allow: false
      }
    }, {
      path: '/learners/duplicates',
      name: 'learners.duplicates',
      component: () => import('./views/learners/Duplicates.vue'),
      meta: {
        permissions: ['view duplicate profiles'],
        allow: false
      }
    },
    {
      path: '/emails',
      name: 'emails.index',
      component: () => import('./views/emails/Index.vue'),
      meta: {
        allow: false,
        permission: ["view classlists"],

      }
    },

    {
      path: '/learners/edit/:id',
      props: true,
      name: 'learners.edit',
      component: () => import('./views/learners/Edit.vue'),
      meta: {
        permissions: ['view learners'],
        allow: false
      }
    },
    {
      path: '/users',
      name: 'users.index',
      meta: {
        permissions: ['administrate users'],
        allow: false
      },

      component: () => import('./views/users/Index.vue')
    },
    {
      path: '/users/create',
      name: 'users.create',
      meta: {
        permissions: ['create users'],
        allow: false
      },

      component: () => import('./views/users/Create.vue')
    },
    {
      path: '/users/edit/:id',
      props: true,
      name: 'users.edit',
      meta: {
        permissions: ['view users'],
        allow: false
      },

      component: () => import('./views/users/Edit.vue')
    },
    // {
    //   path: '/enquiries/edit/:id',
    //   name: 'enquiries.edit',
    //   props: true,
    //   meta: {
    //     permissions: ['view enquiries'],
    //     allow: false
    //   },
    //   component: () => import('./views/enquiries/Edit.vue')
    // },
    // {
    //   path: '/enquiries/create',
    //   name: 'enquiries.create',
    //   meta: {
    //     permissions: ['create enquiries'],
    //     allow: false
    //   },
    //   component: () => import('./views/enquiries/Create.vue')
    // },
    // {
    //   path: '/enquiries',
    //   name: 'enquiries.index',
    //
    //   component: () => import('./views/enquiries/Index.vue'),
    //   meta: {
    //     permissions: ['view enquiries'],
    //     allow: false
    //
    //   }
    // },
    {
      path: '/my-profile',
      name: 'my-profile',

      component: () => import('./views/learners/LearnerProfile.vue'),
      meta: {
        permissions: ['view frontend'],
        allow: 'false',
        forLearner: true,

      }
    },
    {
      path: '/application-support-documents',
      name: 'application-support-documents',

      component: () => import('./views/registrations/LearnerForms.vue'),
      meta: {
        permissions: ['view frontend'],
        allow: 'false'
      }
    },

    {
      path: '/registrations/learnerEdit/:id',
      name: 'registrations.learnerEdit',
      props: true,
      meta: {
        permissions: ['create registrations'],
        allow: false
      },
      component: () => import('./views/registrations/LearnerEdit.vue')
    },
    // {
    //   path: '/applications-home',
    //   name: 'applications-home',
    //   props: true,
    //   meta: {
    //     permissions: ['view frontend'],
    //     allow: false
    //   },
    //   component: () => import('./views/registrations/ApplicationsHome.vue')
    // },
    {
      path: '/registrations/create',
      name: 'registrations.create',
      meta: {
        permissions: ['create registrations'],
        allow: false
      },
      component: () => import('./views/registrations/Create.vue')
    },
    {
      path: '/registrations/mine',
      name: 'registrations.mine',
      meta: {
        permissions: ['view registrations','view frontend'],
        allow: false,
        forLearner: true,
      },
      component: () => import('./views/registrations/learner/Index')
    },
    {
      path: '/grades/mine',
      name: 'grades.mine',
      meta: {
        permissions: ['view registrations','view frontend'],
        allow: false,
        forLearner: true,
      },
      component: () => import('./views/gradebook/learner/Index')
    },
    {
      path: '/registrations/register',
      name: 'registrations.register',
      meta: {
        permissions: ['view registrations','view frontend'],
        allow: false,
        forLearner: true,
      },

      component: () => import('./views/registrations/learner/Process')
    },
    // {
    //   path: '/registrations/register',
    //   name: 'registrations.register',
    //   meta: {
    //     permissions: ['create registrations'],
    //     allow: false
    //   },
    //   component: () => import('./views/registrations/Register.vue')
    // },
    {
      path: '/registrations/edit/:id',
      name: 'registrations.edit',
      props: true,
      meta: {
        permissions: ['view registrations'],
        allow: false
      },
      component: () => import('./views/registrations/Edit.vue')
    },
    // {
    //   path: '/registrations/boards/on-boarding',
    //   name: 'registrations.on-boarding',
    //
    //   component: () => import('./views/registrations/boards/Onboarding.vue'),
    //   meta: {
    //     permissions: ['view on-boarding board'],
    //     allow: false
    //
    //   }
    // },
    {
      path: '/registrations/index',
      name: 'registrations.index',

      component: () => import('./views/registrations/Index.vue'),
      meta: {
        permissions: ['view registrations index'],
        allow: false

      }
    },
    {
      path: '/registrations/boards/registrations',
      name: 'registrations.registrations',

      component: () => import('./views/registrations/boards/Registrations.vue'),
      meta: {
        permissions: ['view registrations board'],
        allow: false

      }
    },
    // {
    //   path: '/registrations/boards/rpl-cat',
    //   name: 'registrations.rpl-cat',
    //
    //   component: () => import('./views/registrations/boards/RplCat.vue'),
    //   meta: {
    //     permissions: ['view rpl/cat board'],
    //     allow: false
    //
    //   }
    // }
    {
      path: '/registrations/boards/rpl-for-access',
      name: 'registrations.rpl-for-access',

      component: () => import('./views/registrations/boards/RPLForAccess.vue'),
      meta: {
        permissions: ['view rpl for access board'],
        allow: false

      }
    }, {
      path: '/registrations/boards/rpl-for-exemption',
      name: 'registrations.rpl-for-exemption',

      component: () => import('./views/registrations/boards/RPLForExemption.vue'),
      meta: {
        permissions: ['view rpl for exemption board'],
        allow: false

      }
    }, {
      path: '/registrations/boards/cat',
      name: 'registrations.cat',

      component: () => import('./views/registrations/boards/CAT.vue'),
      meta: {
        permissions: ['view cat board'],
        allow: false

      }
    },
    // {
    //   path: '/registrations/boards/student-advisors',
    //   name: 'registrations.student-advisors',
    //
    //   component: () => import('./views/registrations/boards/StudentAdvisors.vue'),
    //   meta: {
    //     permissions: ['view student advisors board'],
    //     allow: false
    //
    //   }
    // },
    // {
    //   path: '/registrations/boards/admissions-officers',
    //   name: 'registrations.admissions-officers',
    //
    //   component: () => import('./views/registrations/boards/AdmissionsOfficers.vue'),
    //   meta: {
    //     permissions: ['view admissions officers board'],
    //     allow: false
    //
    //   }
    // },
    {
      path: '/registrations/boards/graduated',
      name: 'registrations.graduated',

      component: () => import('./views/registrations/boards/Graduated.vue'),
      meta: {
        permissions: ['view graduated board'],
        allow: false

      }
    },
    // {
    //   path: '/registrations/boards/final-approval',
    //   name: 'registrations.final-approval',
    //
    //   component: () => import('./views/registrations/boards/FinalApproval'),
    //   meta: {
    //     permissions: ['view final approval of registration board'],
    //     allow: false
    //
    //   }
    // },
    {
      path: '/registrations/boards/finance-bursar',
      name: 'registrations.bpsych-applications',

      component: () => import('./views/registrations/boards/FinanceBursar'),
      meta: {
        permissions: ['view finance/bursar board'],
        allow: false

      },
    },
    {
      path: '/registrations/boards/dormant',
      name: 'registrations.dormant',

      component: () => import('./views/registrations/boards/Dormant'),
      meta: {
        permissions: ['view dormant board'],
        allow: false

      },
    },


    {
      path: '/register',
      name: 'register',
      component: () => import('./views/Register.vue'),
      meta: {
        allow: true,
        accessible: true
      }
    },
    {
      path: '/activities',
      name: 'activities.index',

      component: () => import('./views/activities/Index.vue'),
      meta: {
        permissions: ['view tasks'],
        allow: false

      }
    },
    {
      path: '/courses',
      name: 'courses.index',

      component: () => import('./views/courses/Index.vue'),
      meta: {
        permissions: ['view courses'],
        allow: false

      }
    },
    {
      path: '/courses/edit/:id',
      name: 'courses.edit',
      props: true,
      meta: {
        permissions: ['view courses'],
        allow: false
      },
      component: () => import('./views/courses/Edit.vue')
    },
    {
      path: '/courses/create/',
      name: 'courses.create',
      meta: {
        permissions: ['create courses'],
        allow: false
      },
      component: () => import('./views/courses/Create.vue')
    },
    {
      path: '/intakes',
      name: 'intakes.index',
      meta: {
        permissions: ['view courses'],
        allow: false
      },
      component: () => import('./views/intakes/Index.vue'),
    },
    {
      path: '/intakes/edit/:id',
      name: 'intakes.edit',
      props: true,
      meta: {
        permissions: ['view courses'],
        allow: false
      },
      component: () => import('./views/intakes/Edit.vue')
    },
    {
      path: '/intakes/create/',
      name: 'intakes.create',
      meta: {
        permissions: ['create courses'],
        allow: false
      },
      component: () => import('./views/intakes/Create.vue')
    },
    {
      path: '/semesters',
      name: 'semesters.index',

      component: () => import('./views/semesters/Index.vue'),
      meta: {
        permissions: ['view courses'],
        allow: false
      }
    },
    {
      path: '/semesters/edit/:id',
      name: 'semesters.edit',
      props: true,
      meta: {
        permissions: ['view courses'],
        allow: false
      },
      component: () => import('./views/semesters/Edit.vue')
    },
    {
      path: '/semesters/create/',
      name: 'semesters.create',
      meta: {
        permissions: ['create courses'],
        allow: false
      },
      component: () => import('./views/semesters/Create.vue')
    },
    {
      path: '/qualifications',
      name: 'qualifications.index',

      component: () => import('./views/qualifications/Index.vue'),
      meta: {
        permissions: ['view qualifications'],
        allow: false
      }
    },
    {
      path: '/qualifications/edit/:id',
      name: 'qualifications.edit',
      props: true,
      meta: {
        permissions: ['view qualifications'],
        allow: false
      },
      component: () => import('./views/qualifications/Edit.vue')
    },
    {
      path: '/qualifications/create/',
      name: 'qualifications.create',
      meta: {
        permissions: ['create qualifications'],
        allow: false
      },
      component: () => import('./views/qualifications/Create.vue'),
    },
    {
      path: '/modules',
      name: 'modules.index',
      component: () => import('./views/modules/Index.vue'),
      meta: {
        permissions: ['view modules'],
        allow: false

      }
    },
    {
      path: '/modules/edit/:id',
      name: 'modules.edit',
      props: true,
      meta: {
        permissions: ['view modules'],
        allow: false
      },
      component: () => import('./views/modules/Edit.vue')
    },
    {
      path: '/modules/create/',
      name: 'modules.create',
      meta: {
        permissions: ['create modules'],
        allow: false
      },
      component: () => import('./views/modules/Create.vue')
    },
    {
      path: '/bulk-send',
      name: 'bulk-send.filter',
      meta: {
        allow: false,
        permissions: ['view bulk-send'],

      },
      component: () => import('./views/bulk-send/Filters.vue')
    },
    {
      path: '/roles',
      name: 'roles.index',
      meta: {
        allow: false,
        permissions: ['view roles'],
      },
      component: () => import('./views/roles/Index.vue')
    },
    {
      path: '/roles/create',
      name: 'roles.create',
      meta: {
        allow: false,
        permissions: ['create roles'],
      },
      component: () => import('./views/roles/Create.vue')
    },
    {
      path: '/roles/edit/:id',
      name: 'roles.edit',
      props: true,
      meta: {
        allow: false,
        permissions: ['edit roles'],
      },
      component: () => import('./views/roles/Edit.vue')
    },
    {
      path: '/reports/',
      name: 'reports.index',
      redirect: {
        name: 'reports.enquiries-made'
      },
      meta: {
        permissions: ['view reports'],
        allow: false

      },
      component: () =>
        import(
          './views/reports/Index.vue'
          ),
      children: [

        {
          name: 'reports.enquiries-made',
          path: 'enquiries-made',
          meta: {
            permissions: ['view enquiries made report'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/EnquiryMade.vue'),
        },
        {
          name: 'reports.enquiries-won',
          path: 'enquiries-won',
          meta: {
            permissions: ['view reports'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/EnquiryWon.vue'),
        },
        {
          name: 'reports.enquiries-lost',
          path: 'enquiries-lost',
          meta: {
            permissions: ['view reports'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/EnquiryLost.vue'),
        },
        {
          name: 'reports.enquiries-sources',
          path: 'enquiries-sources',
          meta: {
            permissions: ['view reports'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/EnquiryBySource.vue'),
        },
        {
          name: 'reports.enquiries-stages',
          path: 'enquiries-stages',
          meta: {
            permissions: ['view reports'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/EnquiryByStage.vue'),
        },
        {
          name: 'reports.course-interest',
          path: 'course-interest',
          meta: {
            permissions: ['view course interest report'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/CourseInterest.vue'),
        },
        {
          name: 'reports.registrations-made',
          path: 'registrations-made',
          meta: {
            permissions: ['view registrations made report'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/RegistrationMade.vue'),
        },
        {
          name: 'reports.registrations-courses',
          path: 'registrations-courses',
          meta: {
            permissions: ['view registrations courses report'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/RegistrationMadeByCourse.vue'),
        }, {
          name: 'reports.registrations-sources',
          path: 'registrations-sources',
          meta: {
            permissions: ['view registrations sources report'],
            allow: false,
            highlight: 'reports.index'

          },
          component: () => import('./views/reports/RegistrationBySource.vue'),
        },
        {
          name: 'reports.registrations-stages',
          path: 'registrations-stages',
          meta: {
            permissions: ['view registrations stages report'],
            allow: false,
            highlight: 'reports.index'
          },
          component: () => import('./views/reports/RegistrationMadeByStage.vue'),
        },


      ]
    },
    {
      path: '/classlist/',
      name: 'classlist.index',
      redirect: {
        name: 'classlist.by-module'
      },
      meta: {
        permissions: ['view classlists'],
        allow: false
      },
      component: () =>
        import(
          './views/classlist/Index.vue'
          ),
      children: [
        //
        // {
        //   name: 'classlist.by-course',
        //   path: 'by-course',
        //   meta: {
        //     permissions: ['view by course classlists'],
        //     allow: false
        //   },
        //   component: () => import('./views/classlist/ByCourse.vue'),
        // },
        {
          name: 'classlist.by-module',
          path: 'by-module',
          meta: {
            permissions: ['view by module classlists'],
            allow: false
          },
          component: () => import('./views/classlist/ByModule.vue'),
        }


      ]
    },
    {
      path: '/schedule/',
      name: 'schedule.index',

      meta: {
        permissions: ['view schedule'],
        allow: false
      },
      component: () => import(
        './views/schedule/Index.vue'
        )
    },
    {
      path: '/gradebook/',
      name: 'gradebook.index',

      meta: {
        permissions: ['view grades'],
        allow: false
      },
      component: () => import(
        './views/gradebook/Index.vue'
        )
    },
    {
      path: '/exports/',
      name: 'exports.index',
      redirect: {
        name: 'exports.registrations'
      },
      meta: {
        permissions: ['view exports'],
        allow: false
      },
      component: () =>
        import(
          './views/exports/Index.vue'
          ),
      children: [

        {
          name: 'exports.edudex-staff',
          path: 'edudex-staff',
          meta: {
            permissions: ['view edudex staff export'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/EdudexStaff.vue'),
        }, {
          name: 'exports.edudex-persons',
          path: 'edudex-persons',
          meta: {
            permissions: ['view edudex persons export'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/EdudexPersons.vue'),
        }, {
          name: 'exports.edudex-students',
          path: 'edudex-students',
          meta: {
            permissions: ['view edudex student fte export'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/EdudexStudents.vue'),
        }, {
          name: 'exports.edudex-qualifications',
          path: 'edudex-qualifications',
          meta: {
            permissions: ['view edudex qualifications export'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/EdudexQualifications.vue'),
        }, {
          name: 'exports.registrations',
          path: 'registrations',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/Registrations.vue'),
        },
        {
          name: 'exports.registrations-by-course',
          path: 'registrations-by-course',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByCourse.vue'),
        }, {
          name: 'exports.registrations-by-location',
          path: 'registrations-by-location',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByLocation.vue'),
        },
        {
          name: 'exports.registrations-by-gender',
          path: 'registrations-by-gender',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByGender.vue'),
        },
        {
          name: 'exports.registrations-by-ethnicity',
          path: 'registrations-by-ethnicity',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByEthnicity.vue'),
        },
        {
          name: 'exports.registrations-by-nationality',
          path: 'registrations-by-nationality',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByNationality.vue'),
        },
        {
          name: 'exports.registrations-by-age',
          path: 'registrations-by-age',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsByAge.vue'),
        }, {
          name: 'exports.registrations-stage-changes',
          path: 'registrations-stage-changes',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsStageChanges.vue'),
        }, {
          name: 'exports.registrations-status-changes',
          path: 'registrations-status-changes',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/RegistrationsStatusChanges.vue'),
        },
        {
          name: 'exports.grade-statuses',
          path: 'grade-statuses',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/GradeStatuses.vue'),
        },
        {
          name: 'exports.learners',
          path: 'learners',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/Learners.vue'),
        },
        // {
        //   name: 'exports.enquiries',
        //   path: 'enquiries',
        //   meta: {
        //     permissions: ['view exports'],
        //     allow: false,
        //     highlight: 'exports.index'
        //
        //   },
        //   component: () => import('./views/exports/Enquiries.vue'),
        // },
        {
          name: 'exports.modules-success-rate',
          path: 'modules-success-rate',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/ModulesSuccessRate.vue'),
        },
        {
          name: 'exports.grades',
          path: 'grades',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/Grades.vue'),
        },
        {
          name: 'exports.new-learners-per-course',
          path: 'new-learners-per-course',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/NewLearnersPerCourse.vue'),
        },
        {
          name: 'exports.probation-candidates',
          path: 'probation-candidates',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/ProbationCandidates.vue'),
        },
        {
          name: 'exports.learner-success-rate',
          path: 'learner-success-rate',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/LearnerSuccessRate.vue'),
        },
        {
          name: 'exports.graduand-list',
          path: 'graduand-list',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/GraduandList.vue'),
        },
        {
          name: 'exports.bell-curve-semester',
          path: 'bell-curve-semester',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/BellCurveSemester.vue'),
        },
        {
          name: 'exports.class-size',
          path: 'class-size',
          meta: {
            permissions: ['view exports'],
            allow: false,
            highlight: 'exports.index'

          },
          component: () => import('./views/exports/ClassSize.vue'),
        },


      ]
    },
    {
      path: '/lookups/',
      name: 'lookups.index',
      redirect: {
        name: 'lookups.marketing-sources'
      },
      meta: {
        permissions: ['view lookups'],
        allow: false
      },
      component: () =>
        import(
          './views/lookups/Index.vue'
          ),
      children: [

        {
          name: 'lookups.marketing-sources',
          path: 'marketing-sources',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/MarketingSources.vue'),
        }, {
          name: 'lookups.programme-achievements',
          path: 'programme-achievements',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/ProgrammeAchievements.vue'),
        }, {
          name: 'lookups.programme-achievement-type',
          path: 'programme-achievement-type',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/ProgrammeAchievementType.vue'),
        }, {
          name: 'lookups.honours-achievements',
          path: 'honours-achievements',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/HonoursAchievements.vue'),
        }, {
          name: 'lookups.staff-categories',
          path: 'staff-categories',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/StaffCategories.vue'),
        }, {
          name: 'lookups.cesm-codes',
          path: 'cesm-codes',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/CESMCodes.vue'),
        }, {
          name: 'lookups.employment-status',
          path: 'employment-status',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/EmploymentStatuses.vue'),
        }, {
          name: 'lookups.appointment-type',
          path: 'appointment-type',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/AppointmentType.vue'),
        },
        {
          name: 'lookups.education-levels',
          path: 'education-levels',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/EducationLevels.vue'),
        }, {
          name: 'lookups.gender',
          path: 'gender',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Gender.vue'),
        }, {
          name: 'lookups.ethnicity',
          path: 'ethnicity',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Ethnicity.vue'),
        }, {
          name: 'lookups.identification-types',
          path: 'identification-types',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/IdentificationType.vue'),
        }, {
          name: 'lookups.nationality',
          path: 'nationality',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Nationality.vue'),
        }, {
          name: 'lookups.language',
          path: 'language',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Language.vue'),
        }, {
          name: 'lookups.subject',
          path: 'subject',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Subject.vue'),
        }, {
          name: 'lookups.rpl-reason',
          path: 'rpl-reason',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/RPLReason.vue'),
        }, {
          name: 'lookups.permit-type',
          path: 'permit-type',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/PermitType.vue'),
        }, {
          name: 'lookups.religion',
          path: 'religion',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/Religion.vue'),
        }, {
          name: 'lookups.marital-status',
          path: 'marital-status',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/MaritalStatus.vue'),
        }, {
          name: 'lookups.exemption-type',
          path: 'exemption-type',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/ExemptionType.vue'),
        }, {
          name: 'lookups.where-hear',
          path: 'where-hear',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/WhereHear.vue'),
        }, {
          name: 'lookups.payment-method',
          path: 'payment-method',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/PaymentMethod.vue'),
        }, {
          name: 'lookups.examination-authority',
          path: 'examination-authority',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/ExaminationAuthority.vue'),
        }, {
          name: 'lookups.terms',
          path: 'terms',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'
          },
          component: () => import('./views/lookups/Terms.vue'),
        },
        {
          name: 'lookups.qualifications',
          path: 'qualifications',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Qualifications.vue'),
        },

        {
          name: 'lookups.institutions',
          path: 'institutions',
          meta: {
            permissions: ['view lookups'],
            allow: false,
            highlight: 'lookups.index'

          },
          component: () => import('./views/lookups/Institutions.vue'),
        },
      ]
    },
    {
      path: '/apply',
      name: 'apply',
      meta: {
        allow: true,
      },
      component: () => import('@/views/registrations/learner/PublicApply'),

    },

    {
      path: '/full-page',
      component: () => import( /* webpackChunkName: "full-page" */ './views/FullPage.vue'),
      children: [{
        path: '/login',
        name: 'login',
        component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/Login.vue')
      }, {
        path: '/admin/login',
        name: 'admin-login',
        component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/AdminLogin.vue')
      },
        //   {
        //   path: '/start',
        //   name: 'start',
        //   component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/Start.vue')
        // },
        {
          path: '/password-recovery',
          name: 'password-recovery',

          component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/PasswordRecovery.vue')
        },
        {
          path: '/password-reset/:token',
          name: 'password-reset',
          props: true,

          component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/PasswordReset.vue')
        },
        {
          path: '/error-in-card',
          name: 'error-in-card',
          component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/Error.vue')
        },
        {
          path: '/error-simple',
          name: 'error-simple',
          component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/Error.vue'),
          props: {
            isInCard: false
          }
        },
        {
          path: '/lock-screen',
          name: 'lock-screen',
          component: () => import( /* webpackChunkName: "full-page" */ './views/full-page/LockScreen.vue'),
        }
      ]
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})
