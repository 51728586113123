<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form :class="{'section':!inModal}" class="is-relative" @submit.prevent="handleSubmit(submit)">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">
          {{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <b-tabs v-model="activeTab" :destroy-on-hide="true" class="block no-padding" expanded position="is-centered"
                  size="is-large" type="is-toggle">
            <b-tab-item class="py-5" label="Course Details">
              <b-field label="Qualification" horizontal>
                <b-field>
                  <b-autocomplete
                    v-model="qualificationHold"
                    v-debounce:300ms="getFilteredQualifications"
                    :clear-on-select="false"
                    :data="filteredQualifications"
                    :icon="$tc('icons.courses')"
                    :loading="loadingQualifications"
                    :placeholder="'Qualification Name'"
                    autocomplete="off"
                    field="name"
                    name="Qualification"
                    required
                    @blur="validateAutocomplete(courseObject.qualification_id,'qualificationHold')"
                    @select="option => selectQualification(option)"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.code }} | {{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>
                </b-field>
              </b-field>
              <b-field :horizontal="!inModal" label="Name">
                <b-input
                  v-model="courseObject.name"
                  autocomplete="off"
                  name="name"
                  placeholder="Name"
                  required
                />
              </b-field>
              <b-field :horizontal="!inModal" label="Long Name">

                <b-input
                  v-model="courseObject.long_name"
                  autocomplete="off"
                  name="long_name"
                  placeholder="Long Name"
                />
              </b-field>
              <b-field :horizontal="!inModal" label="CHE Code">
                <b-input
                  v-model="courseObject.che_code"
                  autocomplete="off"
                  name="che_code"
                  placeholder="CHE Code"
                />
              </b-field>
              <b-field :horizontal="!inModal" label="CESM Code">
                <b-select v-model="courseObject.che_cesm_code_id" placeholder="Select a code">
                  <option v-for="code in cesm_codes" :key="code.id" :value="code.id">{{ code.name }}</option>
                </b-select>
              </b-field>
              <b-field :horizontal="!inModal" label="SAQA Number">
                <b-input
                  v-model="courseObject.saqa_number"
                  autocomplete="off"
                  name="saqa_number"
                  placeholder="SAQA Number"
                />
              </b-field>
              <b-field :horizontal="!inModal" label="HEQCIS Number">
                <b-input
                  v-model="courseObject.heqcis_number"
                  autocomplete="off"
                  name="heqcis_number"
                  placeholder="HEQCIS Number"
                />
              </b-field>
              <b-field :horizontal="!inModal" label="Total Credits">
                <b-input
                  v-model="courseObject.total_credits"
                  autocomplete="off"
                  name="total_credits"
                  placeholder="Total Credits"
                  type="number"
                />
              </b-field>
              <b-field :horizontal="!inModal" :label="$tc('Course',1) + ' Status'">
                <b-select v-model="course_status" placeholder="Select a status" required>
                  <option value="1">Open</option>
                  <option value="0">Closed</option>
                </b-select>
              </b-field>
              <b-field :horizontal="!inModal" label="Has Custom Application Flow">
                <b-select v-model="courseObject.is_custom_flow" placeholder="Custom Application Flow" required>
                  <option :value="1">Yes</option>
                  <option :value="0">No</option>
                </b-select>
              </b-field>
              <b-field :horizontal="!inModal" v-if="courseObject.is_custom_flow===1" label="New Application Stage">
                <b-select v-model="courseObject.new_application_stage_id" placeholder="New Application Stage" required>
                  <optgroup :key="index+'board'" v-for="(board,index) in boards"
                            :label="board.name">

                    <option :key="stages.id+'application_stage'" v-for="stages in board.stages" :value="stages.id">{{ stages.name }}
                    </option>
                  </optgroup>
                </b-select>

              </b-field>
              <b-field :horizontal="!inModal" label="Delivery">
                <b-select v-model="delivery_type" placeholder="Select a delivery type" required>
                  <option value="1">Contact</option>
                  <option value="2">Online</option>
                  <option value="3">Multi-Modal</option>
                </b-select>
              </b-field>
              <b-field :horizontal="!inModal" label="Type">
                <b-select v-model="type" placeholder="Select a type" required>
                  <option value="1">Full {{ $tc('course', 1) }}</option>
                  <option value="2">Short {{ $tc('course', 1) }}</option>
                </b-select>
              </b-field>
              <template v-if="edit">
                <b-field :horizontal="!inModal" label="Add module">
                  <b-autocomplete
                    v-model="selectedModule"
                    :clear-on-select="true"
                    :data="filteredModules"
                    :icon="$tc('icons.modules')"
                    :loading="loadingModuleAdd"
                    autocomplete="off"
                    field="name"
                    name="module"
                    placeholder="Module name"
                    @select="option => selectModule(option)"
                    @typing="text=>getFilteredModule(text,modules)"
                  >
                    <template v-slot="props">
                      <p>{{ props.option.attributes.code }} | {{ props.option.attributes.name }}</p>
                    </template>
                  </b-autocomplete>
                </b-field>
                <b-field v-if="loaded" :horizontal="!inModal" label="Assigned modules">
                  <b-table
                    :bordered="true"
                    :data="modules"
                    :header-checkable="false"
                    :hoverable="true"
                    :striped="true"
                    class="margin-top"
                    default-sort="level"
                  >
                    <b-table-column v-slot="props"
                                    field="code"
                                    label="Code"
                                    numeric
                                    sortable
                                    width="40"
                    >{{ props.row.code }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="name" label="Name" sortable>{{
                        props.row.name
                      }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="level" label="Level (year)" sortable>{{
                        props.row.level
                      }}
                    </b-table-column>

                    <b-table-column v-slot="props" field="credits" label="Credits" numeric sortable>{{
                        props.row.credits
                      }}
                    </b-table-column>
                    <!-- <b-table-column v-slot="props" v-slot="props"  v-slot="props" field="credits" sortable label="Credits | Total:" numeric>
                      <template slot="header" slot-scope="{ column }">{{ column.label }} {{totalCredits}}</template>
                      {{ props.row.credits }}
                    </b-table-column>-->
                    <b-table-column v-slot="props" label="Pass/Fail" class="has-text-centered" custom-key="actions" width="120">
                      <b-checkbox
                        disabled
                        :value="props.row.is_pass_fail"
                        :true-value="1"
                        :false-value="0"
                      ></b-checkbox>
                      {{props.row.is_pass_fail}}
                    </b-table-column>

                    <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
                      <b-checkbox
                        :indeterminate="true"
                        :value="true"
                        @click.native.prevent="removeModule(props.row.id)"
                      ></b-checkbox>
                    </b-table-column>

                  </b-table>
                </b-field>
                <b-field :horizontal="!inModal" label="Curriculum Grid">
                  <SingleTagUpload :can-delete="true" :has-caption="false" :has-tag="false" :can-create="canCreate" :tags="['Curriculum Grid']" :tag="'Curriculum Grid'" ref="grid_upload" :title="'Grid Upload'"  :model="'courses'" :model_id="+courseObject.id" />
                </b-field>
              </template>
            </b-tab-item>

            <b-tab-item v-if="edit" class="py-5" label="Application Conditions">
              <div class="container">
                <div>
                  <h3 class="title">Student Registration Information</h3>
                </div>
                <p class="is-italic">This will appear as an information block when students have selected this programme</p>
                <b-field>
                  <b-input v-model="courseObject.student_info"
                   autocomplete="off"
                   name="student_info"
                   placeholder="Type Student Registration Information here"
                  >
                  </b-input>
                </b-field>
                <div>
                  <h3 class="title">Fees</h3>
                </div>
                <b-field :label="`Does this ${$tc('Course',1)} require an application fee?`">
                  <b-switch v-model="courseObject.needs_application_fee" :false-value="0" :true-value="1">
                    {{ courseObject.needs_application_fee === 1 ? 'Yes' : 'No' }}
                  </b-switch>
                </b-field>
                <template v-if="filtered_conditions.length>0">
                  <div>
                    <h3 class="title">New Conditions</h3>
                  </div>

                  <b-field class="py-5">
                    <b-select v-model="newCondition" :required=false placeholder="New Condition">
                      <option v-for="condition in filtered_conditions" :key="condition.id" :value="condition.id">
                        {{ condition.name }}
                      </option>
                    </b-select>
                    <p class="control">
                      <b-button :disabled="newCondition===null" type="is-primary" @click.prevent="addCondition">Add
                      </b-button>
                    </p>
                  </b-field>
                </template>
                <div class="py-5">
                  <h3 class="title">Current Conditions</h3>
                </div>
                <template v-if="courseObject.has_school_subject_requirements===1">
                  <p class="title is-4">School Subjects</p>
                  <p class="is-italic">The subjects required for programme acceptance</p>
                  <b-table :data="course_subjects" bordered hoverable striped>
                    <template>
                      <b-table-column v-slot="props" label="Name">
                        <b-field>
                          <b-select v-model="props.row.school_subject_id" :required=true expanded placeholder="Subject">
                            <option v-for="subject in subjects" :key="subject.id" :value="subject.id">
                              {{ subject.name }}
                            </option>
                          </b-select>
                        </b-field>
                      </b-table-column>
                      <b-table-column v-slot="props" label="Minimum Grade (%)">
                        <b-field>
                          <b-numberinput v-model="props.row.min_grade" :controls="false" max="100" min="0"
                                         placeholder="The minimum grade required" required/>
                        </b-field>
                      </b-table-column>
                      <b-table-column v-slot="props" label="Action">
                        <b-button type="is-danger" @click="startRemoveSubject(props.row.school_subject_id)">
                          Remove
                        </b-button>
                      </b-table-column>
                    </template>
                  </b-table>

                  <b-field>
                    <b-field grouped>
                      <p class="control">
                        <b-button
                          type="is-success"
                          @click.prevent="addSubject"
                        >Add subject
                        </b-button>
                      </p>
                      <p class="control">
                        <b-button type="is-danger" @click.prevent="startRemoveSubjects">
                          Remove all required subjects
                        </b-button>
                      </p>
                    </b-field>
                  </b-field>
                  <hr>
                </template>
                <template v-if="(add_documents===true)||(course_documents.length>0)">
                  <p class="title is-4">Required Uploads</p>
                  <p class="is-italic">The documents required for programme acceptance</p>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <b-dropdown v-model="course_documents" aria-role="list" max-height="400" multiple scrollable>
                          <b-button slot="trigger" :class="{'is-empty':course_documents.length===0}" class="select"
                                    expanded>
                            <span class="mr-5">Select Documents</span>

                          </b-button>

                          <b-dropdown-item
                            v-for="document in documents" :key="document.id" :value="document.id"
                            aria-role="listitem"
                          >
                            <span>{{ document.name }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-field>
                      <b-field>
                        <b-button type="is-danger" @click="startRemoveRequiredUploads">
                          Remove Required Uploads
                        </b-button>
                      </b-field>
                    </div>
                    <div class="column">
                      <ul class="ml-4" style="list-style-type: circle; list-style-position: outside">
                        <li v-for="document in activeCourseDocuments" :key="document.id">
                          {{ document.name }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <hr>

                </template>
                <template v-if="courseObject.has_age_exemption===1">
                  <p class="title is-4">Age Exemption</p>
                  <p class="is-italic">The age at which a student can be accepted to the programme and skip any subject
                    requirements</p>
                  <b-field>
                    <b-numberinput v-model="courseObject.age_exemption" :min="0" required step="1"/>
                  </b-field>
                  <b-field>
                    <b-button type="is-danger" @click="startRemoveAgeExemption">
                      Remove Age Exemption
                    </b-button>
                  </b-field>
                  <hr>
                </template>
                <template v-if="courseObject.has_custom_form===1">
                  <p class="title is-4">Custom Form</p>
                  <p class="is-italic">Select the applicable form to be completed for this programme</p>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <b-dropdown v-model="courseObject.custom_forms" aria-role="list" max-height="400" multiple
                                    scrollable>
                          <b-button slot="trigger" :class="{'is-empty':courseObject.custom_forms.length===0}"
                                    class="select" expanded>
                            <span class="mr-5">Select Form</span>

                          </b-button>
                          <b-dropdown-item
                            v-for="custom_form in custom_forms" :key="custom_form.id" :value="custom_form.id"
                            aria-role="listitem"
                          >
                            <span>{{ custom_form.name }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-field>
                      <b-field>
                        <b-button type="is-danger" @click="startRemoveCustomForms">
                          Remove Custom Forms
                        </b-button>
                      </b-field>
                    </div>
                    <div class="column">
                      <ul class="ml-4" style="list-style-type: circle; list-style-position: outside">
                        <li v-for="form in activeCustomForms" :key="form.id">
                          {{ form.name }}
                        </li>
                      </ul>
                    </div>
                  </div>

                </template>
                <template v-if="courseObject.has_matric_requirements===1">
                  <p class="title is-4">Matric Exemption Type</p>
                  <p class="is-italic">The acceptable matric certificate types for this programme</p>
                  <div class="columns">
                    <div class="column">
                      <b-field>
                        <b-dropdown v-model="course_exemption_types" aria-role="list" max-height="400" multiple
                                    scrollable>
                          <b-button slot="trigger" :class="{'is-empty':course_exemption_types.length===0}"
                                    class="select"
                                    expanded>
                            <span class="mr-5">Select Exemption Type</span>

                          </b-button>
                          <b-dropdown-item @click="course_exemption_types = exemption_types.map(type=>type.id)">All
                          </b-dropdown-item>
                          <b-dropdown-item
                            v-for="exemption_type in exemption_types" :key="exemption_type.id"
                            :value="exemption_type.id"
                            aria-role="listitem"
                          >
                            <span>{{ exemption_type.name }}</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </b-field>
                      <b-field>
                        <b-button type="is-danger" @click="startRemoveExemptionTypes">
                          Remove Exemption Types
                        </b-button>
                      </b-field>
                    </div>
                    <div class="column">
                      <ul class="ml-4" style="list-style-type: circle; list-style-position: outside">
                        <li v-for="type in activeExemptionTypes" :key="type.id">
                          {{ type.name }}
                        </li>
                      </ul>
                    </div>
                  </div>

                </template>
                <template v-if="(add_languages===true)||(course_languages.length>0)">
                  <p class="title is-4">{{ $tc('Course', 1) }} Languages</p>
                  <p class="is-italic">The available language proficiency options for this {{ $tc('course', 1) }}</p>
                  <ValidationProvider v-slot="{ errors }" :rules="'min'"
                                      :skipIfEmpty="false">
                    <b-field
                      :message="errors.length>0?errors[0]:''"
                      :type="errors.length>0?'is-danger':''"
                      label="Languages">
                      <b-taginput
                        v-model="course_languages"
                        :allow-duplicates="false"
                        :allow-new="false"
                        :data="filteredLanguages"
                        :open-on-focus="true"
                        autocomplete
                        field="name"
                        placeholder="Add a language"
                        @typing="getFilteredLanguages"
                        @keydown.enter.native.prevent>
                        <template v-slot="props">
                          <div class="is-flex">
                            <div>
                              <p>{{ props.option.name }}</p>
                            </div>
                          </div>
                        </template>
                      </b-taginput>
                    </b-field>
                  </ValidationProvider>
                  <b-field>
                    <b-button type="is-danger" @click="startRemoveLanguages">
                      Remove Language Requirement
                    </b-button>
                  </b-field>
                  <hr>

                </template>
                <template v-if="courseObject.has_core_options===1">
                  <p class="title is-4">{{ $tc('Course', 1) }} Specialisations</p>
                  <p class="is-italic">The available specialisations for this {{ $tc('course', 1) }}</p>
                  <ValidationProvider v-slot="{ errors }" :rules="`numeric|max_value:@Maximum Additional (Optional) Specialisations`"
                                      :skipIfEmpty="false" class="control"
                                      name="Minimum Additional (Optional) Specialisations">
                    <b-field :message="errors.length>0?errors[0]:''"
                             :type="errors.length>0?'is-danger':''"
                             label="Minimum Additional (Optional) Specialisations"

                    >
                      <b-input v-model="courseObject.min_extra_cores" min="0" name="min_extra_cores" placeholder="Minimum number of options"
                               required type="number"></b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" :rules="`numeric|min_value:@Minimum Additional (Optional) Specialisations`"
                                      :skipIfEmpty="false" class="control"
                                      name="Maximum Additional (Optional) Specialisations">
                    <b-field :message="errors.length>0?errors[0]:''"
                             :type="errors.length>0?'is-danger':''"
                             label="Maximum Additional (Optional) Specialisations"

                    >
                      <b-input v-model="courseObject.max_extra_cores" min="0" name="max_extra_cores" placeholder="Maximum number of options"
                               required type="number"></b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" :rules="`min_value_cores:@Minimum Additional (Optional) Specialisations`"
                                      :skipIfEmpty="false" class="control"
                                      name="Total Available Additional (Optional) Specialisations">
                    <b-field :message="errors.length>0?errors[0]:''"
                             :type="errors.length>0?'is-danger':''"
                             label="Total Available Additional (Optional) Specialisations"

                    >
                      <b-input :value="course_cores.filter(core=>{
                        return core.is_required===0
                      }).length" class="un-input" disabled></b-input>
                    </b-field>
                  </ValidationProvider>
                  <ValidationProvider v-slot="{ errors }" :rules="`min_value:1`"
                                      :skipIfEmpty="false" class="control"
                                      name="Total Specialisations">
                    <b-field
                      :message="errors.length>0?errors[0]:''"
                      :type="errors.length>0?'is-danger':''"
                      label="Total Specialisations"

                    >
                      <b-input :value="course_cores.length" class="un-input" disabled
                      ></b-input>
                    </b-field>
                  </ValidationProvider>
                  <b-field label="Specialisations">
                    <div class="columns is-mobile is-multiline">
                      <b-collapse v-for="(option,index) in course_cores" :key="index" class="card column is-12 mb-0">
                        <template #trigger="props">
                          <div
                            :open="openOption == index"
                            class="card-header"
                            role="button"
                            @open="openOption = index">
                            <p class="card-header-title">
                              {{ (option.core_option !== null && option.core_option !== '') ? option.core_option : 'Specialisation' }}
                            </p>
                            <a class="card-header-icon">
                              <b-icon
                                :icon="props.open ? 'menu-down' : 'menu-up'">
                              </b-icon>
                            </a>
                          </div>
                        </template>
                        <div class="card-content">
                          <b-field label="Name">
                            <b-input v-model="option.core_option" placeholder="Option Name" required>

                            </b-input>
                          </b-field>
                          <b-field label="Is this option required?">
                            <b-switch v-model="option.is_required" :false-value="0" :true-value="1">
                              {{ option.is_required === 1 ? 'Yes' : 'No' }}
                            </b-switch>
                          </b-field>
                          <b-field label="Are credits for this option required?">
                            <b-switch v-model="option.requires_credits" :false-value="0" :true-value="1">
                              {{ option.requires_credits === 1 ? 'Yes' : 'No' }}
                            </b-switch>
                          </b-field>
                          <b-field v-if="option.requires_credits===1" label="Minimum credits for this option">
                            <b-input v-model="option.credit" :required="option.requires_credits===1"
                                     min="0"
                                     placeholder="Minimum credits" type="Number"></b-input>
                          </b-field>
                          <b-field label="Is an NQF level required for this option?">
                            <b-switch v-model="option.requires_nqf_level" :false-value="0" :true-value="1">{{
                                option.requires_nqf_level === 1 ? 'Yes' : 'No'
                              }}
                            </b-switch>
                          </b-field>
                          <b-field v-if="option.requires_nqf_level===1" label="Minimum NQF level for this option">
                            <b-input v-model="option.minimum_nqf_level" :required="option.requires_nqf_level===1"
                                     min="0"
                                     placeholder="Minimum NQF level" type="Number"></b-input>
                          </b-field>
                          <b-field label="Provide supplementary modules for this option">
                            <b-switch v-model="option.provide_supplementary_modules" :false-value="0" :true-value="1">
                              {{ option.provide_supplementary_modules === 1 ? 'Yes' : 'No' }}
                            </b-switch>
                          </b-field>

                          <template v-if="option.provide_supplementary_modules===1">
                            <b-field :horizontal="!inModal" label="Add module">
                              <b-autocomplete
                                v-model="selectedModule"
                                v-debounce:300ms="text=>getFilteredModule(text,option.supplements)"
                                :clear-on-select="true"
                                :data="filteredModules"
                                :icon="$tc('icons.modules')"
                                :loading="loadingModuleAdd"
                                autocomplete="off"
                                field="name"
                                name="module"
                                placeholder="Module name"
                                @select="module => selectOptionModule(module,option.supplements)"
                              >
                                <template v-slot="props">
                                  <p>{{ props.option.attributes.code }} | {{ props.option.attributes.name }}</p>
                                </template>
                              </b-autocomplete>
                            </b-field>
                            <b-field label="Supplementary Modules">
                              <b-table
                                :bordered="true"
                                :data="option.supplements"
                                :header-checkable="false"
                                :hoverable="true"
                                :striped="true"
                                class="margin-top"
                                default-sort="level"
                              >
                                <template #empty>
                                  No Modules Selected
                                </template>
                                <b-table-column v-slot="props"
                                                field="code"
                                                label="Code"
                                                numeric
                                                sortable
                                                width="40"
                                >{{ props.row.code }}
                                </b-table-column>

                                <b-table-column v-slot="props" field="name" label="Name" sortable>{{
                                    props.row.name
                                  }}
                                </b-table-column>
                                <b-table-column v-slot="props" field="level" label="Level (year)" sortable>{{
                                    props.row.level
                                  }}
                                </b-table-column>

                                <b-table-column v-slot="props" field="credits" label="Credits" numeric sortable>{{
                                    props.row.credits
                                  }}
                                </b-table-column>
                                <!-- <b-table-column v-slot="props" v-slot="props"  v-slot="props" field="credits" sortable label="Credits | Total:" numeric>
                                  <template slot="header" slot-scope="{ column }">{{ column.label }} {{totalCredits}}</template>
                                  {{ props.row.credits }}
                                </b-table-column>-->

                                <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
                                  <b-field>
                                    <b-button type="is-danger"
                                              @click.native.prevent="removeOptionModule(props.row.id,index)">Remove
                                    </b-button>
                                  </b-field>

                                </b-table-column>
                              </b-table>
                            </b-field>
                          </template>
                          <b-field label="Allow applicants to enter in modules not on the whitelist">
                            <b-switch v-model="option.allow_non_listed_prior_modules" :false-value="0" :true-value="1">
                              {{ option.allow_non_listed_prior_modules === 1 ? 'Yes' : 'No' }}
                            </b-switch>
                          </b-field>


                          <ValidationProvider v-slot="{ errors }"
                                              :rules="option.allow_non_listed_prior_modules===0?'min':''"
                                              :skipIfEmpty="false">
                            <b-field
                              :message="errors.length>0?errors[0]:''"
                              :type="errors.length>0?'is-danger':''"
                              label="Whitelisted Modules">
                              <b-taginput
                                v-model="option.whitelisted_prior_modules"
                                :before-adding="val=>checkList(option.blacklisted_prior_modules,val)"
                                :remove-on-keys="[]"
                                aria-close-label="Delete this module"
                                ellipsis
                                icon="label"
                                placeholder="Add a module">
                              </b-taginput>

                            </b-field>
                          </ValidationProvider>

                          <b-field label="Blacklisted Modules">
                            <b-taginput
                              v-model="option.blacklisted_prior_modules"
                              :before-adding="val=>checkList(option.whitelisted_prior_modules,val)"
                              :remove-on-keys="[]"
                              aria-close-label="Delete this module"
                              ellipsis
                              icon="label"
                              placeholder="Add a module">
                            </b-taginput>
                          </b-field>
                        </div>
                        <div class="card-footer">
                          <div class="card-footer-item is-flex is-justify-content-end">
                            <b-field>
                              <b-button type="is-danger" @click="startRemoveCoreOption(index)">Remove</b-button>
                            </b-field>
                          </div>
                        </div>
                      </b-collapse>
                    </div>
                  </b-field>
                  <b-field grouped>
                    <b-field>
                      <b-button type="is-primary" @click="addCoreOption">
                        Add Option
                      </b-button>
                    </b-field>
                    <b-field>
                      <b-button type="is-danger" @click="startRemoveCoreOptions()">Remove Specialisations Condition</b-button>
                    </b-field>
                  </b-field>
                  <hr>

                </template>
                <template v-if="courseObject.has_qualification_requirement===1">
                  <p class="title is-4">Qualification Requirement</p>
                  <p class="is-italic">This {{ $tc('course', 1) }} will require the applicant to have a
                    qualification</p>
                  <b-field>
                    <b-button type="is-danger" @click="startRemoveQualificationRequirement">
                      Remove Qualification Requirement
                    </b-button>
                  </b-field>
                  <hr>

                </template>

<!--                <template v-if="edit">-->
<!--                  <p class="is-italic">Students that have been previously accepted for these programmes will also be accepted for this programme</p>-->

<!--                    <b-field :horizontal="!inModal" label="Add related programme">-->
<!--                    <b-autocomplete-->
<!--                        v-model="selectedRelatedCourse"-->
<!--                        :clear-on-select="true"-->
<!--                        :data="filteredRelatedCourses"-->
<!--                        :icon="$tc('icons.modules')"-->
<!--                        :loading="loadingRelatedCourseAdd"-->
<!--                        autocomplete="off"-->
<!--                        field="name"-->
<!--                        name="related_course"-->
<!--                        placeholder="Programme name"-->
<!--                        @select="option => addRelatedCourse(option)"-->
<!--                        @typing="text=>getfilteredRelatedCourses(text,[related_courses])"-->
<!--                    >-->
<!--                      <template v-slot="props">-->
<!--                        <p>{{ props.option.attributes.code }} | {{ props.option.attributes.name }}</p>-->
<!--                      </template>-->
<!--                    </b-autocomplete>-->
<!--                  </b-field>-->
<!--                  <b-field v-if="loaded" :horizontal="!inModal" label="Related programmes">-->
<!--                    <b-table-->
<!--                        :bordered="true"-->
<!--                        :data="related_courses"-->
<!--                        :header-checkable="false"-->
<!--                        :hoverable="true"-->
<!--                        :striped="true"-->
<!--                        class="margin-top"-->
<!--                        default-sort="level"-->
<!--                    >-->
<!--                      <b-table-column v-slot="props"-->
<!--                                      field="code"-->
<!--                                      label="Code"-->
<!--                                      numeric-->
<!--                                      sortable-->
<!--                                      width="40"-->
<!--                      >{{ props.row.code }}-->
<!--                      </b-table-column>-->

<!--                      <b-table-column v-slot="props" field="name" label="Name" sortable>{{-->
<!--                          props.row.name-->
<!--                        }}-->
<!--                      </b-table-column>-->

<!--                      <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">-->
<!--                        <b-checkbox-->
<!--                            :indeterminate="true"-->
<!--                            :value="true"-->
<!--                            @click.native.prevent="removeRelatedCourse(props.row.id)"-->
<!--                        ></b-checkbox>-->
<!--                      </b-table-column>-->
<!--                    </b-table>-->
<!--                  </b-field>-->
<!--                </template>-->

              </div>
            </b-tab-item>
            <b-tab-item v-if="edit" class="py-5" label="Intake Periods" >
              <card-component :header-icon="course_details_lock_icon"
                              title=Intakes
                              v-on:header-icon-click="toggleCourseIntakesLock()"
              >
                <b-field v-if="loaded"
                         :horizontal="!inModal"
                         label="Available Intakes"
                >
                  <b-table
                    :bordered="true"
                    :data="intakes"
                    :header-checkable="false"
                    :hoverable="true"
                    :striped="true"
                    class="margin-top"
                    default-sort="level"
                  >
                    <b-table-column v-slot="props" field="id" label="ID" sortable numeric width="40">{{
                        props.row.id
                      }}
                    </b-table-column>
                    <b-table-column v-slot="props" field="name" label="Name" sortable numeric width="40">{{
                        props.row.name
                      }}
                    </b-table-column>
                    <b-table-column v-slot="props" sortable field="start_date" label="Start Date" >{{ props.row.start_date }}</b-table-column>
                    <b-table-column v-slot="props" sortable field="end_date"  label="End Date" >{{ props.row.end_date }}</b-table-column>

                    <!-- <b-table-column v-slot="props" v-slot="props"  v-slot="props" field="credits" sortable label="Credits | Total:" numeric>
                      <template slot="header" slot-scope="{ column }">{{ column.label }} {{totalCredits}}</template>
                      {{ props.row.credits }}
                    </b-table-column>-->

                    <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
                      <b-checkbox
                        :native-value="props.row.id"
                        v-model="course_intakes"
                        :disabled="course_details_lock"
                      ></b-checkbox>
                    </b-table-column>
                  </b-table>
                </b-field>
              </card-component>
            </b-tab-item>
          </b-tabs>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :loading="loading" native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
              <p v-if="edit" class="control">
                <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                  Delete
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import Course from "@/models/Course";
import Lookup from "@/models/Lookup";
import ModuleCourse from "@/models/ModuleCourse";
import CourseRelatedCourse from "@/models/CourseRelatedCourse";
import Module from "@/models/Module";
import {mapState} from "vuex";
import CourseSubject from "@/models/CourseSubject";
import SchoolSubject from "@/models/SchoolSubject";
import Document from "@/models/Document";
import CourseDocuments from "@/models/CourseDocument";
import CourseExemptionType from "@/models/CourseExemptionType";
import CourseLanguages from "@/models/CourseLanguages";
import CourseCoreOption from "@/models/CourseCoreOption";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {max_value, min_value, numeric} from "vee-validate/dist/rules";
import RegistrationStage from "@/models/RegistrationStage";
import RegistrationBoard from "@/models/RegistrationBoard";
import CourseIntake from "@/models/CourseIntake";
import Intake from "@/models/Intake";
import SingleTagUpload from "@/components/media/SingleTagUpload";
import Qualification from "@/models/Qualification";
import CardComponent from "@/components/CardComponent";

extend('max_value', {
  ...max_value,
  ...{params: ['max'], message: 'Cannot be greater than {max}'}
});
extend('min_value', {
  ...min_value,
  ...{params: ['min'], message: 'Cannot be less than {min}'}
});
extend('min_value_cores', {
  ...min_value,
  ...{params: ['min'],
    message: 'The number of non-required specialisations cannot be less than {min}'}
});
extend('numeric', numeric)
extend("min", (value) => {
  if (value.length > 0) {
    return true;
  }
  return "You must enter at least one option";
});
export default {
  name: "CourseForm",
  components: {
    CardComponent,
    TitleBar,
    ValidationObserver,
    ValidationProvider,
    SingleTagUpload
  },
  data() {
    return {
      selectedModule: null,
      selectedRelatedCourse: null,
      filteredModules: [],
      filteredRelatedCourses: [],
      activeTab: 0,
      loadingRelatedCourseRemove: null,
      loadingModuleRemove: null,
      loadingModuleAdd: false,
      loadingRelatedCourseAdd: false,
      loadingModuleOptionAdd: false,
      loaded: false,
      loading: false,
      newCondition: null,
      course_exemption_types: [],
      course_subjects: [],
      course_documents: [],
      course_cores: [],
      course_languages: [],
      course_intakes: [],
      filteredLanguages: [],
      openOption: 0,
      add_documents: false,
      add_languages: false,
      qualification: null,
      loadingQualifications: false,
      qualificationHold: "",
      filteredQualifications: [],
      course_details_lock: true,
      courseObject: {
        name: null,
        long_name: null,
        che_code: null,
        saqa_number: null,
        min_extra_cores: 0,
        max_extra_cores: 0,
        heqcis_number: null,
        che_cesm_code_id: null,
        total_credits: null,
        is_open: 0,
        is_custom_flow: 0,
        new_application_stage_id: null,
        payment_pending_stage_id: 98,
        application_complete_stage_id: 100,
        delivery_type_id: 1,
        type_id: 1,
        has_school_subject_requirements: 0,
        needs_application_fee: 0,
        has_matric_requirements: 0,
        has_age_exemption: 0,
        has_custom_form: 0,
        has_core_options: 0,
        has_qualification_requirement: 0,
        custom_forms: [],
        qualification_id: null,
        student_info: null,
      },
    }
  },
  mounted() {
    this.qualificationHold =
      this.courseObject.qualification != null ? this.courseObject.qualification.name : "";

  },
  methods: {
    toggleCourseIntakesLock() {
      if (
        this.can_edit_course
      ) {
        this.course_details_lock = !this.course_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
    validateAutocomplete(source, target1, target2 = null) {
      if (source == null) {
        if (target2 !== null) {
          this[target1][target2] = null
        } else {
          this[target1] = null
        }
      }
    },
    selectQualification(qualification) {
      if (qualification) {
        this.courseObject.qualification_id = qualification.id;
        this.qualification = qualification;
        this.courseObject.qualification = qualification;
        this.loadingQualifications = true;

        Qualification.fetchById(qualification.id)
          .then(() => {
            this.qualification = Course.query()
              .whereId(qualification.id)
              .get()[0];
          }).then(() => {
              this.loadingCourses = false;
            })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      }
    },
    getFilteredQualifications(text) {
      this.loadingQualifications = true;
      Qualification.search(text, true, 1).then((result) => {
        this.loadingQualifications = false;
        if (result.entities) {
          this.filteredQualifications = result.entities.qualifications;
        } else {
          this.filteredQualifications = [];
        }
      });
    },
    selectOptionModule(module, target) {
      if (module) {
        if (!target.some(item => item.id == module.id)) {
          target.push(Object.assign(module, module.attributes))
          this.selectedModule = null
        }
      }
    },
    checkList(targetList, newItem) {
      return !targetList.includes(newItem)
    },
    addCoreOption() {
      this.course_cores.push({
        course_id: this.courseObject.id,
        core_option: null,
        requires_credits: 0,
        is_required: 0,
        requires_nqf_level: 0,
        provide_supplementary_modules: 0,
        allow_non_listed_prior_modules: 0,
        credit: null,
        minimum_nqf_level: null,
        whitelisted_prior_modules: [],
        blacklisted_prior_modules: [],
        supplements: [],
        supplement_module_ids: []
      })

      this.openOption = this.course_cores.length - 1
    },
    getFilteredLanguages(text) {
      if (Lookup.query().where('lookup_type_id', 5).where('name', text).exists()) {
        this.filteredLanguages = Lookup.query().where('lookup_type_id', 5).where('name', text).get()
        return
      }
      this.filteredLanguages = Lookup.query().where('lookup_type_id', 5)
        .search(text, {
          caseSensitive: false,
          threshold: 0.3,
          keys: ['name'],
          tokenize: true,
          findAllMatches: true,
          shouldSort: true
        }).get()
    },
    addCondition() {

      if (this.newCondition === 1) {
        this.courseObject.has_school_subject_requirements = 1
      }
      if (this.newCondition === 2) {
        this.add_documents = true
      }
      if (this.newCondition === 3) {
        this.courseObject.has_age_exemption = 1
      }

      if (this.newCondition === 4) {
        this.courseObject.has_matric_requirements = 1
      }
      if (this.newCondition === 5) {
        this.add_languages = true
      }
      if (this.newCondition === 6) {
        this.courseObject.has_core_options = 1
      }
      if (this.newCondition === 7) {
        this.courseObject.has_qualification_requirement = 1
      }
      this.newCondition = null
    },
    getFilteredModule(text, existingModules = []) {
      this.loadingModuleAdd = true;

      Module.search(text, true, 1, 45, null, null).then((result) => {
        this.loadingModuleAdd = false;

        this.filteredModules = result.response.data.data.filter(
          (module) => !existingModules.some((item) => item.id === module.id)
        );
      });
    },
    getfilteredRelatedCourses(text, existingRelatedCourses = []) {
      this.loadingRelatedCourseAdd = true;

      Course.search(text, true, 0, 1, 100, null).then((result) => {
        this.loadingRelatedCourseAdd = false;

        this.filteredRelatedCourses = result.response.data.data.filter(
          (course) => !existingRelatedCourses.some((item) => item.id === course.id)
        );
      });
    },
    removeOptionModule(moduleId, optionIndex) {
      let list = this.course_cores[optionIndex].supplements
      this.course_cores[optionIndex].supplements = list.filter(item => {
        return parseInt(item.id) !== parseInt(moduleId)
      })
    },
    removeModule(moduleId) {
      this.loading = true
      this.loadingModuleRemove = moduleId;
      Course.toggleModule(this.courseObject.id, moduleId).then(() => {
        ModuleCourse.delete([moduleId, this.courseObject.id]);
        this.loadingModuleRemove = moduleId;
        this.loading = false
      });
    },
    removeRelatedCourse(courseId) {
      this.loading = true
      this.loadingRelatedCourseRemove = courseId;
      Course.toggleRelatedCourse(this.courseObject.id, courseId).then(() => {
        CourseRelatedCourse.delete([courseId, this.courseObject.id]);
        this.loadingRelatedCourseRemove = courseId;
        this.loading = false
      });
    },
    selectModule(module) {
      if (module) {
        this.loading = true
        this.loadingModuleAdd = true;
        Course.toggleModule(this.courseObject.id, module.id).then(() => {
          Course.insertOrUpdate({
            data: {
              id: this.courseObject.id,
              modules: [module],
            },
          });
          this.loadingModuleAdd = false;
          this.loading = false
        });
      }
    }, addRelatedCourse(course) {
      if (course) {
        this.loading = true
        this.loadingRelatedCourseAdd = true;

          Course.insertOrUpdate({
            data: {
              id: this.courseObject.id,
              related_courses: [course],
            },
          });
          this.loadingRelatedCourseAdd = false;
          this.loading = false
      }
    },
    addSubject() {
      this.course_subjects.push({
        school_subject_id: null,
        min_grade: null
      })
    },
    startRemoveCoreOption(optionIndex) {
      this.$buefy.dialog.confirm({
        title: `Removing Option`,
        confirmText: `Remove Option`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this option?`,
        onConfirm: () => {
          this.course_cores = this.course_cores.filter((option, index) => {
            return index !== optionIndex
          })

        }
      })
    }, startRemoveCoreOptions() {
      this.$buefy.dialog.confirm({
        title: `Removing Specialisations Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.course_cores = []
          this.courseObject.has_core_options = 0

        }
      })
    },
    startRemoveLanguages() {
      this.$buefy.dialog.confirm({
        title: `Removing Languages Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.course_languages = []
          this.add_languages = null
        }
      })
    },
    startRemoveQualificationRequirement() {
      this.$buefy.dialog.confirm({
        title: `Removing Qualification Requirement Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.courseObject.has_qualification_requirement = 0
        }
      })
    },
    startRemoveRequiredUploads() {
      this.$buefy.dialog.confirm({
        title: `Removing Required Uploads Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.course_documents = []
          this.add_documents = null
        }
      })
    },
    startRemoveExemptionTypes() {
      this.$buefy.dialog.confirm({
        title: `Removing Matric Exemption Types Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.courseObject.has_matric_requirements = 0

        }
      })
    },
    startRemoveCustomForms() {
      this.$buefy.dialog.confirm({
        title: `Removing Custom Form Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.courseObject.has_custom_form = 0

        }
      })
    },
    startRemoveSubjects() {
      this.$buefy.dialog.confirm({
        title: `Removing Subjects Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => {
          this.courseObject.has_school_subject_requirements = 0
          this.course_subjects = []
        }
      })
    },
    startRemoveSubject(subject_id) {
      this.$buefy.dialog.confirm({
        title: `Deleting Subject`,
        confirmText: `Delete Subject`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this subject?`,
        onConfirm: () => this.course_subjects = this.course_subjects.filter(subject => subject.school_subject_id !== subject_id)
      })
    }, startRemoveAgeExemption() {
      this.$buefy.dialog.confirm({
        title: `Remove Age Exemption Condition`,
        confirmText: `Remove Condition`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this condition?`,
        onConfirm: () => this.courseObject.has_age_exemption = 0
      })
    },
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting ${this.$tc('Course', 1)}`,
        confirmText: `Delete ${this.$tc('Course', 1)}`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this ${this.$tc('course', 1)}?`,
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      Course.Delete(this.courseObject.id).then(() => {
        this.$buefy.snackbar.open(`${this.$tc('Course', 1)} deleted!`);
        this.$emit("close");
        this.$emit("deleted");

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
        if (!this.inModal) {
          this.$router.push('/courses')
        }
      });

    },
    submit() {
      this.loading = true
      if (this.edit) {
        if (!this.canEdit) {
          this.$store.dispatch('toast/createToast')
          return
        }
        if (this.courseObject.has_school_subject_requirements === 1) {
          if (this.course_subjects.length === 0) {
            this.courseObject.has_school_subject_requirements = 0
          } else {
            CourseSubject.Update({course_id: this.courseObject.id, school_subjects: this.course_subjects})

          }
        }
        if (this.courseObject.has_custom_form === 1) {
          if (this.courseObject.custom_forms.length === 0) {
            this.courseObject.has_custom_form = 0
          }
        }

        if (this.courseObject.has_matric_requirements === 1) {
          if (this.course_exemption_types.length === 0) {
            this.courseObject.has_matric_requirements = 0
          } else {
            CourseExemptionType.Update({course_id: this.courseObject.id, exemption_types: this.course_exemption_types})

          }
        }
        CourseDocuments.Update({course_id: this.courseObject.id, documents: this.course_documents})
        CourseLanguages.Update({
          course_id: this.courseObject.id,
          languages: this.course_languages.map(language => language.id)
        })
        this.course_cores.map(core => {
          core.supplement_module_ids = core.supplements.map(item => item.id)
        })

        CourseIntake.Update({course_id: this.courseObject.id, intakes: this.course_intakes})
        CourseCoreOption.Update({course_id: this.courseObject.id, core_option: this.course_cores})
        this.courseObject.intakes = this.course_intakes;
        Course.Update(this.courseObject, true).then(() => {
          this.$nextTick(()=>{
            this.$refs['grid_upload'].submitMedia().finally(()=>{
              this.$buefy.snackbar.open(`${this.$tc('Course', 1)} updated!`)
              this.loading = false
            })
          })
          this.$buefy.snackbar.open(`${this.$tc('Course', 1)} updated!`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        if (!this.canCreate) {
          this.$store.dispatch('toast/createToast')
          return;
        }
        Course.Store(this.courseObject).then(({entities: {courses}}) => {
          this.$buefy.snackbar.open(`${this.$tc('Course', 1)} created!`)
          this.courseObject.id = courses[0].id
          this.$nextTick(()=>{
            this.$refs['grid_upload'].submitMedia().finally(()=>{
              this.$router.push(`/courses/edit/${courses[0].id}`)
              this.loading = false
            })
          })


        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },

  computed: {
    can_edit_course() {
      return this.$store.getters["entities/permissions/find"](
        "edit courses"
      );
    },
    qualificationName() {
      if (this.qualification) {
        return this.qualification.name;
      }
      return "No Qualification";
    },
    boards() {
      return RegistrationBoard.query().where('name',(name)=>(name!=='Deleted')&&(name!=='In Transit')).orderBy('order').with('stages').get()
    },
    intakes(){
      return Intake.query().orderBy('start_date', 'desc').orderBy('end_date', 'desc').get()
    },
    cores() {
      return CourseCoreOption.query().get()
    },
    filtered_conditions() {
      return this.available_conditions.filter((condition) => {
        if (condition.id === 1 && this.courseObject.has_school_subject_requirements === 1) {
          return false
        }
        if (condition.id === 2 && (this.add_documents || this.course_documents.length > 0)) {
          return false
        }
        if (condition.id === 3 && this.courseObject.has_age_exemption === 1) {
          return false
        }
        if (condition.id === 4 && this.courseObject.has_matric_requirements === 1) {
          return false
        }
        if (condition.id === 5 && (this.add_languages || this.course_languages.length > 0)) {
          return false
        }
        if (condition.id === 6 && this.courseObject.has_core_options === 1) {
          return false
        }
        if (condition.id === 7 && this.courseObject.has_qualification_requirement === 1) {
          return false
        }
        return true
      })
    },
    activeCourseDocuments() {
      return Document.query().whereIdIn(this.course_documents).get()
    },

    activeExemptionTypes() {
      return Lookup.query().where('lookup_type_id', 13).whereIdIn(this.course_exemption_types).get()
    },
    activeCustomForms() {
      return this.custom_forms.filter(form => this.courseObject.custom_forms.includes(form.id))
    },
    exemption_types() {
      return Lookup.query().where('lookup_type_id', 13).get()
    },
    languages() {
      return Lookup.query().where('lookup_type_id', 5).orderBy('name').get()
    },
    documents() {
      return Document.query().where('name', (value) => {
        return this.$store.state.applications.conditionTags.includes(value)
      }).get()
    }, subjects() {
      return SchoolSubject.query().get()
    },
    ...mapState('conditions', ['available_conditions', 'custom_forms']),
    cesm_codes() {
      return Lookup.query().where('lookup_type_id', 23).get()
    },

    modules() {
      if (this.edit) {
        return Course.query().whereId(this.id).with("modules").get()[0].modules;
      }
      return []
    }, related_courses() {
      if (this.edit) {
        return Course.query().whereId(this.id).with("related_courses").get()[0].related_courses;
      }
      return []
    },
    course_status: {
      get() {
        return this.courseObject.is_open.toString();
      },
      set(newValue) {
        this.courseObject.is_open = parseInt(newValue);
      },
    },
    delivery_type: {
      get() {
        return this.courseObject.delivery_type_id.toString();
      },
      set(newValue) {
        this.courseObject.delivery_type_id = parseInt(newValue);
      },
    },
    type: {
      get() {
        return this.courseObject.type_id.toString();
      },
      set(newValue) {
        this.courseObject.type_id = parseInt(newValue);
      },
    },
    course_details_lock_icon() {
      if (this.course_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
  },
  async created() {
    this.loading = true
    Lookup.fetchAll({lookup_type_id: [13, 23, 5]})
    if (this.edit) {
      await Promise.all([CourseSubject.deleteAll(),
        CourseDocuments.deleteAll(),
        CourseLanguages.deleteAll(),
        CourseCoreOption.deleteAll()])
      await Promise.all([
        CourseDocuments.FetchAll(1, 999, parseInt(this.id)),
        CourseLanguages.FetchAll(1, 999, parseInt(this.id)),
        SchoolSubject.FetchAll(),
        Document.FetchAll(),
        CourseSubject.FetchAll(1, 999, parseInt(this.id)),
        CourseExemptionType.FetchAll(1, 999, parseInt(this.id)),
        RegistrationStage.fetchAll(),
        CourseIntake.FetchAll(parseInt(this.id)),
        Intake.FetchAll({page:1,limit:999})
      ])

      Course.fetchById(this.id)
        .then(() => {
          this.courseObject = Course.query().whereId(this.id).with("modules").with("qualification").first();
          this.qualification = this.courseObject.qualification_id != null ? this.courseObject.qualification : null;
          this.qualificationHold = this.courseObject.qualification_id != null ? this.courseObject.qualification.name : "";
          this.course_subjects = CourseSubject.query().get()
          this.course_documents = CourseDocuments.query().get().map(document => document.id)
          this.course_languages = CourseLanguages.query().get()
          this.course_exemption_types = CourseExemptionType.query().get().map(type => type.id)
          this.course_cores = CourseCoreOption.query().withAll().get()
          this.course_intakes = CourseIntake.query().get();
          if (this.course_intakes.length > 0) {
            this.course_intakes = this.course_intakes.map(intake=>intake.id)
          }
          this.$store.dispatch("loader/hide");
          this.loaded = true;
          this.loading = false
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.loading = false

          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    } else {
      this.loading = false
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

