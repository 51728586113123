<template>
  <div>
    <section class="section padding-top">
      <b-field>
        <b-radio-button v-model="chartType" native-value="bar">
          <span>By {{$tc('Course',1)}} name</span>
        </b-radio-button>

        <!-- <b-radio-button v-model="chartType" native-value="line">
          <span>Over time</span>
        </b-radio-button>-->
      </b-field>
      <div class="columns">
        <div class="column">
          <b-field label="Select start date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMin"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Select end date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMax"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
      </div>
      <b-field grouped group-multiline>
        <b-field label="Year">
          <b-select expanded v-model="$store.state.dates.year" placeholder="Filter by year">
            <option :value="null">All</option>

            <option
              v-for="number in 4"
              :key="number + 'year'"
              :value="(new Date().getFullYear()-3)+number"
            >{{(new Date().getFullYear()-3)+number}}</option>
          </b-select>
        </b-field>
        <b-field label="Semester">
          <b-select
            expanded
            v-model="$store.state.dates.semester_id"
            placeholder="Filter by semester"
          >
            <option :value="null">All</option>

            <option v-for="semester in semesters" :key="semester.id" :value="semester.id">
              <span>{{semester.name}}</span>
            </option>
          </b-select>
        </b-field>
        <b-field label="Actions">
          <b-field>
            <b-button icon-right="filter" @click="filter()">Filter</b-button>
            <b-field grouped class="ml-6">
              <b-button
                icon-right="download"
                :disabled="download_link==null"
                tag="a"
                :href="download_link"
                target="_blank"
              >Download</b-button>
              <b-button icon-right="close" @click="clear()">Clear</b-button>
            </b-field>
          </b-field>
        </b-field>
      </b-field>
      <div class="columns is-centered">
        <div class="column">
          <card-component :title="title" icon="finance" header-icon="reload">
            <div class="chart-area" v-if="chartType == 'line'">
              <line-chart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="lineChart.chartData"
                :options="lineOptions"
              ></line-chart>
            </div>
            <div class="chart-area" v-if="chartType == 'bar'">
              <bar-chart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-bar-chart"
                :chart-data="barChart.chartData"
                :options="barOptions"
              ></bar-chart>
            </div>
          </card-component>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import BarChart from "@/components/Charts/BarChart";
import LineChart from "@/components/Charts/LineChart";

import Semester from "@/models/Semester";
import CourseInterest from "@/models/reports/CourseInterest";
// import CourseInterestOverTime from "@/models/reports/CourseInterestOverTime";

export default {
  name: "course-interest",
  components: {
    CardComponent,
    LineChart,
    BarChart,
  },
  data() {
    return {
      year: null,
      semester_id: null,
      download_link: null,

      lineOptions: {
        legend: { display: true },
        maintainAspectRatio: false,
        spanGaps: true,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              type: "time",
              ticks: {
                source: "data",
                autoSkip: true,
                maxTicksLimit: 20,
              },
              time: {
                distribution: "linear",
                isoWeekday: true,
                unit: "week",
                min: this.$store.state.dates.dateMin,
                max: this.$store.state.dates.dateMax,
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "value",
              },
            },
          ],
        },
      },
      chartType: "bar",
      dataLoaded: false,
      title: "Course interest",
      datasets: { by_week: [] },

      barChart: {
        chartData: {
          datasets: [],
        },
      },
      lineChart: {
        chartData: {
          datasets: [],
        },
      },
    };
  },
  watch: {
    chartType() {
      this.filter();
    },
  },
  computed: {
    semesters() {
      return Semester.all();
    },
    barOptions() {
      return {
        maintainAspectRatio: false,

        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
  },
  mounted() {
    if (this.$route.query.end) {
      this.$store.state.dates.dateMin = new Date(this.$route.query.start);
    }
    if (this.$route.query.end) {
      this.$store.state.dates.dateMax = new Date(this.$route.query.end);
    }
    Semester.FetchAll();
    this.$store.dispatch("loader/show");
    CourseInterest.fetchAll(
      this.$store.getters["dates/formattedDateMin"],
      this.$store.getters["dates/formattedDateMax"],
      true,
      this.$store.state.dates.year,
      this.$store.state.dates.semester_id
    ).then((result) => {
      this.download_link = result.response.data.exportUrl;

      this.processBar(CourseInterest.all());
      this.dataLoaded = true;
      this.$store.dispatch("loader/hide");
    });
  },

  methods: {
    clear() {
      this.$store.dispatch("loader/show");
      this.$store.state.dates.dateMin = null;
      this.$store.state.dates.dateMax = null;
      this.$store.state.dates.year = null;
      this.$store.state.dates.semester_id = null;
      CourseInterest.deleteAll();

      CourseInterest.fetchAll(
        this.$store.getters["dates/formattedDateMin"],
        this.$store.getters["dates/formattedDateMax"],
        true,
        this.$store.state.dates.year,
        this.$store.state.dates.semester_id
      )
        .then((result) => {
          this.download_link = result.response.data.exportUrl;

          this.processBar(CourseInterest.all());
          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    filter() {
      this.$store.dispatch("loader/show");
      this.dataLoaded = false;
      if (this.chartType == "bar") {
        CourseInterest.deleteAll();
        CourseInterest.fetchAll(
          this.$store.getters["dates/formattedDateMin"],
          this.$store.getters["dates/formattedDateMax"],
          true,
          this.$store.state.dates.year,
          this.$store.state.dates.semester_id
        ).then((result) => {
          this.download_link = result.response.data.exportUrl;

          this.processBar(CourseInterest.all());
          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        });
      }
    },
    oneWeekAgo() {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return oneWeekAgo;
    },
    processBar(reports) {
      let data = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: this.$store.state.chart.default_colors,
            backgroundColor: this.$store.state.chart.default_colors,
          },
        ],
      };

      for (let item of reports) {
        data.labels.push(item.course_name);
        data.datasets[0].data.push(item.enquiries_created);
      }
      this.barChart.chartData = data;
      this.dataLoaded = true;
    },
  },
};
</script>
