export const ConditionsModule = {
  namespaced: true,

  state: {
    available_conditions: [
      {id: 1, name: 'Subject Marks'},
      {id: 2, name: 'Required Uploads'},
      {id: 3, name: 'Age Exemption'},
      {id: 4, name: 'Matric Exemption Type'},
      {id: 5, name: 'Languages'},
      {id: 6, name: 'Core Subjects'},
      {id: 7, name: 'Qualifications Requirement'},
    ],
    custom_forms: [
      {id: 1, name: 'PGCE IP'},
      {id: 2, name: 'PGCE FP'},

    ],
  }
};
