<template>
  <section class="section is-relative">
    <div v-if="processed && !learner?.is_suspended" class="container">
      <title-bar><span v-if="activeRegistrationId!==null">{{ activeRegistration.course.name }} - </span>{{ activeStep.title }}</title-bar>
      <b-tabs v-model="activeStep.tab" :destroy-on-hide="true" class="hidden-tabs p-0" expanded multiline
      >
        <!--        <b-tab-item v-if="useTab('wards')">-->
        <!--          <div class=" columns">-->
        <!--            <div class="column is-12">-->
        <!--              <WardsIndex-->
        <!--                  :user_id="$store.state.user.id"-->
        <!--                  @selected="handleStudentSelect"-->
        <!--              ></WardsIndex>-->
        <!--            </div>-->
        <!--          </div>-->
        <!--        </b-tab-item>-->
        <b-tab-item label="Demographic Form">
          <LearnerDetailsForm :id="$store.state.userId" :can-delete="false" :can-edit="true" :latest-registration="registrations.length ? registrations[registrations.length - 1] : {}" edit in-registration @success="updateDetails" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Programme Selection">
          <RegistrationCourseForm :id="$store.state.userId" @success="selectRegistration" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Application Conditions">
          <ApplicationConditionsPanel @success="PostConditionalRedirect" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Application Fee Payment">
          <RegistrationPaymentPanel
            :copy="`
<p class='is-size-5 pb-2'>Please make use of our payment portal for immediate clearance of funds. The payment portal allows you to make secure payments via Debit Card and Credit Card. This allows you to proceed with the application immediately.</p>
<p class='is-size-5 pb-2'>Please note, if you prefer to make direct EFTs, this is processed manually, and as a result there will be a waiting period.</p>`"
            :eft_details="`
<p class='is-size-5 pb-2'>Email your EFT proof of payment to <a href='mailto:bursar@cornerstone.ac.za'>bursar@cornerstone.ac.za</a></p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Name</span>: Cornerstone Institute (RF) NPC</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Bank</span>: Nedbank</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Branch Code</span>: 102 510</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Number</span>: 107 503 0269</p>
<p class='is-size-5 pb-2'><span class='is-italic'>International Swift No</span>: NEDSZAJJ</p>`"
            :invoice_type="1" :success_copy="'You have paid your application fee. You can now proceed to document verification.'" :success_title="'Application Fee Paid Successfully'"
            :title="'Application Fee Payment: R330'"
            @success="SetToPendingVerification" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Module Selection">
          <RegistrationModulesPanel :learner_id="$store.state.userId" :registration_id="activeRegistrationId" @success="setToApplicationComplete" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Document Verification">
          <RegistrationPendingPanel :learner_id="$store.state.userId" :registration_id="activeRegistrationId" @success="SetToPendingVerification" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Initial Fee Payment">
          <RegistrationPaymentPanel :copy="`
<p class='is-size-5 pb-2'>Please make use of our payment portal for immediate clearance of funds. The payment portal allows you to make secure payments via Debit Card and Credit Card. This allows you to proceed with the application immediately.</p>
<p class='is-size-5 pb-2'>Please note, if you prefer to make direct EFTs, this is processed manually, and as a result there will be a waiting period.</p>
<p class='is-size-5 pb-2'>Once your payment has been processed you will receive further information via email.</p>
<p class='is-size-5 pb-2'>Thank you for registering at Cornerstone Institute!</p>
`" :eft_details="`
<p class='is-size-5 pb-2'>Email your EFT proof of payment to <a href='mailto:bursar@cornerstone.ac.za'>bursar@cornerstone.ac.za</a></p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Name</span>: Cornerstone Institute (RF) NPC</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Bank</span>: Nedbank</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Branch Code</span>: 102 510</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Number</span>: 107 503 0269</p>
<p class='is-size-5 pb-2'><span class='is-italic'>International Swift No</span>: NEDSZAJJ</p>`"
                                    :invoice_type="2"
                                    :show_next="false"
                                    :success_copy="'You have paid your initial fee payment. You will receive an email with your credentials and student information. Welcome to the Cornerstone Family!'"
                                    :success_title="'Initial Fee Payment Successful'" :title="'Initial Fee Payment: R5330'" @withdraw="startWithdraw"/>
        </b-tab-item>
        <b-tab-item label="Awaiting Document Verification">
          <div class="has-background-white box is-relative card">
            <title-bar class="card-header"><p class="card-header-title">Your Documents are being Verified</p></title-bar>
              <p class="is-size-5">Your submitted documents are currently being verified. You will receive an email when the verification is confirmed.</p>
            <div class="card-content">
            </div>
          </div>
        </b-tab-item>
      </b-tabs>

      <!--      <LearnerRegistrationListItem v-for="registration in registrations" :key="registration.id" :registration="registration"/>-->
    </div>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>

  </section>
</template>

<script>
import Registration from "@/models/Registration";
import TitleBar from "@/components/TitleBar";
import Learner from "@/models/Learner";
import LearnerRegistrationFlow from "@/mixins/LearnerRegistrationFlow";
import {mapState} from "vuex";
import LearnerDetailsForm from "@/components/users/LearnerDetailsForm";
import RegistrationCourseForm from "@/components/Registrations/RegistrationCourseForm";
import ApplicationConditionsPanel from "@/components/Registrations/ApplicationConditionsPanel";
import RegistrationPaymentPanel from "@/components/Registrations/PaymentPanel";
import RegistrationModulesPanel from "@/components/Registrations/RegistrationModulesPanel";
import RegistrationPendingPanel from "@/components/Registrations/RegistrationPendingPanel";
// import LearnerRegistrationListItem from "@/components/registrations/RegistrationListItem"

export default {
  name: "RegistrationsProcess",
  components: {
    RegistrationPaymentPanel,
    TitleBar,
    LearnerDetailsForm,
    RegistrationCourseForm,
    ApplicationConditionsPanel,
    RegistrationModulesPanel,
    RegistrationPendingPanel
    // LearnerRegistrationListItem
  },
  mixins: [LearnerRegistrationFlow],
  data() {
    return {
      choosingCourse: false,
      meta: Object,
      loading: false,
      page: 1,
      dropFile: null,
      updatingMedia: false,
      apply_for_rpl: null,
      processed: false,
      activeStep: Object
    };
  },
  computed: {
    ...mapState('applications', ['activeRegistrationId']),

    registrations() {
      return Registration.query().get()
    },
    activeRegistration() {
      return Registration.query().whereId(this.activeRegistrationId).withAllRecursive().first()
    },

    learner() {
      return Learner.query().whereId(this.$store.state.userId).withAll().first() ?? Object
    }
  },
  methods: {
    updateDetails() {
      this.choosingCourse = true;
      this.$nextTick(() => {
        this.processActive()

      })
    },
    processActive() {
      let step = Object
      if (this.loading) {
        step = Object
      }
      if (this.activeRegistrationId === null) {
        if (this.choosingCourse === true) {

          step = {tab: 1, title: 'New Registration'}
        } else {
          step = {tab: 0, title: 'New Registration'}

        }

      }
      if (this.activeRegistrationId !== null) {
        if (this.activeRegistration.registration_status_id === 1) {
          step = {tab: 2, title: 'Application Conditions'}
        }
        if (this.activeRegistration.registration_status_id === 17) {
          step = {tab: 3, title: 'Application Fee Payment'}
        }
        if (this.activeRegistration.registration_status_id === 2) {
            step = {tab: 4, title: 'Module Selection'}
        }
        if (this.activeRegistration.registration_status_id === 8) {
          step = {tab: 7, title: 'Awaiting Document Verification'}
        }
        if (this.activeRegistration.registration_status_id === 18) {
          step = {tab: 6, title: 'Initial Payment Pending'}
        }
      }
      this.activeStep = step
    },
    PostConditionalRedirect() {
      // check if the user is returning or not
      Registration.fetchById(this.activeRegistrationId).then(() => {
        Registration.CheckIsReturningCourse(this.activeRegistrationId).then((result) => {
          if (!result.response.data.is_returning) {
            this.setToPaymentPending();
          } else {
            // if you are returning, skip document verification AND initial fee
            this.setToModuleSelection();
          }
        })

      })

    },
    async setToModuleSelection() {
      this.$store.dispatch('loader/show')
      let submitObject = JSON.parse(JSON.stringify(Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()))
      delete submitObject.modules
      submitObject.registration_status_id = 2
      submitObject.stage_id = 100
      Registration.updateRemote(submitObject).then(() => {
        this.activeRegistration.registration_status_id = submitObject.registration_status_id
        this.processActive()
        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    async SetToPendingVerification() {
      this.$store.dispatch('loader/show')
      let submitObject = JSON.parse(JSON.stringify(Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()))
      delete submitObject.modules
      submitObject.registration_status_id = 8
      submitObject.stage_id = 102
      Registration.updateRemote(submitObject).then(() => {
        this.activeRegistration.registration_status_id = submitObject.registration_status_id
        if (parseInt(submitObject.registration_status_id) === 3) {
          this.$router.push({name: 'registrations.mine', query: {success: 1}})
        } else {
          this.processActive()
        }

        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    setToApplicationComplete() {
      this.$store.dispatch('loader/show')
      let submitObject = JSON.parse(JSON.stringify(Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()))
      delete submitObject.modules
      submitObject.registration_status_id = 18
      submitObject.stage_id = 103
      Registration.updateRemote(submitObject).then(() => {
        this.activeRegistration.registration_status_id = 18
        this.processActive()
        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    setToPaymentPending() {
      this.$store.dispatch('loader/show')
      if (this.activeRegistration.course.is_custom_flow===1){
        return;
      }
      if (this.activeRegistration.course.needs_application_fee===0){
        this.SetToPendingVerification();
        return
      }
      let submitObject = JSON.parse(JSON.stringify(Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()))
      delete submitObject.modules
      submitObject.registration_status_id = 17
      submitObject.stage_id = 98
      Registration.updateRemote(submitObject).then(() => {
        this.activeRegistration.registration_status_id = 17
        this.processActive()

        this.$store.dispatch('loader/hide')
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    startWithdraw() {
      this.$buefy.dialog.confirm({
        title: "Withdraw Application",
        message:
          "Are you sure you want to withdraw? This action cannot be undone.",
        confirmText: "Withdraw",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.withdraw(),
      });
    },
    withdraw() {
      this.$store.dispatch('loader/show')
      let withdrawObject = Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()
      withdrawObject.registration_status_id = 6
      Registration.updateRemote(withdrawObject).then(() => {
        this.processActive()
        this.$store.dispatch('loader/hide')
        this.$store.state.applications.activeRegistrationId = null
        this.$store.state.applications.hasRegistrationInProgress = false
        this.$store.state.applications.canSeeRegistrationsIndex = true
        this.$router.push({name: 'registrations.mine'})
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    selectRegistration(id) {
      Registration.fetchById(id).then(() => {
        this.$store.state.applications.activeRegistrationId = id
        this.choosingCourse = false
        this.processActive()
      })
    },
    registrationProgress(status_id) {
      return this.$store.state.applications.applicationProgress.find(group => {
        return group.ids.includes(parseInt(status_id))
      })
    },
    statusName(status) {
      if (status) {
        return this.$store.state.statuses.statuses.find(statusItem => statusItem.id === parseInt(status)).name
      }
      return null
    },
    courseName(registration) {
      return registration.course != null ? registration.course.name : "None";
    },
    showSuspendedModal(){
      this.$buefy.dialog.confirm({
        title: "Account Suspended",
        message:
          `<p>Dear Student, <br/><br/>Your studies have been suspended. Please refer to the correspondence you received in this regard. For further enquiries, please contact <a href="mailto:helpdesk@cornerstone.ac.za" target="_blank">helpdesk@cornerstone.ac.za</a>.</p>`,
        confirmText: "OK",
        type: "is-danger",
        hasIcon: true,
        canCancel:false,
        onConfirm: () => {
          this.$router.replace("/registrations/mine")
        },
      });
    }
  },
  async created() {
    this.$store.dispatch("loader/showOpaque");
    this.loading = true
    this.processActive()
    const learner = await Learner.fetchById(parseInt(this.$store.state.userId))
    if(learner.response.data.data.is_suspended){
      this.showSuspendedModal()
    }
    await this.checkRegistrationFlow(this.$route.name, Object.prototype.hasOwnProperty.call(this.$route.query, 'success')).then(() => {
      this.processed = true
      this.loading = false
      this.processActive()
    }).catch(err => this.$router.push(err))
    // await Registration.fetchByUser(
    //   1,
    //   999,
    //   parseInt(this.$store.state.userId)
    // )
    this.$store.dispatch('loader/hideOpaque')
  },
};
</script>
