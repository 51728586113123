<template>
  <section class="section">
    <title-bar>Welcome to the Cornerstone AAS</title-bar>
    <p>To get started click a menu item on the left or use the following quick buttons:</p>
    <div v-if="hasPermission('view frontend')">
      <p class="mt-4">
        If you are unable to complete your profile information online, visit
        <router-link :to="{name:'application-support-documents'}"> Application Support Documents</router-link>and follow the instructions there.
      </p>
    </div>
    <div
      v-if="!hasPermission('view frontend')"
      class="columns is-variable is-1 is-multiline margin-top"
    >
<!--      <div v-if="hasPermission('view enquiries')" class="column is-6">-->
<!--        <card-component :header-icon="$tc('icons.enquiries')" title="Enquiries">-->
<!--          <div class="columns is-centered">-->
<!--            <div class="column has-text-centered">-->
<!--              <b-button-->
<!--                class="is-primary"-->
<!--                v-if="hasPermission('view enquiries')"-->
<!--                @click="goToRoute('/enquiries')"-->
<!--              >Enquiries Index</b-button>-->
<!--            </div>-->
<!--&lt;!&ndash;            <div class="column has-text-centered">&ndash;&gt;-->
<!--&lt;!&ndash;              <b-button&ndash;&gt;-->
<!--&lt;!&ndash;                class="is-link"&ndash;&gt;-->
<!--&lt;!&ndash;                v-if="hasPermission('create enquiries')"&ndash;&gt;-->
<!--&lt;!&ndash;                @click="goToRoute('/enquiries/create')"&ndash;&gt;-->
<!--&lt;!&ndash;              >Create a new Enquiry</b-button>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--          </div>-->
<!--        </card-component>-->
<!--      </div>-->
      <div v-if="hasPermission('view registrations')" class="column is-6">
        <card-component :header-icon="$tc('icons.registrations')" title="Registrations">
          <div class="columns is-centered">

            <div class="column has-text-centered">
              <b-button
                class="is-link"
                v-if="hasPermission('create registrations')"
                @click="goToRoute('/registrations/create')"
              >Create a new Registration</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view learners')" class="column is-6">
        <card-component :header-icon="$tc('icons.learners')" :title="$tc('Learner',2)">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view learners')"
                @click="goToRoute('/learners')"
              >{{$tc('Learner',2)}} Index</b-button>
            </div>
            <div class="column has-text-centered">
              <b-button
                class="is-link"
                v-if="hasPermission('create learners')"
                @click="goToRoute('/learners/create')"
              >Create a new {{$tc('Learner',1)}}</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view users')" class="column is-6">
        <card-component :header-icon="$tc('icons.users')" title="Users">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view users')"
                @click="goToRoute('/users')"
              >Users Index</b-button>
            </div>
            <div class="column has-text-centered">
              <b-button
                class="is-link"
                v-if="hasPermission('create users')"
                @click="goToRoute('/users/create')"
              >Create a new User</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view classlists')" class="column is-6">
        <card-component :header-icon="$tc('icons.classlists')" title="Classlists">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view classlists')"
                @click="goToRoute('/classlists')"
              >Classlists Index</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <!-- <div v-if="hasPermission('view emails')" class="column is-6">
        <card-component :header-icon="$tc('icons.emails')" title="Emails">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view emails')"
                @click="goToRoute('/emails')"
              >All Emails</b-button>
            </div>
          </div>
        </card-component>
      </div>-->
      <div v-if="hasPermission('view schedule')" class="column is-6">
        <card-component :header-icon="$tc('icons.schedule')" title="Schedule">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view schedule')"
                @click="goToRoute('/schedule')"
              >Schedule Index</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view modules')" class="column is-6">
        <card-component :header-icon="$tc('icons.modules')" title="Modules">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view modules')"
                @click="goToRoute('/modules')"
              >Modules Index</b-button>
            </div>
            <div class="column has-text-centered">
              <b-button
                class="is-link"
                v-if="hasPermission('create modules')"
                @click="goToRoute('/modules/create')"
              >Create a new Module</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view courses')" class="column is-6">
        <card-component :header-icon="$tc('icons.courses')" :title="$tc('Course',2)">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view courses')"
                @click="goToRoute('/courses')"
              >{{$tc('Course',2)}} Index</b-button>
            </div>
            <div class="column has-text-centered">
              <b-button
                class="is-link"
                v-if="hasPermission('create courses')"
                @click="goToRoute('/courses/create')"
              >Create a new {{$tc('Course',1)}}</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view tasks')" class="column is-6">
        <card-component :header-icon="$tc('icons.activities')" title="Activities">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view tasks')"
                @click="goToRoute('/activities')"
              >My Activities</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view bulk-send')" class="column is-6">
        <card-component :header-icon="$tc('icons.bulk-send')" title="Bulk Send">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view bulk-send')"
                @click="goToRoute('/bulk-send')"
              >Compose Bulk Message</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view reports')" class="column is-6">
        <card-component :header-icon="$tc('icons.reports')" title="Reports">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view reports')"
                @click="goToRoute('/reports')"
              >Reports Index</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div v-if="hasPermission('view exports')" class="column is-6">
        <card-component :header-icon="$tc('icons.exports')" title="Exports">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                v-if="hasPermission('view exports')"
                @click="goToRoute('/exports')"
              >Exports Index</b-button>
            </div>
          </div>
        </card-component>
      </div>
    </div>
    <div v-if="hasPermission('view frontend')" class="columns is-centered is-multiline margin-top">
      <div v-if="$store.state.applications.canSeeRegistrationsIndex" class="column is-6">
        <card-component :header-icon="$tc('icons.registrations')" title="My Registrations">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button
                class="is-primary"
                @click="goToRoute('/registrations/mine')"
              >My Registrations</b-button>
            </div>
          </div>
        </card-component>
      </div>
      <div class="column is-6">
        <card-component :header-icon="$tc('icons.learners')" title="My Profile">
          <div class="columns is-centered">
            <div class="column has-text-centered">
              <b-button class="is-primary" @click="goToRoute('/my-profile')">My Profile</b-button>
            </div>
          </div>
        </card-component>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import LearnerRegistrationFlow from "@/mixins/LearnerRegistrationFlow";

export default {
  name: "home",
  components: {
    TitleBar: TitleBar,
    CardComponent,
  },
  data() {
    return {};
  },
  mixins: [LearnerRegistrationFlow],

  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
  },
  async created() {
    this.$store.dispatch("loader/showOpaque");

    await this.checkRegistrationFlow(this.$route.name).then(() => {


    }).catch(err => this.$router.push(err))
    this.$store.dispatch('loader/hideOpaque')

    // this.$buefy.snackbar.open({
    //   message: "Welcome back",
    //   queue: false,
    //type: "is-link";
    // });
  },
};
</script>
