import {
  TransformModel
} from '@/models/TransformModel'
import Role from '@/models/Role'
import RoleUser from '@/models/RoleUser'
import Profile from "@/models/Profile";

export default class User extends TransformModel {
  static entity = 'users'


  static fields() {
    return {
      type: this.attr('users'),
      id: this.attr(null),
      title: this.attr(''),
      first_name: this.attr(''),
      middle_name: this.attr(''),
      last_name: this.attr(''),
      username: this.attr(''),
      profile: this.hasOne(Profile, 'user_id'),
      email: this.attr(''),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      roles: this.belongsToMany(Role, RoleUser, 'user_id', 'role_id'),
      primary_contact_number: this.attr(''),
      secondary_contact_number: this.attr(''),
      cellphone_number: this.attr(''),
      is_admin: this.attr(false),
      is_learner: this.attr(false),
      student_number: this.attr(false),
    }
  }

  static newUser(user) {
    return this.api().post(`/users`, user, {
      save: false
    })
  }
  static search(data, saved, page = 1, limit = 100) {
    return this.api().get(`/users?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      save: saved
    })
  }
  static searchWithRole(data, saved, page = 1, limit = 100, role = 'lecturer') {
    return this.api().get(`/users?search=${data}&limit=${limit}&page=${page}&with[]=roles`, {
      params: {
        ...(role ? {
          user_role: role
        } : {})
      },
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      save: saved
    })
  }
  static updateRemote(user) {
    return this.api().patch(`/users/${user.id}`, user, {
      save: false
    })
  }
  static fetchAll(permission = null, role = null, page = 1, limit = 999, relationships = null) {
    return this.api().get(`/users`, {
      params: {
        page: page,
        limit: limit,
        ...(role ? {
          user_role: role
        } : {}),
        ...(permission ? {
          user_permission: permission
        } : {}),
        ...(relationships ? {
          with: relationships
        } : {})
      },

      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }
  static fetchById(id, relationships = null) {
    return this.api().get(`/users/${id}`, {
      params: {

        ...(relationships ? {
          with: relationships
        } : {})
      },
      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)

        return target
      }
    })
  }
  static deleteRemote(user_id) {
    return this.api().delete(`/users/${user_id}`, {
      delete: user_id
    })
  }


}
