<template>
  <section class="section">
    <title-bar>Modules</title-bar>

    <div class="columns">
      <div class="column">
        <b-field grouped group-multiline>
          <b-field>
            <b-input
              v-debounce:300ms="getFilteredModule"
              placeholder="Search by name"
              :loading="loadingFilter"
              v-model="filter"
            ></b-input>
          </b-field>
          <b-field>
            <b-radio-button v-model="byCourse" :native-value="true">
              <b-icon :icon="$tc('icons.courses')"></b-icon>
              <span>Filter by {{ $tc('Course', 1) }}</span>
            </b-radio-button>
            <b-autocomplete
              v-if="byCourse"
              style="width:300px"
              class
              @typing="getFilteredCourse"
              :data="courseSearchResponse"
              autocomplete="off"
              v-model="courseSearchTerm"
              :loading="loadingCourses"
              :placeholder="$tc('Course',1)"
              :clear-on-select="true"
              @select="option => selectCourse(option)"
            >
              <template slot-scope="props">
                <p>{{ props.option.code }} | {{ props.option.name }}</p>
              </template>
            </b-autocomplete>
          </b-field>
          <b-field>
            <b-select v-model="semesterFilter" placeholder="Semester">
              <option
                v-for="semester in semesters"
                :key="semester.id"
                :value="semester.id"
              >{{ semester.name }}
              </option>
            </b-select>
          </b-field>
          <b-field>
            <b-taglist class="margin-left">
              <b-tag
                type="is-info"
                v-if="selectedCourseId"
                @close="unselectCourse()"
                closable
              >{{ selectedCourseName }}
              </b-tag>
              <b-tag
                type="is-info"
                v-if="semesterFilter"
                @close="semesterFilter = null"
                closable
              >Semester
              </b-tag>
            </b-taglist>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-table class="margin-top table-container" :data="modules" :striped="true" scrollable :hoverable="true" :bordered="true">
      <b-table-column v-slot="props" label="Code" field="code" sortable width="40" numeric>{{ props.row.code }}</b-table-column>

      <b-table-column v-slot="props" sortable field="name" label="Name">{{ props.row.name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="nqf" label="NQF Level">{{ props.row.nqf }}</b-table-column>
      <b-table-column v-slot="props" sortable field="level" label="Level">{{ props.row.level }}</b-table-column>
      <b-table-column v-slot="props" sortable field="standardised_level_id" label="Standardised Level">{{ props.row.standardised_level_id?$store.state.levels.levels.find(level=> level.id===props.row.standardised_level_id).name:'None'  }}</b-table-column>
      <b-table-column v-slot="props" sortable field="lms_code" label="LMS Code">{{ returnCurrentLMSCode(props.row.lms_codes) }}</b-table-column>

      <b-table-column v-slot="props" sortable field="subject.name" label="Subject">{{ props.row.subject.name }}</b-table-column>
      <b-table-column v-slot="props" label="Semester">{{ displaySemesters(props.row.semesters) }}</b-table-column>
      <b-table-column v-slot="props" sortable field="credits" label="Credits">{{ props.row.credits }}</b-table-column>

      <b-table-column v-slot="props" centered custom-key="actions">
        <b-field>
          <span class="control has-text-left">
            <TableDropdown>
              <template>
                <b-dropdown-item aria-role="listitem" @click="viewModule(props.row.id)">Edit</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="openLMSCodeModal(props.row)">View LMS Codes</b-dropdown-item>
              </template>
            </TableDropdown>
          </span>
          <span class="control">
            <b-button
              v-if="can_delete_modules"
              @click="start_delete_module(props.row.id)"
              type="is-danger"
              >Delete
            </b-button>
          </span>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      class="margin-top"
      :total="meta.total"
      :current="meta.current_page"
      :range-before="2"
      :range-after="2"
      :per-page="meta.per_page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Module from "@/models/Module";
import TitleBar from "@/components/TitleBar";
import Course from "@/models/Course";
import Semester from "@/models/Semester";
import LMSCodeModal from "@/components/modules/LMSCodeModal";
import TableDropdown from "@/components/global/selects/TableDropdown.vue";

export default {
  name: "Modules",
  components: {TableDropdown, TitleBar},
  data() {
    return {
      filter: "",
      courseSearchTerm: "",
      selectedCourseName: null,
      selectedCourseId: null,
      courseSearchResponse: [],
      loadingCourses: false,
      byCourse: false,
      semesterFilter: null,
      loadingFilter: false,
      modules: [],
      meta: Object,
      page: 1,
    };
  },
  watch: {
    semesterFilter() {
      this.$store.dispatch("loader/show");
      this.getFilteredModule(this.filter);
    },
  },
  methods: {
    openLMSCodeModal(module) {
      this.$buefy.modal.open({
        parent: this,
        component: LMSCodeModal,
        hasModalCard: true,
        props: {
          module: module,
        },
      });
    },
    returnCurrentLMSCode(lmsCodes) {
      if(!lmsCodes || lmsCodes.length === 0) return "No code"
      return lmsCodes.find(code => code.semester_id == this.currentSemester.id)?.lms_code || lmsCodes[0].lms_code
    },
    displaySemesters(semesters) {
      if (semesters.length === 1) {
        return semesters[0].name
      } else if (semesters.length > 1) {
        let text = semesters[0].name
        for (let i = 1; i < 2; i++) {
          text += ", " + semesters[i].name;
        }
        if (semesters.length == 2) {
          return text
        }
        return text + ' +'
      }
      return "";
    },
    unselectCourse() {
      this.byCourse = false;
      this.selectedCourseId = null;
      this.selectedCourseName = null;
      this.getFilteredModule(this.filter);
    },
    selectCourse(option) {
      this.selectedCourseName = option.name;
      this.selectedCourseId = option.id;
      this.getFilteredModule(this.filter);
    },
    start_delete_module(moduleId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Module",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Module",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteModule(moduleId),
      });

    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.search(text, true).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.courseSearchResponse = result.entities.courses;
        } else {
          this.courseSearchResponse = [];
        }
      });
    },
    deleteModule(moduleId) {
      this.$store.dispatch("loader/show");

      this.updatingModule = true;
      Module.deleteRemote(moduleId)
        .then(() => {
          Module.delete(moduleId);
          this.getModules(1, 15)
            .then(() => {
              this.$store.dispatch("loader/hide");
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
          this.updatingModule = false;
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    viewModule(id) {
      this.$router.push(`/modules/edit/${id}`);
    },
    getFilteredModule(text) {
      this.loadingFilter = true;
      Module.deleteAll()
      Module.search(
        text,
        true,
        1,
        15,
        this.selectedCourseId,
        null,
        this.semesterFilter
      ).then((result) => {
        this.loadingFilter = false;
        this.modules = Module.query().withAll().get();

        this.meta = result.response.data.meta;
        this.$store.dispatch("loader/hide");
      });
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      if (this.filter.length > 0) {
        Module.deleteAll()
        Module.search(this.filter, true, pageNumber, 15).then((result) => {
          this.modules = Module.query().withAll().get();
          this.meta = result.response.data.meta;
          this.$store.dispatch("loader/hide");
        });
      } else {
        this.getModules(pageNumber, this.meta.per_page).then(() => {
          this.$store.dispatch("loader/hide");
        });
      }
    },
    getModules(page, limit) {
      Module.deleteAll()
      return Module.fetchAll(page, limit, ["lms_codes", "lms_codes.semester"]).then((result) => { //"module_lms_codes.semester"
        this.modules = Module.query().withAll().get();

        this.meta = result.response.data.meta;
      });
    },
  },

  computed: {
    semesters() {
      return Semester.all();
    },
    currentSemester(){
      return this.semesters.find((semester) => {
        // is today between start and end date
        const today = new Date()
        const start = new Date(semester.start_date)
        const end = new Date(semester.end_date)
        if(today >= start && today <= end){
          return semester
        }
      })
    },
    can_delete_modules() {
      return this.$store.getters["entities/permissions/find"]("delete modules");
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Semester.FetchAll();
    this.getModules(1, 15)
      .then(() => {
        this.$store.dispatch("loader/hide");
      })
      .catch((err) => {
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      });
  },
};
</script>
