<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit  }">

    <form class="is-relative" @submit.prevent="submit">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Student Details</p></title-bar>
<!--        <h2  class="is-size-3 mb-6 has-text-weight-semibold">Applications are currently closed.</h2>-->

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">

          <b-field label="First Name">
            <b-input
              v-model="learnerObject.first_name"
              autocomplete="off"
              placeholder="First Name"
              required
            />
          </b-field>
          <b-field label="Middle Name(s)">
            <b-input
              v-model="learnerObject.middle_name"
              autocomplete="off"
              placeholder="Middle Name(s)"

            />
          </b-field>
          <b-field label="Last Name">
            <b-input
              v-model="learnerObject.last_name"
              autocomplete="off"
              placeholder="Last Name"
              required
            />
          </b-field>
          <b-field v-if="!inRegistration" :label="$tc('Username',1)">
              <b-input
                autocomplete="chrome-off"
                icon="account"
                disabled
                v-model="learnerObject.username"
                :placeholder="$tc('Username',1)"
                :name="$tc('username',1)"
              />
          </b-field>

          <b-field label="Identity type">
            <b-select v-model="profileObject.identification_type_id"

                      class="style-force"
                      icon="id-card"
                      placeholder="Identity type"
                      required
            >
              <option
                v-for="identification_type in identification_types"
                :key="identification_type.id"
                :value="identification_type.id"
              >{{ identification_type.name }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Identification number">
            <ValidationProvider v-slot="{ errors }"
                                :rules="profileObject.identification_type_id===9? 'required|sa_id':'required'">

              <b-input
                v-model="profileObject.id_number"
                :class="{'danger-border':errors.length>0}"
                autocomplete="chrome-off"
                placeholder="ID number"
                required
                @blur="extractDetails"
                @keypress.native.space.prevent
              />
              <span class="is-danger has-text-danger">{{ errors[0] }}</span>

            </ValidationProvider>
          </b-field>
          <ValidationProvider v-slot="{ errors }"
                              :rules="'required'">
            <b-field :type="{'is-danger':errors.length>0}" label="Date of Birth">
              <b-datepicker
                v-model="date_of_birth"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
                required
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
          <b-field label="Gender">
            <b-field :type="{'is-danger':profileObject.gender_id===null}">
              <b-select

                v-model="profileObject.gender_id"
                placeholder="Gender"
                required
              >
                <option
                  v-for="gender in genders"
                  :key="gender.id"
                  :value="gender.id"
                >{{ gender.name }}
                </option>
              </b-select>
            </b-field>
          </b-field>
          <b-field :type="{'is-danger':profileObject.ethnicity_id===null}" label="Equity Status">
            <b-select

              v-model="profileObject.ethnicity_id"
              placeholder="Equity Status"
              required
            >
              <option
                v-for="ethnicity in ethnicities"
                :key="ethnicity.id"
                :value="ethnicity.id"
              >{{ ethnicity.name }}
              </option>
            </b-select>
          </b-field>

          <b-field label="Personal Email">
            <b-input
              v-model="learnerObject.email"
              autocomplete="off"
              placeholder="Personal Email Address"
              required
              type="email"
            />
          </b-field>
          <b-field
            label="Cellphone Number">
            <b-input
              v-model="learnerObject.cellphone_number"
              :icon="$tc('icons.phone')"
              expanded
              name="phone"
              placeholder="072 555 5555"
              type="tel"
            />
          </b-field>
          <!--          <b-field label="Proof of ID" type="">-->
          <!--            <MediaPanel ref="proof_of_id_upload" :can-create="true" :can-delete="true" :fetch-on-mount="edit" :has-caption="false"-->
          <!--                        :has-table="true" :has-tag="false" :has-upload="true" :model="'users'"-->
          <!--                        :model_id="parseInt(id)" :submit_button="false" :tag="'Certified copy of ID or other proof of identity'"-->
          <!--                        :title="''"/>-->
          <!--          </b-field>-->

          <b-field horizontal>
            <template slot="label">
                <span class="has-pointer" @click="showPhysical = !showPhysical">
                  Physical Address
                  <b-icon :icon="showPhysical?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
            </template>
            <b-field></b-field>
          </b-field>
          <transition name="fade">
            <div v-if="showPhysical">
              <b-field horizontal>

                <b-field :type="{'is-danger':profileObject.physical_address.line_1===null}" label="Street Address">
                  <b-input
                    v-model="profileObject.physical_address.line_1"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="15 Main Street"
                    required
                  />
                </b-field>
                <b-field label="Additional Address Information">
                  <b-input
                    v-model="profileObject.physical_address.line_2"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="10 Fancy Mansions"
                  />
                </b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.physical_address.country===null}" expanded label="Country">

                  <b-select v-model="profileObject.physical_address.country"
                            expanded
                            icon="flag" required
                            @input="((value)=>{getStates(value); profileObject.physical_address.country_id = getCountryId(value)})">
                    <option v-for="(country,index) in countries" :key="index" :value="country.name">{{ country.name }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.physical_address.province===null}" label="Province">
                  <b-select
                    v-if="selectedPhysicalCountryStates.length>0"

                    v-model="profileObject.physical_address.province"

                    @input="value=> {getCities(value); profileObject.physical_address.province_id = getStateId(value,profileObject.physical_address.country_id);profileObject.physical_address.city_id = null;profileObject.physical_address.city =null}">
                    <option v-for="state in selectedPhysicalCountryStates"
                            :key="state.id" :value="state.name">{{ state.name }}
                    </option>
                  </b-select>
                  <b-input v-else
                           v-model="profileObject.physical_address.province"

                           autocomplete="chrome-off"
                           icon="map-marker"
                           placeholder="Gauteng"
                           required
                           @input="profileObject.physical_address.province_id = null;profileObject.physical_address.city_id = null"
                  />
                </b-field>
                <b-field :type="{'is-danger':profileObject.physical_address.city===null}" label="City">
                  <b-autocomplete
                    v-if="profileObject.physical_address.province_id"
                    ref="autocomplete_a"
                    v-model="profileObject.physical_address.city"
                    v-debounce:300ms="value=>{getFilteredCity(value,profileObject.physical_address.province_id)}"
                    :clear-on-select="false"
                    :data="cities"
                    autocomplete="chrome-off"
                    field="name"
                    icon="flag"
                    name="City"
                    placeholder="City"
                    required
                    @blur="validateAutocomplete(profileObject.physical_address.city_id,'profileObject.physical_address.city')"
                    @select="option => {profileObject.physical_address.city=option.name;profileObject.physical_address.city_id=parseInt(option.id)}"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>

                  <b-input
                    v-else
                    v-model="profileObject.physical_address.city"

                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="Johannesburg"
                    required
                  />
                </b-field>

                <b-field :type="{'is-danger':profileObject.physical_address.postal_code===null}" label="Postal Code">
                  <b-input
                    v-model="profileObject.physical_address.postal_code"

                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="5432"
                    required
                  />
                </b-field>
              </b-field>

            </div>
          </transition>
          <b-field horizontal>
            <template slot="label">
                <span class="has-pointer" @click="showPostal = !showPostal">
                  Postal Address
                  <b-icon :icon="showPostal?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
            </template>
            <b-field></b-field>
          </b-field>
          <transition name="fade">
            <div v-show="showPostal">
              <b-field horizontal>
                <b-field>
                  <b-checkbox :value="copy_postal_address" @input="twin_addresses">Same as above</b-checkbox>
                </b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.postal_address.line_1===null}" label="Street Address">
                  <b-input
                    v-model="profileObject.postal_address.line_1"

                    :disabled="copy_postal_address"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="15 Main Street"
                    required
                  />
                </b-field>
                <b-field label="Additional Address Information">
                  <b-input
                    v-model="profileObject.postal_address.line_2"

                    :disabled="copy_postal_address"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="10 Fancy Mansions"
                  />
                </b-field>


              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.postal_address.country===null}" expanded label="Country">

                  <b-select v-model="profileObject.postal_address.country"
                            :disabled="copy_postal_address"
                            expanded

                            icon="flag" required
                            @input="((value)=>{getStates(value); profileObject.postal_address.country_id = getCountryId(value)})">
                    <option v-for="(country,index) in countries" :key="index" :value="country.name">{{ country.name }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>

              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.postal_address.province===null}" label="Province">
                  <b-select v-if="selectedPostalCountryStates.length>0" v-model="profileObject.postal_address.province"

                            :disabled="copy_postal_address"
                            required
                            @input="value=> {getCities(value); profileObject.postal_address.province_id = getStateId(value,profileObject.postal_address.country_id);profileObject.postal_address.city_id = null;profileObject.postal_address.city =null}">
                    <option v-for="state in selectedPostalCountryStates"
                            :key="state.id" :value="state.name">{{ state.name }}
                    </option>
                  </b-select>
                  <b-input v-else
                           v-model="profileObject.postal_address.province"
                           :disabled="copy_postal_address"

                           autocomplete="chrome-off"
                           icon="map-marker"
                           placeholder="Gauteng"
                           required
                           @input="profileObject.postal_address.province_id = null;profileObject.postal_address.city_id = null"
                  />
                </b-field>
                <b-field :type="{'is-danger':profileObject.postal_address.city===null}" label="City">
                  <b-autocomplete
                    v-if="profileObject.postal_address.province_id"
                    ref="autocomplete_b"
                    v-model="profileObject.postal_address.city"
                    v-debounce:300ms="value=>{getFilteredCity(value,profileObject.postal_address.province_id)}"
                    :clear-on-select="false"
                    :data="cities"
                    :disabled="copy_postal_address"
                    autocomplete="chrome-off"
                    field="name"
                    icon="flag"
                    name="City"

                    placeholder="City"
                    required
                    @blur="validateAutocomplete(profileObject.postal_address.city_id,'profileObject.postal_address.city')"
                    @select="option => {profileObject.postal_address.city=option.name;profileObject.postal_address.city_id=parseInt(option.id)}"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>

                  <b-input
                    v-else
                    v-model="profileObject.postal_address.city"
                    :disabled="copy_postal_address"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="Johannesburg"
                    required
                  />
                </b-field>

                <b-field :type="{'is-danger':profileObject.postal_address.postal_code===null}" label="Postal Code">
                  <b-input
                    v-model="profileObject.postal_address.postal_code"
                    :disabled="copy_postal_address"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="5432"
                    required
                  />
                </b-field>
              </b-field>

            </div>
          </transition>
          <b-field horizontal>
            <template slot="label">
                <span class="has-pointer" @click="showAlternative = !showAlternative">
                  Alternative Address
                  <b-icon :icon="showAlternative?'chevron-down':'chevron-right'" size="is-small"></b-icon>
                </span>
            </template>
            <b-field></b-field>
          </b-field>
          <transition name="fade">
            <div v-if="showAlternative">
              <b-field horizontal>
                <b-field label="Street Address">
                  <b-input
                    v-model="profileObject.alternative_address.line_1"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="15 Main Street"
                    required
                  />
                </b-field>
                <b-field label="Additional Address Information">
                  <b-input
                    v-model="profileObject.alternative_address.line_2"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="10 Fancy Mansions"
                  />
                </b-field>


              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.alternative_address.country===null}" expanded
                         label="Country">

                  <b-select v-model="profileObject.alternative_address.country"
                            expanded
                            icon="flag" required
                            @input="((value)=>{getStates(value); profileObject.alternative_address.country_id = getCountryId(value)})">
                    <option v-for="(country,index) in countries" :key="index" :value="country.name">{{ country.name }}
                    </option>
                  </b-select>
                </b-field>
              </b-field>
              <b-field horizontal>
                <b-field :type="{'is-danger':profileObject.alternative_address.province===null}" label="Province">
                  <b-select v-if="selectedAlternativeCountryStates.length>0"
                            v-model="profileObject.alternative_address.province"
                            required
                            @input="value=> {getCities(value); profileObject.alternative_address.province_id = getStateId(value,profileObject.alternative_address.country_id);profileObject.alternative_address.city_id = null;profileObject.alternative_address.city =null}">
                    <option v-for="state in selectedAlternativeCountryStates"
                            :key="state.id" :value="state.name">{{ state.name }}
                    </option>
                  </b-select>
                  <b-input v-else
                           v-model="profileObject.alternative_address.province"
                           autocomplete="chrome-off"
                           icon="map-marker"
                           placeholder="Gauteng"
                           required
                           @input="profileObject.alternative_address.province_id = null;profileObject.alternative_address.city_id = null"
                  />
                </b-field>
                <b-field :type="{'is-danger':profileObject.alternative_address.city===null}" label="City">
                  <b-autocomplete
                    v-if="profileObject.alternative_address.province_id"
                    ref="autocomplete_c"
                    v-model="profileObject.alternative_address.city"

                    v-debounce:300ms="value=>{getFilteredCity(value,profileObject.alternative_address.province_id)}"
                    :clear-on-select="false"
                    :data="cities"

                    autocomplete="chrome-off"
                    field="name"
                    icon="flag"
                    name="City"
                    placeholder="City"
                    required
                    @blur="validateAutocomplete(profileObject.alternative_address.city_id,'profileObject.alternative_address.city')"
                    @select="option => {profileObject.alternative_address.city=option.name;profileObject.alternative_address.city_id=parseInt(option.id)}"
                  >
                    <template slot-scope="props">
                      <p>{{ props.option.name }}</p>
                    </template>
                  </b-autocomplete>

                  <b-input
                    v-else
                    v-model="profileObject.alternative_address.city"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="Johannesburg"
                    required
                  />
                </b-field>
                <b-field :type="{'is-danger':profileObject.alternative_address.postal_code===null}" label="Postal Code">
                  <b-input
                    v-model="profileObject.alternative_address.postal_code"
                    autocomplete="chrome-off"
                    icon="map-marker"
                    placeholder="5432"
                    required
                  />
                </b-field>
              </b-field>
            </div>
          </transition>
          <b-field  >
            <template #label>
                   <p>Alternative Contact</p>
                   <p class="help has-text-weight-normal">Please provide the details of the the person who is responsible for payment of your study fees.</p>
            </template>

          </b-field>
          <b-field label="Full Name">
            <b-input v-model="profileObject.alternative_contact.name" placeholder="Full Name" ></b-input>
          </b-field>
          <b-field label="Contact Number">
            <b-input v-model="profileObject.alternative_contact.contact_number" placeholder="Contact Number"  type="tel"></b-input>
          </b-field>
          <b-field >
            <template #label>
              <p>Person responsible for Billing</p>
              <p class="help has-text-weight-normal">Please provide the details of the person who is responsible for payment of your study fees.</p>
            </template>
          </b-field>
          <b-field label="Full Name">
            <b-input v-model="profileObject.billing_contact.name" placeholder="Full Name" ></b-input>
          </b-field>
          <b-field label="Contact Number">
            <b-input v-model="profileObject.billing_contact.contact_number" placeholder="Contact Number"  type="tel"></b-input>
          </b-field>
          <b-field>
            <DisabilitiesComponent v-model="disabilitiesObject" :showUploads="true" :useInParent="true" @hasDisabilitySelected="setDisabilitySelected"/>
          </b-field>
          <b-field>
            <MarketingForm
              v-model="marketingObject"
              :profileId="id"
            />
          </b-field>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :loading="loading"  native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
              <p v-if="edit && canDelete" class="control">
                <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                  Delete
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import Lookup from "@/models/Lookup";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import validate_id from "@/mixins/SAIDNumberValidation"
import City from "@/models/City";
import Country from "@/models/Country";
import isEqual from "lodash/isEqual";
import State from "@/models/State";
import set from "lodash/set";
import Learner from "@/models/Learner";
import Profile from "@/models/Profile";
import DisabilitiesComponent from "@/components/DisabilitiesComponent.vue";
import MarketingForm from "@/components/MarketingForm.vue";
// import MediaPanel from "@/components/media/MediaPanel";
import Media from "@/models/Media";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
extend("sa_id", (value) => {
  let check = validate_id.methods.validate_id(value)
  if (check.error === null) {
    return true;
  }
  return check.error;
});
export default {
  name: "LearnerDetailsForm",
  mixins: [validate_id],
  components: {
    DisabilitiesComponent,
    MarketingForm,
    TitleBar,
    ValidationProvider,
    ValidationObserver,
    // MediaPanel
  },
  data() {
    return {
      loading: false,
      isPhoneValid: true,
      showPhysical: true,
      showPostal: true,
      showAlternative: false,
      copy_postal_address: false,
      learnerObject: {
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        cellphone_number: null,
      },
      disabilitiesObject: {},
      disabilitySelected: false,
      marketingObject: {},
      profileObject: {
        user_id: null,
        gender_id: null,
        ethnicity_id: null,
        date_of_birth: null,
        identification_type_id: null,
        id_number: null,
        billing_contact: {
          name: null,
          contact_number: null
        }, alternative_contact: {
          name: null,
          contact_number: null
        },
        physical_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          country_id: null,
          city_id: null,
          province_id: null
        }, alternative_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          country_id: null,
          city_id: null,
          province_id: null
        }, postal_address: {
          line_1: null,
          line_2: null,
          city: null,
          province: null,
          postal_code: null,
          country: null,
          country_id: null,
          city_id: null,
          province_id: null
        },
      }

    }
  },
  methods: {
    getStateId(name, country_id) {


      return parseInt(State.query().where('country_id', country_id).where('name', name).first().id)
    },
    getCountryId(name) {

      return Country.query().where('name', name).first().id
    },
    getFilteredCity(text, state_id) {

      City.deleteAll()
      City.fetchAll({page: 1, limit: 30}, {state_id: state_id, search: text})

    },
    selectedCountryStates(country_id) {

      if (country_id) {
        return State.query().where('country_id', country_id).orderBy('name').get()
      }
      return []
    },
    getCities(value) {

      this.$store.dispatch('loader/show')
      State.fetchAll({page: 1, limit: 5}, {name: value}, ['cities']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    }, getStates(value) {

      this.$store.dispatch('loader/show')
      Country.fetchAll({page: 1, limit: 5}, {name: value}, ['states']).then(() => {
        this.$store.dispatch('loader/hide')
      })
    },
    twin_addresses(value) {

      this.copy_postal_address = value;
      if (value === true) {
        return (this.profileObject.postal_address = this.profileObject.physical_address);
      }
      return (this.profileObject.postal_address = JSON.parse(
        JSON.stringify(this.profileObject.postal_address)
      ));
    },
    validateAutocomplete(source, target) {

      if (source == null) {
        set(this, target, null)
      }
    },
    extractDetails() {
      if (this.profileObject.identification_type_id === 9 && this.profileObject.id_number !== null) {
        let check = validate_id.methods.validate_id(this.profileObject.id_number)
        if (check.error === null) {
          if (this.profileObject.date_of_birth === null) {
            this.date_of_birth = new Date(check.data.date_of_birth)
          }
          if (this.profileObject.gender_id === null) {
            this.profileObject.gender_id = Lookup.query().where('name', check.data.gender).first().id
          }
        }
      }
    },
    setDisabilitySelected(bool){
      this.disabilitySelected = bool
    },
    hasDisabilityOptionsSelected() {
      return this.disabilitiesObject?.accommodation_ids?.length > 0 && this.disabilitiesObject?.disability_ids?.length > 0
    },
    async submit() {
      this.loading = true
      if (this.edit) {
        if (!this.canEdit) {
          this.$store.dispatch('toast/createToast')
          return
        }

        await Learner.updateRemote(this.learnerObject).catch(err => {
          this.handleError(err)
          this.loading = false

        })
        // await this.$refs.proof_of_id_upload.submitMedia()
        // if (Media.query().where('tag', 'Certified copy of ID or other proof of identity').exists()) {
        if(this.disabilitySelected && !this.hasDisabilityOptionsSelected()){
          await this.$store.dispatch('toast/createToast', {message: 'Please make sure all disability options are selected'})
          this.loading = false
          return
        }
        if (this.disabilitySelected && !Media.query().where("tag", 'Disability and/or mental health challenge statement').exists()){
          await this.$store.dispatch('toast/createToast', {message: 'Please make sure all supported disability documentation is uploaded'})
          this.loading = false
          return
        }
        if (this.newProfile) {
          this.profileObject.user_id = this.learnerObject.id
          Profile.newProfile({...this.profileObject, ...this.disabilitiesObject, ...this.marketingObject}, this.profileObject.user_id).then(() => {
            this.$buefy.snackbar.open(`${this.$tc('Learner', 1)} updated!`)
            this.$emit('success')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        } else {
          delete this.profileObject.staff_information
          Profile.updateRemote({...this.profileObject, ...this.disabilitiesObject, ...this.marketingObject}, this.profileObject.user_id).then(() => {
            this.$buefy.snackbar.open(`${this.$tc('Learner', 1)} updated!`)
            this.$emit('success')
            this.loading = false
          }).catch(err => {
            this.handleError(err)
            this.loading = false
          })
        }
        // } else {
        //   this.loading = false
        //   this.$store.dispatch('toast/createToast', {message: 'Please upload proof of ID'})
        //
        // }

      } else {
        if (!this.canCreate) {
          this.$store.dispatch('toast/createToast')
          return
        }
        await Learner.newLearner(this.learnerObject).then((result) => {
          this.learnerObject.id = result.response.data.data.id
          this.profileObject.user_id = result.response.data.data.id
        })
        // await this.$refs.proof_of_id_upload.submitMedia()
        // if (!Media.query().where('tag', 'Certified copy of ID or other proof of identity').exists()) {
        if(this.disabilitySelected && !this.hasDisabilityOptionsSelected()){
          await this.$store.dispatch('toast/createToast', {message: 'Please make sure all disability options are selected'})
          this.loading = false
          return
        }
        if (this.disabilitySelected && !Media.query().where("tag", 'Disability and/or mental health challenge statement').exists()){
          await this.$store.dispatch('toast/createToast', {message: 'Please make sure all supported disability documentation is uploaded'})
          this.loading = false
          return
        }
        Profile.newProfile({...this.profileObject, ...this.disabilitiesObject, ...this.marketingObject}, this.profileObject.user_id).then(() => {
          this.$buefy.snackbar.open(`${this.$tc('Learner', 1)} created!`)
          this.$emit('success')
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
        // } else {
        //   this.loading = false
        //   this.$store.dispatch('toast/createToast', {message: 'Please upload proof of ID'})
        //
        // }
      }
    }
  },

  computed: {
    date_of_birth: {
      set(date) {
        let process = new Date(date);
        this.profileObject.date_of_birth = new Date(
          process.getTime() - process.getTimezoneOffset() * 60000
        )
          .toISOString()
          .split("T")[0];
      },
      get() {
        if (this.profileObject.date_of_birth === null) {
          return null
        }
        return new Date(this.profileObject.date_of_birth);
      },
    },
    profile() {
      return Profile.query().where('user_id', (value) => {
        return parseInt(value) === parseInt(this.id)
      }).first()
    },
    genders() {

      return Lookup.query().where("lookup_type_id", 1).get();
    },
    ethnicities() {

      return Lookup.query().where("lookup_type_id", 2).get();
    },
    selectedPhysicalCountryStates() {

      return this.selectedCountryStates(this.profileObject.physical_address.country_id)
    },
    selectedPostalCountryStates() {

      return this.selectedCountryStates(this.profileObject.postal_address.country_id)
    }, selectedAlternativeCountryStates() {

      return this.selectedCountryStates(this.profileObject.alternative_address.country_id)
    },
    cities() {
      return City.query().orderBy('name').get()
    },
    countries() {
      return [Country.query().where('name', 'South Africa').first() ?? {}, ...Country.query().orderBy('name').get()]
    },

    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    },
    newUser() {
      return !Object.prototype.hasOwnProperty.call(this.learnerObject, 'id')
    }, newProfile() {
      return !Object.prototype.hasOwnProperty.call(this.profileObject, 'id')
    },
  },
  async mounted() {
    this.loading = true
    if (this.latestRegistration.hasOwnProperty('registration_status_id') && this.latestRegistration.registration_status_id == 19 ) {
      let message = '';
      if (this.latestRegistration.dismissal_reason == 1) {
        message = 'You\'ve been dismissed because your account has an outstanding balance. Please send an email to bursar@cornerstone.ac.za to make arrangements to settle your account.';
      } else {
        message = 'You\'ve been dismissed for academic/conduct reasons. Please send a letter motivating a reason for re-enrolment acceptance to this email address: dismissal@cornerstone.ac.za.';
      }
      this.$buefy.dialog.alert({
        title: 'Registration Dismissal Alert',
        message: message,
        confirmText: 'I understand.',
        onConfirm: () => this.$router.push('/my-profile')
      })
    } else {
      if (this.inRegistration) {
        await this.$buefy.dialog.confirm({
          title: "Welcome",
          message:
            `<p class="pb-2">Dear applicant, we are delighted that you have chosen Cornerstone to make your future brighter.</p>

<p class="pb-2">Before you begin this process, please make sure that you have the following documents with you:</p>
<ul class="pl-4" style="list-style-type: circle">
<li >Your ID document (whether South African or other)</li>
<li>Your matric certificate</li>
<li >If you want to register for the PGCE - you will also have to upload your transcript from your previous tertiary studies when prompted</li>
</ul>

<p class="py-2"> As you will have to upload all these documents in the process - please prepare by scanning or photographing your documents before you begin this application.</p>

<p class="pb-2">Make sure that your scans or photographs are clear, legible and all information on them is visible. If you are unsure, err on the safe side and rather photograph or scan again.</p>

<p class="pb-2">You will be asked to fill in information from these documents into our application system. You will not be able to complete your application without them.</p>

Good luck with your application. we cannot wait to welcome you to the Cornerstone family.

          `,
          confirmText: "Begin",

          type: "is-primary",
          canCancel: false,
          hasIcon: false,
        });
        this.makeModalLarge()
      }
      await Promise.all([
        Lookup.fetchAll({lookup_type_id: [1, 2, 3, 9, 27, 28]}),
        Country.fetchAllPublic({page: 1, limit: 999})
      ])

      if (this.edit) {
        await Learner.fetchById(this.id)
        this.learnerObject = JSON.parse(JSON.stringify(Learner.query().whereId(this.id).withAll().first()))
        if (Profile.query().where('user_id', (value) => {
          return parseInt(value) === parseInt(this.id)
        }).exists()) {
          this.profileObject = JSON.parse(JSON.stringify(Profile.query().where('user_id', (value) => {
            return parseInt(value) === parseInt(this.id)
          }).first()))
          this.disabilitiesObject = {
            disability_ids: this.profileObject.disability_ids,
            accommodation_ids: this.profileObject.accommodation_ids,
          }
        }
        this.extractDetails()
        let countriesId = []
        if (this.profileObject.physical_address.country) {
          if (!countriesId.includes(this.profileObject.physical_address.country)) {
            countriesId.push(this.profileObject.physical_address.country)
          }
        }

        if (this.profileObject.postal_address.country) {
          if (!countriesId.includes(this.profileObject.postal_address.country)) {
            countriesId.push(this.profileObject.postal_address.country)
          }
        }
        if (this.profileObject.alternative_address.country) {
          if (!countriesId.includes(this.profileObject.alternative_address.country)) {
            countriesId.push(this.profileObject.alternative_address.country)
          }
        }
        let countryPromises = []
        countriesId.forEach(country => {
          countryPromises.push(Country.fetchAll({page: 1, limit: 1}, {name: country}, ['states'])
          )
        })
        await Promise.all(countryPromises)
        if (
          isEqual(
            this.profileObject.physical_address,
            this.profileObject.postal_address
          )
        ) {
          this.twin_addresses(true);
        }
        this.loading = false
      } else {
        this.loading = false
      }
    }

  },
  props: {
    latestRegistration: {
      type: Object,
      default() {
        return {}
      }
    },
    inRegistration: {
      type: Boolean,
      default() {
        return false
      }
    },
    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

