var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"section is-relative"},[(_vm.processed && !_vm.learner?.is_suspended)?_c('div',{staticClass:"container"},[_c('title-bar',[(_vm.activeRegistrationId!==null)?_c('span',[_vm._v(_vm._s(_vm.activeRegistration.course.name)+" - ")]):_vm._e(),_vm._v(_vm._s(_vm.activeStep.title))]),_c('b-tabs',{staticClass:"hidden-tabs p-0",attrs:{"destroy-on-hide":true,"expanded":"","multiline":""},model:{value:(_vm.activeStep.tab),callback:function ($$v) {_vm.$set(_vm.activeStep, "tab", $$v)},expression:"activeStep.tab"}},[_c('b-tab-item',{attrs:{"label":"Demographic Form"}},[_c('LearnerDetailsForm',{attrs:{"id":_vm.$store.state.userId,"can-delete":false,"can-edit":true,"latest-registration":_vm.registrations.length ? _vm.registrations[_vm.registrations.length - 1] : {},"edit":"","in-registration":""},on:{"success":_vm.updateDetails,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Programme Selection"}},[_c('RegistrationCourseForm',{attrs:{"id":_vm.$store.state.userId},on:{"success":_vm.selectRegistration,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Application Conditions"}},[_c('ApplicationConditionsPanel',{on:{"success":_vm.PostConditionalRedirect,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Application Fee Payment"}},[_c('RegistrationPaymentPanel',{attrs:{"copy":`
<p class='is-size-5 pb-2'>Please make use of our payment portal for immediate clearance of funds. The payment portal allows you to make secure payments via Debit Card and Credit Card. This allows you to proceed with the application immediately.</p>
<p class='is-size-5 pb-2'>Please note, if you prefer to make direct EFTs, this is processed manually, and as a result there will be a waiting period.</p>`,"eft_details":`
<p class='is-size-5 pb-2'>Email your EFT proof of payment to <a href='mailto:bursar@cornerstone.ac.za'>bursar@cornerstone.ac.za</a></p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Name</span>: Cornerstone Institute (RF) NPC</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Bank</span>: Nedbank</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Branch Code</span>: 102 510</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Number</span>: 107 503 0269</p>
<p class='is-size-5 pb-2'><span class='is-italic'>International Swift No</span>: NEDSZAJJ</p>`,"invoice_type":1,"success_copy":'You have paid your application fee. You can now proceed to document verification.',"success_title":'Application Fee Paid Successfully',"title":'Application Fee Payment: R330'},on:{"success":_vm.SetToPendingVerification,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Module Selection"}},[_c('RegistrationModulesPanel',{attrs:{"learner_id":_vm.$store.state.userId,"registration_id":_vm.activeRegistrationId},on:{"success":_vm.setToApplicationComplete,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Document Verification"}},[_c('RegistrationPendingPanel',{attrs:{"learner_id":_vm.$store.state.userId,"registration_id":_vm.activeRegistrationId},on:{"success":_vm.SetToPendingVerification,"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Initial Fee Payment"}},[_c('RegistrationPaymentPanel',{attrs:{"copy":`
<p class='is-size-5 pb-2'>Please make use of our payment portal for immediate clearance of funds. The payment portal allows you to make secure payments via Debit Card and Credit Card. This allows you to proceed with the application immediately.</p>
<p class='is-size-5 pb-2'>Please note, if you prefer to make direct EFTs, this is processed manually, and as a result there will be a waiting period.</p>
<p class='is-size-5 pb-2'>Once your payment has been processed you will receive further information via email.</p>
<p class='is-size-5 pb-2'>Thank you for registering at Cornerstone Institute!</p>
`,"eft_details":`
<p class='is-size-5 pb-2'>Email your EFT proof of payment to <a href='mailto:bursar@cornerstone.ac.za'>bursar@cornerstone.ac.za</a></p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Name</span>: Cornerstone Institute (RF) NPC</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Bank</span>: Nedbank</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Branch Code</span>: 102 510</p>
<p class='is-size-5 pb-2'><span class='is-italic'>Account Number</span>: 107 503 0269</p>
<p class='is-size-5 pb-2'><span class='is-italic'>International Swift No</span>: NEDSZAJJ</p>`,"invoice_type":2,"show_next":false,"success_copy":'You have paid your initial fee payment. You will receive an email with your credentials and student information. Welcome to the Cornerstone Family!',"success_title":'Initial Fee Payment Successful',"title":'Initial Fee Payment: R5330'},on:{"withdraw":_vm.startWithdraw}})],1),_c('b-tab-item',{attrs:{"label":"Awaiting Document Verification"}},[_c('div',{staticClass:"has-background-white box is-relative card"},[_c('title-bar',{staticClass:"card-header"},[_c('p',{staticClass:"card-header-title"},[_vm._v("Your Documents are being Verified")])]),_c('p',{staticClass:"is-size-5"},[_vm._v("Your submitted documents are currently being verified. You will receive an email when the verification is confirmed.")]),_c('div',{staticClass:"card-content"})],1)])],1)],1):_vm._e(),_c('b-loading',{attrs:{"active":_vm.loading,"can-cancel":false,"is-full-page":false}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }