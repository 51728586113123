import {
  Model
} from '@vuex-orm/core'

export default class ModuleRegistration extends Model {
  static entity = 'moduleRegistration'

  static primaryKey = ['module_id', 'registration_id']


  static fields() {
    return {
      module_id: this.attr(null),
      registration_id: this.attr(null)
    }
  }


}
