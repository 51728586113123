
export const RegistrationModule = {
    namespaced: true,

    state: {
        registrationsOrder : 'desc',
    },
    // Default dispatch will create permission denied message with is-danger background. Colours are derived from bulma presets.

    getters: {
        registrationsOrder: state => state.registrationsOrder,
    },

    mutations: {
        SET_REGISTRATIONS_ORDER(state, payload) {
            state.registrationsOrder = payload;
        }
    },
    actions: {}
};
