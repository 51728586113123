import {TransformModel} from '@/models/TransformModel'
import Module from "@/models/Module";

export default class SupplementOption extends TransformModel {
  static entity = 'supplement-options'
  static primaryKey = 'core_name'

  static fields() {
    return {
      core_name: this.attr(null),
      credits_required_for_core: this.attr(null),
      prior_credits_achieved_for_core: this.attr(null),
      credits_deficit: this.attr(null),
      modules: this.hasManyBy(Module, 'modules_ids'),
      modules_ids: this.attr(() => [])
    }
  }


}
