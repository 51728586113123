export const DepartmentsModule = {
  namespaced: true,

  state: {
    departments: [
      {
        id: 6,
        name: 'Alternative Education'
      }, {
        id: 5,
        name: 'Business Studies'
      },
      {
        id: 1,
        name: 'Education'
      },{
        id: 7,
        name: 'Media Studies'
      },
            {
        id: 4,
        name: 'Psychology'
      },
      {
        id: 2,
        name: 'Sociology'
      },
      {
        id: 3,
        name: 'Theology'
      },

      ]
  },

};
