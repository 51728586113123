<template>
  <nav v-show="isNavBarVisible" id="navbar-main" class="navbar is-fixed-top">
    <div class="navbar-brand">
      <a @click.prevent="menuToggle" :title="toggleTooltip" class="navbar-item is-hidden-touch">
        <b-icon :icon="menuToggleIcon" />
      </a>
      <a class="navbar-item is-hidden-desktop" @click.prevent="menuToggleMobile">
        <b-icon :icon="menuToggleMobileIcon" />
      </a>
      <!-- <div class="navbar-item has-control has-control-without-left-space">
        <div class="control">
          <input class="input" placeholder="Search everywhere..." />
        </div>
      </div>-->
    </div>
    <div class="navbar-brand">

      <!-- <div class="navbar-item has-control has-control-without-left-space">
        <div class="control">
          <input class="input" placeholder="Search everywhere..." />
        </div>
      </div>-->
      <a :class="{'is-active':isMenuNavBarActive}" aria-expanded="false" aria-label="menu" class="navbar-burger"
         data-target="navbarBasicExample" role="button"
         @click="menuNavBarToggle">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div class="navbar-menu fadeIn animated faster" :class="{'is-active':isMenuNavBarActive}">
      <div class="navbar-end">
        <nav-bar-menu class="has-user-avatar">
          <!-- <div class="is-user-avatar">
            <img :src="userAvatar" :alt="userName" />
          </div>-->
          <div v-if="hasPermission('view frontend')" class="is-user-name">
            <span @click="$router.push('/my-profile')">
              <span v-if="learner.username!==null" class="has-text-weight-bold">Username:</span> {{ learner.username }}
              <span class="has-text-weight-bold">Student Number:</span> {{ learner.student_number }}
            </span>
          </div>
          <div v-else class="is-user-name">
            <span>{{ userName }}</span>
          </div>

          <!-- <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item">
              <b-icon icon="account" custom-size="default"></b-icon>
              <span>My Profile</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="settings" custom-size="default"></b-icon>
              <span>Settings</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="email" custom-size="default"></b-icon>
              <span>Messages</span>
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item">
              <b-icon icon="logout" custom-size="default"></b-icon>
              <span>Log Out</span>
            </a>
          </div>-->
        </nav-bar-menu>

        <!-- <nav-bar-menu>
          <b-icon icon="menu" custom-size="default" />
          <span>Sample Menu</span>
          <div slot="dropdown" class="navbar-dropdown">
            <a class="navbar-item">
              <b-icon icon="application" custom-size="default" />
              <span>Some item</span>
            </a>
            <a class="navbar-item">
              <b-icon icon="book" custom-size="default" />
              <span>Another item</span>
            </a>
            <hr class="navbar-divider" />
            <a class="navbar-item">
              <b-icon icon="card-text-outline" custom-size="default" />
              <span>With divider</span>
            </a>
          </div>
        </nav-bar-menu>-->
        <!-- <a
          class="navbar-item"
          :class="{'is-active':isAsideRightVisible}"
          title="Updates"
          @click.prevent="updatesToggle"
        >
          <b-icon icon="bell" custom-size="default" />
          <span>Updates</span>
        </a>-->
        <a class="navbar-item" title="Log out" @click="logout">
          <b-icon icon="logout" custom-size="default" />
          <span>Log out</span>
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import NavBarMenu from "@/components/NavBarMenu";
import { mapState } from "vuex";
import Learner from "@/models/Learner";

export default {
  name: "NavBar",
  components: {
    NavBarMenu
  },
  data() {
    return {
      isMenuNavBarActive: false,
      learner: Object
    };
  },
  computed: {
    menuNavBarToggleIcon() {
      return this.isMenuNavBarActive ? "close" : "dots-vertical";
    },
    menuToggleIcon() {
      return this.isAsideExpanded ? "backburger" : "forwardburger";
    },
    menuToggleMobileIcon() {
      return this.isAsideMobileExpanded ? "backburger" : "forwardburger";
    },
    toggleTooltip() {
      return this.isAsideExpanded ? "Collapse" : "Expand";
    },
    ...mapState([
      "userName",
      "userAvatar",
      "isNavBarVisible",
      "isAsideExpanded",
      "isAsideMobileExpanded",
      "isAsideRightVisible"
    ])
  },
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
    menuToggle() {
      this.$store.commit("asideStateToggle");
    },
    menuToggleMobile() {
      this.$store.commit("asideMobileStateToggle");
    },
    menuNavBarToggle() {
      this.isMenuNavBarActive = !this.isMenuNavBarActive;
    },
    updatesToggle() {
      this.$store.commit("asideRightToggle");
    },
    logout() {
      this.$cookie.delete("access_token");
      this.$cookie.delete("reloaded");

      this.$store.dispatch("auth/updateAuthenticated", false);
      window.location.reload(true);
    }
  },
  mounted() {
    if (!this.$store.state.isAsideExpanded) {
      this.$store.commit("asideStateToggle");
    }
    if (this.hasPermission('view frontend')) {
      Learner.fetchById(parseInt(this.$store.state.userId))
        .then(result => {
          this.learner = Learner.find(this.$store.state.userId);
        })
        .catch(err => {
        });
    }
  }
};
</script>
