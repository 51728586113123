<template>
  <Export

    :date_filter="false"
    :entities="entities"
    :statuses="$store.state.statuses.grouped_statuses"
    :status_filter="true"
    :title="'Registrations By Course'"
    :semesters="semesters"
    :year_filter="true"
  ></Export>
</template>


<script>
import Export from "@/components/Export";
import Semester from "@/models/Semester";

export default {
  name: "registrations",
  components: {
    Export,
  },
  data() {
    return {
      entities: "registrations-by-course",
      admissions_team: [],

      delivery_types: [
        { id: 1, name: "Contact" },
        { id: 2, name: "Online" },
        { id: 3, name: "Multi-Modal" },
      ],
    };
  },
  computed: {
    semesters() {
      return Semester.all();
    },
  },
  mounted() {
    Semester.FetchAll();
  },
};
</script>
