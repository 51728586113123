<template>
  <section class="section">
    <title-bar>Roles</title-bar>
    <card-component
      :header-icon="$tc('icons.create')"
      title="Role index"
      :icon="$tc('icons.roles')"
      v-on:header-icon-click="beginCreateRole()"
    >
      <b-table :data="roles" :striped="true" :hoverable="true" :bordered="true" narrowed>
        <b-table-column v-slot="props" label="Name" field="name" sortable>
          <p>{{props.row.name}}</p>
        </b-table-column>
        <b-table-column v-slot="props" label="Number of permissions" field="permissions">
          <p>{{props.row.permissions.length}}</p>
        </b-table-column>
        <b-table-column v-slot="props" custom-key="actions" label="Edit">
          <b-field>
            <b-button
              @click="$router.push(`/roles/edit/${props.row.id}`)"
              type="is-primary"
            >Go to role</b-button>
          </b-field>
        </b-table-column>
      </b-table>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Role from "@/models/Role";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      roles: [],
    };
  },
  methods: {
    beginCreateRole() {
      if (this.can_create_role) {
        return this.$router.push("roles/create");
      }
      this.$store.dispatch("toast/createToast");
    },
  },
  computed: {
    can_edit_role() {
      return this.$store.getters["entities/permissions/find"]("edit roles");
    },
    can_create_role() {
      return this.$store.getters["entities/permissions/find"]("create roles");
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Role.fetchAllWithPermissions().then((response) => {
      this.roles = response.response.data.data;
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
