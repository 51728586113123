export const AuthModule = {
  namespaced: true,

  state: {
    handshakeSent: false,
    authenticated: false,
    password:null,
    username:null
  },

  actions: {

    updateHandshake({
      commit
    }, status) {
      commit('UPDATE_HANDSHAKE', status);
    },
    updateAuthenticated({
      commit
    }, status) {
      commit('UPDATE_AUTHENTICATED', status);
    }
  },

  mutations: {

    UPDATE_HANDSHAKE(state, status) {
      state.handshakeSent = status;
    },
    UPDATE_AUTHENTICATED(state, status) {
      state.authenticated = status;
    }
  }
};
