<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="submit">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">{{ $tc('Course', 1) }} Selection</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <b-field>
            <template #label>
              <p>Year of Study</p>
              <p class="help has-text-weight-normal">The year of study is the academic year level (i.e. I am a first year student) that you are registering for.</p>
            </template>

            <b-select
              v-model="registrationObject.level"
              placeholder="Year of Study"
              required
            >
              <option v-for="level in 5" :key="level" :value="level">{{ level }}</option>
            </b-select>
          </b-field>
          <ValidationProvider v-slot="{errors}" rules="required">
            <b-field :label="'Qualification'" :type="{'is-danger':errors.length>0}">
              <b-autocomplete
                v-model="courseHold"
                v-debounce:300ms="getFilteredCourse"
                :clear-on-select="false"
                :data="filteredCourses"
                :disabled="course_id!==null"
                :loading="loading"
                :placeholder="'Qualification' + ' Name'"
                autocomplete="off"
                field="name"
                icon="account"
                name="Course"
                required
                @select="option => selectCourse(option)"
              >
                <template slot-scope="props">
                  <p>{{ props.option.code }} | {{ props.option.name }}</p>
                </template>
              </b-autocomplete>
            </b-field>
          </ValidationProvider>
          <article style="margin-top:1%;" v-if="infoHold && infoHold.length > 0" class="message is-info">
            <div class="message-header">
              {{ $tc('Course', 1) }} Requirements
            </div>
            <div class="message-body">
              {{ infoHold }}
            </div>
          </article>
          <b-field v-if="registrationObject.course_id" label="Delivery Type">
            <b-select
              v-model="registrationObject.delivery_type_id"
              placeholder="Select a delivery type"
              required
            >
              <option
                v-if="registrationObject.course.delivery_type_id === 1 || registrationObject.course.delivery_type_id === 3"
                value="1"
              >Contact
              </option>
              <option
                v-if="registrationObject.course.delivery_type_id === 2 || registrationObject.course.delivery_type_id === 3"
                value="2"
              >Online
              </option>
            </b-select>
          </b-field>
          <b-field label="Year of Entry">
            <b-select
              v-model="registrationObject.year"
              placeholder="Select the year"
              required
            >
              <option
                v-for="number in 2"
                :key="number + 'year'"
                :value="(new Date().getFullYear()-1)+number"
              >{{ (new Date().getFullYear() - 1) + number }}
              </option>
            </b-select>
          </b-field>
          <p class="is-size-5">Please ensure that the qualification for which you wish to enroll is correctly entered before hitting "save". Once you save, we will start generating all the rules for
            that qualification.</p>
          <br/>
          <b-field
            v-if="infoHold && infoHold.length > 0"
          >
            <b-checkbox
              name="confirmed"
              v-model="confirmed"
              size="is-medium"
            >I have read and understood the programme requirements
            </b-checkbox>
          </b-field>

        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :disabled="invalid || !seen" :loading="loading" native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import set from "lodash/set";
import Course from "@/models/Course";
import Registration from "@/models/Registration";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "RegistrationCourseForm",
  components: {
    TitleBar,
    ValidationObserver,
    ValidationProvider
  },
  data() {
    return {
      loading: false,
      courseHold: null,
      infoHold: null,
      confirmed: false,
      filteredCourses: [],
      registrationObject: {
        year: 2023,
        user_id: this.id,
        level: null,
        course_id: this.course_id,
        enrolment_date: null,
        registration_status_id: this.course_id !== null ? 2 : 1,
        stage_id: this.course_id !== null ? 98 : 11,
        delivery_type_id: "",
      },


    }
  },
  methods: {
    selectCourse(course) {
      this.registrationObject.course_id = course.id;
      this.registrationObject.course = course;
      this.infoHold = course.student_info;
      if (course.delivery_type_id !== 3) {
        this.registrationObject.delivery_type_id = course.delivery_type_id
      }
    },
    getFilteredCourse(text) {
      this.loading = true;

      Course.search(text, true, 1).then((result) => {
        this.loading = false;
        if (result.entities) {
          this.filteredCourses = result.entities.courses;
        } else {
          this.filteredCourses = [];
        }
      });
    },

    validateAutocomplete(source, target) {

      if (source == null) {
        set(this, target, null)
      }
    },


    async submit() {
      await this.$store.dispatch('loader/show')
      if (this.registrationObject.course_id === null) {
        this.$store.dispatch('toast/createToast', {message: 'Please select a Qualification to proceed'})
        this.courseHold = null
        this.infoHold = null
        await this.$store.dispatch('loader/hide')

        return
      }
      if (this.course_id!==null) {
        this.registrationObject.stage_id = 98
      } else {
        this.registrationObject.stage_id = Course.find(this.registrationObject.course_id).new_application_stage_id ?? 11

      }
      Registration.newRegistration(this.registrationObject).then(({entities}) => {
        this.$buefy.snackbar.open(`${this.$tc('Course', 1)} selected!`)
        this.$emit('success', entities.registrations[0].id)
        this.$emit('close')
        this.$store.dispatch('loader/hide')
      }).catch((err) => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')

      })


    }
  },

  computed: {
    seen() {
      if (this.infoHold && this.infoHold.length > 0) {
        return this.confirmed
      }
      return true
    }
  },
  async created() {
    if (this.course_id !== null) {
      this.selectCourse(Course.query().whereId(this.course_id).first())
      let current = Course.query().whereId(this.course_id).first()
      this.courseHold = `${current.code} | ${current.name}`
      this.infoHold = current.student_info
    }
  },
  props: {

    id: {
      type: String,
      default() {
        return null
      }
    },
    course_id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

