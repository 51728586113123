<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form :class="{'section':!inModal}" class="is-relative" @submit.prevent="handleSubmit(submit)">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Semester</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">
          {{ edit === false ? 'Create a new' : 'Edit' }} Semester</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <b-field :horizontal="!inModal" label="Name"
          >
            <b-input v-model="semesterObject.name"  placeholder="Semester Name" required></b-input>
          </b-field>
          <b-field :horizontal="!inModal" label="Year"
          >
            <b-numberinput v-model.number="semesterObject.year" placeholder="Semester Year" required></b-numberinput>
          </b-field>
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="Start date">
              <b-datepicker
                v-model="start_date"
                :editable="true"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="End date">
              <b-datepicker
                v-model="end_date"
                :editable="true"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="Enrolment Date">
              <b-datepicker
                v-model="enrolment_date"
                :editable="true"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :loading="loading" native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
              <p v-if="edit" class="control">
                <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                  Delete
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import Semester from "@/models/Semester";
import {format} from "date-fns";

extend("required", (value) => {
  if (value !== null) {
    return true;
  }
  return "This field is required";
});
extend("date", (value) => {
  let date = new Date(value)
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return true;
  }
  return "Please enter a valid date";
});
export default {
  name: "SemesterForm",
  components: {
    TitleBar,
    ValidationObserver,
    ValidationProvider

  },
  data() {
    return {
      loaded: false,
      loading: false,
      semesterObject: {
        start_date: null,
        end_date: null,
        year: new Date().getFullYear(),
        name: null,
        enrolment_date: null,
      },

    }
  },
  computed: {
    start_date: {
      get() {
        if (this.semesterObject.start_date !== null) {
          return new Date(this.semesterObject.start_date)
        } else {
          return null
        }
      },
      set(newVal) {
        this.semesterObject.start_date = format(newVal, 'yyyy-MM-dd')
      }
    },
    end_date: {
      get() {
        if (this.semesterObject.end_date !== null) {
          return new Date(this.semesterObject.end_date)
        } else {
          return null
        }
      },
      set(newVal) {
        this.semesterObject.end_date = format(newVal, 'yyyy-MM-dd')
      }
    },
    enrolment_date: {
      get() {
        if (this.semesterObject.enrolment_date !== null) {
          return new Date(this.semesterObject.enrolment_date)
        } else {
          return null
        }
      },
      set(newVal) {
        this.semesterObject.enrolment_date = format(newVal, 'yyyy-MM-dd')
      }
    },
  },
  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Semester`,
        confirmText: `Delete Semester`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this Semester?`,
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      Semester.Delete(this.semesterObject.id).then(() => {
        this.$buefy.snackbar.open(`Semester deleted!`);
        this.$emit("close");
        this.$emit("deleted");

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
        if (!this.inModal) {
          this.$router.push('/semesters')
        }
      });

    },
    submit() {
      this.loading = true
      if (this.edit) {
        if (!this.canEdit) {
          this.$store.dispatch('toast/createToast')
          return
        }
        Semester.Update(this.semesterObject).then(() => {
          this.$buefy.snackbar.open(`Semester updated!`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        if (!this.canCreate) {
          this.$store.dispatch('toast/createToast')
          return;
        }
        Semester.Store(this.semesterObject).then(({entities: {semesters}}) => {
          this.$buefy.snackbar.open(`Semester created!`)
          this.semesterObject.id = semesters[0].id
          this.$router.push(`/semesters/edit/${semesters[0].id}`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    }
  },

  async created() {
    this.loading = true
    if (this.edit) {
      Semester.FetchById(this.id)
        .then(() => {
          this.semesterObject = Semester.query().whereId(this.id).first();
          this.$store.dispatch("loader/hide");
          this.loaded = true;
          this.loading = false
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.loading = false

          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    } else {
      this.loading = false
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

