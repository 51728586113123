import {
  Model
} from '@vuex-orm/core'

export default class moduleCourse extends Model {
  static entity = 'moduleCourse'

  static primaryKey = ['module_id', 'course_id']


  static fields() {
    return {
      module_id: this.attr(null),
      course_id: this.attr(null)
    }
  }


}
