import {TransformModel} from "@/models/TransformModel";
import Learner from "@/models/Learner";
import InvoiceItem from "@/models/InvoiceItem";
import InvoicePayment from "@/models/InvoicePayment";

/**
 * An invoice
 * @property {number} id
 */
export default class Invoice extends TransformModel {
  static entity = 'invoices'

  static fields() {
    return {
      id: this.attr(null),
      invoiceable_id: this.attr(null),
      invoiceable_type: this.attr(null),
      paying_via_eft: this.attr(null),
      needs_manual_verification: this.attr(0),
      user_id: this.attr(null),
      user: this.belongsTo(Learner, 'user_id'),
      has_vat: this.attr(null),
      total_ex_vat: this.attr(null),
      vat_rate: this.attr(null),
      total: this.attr(null),
      is_paid: this.attr(0),
      created_at: this.attr(null),
      updated_at: this.attr(null),
      invoice_items: this.hasMany(InvoiceItem, 'invoice_id'),
      invoice_payments: this.hasMany(InvoicePayment, 'invoice_id'),

    }
  }
  get readable_type () {
    if (this.invoiceable_type.includes('Registration')) {
      return 'Registration'
    }
    return this.invoiceable_type
  }
  /**
   * Returns all documents
   * @function
   * @param {Object} pagination
   * @param {number} pagination.page - Which page to retrieve
   * @param {number} pagination.limit - How many entities to retrieve
   * @param {?Object} [query={}] - Query terms for the request
   * @param {string} [query.user_id] - Query terms for the request
   * @param {Array.<string>} [relationships=[]] - Relationships to bring along
   * @returns {Promise<Response>}
   */

  static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
    return this.api().get(`/invoices`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          }, ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(invoice => {
            if (Object.prototype.hasOwnProperty.call(invoice.attributes, 'invoice_items')) {
              invoice.attributes.invoice_items.map(item=>{
                Object.assign(item,item.attributes)
              })
            }
            if (Object.prototype.hasOwnProperty.call(invoice.attributes, 'invoice_payments')) {
              invoice.attributes.invoice_payments.map(item=>{
                Object.assign(item,item.attributes)
              })
            }
              return {...invoice, ...invoice.attributes}
          })
        },
      }
    )
  }


  /**
   * Returns a checkoutID for Peach payments to generate the checkout CopyAndPay form
   * @function
   * @param {string} invoice_id - The id of the invoice
   * @param {string} type_id - The type of checkout 1-Application Fee

   * @returns {Promise<Response>} - The newly created invoice
   */

  static GenerateCheckout(invoice_id, type_id) {
    return this.api().post(`/invoices/${invoice_id}/generate-checkout`, {type_id: type_id}, {
        save: false
      }
    )
  }

  /**
   * Returns a checkout redirect url for Peach payments to direct the user to the payment protal
   * @function
   * @param {string} invoice_id - The id of the invoice
   * @param {string} type_id - The type of checkout 1-Application Fee

   * @returns {Promise<Response>} - The redirect url
   */

  static GenerateExternalCheckout(invoice_id, type_id) {
    return this.api().post(`/invoices/${invoice_id}/generate-ext-checkout`, {type_id: type_id}, {
        save: false
      }
    )
  }

  /**
   * Generate application invoice
   * @function
   * @param {string} registration_id - The id of the invoice

   * @returns {Promise<Response>} - The newly created invoice
   */

  static GenerateApplicationInvoice(registration_id) {
    return this.api().post(`/registrations/${registration_id}/generate-application-invoice`, {}, {
        dataTransformer: ({data: {data}}) => {
          return {...data, ...data.attributes}
        },
      }
    )
  }
  /**
   * Generate Initial Fee invoice
   * @function
   * @param {string} registration_id - The id of the invoice

   * @returns {Promise<Response>} - The newly created invoice
   */

  static GenerateInitialFeeInvoice(registration_id) {
    return this.api().post(`/registrations/${registration_id}/generate-initial-fee-invoice`, {}, {
        dataTransformer: ({data: {data}}) => {
          return {...data, ...data.attributes}
        },
      }
    )
  }



  /**
   * Update an existing invoice
   * @function
   * @param {Object} invoice - The document object
   * @returns {Promise<Response>} - The newly updated document
   */

  static Update(invoice) {
    return this.api().patch(`/invoices/${invoice.id}`, invoice, {
        dataTransformer: ({data: {data}}) => {
          return {...data, ...data.attributes}
        },
      }
    )
  }

  /**
   * Delete an existing document
   * @function
   * @param {number} document_id - The id of the document
   */

  static Delete(document_id) {
    return this.api().delete(`/documents/${document_id}`, {
        delete: document_id
      }
    )
  }


}
