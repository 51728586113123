<template>
  <div>
    <section class="section padding-top">
      <b-field>
        <b-radio-button v-model="chartType" native-value="bar">
          <span>By Source</span>
        </b-radio-button>

        <!-- <b-radio-button v-model="chartType" native-value="line">
          <span>Over time</span>
        </b-radio-button>-->
      </b-field>
      <div class="columns">
        <div class="column">
          <b-field label="Select start date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMin"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Select end date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMax"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column">
          <b-field label="Owner">
            <b-select v-model="owner_id" placeholder="Filter by owner">
              <option :value="null">All</option>

              <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                <p>{{user.first_name}} {{user.last_name}} | {{user.email}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Creator">
            <b-select v-model="creator_id" placeholder="Filter by creator">
              <option :value="null">All</option>
              <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                <p>{{user.first_name}} {{user.last_name}} | {{user.email}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Status">
            <b-select v-model="status_id" placeholder="Filter by status">
              <option :value="null">All</option>
              <option v-for="status in statuses" :key="status.id" :value="status.id">
                <p>{{status.name}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field grouped group-multiline>
            <b-field label="Year">
              <b-select expanded v-model="$store.state.dates.year" placeholder="Filter by year">
                <option :value="null">All</option>

                <option
                  v-for="number in 4"
                  :key="number + 'year'"
                  :value="(new Date().getFullYear()-3)+number"
                >{{(new Date().getFullYear()-3)+number}}</option>
              </b-select>
            </b-field>
            <b-field label="Semester">
              <b-select
                expanded
                v-model="$store.state.dates.semester_id"
                placeholder="Filter by semester"
              >
                <option :value="null">All</option>

                <option v-for="semester in semesters" :key="semester.id" :value="semester.id">
                  <span>{{semester.name}}</span>
                </option>
              </b-select>
            </b-field>

            <b-field label="Actions">
              <b-field>
                <b-button icon-right="filter" @click="filter()">Filter</b-button>
                <b-field grouped class="ml-6">
                  <b-button
                    icon-right="download"
                    :disabled="download_link==null"
                    tag="a"
                    :href="download_link"
                    target="_blank"
                  >Download</b-button>
                  <b-button icon-right="close" @click="clear()">Clear</b-button>
                </b-field>
              </b-field>
            </b-field>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <card-component :title="title" icon="finance" header-icon="reload">
            <div class="chart-area" v-if="chartType == 'line'">
              <line-chart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="lineChart.chartData"
                :options="lineOptions"
              ></line-chart>
            </div>
            <div class="chart-area" v-if="chartType == 'bar'">
              <bar-chart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-bar-chart"
                :chart-data="barChart.chartData"
                :options="barOptions"
              ></bar-chart>
            </div>
          </card-component>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import BarChart from "@/components/Charts/BarChart";
import LineChart from "@/components/Charts/LineChart";

import Semester from "@/models/Semester";
import EnquiryMadeBySource from "@/models/reports/EnquiryMadeBySource";
import User from "@/models/User";
import { API_URL, ACCESS_TOKEN } from "@/config";
import axios from "axios";

export default {
  name: "enquiries-made",
  components: {
    CardComponent,
    LineChart,
    BarChart,
  },
  data() {
    return {
      year: null,
      semester_id: null,
      download_link: null,

      statuses: [
        { id: 1, name: "None" },
        { id: 2, name: "Won" },
        { id: 3, name: "Lost" },
      ],
      status_id: null,
      owner_id: null,
      creator_id: null,
      admissions_team: Array,
      lineOptions: {
        legend: { display: true },
        maintainAspectRatio: false,
        spanGaps: true,
        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          xAxes: [
            {
              type: "time",
              ticks: {
                source: "data",
                autoSkip: true,
                maxTicksLimit: 20,
              },
              time: {
                distribution: "linear",
                isoWeekday: true,
                unit: "week",
                min: this.$store.state.dates.dateMin,
                max: this.$store.state.dates.dateMax,
              },
              scaleLabel: {
                display: true,
                labelString: "Date",
              },
            },
          ],
          yAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "value",
              },
            },
          ],
        },
      },
      chartType: "bar",
      dataLoaded: false,
      title: "Enquiries made by Source",
      datasets: { by_week: [] },

      barChart: {
        chartData: {
          datasets: [],
        },
      },
      lineChart: {
        chartData: {
          datasets: [],
        },
      },
    };
  },
  watch: {
    chartType() {
      this.filter();
    },
  },
  computed: {
    semesters() {
      return Semester.all();
    },
    barOptions() {
      return {
        maintainAspectRatio: false,

        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },
  },
  mounted() {
    Semester.FetchAll();

    this.$store.dispatch("loader/show");
    User.deleteAll();
    User.fetchAll("edit enquiries").then((result) => {
      this.admissions_team = result.entities.users;
    });
    EnquiryMadeBySource.deleteAll();
    EnquiryMadeBySource.fetchAll(
      this.$store.getters["dates/formattedDateMin"],
      this.$store.getters["dates/formattedDateMax"],
      this.owner_id,
      this.creator_id,
      this.status_id,
      true,
      this.$store.state.dates.year,
      this.$store.state.dates.semester_id
    ).then((result) => {
      this.download_link = result.response.data.exportUrl;

      this.processBar(EnquiryMadeBySource.all());
      this.dataLoaded = true;
      this.$store.dispatch("loader/hide");
    });
  },

  methods: {
    download() {
      this.$store.dispatch("loader/show");
      axios({
        url: `${API_URL}/reports/enquiries-source`,
        method: "GET",
        responseType: "arraybuffer",
        params: {
          filter: true,
          start: this.$store.state.dates.dateMin,
          end: this.$store.state.dates.dateMax,
          ...(this.status_id
            ? {
                status_id: this.status_id,
              }
            : {}),
          ...(this.owner_id
            ? {
                owner_id: this.owner_id,
              }
            : {}),
          ...(this.creator_id
            ? {
                creator_id: this.creator_id,
              }
            : {}),
          download: true,
        },
        headers: {
          Accept: "*/*",
          Authorization: "Bearer " + ACCESS_TOKEN,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `data.csv`);
          document.body.appendChild(link);
          link.click();
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.$store.dispatch("loader/hide");
        });
    },
    clear() {
      this.$store.dispatch("loader/show");
      this.status_id = null;
      this.owner_id = null;
      this.creator_id = null;

      this.$store.state.dates.dateMin = null;
      this.$store.state.dates.dateMax = null;
      this.$store.state.dates.year = null;
      this.$store.state.dates.semester_id = null;
      EnquiryMadeBySource.deleteAll();

      EnquiryMadeBySource.fetchAll(
        this.$store.getters["dates/formattedDateMin"],
        this.$store.getters["dates/formattedDateMax"],
        this.owner_id,
        this.creator_id,
        this.status_id,
        true,
        this.$store.state.dates.year,
        this.$store.state.dates.semester_id
      ).then((result) => {
        this.download_link = result.response.data.exportUrl;

        this.processBar(EnquiryMadeBySource.all());
        this.dataLoaded = true;
        this.$store.dispatch("loader/hide");
      });
    },
    filter() {
      this.$store.dispatch("loader/show");
      this.dataLoaded = false;
      if (this.chartType == "bar") {
        EnquiryMadeBySource.deleteAll();

        EnquiryMadeBySource.fetchAll(
          this.$store.getters["dates/formattedDateMin"],
          this.$store.getters["dates/formattedDateMax"],
          this.owner_id,
          this.creator_id,
          this.status_id,
          true,
          this.$store.state.dates.year,
          this.$store.state.dates.semester_id
        ).then((result) => {
          this.download_link = result.response.data.exportUrl;

          this.processBar(EnquiryMadeBySource.all());

          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        });
      }
      if (this.chartType == "line") {
        EnquiryMadeBySource.deleteAll();

        EnquiryMadeBySourceOverTime.fetchAll(
          this.$store.getters["dates/formattedDateMin"],
          this.$store.getters["dates/formattedDateMax"]
        ).then(() => {
          let data = EnquiryMadeBySourceOverTime.query().orderBy("$id").get();

          this.lineOptions.scales.xAxes[0].time.min = this.$store.state.dates.dateMin;
          this.lineOptions.scales.xAxes[0].time.max = this.$store.state.dates.dateMax;
          this.processLineResponse(data);
          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        });
      }
    },
    oneWeekAgo() {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return oneWeekAgo;
    },
    processBar(reports) {
      let data = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: this.$store.state.chart.default_colors,
            backgroundColor: this.$store.state.chart.default_colors,
          },
        ],
      };

      for (let item of reports) {
        data.labels.push(item.name);
        data.datasets[0].data.push(item.enquiries_count);
      }

      this.barChart.chartData = data;
      this.dataLoaded = true;
    },
    processLineResponse(data) {
      let by_week_data = [];
      let labels = [];

      data.forEach((element) => {
        if (!labels.includes(element.user)) {
          labels.push(element.user);
        }
      });
      for (let index = 0; index < labels.length; ++index) {
        let value = labels[index];
        let dataset = {
          label: value,
          data: [],
          backgroundColor: this.$store.state.chart.default_colors[index],
          borderColor: this.$store.state.chart.default_colors[index],
          fill: false,
        };
        let rawByUser = EnquiryMadeBySourceOverTime.query()
          .where("user", value)
          .orderBy("$id")
          .get();

        rawByUser.forEach((element) => {
          dataset.data.push({
            x: `${element.year}W${element.week}`,
            y: element.enquiries_created,
          });
        });
        by_week_data.push(dataset);
      }

      this.lineChart.chartData.datasets = by_week_data;
      this.lineChart.chartData.labels = labels;
    },
  },
};
</script>
