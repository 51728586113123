<template>
  <Export  :date_filter="false" :entities="entities" :title="$tc('Learner',2)"></Export>
</template>


<script>
import Export from "@/components/Export";

export default {
  name: "learners",
  components: {
    Export,
  },
  data() {
    return {
      entities: "users",
    };
  },
};
</script>
