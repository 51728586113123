<template>
  <div>

    <LearnerCourseItem v-for="course in courses" :course="course" :user_id="user_id" :key="course.id">
    </LearnerCourseItem>
    <p class="has-text-centered" v-if="courses.length===0">
      This {{$tc('learner',1)}} has not selected a {{$tc('course',1)}}.
    </p>
    <b-loading :is-full-page="false" :active.sync="isLoading" :can-cancel="false"></b-loading>

  </div>

</template>
<script>

import Course from "@/models/Course";
import LearnerCourseItem from "@/components/LearnerCourseItem";

export default {
  name: 'LearnerCourses',
  components: {LearnerCourseItem},
  data() {
    return {
      courses:[],
      isLoading: false,
    }
  },
  props: {
    user_id: {
      type: Number,
      required: true
    }
  },
  methods: {},
  computed: {
    // courses() {
    //   return Course.query().get()
    // }
  },
  mounted() {
    Course.deleteAll()
    this.isLoading = true
    Course.fetchAllUserCourses(1, 999, this.user_id).then((response) => {

      this.courses = response.entities!==null?response.entities.courses:[]
      this.isLoading = false
    }).catch(err => {
      this.$store.dispatch("toast/createToast", {
        message: JSON.stringify(err.response.data),
      });
      this.isLoading = false
    })
  }
}
</script>
