<template>
  <form @submit.prevent="submitActivity()">
    <h3 class="has-text-centered is-capitalized">{{action}} Activity</h3>
    <b-field class="margin-top">
      <b-field>
        <b-input
          autocomplete="off"
          v-model="form.name"
          :placeholder="namePlaceholder"
          name="name"
          required
        />
      </b-field>
    </b-field>
    <b-field>
      <b-field>
        <b-radio-button
          v-for="type in activity_types"
          :key="type.id"
          v-model="form.type_id"
          :native-value="type.id"
        >
          <b-tooltip :label="type.name" position="is-top">
            <b-icon :icon="type.icon"></b-icon>
          </b-tooltip>
        </b-radio-button>
      </b-field>
    </b-field>
    <b-field>
      <b-field>
        <ckeditor :editor="editor" v-model="note" :config="editorConfig"></ckeditor>
      </b-field>
    </b-field>
    <b-field>
      <b-field>
        <b-datetimepicker
          :timepicker="timepicker"
          placeholder="Type or select a date..."
          icon="calendar-today"
          editable
          v-model="due_date"
        ></b-datetimepicker>
      </b-field>
    </b-field>
    <b-field label="Assign to">
      <b-field>
        <b-select v-model="form.assigned_to_user_id" placeholder="Select the activity assignee">
          <option v-for="user in team" :key="user.id" :value="user.id">
            <p>{{user.first_name}} {{user.last_name}} | {{user.email}}</p>
          </option>
        </b-select>
      </b-field>
    </b-field>
    <b-field>
      <b-field grouped>
        <div class="control">
          <b-button native-type="submit" type="is-primary">Submit</b-button>
        </div>
        <div class="control">
          <b-button @click.prevent="cancelActivity()" type="is-secondary">Cancel</b-button>
        </div>

        <b-checkbox
          :true-value="1"
          :false-value="0"
          type="is-success"
          v-model="form.is_complete"
        >Completed</b-checkbox>
      </b-field>
    </b-field>
    <b-loading :is-full-page="false" :active.sync="loadingActivity" :can-cancel="false"></b-loading>
  </form>
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { editorConfig } from "@/EditorConfig";
import Activity from "@/models/Activity";

export default {
  name: "ActivityForm",
  props: {
    model_type: { type: String, required: true },
    model_id: { type: Number, required: true },
    team: {
      type: Array,
      required: true,
    },
    permissions: {
      type: Object,
      default: () => ({
        can_create: false,
        can_edit: false,
      }),
    },
    activity: {
      type: Object,
      default: () => {},
    },
    action: {
      type: String,
      default: "create",
    },
  },
  data() {
    return {
      loadingActivity: false,
      timepicker: {
        incrementMinutes: 5,
        hourFormat: "12",
      },
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{ placeholder: "An optional description" },
      },
      form: {
        is_complete: 0,
        name: "",
        type_id: 1,
        note: "",
        due_at: null,
        assigned_to_user_id: null,
      },
      activity_types: [
        { id: 1, name: "Call", icon: "phone" },
        { id: 2, name: "Email", icon: "email" },
        { id: 3, name: "Task", icon: "clock" },
      ],
    };
  },
  computed: {
    note: {
      get() {
        if (this.form.note == null) {
          return "";
        }
        return this.form.note;
      },
      set(newVal) {
        this.form.note = newVal;
      },
    },
    due_date: {
      set(newVal) {
        this.form.due_at = this.$moment(newVal).format("YYYY-MM-DD hh:mm:ss");
      },
      get() {
        if (this.form.due_at == null) {
          return null;
        }
        return new Date(this.form.due_at);
      },
    },
    namePlaceholder() {
      return this.activity_types.find((type) => type.id == this.form.type_id)
        .name;
    },
  },
  watch: {
    activity() {
      this.form = this.activity;
    },
  },
  methods: {
    cancelActivity() {
      this.form = {
        is_complete: 0,
        name: "",
        type_id: 1,
        note: "",
        due_at: null,
        assigned_to_user_id: null,
      };
      this.$emit("cancelActivity");
    },
    submitActivity() {
      if (this.action == "create") {
        if (this.permissions.can_create) {
          this.loadingActivity = true;

          Activity.newActivity(this.model_type, this.model_id, this.form)
            .then(() => {
              this.$buefy.snackbar.open({
                message: "Activity created",
                queue: false,
                type: "is-link",
              });
              this.loadingActivity = false;
              this.cancelActivity();
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        } else {
          this.$store.dispatch("toast/createToast");
        }
      } else if (this.action == "edit") {
        if (this.permissions.can_edit) {
          this.loadingActivity = true;

          Activity.updateRemote(this.model_type, this.model_id, this.form)
            .then(() => {
              this.$buefy.snackbar.open({
                message: "Activity edited",
                queue: false,
                type: "is-link",
              });
              this.loadingActivity = false;
              this.cancelActivity();
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        } else {
          this.$store.dispatch("toast/createToast");
        }
      }
    },
  },
};
</script>
