<template>
  <div id="reports">
    <div class="columns min-height-classlist">
      <div ref="container" class="column is-one-fifth reports-list has-background-white" @mouseenter="psUpdate">
        <hr />
        <b-collapse
          class="card"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">{{ collapse.title }}</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
            </a>
          </div>
          <div
            v-show="hasPermission(list.permission)"
            v-for="list in collapse.lists"
            :key="list.title"
            class="card-content"
          >
            <div class="content">
              <a @click="$router.push('/lookups/'+list.to)">{{ list.title }}</a>
            </div>
          </div>
        </b-collapse>
      </div>

      <router-view class="column exports-view is-four-fifth" />
    </div>
  </div>
</template>


<script>
import PerfectScrollbar from "perfect-scrollbar";

import Lookup from "@/models/Lookup";
export default {
  components: {},
  data() {
    return {
      isOpen: 0,
      ps:null,
      collapses: [
        {
          title: "Lookups",
          lists: [
            {
              title: "Marketing sources",
              to: "marketing-sources",
              permission: "view lookups",
            },
            {
              title: "Qualifications",
              to: "qualifications",
              permission: "view lookups",
            },
            {
              title: "Institutions",
              to: "institutions",
              permission: "view lookups",
            },
            {
              title: "Education Levels",
              to: "education-levels",
              permission: "view lookups",
            }, {
              title: "Gender",
              to: "gender",
              permission: "view lookups",
            },{
              title: "Ethnicity",
              to: "ethnicity",
              permission: "view lookups",
            },{
              title: "Identification Types",
              to: "identification-types",
              permission: "view lookups",
            },{
              title: "Nationality",
              to: "nationality",
              permission: "view lookups",
            },{
              title: "Language",
              to: "language",
              permission: "view lookups",
            },{
              title: "Subject",
              to: "subject",
              permission: "view lookups",
            },{
              title: "RPL Reason",
              to: "rpl-reason",
              permission: "view lookups",
            },{
              title: "Permit Type",
              to: "permit-type",
              permission: "view lookups",
            },{
              title: "Religion",
              to: "religion",
              permission: "view lookups",
            },{
              title: "Marital Status",
              to: "marital-status",
              permission: "view lookups",
            },{
              title: "Exemption Type",
              to: "exemption-type",
              permission: "view lookups",
            },{
              title: "Where Hear",
              to: "where-hear",
              permission: "view lookups",
            },{
              title: "Payment Method",
              to: "payment-method",
              permission: "view lookups",
            },{
              title: "Examination Authority",
              to: "examination-authority",
              permission: "view lookups",
            },{
              title: "Terms",
              to: "terms",
              permission: "view lookups",
            },{
              title: "Staff Category",
              to: "staff-categories",
              permission: "view lookups",
            },{
              title: "Employment Status",
              to: "employment-status",
              permission: "view lookups",
            },{
              title: "Appointment Type",
              to: "appointment-type",
              permission: "view lookups",
            },{
              title: "CESM Codes",
              to: "cesm-codes",
              permission: "view lookups",
            },{
              title: "Programme Achievements",
              to: "programme-achievements",
              permission: "view lookups",
            },{
              title: "Honours Achievements",
              to: "honours-achievements",
              permission: "view lookups",
            },{
              title: "Programme Achievement Type",
              to: "programme-achievement-type",
              permission: "view lookups",
            },
          ],
        },
      ],
    };
  },

  mounted() {
    Lookup.fetchAll();
    this.ps = new PerfectScrollbar(this.$refs.container);
  },
  methods: {
    psUpdate() {
      if (this.ps) {
        this.ps.update();
      }
    },
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
  },
};
</script>

<style lang="scss" >
.min-height-classlist {
  min-height: 100vh;
}
.reports-list {
  position: relative;
  height: 100vh;
  .card:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
.exports-view {
  max-width: 80%;
}
#reports {
  max-width: 85vw;
}
canvas {
  height: 500px !important;
}
</style>
