<template>
  <IntakeForm :id="id" :can-create="can_create_intakes" :can-delete="can_delete_intakes" :can-edit="can_edit_intakes" edit/>
</template>

<script>
import IntakeForm from "@/components/intakes/IntakeForm";

export default {
  props: ['id'],
  name: 'IntakeEdit',
  components: {
    IntakeForm,

  },
  computed: {
    parsedId() {
      return parseInt(this.id)
    },
    can_delete_intakes() {
      return !!this.$store.getters["entities/permissions/find"]("delete courses");
    }, can_edit_intakes() {
      return !!this.$store.getters["entities/permissions/find"]("edit courses");
    }, can_create_intakes() {
      return !!this.$store.getters["entities/permissions/find"]("create courses");
    }
  }
};
</script>
