<template>
  <QualificationForm  :can-create="can_create_qualifications" :can-delete="can_delete_qualifications" :can-edit="can_edit_qualifications" />
</template>

<script>
import QualificationForm from "@/components/Qualifications/QualificationForm";

export default {
  name: 'QualificationCreate',
  components: {
    QualificationForm,

  },
  computed:{
    can_delete_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("delete qualifications");
    }, can_edit_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("edit qualifications");
    }, can_create_qualifications() {
      return !!this.$store.getters["entities/permissions/find"]("create qualifications");
    }
  },
};
</script>
