import {
  Bar,
  mixins,

} from 'vue-chartjs'
import {
  Chart
} from 'chart.js'
const {
  reactiveProp
} = mixins


export default {
  name: 'bar-chart',
  extends: Bar,
  mixins: [reactiveProp],
  props: ['options', 'chartData'],


  data() {
    return {
      ctx: null
    }
  },

  watch: {
    watch: {
      options() {

        this.$data._chart.destroy();
        this.renderChart(this.chartData, this.options);
      },
      chartData() {

        this.$data._chart.update()
      }

    }
  },
  mounted() {
    (Chart.defaults.global.legend.display = false)

    this.renderChart(this.chartData, this.options)

  }
}
