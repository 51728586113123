import {TransformModel} from '@/models/TransformModel'

export default class CourseSubjects extends TransformModel {
  static entity = 'course-subjects'

  static fields() {
    return {
      id: this.attr(null),
      school_subject_id:this.attr(null),
      min_grade:this.attr(null),

    }
  }

  static FetchAll(page = 1, limit = 999, course_id) {
    return this.api().get(`/courses/${course_id}/school-subjects`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(subject => {
          subject.attributes.min_grade = parseFloat(subject.attributes.min_grade)
          subject.attributes.school_subject_id = parseInt(subject.id)

          return {...subject, ...subject.attributes}
        })
      },
    })
  }

  static Update(course_subjects) {
    return this.api().patch(`/courses/${course_subjects.course_id}/school-subjects`, course_subjects, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }


}
