import {
  TransformModel
} from '@/models/TransformModel'
import Learner from "@/models/Learner"
import Module from "@/models/Module"
export default class RPL extends TransformModel {
  static entity = 'registered prior learnings'

  static fields() {
    return {
      id: this.attr(null),
      user: this.belongsTo(Learner, 'user_id'),
      user_id: this.attr(null),
      module: this.belongsTo(Module, 'module_id'),
      module_id: this.attr(null),
      institution_id: this.attr(null),
      reason_id: this.attr(null),
      grade: this.attr(null),
      symbol: this.attr(null),
      type_id: this.attr(null),
      comment: this.attr(null),
      status_id:this.attr(null)
    }
  }


  static fetchAll(learner_id) {
    return this.api().get(`/learners/${learner_id}/prior-learnings?with[]=module`, {
      dataTransformer: (response) => {
        let storageArray = []

        if (response.data.data.length > 1) {
          response.data.data.forEach(element => {
            let target = element
            Object.assign(target, element.attributes)
            if (target.module == null) {
              delete target.module_id
              target.module = {}

            }
            Object.assign(target.module, element.module.attributes)

            storageArray.push(target)
          });
        } else if (response.data.data.length == 1) {
          let target = response.data.data[0]

          Object.assign(target, target.attributes)
          if (target.module == null) {
            delete target.module_id
            target.module = {}

          }
          Object.assign(target.module, target.module.attributes)

          storageArray.push(target)

        } else {
          return
        }

        return storageArray
      }
    })
  }
  static make(rpl) {
    return this.api().post(`/learners/${rpl.user_id}/prior-learnings`, rpl, {
      save: false
    })
  }
  static updateRemote(rpl) {
    return this.api().patch(`/learners/${rpl.user_id}/prior-learnings/${rpl.id}`, rpl, {
      save: false
    })
  }
  static deleteRemote(rpl) {
    return this.api().delete(`/learners/${rpl.user_id}/prior-learnings/${rpl.id}`, {
      save: false
    })
  }


}
