<template>
<Export
  :date_filter="false"
  :course_filter="true"
  :entities="entities"
  :year_filter="true"
  title="Graduand List"
  >
</Export>
</template>

<script>
import Export from "@/components/Export";
export default {
  name: "GraduandList",
  components: {Export},
  data() {
    return {
      entities: "graduand-list",
    };
  },
}
</script>

<style scoped>

</style>
