<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="handleSubmit(submit)">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Specialisations</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <p class="is-size-5 pb-2">These are the subjects (majors) you wish to focus on in your qualification.</p>
          <b-field v-if="requiredCoreOptions.length>0" label="Required Options">
            For this {{ $tc('Course', 1) }} the following subjects are required.:
            <ul style="list-style-type: disc" class="pl-4">
              <li v-for="core in requiredCoreOptions" :key="core.id+'req'">
                {{ core.core_option }}
              </li>
            </ul>
          </b-field>
          <b-field v-if="course.min_extra_cores>0" label="Non-required Options">
            Please pick at least {{ course.min_extra_cores }} options from the below list<span v-if="course.max_extra_cores>0">, up to a maximum of {{ course.max_extra_cores }}.</span><span
            v-else>.</span>
          </b-field>
          <label  class="label">Optional Subjects</label>
            <div class="columns is-multiline">
              <b-field class="column is-4 is-flex is-align-content-center" v-for="core in nonRequiredCoreOptions" :key="core.id">
                <b-checkbox v-model="selected_core_ids" :disabled="(cores_disabled&& !selected_core_ids.includes(core.id))||!canEdit" :native-value="core.id"
                            :required="core.is_required===1||more_cores_required"
                            size="is-large">{{ core.core_option }}
                </b-checkbox>
              </b-field>
            </div>
          <div v-if="selected_core_ids.length>0">
            <CoreOptionListItem v-for="(core_option,index) in user_core_options_hold" :key="index" :can-edit="canEdit" :core_option="core_option" :edit="edit"/>
          </div>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>

              <p class="control">
                <b-button :disabled="!canEdit" :loading="loading" native-type="submit"
                          type="is-primary">{{ edit ? 'Next' : 'Save' }}
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Lookup from "@/models/Lookup";
import UserCoreOption from "@/models/UserCoreOption";
import CourseCoreOption from "@/models/CourseCoreOption";
import CoreOptionListItem from "@/components/Registrations/conditions/CoreOptionListItem";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "CoreOptions",
  components: {
    TitleBar,
    ValidationObserver,
    CoreOptionListItem
  },
  data() {
    return {
      loading: false,
      selected_core_ids: [],
      user_core_options_hold: [],
      filtered_module_code: [],

    }
  },
  watch: {
    selected_core_ids(newVal) {

      newVal.map((option_id) => {
        if (!this.user_core_options_hold.some(user_option => {
          return parseInt(user_option.option_id) === parseInt(option_id)
        })) {
          if (UserCoreOption.query().whereId(option_id.toString()).exists()){
            this.user_core_options_hold.push(UserCoreOption.query().whereId(option_id.toString()).withAll().first())
          }
          else {
            this.user_core_options_hold.push(({
              id: parseInt(option_id),
              course_id: this.course.id, option_id: parseInt(option_id), option: CourseCoreOption.query().whereId(parseInt(option_id)).first(),
              previous_marks: [
                // {
                //   institution: null,
                //   module_code: null,
                //   grade: null,
                //   credits: null,
                //   semester: null
                // }
              ]
            }))
          }

        }
      })
      this.user_core_options_hold = this.user_core_options_hold.filter(item => {
        return this.selected_core_ids.includes(parseInt(item.option_id))
      })
    },
  },
  methods: {
    async submit() {
      try {
        await this.$store.dispatch('loader/show')
        await UserCoreOption.Update({
          core_option: this.user_core_options_hold
        }, this.learner_id)
        await this.$store.dispatch('loader/hide')
        this.$emit('next', 'core')
      } catch (err) {
        this.handleError(err)
        await this.$store.dispatch('loader/hide')
      }
    }
  },
  computed: {

    requiredCoreOptions() {
      return CourseCoreOption.query().where('is_required', 1).withAll().get()
    },    nonRequiredCoreOptions() {
      return CourseCoreOption.query().where('is_required', 0).withAll().get()
    },
    coreOptions() {
      return CourseCoreOption.query().withAll().get()
    },
    more_cores_required() {
      let required_cores = this.requiredCoreOptions.map(item => parseInt(item.id))
      return this.selected_core_ids.filter(id => {
        return !required_cores.includes(id)
      }).length < this.course.min_extra_cores
    },
    cores_disabled() {
      let required_cores = this.requiredCoreOptions.map(item => item.id)

      return this.selected_core_ids.filter(id => {
        return !required_cores.includes(id)
      }).length >= this.course.max_extra_cores
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([UserCoreOption.deleteAll(), CourseCoreOption.deleteAll()])
    await Promise.all([Lookup.fetchAll({lookup_type_id: [5]}), UserCoreOption.FetchAll(1, 999, this.learner_id), CourseCoreOption.FetchAll(1, 999, this.course.id)])
    this.user_core_options_hold = UserCoreOption.query().where('course_id', parseInt(this.course.id)).withAll().get()
    this.selected_core_ids = UserCoreOption.query().where('course_id', parseInt(this.course.id)).get().map(item => parseInt(item.id))
    if (this.canEdit) {
      this.requiredCoreOptions.map(option=>{
        if (!this.selected_core_ids.includes(option.id)) {
          this.selected_core_ids.push(option.id)
        }
      })
    }
    this.loading = false
  },
  props: {

    learner_id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    course: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

