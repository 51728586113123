<template>
  <div>
  <b-field class="pb-2" label="Disability Indicated?">
  <b-switch v-model="disability" :false-value="false" :true-value="true" :disabled="disabled">
    {{ disability === true ? "Yes" : "No" }}
  </b-switch>
</b-field>
  <b-field v-if="!displayOnlyIndicated">
  <template v-if="disability">
    <div class="content">

      <b-field class="pb-2" label="Disability Status">
        <LookupMultiSelect
          :value="value.disability_ids"
          @input="propagateUpdate('disability_ids',$event)"
          :disabled="disabled"
          :placeholder="'Disabilities'"
          :lookupId="27"/>
      </b-field>
      <b-field class="pb-1" label="Accommodations">
        <LookupMultiSelect
          :value="value.accommodation_ids"
          @input="propagateUpdate('accommodation_ids',$event)"
          :disabled="disabled"
          :placeholder="'Accommodations'"
          :lookupId="28"/>
      </b-field>

    </div>
  </template>
  <b-field v-if="disability">
    <b-field v-if="showUploads">
      <card-component icon="cloud-upload" title="Submit required documentation">
        <div class="content">
          <p class="is-size-4">Required documents:</p>
          <ul>
            <li>Certified copy of National Senior Certificate or Equivalent</li>
            <li>Certified copies of tertiary qualifications including academic record(s)</li>
            <li>Certified copy of RSA ID /OR Certified copy of Passport for non-South African citizens</li>
            <li>If you have a foreign qualification, it must have the appropriate SAQA Verification Certificate</li>
          </ul>
        </div>
        <div v-if="can_create_media" class="columns">
          <b-field class="column is-4">
            <div class="columns is-centered">
              <div class="column is-12 has-text-centered">
                <b-upload v-model="dropFile" drag-drop>
                  <section class="section">
                    <div class="content has-text-centered">
                      <p>
                        <b-icon icon="upload" size="is-large"></b-icon>
                      </p>
                      <p>Drop your file here or click to upload</p>
                      <p>Max file size of 10mb</p>
                    </div>
                  </section>
                </b-upload>
              </div>
            </div>
          </b-field>
          <div v-if="dropFile" class="upload-file-list column">
            <div class="notification">
              <div class="level">
                <div class="level-left">
                  <div class="level-item">
                    <form @submit.prevent="submitMedia">
                      <b-icon custom-size="default" icon="cloud-upload"></b-icon>
                      <span>{{ dropFile.name }}</span>
                      <b-field>
                        <b-field>
                          <b-input
                            v-model="dropFile.caption"
                            name="caption"
                            placeholder="Caption"
                          />
                        </b-field>
                      </b-field>
                      <b-field>
                        <b-select v-model="dropFile.tag" placeholder="Select a tag" >
                          <option v-for="(tag,index) in $store.state.applications.tags" :key="index" :value="tag">
                            {{ tag }}
                          </option>
                        </b-select>
                      </b-field>
                      <b-field>
                        <b-field grouped>
                          <div class="control">
                            <b-button native-type="submit" type="is-primary">Submit upload</b-button>
                          </div>
                        </b-field>
                      </b-field>
                    </form>
                  </div>
                </div>
                <div class="level-right">
                  <div class="level-item">
                    <a class="tag is-delete" @click.prevent="deleteDropFile()"></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <b-table
          v-if="can_view_media"
          :bordered="true"
          :data="media"
          :hoverable="true"
          :striped="true"
          class="margin-top"
        >
          <b-table-column v-slot="props" field="filename" label="Filename" sortable>{{ props.row.filename }}</b-table-column>

          <b-table-column v-slot="props" field="tag" label="Tag" sortable>{{ props.row.tag }}</b-table-column>
          <b-table-column v-slot="props"
                          field="caption"
                          label="Caption"
                          sortable
          >{{ return_caption(props.row.caption) }}
          </b-table-column>
          <b-table-column v-slot="props" centered custom-key="actions">
            <b-field>
                    <span class="control">
                      <b-button
                        :href="props.row.temporary_url"
                        tag="a"
                        target="_blank"
                        type="is-info"
                      >View
                      </b-button>
                    </span>
              <span v-if="can_delete_media" class="control">
                      <b-button
                        type="is-danger"

                        @click="start_delete_media(props.row.id)"
                      >Delete
                      </b-button>
                    </span>
            </b-field>
          </b-table-column>
        </b-table>
        <b-loading :active.sync="updatingMedia" :can-cancel="false" :is-full-page="false"></b-loading>
      </card-component>
    </b-field>
  </b-field>
  </b-field>
  </div>
</template>


<script>

import {defineComponent} from "vue";
import CardComponent from "@/components/CardComponent.vue";
import LookupMultiSelect from "@/components/global/selects/LookupMultiSelect.vue";
import Media from "@/models/Media";
import isEqual from "lodash/isEqual";
import checkExtension from "@/mixins/FileTypes";

export default defineComponent({
  components: {LookupMultiSelect, CardComponent},
  mixins: [checkExtension],
  data() {
    return {
      loading: false,
      disability: false,
      dropFile: null,
      updatingMedia: false,
      fetchingMedia: false,
    }
  },
  props: {
    showUploads: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    useInParent: {
      type: Boolean,
      default() {
        return false;
      },
    },
    displayOnlyIndicated: {
      type: Boolean,
      default() {
        return false;
      },
    },
    profile: {
      type: Object,
      default() {
        return null;
      },
    },
    value: {
      type: Object,
      default() {
        return null
      },
    },
  },

  methods: {
    propagateUpdate(key, value) {
      const newValue = {
        ...this.value,
        [key]: value
      }
      this.$emit("input", newValue)
    },
    start_delete_media(mediaId) {
      this.$buefy.dialog.confirm({
        title: "Deleting Media",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Media",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteMedia(mediaId),
      });
    },
    deleteMedia(mediaId) {
      this.updatingMedia = true;
      Media.deleteRemote(mediaId)
        .then(() => {
          Media.delete(mediaId);
          Media.fetchAll(this.profile.user_id)
            .then(() => {
              this.updatingMedia = false;
            })
            .catch((err) => {
              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    deleteDropFile() {
      this.dropFile = null;
    },
    submitMedia() {
      this.updatingMedia = true;
      let formData = new FormData();
      formData.append("file", this.dropFile);
      formData.append("tag", this.dropFile.tag);
      formData.append("caption", this.dropFile.caption);

      Media.uploadToLearner(formData, this.$store.state.userId)
        .then(() => {
          Media.fetchAll(this.profile.user_id)
            .then(() => {
              this.dropFile = null;
              this.updatingMedia = false;
            })
            .catch(() => {
              this.updatingMedia = false;

              this.$store.dispatch("toast/createToast", {
                message: "Upload failed",
              });
            });
        })
        .catch((err) => {
          this.updatingMedia = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    return_caption(caption) {
      if (caption === "undefined") {
        return "None";
      } else {
        return caption;
      }
    },
    async fetchMedia(id){
      try {
        if(this.fetchingMedia) return
        this.loading = true
        this.fetchingMedia = true
        await Media.fetchAll(id)
        this.loading = false
        this.fetchingMedia = false
      } catch (error) {
        this.fetchingMedia = false
        this.loading = false
        console.log(error)
      }
    }
  },
  watch: {
    profile: {
      handler: function (newVal) {
        if(!this.media.length && newVal?.user_id){
          this.fetchMedia(newVal?.user_id)
        }
      }
    },
    disability: {
      handler: function (newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;
        this.$emit("hasDisabilitySelected", this.disability)
      }
    },
    dropFile(val) {
      if (val !== null) {
        if (!this.checkExtension(val)) {
          this.dropFile = null
        }
      }
    },
    value: {
      handler: function (newVal, oldVal) {
        if (isEqual(newVal, oldVal)) return;
        this.disability = newVal.accommodation_ids?.length > 0 || newVal.disability_ids?.length > 0;
        this.$emit("input", newVal)
      }
    }
  },
  computed: {
    can_delete_media() {
      return this.$store.getters["entities/permissions/find"]("delete media");
    },
    can_view_media() {
      return this.$store.getters["entities/permissions/find"]("view media");
    },
    can_create_media() {
      return this.$store.getters["entities/permissions/find"]("create media");
    },
    media() {
      return Media.all();
    },
  },
})

</script>
