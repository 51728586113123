import * as _ from "lodash";
import { isReactive, unref } from "vue";

export const normalize = (obj, key = "attributes") => {
    // Normalize the specified key field of the given object
    // Return the normalized object
    if (_.isArray(obj)) {
        return obj.map(value => normalize(value, key));
    } else if (_.isObject(obj) && !_.isFunction(obj)) {
        return _.omit(
            {
                ...obj,
                ..._.mapValues(obj[key], value => normalize(value, key)),
            },
            key,
        );
    } else {
        return obj;
    }
};

export const keyedObjToObjArray = (object, key = "name") => {
    return Object.entries(object).map(([objKey, value]) => {
        return {
            id: parseInt(objKey),
            [key]: value,
        };
    });
};

export const transform = ({ data: { data } }) => {
    return normalize(data);
};

export const unwrap = obj => {
    if (!obj) return obj;
    return isReactive(obj) ? { ...obj } : unref(obj);
};
