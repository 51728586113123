<template>
  <div class="is-relative">
    <b-autocomplete
      v-model="selectedCode"
      :clear-on-select="true"
      :data="codeData"
      :icon="$tc('icons.modules')"
      :loading="isLoadingLMSCodes"
      :disabled="isDisabled"
      autocomplete="off"
      field="id"
      name="id"
      placeholder="Search"
      required
      v-debounce:300ms="text=>searchForLMSCodes(text)"
      @select="option => selectCode(option)"
    >
      <template v-slot="props">
        <div>
          <hr class="m-1"/>
          <p>{{ props.option.id }}</p>
          <small>{{ props.option.title }}<span v-if="props.option?.shortDescription"> | {{ props.option?.shortDescription }}</span></small>
        </div>
      </template>
    </b-autocomplete>
    <div >
      <small class="has-text-grey is-text-sm helpText">Search for the module name or full site code with "s, e.g. "2fde1a85-fdd7-4ac2-a892-5c960a83b16e".</small>
    </div>
  </div>
</template>

<script>
import ModuleLMSCode from "@/models/ModuleLMSCode";
import {normalize} from "@/utils/data";

export default{
  name: "LMSCodeSearchSelect",
  props:{
    value: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    // codeData: {
    //   type: Array,
    //   default: null,
    // },
  },
  data(){
    return{
      codeData: [],
      isLoadingLMSCodes:false,
    }
  },
  computed:{
    selectedCode:{
      get(){
        return this.value;
      },
      set(newVal){
        return true
        // this.$emit("input", newVal);
      },
    },
  },
  methods:{
    async searchForLMSCodes(text){
      try{
        this.isLoadingLMSCodes = true;
        let response = await ModuleLMSCode.search(text)
        if(response?.response?.data){
          this.codeData = normalize(response?.response?.data);
        }
        this.isLoadingLMSCodes = false;
      } catch (e) {
        this.isLoadingLMSCodes = false;
        this.$buefy.snackbar.open({
          message: "Error searching for LMS Codes",
          type: "is-danger",
        });
        throw e;
      }
    },
    selectCode(option){
      this.$emit("input", option.id);
    },
  }
}
</script>

<style>
.helpText{
  position: absolute;
  top: 100%;
  width: 150%;
}
</style>



