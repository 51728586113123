import {
  TransformModel
} from '@/models/TransformModel'
import Profile from '@/models/Profile'
import Course from "@/models/Course";

export default class DuplicateLearner extends TransformModel {
  static entity = 'duplicate-learners'
  static fields() {
    return {
      id:this.uid(),
      id_number: this.attr(null),
      username: this.attr(null),
      duplicate_profiles: this.attr([])

    }
  }

  static fetchAll() {
    return this.api().get(`/duplicate-profiles`, {
      dataTransformer: (response) => {
        return response.data      },
    })
  }

}
