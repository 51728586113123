import {TransformModel} from '@/models/TransformModel'

export default class SchoolSubject extends TransformModel {
  static entity = 'school-subjects'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  static FetchAll(page = 1, limit = 999) {
    return this.api().get(`/school-subjects`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }


}
