<template>
  <section class="section">
    <b-tabs v-model="activeTab" :destroy-on-hide="true" class="block no-padding" expanded position="is-centered" size="is-large" type="is-toggle">
      <b-tab-item label="Details">
        <card-component
          :header-icon="user_details_lock_icon"
          icon="ballot"
          title="User Form"
          v-on:header-icon-click="toggleUserDetailsLock()"
        >
          <form @submit.prevent="submit">
            <b-field horizontal label="First Name">
              <b-field>
                <b-input
                  v-model="user.first_name"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  icon="account"
                  name="first_name"
                  placeholder="First Name"
                  required
                />
              </b-field>
            </b-field>
            <b-field label="Middle Name(s)" horizontal>
              <b-field>
                <b-input
                  :disabled="user_details_lock"
                  autocomplete="off"
                  icon="account"
                  v-model="user.middle_name"
                  placeholder="Middle Name(s)"
                  name="middle_name"
                />
              </b-field>
            </b-field> <b-field label="Last Name" horizontal>
              <b-field>
                <b-input
                  v-model="user.last_name"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  icon="account"
                  name="last_name"
                  placeholder="Last Name"
                  required
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Email Address">
              <b-field>
                <b-input
                  v-model="user.email"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  icon="email"
                  name="email"
                  placeholder="E-mail"
                  required
                  type="email"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Send email from address">
              <b-field>
                <b-input
                  v-model="user.username"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  icon="email"
                  name="send_address"
                  placeholder="E-mail"
                  required
                  type="email"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Primary Contact Number">
              <b-field>
                <b-input
                  v-model="user.primary_contact_number"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  expanded
                  icon="phone"
                  name="phone"
                  placeholder="072 555 5555"
                  type="tel"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Secondary Contact Number">
              <b-field>
                <b-input
                  v-model="user.secondary_contact_number"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  expanded
                  icon="phone"
                  name="phone"
                  placeholder="072 555 5555"
                  type="tel"
                />
              </b-field>
            </b-field>
            <b-field :label="$tc('Cellphone',1)+' Number'" horizontal>
              <b-field>
                <b-input
                  v-model="user.cellphone_number"
                  :disabled="user_details_lock"
                  autocomplete="off"
                  expanded
                  icon="phone"
                  name="phone"
                  placeholder="072 555 5555"
                  type="tel"
                />
              </b-field>
            </b-field>
            <b-field horizontal label="Personal title">
              <b-select v-model="user.title" :disabled="user_details_lock" placeholder="Select a title">
                <option value="Mr">Mr</option>
                <option value="Mrs">Mrs</option>
                <option value="Ms">Ms</option>
                <option value="Dr">Dr</option>
              </b-select>
            </b-field>
            <b-field horizontal label="Roles">
              <b-dropdown v-model="userRoles" aria-role="list" multiple position="is-top-right" scrollable>
                <button
                  slot="trigger"
                  :disabled="user_details_lock"
                  class="button is-primary"
                  type="button"
                >
                  <span v-if="userRoles.length<1">Select Roles</span>
                  <div v-for="(userRole,index) in userRoles" :key="'selected' +index">
                    <p>
                      <span>{{ userRole }}</span>
                      <span v-if="index<userRoles.length-1">,&nbsp;</span>
                    </p>
                  </div>
                  <b-icon icon="menu-down"></b-icon>
                </button>
                <b-dropdown-item :value="null" @click="userRoles=[null]">
                  <span>none</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-for="(role,index) in roles"
                  v-show="hasPermissionToAssign(role.name)"
                  :key="index"
                  :value="role.name"
                  aria-role="listitem"
                >
                  <span>{{ role.name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-field>

            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button :disabled="user_details_lock" native-type="submit" type="is-primary">Submit</b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingUser" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>
      </b-tab-item>
      <b-tab-item label="Additional Information">
        <card-component
          icon="ballot"
          title="Additional Information"
        >
          <form @submit.prevent="submitAdditional">
            <b-field horizontal label="Identity type">
              <b-field>
                <b-select
                  v-model="profile.identification_type_id"
                  icon="id-card"
                  placeholder="Identity type"

                >
                  <option
                    v-for="identification_type in identification_types"
                    :key="identification_type.id"
                    :value="identification_type.id"
                  >{{ identification_type.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field horizontal label="Identification number">
              <b-field>
                <ValidationObserver ref="observer">
                  <ValidationProvider
                    v-slot="{ errors }"
                    :rules="`${profile.identification_type_id === 9 ? 'id' : ''}`"
                  >
                    <b-input
                      required
                      v-model="profile.id_number"
                      autocomplete="off"
                      icon="id-card"
                      placeholder="ID number"
                    />
                    <span class="has-text-danger">{{ errors[0] }}</span>
                  </ValidationProvider>
                </ValidationObserver>
              </b-field>
            </b-field>
            <b-field horizontal label="Staff Category">
              <b-field>
                <b-select
                  v-model="profile.staff_information.staff_category_id"
                  icon="id-card"
                  placeholder="Category"

                >
                  <option
                    v-for="category in staff_categories"
                    :key="category.id"
                    :value="category.id"
                  >{{ category.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Appointment Date">
              <b-field>
                <b-datepicker
                  :value="new Date(profile.staff_information.appointment_date)"
                  :years-range="[-100,100]"
                  icon="calendar-today"
                  placeholder="Click to select..."
                  @input="payload => profile.staff_information.appointment_date = payload"
                ></b-datepicker>
              </b-field>
            </b-field>
            <b-field label="Currently Employed" horizontal>
              <b-checkbox
                :true-value="true"
                :false-value="false"
                type="is-primary"
                v-model="currently_employed"
              >{{currently_employed?'Yes':'No'}}</b-checkbox>
            </b-field>
            <b-field v-if="!currently_employed" horizontal label="Termination Date">
              <b-field>
                <b-datepicker
                  :value="new Date(profile.staff_information.termination_date)"
                  :years-range="[-100,100]"
                  icon="calendar-today"
                  placeholder="Click to select..."
                  @input="payload => profile.staff_information.termination_date = payload"
                ></b-datepicker>
              </b-field>
            </b-field>
            <b-field horizontal label="Employment Status">
              <b-field>
                <b-select
                  v-model="profile.staff_information.employment_status_id"
                  icon="id-card"
                  placeholder="Status"

                >
                  <option
                    v-for="status in employment_statuses"
                    :key="status.id"
                    :value="status.id"
                  >{{ status.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Highest Qualification">
              <b-field>
                <b-select
                  v-model="profile.staff_information.highest_qualification_id"
                  icon="id-card"
                  placeholder="Qualification"

                >
                  <option
                    v-for="qualification in qualifications"
                    :key="qualification.id"
                    :value="qualification.id"
                  >{{ qualification.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Appointment Type">
              <b-field>
                <b-select
                  v-model="profile.staff_information.appointment_type_id"
                  icon="id-card"
                  placeholder="Appointment Type"

                >
                  <option
                    v-for="type in appointment_types"
                    :key="type.id"
                    :value="type.id"
                  >{{ type.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>
            <b-field horizontal label="Working Hours Per Month">
              <b-field>
                <b-input
                  v-model="profile.staff_information.working_hours_per_month"
                  autocomplete="off"
                  placeholder="Hours"
                  type="number"
                />

              </b-field>
            </b-field>
            <hr/>
            <b-field horizontal>
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit" type="is-primary">Submit</b-button>
                </div>
              </b-field>
            </b-field>
            <b-loading :active.sync="updatingProfile" :can-cancel="false" :is-full-page="false"></b-loading>
          </form>
        </card-component>

      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
import CardComponent from "@/components/CardComponent";
import Role from "@/models/Role";
import Profile from "@/models/Profile"
import User from "@/models/User";
import Lookup from "@/models/Lookup";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";

extend("id", (idVal) => {
  var checkDigit = parseInt(idVal.charAt(idVal.length - 1));
  var odd = 0;
  var even = "";
  var evenResult = 0;
  var result;
  for (var c = 1; c <= idVal.length; c++) {
    if (c % 2 == 0) {
      even += idVal.charAt(c - 1);
    } else {
      if (c == idVal.length) {

      } else {
        odd = parseInt(odd) + parseInt(idVal.charAt(c - 1));
      }
    }
  }
  even = Number(even) * 2;
  even = even.toString();
  for (var r = 1; r <= even.length; r++) {
    evenResult = parseInt(evenResult) + parseInt(even.charAt(r - 1));
  }
  result = parseInt(odd) + parseInt(evenResult);
  result = result.toString();
  result = 10 - parseInt(result.charAt(result.length - 1));
  result = result.toString();
  if (result.length > 1) {
    result = result.charAt(result.length - 1);
  }
  if (parseInt(result) != checkDigit) {
    return "Please enter a valid SA ID number";
  } else {
    return true;
  }
});
export default {
  props: ["id"],
  components: {
    CardComponent, ValidationObserver, ValidationProvider
  },
  data() {
    return {
      newUserId: null,
      activeTab: 0,
      userRoles: [],
      updatingUser: false,
      updatingProfile: false,
      user_details_lock: true,
      display_termination_override:false,
      newProfile: false,
      user: {
        title: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        email: null,
        primary_contact_number: "",
        secondary_contact_number: "",
        cellphone_number: "",
        roles: [],
      },
      profile: {
        join_by_id_number: false,
        identification_type_id: null,
        id_number: null,
        staff_information: {
          staff_category_id: null,
          appointment_date: new Date(),
          termination_date: null,
          employment_status_id: null,
          highest_qualification_id: null,
          working_hours_per_month: null,
          appointment_type_id: null
        }
      },
    };
  },
  computed: {
    currently_employed:{
      set(newVal){
        if (newVal===true) {
          this.profile.staff_information.termination_date=null
          this.display_termination_override = false
        } else {
          this.display_termination_override = true
          this.profile.staff_information.termination_date = new Date()
        }
      },
      get(){
        return (this.profile.staff_information.termination_date===null)&& !this.display_termination_override
      }
    },
    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    }, staff_categories() {
      return Lookup.query().where("lookup_type_id", 20).get();
    },qualifications() {
      return Lookup.query().where("lookup_type_id", 19).orderBy('name').get();    },
    employment_statuses() {
      return Lookup.query().where("lookup_type_id", 21).get();
    }, appointment_types() {
      return Lookup.query().where("lookup_type_id", 22).get();
    },
    roles() {
      return Role.all();
    },
    can_assign_admissions() {
      return this.$store.getters["entities/permissions/find"](
        "assign admissions role"
      );
    },
    can_assign_admissions_administrator() {
      return this.$store.getters["entities/permissions/find"](
        "assign admissions administrator role"
      );
    },
    can_assign_super_admin() {
      return this.$store.getters["entities/permissions/find"](
        "assign super admin role"
      );
    },
    can_edit_user() {
      return this.$store.getters["entities/permissions/find"]("edit users");
    },
    user_details_lock_icon() {
      if (this.user_details_lock) {
        return "lock";
      } else {
        return "lock-open";
      }
    },
  },
  methods: {
    hasPermissionToAssign(role) {
      if (this.$store.getters["entities/permissions/find"]("assign any role")) {
        return true;
      }
      return this.$store.getters["entities/permissions/find"](
        "assign " + role + " role"
      );
    },
    toggleUserDetailsLock() {
      if (this.can_edit_user) {
        this.user_details_lock = !this.user_details_lock;
      } else {
        this.$store.dispatch("toast/createToast");
      }
    },
    submitAdditional() {
      this.updatingProfile = true
      if (this.newProfile) {
        Profile.newProfile(this.profile, this.id).then(() => {
          this.updatingProfile = false
          this.$buefy.snackbar.open({
            message: this.profile.join_by_id_number ? "Profiles Merged" : "Additional Information Updated!",
            type: "is-link",
            queue: false,
          });
          if (this.profile.join_by_id_number) {
            setTimeout(() => {
              this.$router.push(`/users/edit/${this.newUserId}`);
            }, 1000);
          }
        }).catch((err) => {
          this.updatingProfile = false
          if (err.response.data.hasOwnProperty('errors')) {
            if (err.response.data.errors.hasOwnProperty('id_number')) {

              this.$buefy.dialog.confirm({
                title: "Merge User",
                message:
                  `<p>${err.response.data.errors.id_number[0]}. Would you like to merge their existing information?</p>
                    <p>This will delete the current profile and convert the existing student profile to a staff member</p>
                  `,
                confirmText: "Merge",
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => {
                  this.newUserId = parseInt(err.response.data.errors.existing_user_id[0])
                  this.profile.join_by_id_number = true
                  this.submitAdditional()
                },
              });
            }
          } else {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          }
        });
      } else {
        Profile.updateRemote(this.profile, this.id).then(() => {
          this.updatingProfile = false
          this.$buefy.snackbar.open({
            message: this.profile.join_by_id_number ? "Profiles Merged" : "Additional Information Updated!",
            type: "is-link",
            queue: false,
          });
          if (this.profile.join_by_id_number) {
            setTimeout(() => {
              this.$router.push(`/users/edit/${this.newUserId}`);
            }, 1000);
          }
        }).catch((err) => {
          this.updatingProfile = false
          if (err.response.data.hasOwnProperty('errors')) {
            if (err.response.data.errors.hasOwnProperty('id_number')) {

              this.$buefy.dialog.confirm({
                title: "Merge User",
                message:
                  `<p>${err.response.data.errors.id_number[0]}. Would you like to merge their existing information?</p>
                    <p>This will delete the current profile and convert the existing student profile to a staff member</p>
                  `,
                confirmText: "Merge",
                type: "is-primary",
                hasIcon: true,
                onConfirm: () => {
                  this.newUserId = parseInt(err.response.data.errors.existing_user_id[0])
                  this.profile.join_by_id_number = true
                  this.submitAdditional()
                },
              });
            }
          } else {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          }
        });
      }

    },
    submit() {
      this.updatingUser = true;
      this.user.roles = this.userRoles;
      User.updateRemote(this.user)
        .then(() => {
          this.updatingUser = false;
          this.$buefy.snackbar.open({
            message: "User Updated!",
            type: "is-link",
            queue: false,
          });
        })
        .catch((err) => {
          this.updatingUser = false;
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    Lookup.fetchAll()
    Role.fetchAll().then(() => {
      User.fetchById(this.id, ['roles'])
        .then(() => {
          this.user = User.query().whereId(this.id).with("roles").get()[0];
          let roleNames = [];
          this.user.roles.forEach((element) => {
            roleNames.push(element.name);
          });
          Profile.fetchById(this.id).then(({entities: {profiles}}) => {
            this.profile = profiles[0]
          }).catch(() => {
            this.newProfile = true
          })
          this.userRoles = roleNames;
          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    });
  },
};
</script>
