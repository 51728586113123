<template>
  <Lookup :lookup_type_id="1" :title="'Gender'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "Gender",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
