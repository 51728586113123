<template>
  <Lookup :lookup_type_id="7" :title="'Marketing Source'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "MarketingSources",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
