export const ChartModule = {
  namespaced: true,

  state: {
    default_colors: ["#FC9F5B", "#FBD1A2", "#ECE4B7", '#7DCFB6', "#33CA7F", '#7bdff2ff', '#048a81ff', '#395e66ff',
      '#ffc09fff', '#ffee93ff', '#7B4B94', '#7D82B8', '#B7E3CC', '#C4FFB2', '#D6F7A3', '#03045E', '#0077B6', '#00B4D8', '#90E0EF', '#CAF0F8', '#292F36', '#4ECDC4', '#F7FFF7', '#FF6B6B', '#FFE66D'
    ],
  }



};
