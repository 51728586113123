<template>
  <Lookup :lookup_type_id="16" :title="'Examination Authority'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "ExaminationAuthority",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
