/* Styles */
import '@/scss/main.scss'
import '@mdi/font/css/materialdesignicons.css'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'
import VueCookie from 'vue-cookie';
import vueDebounce from 'vue-debounce'
import CKEditor from '@ckeditor/ckeditor5-vue';
import moment from 'moment'


/* Router & Store */
import router from './router'
import AuthGuard from '@/guards/Auth';
import PermissionsGuard from '@/guards/Permissions'
import store from './store'
import handleError from "@/mixins/ResponseErrors";
Vue.mixin(handleError)

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'
import i18n from './i18n'

/* Vue. Component in recursion */
import AsideMenuList from '@/components/AsideMenuList'


/* Collapse mobile aside menu on route change */
router.afterEach(() => {
  store.commit('asideMobileStateToggle', false)
})

Vue.config.productionTip = false
Vue.prototype.$moment = moment;
Vue.prototype.$cookie = VueCookie;
import makeModalLarge from "@/mixins/LargeModal";

Vue.mixin(makeModalLarge)

/* These components are used in recursion algorithm */
Vue.component('AsideMenuList', AsideMenuList)
Vue.use(VueCookie);
Vue.use(vueDebounce)
Vue.use(Buefy)
Vue.use(CKEditor);

export default new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
