import {
  TransformModel
} from '@/models/TransformModel'
import User from '@/models/User'
import Module from '@/models/Module'
import Lookup from '@/models/Lookup'
export default class Schedule extends TransformModel {
  static entity = 'schedules'

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      year: this.attr(null),
      term_id: this.attr(null),
      term: this.belongsTo(Lookup, 'term_id'),
      lecturer_id: this.attr(null),
      lecturer: this.belongsTo(User, 'lecturer_id'),
      module_id: this.attr(null),
      module: this.belongsTo(Module, 'module_id'),
      code: this.attr(null)
    }
  }

  static fetchAll(year, term_id, lecturer_id, module_id, page, limit, relationships = ['lecturer', 'module']) {
    return this.api().get(`/schedules`, {
      params: {

        ...(year ? {
          year: year
        } : {}),
        ...(term_id ? {
          term_id: term_id
        } : {}),
        ...(lecturer_id ? {
          lecturer_id: lecturer_id
        } : {}),
        ...(module_id ? {
          module_id: module_id
        } : {}),
        ...(page ? {
          page: page
        } : {}),
        ...(limit ? {
          limit: limit
        } : {}),
        ...(relationships ? {
          with: relationships
        } : {})

      },
      dataTransformer: (response) => {
        let storageArray = []
        response.data.data.forEach(element => {
          element.attributes.lecturer = {
            ...element.attributes.lecturer,
            ...element.attributes.lecturer.attributes
          }

          if (element.attributes.module) {
            element.attributes.module = {
              ...element.attributes.module,
              ...element.attributes.module.attributes
            }
          }
          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)
        })
        return storageArray
      }
    })
  }
  static create(schedule) {
    return this.api().post(`/schedules`, schedule, {

      save: false,
      saved: false
    })
  }
  static update(schedule) {
    return this.api().patch(`/schedules/${schedule.id}`, schedule, {

      save: false
    })
  }
  static remove(schedule_id) {
    return this.api().delete(`/schedules/${schedule_id}`)
  }

}
