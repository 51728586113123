import {
  TransformModel
} from '@/models/TransformModel'


export default class RegistrationMadeByCourse extends TransformModel {
  static entity = 'RegistrationsMadeByCourse'
  static primaryKey = 'user'

  static fields() {
    return {
      registrations_created: this.attr(null),
      course_name: this.attr(null)
    }
  }

  static fetchAll(start = null, end = null, owner_id = null, creator_id = null, status_id = null, stage_id = null, filter = true, year = null, semester = null) {
    return this.api().get(`/reports/registrations-by-course`, {
      params: {
        filter: filter,
        start: start,
        end: end,
        ...(status_id ? {
          status_id: status_id
        } : {}),
        ...(owner_id ? {
          owner_id: owner_id
        } : {}),
        ...(stage_id ? {
          stage_id: stage_id
        } : {}),
        ...(creator_id ? {
          creator_id: creator_id
        } : {}),
        ...(year ? {
          year: year
        } : {}),
        ...(semester ? {
          semester: semester
        } : {})
      },

      dataTransformer: (response) => {
        response.data.data.attributes.data.push({
          registrations_created: response.data.data.attributes.data.reduce(function (accumulator, currentValue) {
            return accumulator + currentValue.registrations_created
          }, 0),
          course_name: 'Total',
          $id: 'Total'
        })
        return response.data.data.attributes.data


      }
    })
  }




}
