<template>
  <form @submit.prevent="submit">
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title">Change {{ $tc('Learner', 1) }} Password</p>
      </header>
      <section class='modal-card-body'>
        <ValidationObserver ref="observer">
          <b-field label="Password">
            <ValidationProvider :rules="`required|min|${confirmPassword!==null?'password:@confirm':''}`" v-slot="{ errors }">
              <b-input type="password" v-model="password" name="password"/>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>

          <b-field label="Confirm password">
            <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
              <b-input :loading="isLoading" type="password" v-model="confirmPassword"/>
              <span class="has-text-danger">{{ errors[0] }}</span>
            </ValidationProvider>
          </b-field>
        </ValidationObserver>

      </section>
      <footer class="modal-card-foot">
        <b-field grouped>
          <b-field>
            <b-button @click.prevent="$emit('close')">
              Cancel
            </b-button>
          </b-field>
          <b-field>      <b-button
            :loading="isLoading"
            native-type="submit" type="is-primary">Change Password
          </b-button></b-field>
        </b-field>

      </footer>
    </div>
  </form>
</template>

<script>
import {ValidationProvider, ValidationObserver, extend} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Learner from "@/models/Learner";

extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, {target}) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});

export default {
  name: "LearnerPasswordForm",
  components: {ValidationProvider, ValidationObserver},
  data() {
    return {
      isLoading: false,
      password: '',
      confirmPassword: null,
    }
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        } else {
          this.isLoading = true;
            Learner.changePassword(this.learner_id,this.password,this.confirmPassword).then(()=>{
              this.isLoading = false
              this.$emit('close')
              this.$buefy.snackbar.open({
                message: "Password changed",
                queue: false,
                type: "is-link"
              });
            }).catch(err=>{
              this.isLoading = false;

              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            })
        }
      });
    },

  },
  props: {
    learner_id: {
      type: Number,
      required:true
    }
  }
}
</script>

