<template>
  <Lookup :lookup_type_id="26" :title="'Programme Achievement Type'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "ProgrammeAchievementType",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
