import {TransformModel} from '@/models/TransformModel'

export default class Document extends TransformModel {
  static entity = 'documents'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null)
    }
  }

  static FetchAll(page = 1, limit = 999) {
    return this.api().get(`/documents`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(document => {
          document.id = parseInt(document.id)
          return {...document, ...document.attributes}
        })
      },
    })
  }


}
