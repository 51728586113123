import {TransformModel} from '@/models/TransformModel'
import CourseCoreOption from "@/models/CourseCoreOption";

export default class UserCoreOption extends TransformModel {
  static entity = 'user-core-options'

  static fields() {
    return {
      id: this.attr(null),
      option_id: this.attr(null),
      option:this.belongsTo(CourseCoreOption,'option_id'),
      course_id: this.attr(null),
      previous_marks: this.attr(() => [])
    }
  }

  static FetchAll(page = 1, limit = 999, learner_id) {
    return this.api().get(`/learners/${learner_id}/core-options`, {
      params: {
        page: page,
        limit: limit
      },
      dataTransformer: ({data: {data}}) => {
        return data.map(option => {
          option.attributes.option_id = option.id

          return {...option, ...option.attributes}
        })
      },
    })
  }

  static Update(user_core_options, learner_id) {
    return this.api().patch(`/learners/${learner_id}/core-options`, user_core_options, {

      dataTransformer: (response) => {
        return response.data.data
      }
    })
  }


}
