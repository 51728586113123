import {
  TransformModel
} from '@/models/TransformModel'
// import Permission from '@/models/Permission'
// import PermissionRole from '@/models/PermissionRole'
export default class Role extends TransformModel {
  static entity = 'roles'

  static fields() {
    return {
      type: this.attr(null),
      id: this.attr(null),
      name: this.attr(''),
      // permissions: this.belongsToMany(Permission, PermissionRole, 'role_id', 'permission_id')
    }
  }
  static newRole(role) {
    return this.api().post(`/roles`, role, {
      save: false
    })
  }
  static updateRole(role) {
    return this.api().patch(`/roles/${role.id}`, role, {
      save: false
    })
  }
  static fetchAll() {
    return this.api().get(`/roles`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }
  static fetchAllWithPermissions() {
    return this.api().get(`/roles?with[]=permissions`, {
      dataTransformer: (response) => {
        let storageArray = []

        if (response.data.data.length > 1) {
          response.data.data.forEach(element => {
            let target = element
            element.attributes.permissions.forEach((permission, index) => {
              element.attributes.permissions[index].name = permission.attributes.name
            })
            Object.assign(target, element.attributes)
            storageArray.push(target)
          });
        } else if (response.data.data.length == 1) {
          let target = response.data.data[0]
          response.data.data[0].attributes.permissions.forEach((permission, index) => {
            response.data.data[0].attributes.permissions[index].name = permission.attributes.name
          })
          Object.assign(target, target.attributes)
          storageArray.push(target)

        } else {
          return
        }

        return storageArray
      }
    })
  }
  static fetchSingleWithPermissions(id) {
    return this.api().get(`/roles/${id}?with[]=permissions`, {
      dataTransformer: (response) => {
        let storageArray = []

        if (response.data.data.length > 1) {
          response.data.data.forEach(element => {
            let target = element
            element.attributes.permissions.forEach((permission, index) => {
              element.attributes.permissions[index].name = permission.attributes.name
            })
            Object.assign(target, element.attributes)
            storageArray.push(target)
          });
        } else if (response.data.data.length == 1) {
          let target = response.data.data[0]
          response.data.data[0].attributes.permissions.forEach((permission, index) => {
            response.data.data[0].attributes.permissions[index].name = permission.attributes.name
          })
          Object.assign(target, target.attributes)
          storageArray.push(target)

        } else {
          return
        }

        return storageArray
      }
    })
  }


}
