<template>
  <section class="section">
    <h1 class="title is-size-3">Application Support Documents</h1>
    <p
      class="mt-4"
    >If you are unable to complete your profile information online please do the following:</p>
    <ol class="padding-left mb-4">
      <li>Download the relevant application form(s).</li>
      <li>Fill in the necessary information.</li>
      <li>
        email it back to
        <a href="mailto:info@cornerstone.ac.za">info@cornerstone.ac.za</a>, where one of our onboarding team members will upload it for you.
      </li>
    </ol>
    <b-field group-multiline grouped>
      <b-field>
        <b-button
          icon-left="download"
          target="_blank"
          type="is-link"
          href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/2021+Application+Form+Undergraduate+Studies.pdf"
          tag="a"
        >Download undergraduate form</b-button>
      </b-field>
      <b-field>
        <b-button
          icon-left="download"
          target="_blank"
          type="is-link"
          href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/2021+Application+Form+Postgraduate+Studies.pdf"
          tag="a"
        >Download postgraduate form</b-button>
      </b-field>
      <b-field>
        <b-button
          icon-left="download"
          target="_blank"
          type="is-link"
          href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/BPsych_Equiv_Department_Application_form_2021.pdf"
          tag="a"
        >Download psychology equivalent form</b-button>
      </b-field>
      <b-field>
        <b-button
          icon-left="download"
          target="_blank"
          type="is-link"
          href="https://cornerstone-aas-assets.s3-eu-west-1.amazonaws.com/downloads/BPsych_Equiv_Referee_form_2021.pdf"
          tag="a"
        >Download psychology equivalent referee form</b-button>
      </b-field>
    </b-field>
  </section>
</template>

<script>
// @ is an alias to /src

export default {
  name: "home",
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {
    // this.$buefy.snackbar.open({
    //   message: "Welcome back",
    //   queue: false,
    //type: "is-link";
    // });
  },
};
</script>
