<template>
  <section class="section">
    <title-bar>Schedule</title-bar>
    <card-component>
      <b-field grouped>
        <b-field label="Year">
          <b-select v-model="year">
            <option v-for="year in years" :key="year" :value="year">{{year}}</option>
          </b-select>
        </b-field>
        <b-field label="Term">
          <b-select v-model="term_id">
            <option :value="null">None</option>
            <option v-for="term in terms" :key="term.id" :value="term.id">{{term.name}}</option>
          </b-select>
        </b-field>
        <b-field expanded label="Lecturer">
          <b-autocomplete
            @typing="getFilteredLecturer"
            :data="lecturerSearchResponse"
            autocomplete="off"
            v-model="lecturerSearchTerm"
            placeholder="Lecturer"
            @select="option => selectLecturer(option)"
            :clear-on-select="false"
            :custom-formatter="thing=> lecturerName(thing)"
          >
            <template slot-scope="props">
              <p>{{props.option.first_name}} {{props.option.last_name}} | {{props.option.email}}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field expanded label="Module">
          <b-autocomplete
            @typing="getFilteredModule"
            :data="moduleSearchResponse"
            autocomplete="off"
            v-model="moduleSearchTerm"
            placeholder="Module"
            @select="option => selectModule(option)"
            :clear-on-select="false"
            :custom-formatter="thing=> moduleName(thing)"
          >
            <template slot-scope="props">
              <p>{{props.option.code}} | {{props.option.name}}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </b-field>
      <b-field>
        <b-button
          icon-left="plus-box"
          type="is-success"
          @click.prevent="add_schedule"
        >Add schedule item</b-button>
      </b-field>
      <b-field v-if="newSchedule.length>0" label="New Items">
        <form @submit.prevent="submitNewSchedules">
          <b-field>
            <b-field>
              <table class="table is-bordered is-striped is-fullwidth">
                <thead>
                  <tr>
                    <th>Year</th>
                    <th>Term</th>
                    <th>Code</th>
                    <th>Lecturer</th>
                    <th>Module</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(schedule,index) in newSchedule" :key="index">
                    <td>
                      <b-field>
                        <b-select required v-model="newSchedule[index].year">
                          <option v-for="year in years" :key="year" :value="year">{{year}}</option>
                        </b-select>
                      </b-field>
                    </td>
                    <td>
                      <b-field>
                        <b-select required v-model="newSchedule[index].term_id">
                          <option
                            v-for="term in terms"
                            :key="term.id"
                            :value="term.id"
                          >{{term.name}}</option>
                        </b-select>
                      </b-field>
                    </td>
                    <td>
                      <b-field>
                        <b-input placeholder="Code" v-model="newSchedule[index].code"></b-input>
                      </b-field>
                    </td>
                    <td style="min-width:350px">
                      <b-field expanded>
                        <b-autocomplete
                          @typing="getFilteredLecturer"
                          :data="lecturerSearchResponse"
                          autocomplete="off"
                          required
                          v-model="newSchedule[index].lecturerSearchTerm"
                          placeholder="Lecturer"
                          @select="option => selectLecturer(option, index)"
                          :clear-on-select="false"
                          :custom-formatter="thing=> lecturerName(thing)"
                        >
                          <template slot-scope="props">
                            <p>{{props.option.first_name}} {{props.option.last_name}} | {{props.option.email}}</p>
                          </template>
                        </b-autocomplete>
                      </b-field>
                    </td>
                    <td style="min-width:350px">
                      <b-field expanded>
                        <b-autocomplete
                          @typing="getFilteredModule"
                          :data="moduleSearchResponse"
                          autocomplete="off"
                          required
                          v-model="newSchedule[index].moduleSearchTerm"
                          placeholder="Module"
                          @select="option => selectModule(option, index)"
                          :clear-on-select="false"
                          :custom-formatter="thing=> moduleName(thing)"
                        >
                          <template slot-scope="props">
                            <p>{{props.option.code}} | {{props.option.name}}</p>
                          </template>
                        </b-autocomplete>
                      </b-field>
                    </td>

                    <td>
                      <b-button
                        type="is-danger"
                        @click.prevent="remove_schedule(index)"
                        icon-right="delete"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-field>
          </b-field>

          <hr />
          <b-field>
            <b-field>
              <div class="control">
                <b-button native-type="submit" type="is-primary">Submit</b-button>
              </div>
            </b-field>
          </b-field>
        </form>
      </b-field>
      <b-table
        class="margin-top"
        :data="schedules"
        :striped="true"
        :hoverable="true"
        :bordered="true"
      >
        <b-table-column v-slot="props" label="Code" field="code" sortable width="40" numeric>{{ props.row.code }}</b-table-column>
        <b-table-column v-slot="props" sortable field="term_id" label="Term">{{ props.row.term.name }}</b-table-column>
        <b-table-column v-slot="props"
          label="Lecturer"
        >{{ props.row.lecturer.first_name + ' ' +props.row.lecturer.last_name }}</b-table-column>
        <b-table-column v-slot="props" label="Module">{{ props.row.module.name }}</b-table-column>
        <b-table-column v-slot="props" centered custom-key="actions">

          <b-field>
<span class="control">
   <b-button
     @click="startEditSchedule(props.row)"
     type="is-info"
   >Edit</b-button>
</span>
            <span v-if="can_delete_schedule"
                  class="control">
              <b-button
                @click="start_delete_schedule(props.row)"
                type="is-danger"
                icon-right="delete"
              ></b-button>
</span>
          </b-field>
        </b-table-column>

      </b-table>
      <b-pagination
        class="margin-top"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
      ></b-pagination>
    </card-component>
    <b-modal :active.sync="isEditModalActive">
      <form class="box" style="overflow:hidden" @submit.prevent="submitEditSchedule">
        <b-field grouped multiline>
          <b-field>
            <b-field label="Year">
              <b-select required v-model="editSchedule.year">
                <option v-for="year in years" :key="year" :value="year">{{year}}</option>
              </b-select>
            </b-field>

            <b-field label="Term">
              <b-select required v-model="editSchedule.term_id">
                <option v-for="term in terms" :key="term.id" :value="term.id">{{term.name}}</option>
              </b-select>
            </b-field>
            <b-field style="max-width:100px" label="Code">
              <b-input placeholder="Code" v-model="editSchedule.code"></b-input>
            </b-field>
          </b-field>

          <b-field label="Lecturer" expanded>
            <b-autocomplete
              @typing="getFilteredLecturer"
              :data="lecturerSearchResponse"
              autocomplete="off"
              required
              v-model="editLecturerSearchTerm"
              placeholder="Lecturer"
              @select="option => selectEditLecturer(option)"
              :clear-on-select="false"
              :custom-formatter="thing=> lecturerName(thing)"
            >
              <template slot-scope="props">
                <p>{{props.option.first_name}} {{props.option.last_name}} | {{props.option.email}}</p>
              </template>
            </b-autocomplete>
          </b-field>

          <b-field label="Module" expanded>
            <b-autocomplete
              @typing="getFilteredModule"
              :data="moduleSearchResponse"
              autocomplete="off"
              required
              v-model="editModuleSearchTerm"
              placeholder="Module"
              @select="option => selectEditModule(option)"
              :clear-on-select="false"
              :custom-formatter="thing=> moduleName(thing)"
            >
              <template slot-scope="props">
                <p>{{props.option.code}} | {{props.option.name}}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>

        <hr />
        <b-field>
          <b-field>
            <div class="control">
              <b-button native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </b-modal>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Schedule from "@/models/Schedule";
import User from "@/models/User";
import Module from "@/models/Module";
import Lookup from "@/models/Lookup";
export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      year: this.$moment().year(),
      term_id: null,
      lecturer_id: null,
      module_id: null,
      lecturerSearchResponse: [],
      lecturerSearchTerm: "",
      lecturer: {},
      moduleSearchResponse: [],
      moduleSearchTerm: "",
      module: {},
      newSchedule: [],
      page: 1,
      editSchedule: {},
      isEditModalActive: false,
      editModuleSearchTerm: "",
      editLecturerSearchTerm: "",
      limit: 50,
      meta: {},
    };
  },
  computed: {
    schedules() {
      return Schedule.query()
        .with("module")
        .with("lecturer")
        .with("term")
        .get();
    },
    filters() {
      return {
        ...(this.year
          ? {
              year: this.year,
            }
          : {}),
        ...(this.term_id
          ? {
              term_id: this.term_id,
            }
          : {}),
        ...(this.lecturer_id
          ? {
              lecturer_id: this.lecturer_id,
            }
          : {}),
        ...(this.module_id
          ? {
              module_id: this.module_id,
            }
          : {}),
      };
    },
    years() {
      let years = [];
      for (let i = -5; i < 3; i++) {
        years.push(this.$moment().year() + i);
      }
      return years;
    },

    terms() {
      return Lookup.query().where("lookup_type_id", 18).get();
    },
    can_delete_schedule() {
      return this.$store.getters["entities/permissions/find"](
        "delete schedule"
      );
    },
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  methods: {
    submitEditSchedule() {
      this.$store.dispatch("loader/show");
      this.editSchedule.year = this.editSchedule.year.toString();
      this.editSchedule.term_id = this.editSchedule.term_id.toString();
      Schedule.update(this.editSchedule)
        .then(() => {
          this.isEditModalActive = false;
          this.setPage(this.page);
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    startEditSchedule(schedule) {
      this.editSchedule = schedule;
      this.editModuleSearchTerm = schedule.module.name;
      this.editLecturerSearchTerm =
        schedule.lecturer.first_name + " " + schedule.lecturer.last_name;
      this.isEditModalActive = true;
    },
    deleteSchedule(item) {
      this.$store.dispatch("loader/show");
      Schedule.remove(item.id)
        .then(() => {
          this.setPage(this.page);
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    start_delete_schedule(item) {
      this.$buefy.dialog.confirm({
        title: "Deleting Schedule Item",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete Schedule Item",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSchedule(item),
      });
    },
    setPage(page) {
      this.page = page;
      this.$store.dispatch("loader/show");
      Schedule.deleteAll();
      this.getSchedule(this.filters, page, this.limit);
    },
    add_schedule() {
      this.newSchedule.push({
        term_id: null,
        lecturer_id: null,
        module_id: null,
        lecturerSearchTerm: null,
        moduleSearchTerm: null,
        code: null,
        year: this.year,
      });
    },
    remove_schedule(index) {
      this.newSchedule = this.newSchedule.filter(
        (item, place) => place != index
      );
    },
    lecturerName(lecturer) {
      return `${lecturer.first_name} ${lecturer.last_name} | ${lecturer.email}`;
    },
    selectLecturer(option, index = null) {
      if (option) {
        if (index != null) {
          this.newSchedule[index].lecturer_id = option.id;
          return;
        }
        this.lecturer_id = option.id;
      }
    },
    selectEditLecturer(option) {
      if (option) {
        this.editSchedule.lecturer_id = option.id;
      }
    },
    getFilteredLecturer(text) {
      if (text.length == 0) {
        this.lecturerSearchResponse = [];
        this.lecturer_id = null;
      }
      this.loadingLecturers = true;

      User.searchWithRole(text, true, 1, 15, "lecturer").then((result) => {
        this.loadingLecturers = false;

        if (result.entities) {
          this.lecturerSearchResponse = result.entities.users;
        } else {
          this.lecturerSearchResponse = [];
        }
      });
    },
    moduleName(module) {
      return `${module.code} | ${module.name}`;
    },
    selectModule(option, index = null) {
      if (option) {
        if (index != null) {
          this.newSchedule[index].module_id = option.id;
          return;
        }
        this.module_id = option.id;
      }
    },
    selectEditModule(option) {
      if (option) {
        this.editSchedule.module_id = option.id;
      }
    },
    getFilteredModule(text, is_add = false) {
      if (text.length == 0) {
        this.moduleSearchResponse = [];
        if (!is_add) {
          this.module_id = null;
        }
      }
      this.loadingModules = true;

      Module.search(text).then((result) => {
        this.loadingModules = false;

        if (result.entities) {
          this.moduleSearchResponse = result.entities.modules;
        } else {
          this.moduleSearchResponse = [];
        }
      });
    },
    getSchedule(filters, page, limit) {
      Schedule.fetchAll(
        filters.year,
        filters.term_id,
        filters.lecturer_id,
        filters.module_id,
        page,
        limit
      ).then((result) => {
        this.meta = result.response.data.meta;
        this.$store.dispatch("loader/hide");
      });
    },

    submitNewSchedules() {
      this.$store.dispatch("loader/show");
      let promises = 0;
      this.newSchedule.forEach((schedule) => {
        schedule.year = schedule.year.toString();
        schedule.term_id = schedule.term_id.toString();
        Schedule.create(schedule)
          .then(() => {
            promises++;
            if (promises == this.newSchedule.length) {
              this.$store.dispatch("loader/hide");
              this.newSchedule = [];
              this.$buefy.snackbar.open({
                message: "New Schedule items created!",
                queue: false,
                type: "is-link",
              });
              this.setPage(this.page);
            }
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
            this.$store.dispatch("loader/hide");
          });
      });
    },
  },
  mounted() {
    Lookup.fetchAll();
    Schedule.deleteAll();
    this.$store.dispatch("loader/show");
    this.getSchedule(this.filters, this.page, this.limit);
  },
};
</script>
