import {TransformModel} from '@/models/TransformModel'
import Module from "@/models/Module";

export default class CourseCoreOption extends TransformModel {
  static entity = 'course-cores'

  static fields() {
    return {
      id: this.attr(null),
      core_option: this.attr(null),
      requires_credits: this.attr(0),
      course_id:this.attr(null),
      is_required: this.attr(0),
      requires_nqf_level: this.attr(0),
      provide_supplementary_modules: this.attr(0),
      allow_non_listed_prior_modules: this.attr(0),
      credit: this.attr(null),
      minimum_nqf_level: this.attr(null),
      whitelisted_prior_modules: this.attr(() => {
        return []
      }), blacklisted_prior_modules: this.attr(() => {
        return []
      }),
      supplement_module_ids:this.attr(()=>[]),
      supplements:this.hasManyBy(Module,'supplement_module_ids')

    }
  }

  static FetchAll(page = 1, limit = 999, course_id) {
    return this.api().get(`/courses/${course_id}/core-options?with[]=supplements`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(core => {
          core.id = parseInt(core.id)
          if (Object.prototype.hasOwnProperty.call(core.attributes, 'supplements')){
            core.attributes.supplements.map(module=>{
              Object.assign(module,module.attributes)
            })
          }
          return {...core, ...core.attributes}
        })
      },
    })
  }

  static Update(core_options) {
    return this.api().patch(`/courses/${core_options.course_id}/core-options`, core_options, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }


}
