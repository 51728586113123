<template>
  <section class="section">
    <title-bar>Duplicate {{ $tc('Learner', 2) }}</title-bar>

    <div v-for="(learner,index) in learners" :key="index">
      <p class="has-text-weight-bold is-size-4" v-if="learner.id_number!==null">ID Number: {{learner.id_number}} </p>
      <p class="has-text-weight-bold is-size-4" v-if="learner.username!==null">Cornerstone Email: {{learner.username}}</p>
      <b-table scrollable  class="margin-top" :data="learner.duplicate_profiles"
               :striped="true" :hoverable="true" :bordered="true">
        <b-table-column v-slot="props" label="ID" field="user.id" sortable width="40" numeric>{{ props.row.user.id }}</b-table-column>
        <b-table-column v-slot="props" label="Student Number" field="user.student_number" sortable>{{
            props.row.user.student_number
          }}
        </b-table-column>

        <b-table-column v-slot="props" sortable field="user.first_name" label="First Name">{{ props.row.user.first_name }}</b-table-column>

        <b-table-column v-slot="props" sortable field="user.last_name" label="Last Name">{{ props.row.user.last_name }}</b-table-column>
        <b-table-column v-slot="props" sortable field="user.email" label="Email">{{ props.row.user.email }}</b-table-column>
        <b-table-column v-slot="props" sortable field="user.username" label="Username">{{ props.row.user.username }}</b-table-column>
        <b-table-column v-slot="props" sortable field="registrations" label="Registrations">{{
            props.row.registrations
          }}
        </b-table-column>
        <b-table-column v-slot="props" sortable field="grades" label="Grades">{{
            props.row.grades
          }}
        </b-table-column>
        <b-table-column v-slot="props" sortable field="medias" label="Media">{{
            props.row.medias
          }}
        </b-table-column>
        <b-table-column v-slot="props" sortable field="user.import_id" label="Imported From Access">{{
            props.row.user.import_id?'Yes':'No'
          }}
        </b-table-column>
        <b-table-column v-slot="props" sortable field="user.lms_username" label="LMS Username">
          <span v-if="props.row.user.lms_username">
{{props.row.user.lms_username}}          </span>
        </b-table-column>        <b-table-column v-slot="props" sortable field="user.lms_id" label="LMS ID">
          <span v-if="props.row.user.lms_id">
{{props.row.user.lms_id}}          </span>
      </b-table-column>        <b-table-column v-slot="props" sortable field="user.created_at" label="Created At">{{
          props.row.user.created_at
        }}
      </b-table-column>
        <b-table-column v-slot="props" width="80" custom-key="actions">
          <b-field grouped>
            <b-field>
              <b-button tag="router-link" :to="`/learners/edit/${props.row.user.id}`" type="is-info">View
              </b-button>
            </b-field>
            <b-field v-if="!!$store.getters[`entities/permissions/find`]('delete learners')">
              <b-button @click="start_delete(props.row.user.id, learner)" :disabled="!$store.getters[`entities/permissions/find`]('delete learners')" type="is-danger">Delete
              </b-button>
            </b-field>
          </b-field>
        </b-table-column>
      </b-table>
    </div>
    <b-pagination
      class="margin-top"
      :total="total"
      :current="page"
      :range-before="2"
      :range-after="2"
      :per-page="per_page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import DuplicateLearner from "@/models/DuplicateLearner";
import Learner from "@/models/Learner";
import TitleBar from "@/components/TitleBar";

export default {
  name: "DuplicateLearners",
  components: {TitleBar},
  data() {
    return {
     loadingFilter: false,
      learnersDataHold: null,
      meta: Object,
      page: 1,
      per_page:50
    };
  },
  methods: {
    start_delete(user_id,learner) {
      this.$buefy.dialog.confirm({
        title: "Deleting Learner",
        message:
          "Make sure you have merged any data you don't want to lose.",
        confirmText: "Delete learner",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.confirm_delete(user_id,learner),
      });
    },
    confirm_delete(user_id,learner) {
      this.$store.dispatch("loader/show");
      Learner.deleteRemote(user_id)
        .then(() => {
          this.$store.dispatch("loader/hide");
          let newLearner = JSON.parse(JSON.stringify(learner))
          newLearner.duplicate_profiles = newLearner.duplicate_profiles.filter(profile=>{
            return profile.user.id!==user_id
          })
          DuplicateLearner.update({where:learner.id_number,data:newLearner})
          this.$buefy.snackbar.open({
            message: "Learner deleted!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    viewLearner(id) {
      this.$router.push(`/learners/edit/${id}`);
    },

    setPage(pageNumber) {
      this.$store.dispatch("loader/show");

        this.getLearners().then(() => {
          this.$store.dispatch("loader/hide");
        });

    },
    getLearners(page, limit) {
      this.learnersDataHold = this.learners
      DuplicateLearner.deleteAll()
      return DuplicateLearner.fetchAll(page, limit).then(() => {
        this.learnersDataHold = null

      });
    }
  },

  computed: {
    total(){
      return DuplicateLearner.query().count()
    },
    learners() {
        return DuplicateLearner.query().offset(this.per_page*(this.page-1)).limit(this.per_page).get()

    },
    edit_learners() {
      return this.$store.getters["entities/permissions/find"]("edit learners");
    }
  },
  mounted() {
    this.$store.dispatch("loader/show");

    this.getLearners()
      .then(() => {
        this.$store.dispatch("loader/hide");
      })
      .catch(() => {
        this.$store.dispatch("loader/hide");

      });
  }
};
</script>
