<template>
  <div id="reports">
    <div class="columns min-height-classlist">
      <div class="column is-one-fifth reports-list has-background-white">
        <hr />
        <b-collapse
          class="card"
          v-for="(collapse, index) of collapses"
          :key="index"
          :open="isOpen == index"
          @open="isOpen = index"
        >
          <div slot="trigger" slot-scope="props" class="card-header" role="button">
            <p class="card-header-title">{{ collapse.title }}</p>
            <a class="card-header-icon">
              <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
            </a>
          </div>
          <div
            v-show="hasPermission(list.permission)"
            v-for="list in collapse.lists"
            :key="list.title"
            class="card-content"
          >
            <div class="content">
              <a @click="$router.push('/classlist/'+list.to)">{{ list.title }}</a>
            </div>
          </div>
        </b-collapse>
      </div>

      <router-view class="column exports-view is-four-fifth" />
    </div>
  </div>
</template>


<script>

export default {
  components: {},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Class Lists",
          lists: [

            {
              title: "By Module",
              to: "by-module",
              permission: "view by module classlists"
            }
          ]
        }
      ]
    };
  },
  computed: {},
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    }
  }
};
</script>

<style lang="scss" >
.min-height-classlist {
  min-height: 100vh;
}
.reports-list {
  .card:not(:last-child) {
    margin-bottom: 0 !important;
  }
}
#reports {
  max-width: 85vw;
}
canvas {
  height: 500px !important;
}
</style>
