import {
  TransformModel
} from '@/models/TransformModel'


export default class EnquiryWonByUserOverTime extends TransformModel {
  static entity = 'EnquiriesWonByUserOverTime'
  static primaryKey = ['year', 'week', 'user']

  static fields() {
    return {
      won: this.attr(null),
      user: this.attr(null),
      month: this.attr(null),
      week: this.attr(null),
      year: this.attr(null)
    }
  }
  static fetchAll(start = null, end = null, filter = true, year = null, semester = null) {
    return this.api().get(`/reports/enquiries-won-by-user-over-time`, {
      params: {
        filter: filter,
        start: start,
        end: end,
        ...(year ? {
          year: year
        } : {}),
        ...(semester ? {
          semester: semester
        } : {})
      },

      dataTransformer: (response) => {


        return response.data.data.attributes.data



      }
    })
  }



}
