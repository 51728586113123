<template>
  <div class="container">
    <div class="section">
      <b-tabs v-model="activeTab" :destroy-on-hide="true" class="has-background-white box " expanded
              multiline
      >
        <b-tab-item label="Student Details">

          <LearnerDetailsForm
            :can-delete="false"
            :id="$store.state.userId"
            :can-edit="true"
            :edit="true"
          />
        </b-tab-item>
        <b-tab-item label="Invoices">
          <InvoicePanel :admin="false" :learner_id="$store.state.userId" />
        </b-tab-item>
      </b-tabs>
    </div>
  </div>
</template>
<script>
import LearnerDetailsForm from "@/components/users/LearnerDetailsForm";
import LearnerRegistrationFlow from "@/mixins/LearnerRegistrationFlow";
import InvoicePanel from "@/components/invoices/InvoicePanel";

export default {
  name:'LearnerProfile',
  components: {LearnerDetailsForm, InvoicePanel},
  data(){
    return {
      activeTab:0
    }
  },
  mixins: [LearnerRegistrationFlow],

  async created() {
    this.$store.dispatch("loader/showOpaque");
    await this.checkRegistrationFlow(this.$route.name).then(() => {}).catch(err => this.$router.push(err))
    this.$store.dispatch('loader/hideOpaque')

  }
}
</script>
