<template>
  <BoardIndex
    :board_title="title"

    :team_permission="permission"
    :board_id="10"

  />

</template>

<script>
import BoardIndex from "@/components/BoardIndex";

export default {
  name: "RPL-for-access-board",
  components: {
    BoardIndex
  },
  data() {
    return {
      title: 'RPL For Access',
      permission: 'view rpl for access board',

    };
  },

};
</script>

