import {
  Model
} from '@vuex-orm/core'

export default class IntakeSemester extends Model {
  static entity = 'intake-semester'

  static primaryKey = ['intake_id', 'semester_id']


  static fields() {
    return {
      id: this.attr(null),
      intake_id: this.attr(null),
      semester_id: this.attr(null)
    }
  }


}
