import {TransformModel} from '@/models/TransformModel'

export default class Qualification extends TransformModel {
  static entity = 'qualifications'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      long_name: this.attr(null),
      code: this.attr(null),
      department_id: this.attr(null),
      nqf_level: this.attr(null),
      standardised_level_id: this.attr(null),
    }
  }

  static fetchAll(page = 1, limit = 999) {
    return this.api().get(`/qualifications`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }

  static search(data, saved = true, is_open = null, page = 1, limit = 100, relationships = []) {
    return this.api().get(`/qualifications?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(is_open ? {
          is_open: 1
        } : {}),
        ...(relationships ? {
          with: relationships
        } : {})
      },
      save: saved
    })
  }

  static Delete(qualificationId) {
    return this.api().delete(`/qualifications/${qualificationId}`, {
      delete: qualificationId
    })
  }

  static Store(qualification) {
    return this.api().post(`/qualifications`, qualification, {
      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static Update(qualification) {
    return this.api().patch(`/qualifications/${qualification.id}`, qualification, {

      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        return target
      }
    })
  }

  static fetchById(id,relationships=['courses']) {
    return this.api().get(`/qualifications/${id}`, {
      params: {
        ...(relationships ? {
          with: relationships
        } : {})

      },
      dataTransformer: (response) => {
        let target = response.data.data
        if (Object.prototype.hasOwnProperty.call(target, 'courses')) {

          target.courses.forEach(course => {
            Object.assign(course, course.attributes)
          });
        }
        Object.assign(target, target.attributes)
        return target
      }
    })
  }

}
