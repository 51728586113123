import {TransformModel} from '@/models/TransformModel'
import User from './User'
import Stage from './Stage'
import Course from './Course'
import Note from './Note'

export default class Enquiry extends TransformModel {
  static entity = 'enquiries'

  static fields() {
    return {
      activities: this.attr(() => ([{
        created_at: null,
        note: null,
        user: {},
      }])),
      id: this.attr(null),
      total_tasks_count: this.attr(null),
      completed_tasks_count: this.attr(null),
      first_name: this.attr(null),
      last_name: this.attr(null),
      email: this.attr(null),
      primary_contact_number: this.attr(null),
      type: this.attr(''),
      message: this.attr(''),
      owner_id: this.attr(null),
      owner: this.belongsTo(User, 'owner_id'),
      stage_id: this.attr(null),
      stage: this.belongsTo(Stage, 'stage_id'),
      title: this.attr(null),
      creator_id: this.attr(null),
      creator: this.belongsTo(User, 'creator_id'),
      course_id: this.attr(null),
      course: this.belongsTo(Course, 'course_id'),
      status_id: this.attr(null),
      notes: this.hasMany(Note, 'enquiry_id'),
      created_at: this.attr(''),
      updated_at: this.attr(''),
      year: this.attr(''),
      semester: this.attr(''),
      marketing_source_id: this.attr(null),
      age_human_readable: this.attr(''),
      token_url: this.attr(null)

    }
  }

  static deleteRemote(enquiryId) {
    return this.api().delete(`/enquiries/${enquiryId}`, {
      save: false
    })
  }

  static updateRemote(enquiry) {
    return this.api().patch(`/enquiries/${enquiry.id}`, enquiry, {
      save: false
    })
  }

  static newEnquiry(enquiry) {
    return this.api().post(`/enquiries`, enquiry, {
      save: false
    })
  }

  static setStatusLost(enquiry) {
    return this.api().post(`/enquiries/${enquiry.id}/lost`, {
      save: false
    })
  }

  static setStatusWon(enquiry) {
    return this.api().post(`/enquiries/${enquiry.id}/won`, {
      save: false
    })
  }

  static setStatusNone(enquiry) {
    return this.api().post(`/enquiries/${enquiry.id}/new`, {
      save: false
    })
  }

  static fetchByUser(page, limit, userId) {
    return this.api().get(`/enquiries?with[]=user&with[]=course&page=${page}&limit=${limit}`, {
      params: {

        ...(userId ? {
          user_id: userId
        } : {}),

      },
      dataTransformer: (response) => {

        let storageArray = []
        if (response.data.data.length > 1) {
          response.data.data.forEach(element => {

            let target = element
            Object.assign(target, element.attributes)

            storageArray.push(target)

            if (target.course) {
              let course = target.course
              Object.assign(course, course.attributes)
              Course.insert({
                data: course
              })
            }

          });
        } else if (response.data.data.length == 1) {

          let target = response.data.data[0]

          Object.assign(target, target.attributes)
          storageArray.push(target)

          if (target.course) {
            let course = target.course
            Object.assign(course, course.attributes)
            Course.insert({
              data: course
            })
          }

        }


        return storageArray
      }
    })
  }

  static fetchAll(page = 1, limit = 100, query = {}, by_stages = false) {
    return this.api().get(`/enquiries?with[]=user&with[]=course&page=${page}&limit=${limit}`, {
      params: {

        ...(query.user_id ? {
          user_id: query.user_id
        } : {}),
        ...(query.creator_id ? {
          creator_id: query.creator_id
        } : {}),
        ...(query.owner_id === null ? {
          owner_id: 'null'
        } : {}),
        ...(query.owner_id ? {
          owner_id: query.owner_id
        } : {}),
        ...(query.year ? {
          year: query.year
        } : {}),
        ...(query.semester ? {
          semester: query.semester
        } : {}),
        ...(query.course_id === null ? {
          course_id: 'null'
        } : {}),
        ...(query.course_id ? {
          course_id: query.course_id
        } : {}),
        ...(query.order_by ? {
          order_by: query.order_by
        } : {}),
        ...(query.order_direction ? {
          order_direction: query.order_direction
        } : {}),
        ...(query.status_id == 1 ? {
          status_id: 1
        } : {}),
        ...(by_stages == true ? {
          by_stages: 1
        } : {}),


      },
      dataTransformer: (response) => {

        let storageArray = []
        response.data.data.forEach(element => {

          if (element.attributes.course) {
            element.attributes.course = {
              ...element.attributes.course,
              ...element.attributes.course.attributes
            }
          }
          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)

        })


        return storageArray
      }
    })
  }

  static fetchById(id) {
    return this.api().get(`/enquiries/${id}?with[]=user&with[]=course&with[]=stage&with[]=activities.user`, {
      dataTransformer: (response) => {

        let storageArray = []
        let element = response.data.data

        element.attributes.stage = {
          ...element.attributes.stage,
          ...element.attributes.stage.attributes
        }
        if (element.attributes.course) {
          element.attributes.course = {
            ...element.attributes.course,
            ...element.attributes.course.attributes
          }
        }
        element = {
          ...element,
          ...element.attributes
        }
        storageArray.push(element)

        return storageArray
      }

    })
  }

}
