import {
  Model
} from '@vuex-orm/core'

export default class UserEmail extends Model {
  static entity = 'userEmail'

  static primaryKey = ['email_id', 'user_id']


  static fields() {
    return {
      email_id: this.attr(null),
      user_id: this.attr(null)
    }
  }


}
