<template>
    <div class="modal-card" style="width: auto">
      <header class="modal-card-head">
        <p class="modal-card-title is-bold"><strong>LMS Code History</strong></p>
      </header>
      <section class="modal-card-body">
        <b-table
          :data="module.lms_codes"
          :bordered="true"
        >

            <b-table-column field="lms_code" label="Code" width="70%" v-slot="props">{{ props.row?.lms_code}}</b-table-column>
            <b-table-column field="semester.name" label="Semester" width="40%" v-slot="props">{{props.row?.semester?.name}}</b-table-column>

        </b-table>
      </section>
      <footer class="modal-card-foot is-justify-content-right">
        <b-button
          label="Close"
          @click="$emit('close')" />
      </footer>
    </div>
</template>

<script>
export default {
  name: 'LMSCodeModal',
  props: {
    module: {
      type: Object,
    },
  },
  data () {
    return {
      isModalActive: true
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    }
  },
}
</script>
