<template>
  <section class="section">
    <title-bar>Create a new registration</title-bar>
    <card-component :icon="$tc('icons.registrations')" title="Registration Form">
      <form @submit.prevent="submit">
        <b-field :label="$tc('Learner',1)" horizontal>
          <b-field>
            <b-autocomplete
              v-model="selectedLearner.name"
              v-debounce:300ms="getFilteredLearner"
              :clear-on-select="false"
              :data="filteredLearners"
              :icon="$tc('icons.learners')"
              :loading="loadingLearners"
              autocomplete="off"
              field="first_name"
              name="first_name"
              placeholder="Name"
              required
              @blur="validateAutocomplete(form.user_id,'selectedLearner','name')"
              @select="option => selectLearner(option)"
            >
              <template slot-scope="props">
                <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>
        <b-field horizontal label="Year of Study">
          <b-select v-model="form.level" placeholder="Select the registration year" required>
            <option v-for="level in 5" :key="level" :value="level">{{ level }}</option>
          </b-select>
        </b-field>
        <b-field horizontal label="Year of Entry">
          <b-field>
            <b-input v-model="form.year" placeholder="2000" required type="number"></b-input>
          </b-field>
        </b-field>
        <b-field :label="$tc('Course',1)" horizontal>
          <b-field>
            <b-autocomplete
              v-model="courseHold"
              v-debounce:300ms="getFilteredCourse"
              :clear-on-select="false"
              :data="filteredCourses"
              :icon="$tc('icons.courses')"
              :loading="loadingCourses"
              :placeholder="$tc('Course',1) + ' Name'"
              autocomplete="off"
              field="name"
              name="Course"
              required
              @blur="validateAutocomplete(form.course_id,'courseHold')"
              @select="option => selectCourse(option)"
            >
              <template slot-scope="props">
                <p>{{ props.option.code }} | {{ props.option.name }}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>
<!--        <b-field label="Modules" horizontal>-->
<!--          <b-table-->
<!--            scrollable-->
<!--            :header-checkable="false"-->
<!--            class="margin-top"-->
<!--            narrowed-->
<!--            :data="filteredModules"-->
<!--            :striped="true"-->
<!--            :hoverable="true"-->
<!--            :bordered="true"-->
<!--          >-->
<!--            <b-table-column v-slot="props"-->
<!--              label="Code"-->
<!--              field="code"-->
<!--              sortable-->
<!--              width="40"-->
<!--              numeric-->
<!--            >{{ props.row.code }}-->
<!--            </b-table-column-->
<!--            >-->

<!--            <b-table-column v-slot="props" sortable field="name" label="Name">{{-->
<!--                props.row.name-->
<!--              }}-->
<!--            </b-table-column>-->
<!--            <b-table-column v-slot="props"-->
<!--              sortable-->
<!--              field="level"-->
<!--              label="Level (year)"-->
<!--            >{{ props.row.level }}-->
<!--            </b-table-column-->
<!--            >-->
<!--            <b-table-column v-slot="props"-->
<!--              sortable-->
<!--              field="subject.name"-->
<!--              label="Subject"-->
<!--            >{{ props.row.subject.name }}-->
<!--            </b-table-column-->
<!--            >-->
<!--            <b-table-column v-slot="props" sortable field="standardised_level_id" label="Standardised Level">{{ props.row.standardised_level_id?$store.state.levels.levels.find(level=> level.id===props.row.standardised_level_id).name:'None'  }}</b-table-column>-->

<!--            <b-table-column v-slot="props"-->


<!--              label="Semester"-->
<!--            >-->

<!--              <b-field v-if="props.row.semesters.length>1">-->
<!--                <b-select @input="selectSemester($event,props.row)"-->
<!--                          :required="selectedModuleIds.includes(props.row.id)"-->
<!--                          :disabled="!selectedModuleIds.includes(props.row.id)"-->
<!--                          :value="selectedSemester(props.row)"-->
<!--                          placeholder="Semester">-->
<!--                  <option v-for="semester in props.row.semesters" :key="semester.id" :value="semester.id">-->
<!--                    {{ semester.name }}-->
<!--                  </option>-->
<!--                </b-select>-->
<!--              </b-field>-->
<!--              <b-field v-else-if="props.row.semesters.length===1"> {{ props.row.semesters[0].name }}-->
<!--              </b-field>-->
<!--            </b-table-column>-->

<!--            <b-table-column-->
<!--              field="credits"-->
<!--              sortable-->
<!--              label="Credits |:"-->
<!--              numeric-->
<!--            >-->
<!--              <template v-slot:header="{column}"-->
<!--              >{{ column.label }} {{ totalCredits }}-->
<!--              </template-->
<!--              >-->
<!--              <template v-slot="props" >{{ props.row.credits }}</template>-->
<!--            </b-table-column>-->
<!--            <b-table-column v-slot="props"-->
<!--              field="status_id"-->
<!--              sortable-->

<!--              label="Status"-->
<!--            >-->

<!--              <b-field>-->
<!--                <b-select :disabled="!selectedModuleIds.includes(props.row.id)"-->
<!--                          :required="selectedModuleIds.includes(props.row.id)"-->
<!--                          :v-model="props.row.status_id"-->
<!--                          placeholder="Status" @input="changeModuleStatus($event,props.row.id)">-->
<!--                  <option v-for="status in moduleStatuses" :key="status.id" :value="status.id">-->
<!--                    {{ status.name }}-->
<!--                  </option>-->
<!--                </b-select>-->
<!--              </b-field>-->
<!--            </b-table-column>-->
<!--            <b-table-column v-slot="props"  :label="`${$tc('lms',2)} ID`">-->
<!--              <span >{{ props.row.lms_id !== null ? props.row.lms_id : 'Not synced' }}</span>-->
<!--            </b-table-column>-->
<!--            <b-table-column v-slot="props" width="120" class="has-text-centered" custom-key="actions">-->
<!--              <b-tooltip multilined position="is-left"-->
<!--                         :active="previouslySelectedModules.includes(parseInt(props.row.id))"-->
<!--                         label="This module has already been completed">-->
<!--                <b-checkbox-->
<!--                  @click.native.prevent="checkLevel($event,props.row)"-->
<!--                  v-model="selectedModuleIds"-->
<!--                  :disabled="previouslySelectedModules.includes(parseInt(props.row.id))"-->
<!--                  :native-value="props.row.id"-->
<!--                ></b-checkbox>-->
<!--              </b-tooltip>-->

<!--            </b-table-column>-->

<!--          </b-table>-->
<!--        </b-field>-->
        <b-field horizontal v-if="form.course_id" label="Delivery Type">
          <b-select required v-model="delivery_type" placeholder="Select a delivery type">
            <option
              v-if="form.course.delivery_type_id == 1 || form.course.delivery_type_id == 3"
              value="1"
            >Contact
            </option>
            <option
              v-if="form.course.delivery_type_id == 2 || form.course.delivery_type_id == 3"
              value="2"
            >Online
            </option>
          </b-select>
        </b-field>
        <b-field horizontal label="Enrolment Date">
          <b-datepicker
            v-model="enrolment_date"
            :years-range="[-100,100]"
            icon="calendar-today"
            placeholder="Click to select..."
            trap-focus
            type="month"
          ></b-datepicker>
        </b-field>
        <b-field horizontal label="Marketing Source">
          <b-select
            v-model="form.marketing_source_id"
            placeholder="Select the marketing source"
          >
            <option
              v-for="source in sources"
              :key="source.id"
              :value="source.id"
            >
              {{ source.name }}
            </option>
          </b-select>
        </b-field>
        <b-field horizontal label="Stage">
          <b-select v-model="form.stage_id" placeholder="Select the registration stage" required>
            <optgroup :key="index" v-for="(board,index) in boards"
                      :label="board.name">

              <option :key="stages.id" v-for="stages in board.stages" :value="stages.id">{{ stages.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-field v-if="edit_registrations" horizontal label="Assign to">
          <b-select
            v-model="form.owner_id"
            :icon="$tc('icons.users')"
            placeholder="Select the registration owner"
            required
          >
            <option v-for="user in users" :key="user.id" :value="user.id">
              <p>{{ user.first_name }} {{ user.last_name }} | {{ user.email }}</p>
            </option>
          </b-select>
        </b-field>
        <b-field horizontal label="Message">
          <b-input
            v-model="form.message"
            placeholder="Explain how we can help you"
            type="textarea"
          />
        </b-field>
        <hr/>

        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button :disabled="submitting" :loading="submitting" native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Course from "@/models/Course";
import Learner from "@/models/Learner";
import Stage from "@/models/RegistrationStage";
import User from "@/models/User";
import Module from "@/models/Module";
import Registration from "@/models/Registration";
import Lookup from "@/models/Lookup";
import RegistrationBoard from "@/models/RegistrationBoard";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      submitting: false,
      course: null,
      loaded: false,
      date: new Date(),
      loadingModuleAdd: false,
      selectedModule: null,
      selectedModuleIds: [],
      selectedLearner: {name: ""},
      filteredLearners: [],
      filteredCourses: [],
      filteredModules: [],
      previouslySelectedModules: [],
      moduleStatuses: [{
        name: 'Registered', id: 1
      }, {name: 'Withdrawn', id: 2}],
      loadingCourses: false,
      courseHold: "",
      loadingLearners: false,
      selectedModules: [],
      form: {
        level: null,
        course_id: null,
        year: null,
        user_id: null,
        stage_id: null,
        owner_id: null,
        modules: [],
        enrolment_date: null,
        registration_status_id: 4,
        delivery_type_id: "",
        message: null,
        marketing_source_id: null
      },
    };
  },
  computed: {
    sources() {
      return Lookup.query().where("lookup_type_id", 7).get();
    },
    delivery_type: {
      get() {
        if (this.form.delivery_type_id != null) {
          return this.form.delivery_type_id.toString();
        }
        return "";
      },
      set(newValue) {
        this.form.delivery_type_id = parseInt(newValue);
      },
    },
    totalCredits() {
      let total = 0;
      this.selectedModules.forEach((module) => {

        total += module.credits;
      });
      return total;
    },
    enrolment_date: {
      set(newValue) {
        this.date = new Date(newValue);
      },
      get() {
        return new Date(this.date);
      },
    },
    owner_id() {
      if (this.edit_registrations) {
        return this.form.owner_id;
      } else {
        return this.$store.state.userId;
      }
    },
    users() {
      return User.all();
    },
    edit_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "edit registrations"
      );
    },
    boards() {
      return RegistrationBoard.query().where('name',(name)=>(name!=='Deleted')&&(name!=='In Transit')).orderBy('order').with('stages').get()
    },
  },
  mounted() {
    User.deleteAll();
    Stage.fetchAll();
    User.fetchAll("edit registrations");
    Lookup.fetchAll();
    Registration.deleteAll()
  },
  methods: {
    changeModuleStatus(event, id) {
      if (!this.selectedModuleIds.includes(id)) {
        return
      }


      this.selectedModules[this.selectedModules.findIndex(module => module.id == id)].status_id = parseInt(event)


    },
    validateAutocomplete(source, target1, target2 = null) {
      if (source == null) {
        if (target2 !== null) {
          this[target1][target2] = null
        } else {
          this[target1] = null
        }
      }
    },
    checkLevel($evt, module) {
      if (this.previouslySelectedModules.includes(parseInt(module.id))) {
        return
      }
      if (this.selectedModules.some((item) => item.id == module.id)) {
        this.removeModule(module.id);
      } else {
        this.confirmSelectModule(module);
      }
    },

    removeModule(module_id) {
      this.selectedModules = this.selectedModules.filter(
        (el) => el.id != module_id
      );
      this.selectedModuleIds = this.selectedModuleIds.filter(
        (el) => el != module_id
      );
    },
    confirmSelectModule(module) {
      this.loadingModuleAdd = true;
      this.selectedModuleIds.push(module.id);

      this.selectedModules.push(module);
      this.loadingModuleAdd = false;
    },

    getFilteredModule(text, limit = 60) {
      this.loadingModules = true;

      return Module.search(
        text,
        true,
        1,
        limit,
        this.form.course_id,
        null
      ).then(() => {
        this.filteredModules = Module.query().with('semesters').get();
        this.loadingModules = false;

      });
    },
    selectCourse(course) {
      if (course) {
        this.selectedModules = [];
        this.selectedModuleIds = [];
        this.form.course_id = course.id;
        this.form.course = course;
        this.loadingCourses = true;

        Course.fetchById(course.id)
          .then(() => {
            this.course = Course.query()
              .whereId(course.id)
              .with("modules")
              .get()[0];
            this.getFilteredModule("", 999).then(() => {
              this.loadingCourses = false;
            });
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      }
    },
    selectLearner(learner) {
      this.$store.dispatch('loader/show')
      this.form.user_id = learner.id;
      this.selectedLearner.name = learner.first_name + " " + learner.last_name;
      Registration.fetchByUser(1, 999, this.$store.state.userId).then(() => {
        let previousRegistrations = Registration.query().with('modules').with('modules.semesters').get()
        if (previousRegistrations.length > 0) {
          previousRegistrations.forEach(registration => registration.modules.forEach(module => {
            if (module.hasOwnProperty('status_id')) {
              if (module.status_id == 1 && module.is_passed !== 1) {
                this.previouslySelectedModules.push(parseInt(module.id))
              }
            }
          }))
        }

      }).finally(() => {
        this.$store.dispatch('loader/hide')

      })

    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.search(text, true, 1).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.filteredCourses = result.entities.courses;
        } else {
          this.filteredCourses = [];
        }
      });
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.filteredLearners = result.entities.learners;
        } else {
          this.filteredLearners = [];
        }
      });
    },
    submit() {
      this.submitting = true;
      // this.selectedModules.forEach((element) => {
      //   this.form.modules.push({
      //     module_id: parseInt(element.id),
      //     status_id: parseInt(element.status_id)
      //   });
      // });
      let submit_date = new Date(this.date);

      this.form.owner_id = this.owner_id;
      this.form.enrolment_date =
        submit_date.getFullYear() +
        "-" +
        (submit_date.getMonth() + 1) +
        "-" +
        submit_date.getDate();
      Registration.newRegistration(this.form)
        .then((result) => {
          setTimeout(() => {
            this.$router.push(
              `/registrations/edit/${result.response.data.data.id}?index_stage=${result.response.data.data.stage_id}`
            );
          }, 1000);
          this.$buefy.snackbar.open({
            message: "Registration Created!",
            queue: false,
            actionText: "Go to",
            type: "is-link",
            onAction: () => {
              this.$router.push(
                `/registrations/edit/${result.response.data.data.id}?index_stage=${result.response.data.data.stage_id}`
              );
            },
          });
        })
        .catch((err) => {
          this.submitting = false;

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
};
</script>
