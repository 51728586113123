<template>
  <Lookup :lookup_type_id="21" :title="'Employment Status'"></Lookup>
</template>


<script>
import Lookup from "@/components/Lookup";
export default {
  name: "EmploymentStatuses",
  components: {
    Lookup,
  },
  data() {
    return {};
  },
};
</script>
