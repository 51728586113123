export const LevelsModule = {
  namespaced: true,

  state: {
    levels:
      [
        {id: 1, name: "Basic"},
        {id: 2, name: "Intermediate"},
        {id: 3, name: "Advanced"},
        {id: 4, name: "Postgrad"},
      ]

  },

};
