import {TransformModel} from '@/models/TransformModel'
import {format} from "date-fns";
import Semester from "@/models/Semester";
import IntakeSemester from "@/models/IntakeSemester";

export default class Intake extends TransformModel {
  static entity = 'intakes'

  get formatted_start_date() {
    return format(new Date(this.start_date), 'yyyy-MM-dd')
  }

  get formatted_end_date() {
    return format(new Date(this.end_date), 'yyyy-MM-dd')
  }

  static fields() {

    return {
      id: this.attr(null),
      start_date: this.attr(null),
      end_date: this.attr(null),
      name: this.attr(null),
      semesters: this.belongsToMany(Semester, IntakeSemester, 'intake_id', 'semester_id'),

    }
  }

  static FetchAll({page = 1, limit = 15}, query = {}, relationships = []) {
    return this.api().get(`/intakes`, {
      params: {
        ...{
          page: page, limit: limit, with: relationships
        }, ...(query !== {} ? query : {}),
      },
      dataTransformer: (response) => {
        return this.transformArray(response)
      }
    })
  }


  static Delete(intakeId) {
    return this.api().delete(`/intakes/${intakeId}`, {
      delete: intakeId
    })
  }

  static Store(intake) {
    return this.api().post(`/intakes`, intake, {
      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static Update(intake) {
    return this.api().patch(`/intakes/${intake.id}`, intake, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }

  static FetchById(id, relationships = []) {
    return this.api().get(`/intakes/${id}`, {
      params: {

        ...(relationships ? {
          with: relationships
        } : {})

      },
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        if (Object.prototype.hasOwnProperty.call(target, 'semesters')) {

          target.semesters.forEach(semester => {
            Object.assign(semester, semester.attributes)
          });
        }
        return target
      }
    })
  }

  static search(data, saved = true, page = 1, limit = 100, relationships = []) {
    return this.api().get(`/intakes?search=${data}&limit=${limit}&page=${page}`, {
      dataTransformer: (response) => {
        return this.transformArray(response)
      },
      params: {
        ...(relationships ? {
          with: relationships
        } : {})
      },
      save: saved
    })
  }

}
