import {TransformModel} from '@/models/TransformModel'

export default class CourseDocuments extends TransformModel {
  static entity = 'course-documents'

  static fields() {
    return {
      id: this.attr(null),
      name:this.attr(null)

    }
  }

  static FetchAll(page = 1, limit = 999, course_id) {
    return this.api().get(`/courses/${course_id}/documents`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(document => {
          document.id = parseInt(document.id)
          return {...document, ...document.attributes}
        })
      },
    })
  }

  static Update(course_documents) {
    return this.api().patch(`/courses/${course_documents.course_id}/documents`, course_documents, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }
}
