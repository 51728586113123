import {TransformModel} from '@/models/TransformModel'
import Media from "@/models/Media";
import CourseDocuments from "@/models/CourseDocument";

export default class UserDocument extends TransformModel {
  static entity = 'user-documents'

  static fields() {
    return {
      id: this.attr(null),
      verified  :this.attr(0),
      verification_note:this.attr(null),
      media_id:this.attr(null),
      media:this.belongsTo(Media,'media_id'),
      document_id:this.attr(null),
      course_document:this.belongsTo(CourseDocuments,'document_id'),
      has_been_checked:this.attr(0)
    }
  }

  static FetchAll(page = 1, limit = 999, learner_id) {
    return this.api().get(`/learners/${learner_id}/documents`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(document => {
          document.id = parseInt(document.id)
          return {...document, ...document.attributes}
        })
      },
    })
  }

  static Update(learner_documents,learner_id) {
    return this.api().patch(`/learners/${learner_id}/documents`, learner_documents, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }


}
