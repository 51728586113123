<template>

  <div class="is-relative">
    <div :class="{'modal-card':inModal,'card':!inModal}">
      <header v-if="inModal" class="modal-card-head">
        <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
      </header>
      <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Application Conditions</p></title-bar>

      <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
        <b-field v-if="Object.prototype.hasOwnProperty.call(conditions,'passed')" label="Overall Conditions Status">
          This Application is currently {{ conditions.passed ? 'passing' : 'failing' }} the conditions check.
        </b-field>
        <template v-if="core_options_condition.length>0">
          <label class="label">Specialisations</label>
          <b-table
            :bordered="true"
            :data="core_options_condition"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >
            <b-table-column v-slot="props"
                            field="name"
                            label="Name"
                            sortable
            >{{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>

            <b-table-column v-slot="props"
                            field="credits_required_for_core"
                            label="Credits Required"
                            sortable
            >{{ props.row.credits_required_for_core }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="prior_credits_achieved_for_core"
                            label="Prior Credits Achieved"
                            sortable
            >{{ props.row.prior_credits_achieved_for_core }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="needs_supplement"
                            label="Needs Supplement"
                            sortable
            >{{ props.row.needs_supplement ? 'Yes' : 'No' }}
            </b-table-column>
          </b-table>
        </template>
        <template v-if="documents_condition.length>0">
          <label class="label">Documents</label>
          <b-table
            :bordered="true"
            :data="documents_condition"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >
            <b-table-column v-slot="props"
                            field="name"
                            label="Name"
                            sortable
            >{{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="uploaded"
                            label="Uploaded"
                            sortable
            >{{ props.row.uploaded ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="awaiting_verification"
                            label="Awaiting Verification"
                            sortable
            >{{ props.row.awaiting_verification === 1 ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="verified"
                            label="Verified"
                            sortable
            >{{ props.row.verified === 1 ? 'Yes' : 'No' }}
            </b-table-column>
          </b-table>
        </template>
        <template v-if="previous_studies.length>0">
          <label class="label">Previous Studies</label>
          <b-table
            :bordered="true"
            :data="previous_studies"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >

            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason"
                            label="Reason"
                            sortable
            >{{ props.row.reason }}
            </b-table-column>

          </b-table>
        </template>
        <template v-if="age_exemption.length>0">
          <label class="label">Age Exemption</label>
          <b-table
            :bordered="true"
            :data="age_exemption"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >

            <b-table-column v-slot="props"
                            field="applicant_age"
                            label="Applicant Age"
                            sortable
            >{{ props.row.applicant_age }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason"
                            label="Reason"
                            sortable
            >{{ props.row.reason }}
            </b-table-column>

          </b-table>
        </template>
        <template v-if="matric.length>0">
          <label class="label">Matric</label>
          <b-table
            :bordered="true"
            :data="matric"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >

            <b-table-column v-slot="props"
                            field="exemption_type"
                            label="Exemption Type"
                            sortable
            >{{ props.row.exemption_type }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason"
                            label="Reason"
                            sortable
            >{{ props.row.reason }}
            </b-table-column>

          </b-table>
        </template>
        <template v-if="school_subjects.length>0">
          <label class="label">School Subjects</label>
          <b-table
            :bordered="true"
            :data="school_subjects"
            :header-checkable="false"
            :hoverable="true"
            :striped="true"
            class="mb-5"
          >

            <b-table-column v-slot="props"
                            field="name"
                            label="Name"
                            sortable
            >{{ props.row.name }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="passed"
                            label="Passed"
                            sortable
            >{{ props.row.passed ? 'Yes' : 'No' }}
            </b-table-column>
            <b-table-column v-slot="props"
                            field="reason"
                            label="Reason"
                            sortable
            >{{ props.row.reason }}
            </b-table-column>

          </b-table>
        </template>

      </section>
    </div>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
  </div>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import Registration from "@/models/Registration";


export default {
  name: "RegistrationConditionsPanel",
  components: {
    TitleBar,
  },
  data() {
    return {
      loading: false,
      conditions: {conditions:{}}

    }
  },
  computed: {
    core_options_condition() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'core_options')) {
        return this.conditions.conditions.core_options
      }
      return []
    },
    documents_condition() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'documents')) {
        return this.conditions.conditions.documents
      }
      return []
    },
    previous_studies() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'previous_studies')) {
        return this.conditions.conditions.previous_studies
      }
      return []
    },
    age_exemption() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'age_exemption')) {
        return this.conditions.conditions.age_exemption
      }
      return []
    },
    matric() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'matric')) {
        return this.conditions.conditions.matric
      }
      return []
    },
    school_subjects() {
      if (Object.prototype.hasOwnProperty.call(this.conditions.conditions, 'school_subjects')) {
        return this.conditions.conditions.school_subjects
      }
      return []
    },

  },
  async created() {
    this.loading = true
    await Registration.checkStatus(this.id).then(({response: {data: data}}) => {
      this.conditions = data
    })

    this.loading = false
  },
  props: {

    id: {
      type: String,
      default() {
        return null
      }
    },

    inModal: {
      type: Boolean, default() {
        return false
      }
    },

  }
}
</script>

