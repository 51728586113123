<template>
  <section class="section">
    <title-bar>{{ $tc('Learner', 2) }}</title-bar>

    <div class="columns">
      <div class="column is-half">
        <b-field label="Filter" horizontal>
          <b-input
            v-debounce:300ms="getFilteredLearner"
            placeholder="Type any parameter here"
            :loading="loadingFilter"
            v-model="filter"
          ></b-input>
        </b-field>
      </div>
    </div>
    <b-table class="margin-top" :data="learners" :striped="true" :hoverable="true" :bordered="true">
      <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{ props.row.id }}</b-table-column>
      <b-table-column v-slot="props" label="ID Number">{{
          props.row.profile !== null ? props.row.profile.id_number : 'None'
        }}
      </b-table-column>
      <b-table-column v-slot="props" label="Student Number" field="student_number" sortable>{{
          props.row.student_number
        }}
      </b-table-column>

      <b-table-column v-slot="props" sortable field="first_name" label="First Name">{{ props.row.first_name }}</b-table-column>

      <b-table-column v-slot="props" sortable field="last_name" label="Last Name">{{ props.row.last_name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="email" label="Email">{{ props.row.email }}</b-table-column>
      <b-table-column v-slot="props" sortable field="registrations_count" label="Registrations">{{
          props.row.registrations_count
        }}
      </b-table-column>
      <b-table-column v-slot="props" width="80" custom-key="actions">
        <b-field grouped>
          <b-field>
            <b-button tag="router-link" :to="`/learners/edit/${props.row.id}`" type="is-info">View</b-button>
          </b-field>
          <b-field v-if="!!$store.getters[`entities/permissions/find`]('delete learners')">
            <b-button @click="start_delete(props.row.id)"
                      :disabled="!$store.getters[`entities/permissions/find`]('delete learners')" type="is-danger">
              Delete
            </b-button>
          </b-field>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      class="margin-top"
      :total="meta.total"
      :current="meta.current_page"
      :range-before="2"
      :range-after="2"
      :per-page="meta.per_page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Learner from "@/models/Learner";
import TitleBar from "@/components/TitleBar";

export default {
  name: "Learners",
  components: {TitleBar},
  data() {
    return {
      filter: "",
      loadingFilter: false,
      learnersDataHold: null,
      meta: Object,
      page: 1
    };
  },
  methods: {
    start_delete(user_id) {
      this.$buefy.dialog.confirm({
        title: "Deleting Learner",
        message:
          "Are you sure you want to delete this learner? This action cannot be undone.",
        confirmText: "Delete learner",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.confirm_delete(user_id),
      });
    },
    confirm_delete(user_id) {
      this.$store.dispatch("loader/show");
      Learner.deleteRemote(user_id)
        .then(() => {
          this.$store.dispatch("loader/hide");

          this.$buefy.snackbar.open({
            message: "Learner deleted!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    viewLearner(id) {
      this.$router.push(`/learners/edit/${id}`);
    },
    getFilteredLearner(text) {
      this.loadingFilter = true;
      this.learnersDataHold = this.learners
      Learner.deleteAll()
      Learner.search(text, true).then(result => {
        this.loadingFilter = false;

        this.learnersDataHold = null

        this.meta = result.response.data.meta;
      });
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      if (this.filter.length > 0) {
        this.learnersDataHold = this.learners
        Learner.deleteAll()
        Learner.search(this.filter, true, pageNumber, 15).then(result => {
          this.learnersDataHold = null
          this.meta = result.response.data.meta;
          this.$store.dispatch("loader/hide");
        });
      } else {

        this.getLearners(pageNumber, this.meta.per_page).then(() => {
          this.$store.dispatch("loader/hide");
        });
      }
    },
    getLearners(page, limit) {
      this.learnersDataHold = this.learners
      Learner.deleteAll()
      return Learner.fetchAll(page, limit).then(result => {
        this.learnersDataHold = null

        this.meta = result.response.data.meta;
      });
    }
  },

  computed: {
    learners() {
      if (this.learnersDataHold === null) {
        return Learner.query().with('profile').get()
      }
      return this.learnersDataHold
    },
    edit_learners() {
      return this.$store.getters["entities/permissions/find"]("edit learners");
    }
  },
  mounted() {
    this.$store.dispatch("loader/show");

    this.getLearners(1, 15)
      .then(() => {
        this.$store.dispatch("loader/hide");
      })
      .catch(err => {
        this.$store.dispatch("loader/hide");

      });
  }
};
</script>
