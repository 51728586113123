<template>
  <div class="container is-relative">
    <b-collapse
      :open="isOpen"
      animation="slide"
      aria-id="contentIdForA11y3"
      class="card has-boxshadow"
      @close="isOpen = false"
      @open="isOpen = true">
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
        >
          <div class="card-header-title is-flex is-justify-content-space-between">
            <span>{{ registration.course.code }} - {{ registration.course.name }} {{ registration.year }}</span>
            <span>{{ status }}</span>
          </div>
          <a class="card-header-icon">
            <b-icon
              :icon="props.open ? 'menu-down' : 'menu-up'">
            </b-icon>
          </a>
        </div>
      </template>

      <div class="card-content" v-if="!gradeHold && !isSuspended">
        <template>
          <b-table :data="grades" :columns="columns"></b-table>
        </template>
        <b-field label="Released At:">
          {{ latest_released_at }}
        </b-field>
        <p class="is-size-5">Download your Grade Report/Transcript as PDF:</p>
        <b-field grouped>
          <b-button style="margin-right:10px;" type="is-success" @click="downloadGradeReport" :loading="reportDownloadLoading">
            Download Grade Report
          </b-button>
<!--          <b-button type="is-success" @click="downloadGradeTranscript" :loading="reportTranscriptLoading">-->
<!--            Download Course Transcript-->
<!--          </b-button>-->
        </b-field>

      </div>
      <div class="card-content" v-if="isSuspended">
        <p>Dear Student, Your studies have been suspended. Please refer to the correspondence you received in this regard. For further enquiries, please contact
          <a href="mailto:helpdesk@cornerstone.ac.za" target="_blank">helpdesk@cornerstone.ac.za</a>.</p>
      </div>
      <div class="card-content" v-if="gradeHold && !isSuspended">
        <p>Our records indicate that you have outstanding fees. Please contact our Finance Bursar <a
            href="mailto:bursar@cornerstone.ac.za" target="_blank">bursar@cornerstone.ac.za</a> to gain access to your grade report for this registration.</p>
      </div>

      <footer class="card-footer mb-4">
      </footer>
    </b-collapse>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"/>
  </div>

</template>

<script>
import Grade from "@/models/Grade";

export default {
  name: "LearnerGradesRegistrationListItem",
  data() {
    return {
      loading: false,
      isOpen: false,
      grades: [],
      reportDownloadLoading: false,
      reportTranscriptLoading: false,
    };
  },

  props: {
    registration: {
      type: Object,
      required: true
    }, user_id: {
      type: String,
      required: true
    },
  },
  methods: {
    downloadGradeReport() {
      this.reportDownloadLoading = true;
      Grade.downloadReport(this.registration).then((res) => {
        this.reportDownloadLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res.response.data]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.registration.user.student_number+'-'+this.registration.course.long_name+'-grade-report.pdf');
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    downloadGradeTranscript() {
      this.reportTranscriptLoading = true;
      Grade.downloadTranscript(this.registration).then((res) => {
        this.reportTranscriptLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res.response.data]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.registration.user.student_number+'-'+this.registration.course.long_name+'-grade-transcript.pdf');
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    }
  },
  computed: {
    isSuspended(){
      return this.registration?.user?.is_suspended ?? 0
    },
    gradeHold(){
      return this.registration?.grade_hold ?? 0
    },
    canContinue(){
      return !this.$store.state.statuses.STATUSES_REGISTRATION_COMPLETE.includes(parseInt(this.registration.registration_status_id))
    },
    status() {
      return this.$store.state.statuses.statuses.find(item => {
        return item.id == this.registration.registration_status_id
      }).name
    },
    latest_released_at() {
      let date = null;
      this.grades.forEach((grade) => {
        if (!date && grade.attributes.released_at) {
          date = grade.attributes.released_at;
        } else if (grade.attributes.released_at) {
          if (date < grade.attributes.released_at) {
            date = grade.attributes.released_at
          }
        }
      })
      return date;
    },
    columns() {
      return [
        {
          field: 'attributes.module.code',
          label: 'Module Code'
        },
        {
          field: 'attributes.module.name',
          label: 'Module (name)',
        },
        {
          field: 'attributes.grade',
          label:  'Grade',
        },
        {
          field: 'attributes.symbol',
          label:  'Symbol',
        },
        {
          field: 'attributes.module.credits',
          label:  'Credits',
        },
        {
          field: 'attributes.module.nqf',
          label:  'NQF Level',
        },
        {
          field: 'attributes.comment',
          label:  'Comment',
        },
      ];
    }
  },
  created() {
    Grade.fetchAll(1, 999, {registration: this.registration.id, is_released: 1}, ['module', 'user']).then((res) => {
      this.grades = res.response.data.data;
    })
  },
};
</script>
