<script>
export default {
  name: "TableDropdown",
  data(){
    return{
      dropdownPosition: 'is-bottom-left'
    }
  },
  methods:{
    returnDropdownElement(element){
      if(element.classList.contains('dropdown')){
        return element;
      }
      return this.returnDropdownElement(element.parentElement);
    },
     calculateDropdownPosition(event){
      setTimeout(()=>{
        const tableContainer = document.querySelector('.table-container');
        const dropdownContainer = this.returnDropdownElement(event.srcElement);

        const tableHeight = tableContainer.scrollHeight;
        let dropdownContainerHeight = dropdownContainer.childNodes[2]?.scrollHeight;
        const y = event.clientY - tableContainer.getBoundingClientRect().top;

        if((tableHeight - y) < dropdownContainerHeight) {
          this.dropdownPosition = 'is-top-left';
        } else {
          this.dropdownPosition = 'is-bottom-left';
        }
      },10)
    },

  }
}
</script>

<template>
  <b-dropdown aria-role="list" :position="dropdownPosition" >
    <template #trigger>
      <b-button type="is-info" @click="calculateDropdownPosition">View</b-button>
    </template>

    <slot></slot>

  </b-dropdown>
</template>

