import {TransformModel} from '@/models/TransformModel'
import { normalize } from "../utils/data";

import Semester from '@/models/Semester'
import Module from '@/models/Module'

export default class ModuleLMSCode extends TransformModel {
    static entity = 'module-lms-codes'


    static fields() {
        return {
            id: this.attr(null),
            module_id: this.attr(null),
            module: this.belongsTo(Module, 'module_id'),
            lms_code: this.attr(null),
            semester_id: this.attr(null),
            semester: this.belongsTo(Semester, 'semester_id'),
            year: this.attr(null),
        }
    }

    static FetchAll({page = 1, limit = 15, orderBy = 'id', orderDir = 'asc'} = {}, query = {}, relationships = []) {
        return this.api().get(`/module-lms-codes`, {
            params: {
                // page: page,
                // limit: limit,
                // orderBy,
                // orderDir,
                // with: relationships,
                // query: query
            },
            dataTransformer: (response) => {
                return normalize(response.data.data)
            }
        })
    }

    static Delete(codeId) {
        return this.api().delete(`/module-lms-codes/${codeId}`, {
            delete: codeId
        })
    }

    static Store(payload) {
        return this.api().post(`/module-lms-codes`, payload, {
            dataTransformer: (response) => {
                let target = response.data.data
                return normalize(target)
            }
        })
    }

    static Update(code) {
        return this.api().patch(`/module-lms-codes/${code.id}`, code, {
            dataTransformer: (response) => {
                let target = response.data.data
                return normalize(target)
            }
        })
    }

    static FetchById(id, relationships = []) {
        return this.api().get(`/module-lms-codes/${id}`, {
            params: {
                with: relationships,

            },
            dataTransformer: (response) => {
                let target = response.data.data
                return normalize(target)
            }
        })
    }

    static search(data, page = 1, limit = 100, relationships = []) {
        return this.api().get(`/lms/modules/search?search=${data}&limit=${limit}&page=${page}`, {
            params: {
                with: relationships
            },
        })
    }
}
