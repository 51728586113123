<template>
  <Export
    :can_generate="false"
    :date_filter="false"
    :default_type_value="null"
    :entities="entities"
    :file_types="file_types"
    :title="'Student FTE File 68'"
    :type="'edudex'"
    edudex_date_filter
  ></Export>
</template>


<script>
import Export from "@/components/Export";


export default {
  name: "edudexStudents",
  components: {
    Export,
  },
  data() {
    return {
      file_types: [{name: 'dat', value: null}, {name: "csv", value: 'csv'}, {name: "xls", value: 'xls'}, {name: "xlsx", value: "xlsx"}],
      entities: "student-fte",
      admissions_team: [],

    };
  },
};
</script>
