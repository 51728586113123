<template>
  <div class="listitem">
    <article class="media">
      <figure class="media-left">
        <p class="has-text-centered">
          <b-tooltip :label="type.name" position="is-top">
            <b-icon class="icon rounded is-large box is-64x64" :icon="type.icon"></b-icon>
          </b-tooltip>
        </p>
      </figure>
      <div class="box media-content is-relative">
        <div class="content">
          <b-dropdown class="listitem-dropdown" :position="'is-top-left'" aria-role="list">
            <button class="button is-primary" slot="trigger">
              <b-icon icon="dots-vertical"></b-icon>
            </button>

            <b-dropdown-item aria-role="listitem" @click="editNote(note)">Edit</b-dropdown-item>
            <b-dropdown-item aria-role="listitem" @click="startDelete(note)">Delete</b-dropdown-item>
          </b-dropdown>
          <p class="subtitle is-7">
            created by <span v-if="note.user">{{ note.user.first_name }} {{ note.user.last_name }}</span><span v-else>deleted user</span> @ {{(new Date(note.created_at).toLocaleString())}}
            <span
              v-if="note.created_at != note.updated_at"
            >edited @ {{(new Date(note.updated_at).toLocaleString())}}</span>
          </p>

          <div v-if="!noteBeingEdited" v-html="note.note"></div>
          <div v-if="noteBeingEdited">
            <ckeditor :editor="editor" v-model="note.note" :config="editorConfig"></ckeditor>
            <b-button @click="saveEdit" type="is-primary">Save</b-button>
          </div>
        </div>
      </div>
    </article>

    <hr />
  </div>
</template>

<script>
import Note from "@/models/RegistrationNote";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import { editorConfig } from "@/EditorConfig";
export default {
  name: "NoteListItem",
  props: {
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
      }),
    },

    note: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        ...editorConfig,
        ...{ placeholder: "Note body" },
      },
      noteBeingEdited: false,
      loadingNote: false,
    };
  },
  computed: {
    type() {
      return { name: "Note", icon: "message-bulleted" };
    },
  },
  methods: {
    saveEdit() {
      this.loadingNote = true;
      Note.update({
        where: this.note.id,
        data: { note: this.note.note },
      });
      Note.updateRemote(this.note).then(() => {
        this.$buefy.snackbar.open({
          message: "Note edited",
          queue: false,
          type: "is-link",
        });
        this.loadingNote = false;
      });
      this.noteBeingEdited = false;
    },
    editNote(note) {
      if (
        this.permissions.can_edit ||
        note.user_id == this.$store.state.userId
      ) {
        return (this.noteBeingEdited = true);
      }
      this.$store.dispatch("toast/createToast");
    },
    deleteNote(note) {
      this.$store.dispatch("loader/show");
      Note.deleteRemote(this.model_type, this.model_id, note.id).then(() => {
        Note.delete(note.id);
        this.$buefy.snackbar.open({
          message: "Note deleted",
          queue: false,
          type: "is-link",
        });
        this.$store.dispatch("loader/hide");
      });
    },
    startDelete(note) {
      if (this.permissions.can_delete) {
        this.$buefy.dialog.confirm({
          title: "Deleting note",
          message:
            "Are you sure you want to <b>delete</b> this note? This action cannot be undone.",
          confirmText: "Delete Note",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.deleteNote(note),
        });
        return;
      }
      this.$store.dispatch("toast/createToast");
    },
  },
};
</script>
