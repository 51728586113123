<template>
  <BoardIndex
    :board_title="title"

    :team_permission="permission"
    :default_year="null"
    :board_id="13"
  />

</template>

<script>
import BoardIndex from "@/components/BoardIndex";

export default {
  name: "RegistrationsBoard",
  components: {
    BoardIndex
  },
  data() {
    return {
      title: 'Registrations',
      permission: 'view registrations board',

    };
  },

};
</script>

