<template>
  <div>
    <card-component :icon="$tc('icons.bulk-send')" title="Bulk Send filters">
      <b-field grouped multiline>
        <b-field label="Type">
          <b-select @input="clearSelects()" placeholder="Select type" v-model="type">
            <option value="registrations">Registration</option>
<!--            <option value="enquiries">Enquiry</option>-->
          </b-select>
        </b-field>
        <b-field label="Stage">
          <b-dropdown v-model="filters.selected_stages" aria-role="list">
            <button class="button is-primary" type="button" slot="trigger">
              {{dynamicStagesButton}} &nbsp;
              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="stage in stages"
              :key="stage.id"
              :value="stage.id"
              aria-role="listitem"
            >
              <span>{{stage.name}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <b-field label="Status">
          <b-dropdown v-model="filters.selected_statuses" aria-role="list">
            <button class="button is-primary" type="button" slot="trigger">
              {{dynamicStatusesButton}} &nbsp;
              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="status in statuses"
              :key="status.id"
              :value="status.id"
              aria-role="listitem"
            >
              <span>{{status.name}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <b-field label="Year of Entry">
          <b-input placeholder="Year" v-model="filters.year" type="number"></b-input>
        </b-field>
        <b-field label="Semester">
          <b-select placeholder="Semester" v-model="filters.semester">
            <option value="1">1</option>
            <option value="2">2</option>
          </b-select>
        </b-field>
        <b-field expanded :label="$tc('Course',1)">
          <b-autocomplete
            expanded
            :loading="loadingCourses"
            @typing="getFilteredCourse"
            :data="courseSearchResponse"
            autocomplete="off"
            v-model="courseSearchTerm"
            :placeholder="$tc('Course',1)"
            @select="option => selectCourse(option)"
            :clear-on-select="false"
            :custom-formatter="thing=> courseName(thing)"
          >
            <template slot-scope="props">
              <p>{{props.option.code}} | {{props.option.name}}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </b-field>
      <b-field grouped>
        <b-field>
          <b-button
            icon-right="cancel"
            @click="clearSelects();filters.year=null;filters.semester=null;getFilteredCourse('');tableData=[];page=1"
          >Clear</b-button>
        </b-field>
        <b-field>
          <b-button
            icon-right="filter"
            :disabled="!Object.values(processed_filters).some(filter=> filter!=null)"
            @click="setPage(1)"
          >Generate List</b-button>
        </b-field>
        <b-field v-if="tableData.length>0">
          <b-button icon-right="pencil" @click="compose">Compose</b-button>
        </b-field>
      </b-field>
    </card-component>
    <card-component class="export-table" v-if="tableData.length>0" title="Mailing list">
      <b-table :data="tableData" :striped="true" :hoverable="true" :bordered="true">
        <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{ props.row.id }}</b-table-column>

        <b-table-column v-slot="props" sortable field="first_name" label="First Name">{{ props.row.first_name }}</b-table-column>

        <b-table-column v-slot="props" sortable field="last_name" label="Last Name">{{ props.row.last_name }}</b-table-column>
        <b-table-column v-slot="props" sortable field="email" label="Email">{{ props.row.email }}</b-table-column>
      </b-table>

      <b-pagination
        class="margin-top"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="page_limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
      ></b-pagination>
    </card-component>
    <b-modal
      :active.sync="isComposeActive"
      has-modal-card
      trap-focus
      :destroy-on-hide="true"
      aria-role="dialog"
      aria-modal
      custom-class="inbox_view"
    >
      <card-component>
        <emailForm
          :inModal="true"
          :username_missing="$store.state.userEmail==null"
          :permissions="{can_create:true, can_send_bulk_email:can_create_bulk_email,can_send_bulk_sms:can_create_bulk_sms}"
          :is_bulk="true"
          :filters="processed_filters"
          :has_attachments="false"
          :bulk_total="meta.total"
          @cancel="isComposeActive = false"
          @sent="clearSelects();filters.year=null;filters.semester=null;getFilteredCourse('');tableData=[];page=1;isComposeActive = false"
          :fetch_on_send="false"
        ></emailForm>
      </card-component>
    </b-modal>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import Course from "@/models/Course";
import Stage from "@/models/Stage";
import RegistrationStage from "@/models/RegistrationStage";
import Learner from "@/models/Learner";
import EmailForm from "@/components/EmailForm";

export default {
  name: "filters",
  components: {
    CardComponent,
    EmailForm,
  },

  data() {
    return {
      isComposeActive: false,
      page: 1,
      page_limit: 15,
      tableData: [],
      meta: {},
      type: "registrations",
      courseSearchResponse: [],
      courseSearchTerm: "",
      loadingCourses: false,
      filters: {
        selected_stages: null,
        selected_statuses: null,
        course_id: null,
        year: null,
        semester: null,
      },
      enquiry_statuses: [
        { id: 1, name: "None" },
        { id: 2, name: "Won" },
        { id: 3, name: "Lost" },
      ],
      registration_statuses: this.$store.state.statuses.statuses,
    };
  },
  computed: {
    can_create_bulk_email() {
      return this.$store.getters["entities/permissions/find"](
        "create bulk-email"
      );
    },
    can_create_bulk_sms() {
      return this.$store.getters["entities/permissions/find"](
        "create bulk-sms"
      );
    },
    processed_filters() {
      return this.type == "registrations"
        ? {
            registration_status: this.filters.selected_statuses,
            registration_stage: this.filters.selected_stages,
            registration_course: this.filters.course_id,
            registration_year: this.filters.year,
            registration_semester: this.filters.semester,
          }
        : {
            enquiry_made_status: this.filters.selected_statuses,
            enquiry_made_stage: this.filters.selected_stages,
            enquiry_made_course: this.filters.course_id,
            enquiry_made_year: this.filters.year,
            enquiry_made_semester: this.filters.semester,
          };
    },
    dynamicStatusesButton() {
      if (this.filters.selected_statuses == null) {
        return "Status";
      }
      if (this.type == "registrations") {
        return this.registration_statuses.filter(
          (status) => status.id == this.filters.selected_statuses
        )[0].name;
      }
      if (this.type == "enquiries") {
        return this.enquiry_statuses.filter(
          (status) => status.id == this.filters.selected_statuses
        )[0].name;
      }
      return false;
    },
    dynamicStagesButton() {
      if (this.filters.selected_stages == null) {
        return "Stage";
      }
      if (this.type == "registrations") {
        return RegistrationStage.query()
          .where("id", this.filters.selected_stages)
          .get()[0].name;
      }
      if (this.type == "enquiries") {
        return Stage.query().where("id", this.filters.selected_stages).get()[0]
          .name;
      }
      return false;
    },
    statuses() {
      if (this.type == "registrations") {
        return this.registration_statuses;
      }
      return this.enquiry_statuses;
    },
    stages() {
      if (this.type == "registrations") {
        return RegistrationStage.query().orderBy("order").get();
      }
      return Stage.query().orderBy("order").get();
    },
  },

  methods: {
    compose() {
      this.isComposeActive = true;
    },
    setPage(new_page) {
      this.page = new_page;
      this.getBulkList();
    },
    getBulkList() {
      this.$store.dispatch("loader/show");
      Learner.fetchAllForBulk(
        this.page,
        this.page_limit,
        this.processed_filters
      )
        .then((result) => {
          if (result.response.data.data.length > 0) {
            this.tableData = result.entities.learners;
            this.meta = result.response.data.meta;
          } else {
            this.tableData = [];
            this.meta = {};
          }

          this.$store.dispatch("loader/hide");
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.$store.dispatch("loader/hide");
        });
    },
    courseName(course) {
      return `${course.code} | ${course.name}`;
    },
    selectCourse(option) {
      if (option) {
        this.filters.course_id = option.id;
      }
    },
    getFilteredCourse(text) {
      if (text.length == 0) {
        this.courseSearchResponse = [];
        this.filters.course_id = null;
        this.courseSearchTerm = "";
        return;
      }
      this.loadingCourses = true;

      Course.search(text, true).then((result) => {
        this.loadingCourses = false;

        if (result.entities) {
          this.courseSearchResponse = result.entities.courses;
        } else {
          this.courseSearchResponse = [];
        }
      });
    },
    clearSelects() {
      this.filters.selected_stages = null;
      this.filters.selected_statuses = null;
    },
  },
  mounted() {
    Stage.fetchAll();
    RegistrationStage.fetchAll();
  },
};
</script>


<style lang="scss">
</style>
