<template>
  <Export

    :date_filter="false"
    :entities="entities"
    :title="'Module success rate'"
    :module_filter="false"
    :inline_modules_filter="false"
    :semesters="semesters"
    :year_filter="true"
  ></Export>
</template>


<script>
import Stage from "@/models/RegistrationStage";
import Export from "@/components/Export";
import User from "@/models/User";
import Semester from "@/models/Semester";

export default {
  name: "ModulesSuccessRate",
  components: {
    Export,
  },
  data() {
    return {
      entities: "modules-success-rate",
      admissions_team: [],

    };
  },
  computed: {
    stages() {
      return Stage.all();
    },
    semesters() {
      return Semester.all();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");

    User.deleteAll();
    Stage.fetchAll();
    Semester.FetchAll();

    User.fetchAll("edit registrations").then((result) => {
      this.admissions_team = result.entities.users;
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
