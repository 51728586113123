<template>
  <b-select
    :disabled="disabled"
    :required="required"
    :value="value"
    placeholder="Select a source"
    @input="onInput"
    expanded>
    <option v-for="option in data" :key="option" :value="option">
      {{option}}
    </option>
  </b-select>
</template>

<script>
export default {
  name: "SelectMarketingWhereHear",

  props: {
    disabled: {
      type: Boolean,
      default(){
        return false;
      },
    },
    required: {
      type: Boolean,
      default(){
        return false;
      },
    },
    value: {
      type: String,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    data() {
      return this.$store.state.profile.where_hear_options;
    }
  },

}
</script>
