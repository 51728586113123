import moment from 'moment'
export const DateSelectModule = {
  namespaced: true,

  state: {
    dateMin: null,
    dateMax: null,
    semester_id: null,
    year: null
  },
  getters: {
    formattedDateMin: state => {
      if (moment(state.dateMin).isValid()) {
        return moment(state.dateMin).format('YYYY-MM-DD')
      }
      return null
    },
    formattedDateMax: state => {
      if (moment(state.dateMax).isValid()) {
        return moment(state.dateMax).format('YYYY-MM-DD')
      }
      return null
    },
  }
};
