<template>

  <div class="has-background-white box is-relative card">
    <title-bar class="card-header"><p class="card-header-title">{{ is_success ? success_title : title }}</p></title-bar>

    <div class="card-content">
      <div v-if="!is_success">
        <div v-html="copy"></div>

        <div v-if="!method_confirmed">
          <b-field>
            <ol class="ml-5">
              <li>The R5000 initial payment is non-refundable</li>
              <li>The Initial Payment of R5000 is applied to the administration fee and the balance to the tuition fee
              </li>
              <li>All administration fees due at registration will not be refunded</li>
              <li>A student is able to make changes to or cancel their module selection within the first 10 days at the start of the
                semester. Thereafter, the student will be liable for the payment of the semester fees
              </li>
            </ol>
          </b-field>
          <b-field>
            <b-checkbox class="has-text-weight-bold" v-model="readPaymentDeclaration" required>
              I understand and accept

            </b-checkbox>
          </b-field>

          <b-field label="Payment Method">
            <b-field>
              <b-radio v-model="payment_method" :native-value="0"
                       name="payment_method">
                Pay via Credit or Debit card
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="payment_method" :native-value="1"
                       name="payment_method"
              >
                Pay via EFT
              </b-radio>
            </b-field>
            <b-field>
              <b-radio v-model="payment_method" :native-value="2"
                       name="payment_method"
              >
                Pay via Mobicred
              </b-radio>
            </b-field>
          </b-field>
          <b-field>
            <b-button :disabled="payment_method===null || readPaymentDeclaration!==true" type="is-primary" @click="selectPaymentMethod">
              Choose payment method
            </b-button>
          </b-field>
        </div>
        <div v-if="!method_confirmed">
          <div v-if="payment_type == 2" class="pt-4">
            <p class="is-size 5">
              <span>If you do not have a Mobicred account, sign up <a href="https://live.mobicred.co.za/web_mcrliv/run.w?run=application&merchantId=8406&returnUrl=https://cornerstone.ac.za/">here. </a>
                Please note, the Mobicred account approval process could delay the completion of your registration.
                Change your payment method if you would like to prevent delays on the completion of your registration.</span>
            </p>
          </div>
        </div>
        <div v-if="method_confirmed">
          <div v-if="checkoutId!==null && invoice.paying_via_eft===0">
            <p class="is-size-5 pb-4 has-text-weight-bold">Enter your debit or credit card details below</p>
            <script :src="`${PEACH_URL}v1/paymentWidgets.js?checkoutId=${checkoutId}`" type="application/javascript"></script>

            <form ref="paymentForm" :action="currentPage" class="paymentWidgets" data-brands="VISA MASTER AMEX"></form>
          </div>
          <div v-if="invoice.paying_via_eft===1" class="pt-4">
            <p class="is-size-5 pb-2 has-text-weight-bold">EFT Details:</p>
            <div v-html="eft_details"></div>
            <p class="is-size-5">
              <span class="is-italic">Reference</span>: Student Number ({{ this.learner.student_number }})
            </p>
          </div>
          <b-field>
            <b-button type="is-primary" @click="method_confirmed=false;checkoutId=null">Choose a different method</b-button>
          </b-field>
        </div>

      </div>
      <div v-else>
        <p class="is-size-5 ">{{ success_copy }}</p>

      </div>

    </div>
    <footer class="card-footer">
      <div class="card-footer-item">

        <b-field v-if="is_success && show_next" grouped>
          <p class="control">
            <b-button :loading="loading" native-type="submit" type="is-primary"
                      @click.prevent="$emit('success')">Next
            </b-button>
          </p>
        </b-field>

      </div>
    </footer>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>

  </div>

</template>

<script>
import Registration from "@/models/Registration";
import Learner from "@/models/Learner";
import LearnerRegistrationFlow from "@/mixins/LearnerRegistrationFlow";
import {mapState} from "vuex";
import Invoice from "@/models/Invoice";
import TitleBar from "@/components/TitleBar";
// import LearnerRegistrationListItem from "@/components/registrations/RegistrationListItem"
import {PEACH_URL} from "@/config";

export default {
  name: "RegistrationPaymentPanel",
  components: {
    TitleBar
    // LearnerRegistrationListItem
  },
  mixins: [LearnerRegistrationFlow],
  data() {
    return {
      checkoutId: null,
      loading: false,
      payment_tripped: false,
      method_confirmed: false,
      payment_fetch: null,
      PEACH_URL: PEACH_URL,
      readPaymentDeclaration: false,
      payment_type: null,
    };
  },
  props: {
    invoice_type: {
      type: Number,
      required: true,
      paying_via_peach: false
    },
    show_next: {
      type: Boolean,
      default() {
        return true
      }
    },
    title: {
      type: String,
      default() {
        return 'Application Fee Title'
      }
    }, copy: {
      type: String,
      default() {
        return 'Application Fee copy'
      }
    },
    success_title: {
      type: String,
      default() {
        return 'Application Fee Success Title'
      }
    },
    success_copy: {
      type: String,
      default() {
        return 'Application Fee success copy'
      }
    }, eft_details: {
      type: String,
      default() {
        return 'EFT Details'
      }
    },
  },
  computed: {
    currentPage() {
      if (this.invoice_type === 2) {
        return window.location.href + '?success=1'
      }
      return window.location.href
    },
    ...mapState('applications', ['activeRegistrationId']),
    payment_method: {
      get() {
        if (this.invoice !== null) {
          if (this.invoice.paying_via_eft === 1) {
            return 1
          }
          if (!this.payment_tripped) {
            return null
          }
          return this.payment_type
        } else {
          return null
        }
      }, set(value) {
        if (value > 1) {
          this.invoice.paying_via_eft = 0;
        } else {
          this.invoice.paying_via_eft = value
        }
        this.payment_type = value;
        this.payment_tripped = true
      }
    },
    activeRegistration() {
      return Registration.query().whereId(this.activeRegistrationId).withAllRecursive().first()
    },
    invoice() {
      return Invoice.query().withAll().first()
    },
    learner() {
      return Learner.query().whereId(this.$store.state.userId).withAll().first() ?? Object
    },
    is_success() {
      return Invoice.query().where('is_paid', 1).exists()
    },
  },
  methods: {
    async selectPaymentMethod() {
      this.loading = true
      if (this.invoice.paying_via_eft === 1) {
        if (!this.is_success) {
          Invoice.Update(this.invoice).then(() => {
            this.method_confirmed = true
            this.loading = false
          })
        }
      }
      if (this.invoice.paying_via_eft !== 1) {
        if (!this.is_success) {
          await Invoice.Update(this.invoice)
          await this.generateCheckout().then(() => {
            this.method_confirmed = true
          }).catch(err => {
            this.handleError(err)
            //TODO handle already paid
          })
          this.loading = false

        }
      }
    },
    async generateCheckout() {
      switch (this.payment_type) {
        case 0: {
          return Invoice.GenerateCheckout(this.invoice.id, 1).then((response) => {
            this.checkoutId = response.response.data.checkout_id
          })
        }
        case 2: {
          this.loading = true;
          Invoice.GenerateExternalCheckout(this.invoice.id, 1).then((response) => {
            window.location.href = response.response.data.redirectUrl;
          });
        }
      }
    }
  },
  async created() {
    await Invoice.deleteAll()
    this.loading = true
    Learner.fetchById(this.$store.state.userId)
    await (this.invoice_type === 1 ? Invoice.GenerateApplicationInvoice(this.activeRegistrationId) : Invoice.GenerateInitialFeeInvoice(this.activeRegistrationId)).then(() => {
      this.loading = false
    }).catch(err => {
      if (err.response.status === 422) {
        if (Object.prototype.hasOwnProperty.call(err.response.data.errors, 'invoice')) {

          Invoice.insert({
            data: err.response.data.errors.invoice.map(invoice => {
              invoice.attributes.invoice_items.map(item => {
                Object.assign(item, item.attributes)
              })
              return (Object.assign(invoice, invoice.attributes))
            })
          })
        }
        return
      }
      this.handleError(err)

    })
    if (this.invoice.paying_via_eft === 1) {
      this.method_confirmed = true
      this.payment_method = 1;
    }
    if (this.invoice.is_paid === 0) {
      this.payment_fetch = setInterval(() => {
        (this.invoice_type === 1 ? Invoice.GenerateApplicationInvoice(this.activeRegistrationId) : Invoice.GenerateInitialFeeInvoice(this.activeRegistrationId)).catch((err) => {
          if (err.response.status === 422) {
            if (Object.prototype.hasOwnProperty.call(err.response.data.errors, 'invoice')) {
              if (err.response.data.errors.invoice[0].attributes.is_paid === 1) {
                Invoice.insert({
                  data: err.response.data.errors.invoice.map(invoice => {
                    invoice.attributes.invoice_items.map(item => {
                      Object.assign(item, item.attributes)
                    })
                    return (Object.assign(invoice, invoice.attributes))
                  })
                })
              }
            }

          }
        })
      }, 10000)
    }
    this.loading = false

  },
  beforeDestroy() {
    clearInterval(this.payment_fetch)
  }
};
</script>
