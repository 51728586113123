<template>
  <div class="container">
    <div class="card">

      <title-bar class="card-header mb-3"><p class="card-header-title">My Registrations - {{ learner.student_number }}</p></title-bar>

      <section class="card-content">
<!--        <p class="is-size-5">This is how you start a new Application. You can only have one application in progress at a time. If you already have an application in progress you will be redirected to it.</p>-->
<!--        <b-field class="py-3">-->
<!--          <b-button type="is-success" @click="startNewRegistration">New Application</b-button>-->
<!--        </b-field>-->
        <p class="is-size-5 pb-2">This is where you’ll find the history of your registrations at Cornerstone. You can see the status the registration is in.</p>
      </section>
    </div>

    <LearnerRegistrationListItem :user_id="$store.state.userId" v-for="registration in registrations" :key="registration.id" :registration="registration"/>

  </div>

</template>

<script>
import Registration from "@/models/Registration";
import TitleBar from "@/components/TitleBar";
import Learner from "@/models/Learner";
import LearnerRegistrationFlow from "@/mixins/LearnerRegistrationFlow";
import LearnerRegistrationListItem from "@/components/Registrations/RegistrationListItem"

export default {
  name: "Registrations",
  components: {
    TitleBar,
    LearnerRegistrationListItem
  },
  mixins: [LearnerRegistrationFlow],
  data() {
    return {
      registration_ids: [],
      meta: Object,
      page: 1,
      dropFile: null,
      updatingMedia: false,
      apply_for_rpl: null,
    };
  },
  computed: {
    registrations() {
      return Registration.query()
        .where('user_id', (value) => {
          return parseInt(value) === parseInt(this.$store.state.userId)
        })
        .with("course")
        .with("stage")
        .with("modules").orderBy('created_at', 'desc')
        .get();
    },
    learner() {
      return Learner.query().whereId(this.$store.state.userId).first() ?? Object
    }
  },
  methods: {
    startNewRegistration() {
      this.$store.state.applications.hasRegistrationInProgress = true
      this.$router.push({name: 'registrations.register'})
    },
    registrationProgress(status_id) {
      return this.$store.state.applications.applicationProgress.find(group => {
        return group.ids.includes(parseInt(status_id))
      })
    },

    statusName(status) {
      if (status) {
        return this.$store.state.statuses.statuses.find(statusItem => statusItem.id === parseInt(status)).name
      }
      return null
    },

    courseName(registration) {
      return registration.course != null ? registration.course.name : "None";
    },

  },
  async created() {
    this.$store.dispatch("loader/showOpaque");
    if (Object.prototype.hasOwnProperty.call(this.$route.query,'success')) {
      this.$buefy.dialog.confirm({
        type:'is-success',
        title:'Registration Complete',
        message:`<p class="pb-2">Congratulations, your registration is now complete.</p> <p class="pb-2">You will receive an email with your credentials and student information. You can now close this window.</p><p>Welcome to the Cornerstone Family!</p>`,
      })
    }
    Learner.fetchById(parseInt(this.$store.state.userId))
    await this.checkRegistrationFlow(this.$route.name).catch(err => this.$router.push(err))
    await Registration.fetchByUser(
      1,
      999,
      parseInt(this.$store.state.userId),
      [
        'user',
        'course',
        'modules',
      ]
    )
    this.$store.dispatch('loader/hideOpaque')
  },
};
</script>
