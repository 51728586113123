<template>
  <section class="section">
    <title-bar>{{ board_title }}</title-bar>
    <div class="columns">
      <div class="column is-10">
        <!-- <h3 class="has-text-weight-bold">Filter</h3> -->
        <b-field group-multiline grouped>
          <b-field>
            <b-radio-button v-model="filter" native-value="course">
              <b-icon :icon="$tc('icons.courses')"></b-icon>
              <span>{{ $tc('Course', 1) }}</span>
            </b-radio-button>

            <b-radio-button v-model="filter" native-value="learner">
              <b-icon :icon="$tc('icons.learners')"></b-icon>
              <span>{{ $tc('Learner', 1) }}</span>
            </b-radio-button>

            <b-radio-button v-if="administrate_registrations" v-model="filter" native-value="owner">
              <b-icon :icon="$tc('icons.users')"></b-icon>
              <span>Owner</span>
            </b-radio-button>
          </b-field>
          <b-field v-if="administrate_registrations">
            <b-button @click="setTasksFilter(1)">Incomplete Activities</b-button>
            <b-button @click="setNoOwner">No Owner</b-button>
            <b-button @click="setNoCourse">No Course</b-button>
          </b-field>
          <b-radio-button v-model="rplCatFilter" :native-value="1">
            <span>RPL/CAT requested</span>
          </b-radio-button>
          <b-field>
            <b-select v-model="yearFilter" placeholder="Year">
              <option
                v-for="number in 7"
                :key="number + 'year'"
                :value="(new Date().getFullYear()-3)+number"
              >{{ (new Date().getFullYear() - 3) + number }}
              </option>
            </b-select>

            <b-select v-model="semesterFilter" placeholder="Semester">
              <option v-for="semester in semesters" :key="semester.id" :value="semester.id">{{ semester.name }}</option>
            </b-select>
          </b-field>
          <b-select v-model="statusFilter" placeholder="Registration Status">
            <optgroup v-for="(group,index) in $store.state.statuses.grouped_statuses" :key="index"
                      :label="group.name">

              <option v-for="status in group.statuses" :key="status.id" :value="status.id">{{ status.name }}
              </option>
            </optgroup>
          </b-select>

          <b-field>
            <b-taglist>
              <b-tag
                v-if="courseSearchId"
                closable
                type="is-info"
                @close="courseSearchId = null"
              >{{ $tc('Course', 1) }}
              </b-tag>
              <b-tag
                v-if="tasksFilter"
                closable
                type="is-info"
                @close="setTasksFilter(0)"
              >Incomplete Activities
              </b-tag>
              <b-tag
                v-if="learnerSearchId"
                closable
                type="is-info"
                @close="learnerSearchId = null"
              >{{ $tc('Learner', 1) }}
              </b-tag>
              <b-tag
                v-if="ownerSearchId"
                closable
                type="is-info"
                @close="ownerSearchId = null"
              >Owner
              </b-tag>
              <b-tag v-if="noOwner" closable type="is-info" @close="noOwner = false">No Owner</b-tag>
              <b-tag v-if="noCourse" closable type="is-info" @close="noCourse = false">No Course</b-tag>
              <b-tag v-if="statusFilter" closable type="is-info" @close="statusFilter = null">Status</b-tag>
              <b-tag v-if="yearFilter" closable type="is-info" @close="yearFilter = null">Year</b-tag>
              <b-tag
                v-if="semesterFilter"
                closable
                type="is-info"
                @close="semesterFilter = null"
              >Semester
              </b-tag>
              <b-tag
                v-if="rplCatFilter"
                closable
                type="is-info"
                @close="rplCatFilter = null"
              >rpl/cat
              </b-tag>
            </b-taglist>
          </b-field>
        </b-field>
        <b-field v-if="filter === 'learner'">
          <b-autocomplete
            v-model="learnerSearchTerm"
            :clear-on-select="true"
            :data="learnerSearchResponse"
            :icon="$tc('icons.learners')"
            :placeholder="$tc('Learner',1)"
            autocomplete="off"
            @select="option => selectLearner(option)"
            @typing="getFilteredLearner"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field v-if="filter === 'course'">
          <b-autocomplete
            v-model="courseSearchTerm"
            :clear-on-select="true"
            :data="courseSearchResponse"
            :icon="$tc('icons.courses')"
            :placeholder="$tc('Course',1)"
            autocomplete="off"
            @select="option => selectCourse(option)"
            @typing="getFilteredCourse"
          >
            <template slot-scope="props">
              <p>{{ props.option.code }} | {{ props.option.name }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <b-field v-if="filter === 'owner'">
          <b-autocomplete
            v-model="ownerSearchTerm"
            :clear-on-select="true"
            :data="ownerSearchResponse"
            :icon="$tc('icons.users')"
            autocomplete="off"
            placeholder="Owner"
            @select="option => selectOwner(option)"
            @typing="getFilteredOwner"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </div>
      <div class="column is-2">
        <b-switch v-model="isTable" :false-value="false" :true-value="true" class="mb-5">Table View</b-switch>
        <b-select v-if="!isTable" v-model="sortOrder" placeholder="Sort Order" @input="getRegistrations(page, page_limit, filters, !isTable)">
          <option value="desc">Descending</option>
          <option value="asc">Ascending</option>
        </b-select>
      </div>
    </div>
    <div v-if="isTable && isLoaded">
      <b-field  group-multiline grouped>

        <b-checkbox-button v-model="bulkChange"
                           :native-value="true"
                           type="is-primary">
          <span>Bulk Change Registrations</span>
        </b-checkbox-button>
        <b-select v-if="bulkChange" v-model="bulkChangeStatus" placeholder="Registration Status">
          <option :value="null">Unchanged</option>
          <optgroup v-for="(group,index) in $store.state.statuses.grouped_statuses" :key="index"
                    :label="group.name">

            <option v-for="status in group.statuses" :key="status.id" :value="status.id">{{ status.name }}
            </option>
          </optgroup>
        </b-select>
        <b-field v-if="bulkChange">
          <b-select
            placeholder="Registration Stage" v-model="bulkChangeStage"
          >
            <option :value="null">Unchanged</option>
            <optgroup v-for="board in minItemBoards" :key="board.name" :label="board.name">
              <option :key="stage.name" v-for="stage in orderedStages(board.id)" :value="stage.id">
                {{ stage.name }}
              </option>
            </optgroup>
          </b-select>
        </b-field>
        <b-select
          v-if="bulkChange" v-model="bulkChangeOwnerId" placeholder="Owner">
          <option :value="null">Unchanged</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{
              user.first_name
            }} {{ user.last_name }} | {{ user.email }}
          </option>
        </b-select>
        <b-button v-if="bulkChange" :disabled="bulkChangeRegistrations.length<1" type="is-primary" @click="startBulkChange">
          Save
        </b-button>
      </b-field>
    </div>
    <b-table
      v-if="isTable && isLoaded"
      ref="table"
      :bordered="true"
      :checkable="bulkChange"
      :checked-rows.sync="bulkChangeRegistrations"
      :data="tableHold"
      :hoverable="true"
      :striped="true"
      backend-sorting
      class="margin-top"
      narrowed
      @sort="onSort"
    >
      <b-table-column v-slot="props" field="user.first_name" label="First name">{{ props.row.user.first_name }}</b-table-column>
      <b-table-column v-slot="props" field="user.last_name" label="Last name">{{ props.row.user.last_name }}</b-table-column>
      <b-table-column v-slot="props"
                      field="owner.first_name"
                      label="Owner"
      >{{ props.row.owner != null ? props.row.owner.first_name : '' }}
        {{ props.row.owner != null ? props.row.owner.last_name : '' }}
      </b-table-column>
      <b-table-column v-slot="props"
                      field="creator.first_name"
                      label="Creator"
      >{{ props.row.creator != null ? props.row.creator.first_name : '' }}
        {{ props.row.creator != null ? props.row.creator.last_name : '' }}
      </b-table-column>
      <b-table-column v-slot="props" field="registration_status_id" label="Status">
        {{ props.row.registration_status_id ? processStatusName(props.row.registration_status_id) : 'None' }}
      </b-table-column>
      <b-table-column v-slot="props" field="stage_id" label="Stage">{{ props.row.stage.name }}</b-table-column>
      <b-table-column v-slot="props"
                      :label="$tc('Course',1)"
                      field="course.name"
      >{{ props.row.course != null ? props.row.course.name : '' }}
      </b-table-column>
      <b-table-column v-slot="props" field="age_human_readable" label="Age">{{ props.row.age_human_readable }}</b-table-column>
      <b-table-column v-slot="props"
                      field="created_at"
                      label="Created at"
                      sortable
      >{{ props.row.created_at != null ? (new Date(props.row.created_at).toLocaleString()) : '' }}
      </b-table-column>
      <b-table-column v-slot="props"
                      field="updated_at"
                      label="Updated at"
                      sortable
      >{{ props.row.updated_at != null ? (new Date(props.row.updated_at).toLocaleString()) : '' }}
      </b-table-column>
      <b-table-column v-slot="props" custom-key="actions" label="View">
        <b-button :to="`/registrations/edit/${props.row.id}?index_stage=${props.row.stage_id}`" tag="router-link"
                  type="is-primary" @click="goToRegistration(props.row.id)">View
        </b-button>
      </b-table-column>
    </b-table>
    <b-pagination
      v-if="isTable && isLoaded"
      :current="page"
      :per-page="page_limit"
      :range-after="2"
      :range-before="2"
      :total="meta.total"
      aria-current-label="Current page"
      aria-next-label="Next page"
      aria-page-label="Page"
      aria-previous-label="Previous page"
      class="margin-top"
      v-on:change="setPage"
    ></b-pagination>
    <div class="bar-container stage-columns">
      <div v-if="value && !isTable" class="columns flippy">
        <div v-for="stage in stages" :key="stage.id" class="column stage-column">
          <h3 class="has-text-centered">{{ stage.name }} <b-tag type="is-primary is-rounded is-bold">{{stageCounts[stage.id] ?? 0}}</b-tag></h3>
          <draggable
            v-model="stageSortedRegistrations[stage.id-1]"
            :class="{'column-target':dragging && draggingStage !== stage.id}"
            :group="{name:stage.name, pull:true,put:true}"
            class="column-view"
            @change="onChange($event, stage.id)"
            @end="endDrag($event,stage.id)"
            @start="startDrag($event,stage.id)"
          >
            <router-link
              v-for="registration in stageSortedRegistrations[stage.id-1]"
              :id="registration.id"
              :key="registration.id"
              :class="{'registration-dragged':dragging&& draggingRegistration == registration.id, 'has-background-success':registration.status_id == 2,'has-background-danger':registration.status_id == 3}"
              :to="`/registrations/edit/${registration.id}?index_stage=${stage.id}`"
              class="box has-pointer"
            >
              {{ registration.user.first_name }} {{ registration.user.last_name }}
              <br/>
              <span v-if="registration.course">{{ registration.course.code }}</span>
              <br/>
              <b-icon
                :type="{'is-danger':registrationOwner(registration.owner_id) == 'None'}"
                icon="account"
                size="is-small"
              ></b-icon>
              {{ registrationOwner(registration.owner_id) }}
              <br/>
              <p v-if="registration.total_tasks_count>0">
                <b-icon icon="checkbox-marked" size="is-small"></b-icon>

                <span
                  :class="{'has-text-success':registration.completed_tasks_count >= registration.total_tasks_count,'has-text-warning':registration.completed_tasks_count<registration.total_tasks_count}"
                  class="tasks-indicator"
                >{{ registration.completed_tasks_count }}/{{ registration.total_tasks_count }}</span>
              </p>
              <p>
                <b-icon icon="clock" size="is-small"></b-icon>
                {{ registration.age_human_readable }}
              </p>
            </router-link>
            <hr/>
          </draggable>
        </div>
      </div>
    </div>

    <hr/>
    <infinite-loading
      v-if="value && !isTable"
      :identifier="infiniteId"
      checkboxGroup
      spinner="waveDots"
      @infinite="infiniteHandler"
    ></infinite-loading>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import RegistrationStage from "@/models/RegistrationStage";
import User from "@/models/User";
import Learner from "@/models/Learner";
import Course from "@/models/Course";
import Registration from "@/models/Registration";
import InfiniteLoading from "vue-infinite-loading";
import draggable from "vuedraggable";
import VueCookie from "vue-cookie";
import RegistrationBoard from "@/models/RegistrationBoard";
import Semester from "@/models/Semester";

export default {
  name: "Registrations",
  components: {
    TitleBar,
    InfiniteLoading,
    draggable,
  },
  props: {
    board_id: {
      type: Number, required: true
    },
    team_permission: {
      type: String,
      default() {
        return 'edit registrations'
      }
    },
    board_title: {
      type: String, required: true
    },

    default_year: {
      Number,
      default() {
        return new Date().getFullYear() + 1
      }
    }
  },
  data() {
    return {
      bulkChangeRegistrations: [],
      bulkChange: false,
      bulkChangeStatus: null,
      bulkChangeStage: null,
      bulkChangeOwnerId: null,
      tasksFilter: 0,
      isLoaded: false,
      tableHold: [],
      infiniteId: new Date(),
      isTable: false,
      draggingStage: null,
      draggingRegistration: null,
      dragging: false,
      showWonLost: false,
      filter: "",
      statusFilter: null,
      yearFilter: this.default_year,
      semesterFilter: null,
      rplCatFilter: 0,
      value: false,
      noOwner: false,
      noCourse: false,
      learnerSearchTerm: "",
      ownerSearchTermForBulk: "",
      ownerSearchTerm: "",
      courseSearchTerm: "",
      learnerSearchId: null,
      courseSearchId: null,
      ownerSearchId: null,
      learnerSearchResponse: [],
      courseSearchResponse: [],
      ownerSearchResponse: [],
      ownerSearchResponseForBulk: [],
      stageSortedRegistrations: [],
      stageCounts: {},
      order_by: null,
      order_direction: null,
      card_page: 1,
      card_page_limit: 10,
      page: 1,
      page_limit: 50,
      meta: Object,
    };
  },

  mounted() {
    User.deleteAll();
    this.$store.dispatch("loader/show");
    RegistrationStage.deleteAll();

    RegistrationStage.fetchAll();
    Semester.FetchAll({page: 1, limit: 999, orderBy: 'id', orderDir: 'desc'})
    let registrations_save = JSON.parse(VueCookie.get("registrations_save"));

    if (registrations_save != null) {
      this.page = registrations_save != null ? registrations_save.page : 1;
      this.tasksFilter = registrations_save != null ? registrations_save.tasksFilter : 1;
      if (registrations_save != null) {
        this.learnerSearchId =
          registrations_save.filters.hasOwnProperty("user_id") != null
            ? registrations_save.filters.user_id
            : null;
        this.courseSearchId =
          registrations_save.filters.hasOwnProperty("course_id") != null
            ? registrations_save.filters.course_id
            : null;
        this.ownerSearchId =
          registrations_save.filters.hasOwnProperty("owner_id") != null
            ? registrations_save.filters.owner_id
            : null;
        this.statusFilter =
          registrations_save.filters.hasOwnProperty("registration_status_id") !=
          null
            ? registrations_save.filters.registration_status_id
            : null;
        this.yearFilter =
          registrations_save.filters.hasOwnProperty("year") != null
            ? registrations_save.filters.year
            : null;
        this.semesterFilter =
          registrations_save.filters.hasOwnProperty("semester") != null
            ? registrations_save.filters.semester
            : null;
        this.rplCatFilter =
          registrations_save.filters.hasOwnProperty("rpl_cat_requested") != null
            ? registrations_save.filters.rpl_cat_requested
            : null;
        if (registrations_save.filters.hasOwnProperty("owner_id")) {
          if (registrations_save.filters.owner_id == null) {
            this.noOwner = true;
          }
        }
        if (registrations_save.filters.hasOwnProperty("course_id")) {
          if (registrations_save.filters.course_id == null) {
            this.noCourse = true;
          }
        }
      }
      this.isTable = registrations_save.isTable;
      this.$cookie.delete("registrations_save");
    } else {
      this.getRegistrations(
        this.card_page,
        this.card_page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        this.tableHold = this.registrations;
        this.$store.dispatch("loader/hide");
      });
    }
    User.fetchAll(this.team_permission);
  },
  watch: {
    isTable() {
      this.$store.dispatch("loader/show");

      Registration.deleteAll();
      this.getRegistrations(
        this.page,
        this.page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        this.isLoaded = true;
        this.tableHold = this.registrations;
        this.$store.dispatch("loader/hide");
      });
    },
    filters() {
      this.updateFilters()
    },
  },
  methods: {
    orderedStages(board_id) {
      return RegistrationStage.query().where('board_id', board_id).orderBy('order').get()
    },
    processStatusName(id) {
      return this.$store.state.statuses.statuses.find(status => status.id === parseInt(id)).name
    },
    performChange() {
      this.$store.dispatch('loader/show')
      let registration_ids = []
      this.bulkChangeRegistrations.map(registration => {
        registration_ids.push(registration.id)
      })
      Registration.bulkChange({...{registration_ids: registration_ids}, ...(this.bulkChangeStage ? {stage_id: this.bulkChangeStage} : {}), ...(this.bulkChangeStatus ? {registration_status_id: this.bulkChangeStatus} : {}), ...(this.bulkChangeOwnerId ? {owner_id: this.bulkChangeOwnerId} : {})}).then(response => {
        Registration.deleteAll();
        this.getRegistrations(
          this.page,
          this.page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          this.bulkChangeRegistrations = []
          this.bulkChangeStatus = null
          this.bulkChangeStage = null
          this.bulkChangeOwnerId = null
          this.isLoaded = true;
          this.tableHold = this.registrations;
          this.$buefy.snackbar.open({
            message: response.response.data.message,
            queue: false,
            type: "is-primary"
          });
          this.$store.dispatch("loader/hide");
        });


      }).catch((err) => {
        this.$store.dispatch('loader/hide')

        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      })
    },
    startBulkChange() {
      this.$buefy.dialog.confirm({
        title: "Bulk Change",
        message:
          "Are you sure you want to edit these registrations?",
        confirmText: "Perform bulk change",
        type: "is-primary",
        hasIcon: true,
        onConfirm: () => this.performChange(),
      });
    },
    updateFilters() {
      if (this.isTable && this.isLoaded) {
        this.$store.dispatch("loader/show");
        this.page = 1;

        Registration.deleteAll();
        this.getRegistrations(
          this.page,
          this.page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          VueCookie.set(
            "registrations_save",
            JSON.stringify({
              filters: this.filters,
              page: this.page,
              isTable: this.isTable,
              route: this.$router.currentRoute.path,
              tasksFilter: this.tasksFilter
            })
          );
          this.tableHold = this.registrations;

          this.$store.dispatch("loader/hide");
        });
      }
      if (!this.isTable) {
        this.card_page = 1;
        Registration.deleteAll();

        this.infiniteId++;
        this.$store.dispatch("loader/show");
        this.getRegistrations(
          this.card_page,
          this.card_page_limit,
          this.filters,
          !this.isTable
        ).then(() => {
          VueCookie.set(
            "registrations_save",
            JSON.stringify({
              filters: this.filters,
              page: this.page,
              isTable: this.isTable,
              route: this.$router.currentRoute.path,
              tasksFilter: this.tasksFilter
            })
          );
          this.$store.dispatch("loader/hide");
          this.$forceUpdate();
        });
      }
    },
    onSort(column, sort) {
      this.order_by = column;
      this.order_direction = sort;
      this.setPage(this.page);
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      Registration.deleteAll();
      this.page = pageNumber;
      this.getRegistrations(
        this.page,
        this.page_limit,
        this.filters,
        !this.isTable
      ).then(() => {
        VueCookie.set(
          "registrations_save",
          JSON.stringify({
            filters: this.filters,
            page: this.page,
            isTable: this.isTable,
            route: this.$router.currentRoute.path,
            tasksFilter: this.tasksFilter
          })
        );
        this.tableHold = this.registrations;
        this.$store.dispatch("loader/hide");
      });
    },

    startDrag(evt, stageId) {
      this.dragging = true;
      this.draggingStage = stageId;
      /**
       * @param evt          The drag event.
       * @param evt.originalEvent   Contains the element of the dragged stage
       */
      this.draggingRegistration = evt.originalEvent.target.id;
    },
    endDrag() {
      this.dragging = false;
      this.draggingStage = null;
      this.draggingRegistration = null;
    },
    setTasksFilter(bool) {
      this.tasksFilter = bool;
      this.updateFilters()
    }, setNoOwner() {
      this.ownerSearchId = null;
      this.noOwner = true;
    },
    setNoCourse() {
      this.courseSearchId = null;
      this.noCourse = true;
    },
    twiddle() {
      this.setSortedRegistrations();
      this.$forceUpdate();
    },
    selectCourse(option) {
      this.courseSearchId = option.id;
      this.courseSearchResponse = [];
    },
    selectOwner(option) {
      this.noOwner = false;
      this.ownerSearchId = option.id;
      this.ownerSearchResponse = [];
    },
    selectOwnerForBulk(option) {
      this.bulkChangeOwnerId = option.id;
      // this.ownerSearchResponseForBulk = [];
    },
    selectLearner(option) {
      this.learnerSearchId = option.id;
      this.learnerSearchResponse = [];
    },
    getFilteredOwnerForBulk(text) {
      this.ownerSearchResponseForBulk = User.query()
        .search(text, {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    }, getFilteredOwner(text) {
      this.ownerSearchResponse = User.query()
        .search(text, {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    },
    getFilteredLearner(text) {
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.learnerSearchResponse = result.entities.learners;
        } else {
          this.learnerSearchResponse = [];
        }
      });
    },
    getFilteredCourse(text) {
      this.loadingCourses = true;

      Course.search(text, true).then((result) => {
        this.loadingCourses = false;
        if (result.entities) {
          this.courseSearchResponse = result.entities.courses;
        } else {
          this.courseSearchResponse = [];
        }
      });
    },
    registrationOwner(owner_id) {
      let owner = User.find(parseInt(owner_id));

      if (owner) {
        return `${owner.first_name} ${owner.last_name}`;
      } else {
        return `None`;
      }
    },
    goToRegistration() {
      VueCookie.set(
        "registrations_save",
        JSON.stringify({
          filters: this.filters,
          page: this.page,
          isTable: this.isTable,
          route: this.$router.currentRoute.path,
          tasksFilter: this.tasksFilter
        })
      );

    },

    setSortedRegistrations() {
      this.stages.forEach((element) => {

        this.stageSortedRegistrations[element.id - 1] = Registration.query()
          .with("user")
          .with("course")
          .where((registration) => {
            for (var key in this.filters) {
              if (registration[key] != this.filters[key]) return false;
            }
            return true;
          })
          .where("stage_id", parseInt(element.id))
          .orderBy("created_at", this.sortOrder)
          .get();
      });
      this.value = true;
    },
    onChange(evt, stage) {
      if (evt.hasOwnProperty("added")) {
        let registration = evt.added.element;

        Registration.update({
          where: registration.id,
          data: {
            stage_id: stage,
          },
        }).then((result) => {
          Registration.updateStage(result);
        });
      }
    },

    infiniteHandler($state) {
      return Registration.fetchAll(
        this.card_page + 1,
        this.card_page_limit,
        {
          ...this.filters, ...{board_id: this.board_id}, ...(this.tasksFilter
            ? {
              has_incomplete_tasks: this.tasksFilter,
            }
            : {}),
          ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {order_by: "created_at"}),
          ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {order_direction: "desc"}),
        },
        true
      ).then(({response}) => {
        if (response.data.data.length > 0) {
          this.meta = response.data.meta;
          this.stageCounts = response?.data?.counts ?? {};
          this.card_page++;
          this.twiddle();
          $state.loaded();
          return;
        }
        $state.complete();
      });
    },
    getRegistrations(page, limit, query, by_stages) {
      this.$store.dispatch("loader/show");
      return Registration.fetchAll(
        page,
        limit,
        {
          ...query,
          ...{board_id: this.board_id}, ...(this.tasksFilter
            ? {
              has_incomplete_tasks: this.tasksFilter,
            }
            : {}),
          ...(this.order_by
            ? {
              order_by: this.order_by,
            }
            : {order_by: "created_at"}),
          ...(this.order_direction
            ? {
              order_direction: this.order_direction,
            }
            : {order_direction: this.sortOrder}),
        },
        by_stages
      ).then((result) => {
        this.stageCounts = result.response?.data?.counts ?? {};
        if (result.response.data.meta) {
          this.meta = result.response.data.meta;
        }
        this.$store.dispatch("loader/hide");
        this.setSortedRegistrations();
      });
    },
  },

  computed: {
    semesters() {
      return Semester.all()
    },
    sortOrder:{
      get(){
        return this.$store.getters['registrations/registrationsOrder']
      },
      set(value){
        this.$store.commit('registrations/SET_REGISTRATIONS_ORDER',value)
      }
    },
    boards() {
      return RegistrationBoard.query().orderBy('order').with('stages').get()
    },
    minItemBoards() {
      return this.boards.filter(board => {
        return !board.stages.some(stage => stage.name.length === 0) && board.id !== 4
      })
    },
    filters() {
      return {
        ...(this.statusFilter
          ? {
            registration_status_id: this.statusFilter,
          }
          : {}),
        ...(this.yearFilter
          ? {
            year: this.yearFilter,
          }
          : {}),
        ...(this.semesterFilter
          ? {
            semester: this.semesterFilter,
          }
          : {}),
        ...(this.rplCatFilter
          ? {
            rpl_cat_requested: this.rplCatFilter,
          }
          : {}),
        ...(this.learnerSearchId
          ? {
            user_id: this.learnerSearchId,
          }
          : {}),
        ...(this.courseSearchId
          ? {
            course_id: this.courseSearchId,
          }
          : {}),
        ...(this.ownerSearchId
          ? {
            owner_id: this.ownerSearchId,
          }
          : {}),
        ...(!this.noOwner ? {} : {owner_id: null}),
        ...(!this.noCourse ? {} : {course_id: null}),
      };
    },

    registrations() {
      return Registration.query()
        .with("user")
        .with("owner")
        .with("creator")
        .with("course")
        .with("stage")
        .with('status')
        .where((registration) => {
          for (var key in this.filters) {
            if (registration[key] != this.filters[key]) return false;
          }
          return true;
        })
        .orderBy(
          this.order_by != null ? this.order_by : "created_at",
          this.order_direction != null ? this.order_direction : "desc"
        )
        .get();
    },
    administrate_registrations() {
      return this.$store.getters["entities/permissions/find"](
        "administrate registrations"
      );
    },
    learners() {
      return Learner.all();
    },
    users() {
      return User.all();
    },
    stages() {
      return RegistrationStage.query().where('board_id',this.board_id).orderBy("order", "asc").get();
    },
    search() {
      return Learner.query()
        .search(".com", {
          caseSensitive: false,
          threshold: 0.3,
        })
        .get();
    },
  },
};
</script>

<style lang="scss" scoped>
.column-view {
  .box {
    padding: 0.5rem;
  }

  height: 100%;
}

a:visited {
  color: unset !important;
}

.stage-columns {
  overflow-x: scroll;
  overflow-y: hidden;

  .column {
    &.stage-column {
      min-width: 150px;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.bar-container {
  max-width: 95vw;
  overflow-y: hidden;
}

.flippy {
  max-width: 96vw;
}

.bar-container,
.flippy {
  transform: rotateX(180deg);
  -moz-transform: rotateX(180deg); /* Mozilla */
  -webkit-transform: rotateX(180deg); /* Safari and Chrome */
  -ms-transform: rotateX(180deg); /* IE 9+ */
  -o-transform: rotateX(180deg); /* Opera */
}

.registration-dragged {
  border: 2px solid lightgreen;
}

.column-target {
  border: 2px solid lightblue;
  border-radius: 6px;
}
</style>
