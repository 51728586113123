<template>
  <div class="learner-item">
    <div class="level">
      <div class="level-left course-name-container" >
        <div class="level-item" style="max-width: 100%">
          <section class="is-4">
            <b-field>
              <p>{{ course.name }}</p>
            </b-field>
            <b-field>
              <b-button type="is-success"
                        @click="downloadGradeTranscript(course)"
                        :loading="reportTranscriptLoadingId == course.id"
                        :disabled="registrations.length == 0"
              >
                Download Course Transcript
              </b-button>
            </b-field>
          </section>
        </div>
      </div>
      <div class="level-right">
        <div class="level-item">
          <form @submit.prevent="updateUserCourse">
            <b-field grouped>
              <b-field label="Programme status">
                <b-select required placeholder="Status" v-model="status_hold">
                  <option v-for="status in statuses" :key="status.id" :value="status.id">{{ status.name }}</option>
                </b-select>
              </b-field>
              <b-field label="Honours">
                <b-select required placeholder="Honours" v-model="honour_status_hold">
                  <option v-for="honour_status in honour_statuses" :key="honour_status.id" :value="honour_status.id">
                    {{ honour_status.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field label="Achievement type">
                <b-select placeholder="Achievement type" required v-model="achievement_type_hold">
                  <option v-for="achievement_type in achievement_types" :key="achievement_type.id"
                          :value="achievement_type.id">{{ achievement_type.name }}
                  </option>
                </b-select>
              </b-field>
            </b-field>

            <b-field grouped>
              <b-field expanded label="Achieved at">
                <b-field expanded>
                  <b-datepicker expanded v-model="achieved_at">

                  </b-datepicker>
                  <p class="control">
                    <b-button @click="achieved_at=null" icon-right="close"></b-button>
                  </p>
                </b-field>
              </b-field>
              <b-field label="Save">
                <b-button native-type="submit" type="is-primary">Submit</b-button>
              </b-field>
            </b-field>
          </form>
        </div>
      </div>
    </div>
    <b-field v-if="!!$store.getters['entities/permissions/find']('view registrations')" label="Registrations">
      <b-table v-if="registrations.length>0" class="has-pointer" :selected="selected"
               @select="selectRow"
               :data="registrations" :striped="true" :hoverable="true">
        <b-table-column v-slot="props" label="Id" field="id" width="40" numeric>{{ props.row.id }}</b-table-column>

        <b-table-column v-slot="props"
          sortable
          field="enrolment_date"
          label="Enrolment Date"
        >{{ props.row.enrolment_date }}
        </b-table-column>   <b-table-column v-slot="props"
        sortable
        field="year"
        label="Year"
      >{{ props.row.year }}
      </b-table-column>
        <b-table-column v-slot="props" label="Total Credits">{{ totalCredits(props.row) }}</b-table-column>
        <b-table-column v-slot="props" label="Intake">{{ props.row.intake ? props.row.intake.name : "None Assigned" }}</b-table-column>
        <b-table-column v-slot="props" sortable field="grade_status" label="Grade Status">
                {{ props.row.grade_status }}
        </b-table-column>
        <b-table-column v-slot="props" sortable field="registration_status_id" label="Status">
                <span
                  :class="{'has-background-incomplete':props.row.registration_status_id == 1 ||  props.row.registration_status_id == 6,'has-background-warning':props.row.registration_status_id == 2 || props.row.registration_status_id == 4,'has-background-success':props.row.registration_status_id == 3 ||  props.row.registration_status_id == 5 || props.row.registration_status_id == 8}"
                  class="tag is-size-6"
                >{{ registrationStatusName(props.row.registration_status_id) }}</span>
        </b-table-column>
        <b-table-column v-slot="props" label="Download">
            <b-field>
              <b-button style="margin-right:10px;" type="is-success" @click="downloadGradeReport(props.row, course)" :loading="reportDownloadLoading">
                Download Grade Report
              </b-button>
            </b-field>
        </b-table-column>


      </b-table>
    </b-field>
    <b-field v-if="!!$store.getters['entities/permissions/find']('view grades')" label="Grades">
      <GradesIndex v-if="selected" :in-tab="true" :hasFilters="false" :registration-id="parseInt(selected.id)"
                   :current-semester="false" :default-course="parseInt(this.course.id)" :default-user="parseInt(this.user_id)"/>

    </b-field>
    <b-loading :is-full-page="false" v-model="isLoading"></b-loading>
    <hr>
  </div>

</template>

<script>
import Course from "@/models/Course";
import Registration from "@/models/Registration";
import Lookup from "@/models/Lookup";
import GradesIndex from "@/components/GradesIndex";
import Grade from "@/models/Grade";
import User from "@/models/User";

export default {
  name: 'LearnerCourseItem',
  components: {
    GradesIndex
  },
  data() {
    return {
      selectedIndex:0,
      isLoading: false,
      status_hold: null,
      honour_status_hold: null,
      achievement_type_hold: null,
      achieved_at: null,
      reportTranscriptLoadingId: null,
      user: null,
      reportDownloadLoading: false,
    }
  },

  methods: {
    downloadGradeTranscript(course) {
      this.reportTranscriptLoadingId = course.id;
      Grade.downloadTranscript(this.registrations[0]).then((res) => {
        this.reportTranscriptLoadingId = null;
        let FILE = window.URL.createObjectURL(new Blob([res.response.data]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.user.student_number+'-'+course.long_name+'-grade-transcript.pdf');
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    downloadGradeReport(reg, course) {
      this.reportDownloadLoading = true;
      Grade.downloadReport(reg).then((res) => {
        this.reportDownloadLoading = false;
        let FILE = window.URL.createObjectURL(new Blob([res.response.data]));
        let docUrl = document.createElement('a');
        docUrl.href = FILE;
        docUrl.setAttribute('download', this.user.student_number+'-'+ course.long_name+'-grade-report.pdf');
        document.body.appendChild(docUrl);
        docUrl.click();
      });
    },
    selectRow(event){
      this.selectedIndex= this.registrations.findIndex(registration=> registration.id == event.id)
    },
    registrationStatusName(id) {

      return this.$store.state.statuses.statuses.find(status=> status.id ===parseInt(id)).name
          },

    updateUserCourse() {
      this.isLoading = true
      Course.updateUserCourse(this.user_id, this.course.id, {
        status_id: this.status_hold,
        honors_status_id: this.honour_status_hold,
        achievement_type_id: this.achievement_type_hold,
        achieved_at: this.achieved_at!==null?this.$moment(this.achieved_at).format("YYYY-MM-DD hh:mm:ss"):null
      }).then(() => {
        this.isLoading = false
        this.$buefy.snackbar.open('Course updated!')
      }).catch((err) => {
        this.isLoading = false

        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data),
        });
      });
    },
    totalCredits(registration) {
      let total = 0;
      registration.modules.forEach((element) => {
        if (element.status_id !== 2) {
          total += element.credits;
        }
      });
      return total;
    }, viewRegistration(registration_id) {
      this.$router.push(`/registrations/edit/${registration_id}`);
    },
  },
  mounted() {
    this.isLoading = true
    this.honour_status_hold = this.course.honors_status_id
    this.achievement_type_hold = this.course.achievement_type_id
    this.status_hold = this.course.status_id
    this.achieved_at =  this.course.achieved_at===null?null:new Date(this.course.achieved_at)
    User.fetchById(this.user_id).then((res) => {
      this.user = res.entities.users[0];
    })
    Registration.fetchAll(1, 999, {user_id: this.user_id, course_id: this.course.id}).then(() => {
      this.isLoading = false

    }).catch(err => {
      this.$store.dispatch("toast/createToast", {
        message: JSON.stringify(err.response.data),
      });
      this.isLoading = false
    })
  },
  computed: {
selected(){
  return this.registrations[this.selectedIndex]
},
    statuses() {
      return Lookup.query().where('lookup_type_id', 24).get()
    }, honour_statuses() {
      return Lookup.query().where('lookup_type_id', 25).get()

    }, achievement_types() {
      return Lookup.query().where('lookup_type_id', 26).get()

    },
    registrations() {
      return Registration.query().where('user_id', (value) => {
        return parseInt(value) === parseInt(this.user_id)
      }).where('course_id', parseInt(this.course.id)).with('modules').with('intake').orderBy('year').get()
    },

  },
  props: {
    course: {type: Object, required: true}
    , user_id: {
      type: Number,
      required: true
    }
  }
}
</script>
