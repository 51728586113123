<template>
  <li v-if="hasPermission" :class="{'is-active':isDropdownActive}">
    <template v-if="!isButton">
      <router-link
        v-if="componentIs==='router-link'"
        :to="itemTo"
        @click="menuClick"
        :title="item.label"
        exact-active-class="is-active"
        :class="{'has-icon':!!item.icon,'open':is_open}"
      >
        <div class="level">
          <div class="level-left no-overflow">
            <div class="level-item no-overflow">
              <b-icon
                v-if="item.icon"
                :icon="item.icon"
                :class="{ 'has-update-mark' : item.updateMark }"
                custom-size="default"
              />
              <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </router-link>
      <a
        v-if="componentIs==='a'"
        :href="itemHref"
        @click="menuClick"
        :title="item.label"
        exact-active-class="is-active"
        :class="{'has-icon':!!item.icon,'open':is_open}"
      >
        <div class="level">
          <div class="level-left no-overflow">
            <div class="level-item no-overflow">
              <b-icon
                v-if="item.icon"
                :icon="item.icon"
                :class="{ 'has-update-mark' : item.updateMark }"
                custom-size="default"
              />
              <span v-if="item.label" :class="{'menu-item-label':!!item.icon}">{{ item.label }}</span>
            </div>
          </div>
        </div>
      </a>
    </template>
    <template v-else>
      <div         :class="{'has-icon':!!item.icon,'open':is_open}"
      >
        <div class="level">
          <div class="level-left no-overflow">
            <div class="level-item no-overflow">
              <b-icon
                v-if="item.icon"
                :icon="item.icon"
                :class="{ 'has-update-mark' : item.updateMark }"
                custom-size="default"
              />
              <b-button @click="startWithdraw" type="is-danger">
                Withdraw
              </b-button>
            </div>
          </div>
        </div>
      </div>

    </template>

    <aside-menu-list v-if="hasDropdown" :menu="item.menu" :isSubmenuList="true" />
  </li>
</template>

<script>
import { mapState } from "vuex";
import Registration from "@/models/Registration";

export default {
  name: "AsideMenuItem",
  data() {
    return {
      isDropdownActive: false,
    };
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
  },
  methods: {
    startWithdraw() {
      this.$buefy.dialog.confirm({
        title: "Withdraw Application",
        message:
          "Are you sure you want to withdraw? This action cannot be undone.",
        confirmText: "Withdraw",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.withdraw(),
      });
    },
    withdraw() {
      this.$store.dispatch('loader/show')
      let withdrawObject = Registration.query().whereId(this.$store.state.applications.activeRegistrationId).first()
      withdrawObject.registration_status_id = 6
      Registration.updateRemote(withdrawObject).then(() => {
        this.$store.dispatch('loader/hide')
        this.$store.state.applications.activeRegistrationId = null
        this.$store.state.applications.hasRegistrationInProgress = false
        this.$store.state.applications.canSeeRegistrationsIndex = true
        this.$router.push({name: 'registrations.mine'})
      }).catch(err => {
        this.handleError(err)
        this.$store.dispatch('loader/hide')
      })
    },
    menuClick() {
      this.$emit("menu-click", this.item);

      if (this.hasDropdown) {
        this.isDropdownActive = !this.isDropdownActive;

        if (!this.isAsideMobileExpanded) {
          this.$store.commit("asideStateToggle", true);
        }
      }
    },
  },
  computed: {
    is_open() {
      let bool = false;
      if (this.$route.meta.highlight) {
        bool = this.$route.meta.highlight == this.item.name;
      } else if (this.$route.name) {
        bool = this.$route.name.includes(this.item.name);
      }
      return bool;
    },
    hasPermission() {
      if (this.item.permission) {
        return this.$store.getters["entities/permissions/find"](
          this.item.permission
        );
      }
      return true;
    },
    componentIs() {
      return this.item.to ? "router-link" : "a";
    },
    isButton(){
      if (Object.prototype.hasOwnProperty.call(this.item,'is_button')){
        return this.item.is_button
      }
      return false
    },
    hasDropdown() {
      return !!this.item.menu;
    },
    itemTo() {
      return this.item.to ? this.item.to : null;
    },
    itemHref() {
      return this.item.href ? this.item.href : null;
    },
    ...mapState(["isAsideExpanded", "isAsideMobileExpanded"]),
  },
  watch: {
    isAsideExpanded(newValue) {
      if (!newValue) {
        this.isDropdownActive = false;
      }
    },
  },
};
</script>
