<template>
  <section class="section">
    <title-bar>{{ $tc('Course', 2) }}</title-bar>

    <div class="columns">
      <div class="column is-3">
        <b-field label="Search" horizontal>
          <b-input
            v-debounce:300ms="getFilteredCourse"
            placeholder="Type any parameter here"
            :loading="loadingFilter"
            v-model="filter"
          ></b-input>
        </b-field>
      </div>
      <div class="column is-8">
        <b-field label="Qualification" horizontal>
          <b-autocomplete
            v-model="qualificationHold"
            v-debounce:300ms="getFilteredQualifications"
            :clear-on-select="false"
            :data="filteredQualifications"
            :loading="loadingQualifications"
            placeholder='Qualification Name'
            autocomplete="off"
            field="name"
            icon="account"
            name="Qualification"
            @select="option => selectQualification(option)"
            icon-right="close-thick"
            :icon-right-clickable=true
            @click.native="clearQualification"
          >
            <template slot-scope="props">
              <p>{{ props.option.code }} | {{ props.option.long_name }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <p
          v-if="qualificationHold.length>0 && qualification_id == null && !loadingQualifications"
          class="has-text-danger is-size-7"
        >No qualification selected</p>
      </div>
      <div class="column is-3">
        <b-field label="Intake" horizontal>
          <b-autocomplete
            v-model="intakeHold"
            v-debounce:300ms="getFilteredIntakes"
            :clear-on-select="false"
            :data="filteredIntakes"
            :loading="loadingIntakes"
            placeholder='Intake Name'
            autocomplete="off"
            field="name"
            icon="account"
            icon-right="close-thick"
            :icon-right-clickable=true
            name="Intake"
            @select="option => selectIntake(option)"
            @click.native="clearIntake"
            @blur="getFilteredCourse(filter)"
          >
            <template slot-scope="props">
              <p>{{ props.option.name }}</p>
            </template>
          </b-autocomplete>
        </b-field>
        <p
          v-if="intakeHold.length>0 && intake_id == null && !loadingIntakes"
          class="has-text-danger is-size-7"
        >No intake selected</p>
      </div>
    </div>
    <b-table scrollable class="margin-top" :data="courses" :striped="true" :hoverable="true" :bordered="true">
      <b-table-column v-slot="props" sortable field="name" label="Name">{{ props.row.name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="che_code" label="CHE Code">{{ props.row.che_code }}</b-table-column>
      <b-table-column v-slot="props" sortable field="saqa_number" label="SAQA">{{ props.row.saqa_number }}</b-table-column>
      <b-table-column v-slot="props" sortable field="heqcis_number" label="HEQCIS">{{ props.row.heqcis_number }}</b-table-column>
      <b-table-column v-slot="props"
        sortable
        field="total_credits"
        label="Total Credits"
      >{{ props.row.total_credits }}
      </b-table-column>
      <b-table-column v-slot="props"
        sortable
        field="is_open"
        label="Status"
      >
        <b-switch @input="updateStatus(props.row,$event)" v-model="props.row.is_open" :true-value="1" :false-value="0">{{ props.row.is_open===1 ? 'Open' : 'Closed' }}</b-switch>

      </b-table-column>
      <b-table-column v-slot="props" centered  custom-key="actions">
        <b-field>
<span class="control">            <b-button  @click="viewCourse(props.row.id)" type="is-info">View</b-button>
</span> <span class="control"><b-button
          v-if="can_delete_courses"
          @click="start_delete_course(props.row.id)"
          type="is-danger"
        >Delete
            </b-button></span>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      class="margin-top"
      :total="meta.total"
      :current="meta.current_page"
      :range-before="2"
      :range-after="2"
      :per-page="meta.per_page"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Course from "@/models/Course";
import Qualification from "@/models/Qualification";
import TitleBar from "@/components/TitleBar";
import Intake from "@/models/Intake";

export default {
  name: "Courses",
  components: {TitleBar},
  data() {
    return {
      filter: "",
      loadingFilter: false,
      courses: [],
      meta: Object,
      page: 1,
      qualificationHold: "",
      intakeHold: "",
      qualification_id: null,
      intake_id: null,
      filteredQualifications: [],
      filteredIntakes: [],
      loadingQualifications: false,
      loadingIntakes: false,
    };
  },
  methods: {
    getFilteredQualifications(text) {
      if (text.length > 0) {
        this.loadingQualifications = true;

        Qualification.search(text,true, 1).then((result) => {
          this.loadingQualifications = false;
          if (result.entities) {
            this.filteredQualifications = result.entities.qualifications;
          } else {
            this.filteredQualifications = [];
          }
        });
      }
    },
    getFilteredIntakes(text) {
      if (text.length > 0) {
        this.loadingIntakes = true;

        Intake.search(text,true, 1).then((result) => {
          this.loadingIntakes = false;
          if (result.entities) {
            this.filteredIntakes = result.entities.intakes;
          } else {
            this.filteredIntakes = [];
          }
        });
      }
    },
    clearIntake() {
      this.intakeHold = "";
      this.intake_id = null;
      this.getFilteredCourse(this.filter);
    },
    clearQualification() {
      this.qualificationHold = "";
      this.qualification_id = null;
      this.getFilteredCourse(this.filter);
    },
    selectQualification(qualification) {
      if (qualification) {
        this.qualification_id = qualification.id;
        this.getFilteredCourse(this.filter);
      } else {
        this.qualification_id = null;
      }
    },
    selectIntake(intake) {
      if (intake) {
        this.intake_id = intake.id;
        this.getFilteredCourse(this.filter);
      } else {
        this.qualification_id = null;
      }
    },
    updateStatus(course,event){
      let submitObject = JSON.parse(JSON.stringify(course))
      submitObject.is_open = event
      Course.Update(submitObject).catch(err => {
        this.$store.dispatch("toast/createToast", {
          message: err.response.data.errors.is_open[0],
        });
        // set toggle back to opposite of what it tried to be
        let index = this.courses.findIndex(item => item.id == course.id)
        this.courses[index].is_open = !event;
      })

    },
    start_delete_course(courseId) {
      this.$buefy.dialog.confirm({
        title: "Deleting " + this.$tc("Course", 1),
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete " + this.$tc("Course", 1),
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteCourse(courseId)
      });

    },
    deleteCourse(courseId) {
      this.$store.dispatch("loader/show");

      this.updatingCourse = true;
      Course.Delete(courseId)
        .then(() => {
          Course.delete(courseId);
          this.getCourses(1, 15)
            .then(() => {
              this.$store.dispatch("loader/hide");
            })
            .catch(err => {
            });
          this.updatingCourse = false;
        })
        .catch(err => {
        });
    },
    viewCourse(id) {
      this.$router.push(`/courses/edit/${id}`);
    },
    getFilteredCourse(text) {
      this.loadingFilter = true;

      Course.qSearch(text, this.qualification_id, this.intake_id,true).then(result => {
        this.loadingFilter = false;

        this.courses = result.response.data.data;
        this.meta = result.response.data.meta;
      });
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      if (this.filter.length > 0) {
        Course.search(this.filter, true, null, pageNumber, 15).then(result => {
          this.courses = result.response.data.data;
          this.meta = result.response.data.meta;
          this.$store.dispatch("loader/hide");
        });
      } else {
        this.getCourses(pageNumber, this.meta.per_page).then(() => {
          this.$store.dispatch("loader/hide");
        });
      }
    },
    getCourses(page, limit) {
      return Course.fetchAll(page, limit).then(result => {
        this.courses = result.response.data.data;

        this.meta = result.response.data.meta;
      });
    }
  },

  computed: {
    can_delete_courses() {
      return this.$store.getters["entities/permissions/find"]("delete courses");
    }
  },
  mounted() {
    this.$store.dispatch("loader/show");
    this.getCourses(1, 15)
      .then(() => {
        this.$store.dispatch("loader/hide");
      })
      .catch(err => {
      });
  }
};
</script>
