<template>
  <div id="app">
    <b-loading ref="pageLoader" :active.sync="loading" :can-cancel="false" :class="{'opaque':opaque}"
               :is-full-page="true"/>
    <nav-bar v-if="authenticated"/>
    <aside-menu v-if="authenticated" :menu="menu"/>
    <router-view id="app-view"/>
    <aside-right v-if="authenticated"/>
    <footer-bar v-if="authenticated"/>
    <div v-if="maintenance" class="staging-footer">
      <h2 class="has-text-white has-text-centered">The site is currently in maintenance mode. Please return later.</h2>
    </div>
  </div>
</template>


<script>
import NavBar from "@/components/NavBar";
import AsideMenu from "@/components/AsideMenu";
import FooterBar from "@/components/FooterBar";
import AsideRight from "@/components/AsideRight";
import {mapState} from "vuex";
// import Permission from "@/models/Permission";
import router from "./router";

// import Post from "@/models/Post";

export default {
  components: {AsideRight, FooterBar, AsideMenu, NavBar},
  data() {
    return {
      maintenance:false,
    };
  },
  methods: {
    hasPermission(permission) {
      return this.$store.getters["entities/permissions/find"](permission);
    },
  },
  computed: {
    ...mapState("auth", ["authenticated"]),
    ...mapState("loader", ['loading', 'overlay', 'opaque']),
    canWithdraw(){
      return !!this.$store.state.applications.activeRegistrationId && this.$route.name==='registrations.register'
    },
    menu() {
      return this.hasPermission("view frontend")
        ? [
          //   {
          //   to: "/applications-home",
          //   label: "Application Process",
          //   icon: router.app.$tc("icons.index"),
          //   name: "applications-home",
          //   permission: "view frontend",
          // },
          ...(
            this.$store.state.applications.canSeeRegistrationsIndex ? [{
              to: "/registrations/mine",
              label: "My Registrations",
              icon: router.app.$tc("icons.index"),
              name: "registrations.mine",
              permission: "view registrations",
            }] : []
          ),
          ...(
            this.$store.state.applications.canSeeRegistrationsIndex ? [{
              to: "/grades/mine",
              label: "Grades",
              icon: router.app.$tc("icons.index"),
              name: "grades.mine",
              permission: "view registrations",
            }] : []
          ),
          ...(
            this.$store.state.applications.hasRegistrationInProgress ? [
              {
              to: "/registrations/register",
              label: "Register",
              icon: router.app.$tc("icons.index"),
              name: "registrations.process",
              permission: "view registrations",
            }
            ] : []
          ),


          {
            to: "/my-profile",
            label: "My Profile",
            icon: router.app.$tc("icons.users"),
            name: "my-profile",
          },
          ...(
            this.canWithdraw ? [{
              is_button:true,
              to: "/registrations/register",
              label: "Register",
              icon: router.app.$tc("icons.delete"),
              name: "registrations.process",
              permission: "view registrations",
            }] : []
          ),
          // {
          //   to: "/application-support-documents",
          //   label: "Application Support Documents",
          //   icon: router.app.$tc("icons.documents"),
          //   name: "application-support-documents",
          // },
        ]
        : [
          // {
          //   label: "Enquiries",
          //   icon: router.app.$tc("icons.enquiries"),
          //   permission: "view enquiries",
          //   name: "enquiries",
          //
          //   menu: [
          //     {
          //       name: "enquiries.index",
          //       to: "/enquiries",
          //       label: "View All",
          //       icon: router.app.$tc("icons.index"),
          //     },
          //     {
          //       to: "/enquiries/create",
          //       label: "Quick Create",
          //       icon: router.app.$tc("icons.create"),
          //       name: "enquiries.create",
          //
          //       permission: "create enquiries",
          //     },
          //   ],
          // },


          {
            label: "Registrations",
            icon: router.app.$tc("icons.registrations"),
            permission: "view registrations",
            name: "registrations",
            menu: [
              {
                to: "/registrations/index",
                label: "Index",
                icon: router.app.$tc("icons.index"),
                name: "registrations.index",
                permission: "view registrations index",
              },
              // {
              //   to: "/registrations/boards/on-boarding",
              //   label: "On-Boarding",
              //   icon: router.app.$tc("icons.index"),
              //   name: "registrations.on-boarding",
              //   permission: "view on-boarding board",
              // },

              // {
              //   to: "/registrations/boards/student-advisors",
              //   label: "Student Advisors",
              //   icon: router.app.$tc("icons.index"),
              //   name: "registrations.student-advisors",
              //   permission: "view student advisors board",
              // },

              // {
              //   to: "/registrations/boards/admissions-officers",
              //   label: "Admissions",
              //   icon: router.app.$tc("icons.index"),
              //   name: "registrations.admissions-officers",
              //   permission: "view admissions officers board",
              // },

              // {
              //   to: "/registrations/boards/rpl-cat",
              //   label: "RPL/CAT",
              //   icon: router.app.$tc("icons.index"),
              //   name: "registrations.rpl-cat",
              //   permission: "view rpl/cat board",
              // },
              {
                to: "/registrations/boards/rpl-for-access",
                label: "RPL For Access",
                icon: router.app.$tc("icons.index"),
                name: "registrations.rpl-for-access",
                permission: "view rpl for access board",
              },
              {
                to: "/registrations/boards/rpl-for-exemption",
                label: "RPL For Exemption",
                icon: router.app.$tc("icons.index"),
                name: "registrations.rpl-for-exemption",
                permission: "view rpl for exemption board",
              },
              {
                to: "/registrations/boards/cat",
                label: "CAT",
                icon: router.app.$tc("icons.index"),
                name: "registrations.cat",
                permission: "view cat board",
              },
              {
                to: "/registrations/boards/finance-bursar",
                label: "Postgraduate Applications",
                icon: router.app.$tc("icons.index"),
                name: "registrations.psych-applications",
                permission: "view finance/bursar board",
              },

              // {
              //   to: "/registrations/boards/final-approval",
              //   label: "Final Approval of Registration",
              //   icon: router.app.$tc("icons.index"),
              //   name: "registrations.final-approval",
              //   permission: "view final approval of registration board",
              // },

              {
                to: "/registrations/boards/graduated",
                label: "Graduated",
                icon: router.app.$tc("icons.index"),
                name: "registrations.graduated",
                permission: "view graduated board",
              },
              {
                to: "/registrations/boards/registrations",
                label: "Registrations",
                icon: router.app.$tc("icons.index"),
                name: "registrations.registrations",
                permission: "view registrations board",
              },
              {
                to: "/registrations/boards/dormant",
                label: "Dormant",
                icon: router.app.$tc("icons.index"),
                name: "registrations.dormant",
                permission: "view dormant board",
              },
              {
                to: "/registrations/create",
                label: "Quick Create",
                name: "registrations.create",

                icon: router.app.$tc("icons.create"),
                permission: "create registrations",
              },
            ],
          },
          {
            label: router.app.$tc("Learner", 2),
            icon: router.app.$tc("icons.learners"),
            permission: "view learners",
            name: "learners",

            menu: [
              {
                to: "/learners",
                label: "View All",
                name: "learners.index",
                icon: router.app.$tc("icons.index", 2),
              }, {
                to: "/learners/duplicates",
                label: "View Duplicates",
                name: "learners.duplicates",
                icon: router.app.$tc("icons.index", 2),
                permission: 'view duplicate profiles'
              },
              {
                to: "/learners/create",
                name: "learners.create",
                label: "Create a " + router.app.$tc("learner", 1),
                icon: router.app.$tc("icons.create"),
                permission: "create learners",
              },
            ],
          },
          {
            label: "Users",
            icon: router.app.$tc("icons.users"),
            permission: "administrate users",
            name: "users",
            menu: [
              {
                to: "/users",
                label: "View All",
                icon: router.app.$tc("icons.index"),
                name: "users.index",
              },
              {
                to: "/users/create",
                name: "users.create",
                label: "Create a user",
                icon: router.app.$tc("icons.create"),
                permission: "create users",
              },
            ],
          },
          {
            to: "/classlist",
            label: "Classlists",
            icon: router.app.$tc("icons.classlists"),
            name: "classlist",
            permission: "view classlists",
          },
          {
            label: "Emails",
            icon: router.app.$tc("icons.emails"),
            name: "emails",
            to: "/emails",
            permission: "view emails",
          },
          {
            to: "/schedule",
            label: "Schedule",
            icon: router.app.$tc("icons.schedule"),
            name: "schedule",
            permission: "view schedule",
          },
          {
            to: "/gradebook",
            label: "Gradebook",
            icon: router.app.$tc("icons.gradebook"),
            name: "gradebook",
            permission: "view grades",
          },
          {
            label: "Modules",
            icon: router.app.$tc("icons.modules"),
            permission: "view modules",
            name: "modules",
            menu: [
              {
                to: "/modules",
                label: "View All",
                name: "modules.index",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/modules/create",
                label: "Create a module",
                name: "modules.create",
                icon: router.app.$tc("icons.create"),
                permission: "create modules",
              },
            ],
          },
          {
            label: router.app.$tc("Course", 2),
            icon: router.app.$tc("icons.courses"),
            permission: "view courses",
            name: "courses",
            menu: [
              {
                to: "/courses",
                name: "courses.index",

                label: "View All",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/courses/create",
                name: "courses.create",

                label: "Create a " + router.app.$tc("Course", 1),
                icon: router.app.$tc("icons.create"),
                permission: "create courses",
              },
            ],
          },
          {
            label: 'Qualifications',
            icon: router.app.$tc("icons.courses"),
            name: "qualifications",
            permission: "view qualifications",
            menu: [
              {
                to: "/qualifications",
                name: "qualifications.index",
                label: "View all",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/qualifications/create",
                name: "qualifications.create",
                label: "Create a Qualification",
                icon: router.app.$tc("icons.create"),
              }
            ]
          },
          {
            label: 'Intake Periods',
            icon: router.app.$tc("icons.courses"),
            permission: "view courses",
            name: "intakes",
            menu: [
              {
                to: "/intakes",
                name: "intakes.index",
                label: "View All",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/intakes/create",
                name: "intakes.create",
                label: "Create an intake",
                icon: router.app.$tc("icons.create"),
                permission: "create courses",
              },
            ],
          },{
            label: 'Semesters',
            icon: router.app.$tc("icons.courses"),
            permission: "view courses",
            name: "semesters",
            menu: [
              {
                to: "/semesters",
                name: "semesters.index",

                label: "View All",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/semesters/create",
                name: "semesters.create",

                label: "Create a semester",
                icon: router.app.$tc("icons.create"),
                permission: "create courses",
              },
            ],
          },
          {
            label: "Activities",
            icon: router.app.$tc("icons.activities"),
            permission: "view tasks",
            name: "activities",
            menu: [
              {
                to: "/activities",
                name: "activities.index",

                label: "My Activities",
                icon: router.app.$tc("icons.index"),
              },
            ],
          },
          {
            to: "/bulk-send",
            label: "Bulk Send",
            icon: router.app.$tc("icons.bulk-send"),
            name: "bulk-send.filters",
            permission: "view bulk-send",
          },
          {
            label: "Roles",
            icon: router.app.$tc("icons.roles"),
            name: "roles",
            permission: "view roles",
            menu: [
              {
                to: "/roles",
                name: "roles.index",
                label: "View All",
                icon: router.app.$tc("icons.index"),
              },
              {
                to: "/roles/create",
                name: "roles.create",
                label: "New Role",
                icon: router.app.$tc("icons.create"),
                permission: "create roles",
              },
            ],
          },

          {
            to: "/reports",
            label: "Reports",
            icon: router.app.$tc("icons.reports"),
            name: "reports.index",
            permission: "view reports",
          },
          {
            to: "/exports",
            label: "Exports",
            icon: router.app.$tc("icons.exports"),
            name: "exports.index",
            permission: "view exports",
          },
          {
            to: "/lookups",
            label: "Lookup Manager",
            icon: router.app.$tc("icons.lookups"),
            name: "lookups.index",
            permission: "view lookups",
          },
        ];
    },
  },

  created() {
    this.$on("showLoader", () => {
      this.isLoading = true;
    });
    this.$on("hideLoader", () => {
      this.isLoading = false;
    });
  },
};
</script>
