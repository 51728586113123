<template>
  <section class="section">
    <title-bar>Semesters</title-bar>

    <b-table :bordered="true" :data="semesters" :hoverable="true" :striped="true" class="margin-top" scrollable>
      <template #empty>
        No Semesters loaded
      </template>
      <b-table-column v-slot="props" field="id" label="ID" numeric width="40">{{
          props.row.id
        }}
      </b-table-column>
      <b-table-column v-slot="props" field="name" label="Name" >{{ props.row.name }}</b-table-column>
      <b-table-column v-slot="props" label="Year" >{{ props.row.year }}</b-table-column>
      <b-table-column v-slot="props" label="Start Date" >{{ props.row.formatted_start_date }}</b-table-column>
      <b-table-column v-slot="props"  label="End Date" >{{ props.row.formatted_end_date }}</b-table-column>

      <b-table-column v-slot="props" width="120" label="Actions" centered custom-key="actions">
        <b-field>
          <span class="control">
          <b-button type="is-info" @click="viewSemester(props.row.id)">View</b-button>
          </span>
          <span class="control">
            <b-button
              v-if="!!$store.getters['entities/permissions/find']('delete courses')"
              type="is-danger"
              @click="startDelete(props.row.id)"
            >Delete
            </b-button>
          </span>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      :current="meta.current_page"
      :per-page="meta.per_page"
      :range-after="2"
      :range-before="2"
      :total="meta.total"
      aria-current-label="Current page"
      aria-next-label="Next page"
      aria-page-label="Page"
      aria-previous-label="Previous page"
      class="margin-top"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Semester from "@/models/Semester";
import TitleBar from "@/components/TitleBar";

export default {
  name: "SemestersIndex",
  components: {TitleBar},
  data() {
    return {
      semesters: [],
      semestersDataHold: [],
      loading: false,
      meta: Object,
      page: 1
    };
  },
  methods: {
    startDelete(semesterId) {
      this.$buefy.dialog.confirm({
        title: "Deleting semester",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete semester",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteSemester(semesterId)
      });

    },
    deleteSemester(semesterId) {
      this.$store.dispatch("loader/show");

      Semester.Delete(semesterId)
        .then(() => {
          this.$store.dispatch("loader/hide");

        })
        .catch(err => {
          this.handleError(err)
        });
    },
    viewSemester(id) {
      this.$router.push(`/semesters/edit/${id}`);
    },
    setPage(pageNumber) {
      this.loading = true;
      this.semestersDataHold = this.semesters
      Semester.deleteAll();
      this.page = pageNumber;
     return Semester.FetchAll(
        {
          page: pageNumber,
          limit: 15,
          orderBy: 'id',
          orderDir: 'desc'
        },
        this.filters,
      ).then(
        ({
           entities: {
             semesters
           },
           response: {
             data: {meta},
           }
         }) => {
          this.meta = meta;
          this.semesters = semesters.sort((a, b) => b.id - a.id);
          this.loading = false;
          this.semestersDataHold = null;
        }
      );
    },

  },
  async mounted() {
    await this.$store.dispatch("loader/show");
    await this.setPage(1)
    await this.$store.dispatch("loader/hide");

  }
};
</script>
