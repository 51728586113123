<template>
  <b-select
    :disabled="disabled"
    :required="required"
    :value="value"
    placeholder="Select an institution"
    @input="onInput"
    expanded>
    <option v-for="option in data" :key="option.id" :value="option.id">
      {{option.name}}
    </option>
  </b-select>
</template>

<script>
import Lookup from "@/models/Lookup";

export default {
  name: "SelectMarketingPriorInstitution",

  props: {
    disabled: {
      type: Boolean,
      default(){
        return false;
      },
    },
    required: {
      type: Boolean,
      default(){
        return false;
      },
    },
    value: {
      type: Number,
      default() {
        return -1;
      },
    },
  },
  data() {
    return {
      selected: null,
    }
  },
  methods: {
    onInput(e) {
      this.$emit("input", e);
    },
  },
  computed: {
    data() {
      return Lookup.query().where("lookup_type_id", 9).get();
    }
  },
}
</script>
