import { DialogProgrammatic as Dialog } from 'buefy'
export default {
    methods:
    {
      checkExtension(file){
        let valid = ['pdf','jpeg','png','gif','jpg','tiff','tif']
        let extension = file.type.substring(file.type.lastIndexOf('/')+1)
        if (valid.includes(extension)) {
          return true
        }
         valid = ['.pdf','.jpeg','.png','.gif','.jpg','.tiff','.tif']
        // eslint-disable-next-line no-useless-escape
        let regex = new RegExp("([a-zA-Z0-9s_\\.\-:])+(" + valid.join('|') + ")$");
        if (regex.test(file.name.toLowerCase())) {

          return true;
        }

        let validList = ''
        for (let type of valid){
          validList +=`<li>${type}</li>`
        }
        Dialog.alert({
          title: "Non accepted file type",
          message: `You are trying to upload a file of a non-accepted type. Only the following file types are allowed: <div class="content"><ul>${validList}</ul></div>`,
          type: "is-link",
          hasIcon: true,
          confirmText:'Ok',
          onConfirm: () => {
            return false
          },

        });
      }
    }
}
