import {
  TransformModel
} from '@/models/TransformModel'
import City from "@/models/City";


export default class State extends TransformModel {
  static entity = 'states'

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      country_id: this.attr(null),
      cities: this.hasMany(City, 'state_id')

    }
  }

  static fetchAll({page = 1, limit = 100}, query = {}, relationships = []) {
    return this.api().get(`/states`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          }, ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(state => {

            if (relationships.includes('cities')) {
              state.attributes.cities.map(city => {
                Object.assign(city, city.attributes)
              })
            }
            state.id = parseInt(state.id)
            state.country_id = parseInt(state.country_id)
            return {...state, ...state.attributes}
          })
        },
      }
    )
  }

}
