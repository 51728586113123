<template>
  <GradesIndex  />
</template>

<script>
import GradesIndex from "@/components/GradesIndex";
export default {
  components: {
    GradesIndex,
  },
  data() {
    return {
    };
  },

};
</script>
