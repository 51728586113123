<template>
  <CourseForm  :can-create="can_create_courses" :can-delete="can_delete_courses" :can-edit="can_edit_courses" />
</template>

<script>
import CourseForm from "@/components/courses/CourseForm";

export default {
  name: 'CourseCreate',
  components: {
    CourseForm,

  },
  computed:{
    can_delete_courses() {
      return !!this.$store.getters["entities/permissions/find"]("delete courses");
    }, can_edit_courses() {
      return !!this.$store.getters["entities/permissions/find"]("edit courses");
    }, can_create_courses() {
      return !!this.$store.getters["entities/permissions/find"]("create courses");
    }
  },
};
</script>
