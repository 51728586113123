<template>
  <div
    class="is-relative"
  >
    <template v-if="document.media!==null"><p class="title is-4">{{ document?.media?.tag }}</p>
      <div v-if="viewerType==='image'" class="is-flex is-justify-content-center">
        <div style="max-width: 500px">
          <b-image :src="document.media.temporary_url"/>
        </div>
      </div>
      <div v-if="viewerType==='iframe'" class="document.media-container is-justify-content-center is-flex">
        <iframe :src="document.media.temporary_url" frameborder="0" style="width: 100%;min-height: 700px"></iframe>
      </div>
      <div v-if="viewerType==='external'">
        <p>Cannot preview document. Please open externally</p>
      </div>
      <b-table
        :bordered="false"
        :data="[document.media]"
        :hoverable="true"
        :striped="true"
        class="margin-top"
      >
        <template #empty>
          <div class="has-text-centered">None</div>
        </template>
        <b-table-column v-slot="props"

                        field="filename"
                        label="Filename"
                        sortable
        >{{ props.row.filename }}
        </b-table-column
        >
        <b-table-column v-slot="props" custom-key="actions" width="170">
          <b-field grouped>
            <b-field>
              <b-button
                :href="props.row.temporary_url"
                download
                tag="a"
                target="_blank"
                type="is-primary">Open
              </b-button>

            </b-field>
          </b-field>

        </b-table-column>
      </b-table>
    </template>
    <div v-if="(document?.media?.tag==='Qualification Certificate')">
      <p class="has-text-weight-bold ">Prior Qualifications</p>
      <PriorQualificationListItem v-for="(study,index) in profile.previous_studies" :key="index" :index="index" :study="study" view_mode/>
    </div>
    <div v-if="(document?.media?.tag==='Certified copy of ID or other proof of identity')">
      <b-field expanded  label="Identity type">
        <b-select  expanded v-model="profile.identification_type_id"

                  class="style-force"
                  disabled
                  icon="id-card"
                  placeholder="Identity type"
        >
          <option
            v-for="identification_type in identification_types"
            :key="identification_type.id"
            :value="identification_type.id"
          >{{ identification_type.name }}
          </option>
        </b-select>
      </b-field>

      <b-field  expanded label="Identification number">


        <b-input expanded
          v-model="profile.id_number"
          autocomplete="chrome-off"
          disabled
          placeholder="ID number"
        />

      </b-field>
    </div>
    <div v-if="document?.media?.tag==='Certified copy of National Senior Certificate or equivalent'">
      <p class="has-text-weight-bold ">School Subjects</p>

      <b-table
          :bordered="true"
          :data="user_subject_store"
          :header-checkable="false"
          :hoverable="true"
          :striped="true"
          class="margin-top"
        >
          <b-table-column v-slot="props"
                          label="Subject"

          >{{ props.row.subject ? props.row.subject.name : '' }}
          </b-table-column>
          <b-table-column v-slot="props"
                          label="Grade Achieved (%)"

          >
            <b-field>
              <b-numberinput v-model="props.row.grade" :controls="false" min-step="0.01" placeholder="Grade Achieved" required/>
            </b-field>
          </b-table-column>
        </b-table>
    </div>
    <ValidationProvider v-slot="{errors}" :rules="has_been_checked===1?'required':''">

      <b-field :message="errors.length>0?errors[0]:''" :type="errors.length>0?'is-danger':''"
               label="Verification Status">
        <b-select v-model="documentObject.verified" :disabled="!edit" placeholder="Is the document verified">
          <option :value="0">Not Verified</option>
          <option :value="1">Verified</option>
        </b-select>
      </b-field>
    </ValidationProvider>
    <ValidationProvider v-slot="{errors}" :rules="(documentObject.verified===0&&(has_been_checked===1))?'required':''">

      <b-field :message="errors.length>0?errors[0]:''" :type="errors.length>0?'is-danger':''"
               label="Verification Note">
        <b-input v-model="documentObject.verification_note" :disabled="!edit" :required="(documentObject.verified===0&&(has_been_checked===1))" type="textarea"/>
      </b-field>
    </ValidationProvider>

    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"/>
  </div>
</template>

<script>

import UserDocument from "@/models/UserDocument";
import {extend, ValidationProvider} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import PriorQualificationListItem from "@/components/Registrations/conditions/PriorQualificationListItem";
import Lookup from "@/models/Lookup";
import UserSubject from "@/models/UserSubject";

extend("required", {
  ...required,
  message: "This field is required",
});
export default {
  name: 'DocumentVerificationItem',
  components: {
    ValidationProvider,
    PriorQualificationListItem
  },
  props: {
    profile: {
      type: Object,
      default() {
        return Object
      }
    },
    has_been_checked: {
      type: Number,
      required: true
    }, document: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  computed: {
    user_subject_store() {
      return UserSubject.query().withAll().get()
    },
    identification_types() {
      return Lookup.query().where("lookup_type_id", 3).get();
    },
    viewerType() {
      if (this.iframeTypes.includes(this.document.media.extension)) {
        return 'iframe'
      }
      if (this.externalTypes.includes(this.document.media.extension)) {
        return 'external'
      }
      return 'image'
    },
  },
  methods: {
    submit() {
      return UserDocument.update({data: this.documentObject})
    }
  },
  created() {
    this.documentObject = JSON.parse(JSON.stringify(this.document))
    if (this.document?.media?.tag === 'Qualification Certificate') {
      Lookup.fetchAll({lookup_type_id: [9, 19]})
    }
    if (this.document?.media?.tag === 'Certified copy of ID or other proof of identity') {
      Lookup.fetchAll({lookup_type_id: [3]})
    }
    if (this.document?.media?.tag === 'Certified copy of National Senior Certificate or equivalent') {
      Lookup.fetchAll({lookup_type_id: [23]})
      UserSubject.FetchAll(1, 999, this.profile.user_id)

    }
  },
  data() {
    return {
      documentObject: {},
      loading: false,
      iframeTypes: ['pdf', 'txt'],
      externalTypes: ['doc', 'docx']
    };
  },


};
</script>
