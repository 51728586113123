<template>
  <section class="section">
    <title-bar>Intake Periods</title-bar>

    <b-table :bordered="true" :data="intakes" :hoverable="true" :striped="true" class="margin-top" scrollable>
      <template #empty>
        No Intake Periods loaded
      </template>
      <b-table-column v-slot="props" field="id" label="ID" numeric width="40">{{
          props.row.id
        }}
      </b-table-column>
      <b-table-column v-slot="props" label="Name" >{{ props.row.name }}</b-table-column>
      <b-table-column v-slot="props" label="Start Date" >{{ props.row.formatted_start_date }}</b-table-column>
      <b-table-column v-slot="props"  label="End Date" >{{ props.row.formatted_end_date }}</b-table-column>

      <b-table-column v-slot="props" width="120" label="Actions" centered custom-key="actions">
        <b-field>
          <span class="control">
          <b-button type="is-info" @click="viewIntake(props.row.id)">View</b-button>
          </span>
          <span class="control">
            <b-button
              v-if="!!$store.getters['entities/permissions/find']('delete courses')"
              type="is-danger"
              @click="startDelete(props.row.id)"
            >Delete
            </b-button>
          </span>
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      :current="meta.current_page"
      :per-page="meta.per_page"
      :range-after="2"
      :range-before="2"
      :total="meta.total"
      aria-current-label="Current page"
      aria-next-label="Next page"
      aria-page-label="Page"
      aria-previous-label="Previous page"
      class="margin-top"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import Intake from "@/models/Intake";
import TitleBar from "@/components/TitleBar";

export default {
  name: "IntakesIndex",
  components: {TitleBar},
  data() {
    return {
      intakesDataHold: [],
      loading: false,
      meta: Object,
      page: 1
    };
  },
  methods: {

    startDelete(intakeId) {
      this.$buefy.dialog.confirm({
        title: "Deleting intake",
        message:
          "Are you sure you want to <b>delete</b> this item? This action cannot be undone.",
        confirmText: "Delete intake",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.deleteIntake(intakeId)
      });

    },
    deleteIntake(intakeId) {
      this.$store.dispatch("loader/show");

      Intake.Delete(intakeId)
        .then(() => {
          this.$store.dispatch("loader/hide");

        })
        .catch(err => {
          this.handleError(err)
        });
    },
    viewIntake(id) {
      this.$router.push(`/intakes/edit/${id}`);
    },
    setPage(pageNumber) {
      this.loading = true;
      this.intakesDataHold = this.intakes
      Intake.deleteAll();
      this.page = pageNumber;
     return Intake.FetchAll(
        {
          page: 1,
          limit: 999,
        },
        this.filters,
      ).then(
        ({
           response: {
             data: {meta},
           }
         }) => {
          this.meta = meta;
          this.loading = false;
          this.intakesDataHold = null;
        }
      );
    },

  },

  computed: {
    intakes() {
      return Intake.query().get().sort((a,b) => b.id - a.id);
    },
  },
  async mounted() {
    await this.$store.dispatch("loader/show");
    await this.setPage(1)
    await this.$store.dispatch("loader/hide");

  }
};
</script>
