import {TransformModel} from '@/models/TransformModel'
import Lookup from "@/models/Lookup";

export default class UserLanguage extends TransformModel {
  static entity = 'user-languages'

  static fields() {
    return {
      id: this.attr(null),
      user_id:this.attr(null),
      language_id: this.attr(null),
      language: this.belongsTo(Lookup, 'id'),
      acquired_type_id: this.attr(null),
      grade: this.attr(null),
      code: this.attr(null),
      institution: this.attr(null),
    }
  }

  static FetchAll(page = 1, limit = 999, learner_id) {
    return this.api().get(`/learners/${learner_id}/languages`, {
      params: {
        page: page,
        limit: limit

      },

      dataTransformer: ({data: {data}}) => {
        return data.map(language => {
          if (language.grade !== null) {
            language.grade = parseFloat(language.grade)
          }

          return language
        })
      },
    })
  }

  static Update(user_languages, learner_id) {
    return this.api().patch(`/learners/${learner_id}/languages`, user_languages, {

      dataTransformer: (response) => {
        return response.data.data
      }
    })
  }


}
