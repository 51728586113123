<template>
  <div class="listitem">
    <article class="media">
      <figure class="media-left">
        <p class="has-text-centered">
          <b-tooltip :label="'Email ' + email_direction" position="is-top">
            <b-icon
              class="icon rounded is-large box is-64x64"
              :icon="icon"
            ></b-icon>
          </b-tooltip>
        </p>
      </figure>
      <div class="box media-content is-relative">
        <div class="content">
          <h3 style="max-width: 80%" class="is-capitalized" v-html="email.subject"></h3>
          <div class="listitem-button-group">
            <b-tooltip v-if="email_direction!=='Sent'" :label="email.is_read===0?'Mark as Read':'Mark as Unread'" position="is-top">
              <b-button icon-left="check-box-outline" @click="toggleRead()">
              </b-button>

            </b-tooltip>
            <b-button v-if="can_reply" icon-left="reply" @click="replyEmail(email)"></b-button>
            <b-button
              :icon-left="`unfold-${expanded?'less':'more'}-horizontal`"
              @click="toggleExpand()"
              :disabled="force_expanded"
            ></b-button>
          </div>

          <p
            class="subtitle is-7"
          >From {{ email.from_name }} @ {{ (new Date(email.created_at).toLocaleString()) }}</p>

          <ShadowDom v-if="(expanded==true || force_expanded)&&email.is_html!==0" class="content" :html-content="email.body"></ShadowDom>
          <div v-else-if="(expanded==true || force_expanded)&&email.is_html===0" class="content">{{email.text}}</div>
          <ShadowDom v-else-if="email.is_html!==0" class="content" :html-content="clipEmail(email.body)"></ShadowDom>
          <div v-else class="content">
            {{clipEmail(email.text,false)}}
          </div>
          <div v-if="email.media.length>0" class="upload-file-list column">
            <p>Attachments:</p>
            <div class="notification">
              <div class="tags">
                <b-button
                  icon-right="download"
                  tag="a"
                  v-for="(file, index) in email.media"
                  :key="index"
                  :href="file.temporary_url"
                  target="_blank"
                  class="tag is-primary"
                >{{ file.filename }}
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>

    <hr/>
  </div>
</template>

<script>
import clip from "text-clipper";
import Email from "@/models/Email";
import ShadowDom from "@/components/global/ShadowDom.vue";

export default {
  name: "EmailListItem",
  components: {
    ShadowDom,
  },
  props: {
    permissions: {
      type: Object,
      default: () => ({
        can_delete: false,
        can_edit: false,
        can_complete: false,
      }),
    },
    email_direction: {
      type: String,
      default() {
        return "Sent";
      },
    },
    can_reply: {
      type: Boolean,
      default() {
        return true;
      },
    },
    force_expanded: {
      type: Boolean,
      default() {
        return false;
      },
    },

    email: {
      type: Object,
      default: () => Object,
    },
  },
  computed: {
    icon() {
      let icon = ''
      icon = this.email.was_received === 0 ? 'email-send' : icon = this.email.is_read === 0 ? 'email-receive' : 'email-receive-outline';
      return icon
    }

  },
  data() {
    return {
      expanded: false,
      loadingEmail: false,
    };
  },

  methods: {
    toggleRead() {
      Email.toggleRead(this.email.id)
      if (this.email.is_read === 0) {
        Email.update({
          where: this.email.id,
          data: {
            is_read: 1
          }
        })
      } else {
        Email.update({
          where: this.email.id,
          data: {
            is_read: 0
          }
        })
      }
    },
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    replyEmail(email) {
      this.$emit("reply", email);
    },
    clipEmail(text,is_html=true) {
      return clip(text, 100, {
        html: is_html,
      });
    },
  },
};
</script>
