<template>
  <section class="section">
    <title-bar>Create a new Role</title-bar>
    <card-component title="Role Form" :icon="$tc('icons.roles')">
      <form @submit.prevent="submit">
        <b-field label="Name" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              v-model="role.name"
              placeholder="Name"
              name="name"
              required
            />
          </b-field>
        </b-field>
        <b-field horizontal label="Permission search">
          <b-input v-model="search" placeholder="Search by name" name="search" />
        </b-field>
        <b-field>
          <b-field label="Permissions" horizontal>
            <b-table
              :header-checkable="false"
              class="margin-top"
              :data="processedPermissions"
              :striped="true"
              :hoverable="true"
              :bordered="true"
            >
              <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{ props.row.id }}</b-table-column>

              <b-table-column v-slot="props" sortable field="attributes.name" label="Name">{{ props.row.name }}</b-table-column>

              <b-table-column v-slot="props"
                width="120"
                class="has-text-centered"
                label="Active"
                custom-key="actions"
              >
                <b-checkbox v-model="role.permissions" :native-value="props.row.id"></b-checkbox>
              </b-table-column>
            </b-table>
          </b-field>
        </b-field>
        <hr />
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";
import Role from "@/models/Role";
import Permission from "@/models/Permission";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      search: "",
      role: {
        name: "",
        permissions: [],
      },
      permissions: [],
    };
  },

  computed: {
    processedPermissions() {
      let store = [];
      this.permissions.forEach((permission) => {
        permission.name = permission.attributes.name;
        store.push(permission);
      });
      return store.filter((permission) =>
        permission.name.includes(this.search)
      );
    },
  },
  methods: {
    getFilteredSubject(text) {
      this.subjectSearchTerm = text;
    },
    submit() {
      this.$store.dispatch("loader/show");
      Role.newRole(this.role)
        .then(() => {
          this.$store.dispatch("loader/hide");
          this.$buefy.snackbar.open({
            message: "Role created",
            queue: false,
            type: "is-link",
          });
          setTimeout(() => {
            this.$router.push(`/roles`);
          }, 1000);
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data.message),
          });
        });
    },
  },
  mounted() {
    Permission.fetchAll()
      .then(({ response }) => {
        this.permissions = response.data.data;
      })
      .catch((err) => {
        this.$store.dispatch("toast/createToast", {
          message: JSON.stringify(err.response.data.message),
        });
      });
  },
};
</script>
