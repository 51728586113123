<template>
  <section class="section">
    <title-bar>Create a new Module</title-bar>
    <card-component title="Module Form" :icon="$tc('icons.courses')">
      <form @submit.prevent="submit">
        <b-field label="Name" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              v-model="module.name"
              placeholder="Name"
              name="name"
              required
            />
          </b-field>
        </b-field>

        <b-field label="Code" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              v-model="module_code"
              placeholder="Code"
              name="code"
              required
            />
          </b-field>
        </b-field>
        <b-field label="NQF Level" horizontal>
          <b-field>
            <b-input
              required
              type="number"
              autocomplete="off"
              v-model="nqf_level"
              placeholder="NQF Level"
              name="nqf_level"
            />
          </b-field>
        </b-field>
        <b-field label="Level" horizontal>
          <b-field>
            <b-input
              required
              type="number"
              autocomplete="off"
              v-model="level"
              placeholder="Level"
              name="level"
            />
          </b-field>
        </b-field>
        <b-field label="LMS Code" horizontal>
          <b-field>
            <b-input
              autocomplete="off"
              v-model="module.lms_code"
              placeholder="LMS Code"
              name="lms_code"
            />
          </b-field>
        </b-field>

        <b-field label="Credits" horizontal>
          <b-field>
            <b-input
              required
              type="number"
              autocomplete="off"
              v-model="credits"
              placeholder="Credits"
              name="credits"
            />
          </b-field>
        </b-field>
        <b-field label="Subject" horizontal>
          <b-field>
            <b-select required v-model="module.subject_id" placeholder="Select a Subject">
              <option
                v-for="subject in subjects"
                :key="subject.id"
                :value="subject.id"
              >{{subject.name}}</option>
            </b-select>
          </b-field>
        </b-field>
        <b-field label="Standardised Level" horizontal>
          <b-field>
            <b-select required v-model="module.standardised_level_id" placeholder="Select a Level">
              <option
                v-for="level in $store.state.levels.levels"
                :key="level.id"
                :value="level.id"
              >{{ level.name }}
              </option>
            </b-select>
          </b-field>
      </b-field>
        <b-field label="Semesters" horizontal>
          <b-dropdown v-model="module.semesters" multiple aria-role="list">
            <button class="button is-primary" type="button" slot="trigger">
              <span>Select Semesters</span>

              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="semester in semesters"
              :key="semester.id"
              :value="semester.id"
              aria-role="listitem"
            >
              <span>{{semester.name}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <!-- <b-field label="Subject" horizontal>
          <b-field>
            <b-autocomplete
              @typing="getFilteredSubject"
              :data="subjects"
              icon="account"
              autocomplete="off"
              v-model="selectedSubject"
              placeholder="Subject"
              name="subject"
              required
              field="name"
              @select="option => this.module.subject_id = parseInt(option.id)"
              :clear-on-select="false"
            >
              <template slot-scope="props">
                <p>{{props.option.name}}</p>
              </template>
            </b-autocomplete>
          </b-field>
        </b-field>-->
        <hr />
        <b-field horizontal>
          <b-field grouped>
            <div class="control">
              <b-button native-type="submit" type="is-primary">Submit</b-button>
            </div>
          </b-field>
        </b-field>
        <b-loading :is-full-page="false" :active.sync="updatingModule" :can-cancel="false"></b-loading>
      </form>
    </card-component>
  </section>
</template>

<script>
import TitleBar from "@/components/TitleBar";
import CardComponent from "@/components/CardComponent";

import Module from "@/models/Module";
import Lookup from "@/models/Lookup";
import Semester from "@/models/Semester";

export default {
  components: {
    CardComponent,
    TitleBar,
  },
  data() {
    return {
      updatingModule: false,
      subjectSearchTerm: "",
      selectedSubject: null,
      module: {
        name: "",
        code: "",
        nqf: null,
        level: null,
        lms_code: null,
        semesters: [],
        credits: null,
        subject_id: null,
        standardised_level_id: null
      },
    };
  },

  computed: {
    semesters() {
      return Semester.query().get();
    },
    credits: {
      get() {
        return parseInt(this.module.credits);
      },
      set(newVal) {
        this.module.credits = newVal;
      },
    },
    level: {
      get() {
        return parseInt(this.module.level);
      },
      set(newVal) {
        this.module.level = newVal;
      },
    },
    module_code: {
      get() {
        return this.module.code;
      },
      set(newVal) {
        this.module.code = newVal;
      },
    },
    nqf_level: {
      get() {
        return parseInt(this.module.nqf);
      },
      set(newVal) {
        this.module.nqf = newVal;
      },
    },
    subjects() {
      return Lookup.query().where("lookup_type_id", 6).get();
    },
  },
  methods: {
    getFilteredSubject(text) {
      this.subjectSearchTerm = text;
    },
    submit() {
      this.$store.dispatch("loader/show");
      Module.newModule(this.module)
        .then((result) => {
          if (this.module.semesters.length > 0) {
            this.module.semesters.forEach((element) => {
              Module.toggleSemester(
                result.response.data.data.id,
                element
              ).catch((err) => {
                this.$store.dispatch("toast/createToast", {
                  message: JSON.stringify(err.response.data),
                });
              });
            });
          }
          setTimeout(() => {
            this.$store.dispatch("loader/hide");
            this.$router.push(`/modules/edit/${result.response.data.data.id}`);
          }, 1000);

          this.$buefy.snackbar.open({
            message: "Module Created!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },
  mounted() {
    Semester.FetchAll();

    Lookup.fetchAll();
  },
};
</script>
