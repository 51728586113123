<template>
  <div class="core-option-list-item p-2 pb-4">
    <b-field group-multiline grouped>
      <b-field expanded label="Start Year">
        <b-select v-model="study.from_year" :disabled="view_mode" :required="!view_mode" expanded name="from_year" placeholder="From Year">
          <option v-for="year in years" :key="year+'start'+index" :value="year">{{ year }}</option>
        </b-select>

      </b-field>
      <b-field expanded label="End Year">
        <b-select v-model="study.to_year" :disabled="view_mode" :required="!view_mode" expanded name="to_year" placeholder="End Year">
          <option v-for="year in years" :key="year+'start'+index" :value="year">{{ year }}</option>
        </b-select>

      </b-field>

      <b-field expanded label="Name of Institution">
        <b-select v-model="study.institution_name"
                  :disabled="view_mode"
                  :required="!view_mode"
                  autocomplete="chrome-off"
                  expanded
                  name="institution_name" placeholder="Name"
        >
          <option
            v-for="institution in institutions"
            :key="institution.id"
            :value="institution.name"
          >{{ institution.name }}
          </option>
        </b-select>
      </b-field>

      <b-field expanded label="Name of degree/diploma/certificate">
        <b-select v-model="study.qualification_name"
                  :disabled="view_mode"
                  :required="!view_mode"
                  autocomplete="chrome-off"
                  expanded
                  name="qualification_name" placeholder="Qualification"
        >
          <option
            v-for="qualification in qualifications"
            :key="qualification.id"
            :value="qualification.name"
          >{{ qualification.name }}
          </option>
        </b-select>
      </b-field>

      <b-field expanded label="Study complete?">
        <b-select v-model="study.is_complete"
                  :disabled="view_mode"
                  :required="!view_mode"
        >
          <option :value="true">Yes</option>
          <option :value="false">No</option>
        </b-select>
       </b-field>

      <b-field expanded label="Type of Institution">

        <b-select v-model="study.type_of_institution"
                  :disabled="view_mode"
                  :required="!view_mode" expanded icon="flag" placeholder="Type of institution">
          <option v-for="type in $store.state.profile.school_types" :value="type">{{ type }}
          </option>
        </b-select>
      </b-field>

<!--      <b-field expanded label="SAQA verification">-->
<!--        <b-select v-model="study.saqa_verification"-->
<!--                  :disabled="view_mode" :required="!view_mode && study.type_of_institution!=='Public'"-->
<!--                  expanded-->
<!--                  placeholder="SAQA Verification Status"-->
<!--        >-->
<!--          <option-->
<!--            v-for="status in $store.state.profile.saqa_statuses"-->
<!--            :value="status"-->
<!--          >{{ status }}-->
<!--          </option>-->
<!--        </b-select>-->
<!--      </b-field>-->

      <b-field v-if="!view_mode" label="Remove Qualification">
        <p class="control">
          <b-button

            icon-right="delete"
            type="is-danger"
            @click.prevent="startRemoveStudy"
          >Remove
          </b-button>
        </p>
      </b-field>
    </b-field>
  </div>

</template>

<script>

import Lookup from "@/models/Lookup";

export default {
  name: "PriorQualificationListItem",
  props: {
    study: {
      type: Object,
      required: true
    },
    view_mode: {
      type: Boolean,
      default() {
        return false;
      }
    },
    index: {
      type: Number,
      default() {
        return 0
      }
    }
  },
  methods:{
    removeStudy(){
      this.$emit('remove')
    },
    startRemoveStudy() {
      this.$buefy.dialog.confirm({
        title: `Removing Prior Qualification`,
        confirmText: `Remove Prior Qualification`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to remove this prior qualification?`,
        onConfirm: () => {
          this.removeStudy()
        }
      })
    },
  },
  computed: {
    years() {
      let years = [];
      for (let i = -3; i < 50; i++) {
        years.push(new Date().getFullYear() - i);
      }
      return years;
    },
    institutions() {

      return Lookup.query().where("lookup_type_id", 9).orderBy('name').get();
    },
    qualifications() {

      return Lookup.query().where("lookup_type_id", 19).orderBy('name').get();
    },


  },
}
</script>
