import moment from 'moment'
export const SemestersModule = {
  namespaced: true,

  state: {
    semester_1_start: "2022-08-22",
    semester_1_end: "2023-02-28"
  },
  getters: {
    available_semesters: state => {
      if (moment().isBetween(state.semester_1_start, state.semester_1_end, undefined, '[]')) {
        return [1, 2]
      }
      return [2]
    }
  }
};
