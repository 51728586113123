import {
  TransformModel
} from '@/models/TransformModel'
import User from '@/models/User'


export default class Note extends TransformModel {
  static entity = 'notes'

  static fields() {
    return {
      type: this.attr('notes'),
      id: this.attr(null),
      note: this.attr(''),
      user_id: this.attr(''),
      enquiry_id: this.attr(''),
      user: this.belongsTo(User, 'user_id'),
      created_at: this.attr(''),
      updated_at: this.attr('')
    }
  }
  static newNote(note) {
    return this.api().post(`/enquiries/${note.enquiry_id}/notes`, note, {
      dataTransformer: (response) => {
        return this.transformArray(response)

      }
    })
  }
  static deleteRemote(note) {
    return this.api().delete(`/enquiries/${note.enquiry_id}/notes/${note.id}`, {
      save: false
    })
  }
  static updateRemote(note) {
    return this.api().patch(`/enquiries/${note.enquiry_id}/notes/${note.id}`, {
      note: note.note
    }, {
      save: false
    })
  }
  static fetchAllByEnquiry(enquiry_id) {
    return this.api().get(`/enquiries/${enquiry_id}/notes?with[]=user`, {
      dataTransformer: (response) => {

        return this.transformArrayWithUser(response)
      }
    })
  }



}
