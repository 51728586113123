import {
  Model
} from '@vuex-orm/core'

export default class courseRelatedCourse extends Model {
  static entity = 'courseRelatedCourse'

  static primaryKey = ['related_course_id', 'course_id']


  static fields() {
    return {
      related_course_id: this.attr(null),
      course_id: this.attr(null)
    }
  }


}
