<template>
  <section class="section">
    <title-bar>Users</title-bar>

    <div class="columns">
      <div class="column">
        <b-field grouped>
          <b-field label="Filter" horizontal>
            <b-input
              v-debounce:300ms="getFilteredUser"
              placeholder="Type any parameter here"
              :loading="loadingFilter"
              v-model="filter"
            ></b-input>
          </b-field>
          <b-field>
            <b-select v-model="roleFilter" placeholder="Role">
              <option :value="null">None</option>
              <option v-for="role in roles" :key="role.id" :value="role.name">{{role.name}}</option>
            </b-select>
          </b-field>
        </b-field>
      </div>
    </div>
    <b-table class="margin-top" :data="users" :striped="true" :hoverable="true" :bordered="true">
      <b-table-column v-slot="props" label="ID" field="id" sortable width="40" numeric>{{ props.row.id }}</b-table-column>

      <b-table-column v-slot="props" sortable field="first_name" label="First Name">{{ props.row.first_name }}</b-table-column>

      <b-table-column v-slot="props" sortable field="last_name" label="Last Name">{{ props.row.last_name }}</b-table-column>
      <b-table-column v-slot="props" sortable field="email" label="Email">{{ props.row.email }}</b-table-column>
      <b-table-column v-slot="props" class="is-capitalized" label="Roles">{{ displayRoles(props.row.roles) }}</b-table-column>
      <b-table-column v-slot="props" width="80" custom-key="actions">
        <b-field grouped>
          <b-field>
            <b-button tag="router-link" :to="`/users/edit/${props.row.id}`" type="is-info">View</b-button>
          </b-field>
          <!--            <b-field v-if="!!$store.getters[`entities/permissions/find`]('delete users')">-->
          <!--              <b-button @click="start_delete(props.row.id)"-->
          <!--                        :disabled="!$store.getters[`entities/permissions/find`]('delete users')" type="is-danger">-->
          <!--                Delete-->
          <!--              </b-button>-->
          <!--            </b-field>-->
        </b-field>
      </b-table-column>
    </b-table>
    <b-pagination
      class="margin-top"
      :total="meta.total"
      :current="page"
      :range-before="2"
      :range-after="2"
      :per-page="limit"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      v-on:change="setPage"
    ></b-pagination>
  </section>
</template>

<script>
import User from "@/models/User";
import TitleBar from "@/components/TitleBar";
import Role from "@/models/Role";

export default {
  name: "Users",
  components: { TitleBar },
  data() {
    return {
      filter: "",
      loadingFilter: false,
      users: [],
      meta: Object,
      page: 1,
      limit: 15,
      roleFilterHold: null,
    };
  },
  methods: {
    start_delete(user_id) {
      this.$buefy.dialog.confirm({
        title: "Deleting User",
        message:
          "Are you sure you want to delete this user? This action cannot be undone.",
        confirmText: "Delete user",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => this.confirm_delete(user_id),
      });
    },
    confirm_delete(user_id) {
      this.$store.dispatch("loader/show");
      User.deleteRemote(user_id)
        .then(() => {
          this.$store.dispatch("loader/hide");

          this.$buefy.snackbar.open({
            message: "User deleted!",
            queue: false,
            type: "is-link",
          });
        })
        .catch((err) => {
          this.$store.dispatch("loader/hide");

          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    displayRoles(roles) {
      let blank = "";
      roles.forEach((element, index) => {
        blank += Role.query().whereId(element.id).get()[0].name;
        if (index < roles.length - 1) {
          blank += ", ";
        }
      });
      return blank;
    },

    getFilteredUser(text) {
      this.loadingFilter = true;

      User.searchWithRole(text, true, this.page, 15, this.roleFilter).then(
        (result) => {
          this.loadingFilter = false;

          this.users = result.response.data.data;

          this.meta = result.response.data.meta;
          this.$cookie.set(
            "user_filters",
            JSON.stringify({
              page: this.page,
              filter: this.filter,
              role: this.roleFilter,
            }),
            "1d"
          );
        }
      );
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      if (this.filter.length > 0 || this.roleFilterHold != null) {
        User.searchWithRole(
          this.filter,
          true,
          pageNumber,
          this.limit,
          this.roleFilter
        ).then((result) => {
          this.users = result.response.data.data;
          this.meta = result.response.data.meta;
          this.$store.dispatch("loader/hide");
          this.page = pageNumber;
        });
      } else {
        this.getUsers(pageNumber, this.limit).then(() => {
          this.$store.dispatch("loader/hide");
          this.page = pageNumber;
        });
      }

      this.$cookie.set(
        "user_filters",
        JSON.stringify({
          page: pageNumber,
          filter: this.filter,
          role: this.roleFilter,
        }),
        "1d"
      );
    },
    getUsers(page, limit) {
      return User.fetchAll(null, null, page, limit, ["roles"]).then(
        (result) => {
          this.users = result.response.data.data;

          this.meta = result.response.data.meta;
        }
      );
    },
  },

  computed: {
    roleFilter: {
      set(newValue) {
        this.roleFilterHold = newValue;
        this.setPage(this.page);
      },
      get() {
        return this.roleFilterHold;
      },
    },
    roles() {
      return Role.query().get();
    },
    edit_users() {
      return this.$store.getters["entities/permissions/find"]("edit users");
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");
    if (this.$cookie.get("user_filters")) {
      let filters = JSON.parse(this.$cookie.get("user_filters"));
      this.roleFilterHold = filters.role;
      this.page = filters.page;
      this.filter = filters.filter;
    }
    Role.fetchAll().then(() => {
      this.setPage(this.page);
    });
  },
};
</script>
