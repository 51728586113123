<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form class="is-relative" @submit.prevent="submit">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} {{ $tc('Course', 1) }}</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">Required Documents</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <p class="pb-2 is-size-5">These documents are required as part of the supporting documents for the application/registration of this programme.
          </p>
          <b-field v-for="(document,index) in required_documents" :key="document.id" :label="document.name">
            <SingleTagUpload :verifications="document_verifications" :title="''" required :can-create="true" :has-caption="false" :has-tag="false" :ref="'upload'+index" :submit_button="false" :submit_flip="submitMedia" :tag="document.name" :model="'users'" :model_id="parseInt($store.state.userId)" :fetch-on-mount="false" />
          </b-field>

        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button type="is-secondary" @click="submit(false)">Save and Continue Later</b-button>
              </p>
              <p class="control">
                <b-button :disabled="invalid" :loading="loading" native-type="submit"
                          type="is-primary">Next
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver} from "vee-validate";
import {required} from "vee-validate/dist/rules";
import Media from "@/models/Media";
import CourseDocuments from "@/models/CourseDocument";
import SingleTagUpload from "@/components/media/SingleTagUpload";
import Registration from "@/models/Registration";

extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  name: "RequiredUploads",
  components: {
    SingleTagUpload,
    TitleBar,
    ValidationObserver,
  },
  data() {
    return {
      loading: false,
      courseHold: null,
      filteredCourses: [],
      exemption_type: null,
      user_subjects: [],
      submitMedia: false,
      document_verifications:[]

    }
  },
  methods: {

    async submit(goNext = true) {
      await this.$store.dispatch('loader/show')
      let promises=[]
      this.required_documents.map((document,index)=>{
        let string='upload' +index

        promises.push(this.$refs[string][0].submitMedia())
      })
      Promise.all(promises).then(()=>{
        if (goNext) {
          this.$emit('next', 'uploads')
        }
        this.$store.dispatch('loader/hide')
      })



    }
  },

  computed: {
    required_documents() {
      return CourseDocuments.query().get()
    },
    media() {
      return Media.query().get()
    },
  },
  async mounted() {
    this.loading = true
    await Promise.all([Media.fetchAll(parseInt(this.$store.state.userId), 'users'),
    Registration.checkStatus(this.registration_id).then(({response: {data:conditions}})=>{
        if (Object.prototype.hasOwnProperty.call(conditions.conditions,'documents')) {
        this.document_verifications = conditions.conditions.documents
      }
    })])
    this.loading = false
  },
  props: {

    id: {
      type: String,
      default() {
        return null
      }
    },registration_id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    course: {
      type: Object,
      required: true
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

