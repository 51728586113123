<template>
  <card-component title="Password reset" icon="lock-reset">
    <form @submit.prevent="submit">
      <div class="section">
        <div class="container">
          <div class="columns is-centered">
            <div class="column">
              <p>Please enter a new password below. It must be a minimum of 8 characters</p>
              <ValidationObserver ref="observer">
                <b-field label="Password">
                  <ValidationProvider rules="required|min|password:@confirm" v-slot="{ errors }">
                    <b-input type="password" v-model="password" name="password" />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>

                <b-field label="Confirm password">
                  <ValidationProvider rules="required" name="confirm" v-slot="{ errors }">
                    <b-input :loading="isLoading" type="password" v-model="confirmPassword" />
                    <span>{{ errors[0] }}</span>
                  </ValidationProvider>
                </b-field>
              </ValidationObserver>
              <div class="columns is-centered" style="margin-top:1rem;">
                <div class="column">
                  <b-button
                    :loading="isLoading"
                    @click.prevent="submit"
                    expanded
                    type="is-primary"
                  >Submit</b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import { AuthClient } from "@/clients/AuthClient";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});
extend("password", {
  params: ["target"],
  validate(value, { target }) {
    return value === target;
  },
  message: "Password confirmation does not match",
});
extend("min", (value) => {
  if (value.length >= 8) {
    return true;
  }
  return "The password must be at least 8 characters";
});
export default {
  props: ["token"],
  components: { CardComponent, ValidationProvider, ValidationObserver },
  data() {
    return {
      isLoading: false,
      password: null,
      confirmPassword: null,
    };
  },
  methods: {
    submit() {
      this.$refs.observer.validate().then((valid) => {
        if (!valid) {
          return;
        } else {
          this.isLoading = true;

          AuthClient.setPassword({
            password: this.password,
            password_confirmation: this.confirmPassword,
            token: this.token,
          })
            .then(() => {
              this.isLoading = false;
              this.$router.push("/login");
            })
            .catch((err) => {
              this.isLoading = false;

              this.$store.dispatch("toast/createToast", {
                message: JSON.stringify(err.response.data),
              });
            });
        }
      });
    },
    // submit() {
    // }
  },
};
</script>

<style scoped>
</style>
