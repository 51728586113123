import {TransformModel} from '@/models/TransformModel'
import User from '@/models/User'


export default class Activity extends TransformModel {
  static entity = 'activities'

  static fields() {
    return {
      type: this.attr('tasks'),
      id: this.attr(null),
      name: this.attr(''),
      type_id: this.attr(null),
      note: this.attr(''),
      completed_user_id: this.attr(''),
      completed_user: this.belongsTo(User, 'completed_user_id'),
      creator_id: this.attr(''),
      creator: this.belongsTo(User, 'creator_id'),
      assigned_to_user: this.belongsTo(User, 'assigned_to_user_id'),
      assigned_to_user_id: this.attr(''),
      completed_at: this.attr(''),
      is_complete: this.attr(null),
      taskable_id: this.attr(null),
      taskable_type: this.attr(null),
      due_at: this.attr(''),
      created_at: this.attr(null)
    }
  }

  static newActivity(model_type, model_id, activity) {
    return this.api().post(`/${model_type}/${model_id}/tasks`, activity, {
      save: true,
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        return target
      }
    })
  }

  static deleteRemote(model_type, model_id, activity_id) {
    return this.api().delete(`/${model_type}/${model_id}/tasks/${activity_id}`, {
      save: false
    })
  }

  static toggleComplete(model_type, model_id, activity_id) {
    return this.api().patch(`/${model_type}/${model_id}/tasks/${activity_id}/toggle-complete`, activity_id, {
      dataTransformer: (response) => {
        let target = response.data.data
        return Object.assign(target, target.attributes)

      },
    })
  }

  static fetchById(model_type, model_id, activity_id) {
    return this.api().get(`/${model_type}/${model_id}/tasks/${activity_id}`, {
      dataTransformer: (response) => {
        return response
      }
    })
  }

  static updateRemote(model_type, model_id, activity) {
    return this.api().put(`/${model_type}/${model_id}/tasks/${activity.id}`, activity, {
      dataTransformer: (response) => {
        let target = response.data.data
        Object.assign(target, target.attributes)
        return target
      }
    })
  }

  static fetchAll(model_type, model_id, search = null, name = null, note = null, task_type_id = null, creator_id = null, completed_user_id = null, assigned_to_user_id = null) {
    return this.api().get(`/${model_type}/${model_id}/tasks?with[]=creator&with[]=assignedToUser&with[]=completedUser&limit=50`, {
      params: {
        ...(search ? {
          search: search
        } : {}),
        ...(name ? {
          name: name
        } : {}),
        ...(note ? {
          note: note
        } : {}),
        ...(task_type_id ? {
          task_type: task_type_id
        } : {}),
        ...(creator_id ? {
          creator: creator_id
        } : {}),
        ...(completed_user_id ? {
          completed_user: completed_user_id
        } : {}),
        ...(assigned_to_user_id ? {
          assigned_to_user: assigned_to_user_id
        } : {})
      },
      dataTransformer: (response) => {

        let storageArray = []

        response.data.data.forEach(element => {
          if (element.attributes.creator_id !== null) {
            if (element.attributes.creator !== null) {
              element.attributes.creator = {
                ...element.attributes.creator,
                ...element.attributes.creator.attributes
              }
            }
          }
          if (element.attributes.assigned_to_user_id !== null) {
            if (element.attributes.assigned_to_user !== null) {
              element.attributes.assigned_to_user = {
                ...element.attributes.assigned_to_user,
                ...element.attributes.assigned_to_user.attributes
              }
            }
          }
          if (element.attributes.completed_user_id !== null) {
            if (element.attributes.completed_user !== null) {
              element.attributes.completed_user = {
                ...element.attributes.completed_user,
                ...element.attributes.completed_user.attributes
              }
            }
          }
          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)

        });

        return storageArray

      }
    })
  }

  static fetchWithoutModel(query, page = 1, limit = 100) {
    return this.api().get(`/tasks?with[]=creator&with[]=assignedToUser&with[]=completedUser`, {
      params: {
        ...(query.search ? {
          search: query.search
        } : {}),
        ...(query.model_type ? {
          model: query.model_type
        } : {}),
        ...(query.name ? {
          name: query.name
        } : {}),
        ...(query.note ? {
          note: query.note
        } : {}),
        ...(query.task_type_id ? {
          task_type: query.task_type_id
        } : {}),
        ...(query.order_by ? {
          order_by: query.order_by
        } : {}),
        ...(query.order_direction ? {
          order_direction: query.order_direction
        } : {}),
        ...(query.creator_id ? {
          creator: query.creator_id
        } : {}),
        ...(query.completed_user_id ? {
          completed_user: query.completed_user_id
        } : {}),
        ...(query.assigned_to_user_id ? {
          assigned_to_user: query.assigned_to_user_id
        } : {}),
        ...(query.is_complete != null ? {
          isComplete: query.is_complete
        } : {}),
        ...(page ? {
          page: page
        } : {}),
        ...(limit ? {
          limit: limit
        } : {})
      },
      dataTransformer: (response) => {

        let storageArray = []

        response.data.data.forEach(element => {
          if (element.attributes.creator_id !== null) {
            if (element.attributes.creator !== null) {
              element.attributes.creator = {
                ...element.attributes.creator,
                ...element.attributes.creator.attributes
              }
            }
          }
          if (element.attributes.assigned_to_user_id !== null) {
            if (element.attributes.assigned_to_user !== null) {
              element.attributes.assigned_to_user = {
                ...element.attributes.assigned_to_user,
                ...element.attributes.assigned_to_user.attributes
              }
            }
          }
          if (element.attributes.completed_user_id !== null) {
            if (element.attributes.completed_user !== null) {
              element.attributes.completed_user = {
                ...element.attributes.completed_user,
                ...element.attributes.completed_user.attributes
              }
            }
          }
          element = {
            ...element,
            ...element.attributes
          }
          storageArray.push(element)

        });

        return storageArray

      }
    })
  }


}
