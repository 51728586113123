<template>
  <section class="columns">
    <div class="column is-one-fifth reports-list has-background-white">
      <hr/>
      <b-collapse
        class="card"
        v-for="(collapse, index) of collapses"
        :key="index"
        :open="isOpen == index"
      >
        <div slot="trigger" slot-scope="props" class="card-header" role="button">
          <p class="card-header-title">{{ collapse.title }}</p>
          <a class="card-header-icon">
            <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"></b-icon>
          </a>
        </div>
        <div v-for="list in collapse.lists" :key="list.title" class="card-content">
          <div class="content">
            <a @click="viewType = list.viewType; ">{{ list.title }}</a>
          </div>
        </div>
      </b-collapse>
    </div>
    <card-component :title="emailTitle" class="column" icon="email-multiple">
      <b-field grouped>
        <b-field expanded>
          <b-autocomplete
            @typing="getFilteredLearner"
            :data="learnerSearchResponse"
            autocomplete="off"
            v-model="learnerSearchTerm"
            :placeholder="$tc('Learner',1)"
            @select="option => selectLearner(option)"
            :clear-on-select="false"
            :custom-formatter="thing=> learnerName(thing)"
          >
            <template slot-scope="props">
              <p>{{ props.option.first_name }} {{ props.option.last_name }} | {{ props.option.email }}</p>
            </template>
          </b-autocomplete>
        </b-field>
      </b-field>
      <b-modal
        :active.sync="isReplyActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="true"
        aria-role="dialog"
        aria-modal
        custom-class="inbox_view"
      >
        <card-component>
          <emailForm
            :inModal="true"
            :username_missing="$store.state.userEmail==null"
            :permissions="{can_create:true}"
            :reply_email="reply_email"
            :send_to_email="learner.id===null"
            :default_recipient="learner"
            @cancel="isReplyActive = false"
            @sent="setPage(page)"
            :fetch_on_send="true"
          ></emailForm>
        </card-component>
      </b-modal>
      <b-modal
        :active.sync="isEmailActive"
        has-modal-card
        trap-focus
        :destroy-on-hide="false"
        aria-role="dialog"
        aria-modal
        custom-class="inbox_view"
      >
        <emailListItem
          @reply="reply"
          :can_reply="activeEmail.from_user_id!==null?(activeEmail.from_user_id != $store.state.userId):(activeEmail.from_user_email!= $store.state.userEmail)"
          :email_direction="activeEmail.was_received===0?'Sent':'Received'"
          :email="activeEmail"
          :force-expanded="true"
        ></emailListItem>
      </b-modal>

      <b-table
        ref="table"
        class="margin-top"
        :data="emails"
        :striped="true"
        :hoverable="true"
        :bordered="true"
        narrowed
        @select="openEmail"
      >
        <b-table-column v-slot="props">
          <b-icon
            class="icon rounded is-medium is-32x32"
            :icon="props.row.was_received === 0 ? 'email-send' : props.row.is_read === 0 ? 'email-receive' : 'email-receive-outline'"
          ></b-icon>
        </b-table-column>
        <b-table-column v-slot="props" label="Subject" field="subject" sortable>
          <p>
            {{ props.row.subject }}
            <b-icon
              v-if="props.row.media.length>0"
              class="icon rounded is-pulled-right is-medium is-32x32"
              icon="paperclip"
            ></b-icon>
          </p>
        </b-table-column>
        <b-table-column v-slot="props" label="From" field="from_email" sortable>
          <p> {{props.row.from_name}} ({{props.row.from_email}})</p>
        </b-table-column>
        <b-table-column v-slot="props" label="To" field="to_email" sortable>
          <p> {{props.row.to_name}} ({{props.row.to_email}})</p>
        </b-table-column>

        <b-table-column v-slot="props"
          sortable
          field="created_at"
          label="Date"
        >{{ props.row.created_at != null ? (new Date(props.row.created_at).toLocaleString()) : '' }}
        </b-table-column>

        <b-table-column v-slot="props" custom-key="actions" label="Reply">
          <b-button
            v-if="props.row.from_email != $store.state.userEmail"
            icon-left="reply"
            @click.stop="reply(props.row)"
          ></b-button>
        </b-table-column>
      </b-table>
      <b-pagination
        class="margin-top"
        :total="meta.total"
        :current="page"
        :range-before="2"
        :range-after="2"
        :per-page="page_limit"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        v-on:change="setPage"
      ></b-pagination>
    </card-component>
  </section>
</template>

<script>
import Email from "@/models/Email";
import CardComponent from "@/components/CardComponent";
import EmailForm from "@/components/EmailForm";
import EmailListItem from "@/components/EmailListItem";
import Learner from "@/models/Learner";

export default {
  name: "Emails",
  components: {CardComponent, EmailForm, EmailListItem},
  data() {
    return {
      isOpen: 0,
      collapses: [
        {
          title: "Emails",
          lists: [
            {
              title: "All",
              viewType: 1,
            },
            {
              title: "Inbox",
              viewType: 2,
            },
            {
              title: "Outbox",
              viewType: 0,
            },
          ],
        },
      ],
      learnerSearchId: null,
      learnerSearchResponse: [],
      learnerSearchTerm: "",
      reply_email: {},
      activeEmail: {},
      learner: {},
      isReplyActive: false,
      isEmailActive: false,
      page: 1,
      page_limit: 15,
      meta: {},
      viewType: 1,
    };
  },
  watch: {
    filters() {
      this.setPage(this.page);
    },
  },
  methods: {
    learnerName(learner) {
      return `${learner.first_name} ${learner.last_name} | ${learner.email}`;
    },
    selectLearner(option) {
      if (option) {
        this.learnerSearchId = option.id;
        this.setPage(this.page);
      }
    },
    getFilteredLearner(text) {
      if (text.length == 0) {
        this.learnerSearchResponse = [];
        this.learnerSearchId = null;
        this.setPage(this.page);
        return;
      }
      this.loadingLearners = true;

      Learner.search(text, true).then((result) => {
        this.loadingLearners = false;

        if (result.entities) {
          this.learnerSearchResponse = result.entities.learners;
        } else {
          this.learnerSearchResponse = [];
        }
      });
    },
    openEmail(email) {
      if (email.is_read === 0) {
        Email.toggleRead(email.id)
        Email.update({
          where: email.id,
          data: {
            is_read: 1
          }
        })
      }
      this.activeEmail = Email.query().whereId(email.id).with('media').first();

      this.isEmailActive = true;
    },
    reply(email) {
      this.isEmailActive = false;
      if (email.from_user_id!==null) {
        Learner.fetchById(email.from_user_id)
          .then(({entities}) => {
            this.isReplyActive = true;
            this.learner = entities.learners[0];
            this.reply_email = email;
          })
          .catch((err) => {
            this.$store.dispatch("toast/createToast", {
              message: JSON.stringify(err.response.data),
            });
          });
      } else {
        this.isReplyActive = true;
        this.reply_email = email;
        this.learner = {first_name:email.from_name,last_name:null,email:email.from_user_email,id:null};

      }
    },
    setPage(pageNumber) {
      this.$store.dispatch("loader/show");
      Email.deleteAll();
      this.page = pageNumber;
      this.getEmails().then(() => {
        this.$store.dispatch("loader/hide");
      });
    },
    getEmails() {
      return Email.fetch(
        this.for_filter,
        this.from_filter,
        this.to_filter,
        this.between_filter,
        null,
        null,
        this.page,
        this.page_limit
      )
        .then(({response}) => {
          this.meta = response.data.meta;
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
  },

  computed: {
    filters() {
      return [
        this.for_filter,
        this.from_filter,
        this.to_filter,
        this.between_filter,
      ];
    },
    emailTitle() {
      if (this.viewType == 2) {
        return "Inbox";
      }
      if (this.viewType == 1) {
        return "All emails";
      }
      if (this.viewType == 0) {
        return "Outbox";
      }
      return "Emails";
    },
    between_filter() {
      if (this.viewType == 1 && this.learnerSearchId != null) {
        return [parseInt(this.$store.state.userId), this.learnerSearchId];
      }
      return null;
    },
    for_filter() {
      if (this.viewType == 1) {
        return this.learnerSearchId == null
          ? parseInt(this.$store.state.userId)
          : null;
      }
      return null;
    },
    from_filter() {
      if (this.viewType == 1) {
        return null;
      }
      if (this.viewType == 0) {
        return parseInt(this.$store.state.userId);
      }
      if (this.viewType == 2 && this.learnerSearchId != null) {
        return this.learnerSearchId;
      }
      return null;
    },
    to_filter() {
      if (this.viewType == 1) {
        return null;
      }
      if (this.viewType == 2) {
        return parseInt(this.$store.state.userId);
      }
      if (this.viewType == 0 && this.learnerSearchId != null) {
        return this.learnerSearchId;
      }
      return null;
    },

    emails() {
      return Email.query().orderBy("created_at", "desc").with("media").get();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");

    Email.deleteAll();
    this.getEmails().then(() => {
      this.$store.dispatch("loader/hide");
    });
  },
};
</script>
