<template>
  <div>
    <section class="section padding-top">
      <b-field>
        <b-radio-button v-model="chartType" native-value="bar">
          <span>By Stage</span>
        </b-radio-button>

        <b-radio-button v-model="chartType" native-value="horizontalBar">
          <span>By User</span>
        </b-radio-button>
      </b-field>
      <div class="columns">
        <div class="column">
          <b-field label="Select start date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMin"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Select end date">
            <b-datepicker
              :editable="true"
              :years-range="[-100,100]"
              v-model="$store.state.dates.dateMax"
              placeholder="Click to select..."
              icon="calendar-today"
            ></b-datepicker>
          </b-field>
        </div>
      </div>
      <div class="columns is-multiline">
        <div v-show="chartType == 'bar'" class="column">
          <b-field label="Owner">
            <b-select v-model="owner_id" placeholder="Filter by owner">
              <option :value="null">All</option>
              <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                <p>{{user.first_name}} {{user.last_name}} | {{user.email}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-show="chartType == 'bar'" class="column">
          <b-field label="Creator">
            <b-select v-model="creator_id" placeholder="Filter by creator">
              <option :value="null">All</option>
              <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                <p>{{user.first_name}} {{user.last_name}} | {{user.email}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div v-show="chartType == 'horizontalBar'" class="column is-6">
          <b-field label="User Type">
            <b-select v-model="user_type" placeholder="Filter by the type of user">
              <option value="owner">Owner</option>
              <option value="creator">Creator</option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field label="Status">
            <b-select v-model="status_id" placeholder="Filter by status">
              <option :value="null">All</option>
              <option v-for="status in statuses" :key="status.id" :value="status.id">
                <p>{{status.name}}</p>
              </option>
            </b-select>
          </b-field>
        </div>
        <div class="column">
          <b-field grouped group-multiline>
            <b-field label="Year">
              <b-select expanded v-model="$store.state.dates.year" placeholder="Filter by year">
                <option :value="null">All</option>

                <option
                  v-for="number in 4"
                  :key="number + 'year'"
                  :value="(new Date().getFullYear()-3)+number"
                >{{(new Date().getFullYear()-3)+number}}</option>
              </b-select>
            </b-field>
            <b-field label="Semester">
              <b-select
                expanded
                v-model="$store.state.dates.semester_id"
                placeholder="Filter by semester"
              >
                <option :value="null">All</option>

                <option v-for="semester in semesters" :key="semester.id" :value="semester.id">
                  <span>{{semester.name}}</span>
                </option>
              </b-select>
            </b-field>
            <b-field label="Actions">
              <b-field>
                <b-button icon-right="filter" @click="filter()">Filter</b-button>
                <b-field grouped class="ml-6">
                  <b-button
                    icon-right="download"
                    :disabled="download_link==null"
                    tag="a"
                    :href="download_link"
                    target="_blank"
                  >Download</b-button>
                  <b-button icon-right="close" @click="clear()">Clear</b-button>
                </b-field>
              </b-field>
            </b-field>
          </b-field>
        </div>
      </div>
      <div class="columns is-centered">
        <div class="column">
          <card-component :title="title" icon="finance" header-icon="reload">
            <div class="chart-area" v-if="chartType == 'horizontalBar'">
              <horizontalBarChart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-line-chart"
                :chart-data="horizontalBarChart.chartData"
                :options="horizontalBarOptions"
              ></horizontalBarChart>
            </div>
            <div class="chart-area" v-if="chartType == 'bar'">
              <bar-chart
                style="width: 100% !important;height: auto !important;"
                v-if="dataLoaded"
                ref="bigChart"
                chart-id="big-bar-chart"
                :chart-data="barChart.chartData"
                :options="barOptions"
              ></bar-chart>
            </div>
          </card-component>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import BarChart from "@/components/Charts/BarChart";
import horizontalBarChart from "@/components/Charts/HorizontalBarChart";

import Semester from "@/models/Semester";
import EnquiryMadeByStage from "@/models/reports/EnquiryMadeByStage";
import EnquiryMadeByStageByUser from "@/models/reports/EnquiryMadeByStageByUser";
import User from "@/models/User";
import Stage from "@/models/Stage";
export default {
  name: "enquiries-made",
  components: {
    CardComponent,
    horizontalBarChart,
    BarChart,
  },
  data() {
    return {
      year: null,
      semester_id: null,
      download_link: null,
      user_type: "creator",
      statuses: [
        { id: 1, name: "None" },
        { id: 2, name: "Won" },
        { id: 3, name: "Lost" },
      ],
      status_id: null,
      owner_id: null,
      creator_id: null,
      admissions_team: Array,
      chartType: "bar",
      dataLoaded: false,
      title: "Enquiries made by stage",
      datasets: { by_week: [] },

      barChart: {
        chartData: {
          datasets: [],
        },
      },
      horizontalBarChart: {
        chartData: {
          datasets: [],
        },
      },
    };
  },
  watch: {
    chartType() {
      this.filter();
    },
  },
  computed: {
    stages() {
      return Stage.query().orderBy("order", "asc").get();
    },
    semesters() {
      return Semester.all();
    },
    barOptions() {
      return {
        maintainAspectRatio: false,

        animation: {
          duration: 0,
        },
        hover: {
          animationDuration: 0,
        },
        responsiveAnimationDuration: 0,
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      };
    },

    horizontalBarOptions() {
      return {
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        tooltips: {
          enabled: true,
        },
        hover: {
          animationDuration: 0,
        },
        scales: {
          xAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: false,
              },
              gridLines: {},
              stacked: true,
            },
          ],
          yAxes: [
            {
              stacked: true,
            },
          ],
        },
        legend: {
          display: true,
        },
      };
    },
  },
  mounted() {
    Semester.FetchAll();

    this.$store.dispatch("loader/show");
    Stage.fetchAll();

    User.deleteAll();
    User.fetchAll("edit enquiries").then((result) => {
      this.admissions_team = result.entities.users;
    });
    EnquiryMadeByStage.deleteAll();
    EnquiryMadeByStage.fetchAll(
      this.$store.getters["dates/formattedDateMin"],
      this.$store.getters["dates/formattedDateMax"],
      this.owner_id,
      this.creator_id,
      this.status_id,
      true,
      this.$store.state.dates.year,
      this.$store.state.dates.semester_id
    ).then((result) => {
      this.download_link = result.response.data.exportUrl;

      this.processBar(EnquiryMadeByStage.all());
      this.dataLoaded = true;
      this.$store.dispatch("loader/hide");
    });
  },

  methods: {
    clear() {
      this.$store.dispatch("loader/show");
      this.status_id = null;
      this.owner_id = null;
      this.creator_id = null;
      this.$store.state.dates.dateMin = null;
      this.$store.state.dates.dateMax = null;
      this.$store.state.dates.year = null;
      this.$store.state.dates.semester_id = null;
      this.user_type = "creator";
      this.filter();
    },
    filter() {
      this.$store.dispatch("loader/show");
      this.dataLoaded = false;
      if (this.chartType == "bar") {
        EnquiryMadeByStage.deleteAll();

        EnquiryMadeByStage.fetchAll(
          this.$store.getters["dates/formattedDateMin"],
          this.$store.getters["dates/formattedDateMax"],
          this.owner_id,
          this.creator_id,
          this.status_id,
          true,
          this.$store.state.dates.year,
          this.$store.state.dates.semester_id
        ).then((result) => {
          this.download_link = result.response.data.exportUrl;

          this.processBar(EnquiryMadeByStage.all());

          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        });
      }
      if (this.chartType == "horizontalBar") {
        EnquiryMadeByStageByUser.deleteAll();

        EnquiryMadeByStageByUser.fetchAll(
          this.$store.getters["dates/formattedDateMin"],
          this.$store.getters["dates/formattedDateMax"],
          this.user_type,
          this.status_id,
          true,
          this.$store.state.dates.year,
          this.$store.state.dates.semester_id
        ).then((result) => {
          this.download_link = result.response.data.exportUrl;

          let data = EnquiryMadeByStageByUser.query()
            .orderBy("user_name")
            .get();

          this.processHorizontalBar(data);
          this.dataLoaded = true;
          this.$store.dispatch("loader/hide");
        });
      }
    },
    oneWeekAgo() {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return oneWeekAgo;
    },
    processBar(reports) {
      let data = {
        labels: [],
        datasets: [
          {
            data: [],
            borderColor: this.$store.state.chart.default_colors,
            backgroundColor: this.$store.state.chart.default_colors,
          },
        ],
      };

      for (let item of reports) {
        data.labels.push(item.name);
        data.datasets[0].data.push(item.enquiries_count);
      }

      this.barChart.chartData = data;
      this.dataLoaded = true;
    },
    processHorizontalBar(reports) {
      let names = [];
      let data = {
        labels: [],
        datasets: [],
      };

      for (let item of reports) {
        if (!names.includes(item.user_name)) {
          names.push(item.user_name);
        }
      }
      this.stages.forEach((stage, index) => {
        data.datasets.push({
          data: [],
          label: stage.name,
          borderColor: this.$store.state.chart.default_colors[index],
          backgroundColor: this.$store.state.chart.default_colors[index],
        });
        names.forEach((name) => {
          let hold = EnquiryMadeByStageByUser.query()
            .where("name", stage.name)
            .where("user_name", name)
            .get();
          data.datasets[index].data.push(
            hold.length > 0 ? hold[0].enquiries_count : 0
          );
        });
      });
      data.labels = names;
      this.horizontalBarChart.chartData = data;
      this.dataLoaded = true;
    },
  },
};
</script>
