import {
  TransformModel
} from '@/models/TransformModel'


export default class City extends TransformModel {
  static entity = 'cities'

  static fields() {
    return {
      id: this.attr(null),
      name:this.attr(null),


    }
  }
  static fetchAll({page = 1, limit = 100}, query = {}, relationships = []) {
    return this.api().get(`/cities`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          },
          ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(city => {

            return {...city, ...city.attributes}
          })
        },
      }
    )
  } static fetchAllPublic({page = 1, limit = 100}, query = {}, relationships = []) {
    return this.api().get(`/public/lookups/cities`, {
        params: {
          ...{
            page: page, limit: limit, with: relationships
          }, ...(query !== {} ? query : {}),
        },
        dataTransformer: ({data: {data}}) => {
          return data.map(city => {

            return {...city, ...city.attributes}
          })
        },
      }
    )
  }



}
