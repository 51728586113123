import {
  Model
} from '@vuex-orm/core'

export default class RoleUser extends Model {
  static entity = 'permissionRole'

  static primaryKey = ['role_id', 'permission_id']

  static fields() {
    return {
      role_id: this.attr(null),
      permission_id: this.attr(null)
    }
  }
}
