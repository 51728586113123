<template>
  <ValidationObserver ref="observer" v-slot="{ handleSubmit,invalid  }">

    <form :class="{'section':!inModal}" class="is-relative" @submit.prevent="handleSubmit(submit)">
      <div :class="{'modal-card':inModal,'card':!inModal}">
        <header v-if="inModal" class="modal-card-head">
          <p class="modal-card-title">{{ edit === false ? 'Create a new' : 'Edit' }} Intake Period</p>
        </header>
        <title-bar v-if="!inModal" class="card-header"><p class="card-header-title">
          {{ edit === false ? 'Create a new' : 'Edit' }} Intake period</p></title-bar>

        <section :class="{'modal-card-body':inModal,'card-content':!inModal}">
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="Start date">
              <b-datepicker
                v-model="start_date"
                :editable="true"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="End date">
              <b-datepicker
                v-model="end_date"
                :editable="true"
                :years-range="[-100,100]"
                icon="calendar-today"
                placeholder="Click to select..."
              ></b-datepicker>
            </b-field>
          </ValidationProvider>
          <ValidationProvider v-slot="{ errors }"
                              :skipIfEmpty="false"
                              class="control my-4"
                              rules="required"
                              tag="div">
            <b-field
              :horizontal="!inModal"
              :message="errors.length>0?errors[0]:''"
              :type="errors.length>0?'is-danger':''" label="Name">
              <b-input
                v-model="intakeObject.name"
                autocomplete="off"
                name="name"
                placeholder="Name"
                required
              />
            </b-field>
          </ValidationProvider>
          <template v-if="edit">
            <b-field :horizontal="!inModal" label="Add semester">
              <b-autocomplete
                v-model="selectedSemester"
                :clear-on-select="true"
                :data="filteredSemesters"
                :icon="$tc('icons.modules')"
                :loading="loadingSemesters"
                autocomplete="off"
                field="name"
                name="related_course"
                placeholder="Select Semester"
                @select="option => addSemester(option)"
                @typing="text=>getFilteredSemesters(text,[semesters])"
              >
                <template v-slot="props">
                  <p>{{ props.option.attributes.name }}</p>
                </template>
              </b-autocomplete>
            </b-field>
            <b-field v-if="loaded" :horizontal="!inModal" label="Semesters">
              <b-table
                :bordered="true"
                :data="intakeObject.semesters"
                :header-checkable="false"
                :hoverable="true"
                :striped="true"
                class="margin-top"
                default-sort="level"
              >
                <b-table-column v-slot="props" field="name" label="Name" sortable>{{
                    props.row.name
                  }}
                </b-table-column>
                <b-table-column v-slot="props"
                                field="start_date"
                                label="Start Date"
                                numeric
                                sortable
                                width="40"
                >{{ props.row.start_date }}
                </b-table-column>
                <b-table-column v-slot="props"
                                field="end_date"
                                label="End Date"
                                numeric
                                sortable
                                width="40"
                >{{ props.row.end_date }}
                </b-table-column>
                <b-table-column v-slot="props" class="has-text-centered" custom-key="actions" width="120">
                  <b-checkbox
                    :indeterminate="true"
                    :value="true"
                    @click.native.prevent="removeSemester(props.row.id)"
                  ></b-checkbox>
                </b-table-column>
              </b-table>
            </b-field>
          </template>
        </section>
        <footer :class="{'modal-card-foot':inModal,'card-footer':!inModal}">
          <div class="card-footer-item">
            <b-field grouped>
              <p class="control">
                <b-button :loading="loading" native-type="submit"
                          type="is-primary">Save
                </b-button>
              </p>
              <p v-if="edit" class="control">
                <b-button :disabled="!canDelete" type="is-danger" @click.prevent="startDelete">
                  Delete
                </b-button>
              </p>
            </b-field>
          </div>
        </footer>
      </div>
      <b-loading :active="loading" :can-cancel="false" :is-full-page="false"></b-loading>
    </form>
  </ValidationObserver>
</template>

<script>

import TitleBar from "@/components/TitleBar";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";
import Intake from "@/models/Intake";
import {format} from "date-fns";
import Semester from "@/models/Semester";

extend("required", (value) => {
  if (value !== null) {
    return true;
  }
  return "This field is required";
});
extend("date", (value) => {
  let date = new Date(value)
  if (date instanceof Date && !isNaN(date.valueOf())) {
    return true;
  }
  return "Please enter a valid date";
});
export default {
  name: "IntakeForm",
  components: {
    TitleBar,
    ValidationObserver,
    ValidationProvider

  },
  data() {
    return {
      loaded: false,
      loading: false,
      selectedSemester: null,
      filteredSemesters: [],
      loadingSemesters: false,
      intakeObject: {
        start_date: null,
        end_date: null,
        name: "",
        semesters: []
      },

    }
  },
  computed: {
    start_date: {
      get() {
        if (this.intakeObject.start_date !== null) {
          return new Date(this.intakeObject.start_date)
        } else {
          return null
        }
      },
      set(newVal) {
        this.intakeObject.start_date = format(newVal, 'yyyy-MM-dd')
      }
    },
    end_date: {
      get() {
        if (this.intakeObject.end_date !== null) {
          return new Date(this.intakeObject.end_date)
        } else {
          return null
        }
      },
      set(newVal) {
        this.intakeObject.end_date = format(newVal, 'yyyy-MM-dd')
      }
    },
    semesters() {
      if (this.edit) {
        return this.intakeObject.semesters.map((item) => {
          return item.id
        })
      }
      return []
    },
  },
  methods: {
    startDelete() {
      this.$buefy.dialog.confirm({
        title: `Deleting Intake Period`,
        confirmText: `Delete Intake Period`,
        hasIcon: true,
        type: 'is-danger',
        message: `Are you sure you want to delete this Intake Period?`,
        onConfirm: () => this.delete()
      })
    },
    delete() {
      this.loading = true
      Intake.Delete(this.intakeObject.id).then(() => {
        this.$buefy.snackbar.open(`Intake Period deleted!`);
        this.$emit("close");
        this.$emit("deleted");

      }).catch(err => {
        this.handleError(err)
      }).finally(() => {
        this.loading = false
        if (!this.inModal) {
          this.$router.push('/intakes')
        }
      });

    },
    submit() {
      this.loading = true
      if (this.edit) {
        if (!this.canEdit) {
          this.$store.dispatch('toast/createToast')
          return
        }
        Intake.Update({
          id: this.intakeObject.id,
          name: this.intakeObject.name,
          start_date: this.intakeObject.start_date,
          end_date: this.intakeObject.end_date,
          semesters: this.semesters,
        }).then(() => {
          this.$buefy.snackbar.open(`Intake Period updated!`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      } else {
        if (!this.canCreate) {
          this.$store.dispatch('toast/createToast')
          return;
        }
        Intake.Store({
            name: this.intakeObject.name,
          start_date: this.intakeObject.start_date,
          end_date: this.intakeObject.end_date,
          semesters: this.semesters,
        }).then(({entities: {intakes}}) => {
          this.$buefy.snackbar.open(`Intake Period created!`)
          this.intakeObject.id = intakes[0].id
          this.$router.push(`/intakes/edit/${intakes[0].id}`)
          this.loading = false
        }).catch(err => {
          this.handleError(err)
          this.loading = false
        })
      }
    },
    addSemester(semester) {
      if (semester) {
        this.loading = true
        this.loadingSemesters = true;
        Intake.Update({
            id: this.intakeObject.id,
            name: this.intakeObject.name,
            start_date: this.intakeObject.start_date,
            end_date: this.intakeObject.end_date,
            semesters: [...this.semesters, semester.id],
        }).then(() => {
          Intake.FetchById(+this.id, ['semesters'])
            .then((res) => {
              if (res.entities.semesters) {
                this.intakeObject.semesters = res.entities.semesters;
              }
          });
        this.loadingSemesters = false;
        this.loading = false
        })
      }
    },
    removeSemester(semester) {
      this.loading = true
      this.loadingSemesters = true;
      Intake.Update({
        id: this.intakeObject.id,
        name: this.intakeObject.name,
        start_date: this.intakeObject.start_date,
        end_date: this.intakeObject.end_date,
        semesters: this.semesters.splice(this.semesters.indexOf(semester.id), 1),
      }).then(() => {
        Intake.FetchById(+this.id, ['semesters'])
          .then((res) => {
            if (res.entities.semesters) {
              this.intakeObject.semesters = res.entities.semesters;
            }
          });
        this.loadingSemesters = false;
        this.loading = false
      })
    },
    getFilteredSemesters(text, existingSemesters = []) {
      this.loadingSemesters = true;

      Semester.search(text, true, 0, 1, 100, null).then((result) => {
        this.loadingSemesters = false;

        this.filteredSemesters = result.response.data.data.filter(
          (semester) => !existingSemesters.some((item) => item.id === semester.id)
        );
      });
    },
  },
  async created() {
    this.loading = true
    if (this.edit) {
      Intake.FetchById(+this.id, ['semesters'])
        .then((res) => {
          this.intakeObject = Intake.query().whereId(+this.id).first();
          if (res.entities.semesters) {
            this.intakeObject.semesters = res.entities.semesters;
          }
          this.$store.dispatch("loader/hide");
          this.loaded = true;
          this.loading = false
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
          this.loading = false

          if (
            err.response.status.toString()[0] == 4 ||
            err.response.status.toString()[0] == 5
          ) {
            setTimeout(() => {
              this.$router.go(-1);
            }, 2000);
          }
        });
    } else {
      this.loading = false
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return null
      }
    },
    inModal: {
      type: Boolean, default() {
        return false
      }
    },
    edit: {
      type: Boolean, default() {
        return false
      }
    }, canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    }, canEdit: {
      type: Boolean, default() {
        return false
      }
    },
  }
}
</script>

