<template>
  <card-component
    :icon="$tc('icons.media')"
    :title="title"
    un-card
  >
    <p v-if="message!==null" class="help">
      {{ message }}
    </p>
    <div class="columns is-multiline is-fullwidth">
      <div class="column is-12">
        <div class="columns is-centered">
          <div class="column is-12 has-text-centered">
            <b-upload v-model="dropFile" :disabled="disabled" :required="required&&(media_count===0)&&(dropFile===null)" drag-drop>
              <section class="section">
                <div class="content has-text-centered">
                  <p class="is-flex is-align-items-center is-justify-content-center">
                    <b-icon
                      :icon="$tc('icons.upload')"
                      size="is-large"
                    ></b-icon>
                  </p>
                  <p>Drop your file here or click to upload</p>
                  <p>Max file size of 10mb</p>
                </div>
              </section>
            </b-upload>
          </div>
        </div>
      </div>
      <label v-if="dropFile" class="label">File awaiting upload</label>
      <div v-if="dropFile" class="upload-file-list column  is-12">

        <div class="notification">
          <form @submit.prevent="submitMedia">
            <div class="mb-5">

              <div class="is-flex is-align-content-center">
                <p class="is-flex is-align-items-center">
                  <b-icon
                    :icon="$tc('icons.upload')"
                    custom-size="default"
                  ></b-icon>
                  <span>{{ dropFile.name }}</span></p>
                <b-field v-if="hasCaption">
                  <b-input
                    v-model="dropFile.caption"
                    name="caption"
                    placeholder="Describe the upload"
                  />
                </b-field>
                <b-field v-if="hasTag">
                  <b-select
                    v-model="dropFile.tag"
                    placeholder="Select a tag"
                    required
                  >
                    <option
                      v-for="(tag,index) in tags"
                      :key="index"
                      :value="tag"
                    >{{ tag }}
                    </option>
                  </b-select>
                </b-field>
                <b-field>
                  <b-switch v-if="showGuardianVisibility" v-model="dropFile.uploadVisibility"
                            false-value=0
                            true-value=1>
                    Guardian Visibility
                  </b-switch>
                </b-field>
                <p class="control">
                  <b-button :icon-right="$tc('icons.close-circle')" type="is-ghost"
                            @click.prevent="deleteDropFile()">

                  </b-button>
                </p>
              </div>

            </div>
            <b-field v-if="submit_button" class="mt-5">
              <b-field grouped>
                <div class="control">
                  <b-button native-type="submit"
                            type="is-primary"
                  >Submit upload
                  </b-button
                  >
                </div>
              </b-field>
            </b-field>


          </form>
        </div>
      </div>
    </div>

    <label v-if="media_count>0" class="label">Previously uploaded document</label>
    <b-table v-if="media_count>0"
             :bordered="false"
             :data="media"
             :hoverable="true"
             :striped="true"
             class="margin-top"
    >
      <template #empty>
        <div class="has-text-centered">None</div>
      </template>
      <b-table-column v-slot="props"

                      field="filename"
                      label="Filename"
                      sortable
      >{{ props.row.filename }}
      </b-table-column
      >

      <b-table-column v-if="hasTag" v-slot="props"
                      field="tag"
                      label="Tag"
                      sortable
      >{{ props.row.tag }}
      </b-table-column>
      <b-table-column v-if="hasCaption"
                      v-slot="props"
                      field="caption"
                      label="Caption"
                      sortable
      >{{
          props.row.caption
        }}
      </b-table-column
      >

      <b-table-column v-slot="props" custom-key="actions" width="170">
        <b-field grouped>
          <b-field>
            <b-button
              :href="props.row.temporary_url"
              tag="a"
              target="_blank"
              type="is-primary">View
            </b-button>

          </b-field>
          <b-field style="height: fit-content; margin: auto">
            <b-tooltip
              v-if="canDelete"
              label="Delete"
              size="is-small"
              type="is-danger">
              <a
                class="card-header-icon has-text-danger px-0 py-0"
                @click.prevent.stop="startDelete(props.row)">
                <b-icon :icon="$tc('icons.delete')"/>
              </a>
            </b-tooltip>
          </b-field>
        </b-field>

      </b-table-column>
    </b-table>
    <template v-if="verification!==null">
      <div v-if="verification.awaiting_verification!==1 && verification.uploaded !==false">
        <label class="label">Document Verification</label>
        <b-table
          :bordered="true"
          :data="[verification]"
          :header-checkable="false"
          :hoverable="true"
          :striped="true"
        >
          <b-table-column v-slot="props"
                          field="passed"
                          label="Verification Status"
                          sortable
          >{{ props.row.passed ? 'Passed' : 'Failed' }}
          </b-table-column>
          <b-table-column v-slot="props" :visible="!verification.passed"
                          field="reason"
                          label="Reason"
                          sortable
          >{{ props.row.reason }}
          </b-table-column>
        </b-table>

      </div>
    </template>
    <b-loading
      :active.sync="updatingMedia"
      :can-cancel="false"
      :is-full-page="false"
    ></b-loading>
    <hr>
  </card-component>

</template>
<script>
import CardComponent from "@/components/CardComponent";
import Media from "@/models/Media";

export default {
  name: 'SingleTagUpload',

  data() {
    return {
      updatingMedia: false,
      dropFile: null,
      selectedTag: this.tag,
      visibility: 0,
      mediaDataHold: null,
      meta: Object,
      page: 1,
      limit: 100,
      offset: 0,
      paginationLimit: 15,
      order_by: 'created_at',
      order_direction: 'desc'
    }
  },
  watch: {
    submit_flip(val) {
      if (val === true) {
        this.submitMedia()
      }
    },
    selectedTag() {
      this.fetchMedia()
      this.setPage(1)
    }
  },
  props: {
    verifications: {
      type: Array,
      default() {
        return []
      }
    }, tag: {
      type: String,
      default() {
        return null
      }
    },
    isCentral: {
      type: Boolean,
      default() {
        return false
      }
    }, programmaticFile: {
      type: Blob,
      default() {
        return null
      }
    },
    showGuardianVisibility: {
      type: Boolean,
      default() {
        return false
      }
    },
    submit_flip: {
      type: Boolean,
      default() {
        return false
      }
    },
    fetchOnMount: {
      type: Boolean,
      default() {
        return true
      }
    },
    submit_button: {
      type: Boolean,
      default() {
        return true
      }
    },
    model: {
      type: String, required: true,
    },
    retrieve_model: {
      type: String, default() {
        return null
      },
    },
    model_id: {
      type: Number, required: true,
    },
    tags: {
      type: Array, default() {
        return [
          'Birth Certificate',
          'Proof of Address',
          'Proof of Banking',
          'ID',
          'Passport',
          'School Report',
          'Academic Assessment',
          'Medical Certificate',
          'PoP (Registration)',
          'PoP (Enrolment)',
          'Entrance Assessment'
        ]
      }
    },

    canDelete: {
      type: Boolean, default() {
        return false
      }
    }, canCreate: {
      type: Boolean, default() {
        return false
      }
    },
    required: {
      type: Boolean, default() {
        return false
      }
    }, hasUpload: {
      type: Boolean, default() {
        return true
      }
    },
    hasTable: {
      type: Boolean, default() {
        return true
      }
    }, hasCaption: {
      type: Boolean, default() {
        return true
      }
    }, hasTag: {
      type: Boolean, default() {
        return true
      }
    },
    title: {
      type: String, default() {
        return 'Supporting Documents'
      }
    }
  },
  computed: {
    message() {
      if (this.tag === 'Certified copy of ID or other proof of identity') {
        return 'Please upload a clear scan or photograph of your Identity Document. Your photo and the number both need to be clearly legible. '
      }
      return null
    },
    disabled() {
      if (this.verification !== null) {
        if (this.verification.awaiting_verification ===0){
          return this.verification.verified===1 || !this.canCreate
        }
      }
      return !this.canCreate
    },
    verification() {
      if (this.verifications.findIndex(verification => {
        return verification.name === this.tag
      }) > -1) {
        return this.verifications[this.verifications.findIndex(verification => {
          return verification.name === this.tag
        })]
      }
      return null
    },
    media_count() {
      return Media.query()
        .where('mediable_type', this.model)
        .where('mediable_id', this.model_id)
        .where('tag', this.selectedTag)
        .count()
    },
    media() {
      return Media.query()
        .where('mediable_type', this.model)
        .where('mediable_id', this.model_id)
        .where('tag', this.selectedTag)
        .get()
    }
  },
  mounted() {
    if (this.fetchOnMount) {
      this.fetchMedia()
    }

  },
  methods: {

    deleteDropFile() {
      this.dropFile = null
    },
    delete(media) {
      this.updatingMedia = true
      Media.deleteRemote(media.id).then(() => {
        this.$buefy.snackbar.open('Media item deleted!')
        this.updatingMedia = false
      }, this.isCentral).catch(
        err => {
          this.updatingMedia = false;

          this.handleError(err)
        }
      )
    },
    startDelete(media) {
      this.$buefy.dialog.confirm({
        title: 'Deleting media',
        confirmText: 'Delete Media',
        hasIcon: true,
        type: 'is-danger',
        message: 'Are you sure you want to delete this media item?',
        onConfirm: () => this.delete(media)
      })
    },
    fetchMedia() {

      this.updatingMedia = true
      return Media.fetchAll(this.model_id, this.model).then(() => {
        this.updatingMedia = false
      }).catch(
        err => {
          this.updatingMedia = false;
          this.handleError(err)
        }
      )
    },
    async submitMedia() {
      if (this.dropFile === null) {
        return Promise.resolve()
      }
      this.$emit('uploading')
      this.updatingMedia = true;
      let promises = []
      if (this.media_count > 0) {
        promises.push(Media.deleteRemote(this.media[0].id))
      }

      let formData = new FormData();
      formData.append("file", this.dropFile);
      if (this.tag !== null) {
        formData.append("tag", this.tag);
      } else {
        formData.append("tag", this.dropFile.tag);

      }
      formData.append("caption", typeof this.dropFile.caption !== 'undefined' ? this.dropFile.caption : '');


      promises.push(Media.Store({model: this.model, model_id: this.model_id}, formData))
      return Promise.all(promises)
        .then(() => {
          if (this.dropFile !== null) {
            this.$buefy.snackbar.open(`Media uploaded!`)

          }
          this.dropFile = null

          this.$emit('submitted')
          this.updatingMedia = false;

          this.fetchMedia()
        }).catch(
          err => {
            this.updatingMedia = false;

            this.handleError(err)
          }
        )
    }
  }


  ,
  components: {
    CardComponent,

  }
}
</script>
