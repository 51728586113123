<template>
  <BoardIndex
    :board_title="title"

    :team_permission="permission"
    :board_id="8"

  />

</template>

<script>
import BoardIndex from "@/components/BoardIndex";

export default {
  name: "Graduated-board",
  components: {
    BoardIndex
  },
  data() {
    return {
      title: 'Graduated',
      permission: 'view graduated board',

    };
  },

};
</script>

