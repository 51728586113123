<template>
  <Export

    :date_filter="false"
    :entities="entities"
    :required_filters="['module']"
    :title="`Class list by Module`"
    :module_filter="true"
    :course_filter="false"
    :inline_modules_filter="false"
    :semesters="semesters"
    :year_filter="true"
  ></Export>
</template>


<script>
import Stage from "@/models/RegistrationStage";
import Export from "@/components/Export";
import Semester from "@/models/Semester";

export default {
  name: "ClasslistByModule",
  components: {
    Export,
  },
  data() {
    return {
      entities: "classlist-by-module",
      admissions_team: [],

    };
  },
  computed: {
    stages() {
      return Stage.all();
    },
    semesters() {
      return Semester.all();
    },
  },
  mounted() {
    this.$store.dispatch("loader/show");



    Promise.all([  Stage.fetchAll(),
      Semester.FetchAll()]).finally(()=>{
      this.$store.dispatch("loader/hide");

    })

  },
};
</script>
