import {TransformModel} from '@/models/TransformModel'

export default class CourseIntake extends TransformModel {
  static entity = 'course-intakes'

  static fields() {
    return {
      id: this.attr(null),
      start_date:this.attr(null),
      end_date:this.attr(null)
    }
  }

  static FetchAll(course_id) {
    return this.api().get(`/courses/${course_id}/intakes`, {

      dataTransformer: ({data: {data}}) => {
        return data.map(intake => {
          intake.id = parseInt(intake.id)
          return {...intake, ...intake.attributes}
        })
      },
    })
  }

  static Update(course_intakes) {
    return this.api().patch(`/courses/${course_intakes.course_id}/intakes`, course_intakes, {

      dataTransformer: (response) => {
        let target = response.data.data

        Object.assign(target, target.attributes)


        return target
      }
    })
  }




}
