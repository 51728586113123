import {
  TransformModel
} from '@/models/TransformModel'


export default class Media extends TransformModel {
  static entity = 'Media'

  static fields() {
    return {
      id: this.attr(null),
      filename: this.attr(null),
      extension: this.attr(null),
      tag: this.attr(null),
      caption: this.attr(null),
      temporary_url: this.attr(null),
      mediable_id: this.attr(null),
      mediable_type: this.attr(null)
    }
  }
  static uploadToLearner(file, learner_id) {
    return this.api().post(`/learners/${learner_id}/media`, file, {
      save: false
    })
  }
  static uploadToRegistration(file, registration_id) {
    return this.api().post(`/registrations/${registration_id}/media`, file, {
      save: false
    })
  }
  static deleteRemote(file_id) {
    return this.api().delete(`/media/${file_id}`, {
      delete:file_id
    })
  }
  /**
   * Store a new media item
   * @function
   * @param {Object} model - the associated entity
   * @param {string} model.model - the associated entity type
   * @param {number} model.model_id - the associated entity id
   * @param {boolean} isCentral - Whether to use the central api
   * @param {FormData} media
   * @returns {Promise<Response>} - The newly created user
   */

  static Store(model, media,) {
    return this.api().post(`/${model.model}/${model.model_id}/media`, media, {

        dataTransformer: ({data: {data}}) => {

          // if (relationships.includes('user')) {
          //     Object.assign(task.attributes.user, task.attributes.user.attributes)
          // }
          data.mediable_type = model.model === 'leaves' ? 'leave' : model.model
          data.mediable_id = model.model_id
          return {...data, ...data.attributes}
        },
      }
    )
  }

  static fetchAll(model_id, model_type = 'users') {
    return this.api().get(`/media`, {
      params: {
        modelName: model_type,
        model: model_id,
        page:1,
        limit:999
      },

      dataTransformer: ({data: {data}}) => {
        return data.map(media => {
            media.mediable_type = model_type
            media.mediable_id = model_id
                 // if (relationships.includes('user')) {
          //     Object.assign(task.attributes.user, task.attributes.user.attributes)
          // }
          return {...media, ...media.attributes}
        })
      },
    })
  }



}
