<template>
  <div class="listitem">
    <article class="media">
      <figure class="media-left">
        <p class="has-text-centered margin-top">
          <b-field>
            <b-icon class="icon rounded is-large box is-64x64" :icon="$tc('icons.log')"></b-icon>
          </b-field>
        </p>
      </figure>
      <div class="box media-content is-relative">
        <div class="content">
          <h3 class="is-capitalized" v-html="log?.attributes?.note"></h3>
          <p
            class="subtitle is-7 no-margin-bottom"
          >{{log.attributes.user.attributes.first_name}} {{log.attributes.user.attributes.last_name}} @ {{(new Date(log.attributes.created_at).toLocaleString())}}</p>
        </div>
      </div>
    </article>

    <hr />
  </div>
</template>

<script>
export default {
  name: "logListItem",
  props: {
    log: {
      type: Object,
      default: () => Object,
    },
  },
  data() {
    return {};
  },
};
</script>
