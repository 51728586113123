<template>
  <card-component
    :icon="$tc('icons.media')"
    :title="'Document Verification'"
    class="is-relative"
  >
    <ValidationObserver ref="observer" v-slot="{ handleSubmit  }">

      <form @submit.prevent="handleSubmit(saveUserDocuments)">
        <template v-if="loaded">
          <template v-for="(document,index) in documents">
            <DocumentVerificationItem :profile="profile" :key="document.id" :ref="'verify'+document.id" :document="document" :has_been_checked="has_been_checked"/>
            <hr v-if="index<documents.length-1" :key="document.id+'hr'">
          </template>
        </template>
        <b-field  label="Document Verification Completed">
          <b-checkbox v-model="has_been_checked" :false-value="0" :true-value="1">{{ has_been_checked ? 'Yes' : 'No' }}</b-checkbox>
        </b-field>
        <div class="card-footer">
          <div class="card-footer-item">
            <b-field>
              <b-button native-type="submit" type="is-primary">
                Save
              </b-button>
            </b-field>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <b-loading :active="loading" :can-cancel="false" :is-full-page="false"/>
  </card-component>
</template>

<script>
import CardComponent from "@/components/CardComponent";
import Media from "@/models/Media";
import UserDocument from "@/models/UserDocument";
import DocumentVerificationItem from "@/components/media/DocumentVerificationItem";
import {ValidationObserver} from "vee-validate";
import Profile from "@/models/Profile";
import Learner from "@/models/Learner";
// import CourseDocuments from "@/models/CourseDocument";

export default {
  name: 'DocumentVerificationPanel',
  components: {
    DocumentVerificationItem,
    CardComponent,
    ValidationObserver
  },
  props: {
    learner_id: {
      type: String,
      required: true
    },
    course_id: {
      type: Number,
      required: true
    },

  },
  methods: {
    async saveUserDocuments() {
      this.loading = true
      let promises = []
      this.documents.map((document) => {
        let string = 'verify' + document.id

        promises.push(this.$refs[string][0].submit())
      })
      await Promise.all([promises])
      UserDocument.Update({
        documents: this.documents.map(document => {
          return {
            media_id: document.media_id,
            document_id: document.document_id,
            verified: document.verified,
            verification_note: document.verification_note,
            has_been_checked: this.has_been_checked
          }
        })
      }, this.learner_id).then(() => {
        this.$buefy.snackbar.open('Document Verification Updated!')
        this.loading = false
      }).catch(err => {
        this.handleError(err)
        this.loading = false
      })
    },
  },
  computed: {
    profile(){
      return Profile.query().where('user_id', (value) => {
        return parseInt(value) === parseInt(this.learner_id)
      }).first()
    },
    documents() {
      return UserDocument.query().withAll().get()
    },
    media() {
      return Media.query().get()
    },
  },
  data() {
    return {
      loading: false,
      loaded: false,
      has_been_checked: 0,
    };
  },

  async created() {
    this.loading = true
    await Promise.all([
      UserDocument.deleteAll(),
      // CourseDocuments.deleteAll(),
    ])
    await Promise.all([
      Media.fetchAll(parseInt(this.learner_id), 'users'),
      UserDocument.FetchAll(1, 999, this.learner_id),
      Learner.fetchById(this.learner_id)
      // CourseDocuments.FetchAll(1, 999, this.course_id),
    ])
    if (UserDocument.query().where('has_been_checked', 0).exists()) {
      this.has_been_checked = 0
    } else {
      this.has_been_checked = 1
    }
    this.loaded = true
    this.loading = false


  },
};
</script>
