<template>
  <div>
    <card-component :title="title">
      <section class="section no-padding-left no-padding-right padding-top">
        <form ref="form" @submit.prevent="download">
          <div v-if="date_filter" class="columns">
            <div class="column">
              <b-field :label="`Select start date ${date_type_start!=null?`(${date_type_start})`:''}`">
                <b-datepicker
                  v-model="$store.state.dates.dateMin"
                  :years-range="[-100,100]"
                  icon="calendar-today"
                  placeholder="Click to select..."
                ></b-datepicker>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="`Select end date ${date_type_end!=null?`(${date_type_end})`:''}`">
                <b-datepicker
                  v-model="$store.state.dates.dateMax"
                  :years-range="[-100,100]"
                  icon="calendar-today"
                  placeholder="Click to select..."
                ></b-datepicker>
              </b-field>
            </div>
          </div>
          <div v-if="edudex_date_filter" class="columns">
            <div class="column">
              <b-field :label="`Select start date`">
                <b-select v-model="edudex_start_month" expanded placeholder="Start date" required>
                  <option :value="1">1st January</option>
                  <option :value="7">1st July</option>
                </b-select>
                <b-select v-model="edudex_start_year" expanded placeholder="Year" required>
                  <option v-for="year in years" :key="year+'start'" :value="year">
                    {{ year }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field :label="`Select end date`">
                <b-select v-model="edudex_end_month" expanded placeholder="End date" required>
                  <option :value="1">1st January</option>
                  <option :value="7">1st July</option>
                </b-select>
                <b-select v-model="edudex_end_year" expanded placeholder="Year" required>
                  <option v-for="year in years" :key="year+'end'" :value="year">
                    {{ year }}
                  </option>
                </b-select>
              </b-field>
            </div>
          </div>
          <div class="columns is-multiline">
            <div v-if="course_filter" class="column is-6">
              <b-field>
                <template #label>
                  {{ $tc('Course', 1) }} <span class="subtitle is-6 is-italic">- Leave blank to get data for all programmes.
</span>
                </template>
                <b-autocomplete
                  v-model="courseHold"

                  v-debounce:300ms="getFilteredCourse"
                  :clear-on-select="false"
                  :data="filteredCourses"
                  :loading="loadingCourses"
                  :placeholder="$tc('Course',1) + ' Name'"
                  :required="required_filters.includes('course')"
                  autocomplete="off"
                  field="name"
                  icon="account"
                  name="Course"
                  @select="option => selectCourse(option)"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.code }} | {{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
              <p
                v-if="courseHold.length>0 && course_id == null && !loadingCourses && submitted"
                class="has-text-danger is-size-7"
              >No course selected</p>
            </div>
            <div v-if="module_filter" class="column is-6">
              <b-field label="Module">
                <b-autocomplete
                  v-model="moduleHold"

                  :clear-on-select="false"
                  :data="filteredModules"
                  :loading="loadingModules"
                  :required="required_filters.includes('module')"
                  autocomplete="off"
                  field="name"
                  icon="account"
                  name="module"
                  placeholder="Module name"
                  @select="option => selectModule(option)"
                  @typing="getFilteredModule"
                >
                  <template slot-scope="props">
                    <p>{{ props.option.code }} | {{ props.option.name }}</p>
                  </template>
                </b-autocomplete>
              </b-field>
              <p
                v-if="moduleHold.length>0 && module_id == null && !loadingModules && submitted"
                class="has-text-danger is-size-7"
              >No module selected</p>
            </div>
            <div v-if="owner_filter" class="column is-4">
              <b-field label="Owner">
                <b-select v-model="owner_id" expanded placeholder="Filter by owner">
                  <option :value="null">All</option>
                  <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                    <p>{{ user.first_name }} {{ user.last_name }} | {{ user.email }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="creator_filter" class="column is-4">
              <b-field label="Creator">
                <b-select v-model="creator_id" expanded placeholder="Filter by creator">
                  <option :value="null">All</option>
                  <option v-for="user in admissions_team" :key="user.id" :value="user.id">
                    <p>{{ user.first_name }} {{ user.last_name }} | {{ user.email }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="location_type_filter" class="column is-4">
              <b-field label="Location Type">
                <b-select v-model="location_type" expanded placeholder="Location type" required>
                  <option value="country">Country</option>
                  <option value="province">Province</option>
                  <option value="city">City</option>
                </b-select>
              </b-field>
            </div>

            <div v-if="status_filter" class="column is-4">
              <b-field expanded label="Status">
                <b-dropdown v-model="status_id" aria-role="list" expanded multiple>
                  <b-button slot="trigger" expanded type="is-primary">
                    <span>Select Statuses</span>

                    <b-icon icon="menu-down"></b-icon>
                  </b-button>
                  <b-dropdown-item :class="{'is-active':status_id.length===0}" @click="status_id=[]">
                    <span>All</span>
                  </b-dropdown-item>
                  <div v-for="(group,index) in statuses" :key="index"
                  >
                    <span class="is-size-5 px-2 has-text-weight-bold">{{ group.name }}</span>
                    <b-dropdown-item
                      v-for="status in group.statuses" :key="status.id" :value="status.id"
                      aria-role="listitem"
                    >
                      <span>{{ status.name }}</span>
                    </b-dropdown-item>
                  </div>
                </b-dropdown>
                <!--                <b-select expanded v-model="status_id" placeholder="Filter by status">-->
                <!--                  <option :value="null">All</option>-->
                <!--                  <optgroup :key="index" v-for="(group,index) in statuses"-->
                <!--                            :label="group.name">-->

                <!--                    <option :key="status.id" v-for="status in group.statuses" :value="status.id">{{ status.name }}-->
                <!--                    </option>-->
                <!--                  </optgroup>-->
                <!--                </b-select>-->
              </b-field>
            </div>
            <div v-if="grade_status_filter" class="column is-4">
              <b-field label="Grade Status">
                <b-select v-model="grade_status_id" aria-role="list">
                  <option :value="null">All</option>
                  <option key="1" value=1>Enrolled</option>
                  <option key="2" value=2>Probation</option>
                  <option key="3" value=3>Extended Probation</option>
                  <option key="4" value=4>Dismissed</option>
                </b-select>
              </b-field>
            </div>
            <div v-if="statuses_range_filter" class="column is-4">
              <b-field label="From Status">
                <b-select v-model="from_status_id" expanded placeholder="Filter by status">
                  <option :value="null">All</option>
                  <optgroup v-for="(group,index) in statuses" :key="index"
                            :label="group.name">

                    <option v-for="status in group.statuses" :key="status.id" :value="status.id">{{ status.name }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
            </div>
            <div v-if="statuses_range_filter" class="column is-4">
              <b-field label="To Status">
                <b-select v-model="to_status_id" expanded placeholder="Filter by status">
                  <option :value="null">All</option>
                  <optgroup v-for="(group,index) in statuses" :key="index"
                            :label="group.name">

                    <option v-for="status in group.statuses" :key="status.id" :value="status.id">{{ status.name }}
                    </option>
                  </optgroup>
                </b-select>
              </b-field>
            </div>
            <div v-if="year_filter" class="column is-4">
              <b-field label="Year">
                <b-select
                  v-model="$store.state.dates.year"
                  :required="required_filters.includes('year')"
                  expanded
                  placeholder="Filter by year"
                >
                  <option :value="null">All</option>

                  <option
                    v-for="number in 4"
                    :key="number + 'year'"
                    :value="(new Date().getFullYear()-3)+number"
                  >{{ (new Date().getFullYear() - 3) + number }}
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="semesters.length>0" class="column is-4">
              <b-field label="Semester">
                <b-select
                  v-model="$store.state.dates.semester_id"
                  expanded
                  placeholder="Filter by semester"
                >
                  <option :value="null">All</option>

                  <option v-for="semester in semesters" :key="semester.id" :value="semester.id">
                    <span>{{ semester.name }}</span>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="delivery_types.length>0" class="column is-4">
              <b-field label="Delivery Type">
                <b-select v-model="delivery_type_id" expanded placeholder="Filter by delivery type">
                  <option :value="null">All</option>
                  <option
                    v-for="delivery_type in delivery_types"
                    :key="delivery_type.id"
                    :value="delivery_type.id"
                  >
                    <p>{{ delivery_type.name }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_filter" class="column is-4">
              <b-field label="Stage">
                <b-select v-model="stage_id" expanded placeholder="Filter by stage">
                  <option :value="null">All</option>
                  <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                    <p>{{ stage.name }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_range_filter" class="column is-4">
              <b-field label="From Stage">
                <b-select v-model="from_stage_id" expanded placeholder="Filter by stage">
                  <option :value="null">All</option>
                  <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                    <p>{{ stage.name }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="stages_range_filter" class="column is-4">
              <b-field label="To Stage">
                <b-select v-model="to_stage_id" expanded placeholder="Filter by stage">
                  <option :value="null">All</option>
                  <option v-for="stage in stages" :key="stage.id" :value="stage.id">
                    <p>{{ stage.name }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div v-if="existing_filter" class="column is-4">
              <b-field label="Existing Students">
                <b-checkbox
                  v-model="existing"
                  :false-value="0"
                  :true-value="1"
                >
                  Show only returning students
                </b-checkbox>
              </b-field>
            </div>
            <div class="column is-4">
              <b-field label="Export File Type">
                <b-select v-model="export_file_type" expanded placeholder="Export type">
                  <option v-for="type in file_types" :key="type.name" :value="type.value">
                    <p>{{ type.name }}</p>
                  </option>
                </b-select>
              </b-field>
            </div>
            <div class="column">
              <b-field label="Actions">
                <b-field grouped>
                  <b-field v-if="inline_modules_filter"
                  >
                    <b-checkbox
                      v-model="inline_modules"
                      :false-value="null"
                      :true-value="1"
                    >Inline Modules
                    </b-checkbox>
                  </b-field>
                  <b-field v-if="to_total_filter"
                  >
                    <b-checkbox
                      v-model="to_total"
                      :false-value="null"
                      :true-value="1"
                    >Total
                    </b-checkbox>
                  </b-field>
                  <b-field>
                    <b-button
                      v-if="can_generate"
                      :loading="loading"
                      icon-right="table"
                      native-type="submit"
                      @click="download_state=false;"
                    >Generate
                    </b-button>

                    <b-button
                      :loading="loading"
                      icon-right="download"
                      native-type="submit"
                      @click="download_state=true; "
                    >Download
                    </b-button>

                    <b-button
                      v-if="admissions_team.length>0"
                      icon-right="close"
                      @click="clear()"
                    >Clear
                    </b-button>
                  </b-field>
                </b-field>
              </b-field>
            </div>
          </div>
        </form>
      </section>
    </card-component>
    <card-component v-if="tableData.length>0" class="export-table" title="Table view">
      <b-table
        ref="table"
        :bordered="true"
        :data="tableData"
        :hoverable="true"
        :scrollable="true"
        :striped="true"
        class="margin-top my-table"
        narrowed
      >
        <b-table-column v-for="(name) in columns"
                        :key="name"
                        v-slot="props"
                        :field="name"
                        :label="name"
                        centered
                        sortable
        >{{ props.row[name] }}
        </b-table-column>
      </b-table>
    </card-component>
  </div>
</template>


<script>
import CardComponent from "@/components/CardComponent";
import axios from "axios";
import {ACCESS_TOKEN, API_URL} from "@/config";
import Course from "@/models/Course";
import Module from "@/models/Module";

export default {
  name: "export",
  components: {
    CardComponent,
  },
  props: {
    type: {
      type: String,
      default() {
        return 'exports'
      }
    },
    required_filters: {
      type: Array,
      default: () => {
        return [];
      },
    },
    can_generate: {type: Boolean, default: true},
    owner_filter: {type: Boolean, default: false},
    creator_filter: {type: Boolean, default: false},
    existing_filter: {type: Boolean, default: false},
    course_filter: {type: Boolean, default: false},
    inline_modules_filter: {type: Boolean, default: false},
    to_total_filter: {type: Boolean, default: false},
    location_type_filter: {type: Boolean, default: false},
    module_filter: {type: Boolean, default: false},
    stages_filter: {type: Boolean, default: false},
    stages_range_filter: {type: Boolean, default: false},
    status_filter: {type: Boolean, default: false},
    grade_status_filter: {type: Boolean, default: false},
    statuses_range_filter: {type: Boolean, default: false},
    year_filter: {type: Boolean, default: false},
    date_filter: {type: Boolean, default: true},
    edudex_date_filter: {type: Boolean, default: false},
    entities: {type: String, required: true},
    date_type_start: {type: String, default: () => null},
    date_type_end: {type: String, default: () => null},
    statuses: {type: Array, default: () => []},
    semesters: {type: Array, default: () => []},
    delivery_types: {type: Array, default: () => []},
    stages: {type: Array, default: () => []},
    admissions_team: {type: Array, default: () => []},
    title: {type: String, required: true},
    file_types: {
      type: Array,
      default() {
        return [{name: "csv", value: 'csv'}, {name: "xls", value: 'xls'}, {name: "xlsx", value: "xlsx"}]
      }
    },
    default_type_value: {
      type: String, default() {
        return 'csv'
      }
    }
  },
  computed: {
    years() {
      let years = [];
      for (let i = 0; i <= 4; i++) {
        years.push((new Date().getFullYear()-3) + i);
      }
      return years;
    },
    columns() {
      if (this.tableData) {
        let item = this.tableData[0]
        return Object.keys(item)
      }
      return []
    },
  },
  data() {
    return {
      download_state: false,
      submitted: false,
      loadingModules: false,
      module_id: null,
      tableData: [],
      filteredModules: [],
      moduleHold: "",
      courseHold: "",
      edudex_start_month: null,
      edudex_start_year: null,
      edudex_end_month: null,
      edudex_end_year: null,
      loadingCourses: false,
      course_id: null,
      filteredCourses: [],
      year: null,
      semester_id: null,
      status_id: [],
      delivery_type_id: null,
      location_type: null,
      stage_id: null,
      owner_id: null,
      inline_modules: null,
      to_total: null,
      creator_id: null,
      existing: 0,
      from_stage_id: null,
      to_stage_id: null, from_status_id: null,
      to_status_id: null,
      loading: false,
      export_file_type: this.default_type_value,
      grade_status_id: null,
    };
  },

  methods: {
    getFilteredModule(text) {
      if (text.length > 0) {
        this.loadingModules = true;

        Module.search(text, true, 1).then((result) => {
          this.loadingModules = false;
          if (result.entities) {
            this.filteredModules = result.entities.modules;
          } else {
            this.filteredModules = [];
          }
        });
      }
    },
    getFilteredCourse(text) {
      if (text.length > 0) {
        this.loadingCourses = true;

        Course.search(text, true, 1).then((result) => {
          this.loadingCourses = false;
          if (result.entities) {
            this.filteredCourses = result.entities.courses;
          } else {
            this.filteredCourses = [];
          }
        });
      }
    },
    selectModule(module) {
      if (module) {
        this.module_id = module.id;
      } else {
        this.module_id = null;
      }
    },
    selectCourse(course) {
      if (course) {
        this.course_id = course.id;
      } else {
        this.course_id = null;
      }
    },
    clear() {
      this.status_id = [];
      this.delivery_type_id = null;
      this.stage_id = null;
      this.$store.state.dates.semester_id = null;
      this.$store.state.dates.year = null;
      this.owner_id = null;
      this.creator_id = null;
      this.course_id = null;
      this.courseHold = "";
      this.module_id = null;
      this.location_type = null;
      this.inline_modules = null;
      this.to_total = null;
      this.moduleHold = "";
      this.existing = false;
      this.to_stage_id = null;
      this.from_stage_id = null;
      this.to_status_id = null;
      this.from_status_id = null;
      this.grade_status_id = null;
    },
    download() {
      // this.tableData = []
      let action = this.download_state;
      this.submitted = true;
      if (
        this.moduleHold.length > 0 &&
        this.module_id == null &&
        !this.loadingModules
      ) {
        return;
      }
      if (
        this.courseHold.length > 0 &&
        this.course_id == null &&
        !this.loadingCourses
      ) {
        return;
      }
      this.$store.dispatch("loader/show");
      this.loading = true;
      axios({
        url: `${API_URL}/${this.type}/${this.entities}`, //your url
        method: "GET",

        params: {
          ...(this.$store.state.dates.dateMin && this.date_filter
            ? {
              start: this.$store.getters["dates/formattedDateMin"],
            }
            : {}),
          ...(this.$store.state.dates.dateMax && this.date_filter
            ? {
              end: this.$store.getters["dates/formattedDateMax"],
            }
            : {}),
          ...(this.edudex_date_filter
            ? {
              start: `${this.edudex_start_year}-0${this.edudex_start_month}-01`,
            }
            : {}),
          ...(this.edudex_date_filter
            ? {
              end: `${this.edudex_end_year}-0${this.edudex_end_month}-01`,
            }
            : {}),

          ...(this.statuses.length > 0 && this.status_id.length > 0
            ? {
              status_id: this.status_id,
            }
            : {}),
          ...(this.creator_id
            ? {
              creator_id: this.creator_id,
            }
            : {}), ...(this.existing_filter
            ? {
              existing: this.existing,
            }
            : {}),
          ...(this.delivery_type_id
            ? {
              delivery_type_id: this.delivery_type_id,
            }
            : {}),
          ...(this.owner_id
            ? {
              owner_id: this.owner_id,
            }
            : {}), ...(this.location_type_filter
            ? {
              location_type: this.location_type,
            }
            : {}),
          ...(this.course_id
            ? {
              course_id: this.course_id,
            }
            : {}),
          ...(this.module_id
            ? {
              module_id: this.module_id,
            }
            : {}),
          ...(this.stage_id
            ? {
              stage_id: this.stage_id,
            }
            : {}),
          ...(this.from_stage_id
            ? {
              from_stage_id: this.from_stage_id,
            }
            : {}),
          ...(this.to_stage_id
            ? {
              to_stage_id: this.to_stage_id,
            }
            : {}), ...(this.from_status_id
            ? {
              from_status_id: this.from_status_id,
            }
            : {}),
          ...(this.to_status_id
            ? {
              to_status_id: this.to_status_id,
            }
            : {}),
          ...(this.inline_modules
            ? {
              inline_modules: this.inline_modules,
            }
            : {}),
          ...(this.to_total
            ? {
              to_total: this.to_total,
            }
            : {}),
          ...(this.grade_status_id
            ? {
              grade_status_id: this.grade_status_id,
            }
            : {}),
          ...(this.$store.state.dates.year
            ? {
              year: this.$store.state.dates.year,
            }
            : {}),
          ...(this.$store.state.dates.semester_id
            ? {
              semester: this.$store.state.dates.semester_id,
            }
            : {}),
          ...(action === false
            ? {
              generate: true,
            }
            : {}),
          ...(action === true
            ? (this.export_file_type ? {
              export_file_type: this.export_file_type,
            } : {})
            : {export_file_type: 'csv'}),

          // ...(this.stage_id && this.entities != "registrations"
          //   ? {
          //       stage_id: this.stage_id
          //     }
          //   : {})
        },
        headers: {
          "Content-Type": "application/json",

          Authorization: "Bearer " + ACCESS_TOKEN,
          "X-Requested-With": "XMLHttpRequest",
        },
      })
        .then((response) => {
          if (action === false) {
            this.tableData = response.data.data.attributes.data;
          }
          if (action === true) {
            const link = document.createElement("a");
            link.href = response.data.exportUrl;
            link.target = '_blank'
            // link.setAttributeNode(document.createAttribute('download'));
            document.body.appendChild(link);
            link.click();
          }
          this.$store.dispatch("loader/hide");
          this.submitted = false;
          this.loading = false;
        })
        .catch((err) => {
          this.$store.dispatch("toast/createToast", {
            message: JSON.stringify(err.response.data),
          });
        });
    },
    oneWeekAgo() {
      var oneWeekAgo = new Date();
      oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
      return oneWeekAgo;
    },
  },
};
</script>


<style lang="scss">
</style>
